import React, { Component } from 'react';
import JobApi from "./../../api/JobApi"

class AppliedCount extends Component {

    constructor(props) {
        super(props)

        this.state = {
            applicationCount:0
        }

        this.getApllicationList = this.getApllicationList.bind(this)
    }

    componentDidMount(){
        this.getApllicationList()
    }

    getApllicationList() {
        const obJob = new JobApi()

        const params = {
            job_poster:this.props.jobId
        }

        obJob.getApplicationList(params)
        .then(res => {

            this.setState({
                applicationCount:res.data.results.length
            })
        })
        .catch(err => {
            console.log(err.message)
        })
    }

    render() {
        return (
            <span>
                { this.state.applicationCount }
            </span>
        );
    }
}

export default AppliedCount;