import React, { useState } from 'react'
import ContactAddModal from '../Modal/ContactAddModal'

import css from './ContactSelect.module.css'

function ContactSelectButton(props) {
    const [isShowModal, setIsShowModal] = useState(false)
    let initial = ""
    if (props.name) {
        initial = props.name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
    }
    return (
        <div>
            <div className={["d-flex align-items-center p-3 bg-white"].join(' ')}>
                <div
                    className="mr-3 d-flex align-items-center justify-content-between"
                    style={{ backgroundColor: "#E2E2E2", minWidth: "32px", height: "32px", width: "32px", borderRadius: "50%", border: "2px solid #223771" }}
                >
                    <p className="mb-0 mx-auto">{initial}</p>
                </div>
                <div className="row w-100" >
                    <div className={["col-md-6", css.text].join(' ')}>
                        <p className={css.name}>{props.name}</p>
                        <p className={css.title}>{props.title}</p>
                    </div>
                    <div className={["col-md-6", css.text].join(' ')}>
                        <p className={css.phone}>{props.phone}</p>
                        <p className={css.email}>{props.email}</p>
                    </div>
                </div>
            </div>
            <div>
                <button
                    className={["form-control mt-2 btn btn-secondary", css.ButtonSecondary].join(' ')}
                    onClick={() => { setIsShowModal(true) }}
                >
                    Assign Contact
                </button>
            </div>
            <ContactAddModal
                idx={props.idx}
                show={isShowModal}
                onHide={() => {setIsShowModal(false)}}
                noSubmitApi={true}
                handleAssignedContact={props.handleAssignedContact}
            />
        </div>
    )
}

export default ContactSelectButton;