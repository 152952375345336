import axios from "axios";
import config from "./../config/config";
class LanguageApi {
  base_api = config.base_api;
  token = config.token;

  getAllLanguage(params) {
    return axios.get(this.base_api + "languages/", {
      params: params,
      headers: {
        Authorization: "Token " + this.token,
        "Content-Type": "application/json",
      },
    });
  }

  getDetailLanguage(langId) {
    return axios.get(this.base_api + `languages/${langId}/`, {
      headers: {
        Authorization: "Token " + this.token,
        "Content-Type": "application/json",
      },
    });
  }

  languageAdd(lang) {
    return axios.post(
      this.base_api + `languages/`,
      {
        name: lang.name,
        abbreviation: lang.abbreviation,
        json_field: lang.json_field,
        flag:lang.flag

      },
      {
        headers: {
          Authorization: "Token " + this.token,
          "Content-Type": "application/json",
        },
      }
    );
  }

  languageUpdate(id, lang) {
    return axios.patch(
      this.base_api + `languages/${id}/`,
      {
        name: lang.name,
        abbreviation: lang.abbreviation,
        json_field: lang.json_field
      },
      {
        headers: {
          Authorization: "Token " + this.token,
          "Content-Type": "application/json",
        },
      }
    );
  }

  updateClientDetail(clientId, client) {
    return axios.patch(this.base_api + `clients/${clientId}/`, client, {
      headers: {
        'Authorization': `Token ${this.token}`,
        "Content-Type": "application/json"
      }
    })
  }

  updateSiteDetail(siteId, site) {
    return axios.patch(this.base_api + `sites/${siteId}/`, site, {
      headers: {
        'Authorization': `Token ${this.token}`,
        "Content-Type": "application/json"
      }
    })
  }

  pairSiteLanguage(siteId, language) {
    return axios.patch(this.base_api + `sites/${siteId}/pair_languages/`, language, {
      headers: {
        'Authorization': `Token ${this.token}`,
        "Content-Type": "application/json"
      }
    })
  }

  unpairSitelanguage(siteId, language) {
    return axios.patch(this.base_api + `sites/${siteId}/unpair_languages/`, language, {
      headers: {
        'Authorization': `Token ${this.token}`,
        "Content-Type": "application/json"
      }
    })
  }

}

export default LanguageApi;
