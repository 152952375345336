import React, { Component } from 'react';
import DashboardNew from '../components/section/DashboardNew';

class TestSidebarPage extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    toggleActive = () => {
        this.setState((prevState) => {
            return {
                isCollapse: !prevState.isCollapse,
            };
        })
    }

    render() {
        return (
            <>
                <DashboardNew>
                </DashboardNew>
            </>

        );
    }
}

export default TestSidebarPage;