import React, { Component } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
// import DataTable from "react-data-table-component";
// import DataTableExtensions from "react-data-table-component-extensions";
import Pagination from "react-js-pagination";

import { withTranslation } from "react-i18next";

import Context from "./Context";
import { Content, ContentHeader, ContentBody } from "./../components/Content";
import TitlePages from "./Elements/TitlePages";
import SuperAdminUserDetail from "../components/Modal/SuperAdminUserDetail";
import UserDeleteConfirm from "../components/Modal/UserDeleteConfirm";

import SuperAdminApi from "../api/SuperAdminApi";

import "react-data-table-component-extensions/dist/index.css";
import "./SuperAdminPage.css";

class SuperAdminUserListPage extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);

        this.state = {
            userID: false,
            users: [],
            activeDetailUser: false,
            showingDetailUser: false,
            userSelected: [],
            userSelectedNames: [],
            groups: [],

            isDeleteUser: false,

            search: "",
            activePage: 1,
            limitPageData: 10,
            totalCount: 0,
            stateLocation: 0,

            activeSubscription: [],
        };

        this.getUsersBySite = this.getUsersBySite.bind(this);
        this.getAllGroups = this.getAllGroups.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSelectUser = this.handleSelectUser.bind(this);
        this.showingDetailUser = this.showingDetailUser.bind(this);
    }

    componentDidMount() {
        const stateLocation = this.props.location.state;
        const contextValue = this.context;
        const activeSite = contextValue.activeSite;

        if (activeSite) {
            this.setState({
                userID: activeSite,
                stateLocation: stateLocation,
            });
            this.getUsersBySite(activeSite);
            this.getClientDetail(contextValue.activeClient);
        }
    }

    componentDidUpdate() {
        const contextValue = this.context;

        if (this.state.userID !== contextValue.activeSite) {
            this.setState({ userID: contextValue.activeSite }, () => {
                this.getUsersBySite(this.state.userID);
                this.getClientDetail(contextValue.activeClient);
            });
        }
    }

    getUsersBySite(userId) {
        const Sa = new SuperAdminApi();
        const contextValue = this.context;

        const param = {
            page_size: this.state.limitPageData,
            page: this.state.activePage,
            search: this.state.search,
            // max_size: true,
        };

        Sa.getUsersBySite(contextValue.userKey, parseInt(userId), param)
            .then((res) => {
                this.setState({
                    users: res.data.results,
                    totalCount: res.data.results.length,
                });
            })
            .catch((err) => { });
    }

    getClientDetail = (client) => {
        const Sa = new SuperAdminApi();
        const contextValue = this.context;

        Sa.getClientDetail(contextValue.userKey, client)
            .then((res) => {
                let allSubscription = res.data.subscriptions;
                let activeSubscription = [];

                if (allSubscription && allSubscription.length > 0) {
                    allSubscription.forEach((sb, index) => {
                        activeSubscription.push(sb.id);
                    });
                }

                this.setState(
                    {
                        activeSubscription: activeSubscription,
                    },
                    () => {
                        this.getAllGroups();
                    }
                );
            })
            .catch((err) => { });
    };

    getAllGroups() {
        const Sa = new SuperAdminApi();
        const contextValue = this.context;
        const { activeSubscription } = this.state;

        if (activeSubscription) {
            Sa.getGroupsBasedSubscriptions(
                contextValue.userKey,
                activeSubscription
            )
                .then((res) => {
                    let allgroups = res.data.results;
                    let activeGroups = [];

                    if (allgroups && allgroups.length > 0) {
                        allgroups.forEach((gr) => {
                            activeGroups.push({
                                id: gr.id,
                                name: gr.name,
                                active: false,
                            });
                        });
                    }

                    this.setState({
                        groups: res.data.results,
                        activeGroups: activeGroups,
                    });
                })
                .catch((err) => { });
        }
    }

    handlePageChange(pageNumber) {
        this.setState(
            {
                activePage: pageNumber,
            },
            () => {
                this.getAllLocation();
            }
        );
    }

    handleSearchChange(value) {
        this.setState(
            {
                activePage: 1,
                search: value,
            },
            () => {
                this.getUsersBySite(this.state.userID);
            }
        );
    }

    handleChangeShow(value) {
        this.setState({
            limitPageData: value,
        });
    }

    showingDetailUser(userId) {
        this.setState(
            {
                activeDetailUser:
                    userId !== this.state.activeDetailUser ? userId : false,
                showingDetailUser: !this.state.showingDetailUser,
            }
        );
    }

    setUserDelete = () => {
        this.setState({
            isDeleteUser: !this.state.isDeleteUser,
        });
    };

    handleSelectUser(e, id, username) {
        const { userSelected, userSelectedNames } = this.state;

        let users = userSelected;
        let usersName = userSelectedNames;
        if (e.target.checked && id) {
            if (!userSelected.includes(id)) {
                users.push(id);
                usersName.push({ username: username });
            }
        } else if (!e.target.checked && id) {
            if (userSelected.includes(id)) {
                const index = users.indexOf(id);
                if (index > -1) {
                    users.splice(index, 1);
                    usersName.splice(index, 1);
                }
            }
        }

        this.setState(
            {
                userSelected: users,
                userSelectedNames: usersName,
            }
        );
    }

    deleteSelectedUser() {
        const { userSelected } = this.state;
        const Sa = new SuperAdminApi();
        const contextValue = this.context;

        if (userSelected.length > 0) {
            Sa.deleteUser(contextValue.userKey, userSelected)
                .then((res) => {
                    if (res.data) {
                        swal("Success", `User deleted`, "success");
                        this.setUserDelete();
                        this.getUsersBySite(contextValue.activeSite);
                    }
                })
                .catch((err) => { });
        }
    }

    render() {
        const { users, userSelected } = this.state;

        let tableUsers;
        if (users && userSelected) {
            tableUsers = users.map((user, index) => {
                let groups;
                if (user.groups && user.groups.length > 0) {
                    groups = user.groups.map((gr) => {
                        return <div className="gr-priv">{gr}</div>;
                    });
                }
                let active = userSelected.includes(user.id) ? "active" : "";

                return (
                    <div className={["s-adm-users-tbl-con", active].join(" ")} key={`table-${index}`}>
                        <div className="w-5">
                            <p className="c-ck-custom">
                                <input
                                    type="checkbox"
                                    onClick={(e) => {
                                        this.handleSelectUser(
                                            e,
                                            user.id,
                                            user.name
                                        );
                                    }}
                                />
                                <span className="checkmark"></span>
                            </p>
                        </div>
                        <div className="w-5">
                            <p>{user.id}</p>
                        </div>
                        <div className="w-20">
                            <p>{user.username}</p>
                        </div>
                        <div className="w-20">
                            <p>{user.name}</p>
                        </div>
                        <div className="w-10 d-flex">
                            <div className="w-100 m-auto d-flex flex-row justify-content-center">
                                {groups}
                            </div>
                        </div>
                        <div className="w-10" onClick={() => this.showingDetailUser(user.id)}>
                            <div className="sa-btn sa-btn-bl sa-field">
                                Update
                            </div>
                        </div>
                    </div>
                );
            });
        }

        let btnActionActive;
        if (this.state.userSelected && this.state.userSelected.length < 1) {
            btnActionActive = "sa-field-nonactive";
        }

        return (
            <Content>
                <ContentHeader>
                    <TitlePages title={"Users"} />
                </ContentHeader>
                <ContentBody>
                    <div id="s-adm-user-page" className="col-md-12">
                        <div id="c_dt_tbl">
                            <div className="d-flex flex-row" width="100%">
                                <div
                                    className="form-group"
                                    style={{ width: "30%", margin: "0" }}
                                >
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        className="form-control sa-field"
                                        name="search"
                                        value={this.state.search}
                                        onChange={(event) =>
                                            this.handleSearchChange(
                                                event.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className="d-flex ml-3">
                                    <span className="mt-auto mb-auto mr-2">
                                        shows
                                    </span>
                                    <select
                                        className="form-control sa-field"
                                        onChange={(event) =>
                                            this.handleChangeShow(
                                                event.target.value
                                            )
                                        }
                                    >
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                    </select>
                                </div>
                                <div className="d-flex ml-auto">
                                    <Link
                                        to={"/superadmin"}
                                        className="sa-btn sa-btn-bl sa-field"
                                    >
                                        Back
                                    </Link>
                                    <Link
                                        to={{
                                            pathname: "/superadmin/users/add",
                                            state: {
                                                value: this.state.userID,
                                            },
                                        }}
                                        className="sa-btn sa-btn-bl sa-field"
                                    >
                                        Add user
                                    </Link>
                                    <div
                                        className={[
                                            "sa-btn sa-btn-wh sa-field",
                                            btnActionActive,
                                        ].join(" ")}
                                    >
                                        Bulk change
                                    </div>
                                    <div
                                        className={[
                                            "sa-btn sa-btn-wh sa-field",
                                            btnActionActive,
                                        ].join(" ")}
                                        onClick={this.setUserDelete}
                                    >
                                        Delete
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <div className="s-adm-user-tbl-head">
                                <div className="w-5">
                                    <p></p>
                                </div>
                                <div className="w-5">
                                    <p>ID</p>
                                </div>
                                <div className="w-20">
                                    <p>Username</p>
                                </div>
                                <div className="w-20">
                                    <p>Name</p>
                                </div>
                                <div className="w-10">
                                    <p>Groups</p>
                                </div>
                                <div className="w-10">
                                    <p>Action</p>
                                </div>
                            </div>

                            {tableUsers}

                            <div id="pagination_custom" style={{ float: "left", margin: "2rem 0" }}>
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.limitPageData}
                                    totalItemsCount={this.state.totalCount}
                                    pageRangeDisplayed={4}
                                    onChange={this.handlePageChange.bind(this)}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    prevPageText="Prev"
                                    nextPageText="Next"
                                />
                            </div>
                        </div>
                    </div>
                </ContentBody>

                <SuperAdminUserDetail
                    show={this.state.showingDetailUser}
                    onHide={() => {
                        this.showingDetailUser(this.state.activeDetailUser);
                    }}
                    callback={() => {
                        this.getUsersBySite(this.state.userID);
                    }}
                    activeDetailUser={this.state.activeDetailUser}
                    groups={this.state.groups}
                    state={this.state.stateLocation}
                    activeSubscription={this.state.activeSubscription}
                ></SuperAdminUserDetail>
                <UserDeleteConfirm
                    show={this.state.isDeleteUser}
                    onHide={this.setUserDelete}
                    getUser={() => {
                        this.getUsersBySite(this.state.userId);
                    }}
                    selectedUser={this.state.userSelectedNames}
                    deleteSelectedUser={() => this.deleteSelectedUser()}
                ></UserDeleteConfirm>
            </Content>
        );
    }
}

export default withTranslation()(SuperAdminUserListPage);
