import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"
import swal from "sweetalert"
import AlertHelper from "./../../lib/AlertHelper"

import SectionApi from "./../../api/SectionApi"
import WidgetApi from "./../../api/WidgetApi"
import PageApi from "../../api/PageApi"
import Context from "../../pages/Context"

class WidgetAddModal extends Component {
    static contextType = Context;
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            name: "",
            category: 0,
            sectionId: 0,
            widgetCategories: [],
            sectionList: [],
            categorySelectDisable: false,
            widgetType: "internal",
            urlType: "internal",
            pages: [],
            sections: [],
            languageList: [],
            urlLanguages: [],
            selectedPageId: null,
            selectedSectionId: null
        }

        this.onOpenModal = this.onOpenModal.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)
        this.getWidgetCategories = this.getWidgetCategories.bind(this)
        this.getAllSections = this.getAllSections.bind(this) // APA INI ? 
        this.getPages = this.getPages.bind(this)
        this.getSectionByPage = this.getSectionByPage.bind(this)

        this.handleChangeName = this.handleChangeName.bind(this)
        this.handleChangeCategory = this.handleChangeCategory.bind(this)
        // this.handleChangeSection = this.handleChangeSection.bind(this)
        this.handleWidgetAdd = this.handleWidgetAdd.bind(this)
        this.handleChangeWidgetType = this.handleChangeWidgetType.bind(this)
        this.handleUrlTypeChange = this.handleUrlTypeChange.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.handleSectionChange = this.handleSectionChange.bind(this)
        this.handleUrlExternalChange = this.handleUrlExternalChange.bind(this)

        this.widgetUpdate = this.widgetUpdate.bind(this)
    }

    handleChangeName(value) {
        this.setState({
            name: value
        })
    }

    handleChangeCategory(value) {
        this.setState({
            category: value
        })
    }

    // handleChangeSection(value) {
    //     this.setState({
    //         sectionId:value
    //     })
    // }

    handleChangeWidgetType(value) {
        this.setState({
            widgetType: value
        })
    }

    getPages() {
        const objPage = new PageApi()

        const params = {
            language: localStorage.getItem("lang"),
            template: localStorage.getItem("templateId"),
            max_size: true
        }

        objPage.pageLanguageList(params)
            .then(res => {

                this.setState({
                    pages: res.data.results
                })
            })
    }

    getSectionByPage() {
        const objSection = new SectionApi()

        const params = {
            language: localStorage.getItem("lang"),
            page_id: this.state.selectedPageId,
            max_size: true
        }

        objSection.sectionLanguage(params)
            .then(res => {

                this.setState({
                    sections: res.data.results
                })
            })
    }

    handlePageChange(value) {
        if (!value) {
            this.setState({
                selectedPageId: null,
                selectedSectionId: null,
                sections: []
            }, () => {
                //this.getSectionByPage()
            })
        } else {
            this.setState({
                selectedPageId: value
            }, () => {
                this.getSectionByPage()
            })

        }
    }

    handleSectionChange(value) {
        this.setState({
            selectedSectionId: value
        })
    }


    handleUrlTypeChange(value) {
        this.setState({
            urlType: value
        })
    }

    handleUrlExternalChange(id, value) {
        let urlLanguages = [...this.state.urlLanguages]
        let urlLanguage = { ...urlLanguages[id] }
        urlLanguage.path = value
        urlLanguages[id] = urlLanguage

        this.setState({
            urlLanguages: urlLanguages
        })
    }

    handleWidgetAdd() {
        const objSection = new SectionApi()

        const name = this.state.name
        const category = this.state.category
        const sections = this.state.sectionId
        const is_internal = this.state.widgetType === "internal" ? "True" : "False"

        const urls = this.state.urlLanguages.map(item => {
            return {
                id: null,
                language: item.language,
                page: !this.state.selectedPageId ? null : this.state.selectedPageId,
                section: !this.state.selectedSectionId ? null : this.state.selectedSectionId,
                path: item.path
            }
        })

        const data = {
            name: name,
            category: category,
            section: sections,
            is_internal: is_internal,
            urls: urls
        }

        objSection.sectionWidgetAdd(data)
            .then(res => {
                this.props.getSectionDetail(this.props.section.id)
                this.props.onHide()
                swal("Success !", "You Sucessfully add new Widget on this Section", "success")
                setTimeout(() => {
                    window.location.reload();
                }, 3000)
            })
            .catch(err => {
                const objAlert = new AlertHelper()
                const resErr = err.message
                objAlert.errorHandlerAlert(resErr)
            })
    }

    widgetUpdate() {
        const objSection = new SectionApi()
        const objWidget = new WidgetApi()

        const id = this.state.id
        const name = this.state.name
        const category = this.state.category
        // const sections = this.state.sectionId

        //const sections = this.props.section.id || this.state.sectionId
        const urls = this.state.urlLanguages.map(item => {
            return {
                id: null,
                language: item.language,
                page: !this.state.selectedPageId ? null : this.state.selectedPageId,
                section: !this.state.selectedSectionId ? null : this.state.selectedSectionId,
                path: item.path
            }
        })


        objWidget.getDetailWidget(id)
            .then(res => {

                if (this.state.sectionId && this.state.sectionId !== this.props.section.id) {
                    objSection.sectionWidgetUpdate(id, name, category)
                        .then(res => {

                            return objSection.sectionWidgetDelete(this.props.section.id, [id])

                        })
                        .then(res => {

                            return objSection.sectionWidgetPair(this.state.sectionId, [id])

                        })
                        .then(res => {
                            this.props.getSectionDetail(this.state.sectionId)
                            this.props.onHide()
                            swal("Success !", "You Sucessfully updated Widget on this Section", "success")
                            setTimeout(() => {
                                window.location.reload();
                            }, 0)
                        })
                        .catch(err => {
                            const objAlert = new AlertHelper()
                            const resErr = err?.response?.data ? err?.response?.data : err.message
                            objAlert.errorHandlerAlert(resErr)
                        })
                } else {

                    const data = {
                        id: id,
                        name: name,
                        category: category,
                        urls: urls
                    }

                    objSection.sectionWidgetUpdate(data)
                        // .then(res => {

                        // })
                        .then(res => {
                            this.props.getSectionDetail(this.props.section.id)
                            this.props.onHide()
                            swal("Success !", "You Sucessfully updated Widget on this Section", "success")
                            // setTimeout(() => {
                            //     window.location.reload();
                            // },0)
                        })
                        .catch(err => {
                            const objAlert = new AlertHelper()
                            const resErr = err.message
                            objAlert.errorHandlerAlert(resErr)
                        })
                }
            })

    }

    onOpenModal() {
        const { languagesList } = this.context;
        var categorySelectDisable = this.props.widgetId ? true : false

        this.setState({
            id: this.props.widgetId || 0,
            sectionId: this.props.section.id,
            categorySelectDisable: categorySelectDisable,
            languageList: languagesList

        }, () => {

            if (this.state.id) {
                this.getDetailWidget()
            } else {

                var urlLangList = this.state.languageList.map(item => {

                    var result = {
                        id: null,
                        language: item?.language?.id,
                        languageName: item.name,
                        page: null,
                        section: null,
                        path: null
                    }

                    return result
                })

                this.setState({
                    urlLanguages: urlLangList
                })

            }

            this.getWidgetCategories()
            this.getAllSections()
            this.getPages()

        })

    }

    onCloseModal() {
        this.setState({
            id: 0,
            name: "",
            category: 0,
            sectionId: 0,
            widgetCategories: [],
            sectionList: [],
            categorySelectDisable: false
        })

        this.props.onHide()
    }

    getAllSections() {
        const obj = new SectionApi()

        obj.getAllSections()
            .then(res => {
                this.setState({
                    sectionList: res.data.results || []
                })
            })
            .catch(err => {
                const objAlert = new AlertHelper()
                const resErr = err.message
                objAlert.errorHandlerAlert(resErr)
            })
    }

    getWidgetCategories() {
        const obj = new WidgetApi()

        obj.getWidgetCategories()
            .then(res => {
                this.setState({
                    widgetCategories: res.data.results || []
                })
            })
            .catch(err => {
                const objAlert = new AlertHelper()
                const resErr = err.message
                objAlert.errorHandlerAlert(resErr)
            })
    }

    getDetailWidget() {
        const objWidget = new WidgetApi()

        objWidget.getDetailWidget(this.state.id)
            .then(res => {
                const widget = res.data
                var urlLangList = this.state.languageList.map(item => {

                    var result = {
                        id: null,
                        language: item?.language?.id,
                        languageName: item.name,
                        page: null,
                        section: null,
                        path: null
                    }

                    return result
                })

                this.setState({
                    name: res.data.name,
                    category: res.data.category,
                    sectionId: res.data.sectionId,
                    urlLanguages: urlLangList,
                    widgetType: widget.is_internal ? "internal" : "external"
                })
            })
    }

    render() {
        const { widgetId } = this.props;
        var BtnText = "Add Widget "
        var Action = "Add "
        var BtnAction = this.handleWidgetAdd

        if (this.props.widgetId) {
            BtnText = "Update Widget "
            Action = "Update "
            BtnAction = this.widgetUpdate
        }

        const PageSelect = this.state.pages.map(item => {
            return (<option key={item.id} value={item.id}>{item.name}</option>)
        })

        const SectionSelect = this.state.sections.map(item => {
            return (<option key={item.id} value={item.section}>{item.name}</option>)
        })

        const WidgetCategoriesOption = this.state.widgetCategories.map(item => {
            return (
                <option key={item.id} value={item.id}> {item.name} </option>
            )
        })

        // GANTI JIKA SUDAH ADA4
        var URLInput = this.state.urlLanguages.map((item, index) => {
            return (
                <div className="form-group" key={index}>

                    <label> Url {item.languageName}</label>
                    <input value={this.state.urlLanguages[index].path} type="text" className="form-control"
                        onChange={(event) => this.handleUrlExternalChange(index, event.target.value)}></input>
                </div>
            )
        })

        if (this.state.urlType === "internal") {
            URLInput = <div>
                <div className="form-group">
                    <label> Page </label>
                    <select className="form-control" value={this.state.selectedPageId} onChange={(event) => this.handlePageChange(event.target.value)}>
                        <option value={null}> -- Please Select Your Page -- </option>
                        {PageSelect}
                    </select>
                </div>
                <div className="form-group">
                    <label> Section </label>
                    <select className="form-control" value={this.state.selectedSectionId} onChange={(event) => this.handleSectionChange(event.target.value)}>
                        <option value={null}> -- Please Select Your Section -- </option>
                        {SectionSelect}
                    </select>
                </div>

            </div>
        }

        return (
            <Modal show={this.props.show} onHide={() => this.onCloseModal()} size="lg" onShow={this.onOpenModal}>
                <Modal.Header closeButton className="bg-primary">
                    <Modal.Title> {Action} {this.props.section.name} Widget </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* { JSON.stringify(this.state.urlLanguages)} */}
                    {/* <div className="form-group">
                         <label> Section</label>
                         <select value={this.state.sectionId} className="form-control" onChange={(event) => this.handleChangeSection(event.target.value)}>
                            {SectionOption}
                         </select>
                    </div> */}
                    <div className="form-group">
                        <label> Name </label>
                        <input className="form-control" type="text" value={this.state.name} onChange={(event) => this.handleChangeName(event.target.value)} ></input>
                    </div>
                    <div className="form-group">
                        <label>Category</label>
                        <select disabled={this.state.categorySelectDisable} className="form-control" value={this.state.category} onChange={(event) => this.handleChangeCategory(event.target.value)} >
                            <option value=""> -- Select Category -- </option>
                            {WidgetCategoriesOption}
                        </select>

                    </div>
                    <div className="form-group">
                        <label> Widget Type </label>
                        <div>
                            <input
                                disabled={!widgetId ? false : true}
                                name="widget-type"
                                type="radio"
                                value="internal"
                                id="widget-internal"
                                checked={this.state.widgetType === "internal"}
                                onChange={() => this.handleChangeWidgetType("internal")}
                            ></input>
                            <label htmlFor="widget-internal">&nbsp; Internal </label>
                            <span>&nbsp; &nbsp; &nbsp;</span>
                            <input
                                disabled={!widgetId ? false : true}
                                name="widget-type"
                                type="radio"
                                value="external"
                                id="widget-external"
                                checked={this.state.widgetType === "external"}
                                onChange={() => this.handleChangeWidgetType("external")}
                            ></input>
                            <label htmlFor="widget-external">&nbsp; External </label>
                        </div>

                    </div>

                    <div className="form-group">
                        <label> Url Type</label><br></br>

                        <label htmlFor="url-internal">
                            <input
                                type="radio"
                                className=""
                                id="url-internal"
                                name="url_type"
                                value={"internal"}
                                checked={this.state.urlType === "internal"}
                                onChange={() => this.handleUrlTypeChange("internal")}
                            ></input>
                            &nbsp; Internal
                        </label>
                        &nbsp; &nbsp;
                        <label htmlFor="url-external">
                            <input
                                type="radio"
                                className=""
                                id="url-external"
                                name="url_type"
                                value="external"
                                checked={this.state.urlType === "external"}
                                onChange={() => this.handleUrlTypeChange("external")}
                            ></input>
                            &nbsp; External </label>
                    </div>
                    {URLInput}
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={() => BtnAction()}>
                        <i className="fa fa-plus">&nbsp;</i>
                        {BtnText}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default WidgetAddModal;