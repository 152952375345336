import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import Card, { CardHeader, CardBody } from "./../../components/Card";
// import { Content, ContentHeader, ContentBody } from "./../../components/Content"

import { shortSentence } from "./../../lib/StringHelper";

import Context from "../Context";

class TextElement extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);

        this.state = {
            languages: [],
        };

        this.handleTextChange = this.handleTextChange.bind(this);
        this.getLanguages = this.getLanguages.bind(this);
    }

    handleTextChange(value) {
        if (this.props.handleTextChange) {
            this.props.handleTextChange(value);
        }
    }

    componentDidMount() {
        this.getLanguages();
    }

    getLanguages() {
        const { languagesList } = this.context;

        this.setState({
            languages: languagesList,
        });
    }

    render() {
        const TextElement = this.props.text_elements.map((item) => {
            //var searchLang = item.text_languages.find(item => item.lang )
            var index = this.state.languages.findIndex(
                (item) => item.abbreviation === localStorage.getItem("lang")
            );

            var shortContent = shortSentence(
                item.text_languages[index]?.content,
                5
            );

            return (
                <div className="col-md-3" key={item.id}>
                    <Card>
                        <CardHeader className="bg-primary">
                            <div className="form-check form-check-inline">
                                <input
                                    type="checkbox"
                                    value=""
                                    onClick={(event) => {
                                        this.props.setSelectedText(
                                            "text",
                                            item,
                                            event.currentTarget.checked
                                        );
                                    }}
                                    className="form-check-input"
                                    id={"label" + item.id}
                                ></input>
                                &nbsp;
                                <label
                                    className="form-check-label"
                                    htmlFor={"label" + item.id}
                                >
                                    <b>{item.name}</b>{" "}
                                </label>
                            </div>
                            <div className="card-tools">
                                <Dropdown drop="down">
                                    <Dropdown.Toggle
                                        id="dropdown-basic"
                                        as={"a"}
                                    >
                                        <label
                                            href="#"
                                            style={{ color: "black" }}
                                        >
                                            <i className="fa fa-ellipsis-v"></i>
                                        </label>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => {
                                                this.props.setTextId(item.id);
                                            }}
                                        >
                                            Update
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                            >
                                <div className="container-fluid" align="center">
                                    {shortContent} ...
                                </div>
                            </Link>
                        </CardBody>
                    </Card>
                </div>
            );
        });

        return <div className="row">{TextElement}</div>;
    }
}

export default TextElement;
