import axios from "axios";
import config from "./../config/config";
export default class AssetApi {
    // base_api = "http://192.168.13.177:7000/"
    base_api = config.base_api;
    token = config.token;

    getAllAssets(params) {
        return axios.get(this.base_api + "assets/", {
            params: params,
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getAllAssetsAfterFilter(params) {
        return axios.get(this.base_api + "assets/", {
            params: params,
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getDetailAsset(assetId) {
        return axios.get(this.base_api + "assets/" + assetId + "/", {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    /* 
          data = {
              name:..,
              asset_file:..,
          }
      */
    assetAdd(data) {
        var bodyFormData = new FormData();
        var bool = "False";

        if (data.has_languages) {
            bool = "True";
        }

        bodyFormData.append("client", data.client);

        bodyFormData.append("name", data.name);
        bodyFormData.append("has_languages", bool);

        if (data.asset_file) {
            bodyFormData.append("asset_file", data.asset_file);
        }

        if (data.asset_mobile) {
            bodyFormData.append("asset_mobile", data.asset_mobile);
        }

        if (data.mobile_variable) {
            bodyFormData.append("mobile_aspect", data.mobile_variable.mobile_aspect);
            bodyFormData.append("mobile_unit", data.mobile_variable.mobile_unit);
            bodyFormData.append("mobile_height", data.mobile_variable.mobile_height);
            bodyFormData.append("mobile_width", data.mobile_variable.mobile_width);
            bodyFormData.append("mobile_x", data.mobile_variable.mobile_x);
            bodyFormData.append("mobile_y", data.mobile_variable.mobile_y);
        }


        if (data.thumbnail !== null) {
            bodyFormData.append("thumbnail", data.thumbnail)
        }

        if (data.asset_file) {
            bodyFormData.append("asset_file", data.asset_file)
        }

        if (data.asset_link) {
            bodyFormData.append("asset_link", data.asset_link)
        }

        bodyFormData.append("file_type", data.file_type)
        bodyFormData.append("asset_type", data.asset_type)

        bodyFormData.append("set_logo_opacity", data.set_logo_opacity);
        bodyFormData.append("has_slogan", data.has_slogan);

        if (data.logo_opacity) {
            bodyFormData.append("logo_opacity", data.logo_opacity)
        }


        data.asset_languages.forEach((item, index) => {
            bodyFormData.append(`asset_languages[${index}]language`, item.language)
            bodyFormData.append(`asset_languages[${index}]description`, item.description)
            if (item.asset_file) {
                bodyFormData.append(`asset_languages[${index}]asset_file`, item.asset_file)
            }

            if (item.asset_link) {
                bodyFormData.append(`asset_languages[${index}]asset_link`, item.asset_link)
            }

            if (item.asset_mobile) {
                bodyFormData.append(`asset_languages[${index}]asset_mobile`, item.asset_mobile)
            }

            if (data.mobile_variable) {
                bodyFormData.append(`asset_languages[${index}]mobile_aspect`, item.mobile_variable.mobile_aspect);
                bodyFormData.append(`asset_languages[${index}]mobile_unit`, item.mobile_variable.mobile_unit);
                bodyFormData.append(`asset_languages[${index}]mobile_height`, item.mobile_variable.mobile_height);
                bodyFormData.append(`asset_languages[${index}]mobile_width`, item.mobile_variable.mobile_width);
                bodyFormData.append(`asset_languages[${index}]mobile_x`, item.mobile_variable.mobile_x);
                bodyFormData.append(`asset_languages[${index}]mobile_y`, item.mobile_variable.mobile_y);
            }

            if (item.asset_link) {
                bodyFormData.append(`asset_languages[${index}]asset_link`, item.asset_link)
            }
        })

        return axios.post(this.base_api + "assets/", bodyFormData, {
            headers: {
                'Authorization': `Token ${this.token}`,
                "Content-Type": "multipart/form-data",

            }
        })
    }


    /*
      
      */
    assetUpdate(assetId, data) {
        var bodyFormData = new FormData();
        var bool = "False";

        if (data.has_languages) {
            bool = "True";
        }

        bodyFormData.append("client", data.client);

        bodyFormData.append("name", data.name);
        bodyFormData.append("has_languages", bool);

        if (data.asset_mobile) {
            bodyFormData.append("asset_mobile", data.asset_mobile);
        }

        if (data.mobile_variable) {
            bodyFormData.append("mobile_aspect", data.mobile_variable.mobile_aspect);
            bodyFormData.append("mobile_unit", data.mobile_variable.mobile_unit);
            bodyFormData.append("mobile_height", data.mobile_variable.mobile_height);
            bodyFormData.append("mobile_width", data.mobile_variable.mobile_width);
            bodyFormData.append("mobile_x", data.mobile_variable.mobile_x);
            bodyFormData.append("mobile_y", data.mobile_variable.mobile_y);
        }

        if (data.thumbnail !== null) {
            bodyFormData.append("thumbnail", data.thumbnail)
        } else {
            bodyFormData.append("thumbnail", '')
        }

        if (data.asset_file) {
            bodyFormData.append("asset_file", data.asset_file)
        }

        if (data.asset_link) {
            bodyFormData.append("asset_link", data.asset_link);
        }

        bodyFormData.append("file_type", data.file_type);
        bodyFormData.append("asset_type", data.asset_type);

        bodyFormData.append("set_logo_opacity", data.set_logo_opacity);
        bodyFormData.append("has_slogan", data.has_slogan);

        if (data.logo_opacity) {
            bodyFormData.append("logo_opacity", data.logo_opacity);
        }

        data.asset_languages.forEach((item, index) => {
            bodyFormData.append(`asset_languages[${index}]language`, item.language);
            bodyFormData.append(
                `asset_languages[${index}]description`,
                item.description
            );
            if (item.asset_file) {
                bodyFormData.append(
                    `asset_languages[${index}]asset_file`,
                    item.asset_file
                );
            }

            if (item.asset_link) {
                bodyFormData.append(`asset_languages[${index}]asset_link`, item.asset_link)
            }

            if (item.asset_mobile) {
                bodyFormData.append(`asset_languages[${index}]asset_mobile`, item.asset_mobile);
            }

            if (data.mobile_variable) {
                bodyFormData.append(`asset_languages[${index}]mobile_aspect`, item.mobile_variable.mobile_aspect);
                bodyFormData.append(`asset_languages[${index}]mobile_unit`, item.mobile_variable.mobile_unit);
                bodyFormData.append(`asset_languages[${index}]mobile_height`, item.mobile_variable.mobile_height);
                bodyFormData.append(`asset_languages[${index}]mobile_width`, item.mobile_variable.mobile_width);
                bodyFormData.append(`asset_languages[${index}]mobile_x`, item.mobile_variable.mobile_x);
                bodyFormData.append(`asset_languages[${index}]mobile_y`, item.mobile_variable.mobile_y);
            }

            if (item.asset_link){
                bodyFormData.append(`asset_languages[${index}]asset_link`, item.asset_link) // not exists in database
            }
        });

        return axios.patch(this.base_api + `assets/${assetId}/`, bodyFormData, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "multipart/form-data",
            },
        });
    }

    assetDelete(assetId) {
        return axios.delete(this.base_api + `assets/${assetId}/`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }
    /*
          assets = [3,4]
      */
    assetMultiDelete(assets) {
        return axios.delete(this.base_api + `assets/delete_assets/`, {
            data: {
                assets: assets,
            },
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    assetTypeLanguage(params) {
        return axios.get(this.base_api + "asset_types/", {
            params: params,
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    assetUpdatePairWidget(widgetId, widgetAssetId, assetId) {
        var bodyFormData = new FormData();

        bodyFormData.append("widget_asset", widgetAssetId);
        bodyFormData.append("asset", assetId);

        // return axios.patch(this.base_api + `widget_assets/${widgetId}/`, bodyFormData, {
        return axios.patch(this.base_api + `widgets/${widgetId}/update_widgetasset_asset/`, bodyFormData, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "multipart/form-data",
            },
        });
    }
}
