import React, { Component } from "react";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import swal from "sweetalert";

import JobApi from "./../../api/JobApi";
import CompanyApi from "./../../api/CompanyApi";
import GeneralApi from "../../api/GeneralApi";
import { withTranslation } from "react-i18next";
import Context from "../Context";
// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import Cookies from "js-cookie";
class LocationAddModal extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            address: "",
            name: "",
            selectedCompany: "",
            companyId: 0,
            selectedCountry: "",
            longitude: "",
            latitude: "",
            locationLanguages: [],

            companies: [],
            countries: [],
            languageList: [],

            tabKey: 1,
            selectedLanguage: 1,
            idElement: 0,
            renderTabLanguage: "",
            transPage: "modals.location_add.",
            transAlert: "alert.",

            clientID: parseInt(Cookies.get("active-client")),
        };

        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);

        this.handleChangeCompany = this.handleChangeCompany.bind(this);
        this.handleChangeCountry = this.handleChangeCountry.bind(this);
        this.handleChangeNames = this.handleChangeNames.bind(this);
        this.handleChangeAddress = this.handleChangeAddress.bind(this);
        this.handleChangeLongitude = this.handleChangeLongitude.bind(this);
        this.handleChangeLatitude = this.handleChangeLatitude.bind(this);
        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);

        this.addLocation = this.addLocation.bind(this);
        this.updateLocation = this.updateLocation.bind(this);

        this.getDetailLocation = this.getDetailLocation.bind(this);
        this.getCompanies = this.getCompanies.bind(this);

        this.renderTabLanguage = this.renderTabLanguage.bind(this);
    }

    onOpenModal() {
        const { activeSiteLang } = this.context;

        this.getCompanies();
        this.getCountries();

        this.setState(
            {
                id: this.props.positionId | 0,
                tabKey: 1,
                selectedLanguage: 1,
                languageList: activeSiteLang,
            },
            () => {
                this.setState({
                    address: "",
                });

                if (this.props.locationId) {
                    this.getDetailLocation();
                } else {
                    this.renderTabLanguage();
                }
            }
        );
    }

    getDetailLocation() {
        const obj = new JobApi();

        obj.getDetailLocation(this.props.locationId)
            .then((res) => {
                this.setState(
                    {
                        address: res.data.address,
                        name: res.data.location_type,
                        longitude: res.data.longitude,
                        latitude: res.data.latitude,
                        selectedCompany: res.data.company,
                        selectedCountry: res.data.country,
                        locationLanguages: res.data.location_languages,
                    },
                    () => {
                        this.renderTabLanguage();
                    }
                );
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    onCloseModal() {
        this.props.callback();
        this.props.onHide();
    }

    getCompanies() {
        const objComp = new CompanyApi();

        objComp
            .getAllCompanies({
                max_size: true,
            })
            .then((res) => {
                this.setState({
                    companies: res.data.results,
                });
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    getCountries() {
        const objCountry = new GeneralApi();

        objCountry
            .getCountries({
                max_size: true,
            })
            .then((res) => {
                this.setState({
                    countries: res.data.results,
                });
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    handleChangeTab(value) {
        this.setState(
            {
                selectedLanguage: value,
                tabKey: value,
            },
            () => {
                this.renderTabLanguage();
            }
        );
    }

    handleChangeAddress(id, value) {
        let location_languages = [...this.state.locationLanguages];
        let location_language = { ...location_languages[id] };
        location_language.address = value;
        location_languages[id] = location_language;

        this.setState(
            {
                address: location_languages[0].address,
                locationLanguages: location_languages,
            }
        );
    }

    handleChangeNames(id, value) {
        let location_languages = [...this.state.locationLanguages];
        let location_language = { ...location_languages[id] };
        location_language.name = value;
        location_languages[id] = location_language;

        this.setState(
            {
                name: location_languages[0].name,
                locationLanguages: location_languages,
            }
        );
    }

    handleChangeName(value) {
        this.setState({
            name: value,
        });
    }

    handleChangeCompany(value) {
        this.setState({
            selectedCompany: value,
        });
    }

    handleChangeCountry(value) {
        this.setState({
            selectedCountry: value,
        });
    }

    handleChangeLongitude(value) {
        this.setState({
            longitude: value,
        });
    }

    handleChangeLatitude(value) {
        this.setState({
            latitude: value,
        });
    }

    addLocation() {
        const { t } = this.props;
        const obJob = new JobApi();

        const request = {
            client: this.state.clientID,
            country: null,
            name: this.state.name,
            address: this.state.address,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            location_languages: this.state.locationLanguages,
        };

        obJob
            .addLocation(request)
            .then((res) => {
                swal(
                    "Success!",
                    t(this.state.transAlert + "location_add_success"),
                    "success"
                );
                this.onCloseModal();
            })
            .catch((err) => {
                swal("Error!", err.message, "error");
            });
    }

    updateLocation() {
        const { t } = this.props;
        const obJob = new JobApi();

        // const request = {
        //     company: this.state.selectedCompany || "",
        //     country: this.state.selectedCountry || "",
        //     location_type: this.state.name || "",
        //     address: this.state.address || "",
        //     longitude: this.state.longitude || "",
        //     latitude: this.state.latitude || "",
        //     location_languages: this.state.locationLanguages,
        // };

        const request = {
            client: this.state.clientID,
            country: null,
            name: this.state.name,
            address: this.state.address,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            location_languages: this.state.locationLanguages,
        };

        obJob
            .updateLocation(this.props.locationId, request)
            .then((res) => {
                swal(
                    "Success!",
                    t(this.state.transAlert + "location_update_success"),
                    "success"
                );
                this.onCloseModal();
            })
            .catch((err) => {
                swal("Error!", err.message, "error");
            });
    }

    renderTabLanguage() {
        const renderTabLanguage = this.state.languageList.map((item) => {
            var result = this.state.locationLanguages.find(
                (locationLang) => item.id === locationLang.language
            );

            if (!result) {
                result = {
                    language: item?.language?.id,
                    languageName: item.name,
                    name: "",
                    // location:"",
                    address: "",
                };
            }

            result.languageName = item.name;
            return result;
        });

        this.setState(
            {
                locationLanguages: renderTabLanguage,
            }
        );
    }

    render() {
        const { t } = this.props;
        // const position = [51.505, -0.09];

        var HeaderText = (
            <Modal.Title>
                {" "}
                {t(this.state.transPage + "add_location")}{" "}
            </Modal.Title>
        );

        var SubmitButton = (
            <Button variant="primary" onClick={() => this.addLocation()}>
                <i className="fa fa-plus">&nbsp;</i>
                {t(this.state.transPage + "add_location")}
            </Button>
        );

        if (this.props.locationId) {
            HeaderText = (
                <Modal.Title>
                    {" "}
                    {t(this.state.transPage + "update_location")}{" "}
                </Modal.Title>
            );
            SubmitButton = (
                <Button variant="primary" onClick={() => this.updateLocation()}>
                    <i className="fa fa-plus">&nbsp;</i>
                    {t(this.state.transPage + "update_location")}
                </Button>
            );
        }

        const TabLanguage = this.state.locationLanguages.map((item, index) => {
            return (
                <Tab
                    key={index}
                    eventKey={item.language}
                    title={item.languageName}
                >
                    <br></br>

                    <div className="form-group">
                        <label> Name</label>
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.locationLanguages[index].name}
                            onChange={(event) =>
                                this.handleChangeNames(
                                    index,
                                    event.target.value
                                )
                            }
                        ></input>
                        {/* { JSON.stringify( this.state.jobTypeLanguages )  } */}
                    </div>
                    <div className="form-group">
                        <label> {t("address")} </label>
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.locationLanguages[index].address}
                            onChange={(event) =>
                                this.handleChangeAddress(
                                    index,
                                    event.target.value
                                )
                            }
                        ></input>
                        {/* { JSON.stringify( this.state.jobTypeLanguages )  } */}
                    </div>
                </Tab>
            );
        });

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                onShow={this.onOpenModal}
            >
                <Modal.Header closeButton className="bg-primary">
                    {HeaderText}
                </Modal.Header>
                <Modal.Body>
                    {/* <Location /> */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Tabs
                                    id="controlled-tab-example-3"
                                    activeKey={this.state.tabKey}
                                    onSelect={(k) => this.handleChangeTab(k)}
                                >
                                    {TabLanguage}
                                </Tabs>
                            </div>
                            <hr></hr>
                            {/* <div className="form-group">
                                <label>{t("country")}</label>
                                <select className="form-control">
                                    {this.state.countries.map((item) => {
                                        return (
                                            <option
                                                value={item.id}
                                                key={item.id}
                                            >
                                                {" "}
                                                {item.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div> */}

                            <div className="form-group">
                                <label> {t("longitude")} </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.longitude}
                                    onChange={(event) =>
                                        this.handleChangeLongitude(
                                            event.target.value
                                        )
                                    }
                                ></input>
                            </div>
                            <div className="form-group">
                                <label> {t("latitude")} </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.latitude}
                                    onChange={(event) =>
                                        this.handleChangeLatitude(
                                            event.target.value
                                        )
                                    }
                                ></input>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>{SubmitButton}</Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(LocationAddModal);
