import { useState } from "react";
import Pagination from "react-js-pagination";
import css from "./BzDataTable.module.css";
import { CgChevronLeft, CgChevronRight, CgPushChevronLeft, CgPushChevronRight } from "react-icons/cg";
import { Spinner } from "react-bootstrap";

function BzDataTable(props) {
    const [paginationRowsPerPage, setPaginationRowsPerPage] = useState(10)

    const handleRowCheckbox = (e) => {
        e.stopPropagation()
    }

    const handleCheckboxAll = e => {
        var checkboxNodes = document.getElementsByName("bzdt-row-checkbox")

        for (let i = 0; i < checkboxNodes.length; i++) {
            checkboxNodes[i].checked = e.target.checked
        }

        props.onSelectedRowsChange({ selectedRows: props.data, isChecked: e.target.checked })
    }

    // const isCheckedChexboxAll = () => {
    //     var checkboxNodes = document.getElementsByName("bzdt-row-checkbox")
    //     let isCheckedAll = true

    //     for (let i = 0; i < checkboxNodes.length; i++) {
    //         if (!checkboxNodes[i].checked) {
    //             isCheckedAll = false
    //         }
    //     }

    //     return isCheckedAll
    // }

    return (
        <div>
            {
                props.progressPending
                    ? <div style={{ textAlign: "center" }}><Spinner animation="border" /></div>
                    : props.data?.length
                        ? <>
                            <div className={css.table_container}>
                                <table className={css.table}>
                                    <thead>
                                        <tr>
                                            <th>
                                                <input
                                                    type='checkbox'
                                                    onChange={handleCheckboxAll}
                                                // checked={isCheckedChexboxAll()}
                                                />
                                            </th>
                                            {
                                                props.columns?.map(col => <th>{col.name}</th>)
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            props.data?.map(row => {
                                                return <tr onClick={(e) => { props.onRowClicked(row, e) }}>
                                                    <td onClick={handleRowCheckbox}>
                                                        <input
                                                            type='checkbox'
                                                            value={row.id}
                                                            onChange={(e) => { props.onSelectedRowsChange({ selectedRows: [row], isChecked: e.target.checked }) }}
                                                            checked={props.selectableRowSelected(row)}
                                                            name="bzdt-row-checkbox"
                                                        />
                                                    </td>
                                                    {
                                                        props.columns?.map(col => <td>{col.selector(row)}</td>)
                                                    }
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div className={["mt-4", css.pagination_container].join(' ')}>
                                <div className="mr-4">
                                    <span className="mr-1">Rows per page:</span>
                                    <select
                                        value={paginationRowsPerPage}
                                        onChange={(e) => { props.onChangeRowsPerPage(parseInt(e.target.value)); setPaginationRowsPerPage(parseInt(e.target.value)) }}
                                        style={{ background: "none", border: "none", cursor: "pointer", textAlign: "right" }}
                                    >
                                        {
                                            props.paginationRowsPerPageOptions.map(item => <option value={item}>{item}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="mr-4">
                                    {paginationRowsPerPage * (props.paginationDefaultPage - 1) + 1}-{paginationRowsPerPage * props.paginationDefaultPage} of {props.paginationTotalRows}
                                </div>
                                <div className={["mr-3", css.pagination].join(' ')}>
                                    <Pagination
                                        activePage={props.paginationDefaultPage}
                                        itemsCountPerPage={paginationRowsPerPage}
                                        totalItemsCount={props.paginationTotalRows}
                                        onChange={props.onChangePage}
                                        pageRangeDisplayed={1}

                                        prevPageText={<CgChevronLeft />}
                                        firstPageText={<CgPushChevronLeft />}
                                        nextPageText={<CgChevronRight />}
                                        lastPageText={<CgPushChevronRight />}
                                        activeClass="d-none"
                                        itemClass={css.item_class}
                                        disabledClass={css.disabled_class}
                                    />
                                </div>
                            </div>
                        </>
                        : <div style={{width: "100%", backgroundColor: "white", padding: "24px", textAlign: "center"}}>There are no records to display</div>
            }

        </div>
    );
}

export default BzDataTable;