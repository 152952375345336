import React, { Component } from 'react';
import swal from "sweetalert";

import { GoogleMap, Marker } from "@react-google-maps/api";
import { Modal, Button } from "react-bootstrap";
import Card, { CardHeader, CardBody } from "../Card";

import LocationApi from "../../api/LocationApi";
// import { withTranslation } from "react-i18next";

// Context
import Context from "../../pages/Context"

class LocationPair extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            gmapsApiKey: 'AIzaSyCKqMs4hmNgoQtmIUsd7diBKHj6UV610Jg',
            mapsLib: ["drawing", "places"],

            mode: "add",
            locations: [],
            selectedLocations: [],
            selectedMainLocation: ""
        }

        this.onCloseModal = this.onCloseModal.bind(this)
        this.getLocations = this.getLocations.bind(this)
        this.handleSelectedLocations = this.handleSelectedLocations.bind(this)
        this.submitData = this.submitData.bind(this)
    }

    onOpenModal = () => {
        this.getLocations()
    }

    onCloseModal = () => {
        this.props.onHide()
        this.setState({
            selectedLocations: [],
        })
        this.props.reload()
    }

    getLocations() {
        const locationApi = new LocationApi();
        const { activeClient } = this.context
        const memberId = this.props.memberId

        locationApi
            .getAllLocations({ client: activeClient, max_size: true })
            .then(res => {
                const locations = res.data.results.filter(result => !result.member || result.member === parseInt(memberId))
                const selectedLocations = locations
                    .filter(location => location.member === parseInt(memberId))
                    .map(location => {
                        if (location.is_main) {
                            this.setState({ selectedMainLocation: location.id })
                        }
                        return location.id
                    })

                if (!this.props.noSubmitApi) {
                    this.setState({ locations: locations, selectedLocations: selectedLocations })
                } else {
                    this.setState({ locations: locations, selectedLocations: this.props.selectedLocations, selectedMainLocation: this.props.selectedMainLocation })
                }
            })
            .catch(e => {
                console.log(e)
            })
    }

    handleSelectedLocations(value, locationId) {
        let newSelectedLocations = new Set(this.state.selectedLocations)

        if (value) {
            newSelectedLocations.add(locationId)
        } else {
            newSelectedLocations.delete(locationId)
        }

        this.setState({ selectedLocations: [...newSelectedLocations] })
    }

    handleSelectedMainLocation(value, locationId) {
        this.setState({ selectedMainLocation: locationId })
    }

    submitData() {
        const locationApi = new LocationApi()
        const memberId = this.props.memberId

        if (!this.props.noSubmitApi) {
            const unselectedLocations = this.state.locations
                .filter(location => location.member === parseInt(memberId) && !this.state.selectedLocations.includes(location.id))
                .map(location => {
                    return {
                        member: null,
                        id: location.id,
                        is_main: false,
                    }
                })

            const data = this.state.selectedLocations.map(selectedLocation => {
                return {
                    member: memberId,
                    id: selectedLocation,
                    is_main: this.state.selectedMainLocation === selectedLocation,
                }
            })

            if (this.state.selectedLocations.includes(this.state.selectedMainLocation)) {
                locationApi
                    .bulkUpdateLocation([...data, ...unselectedLocations])
                    .then(() => {
                        swal(
                            "Success !",
                            "You successfully set locations !",
                            "success"
                        )
                        this.onCloseModal()
                    })
                    .catch(e => { console.log(e) })
            } else {
                swal(
                    "Error !",
                    "You selected main location incorrectly !",
                    "error"
                )
            }

        } else {
            this.props.customerDetailSetState({ selectedLocations: this.state.selectedLocations, selectedMainLocation: this.state.selectedMainLocation })
            this.props.onHide()
            this.props.reload()
        }
    }

    render() {
        const containerStyle = {
            width: "100%",
            height: "100%",
        };

        let cards = this.state.locations.map((el) => {
            let locs = el.name;
            let address = el.address;

            const center = {
                lat: parseFloat(el.latitude),
                lng: parseFloat(el.longitude),
            };

            const langId = parseInt(localStorage.getItem("langId"))
            el.location_languages.forEach(locationLanguage => {
                if (locationLanguage.id === langId) {
                    locs = locationLanguage.name
                    address = locationLanguage.address
                }
            })

            return (
                <div className="col-md-6" key={el.id}>
                    <Card className="card card-primary">
                        <CardHeader>
                            {/* <CardTitle> */}
                            <div className="row">
                                <div className="col-md-6">
                                    <input
                                        type="checkbox"
                                        id={`location-pair-${el.id}`}
                                        checked={this.state.selectedLocations.includes(el.id)}
                                        onChange={(e) => { this.handleSelectedLocations(e.target.checked, el.id) }}
                                    />
                                    &nbsp;
                                    <label htmlFor={`location-pair-${el.id}`}>{el.name}</label>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        type="radio"
                                        id={`location-pair-radio-${el.id}`}
                                        name={`location-pair-is-main`}
                                        checked={this.state.selectedMainLocation === el.id}
                                        onChange={(e) => { this.handleSelectedMainLocation(e.target.checked, el.id) }}
                                    />
                                    &nbsp;
                                    <label htmlFor={`location-pair-radio-${el.id}`}>Main location</label>
                                </div>
                            </div>
                            {/* </CardTitle> */}
                        </CardHeader>
                        <CardBody className="p-0">
                            <div className="form-group" style={{ margin: "0" }}>
                                <div className="d-flex flex-row">
                                    <div className="col-md-4" style={{ padding: "unset", height: "15vh" }}>
                                        {/* <LoadScript googleMapsApiKey={this.state.gmapsApiKey} libraries={this.state.mapsLib}> */}
                                        <GoogleMap
                                            mapContainerStyle={containerStyle}
                                            center={center}
                                            zoom={15}
                                            mapTypeId="roadmap"
                                        >
                                            <Marker position={center} />
                                        </GoogleMap>
                                        {/* </LoadScript> */}
                                    </div>
                                    <div
                                        className="col-md-8"
                                        style={{ margin: "1rem 0", padding: "0px 1rem 0px 2rem" }}
                                    >
                                        <p className="link">{locs}</p>
                                        <p className="add">{address}</p>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            );
        });

        return (
            <Modal
                show={this.props.show}
                onShow={this.onOpenModal}
                onHide={this.onCloseModal}
                size="xl"
            >
                <Modal.Header closeButton className="bg-primary">
                    <Modal.Title>Pair Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {cards}
                    </div>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.props.customerDetailSetState({ isShowCrmLocationModal: true, isShowLocationPairModal: false })
                        }}
                    >
                        Create new location
                    </Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.submitData}>Submit</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default LocationPair;