import React, { Component } from "react"
import { Modal, Button, Tabs, Tab } from "react-bootstrap"
import swal from "sweetalert"
import AlertHelper from "./../../lib/AlertHelper"
import _ from 'lodash'

import MenuApi from "./../../api/MenuApi"
import PageApi from "./../../api/PageApi"
import SectionApi from "./../../api/SectionApi"
import WidgetApi from "./../../api/WidgetApi"
import { withTranslation } from 'react-i18next';

import Context from "../../pages/Context";

class MenuItemAdd extends Component {
  static contextType = Context;
  constructor(props) {
    super(props)

    this.state = {
      menuItemId: 0,

      menuId: 0,
      name: "",
      parent: null,
      names: [],
      languageList: [],
      url: null,
      pages: [],
      sections: [],
      selectedPage: 0,
      selectedSection: 0,

      update: false,

      tabKey: 1,
      selectedLanguage: 1,
      renderTabLanguage: "",
      idElement: 0,

      widgets: [],
      widget: null,

      open: false,

      urlType: "internal",
      isDraft: false,
      transPage: "modals.menu_item_add.",
      transAlert: "alert."
    }

    this.onShowModal = this.onShowModal.bind(this)
    this.onHideModal = this.onHideModal.bind(this)
    this.getDetailMenuItem = this.getDetailMenuItem.bind(this)
    this.getLanguages = this.getLanguages.bind(this)
    this.getWidgetList = this.getWidgetList.bind(this)
    this.getPageLanguage = this.getPageLanguage.bind(this)
    this.getSectionLanguage = this.getSectionLanguage.bind(this)

    this.handleWidgetChange = this.handleWidgetChange.bind(this)
    this.handlePageLanguageChange = this.handlePageLanguageChange.bind(this)
    this.handleSectionLanguageChange = this.handleSectionLanguageChange.bind(this)
    this.handleUrlTypeChange = this.handleUrlTypeChange.bind(this)
    this.handleChangeIsPublish = this.handleChangeIsPublish.bind(this)

    this.clearInternal = this.clearInternal.bind(this)
    this.clearExternal = this.clearExternal.bind(this)
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {
    const { activeSiteLang } = this.context;

    if (activeSiteLang.length > 0) {
      if (this.state.languageList !== activeSiteLang) {
        this.setState({
          languageList: activeSiteLang
        })
      }
    }
  }

  onShowModal() {
    const _this = this

    this.getWidgetList()
      .then((res) => {
        this.setState({
          widgets: res.data.results,
        }, () => {
          _this.getPageLanguage()
        })
      })
  }

  onHideModal() {
    this.setState(
      {
        menuItemId: 0,
        menuId: 0,
        name: "",
        parent: null,
        url: "",
        widget: null,
        // languageList:[],
        urlType: "internal",
        tabKey: 1,
        selectedLanguage: 1,
        names: [],
        renderTabLanguage: "",
        isDraft: false,
        selectedPage: 0,
        selectedSection: 0,
      },
      () => this.props.onHide()
    )
  }

  getWidgetList() {
    const objWidget = new WidgetApi()

    return objWidget
      .getAllWidgets({
        internal: false,
        max_size: true,
      })
  }

  getDetailMenuItem() {
    const menuObj = new MenuApi()

    const menuItemId = this.state.menuItemId
    menuObj.getDetailMenuItem(menuItemId).then((res) => {
      this.setState(
        {
          menuId: res.data.menu,
          parent: res.data.parent,
          names: res.data.item_languages,
          widget: res.data.widget,
          urlType: res.data.urlType,
          selectedPage: res.data.item_languages[0].page,
          selectedSection: res.data.item_languages[0].section,
          isDraft: res.data.is_draft
        },
        () => {

          this.handleWidgetChange(res.data.widget)
          if (this.state.selectedPage === null) {
            this.setState({
              urlType: "external"
            })
          } else {
            //this.state.urlType = "internal"
            this.setState({
              urlType: "internal"
            }, () => {
              // this.getWidgetList()
              // this.getPageLanguage()
              this.handlePageLanguageChange(this.state.selectedPage)
            })

          }
          this.renderTabLanguage()
        }
      )
    })
  }

  getLanguages() {
    const { activeSiteLang } = this.context;

    this.setState(
      {
        menuItemId: this.props.id ? this.props.id : 0,
        menuId: this.props.menuId ? this.props.menuId : 0,
        parent: this.props.parent,
        update: this.props.update,
        tabKey: 1,
        selectedLanguage: 1,
        languageList: activeSiteLang,
        open: this.props.open,
      },
      () => {
        // alert(this.state.menuItemId)
        if (this.state.menuItemId) {
          this.getDetailMenuItem()
          //this.handleSectionLanguageChange(this.state.selectedSection);
        } else {
          this.renderTabLanguage()
        }
      }
    )
  }

  getPageLanguage() {
    const objPage = new PageApi()

    return objPage
      .pageLanguageList({
        template: localStorage.getItem("templateId"),
        language: localStorage.getItem("langId") ? localStorage.getItem("langId") : 1,
        max_size: true
      }).then((res) => {
        this.setState({
          pages: res.data?.results,
        }, () => {
          //
          this.getLanguages()
        })
      })
  }

  getSectionLanguage(pageId) {
    const objSection = new SectionApi()

    objSection
      .sectionBasedPageActive({
        // template: localStorage.getItem("templateId"),
        // language: localStorage.getItem("lang"),
        page__id: pageId,
        max_size: true
      })
      .then((res) => {
        this.setState({
          sections: res.data.results,
        })
      })
  }

  handleContentChange(id, value) {
    let names = [...this.state.names]
    let rowName = { ...names[id] }
    rowName.name = value
    names[id] = rowName

    this.setState({
      names: names,
    })
  }

  handleUrlChange(value, index) {
    const newNames = _.cloneDeep(this.state.names)

    newNames[index].path = value
    newNames[index].page = null
    newNames[index].section = null

    this.setState({
      url: value,
      selectedPage: 0,
      selectedSection: 0,
      names: newNames
    }, () => {

    })
  }

  handleUrlTypeChange(value) {
    const _this = this

    if (value === "internal") {
      _this.clearExternal()
    } else {
      _this.clearInternal()
    }
    this.setState({
      urlType: value,
    }, () => {
      // _this.getDetailWidget()
    })
  }

  handleChangeTab(value) {
    this.setState(
      {
        selectedLanguage: value,
        tabKey: value,
      },
      () => {
        this.renderTabLanguage()
      }
    )
  }

  handleWidgetChange(value) {
    this.setState({
      widget: value,
    })
  }

  handlePageLanguageChange(value) {
    if (value === "") {
      value = null
    }
    this.setState({
      selectedPage: value,
    },
      () => {
        this.getSectionLanguage(value)
        this.renderTabLanguage()
      }
    )
  }

  handleSectionLanguageChange(value) {
    if (value === "") {
      value = null
    }
    this.setState({
      selectedSection: value,
    },
      () => {
        this.renderTabLanguage()
      }
    )
  }

  handleChangeIsPublish(value) {
    this.setState({
      isDraft: value
    })
  }

  renderTabLanguage() {
    const renderTabLanguage = this.state.languageList.map((item, index) => {
      var findName = this.state.names.find((textLang, idx) => item.id === textLang.language)

      if (this.state.update) {
        return {
          id: findName?.id,
          language: item?.language?.id,
          name: findName ? findName.name : "",
          languageName: item.name,
          page: parseInt(this.state.selectedPage),
          section: parseInt(this.state.selectedSection),
          path: this.state.names[index]?.path,
        }
      } else {
        return {
          language: item?.language?.id,
          name: findName ? findName.name : "",
          languageName: item.name,
          page: parseInt(this.state.selectedPage),
          section: parseInt(this.state.selectedSection),
          path: null,
        }
      }
    })

    this.setState(
      {
        names: renderTabLanguage,
      }
    )
  }

  addmenuItem() {
    const { t } = this.props;

    const objMenuItem = new MenuApi()
    const data = {
      menu: this.state.menuId,
      item_languages: this.state.names,
      parent: this.state.parent,
      //url:this.state.url,
      //selectedPage: null,
      widget: this.state.widget,
      urlType: this.state.urlType,
      is_draft: this.state.isDraft
    }

    objMenuItem
      .menuItemAdd(data)
      .then((res) => {
        swal("Success !", t(this.state.transAlert + "menu_item_add_success"), "success")
        this.props.getMenuDetail()
        this.props.onHide()
      })
      .catch((err) => {
        const objAlert = new AlertHelper()
        objAlert.errorHandlerAlert(err.response.data)
      })
  }

  updateMenuItem() {
    const { t } = this.props;
    const objMenuItem = new MenuApi()

    const menuItemId = this.state.menuItemId

    const data = {
      menu: this.state.menuId,
      item_languages: this.state.names,
      parent: this.state.parent,
      urlType: this.state.urlType,
      widget: this.state.widget,
      is_draft: this.state.isDraft
    }

    objMenuItem
      .menuItemUpdate(menuItemId, data)
      .then((res) => {
        swal("Success !", t(this.state.transAlert + "menu_item_update_success"), "success")
        this.props.getMenuDetail()
        this.props.onHide()
      })
      .catch((err) => {
        const objAlert = new AlertHelper()
        const resErr = err?.response?.data ? err?.response?.data : err.message
        objAlert.errorHandlerAlert(resErr)
      })
  }

  clearInternal() {
    this.setState({
      selectedPage: null,
      selectedSection: null
    }, () => {
      const names = [...this.state.names]
      for (const key in names) {
        names[key].path = null;
      }
      this.setState({
        names: names
      })
    })
  }

  clearExternal() {
    let urlLanguages = [...this.state.names]
    urlLanguages.map((item, id) => {

      item.path = null
      urlLanguages[id] = item

      return item
    })

    this.setState({
      names: urlLanguages
    })
  }

  render() {
    const { t } = this.props;
    var HeaderText
    var BtnSubmit

    if (!this.state.update) {
      HeaderText = t(this.state.transPage + "title_add")
      BtnSubmit = (
        <Button variant='primary' onClick={() => this.addmenuItem()}>
          <i className='fa fa-plus'>&nbsp;</i>
          {t('add')}
        </Button>
      )
    } else {
      HeaderText = t(this.state.transPage + "title_update")
      BtnSubmit = (
        <Button variant='primary' onClick={() => this.updateMenuItem()}>
          <i className='fa fa-edit'>&nbsp;</i>
          {t('update')}
        </Button>
      )
    }

    const WidgetList = this.state.widgets.map((item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      )

    })

    const PageLangList = this.state.pages.map((item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      )

    })

    const SectionLangList = this.state.sections.map((item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      )

    })

    const TabLanguage = this.state.names.map((item, index) => {
      return (
        <Tab key={index} eventKey={item.language} title={item.languageName}>
          <br></br>

          <div className='form-group'>
            <label> {t('name')} </label>
            <textarea className='form-control' onChange={(event) => this.handleContentChange(index, event.target.value)} value={this.state.names[index].name}></textarea>
          </div>
        </Tab>
      )
    })

    var URLInput = null

    if (this.state.urlType === "internal") {
      URLInput = (
        <div>
          <div className='form-group'>
            <label style={{ cssTransform: "capitalized" }}> {t('page')} </label>
            <select value={this.state.selectedPage} className='form-control'
              onChange={(event) => {
                this.handlePageLanguageChange(event.target.value)
              }}
            >
              <option value={null} selected={this.state.selectedPage === null}> -- {t(this.state.transPage + "select_page")} -- </option>
              {PageLangList}
            </select>
          </div>

          <div className='form-group'>
            <label style={{ cssTransform: "capitalized" }}> {t('section')} </label>
            <select value={this.state.selectedSection} className='form-control' id='selectionForm'
              onChange={(event) => {
                this.handleSectionLanguageChange(event.target.value)
              }}
            >
              <option value={null} selected={this.state.selectedSection === null}> -- {t(this.state.transPage + "select_section")} -- </option>
              {SectionLangList}
            </select>
          </div>
        </div>
      )
    } else if (this.state.urlType === "external") {
      URLInput = this.state.names.map((item, index) => {
        return (
          <div className='form-group'>
            <label> Url {item.languageName}</label>
            <input value={item.path ? item.path : ""} type='text' className='form-control' onChange={(event) => this.handleUrlChange(event.target.value, index)}></input>
          </div>
        )
      })
    }

    let isCheckedI = this.state.urlType === "internal" ? true : false
    let isCheckedE = this.state.urlType === "external" ? true : false

    return (
      <Modal show={this.props.show} onHide={() => this.onHideModal()} onShow={() => this.onShowModal()}>
        <Modal.Header closeButton className='bg-primary'>
          <Modal.Title> {HeaderText} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs id='controlled-tab-example-3' activeKey={this.state.tabKey} onSelect={(k) => this.handleChangeTab(k)}>
            {TabLanguage}
          </Tabs>

          <div className='form-group'>
            <label> {t('url_type')} </label>
            <br></br>
            <label htmlFor='internal'>
              <input id='internal' type='radio' name='urlTypeInternal' value={"internal"}
                checked={isCheckedI}
                onChange={() => this.handleUrlTypeChange("internal")}
              ></input>{" "}
              {t('internal')}
            </label>
            &nbsp;&nbsp;&nbsp;
            <label htmlFor='external'>
              <input id='external' type='radio' name='urlTypeExternal' value={"external"}
                checked={isCheckedE}
                onChange={() => this.handleUrlTypeChange("external")}
              // onChange={() =>
              //   this.setState({
              //     urlType: "external",
              //   })
              // }
              ></input>{" "}
              {t('external')}
            </label>
          </div>

          {URLInput}

          <div className='form-group'>
            <label> {t('widget')} </label>
            <select className='form-control'
              value={this.state.widget}
              onChange={(event) => {
                this.handleWidgetChange(event.target.value)
              }}
            >
              <option value='' key=''>
                -- {t(this.state.transPage + "select_widget")} --
              </option>
              {WidgetList}
            </select>
          </div>
          <div className='form-group'>
            <label> {t('publicity')} </label>
            <br></br>

            <input id='publish' type='radio' name='publicity' value={"publish"} defaultChecked={this.state.isDraft === false} onChange={() => this.handleChangeIsPublish(false)}></input>{" "}
            <label htmlFor='publish' className="mr-3">
              {t('publish')}
            </label>

            <input id='draft' type='radio' name='publicity' value={"draft"} defaultChecked={this.state.isDraft === true} onChange={() => this.handleChangeIsPublish(true)}></input>{" "}
            <label htmlFor='draft'>
              {t('draft')}
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>{BtnSubmit}</Modal.Footer>
      </Modal>
    )
  }
}

export default withTranslation()(MenuItemAdd)
