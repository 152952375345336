import React, { Component } from 'react';
import { Button, Dropdown } from "react-bootstrap"

import Card, { CardHeader, CardBody } from "../../Card";
// import AssetsElement from './../../../pages/Elements/AssetsElement';
import swal from "sweetalert"
import AlertHelper from "./../../../lib/AlertHelper"
import ArticleAssetAddModal from "./../../../components/Modal/ArticleAssetAddModal"
import AssetDeleteConfirm from "./../../../components/Modal/AssetDeleteConfirm"
import ArticleAssetChangeModal from "./../../../components/Modal/ArticleAssetChangeModal"
import AssetOrderModal from '../../Modal/AssetOrderModal';

import ArticleApi from '../../../api/ArticleApi';
import WidgetApi from '../../../api/WidgetApi';
// import ArticleDeleteConfirm from '../../Modal/ArticleDeleteConfirm';
// import AssetApi from "./../../../api/AssetApi"
import { withTranslation } from 'react-i18next';
import UserContext from '../../../pages/Context'

class ArticleAssetListUpdate extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props)
        this.state = {
            articleId: 0,
            articleAssetId: 0,
            selectedAsset: [],
            selectedOneAsset: {},
            deleteDisable: true,

            showAssetChangeModal: false,
            showAssetModal: false,
            showConfirmModal: false,
            showOrderAssets: false,

            loadingModal: "",

            transPage: "widgets.article_asset_update.",
            transAlert: "alert."
        }

        this.setSelectedAsset = this.setSelectedAsset.bind(this)
        this.setShowAssetModal = this.setShowAssetModal.bind(this)
        this.setShowConfirmModal = this.setShowConfirmModal.bind(this)
        this.setShowAssetChangeModal = this.setShowAssetChangeModal.bind(this)
        this.setShowOrderAssets = this.setShowOrderAssets.bind(this)
        this.confirmAssetOrder = this.confirmAssetOrder.bind(this)
        this.deleteSelectedAsset = this.deleteSelectedAsset.bind(this)
    }

    componentDidMount() {
        this.setState({
            articleId: this.props.articleId
        })
    }

    setSelectedAsset(asset, value) {
        var lastSelected = []
        lastSelected = [...this.state.selectedAsset]
        var indexDelete = -1
        var stateSelected = "selectedAsset"

        if (value === true) {

            lastSelected.push(asset)
        } else {
            this.state[stateSelected].forEach((item, index) => {
                if (item.id === asset.id) {
                    indexDelete = index
                }
            })

            lastSelected.splice(indexDelete, 1)
        }

        this.setState({
            [stateSelected]: lastSelected,

        }, () => {

            if (this.state[stateSelected].length > 0) {
                this.setState({
                    deleteDisable: false,
                })
            } else {

                this.setState({
                    deleteDisable: true,

                })
            }
        })
    }

    setShowAssetChangeModal() {
        this.setState({
            showAssetChangeModal: !this.state.showAssetChangeModal
        })
    }

    setShowAssetModal() {
        this.setState({
            showAssetModal: !this.state.showAssetModal
        })
    }

    setShowConfirmModal() {
        this.setState({
            showConfirmModal: !this.state.showConfirmModal
        })
    }

    deleteSelectedAsset() {
        const { t } = this.props;
        const objArticle = new ArticleApi()
        this.setState({
            loadingModal: <div className="overlay d-flex justify-content-center align-items-center">
                <i className="fas fa-2x fa-sync fa-spin"></i>
            </div>
        })

        var selectedAssetId = this.state.selectedAsset.map(item => {
            // return (item.asset)
            return (item.asset.id)
        })

        objArticle.articleAssetUnpair(this.state.articleId, selectedAssetId)
            .then(res => {

                return this.props.getDetailArticle()

            })
            .then(resB => {
                this.setState({
                    selectedAsset: [],
                    loadingModal: "",
                    showConfirmModal: !this.state.showConfirmModal
                }, () => {
                    swal("Success!", t(this.state.transAlert + "delete_asset_in_article_success"), "success")
                })
            })
            .catch(err => {
                const objAlert = new AlertHelper()
                objAlert.errorHandlerAlert(err.response.data)
            })

    }

    setShowOrderAssets = () => {
        this.setState({
            showOrderAssets: !this.state.showOrderAssets,
        });
    };

    confirmAssetOrder = (articleId, dataOrder, prop) => {
        const objWidget = new WidgetApi();

        const arrId = [];
        if (dataOrder) {
            dataOrder.forEach((or) => {
                arrId.push(or.id);
            });
        }

        if (arrId.length > 0) {
            const data = {
                article_assets: arrId,
            };

            objWidget
                .widgetArticleOrder(articleId, data)
                .then((res) => {
                    this.props.getDetailArticle()
                })
                .catch((err) => {
                    console.log(err.message);
                });
        }
    };

    render() {
        const { t } = this.props;
        const { employee_privileges } = this.context;
        const isOnlyMonitor = employee_privileges?.filter(e => e.id === 5).length > 0;

        const Assets = this.props.article_assets.map((item, index) => {

            var media = <img width={"100%"} alt="" src={item.asset.asset_file}></img>
            if (item.asset.asset_type_name === "URL") {
                media = <iframe title={`asset-media-embedded-${index}`} width={"100%"} src={item.asset_link} allowFullScreen></iframe>
            } else if (item.asset.asset_type_name === "Video") {
                media = <video width={"100%"} height={"50%"} title="Video" controls>
                    <source src={item.asset_file} />
                </video>
            }

            return (
                <div className="col-md-2" key={item.asset.id}>

                    <Card id={item.asset.name + "-" + item.asset.id}>
                        <CardHeader className="bg-primary">
                            <div className="form-check form-check-inline">
                                <input type="checkbox" value="" onClick={(event) => this.setSelectedAsset(item, event.currentTarget.checked)} className="form-check-input" id={"label" + item.id}></input>&nbsp;
                                <label className="form-check-label" htmlFor={"label" + item.asset.id}><b >{item.asset.name}</b> </label>
                            </div>
                            <div className="card-tools">
                                <Dropdown >
                                    <Dropdown.Toggle id="dropdown-basic" as={"span"}>
                                        <a href={"#" + item.name + "-" + item.id} style={{ "color": "black" }}><i className="fa fa-ellipsis-v"></i></a>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => {
                                                this.setState({
                                                    selectedOneAsset: {
                                                        id: item.asset,
                                                        asset_name: item.asset_name,
                                                        asset_file: item.asset_file,
                                                        asset_languages: item.asset_languages,
                                                        description: item.description,

                                                    },
                                                    articleAssetId: item.id
                                                }, () => {
                                                    this.setShowAssetChangeModal()
                                                })
                                            }}
                                        > {t(this.state.transPage + "update_pair")} </Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            this.setState({
                                                selectedAsset: this.state.selectedAsset.concat([item])
                                            }, () => {
                                                this.setShowConfirmModal()
                                            })
                                        }}>{t('delete')}</Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </CardHeader>
                        <CardBody>

                            <div className="container-fluid" align="center">
                                {media}
                            </div>

                        </CardBody>


                    </Card>
                </div>

            )
        })

        return (
            <div>
                <div className="row">
                    <div className="float-right col-md-6">
                        &nbsp;
                    </div>
                    <div className="float-right col-md-6">
                        <Button variant="danger" disabled={this.state.deleteDisable || isOnlyMonitor} onClick={() => this.setShowConfirmModal()} className="float-right" > <i className="fa fa-trash"></i> {t(this.state.transPage + "delete_all")}</Button>
                        <Button variant="primary" disabled={isOnlyMonitor} className="float-right" style={{ marginRight: 10 }} onClick={() => this.setShowAssetModal()}> <i className="fa fa-plus"></i> {t(this.state.transPage + "add_new_asset")} </Button>
                        {
                            this.props.article_assets && this.props.article_assets.length > 1 &&
                            <Button variant="primary" className="float-right" style={{ marginRight: 10 }} onClick={this.setShowOrderAssets}>Order Assets</Button>
                        }
                    </div>
                    <div className="clearfix"></div>
                </div>
                <div>&nbsp;</div>

                <div style={{ border: "3px dashed grey", padding: 5, minHeight: 260 }}>
                    <div className="row">

                        {Assets}

                    </div>
                </div>
                <ArticleAssetAddModal
                    type="update"
                    getDetailArticle={this.props.getDetailArticle}
                    articleId={this.state.articleId}
                    show={this.state.showAssetModal}
                    onHide={this.setShowAssetModal}
                ></ArticleAssetAddModal>
                <ArticleAssetChangeModal
                    type="update"
                    getDetailArticle={this.props.getDetailArticle}
                    articleId={this.state.articleId}
                    articleAssetId={this.state.articleAssetId}
                    assetId={this.state.selectedOneAsset}
                    show={this.state.showAssetChangeModal}
                    onHide={this.setShowAssetChangeModal}
                ></ArticleAssetChangeModal>
                <AssetDeleteConfirm

                    selectedAssets={this.state.selectedAsset}
                    deleteSelectedAsset={this.deleteSelectedAsset}
                    show={this.state.showConfirmModal}
                    onHide={this.setShowConfirmModal}
                    loadingModal={this.state.loadingModal}
                >
                </AssetDeleteConfirm>
                <AssetOrderModal
                    show={this.state.showOrderAssets}
                    onHide={this.setShowOrderAssets}
                    data={this.props.article_assets.map(articleAsset => ({ ...articleAsset, name: articleAsset.asset.name }))}
                    widgetId={this.props.articleId}
                    prop={this.props}
                    confirmAssetOrder={this.confirmAssetOrder}
                />
            </div>
        );
    }
}

export default withTranslation()(ArticleAssetListUpdate);