import React, { Component } from 'react';
// import { Link } from "react-router-dom"
import { Modal, Card, Button } from 'react-bootstrap';
import swal from 'sweetalert';
import AlertHelper from './../../lib/AlertHelper';
import Pagination from 'react-js-pagination';
import Cookies from 'js-cookie';

// import MyCard,{ CardHeader, CardTitle, CardBody, CardFooter  } from "./../Card";

import AssetApi from '../../api/AssetApi';
import WidgetApi from '../../api/WidgetApi';

import { shortSentence } from '../../lib/StringHelper';
import docImage from '../Assets/Docx.svg';
import ekselImage from '../Assets/Xlsx.svg';
import pdfImage from '../Assets/Pdf.svg';
import defaultImage from '../Assets/default-image.svg';
import videoImage from '../Assets/Video.svg';
import svgImage from '../Assets/Svg.svg';
// import ArticleApi from '../../api/ArticleApi';

import Context from '../../pages/Context';

class WidgetAssetAddModal extends Component {
	static contextType = Context;
	constructor(props) {
		super(props);
		this.state = {
			articleId: 0,
			assets: [],
			selectedAssets: [],
			asset_types: [],
			pairDisable: true,
			loadingModal: '',
			search: '',
			clientID: parseInt(Cookies.get('active-client')),

			asset_type: 0,
			inputType: 'FILE',
			activePage: 1,
			limitPageData: 30,
			totalCount: 0,
			co_md: '',
		};
		this.getAllAssets = this.getAllAssets.bind(this);
		this.handleSelectedAsset = this.handleSelectedAsset.bind(this);
		this.onShowModal = this.onShowModal.bind(this);
		this.onHideModal = this.onHideModal.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		// this.addAssetArticle = this.addAssetArticle.bind(this)
		this.getAssetTypeLanguage = this.getAssetTypeLanguage.bind(this);
		this.handleChangeFileType = this.handleChangeFileType.bind(this);
		this.getAllAssetsAfterFilter = this.getAllAssetsAfterFilter.bind(this);
	}

	onShowModal() {
		const w = window.screen.width;

		if (w <= 1440) {
			this.setState(
				{
					limitPageData: 20,
					co_md: 'col-md-3',
				},
				() => {
					this.getAllAssets();
				}
			);
		} else if (w > 1441) {
			this.setState(
				{
					limitPageData: 30,
					co_md: 'col-md-2',
				},
				() => {
					this.getAllAssets();
				}
			);
		}
		this.setState({
			articleId: this.props.articleId,
			selectedAssets: [],
		});
		this.getAssetTypeLanguage();
		//alert("props => "+this.props.articleId)
	}

	onHideModal() {
		this.setState({
			selectedAssets: [],
			pairDisable: true,
		});
		this.props.onHide();
	}

	getAllAssets() {
		const objAsset = new AssetApi();
		const langId = localStorage.getItem('langId');
		const { activeClient } = this.context;

		return objAsset
			.getAllAssets({
				client: activeClient,
				language: langId ? langId : 1,
				page_size: this.state.limitPageData,
				page: this.state.activePage,
				search: this.state.search,
			})
			.then((res) => {
				this.setState({
					totalCount: res.data.count,
					assets: res.data.results,
				});
			});
	}

	getAssetTypeLanguage() {
		const obj = new AssetApi();
		const lang = localStorage.getItem('langId');

		obj
			.assetTypeLanguage({
				language: lang ? lang : 1,
			})
			.then((res) => {
				this.setState({
					asset_types: res.data.results,
				});
			});
	}

	handlePageChange(pageNumber) {
		this.setState(
			{
				activePage: pageNumber,
			},
			() => {
				this.getAllAssets();
			}
		);
	}

	handleSelectedAsset(asset, value) {
		var lastSelected = [...this.state.selectedAssets];
		var indexDelete = -1;
		// var stateSelected = ""

		if (value === true) {
			lastSelected.push(asset);
		} else {
			this.state.selectedAssets.forEach((item, index) => {
				if (item.id === asset.id) {
					indexDelete = index;
				}
			});

			lastSelected.splice(indexDelete, 1);
		}

		this.setState(
			{
				selectedAssets: lastSelected,
			},
			() => {
				if (this.state.selectedAssets.length > 0) {
					this.setState({
						pairDisable: false,
					});
				} else {
					this.setState({
						pairDisable: true,
					});
				}
			}
		);
	}

	addAssetWidget() {
		const objWidget = new WidgetApi();

		this.setState({
			loadingModal: (
				<div className='overlay d-flex justify-content-center align-items-center'>
					<i className='fas fa-2x fa-sync fa-spin'></i>
				</div>
			),
		});

		const assets = this.state.selectedAssets.map((item) => {
			return item.id;
		});

		objWidget
			.widgetAssetPair(this.props.widgetId, assets)
			.then((res) => {
				//this.props.setSelectedAssetAdd(this.state.selectedAssets)
				this.props.getWidgetDetail(this.props.widgetId);
				this.setState({
					loadingModal: '',
				});
				this.props.onHide();
				swal('Success !', 'You sucessfully added asset into this widget !', 'success');
			})
			.catch((err) => {
				const objAlert = new AlertHelper();
				const resErr = err?.response?.data ? err?.response?.data : err.message;
				objAlert.errorHandlerAlert(resErr);
			});
	}

	handleSearchChange(value) {
		this.setState(
			{
				activePage: 1,
				search: value,
			},
			() => {
				this.getAllAssets();
			}
		);
	}

	getAllAssetsAfterFilter(input) {
		const objAsset = new AssetApi();
		const langId = localStorage.getItem('langId');

		return objAsset
			.getAllAssetsAfterFilter({
				client: this.state.clientID,
				language: langId ? langId : 1,
				asset_type: input,
				search: this.state.search,
				page_size: this.state.limitPageData,
				page: this.state.activePage,
			})
			.then((res) => {
				this.setState({
					assets: res.data.results,
					totalCount: res.data.count,
				});
			});
	}

	handleChangeFileType(value) {
		var inputType = 'FILE';
		this.setState(
			{
				asset_type: value,
				inputType: inputType,
				activePage: 1,
				search: '',
			},
			() => {
				this.getAllAssetsAfterFilter(value);
			}
		);
	}

	render() {
		var media, url;
		const AssetList =
			this.state.assets.length > 0 ? (
				this.state.assets.map((item, index) => {
					if (item.asset_file) {
						url = item.asset_file;
					} else {
						url = '';
					}

					let typeDoc = ['.docx', '.doc'];
					let typePdf = '.pdf';
					let typeXlx = ['.xls', '.xlxs', '.xlxm', '.xlxb', '.xltx', '.xltm'];
					let typeSvg = '.svg';

					let resultDoc = url.split().some((i) => typeDoc.includes(i));
					let resultPdf = url.includes(typePdf);

					let resultEksel = url.split().some((i) => typeXlx.includes(i));
					let resultSvg = url.includes(typeSvg);

					if (item.asset_type.id === 1) {
						media = (
							<Card.Img
								title={`asset-media-image-${index}`}
								style={{
									height: '126px',
									width: '100%',
									objectFit: 'cover',
									borderRadius: '6px',
								}}
								src={item.file_small ? item.file_small : item.asset_file}
							></Card.Img>
						);
					} else if (item.asset_type.id !== 1) {
						media = (
							<>
								{resultPdf ? (
									<img
										style={{
											height: '126px',
											width: '100%',
											objectFit: 'contain',
											borderRadius: '6px',
										}}
										src={item.thumbnail_file ? item.thumbnail_file : pdfImage}
										alt=''
									/>
								) : resultDoc ? (
									<img
										style={{
											height: '126px',
											width: '100%',
											objectFit: 'contain',
											borderRadius: '6px',
										}}
										src={item.thumbnail_file ? item.thumbnail_file : docImage}
										alt=''
									/>
								) : resultEksel ? (
									<img
										style={{
											height: '126px',
											width: '100%',
											objectFit: 'contain',
											borderRadius: '6px',
										}}
										src={item.thumbnail_file ? item.thumbnail_file : ekselImage}
										alt=''
									/>
								) : resultSvg ? (
									<img
										style={{
											height: '126px',
											width: '100%',
											objectFit: 'contain',
											borderRadius: '6px',
										}}
										src={item.thumbnail_file ? item.thumbnail_file : svgImage}
										alt=''
									/>
								) : (
									<img
										style={{
											height: '126px',
											width: '100%',
											objectFit: 'contain',
											borderRadius: '6px',
										}}
										src={item.thumbnail_file ? item.thumbnail_file : defaultImage}
										alt=''
									/>
								)}
							</>
						);
					} else {
						if (item.asset_link) {
							media = (
								<>
									{item.thumbnail_file ? (
										<Card.Img
											title={`media-${item.asset_type_name}-${index}`}
											style={{
												height: '126px',
												width: '100%',
												objectFit: 'cover',
												borderRadius: '6px',
											}}
											src={item.thumbnail_file}
										></Card.Img>
									) : (
										<img
											style={{
												height: '126px',
												width: '100%',
												objectFit: 'contain',
												borderRadius: '6px',
											}}
											src={videoImage}
											alt=''
										/>
									)}
								</>
							);
						}
					}

					return (
						<div key={item.id} className={this.state.co_md}>
							<Card>
								<Card.Header className='' style={{ padding: '1rem 0.5rem 0' }}>
									<div
										className='form-check form-check-inline'
										style={{
											position: 'relative',
											width: '100%',
											overflow: 'hidden',
										}}
									>
										<input
											type='radio'
											className='form-check-input'
											id={'label-modal-' + item.id}
											onClick={(event) => this.handleSelectedAsset(item, event.target.checked)}
										></input>
										<label
											className='form-check-label'
											style={{
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												maxWidth: '100%',
											}}
											htmlFor={'label-modal-' + item.id}
										>
											{item.name}
										</label>
									</div>
								</Card.Header>

								<Card.Body style={{ padding: '1rem .5rem' }}>{media}</Card.Body>

								<Card.Footer
									style={{
										fontSize: 16,
										margin: 'auto auto 1rem .5rem',
										padding: '0',
										textAlign: 'left',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										maxWidth: '100%',
										backgroundColor: 'white',
									}}
								>
									<div align='center' style={{ fontSize: 12 }}>
										{shortSentence(item.description, 6)} ...
									</div>
								</Card.Footer>
							</Card>
						</div>
					);
				})
			) : (
				<div className='col-md-12 center-block'>
					<h4 className='text-center'>There is no Assets</h4>
				</div>
			);

		return (
			<Modal show={this.props.show} onHide={() => this.onHideModal()} size='xl' onShow={() => this.onShowModal()}>
				{this.state.loadingModal}
				<Modal.Header closeButton className='bg-primary'>
					Select Widget Asset's
				</Modal.Header>

				<Modal.Body>
					<div className='d-flex flex-row' width='100%' style={{ padding: '0 2rem' }}>
						<div className='form-group' style={{ width: '30%', marginRight: '1rem' }}>
							<label>File Type</label>
							<select
								className='form-control'
								defaultValue={this.state.inputType}
								onChange={(event) => {
									this.handleChangeFileType(event.target.value);
								}}
							>
								<option value=''> -- Select File Type -- </option>
								{this.state.asset_types.map((item) => {
									return <option value={item.id}> {item.name} </option>;
								})}
							</select>
						</div>

						<div className='form-group' style={{ width: '30%' }}>
							<label>Search</label>
							<input
								type='text'
								placeholder='Search...'
								className='form-control'
								name='search'
								value={this.state.search}
								onChange={(event) => this.handleSearchChange(event.target.value)}
							/>
						</div>
					</div>
					<div style={{ padding: '0 2rem' }}>
						{' '}
						<hr />
					</div>
					<div className='clearfix'></div>
					<div className='row' style={{ padding: '0 2rem' }}>
						{AssetList}
					</div>
					<div id='pagination_custom' style={{ float: 'left', padding: '0 2rem', margin: '2rem 0' }}>
						<Pagination
							activePage={this.state.activePage}
							itemsCountPerPage={this.state.limitPageData}
							totalItemsCount={this.state.totalCount}
							pageRangeDisplayed={4}
							onChange={this.handlePageChange.bind(this)}
							itemClass='page-item'
							linkClass='page-link'
							hideDisabled={true}
							prevPageText='Prev'
							nextPageText='Next'
						/>
					</div>
					<div className='clearfix'></div>
				</Modal.Body>
				<Modal.Footer>
					<Button disabled={this.state.pairDisable} onClick={() => this.addAssetWidget()}>
						{' '}
						<i className='fa fa-paperclip'>&nbsp;</i> Pair These Assets{' '}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WidgetAssetAddModal;
