import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
import AlertHelper from "../../lib/AlertHelper";

import WidgetApi from "../../api/WidgetApi";
import SectionApi from "../../api/SectionApi";
import { withTranslation } from "react-i18next";
import Cookies from "js-cookie";

class JobCollectionAddModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobCollectionName: "",
            transPage: "modals.job_collection_add.",
            transAlert: "alert.",
            clientID: parseInt(Cookies.get("active-client")),
        };

        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.getDetailJobCollection = this.getDetailJobCollection.bind(this);

        this.addJobCollection = this.addJobCollection.bind(this);
        this.updateJobCollection = this.updateJobCollection.bind(this);
    }

    onOpenModal() {
        this.setState(
            {
                id: this.props.widgetId | 0,
            },
            () => {
                if (this.props.widgetId) {
                    this.getDetailJobCollection();
                } else {
                    this.setState({ jobCollectionName: "" });
                }
            }
        );
    }

    onCloseModal() {
        this.setState(
            {
                jobCollectionName: "",
            },
            () => {
                this.props.onHide();
            }
        );
    }

    handleChangeName(value) {
        this.setState({
            jobCollectionName: value,
        });
    }

    getDetailJobCollection() {
        const widgetApi = new WidgetApi();

        widgetApi.getDetailWidget(this.props.widgetId).then((res) => {
            const jobCollectionName = res.data.name;
            this.setState({
                jobCollectionName: jobCollectionName,
            });
        });
    }

    addJobCollection() {
        const sectionApi = new SectionApi();

        // const urls = this.state.urlLanguages.map(item => {
        //     return {
        //         id: null,
        //         language: item.language,
        //         page: !this.state.selectedPageId ? null : this.state.selectedPageId,
        //         section: !this.state.selectedSectionId ? null : this.state.selectedSectionId,
        //         path: item.path
        //     }
        // })

        const data = {
            name: this.state.jobCollectionName,
            category: 5,
            is_internal: "False",
            client: this.state.clientID,
            // template: parseInt(this.props.templateId)
            // section: sections,
            // urls: urls
        };

        sectionApi
            .sectionWidgetAdd(data)
            .then((res) => {
                this.props.callbackSuccess();
                this.props.onHide();
                swal(
                    "Success !",
                    "You Sucessfully add Job Collection",
                    "success"
                );
            })
            .catch((err) => {
                const objAlert = new AlertHelper();
                const resErr = err.message;
                objAlert.errorHandlerAlert(resErr);
            });
    }

    updateJobCollection() {
        const sectionApi = new SectionApi();

        const data = {
            id: this.props.widgetId,
            name: this.state.jobCollectionName,
            category: 5,
            is_internal: "False",
            template: parseInt(this.props.templateId),
            // section: sections,
            // urls: urls
        };

        sectionApi
            .sectionWidgetUpdate(data)
            .then((res) => {
                this.props.callbackSuccess();
                this.props.onHide();
                swal(
                    "Success !",
                    "You Sucessfully add Job Collection",
                    "success"
                );
            })
            .catch((err) => {
                const objAlert = new AlertHelper();
                const resErr = err.message;
                objAlert.errorHandlerAlert(resErr);
            });
    }

    render() {
        const { t } = this.props;
        var HeaderText = (
            <Modal.Title>
                {" "}
                {t(this.state.transPage + "add_job_collection")}{" "}
            </Modal.Title>
        );

        var SubmitButton = (
            <Button variant="primary" onClick={() => this.addJobCollection()}>
                <i className="fa fa-plus">&nbsp;</i>
                {t(this.state.transPage + "add_job_collection")}
            </Button>
        );

        if (this.props.widgetId) {
            HeaderText = (
                <Modal.Title>
                    {" "}
                    {t(this.state.transPage + "update_job_collection")}{" "}
                </Modal.Title>
            );
            SubmitButton = (
                <Button
                    variant="primary"
                    onClick={() => this.updateJobCollection()}
                >
                    <i className="fa fa-plus">&nbsp;</i>
                    {t(this.state.transPage + "update_job_collection")}
                </Button>
            );
        }

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                onShow={this.onOpenModal}
            >
                <Modal.Header closeButton className="bg-primary">
                    {HeaderText}
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label> {t("job_collection")} </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.jobCollectionName}
                                    onChange={(event) =>
                                        this.handleChangeName(
                                            event.target.value
                                        )
                                    }
                                ></input>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>{SubmitButton}</Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(JobCollectionAddModal);
