import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import _ from 'lodash';

import Context from "./Context";

import HeaderNew from "./HeaderNew";
import SideBarNew from "./SidebarNew";

import LoadingPage from "../../pages/LoadingPage";
import "./DashboardNew.css";

class DashboardNew extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            currentRoute: "/",
            showSidebar: false,
            showingSidebar: () => {
                this.showSidebar();
            },
            siteStructure: false,

            activeSidebarItem: null,
            setActiveSidebarItem: (value) => this.setState({ activeSidebarItem: value })
        };
    }

    componentDidMount() {
        this.setState({
            // showSidebar: localStorage.getItem("sidebar") !== "" ? localStorage.getItem("sidebar") : true
            currentRoute: this.props.location.pathname,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                currentRoute: this.props.location.pathname,
            });
        }
    }

    showSidebar = () => {
        this.setState((prevState) => {
            return { showSidebar: !prevState.showSidebar };
        });
        localStorage.setItem("sidebar", !this.state.showSidebar);
    };

    render() {
        let children;
        const myUser = this.props.user;

        if (myUser) {
            const groups = (myUser.groups || []).map(e => e.id)
            if (!myUser.is_superuser && groups.includes(8)) {
                children = <div>{this.props.children}</div>;
            } else {
                const subsc = this.props?.clientInformation.length ? (this.props?.clientInformation || []).map(e => e.id) : []
                if (myUser?.is_superuser && _.intersection([1, 2], subsc).length > 0) {
                    if (this.props.languageList && this.props.activeSiteLang && this.props.adminSiteLanguages && this.props.activeContentLang) {
                        children = <div>{this.props.children}</div>;
                    } else {
                        if (
                            this.state.currentRoute &&
                            this.state.currentRoute !== "/" &&
                            (this.state.currentRoute.includes("/superadmin") || this.state.currentRoute.includes("/language"))
                        ) {
                            children = <div>{this.props.children}</div>;
                        } else {
                            children = <LoadingPage />;
                        }
                    }
                } else {
                    children = <div>{this.props.children}</div>;
                }
            }
        }

        return (
            <Context.Provider value={this.state}>
                <div id="DashboardNew" className="wrapper">
                    <HeaderNew
                        showSidebar={this.showSidebar}
                        changeLanguage={this.props.changeLanguage}
                        sites={this.props.sites}
                    />
                    <SideBarNew
                        showSidebar={this.showSidebar}
                        {...this.props}
                        showSidebarValue={this.state.showSidebar}
                        currentRoute={this.state.currentRoute}
                    />
                  
                    {children}
                </div>
            </Context.Provider>
        );
    }
}

export default withRouter((props) => <DashboardNew {...props} />);
