import React, { Component } from "react";
import { Modal, Card, Button } from "react-bootstrap";
import swal from "sweetalert";
import AlertHelper from "./../../lib/AlertHelper";
import Pagination from "react-js-pagination";

import AssetApi from "../../api/AssetApi";

import { shortSentence } from "../../lib/StringHelper";
import ArticleApi from "../../api/ArticleApi";
import { withTranslation } from "react-i18next";
import Cookies from "js-cookie";

import docImage from "../Assets/Docx.svg";
import ekselImage from "../Assets/Xlsx.svg";
import pdfImage from "../Assets/Pdf.svg";
import defaultImage from "../Assets/default-image.svg";
import videoImage from "../Assets/Video.svg";
import svgImage from "../Assets/Svg.svg";

class ArticleAssetAddModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articleId: 0,
            assets: [],
            selectedAssets: [],
            asset_types: [],
            pairDisable: true,
            loadingModal: "",

            activePage: 1,
            limitPageData: 30,
            totalCount: 0,
            clientID: parseInt(Cookies.get("active-client")),

            search: "",

            transPage: "modals.article_asset_add.",
            transAlert: "alert.",
            co_md: "",
        };
        this.getAllAssets = this.getAllAssets.bind(this);
        this.handleSelectedAsset = this.handleSelectedAsset.bind(this);
        this.onShowModal = this.onShowModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.addAssetArticle = this.addAssetArticle.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    onShowModal() {
        const w = window.screen.width;

        if (w <= 1440) {
            this.setState(
                {
                    limitPageData: 20,
                    co_md: "col-md-3",
                },
                () => {
                    this.getAllAssets();
                }
            );
        } else if (w > 1441) {
            this.setState(
                {
                    limitPageData: 30,
                    co_md: "col-md-2",
                },
                () => {
                    this.getAllAssets();
                }
            );
        }

        this.getAssetTypeLanguage();
        this.setState({
            articleId: this.props.articleId,
        });
    }

    onCloseModal() {
        this.setState(
            {
                assets: [],
                selectedAssets: [],
            },
            () => {
                this.props.onHide();
            }
        );
    }

    getAllAssets() {
        const objAsset = new AssetApi();
        const langId = localStorage.getItem('langId')

        return objAsset
            .getAllAssets({
                client: this.state.clientID,
                language: langId ? langId : 1,
                page_size: this.state.limitPageData,
                search: this.state.search,
                page: this.state.activePage,
            })
            .then((res) => {
                this.setState({
                    assets: res.data.results,
                    totalCount: res.data.count,
                });
            });
    }

    getAssetTypeLanguage = () => {
        const obj = new AssetApi();
        const lang = localStorage.getItem('langId')

        obj.assetTypeLanguage({
            language: lang ? lang : 1
        }).then((res) => {
            this.setState({
                asset_types: res.data.results,
            });
        });
    }

    getAllAssetsAfterFilter(input) {
        const objAsset = new AssetApi();
        const langId = localStorage.getItem('langId')

        return objAsset
            .getAllAssetsAfterFilter({
                client: this.state.clientID,
                language: langId ? langId : 1,
                asset_type: input,
                search: this.state.search,
                page_size: this.state.limitPageData,

                // page_size: 30,
                page: this.state.activePage,
            })
            .then((res) => {
                this.setState({
                    assets: res.data.results,
                    totalCount: res.data.count,
                });
            });
    }

    handleSelectedAsset(asset, value) {
        var lastSelected = [...this.state.selectedAssets];
        var indexDelete = -1;
        // var stateSelected = ""

        if (value === true) {
            lastSelected.push(asset);
        } else {
            this.state.selectedAssets.forEach((item, index) => {
                if (item.id === asset.id) {
                    indexDelete = index;
                }
            });

            lastSelected.splice(indexDelete, 1);
        }

        this.setState(
            {
                selectedAssets: lastSelected,
            },
            () => {
                if (this.state.selectedAssets.length > 0) {
                    this.setState({
                        pairDisable: false,
                    });
                } else {
                    this.setState({
                        pairDisable: true,
                    });
                }
            }
        );
    }

    addAssetArticle() {
        const { t } = this.props;
        const objArticle = new ArticleApi();

        this.setState({
            loadingModal: (
                <div className="overlay d-flex justify-content-center align-items-center">
                    <i className="fas fa-2x fa-sync fa-spin"></i>
                </div>
            ),
        });

        if (this.props.type === "add") {
            this.props.setSelectedAssetAdd(this.state.selectedAssets);
            // this.props.getDetailArticle()
            this.setState({
                loadingModal: "",
            });
            this.onCloseModal();
            swal(
                "Success !",
                t(this.state.transAlert + "add_asset_into_article_success"),
                "success"
            );
        } else if (this.props.type === "update") {
            const ids = this.state.selectedAssets.map((item) => {
                return item.id;
            });

            const dt = {
                articleId: this.state.articleId,
                ids: ids,
            };

            objArticle
                .articleAssetPair(dt.articleId, dt.ids)
                .then((res) => {
                    this.props.getDetailArticle();
                    this.setState({
                        loadingModal: "",
                    });
                    this.onCloseModal();
                    swal(
                        "Success !",
                        t(
                            this.state.transAlert +
                            "add_asset_into_article_success"
                        ),
                        "success"
                    );
                })
                .catch((err) => {
                    const objAlert = new AlertHelper();
                    objAlert.errorHandlerAlert(err.message);
                });
        }
    }

    handlePageChange(pageNumber) {
        this.setState(
            {
                activePage: pageNumber,
            },
            () => {
                this.getAllAssets();
            }
        );
    }

    handleSearchChange(value) {
        this.setState(
            {
                search: value,
                activePage: 1,
            },
            () => {
                this.getAllAssets();
            }
        );
    }

    handleChangeFileType(value) {
        if (value) {
            this.setState(
                {
                    asset_type: value,
                    inputType: "",
                    activePage: 1,
                    search: "",
                },
                () => {
                    this.getAllAssetsAfterFilter(value);
                }
            );
        }
        else {
            this.getAllAssets()
        }
    }

    render() {
        const { t } = this.props;
        let media, url;

        const AssetList =
            this.state.assets.length > 0 ? (
                this.state.assets.map((item, index) => {
                    if (item.asset_file) {
                        url = item.asset_file;
                    } else {
                        url = "";
                    }

                    let typeDoc = [".docx", ".doc"];
                    let typePdf = ".pdf";
                    let typeXlx = [".xls", ".xlxs", ".xlxm", ".xlxb", ".xltx", ".xltm"];
                    let typeSvg = ".svg";

                    let resultDoc = url.split().some((i) => typeDoc.includes(i));
                    let resultPdf = url.includes(typePdf);

                    let resultEksel = url.split().some((i) => typeXlx.includes(i));
                    let resultSvg = url.includes(typeSvg);

                    if (item.asset_type.id === 1) {
                        media = (
                            <Card.Img
                                title={`asset-media-image-${index}`}
                                style={{
                                    height: "126px",
                                    width: "100%",
                                    objectFit: "cover",
                                    borderRadius: "6px",
                                }}
                                src={item.asset_file}
                            ></Card.Img>
                        );
                    } else if (item.asset_type.id !== 1) {
                        media = (
                            <>
                                {resultPdf ? (
                                    <img
                                        style={{
                                            height: "126px",
                                            width: "100%",
                                            objectFit: "contain",
                                            borderRadius: "6px",
                                        }}
                                        src={item.thumbnail_file ? item.thumbnail_file : pdfImage}
                                        alt=""
                                    />
                                ) : resultDoc ? (
                                    <img
                                        style={{
                                            height: "126px",
                                            width: "100%",
                                            objectFit: "contain",
                                            borderRadius: "6px",
                                        }}
                                        src={item.thumbnail_file ? item.thumbnail_file : docImage}
                                        alt=""
                                    />
                                ) : resultEksel ? (
                                    <img
                                        style={{
                                            height: "126px",
                                            width: "100%",
                                            objectFit: "contain",
                                            borderRadius: "6px",
                                        }}
                                        src={item.thumbnail_file ? item.thumbnail_file : ekselImage}
                                        alt=""
                                    />
                                ) : resultSvg ? (
                                    <img
                                        style={{
                                            height: "126px",
                                            width: "100%",
                                            objectFit: "contain",
                                            borderRadius: "6px",
                                        }}
                                        src={item.thumbnail_file ? item.thumbnail_file : svgImage}
                                        alt=""
                                    />
                                ) : (
                                    <img
                                        style={{
                                            height: "126px",
                                            width: "100%",
                                            objectFit: "contain",
                                            borderRadius: "6px",
                                        }}
                                        src={
                                            item.thumbnail_file ? item.thumbnail_file : defaultImage
                                        }
                                        alt=""
                                    />
                                )}
                            </>
                        );
                    } else {
                        if (item.asset_link) {
                            media = (
                                <>
                                    {item.thumbnail_file ? (
                                        <Card.Img
                                            title={`media-${item.asset_type_name}-${index}`}
                                            style={{
                                                height: "126px",
                                                width: "100%",
                                                objectFit: "cover",
                                                borderRadius: "6px",
                                            }}
                                            src={item.thumbnail_file}
                                        ></Card.Img>
                                    ) : (
                                        <img
                                            style={{
                                                height: "126px",
                                                width: "100%",
                                                objectFit: "contain",
                                                borderRadius: "6px",
                                            }}
                                            src={videoImage}
                                            alt=""
                                        />
                                    )}
                                </>
                            );
                        }
                    }

                    return (
                        <div key={item.id} className={this.state.co_md}>
                            <Card>
                                <Card.Header className="" style={{ padding: "1rem 0.5rem 0" }}>
                                    <div
                                        className="form-check form-check-inline"
                                        style={{
                                            position: "relative",
                                            width: "100%",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={"label-modal-" + item.id}
                                            onClick={(event) =>
                                                this.handleSelectedAsset(item, event.target.checked)
                                            }
                                        ></input>
                                        <label
                                            className="form-check-label"
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                maxWidth: "100%",
                                            }}
                                            htmlFor={"label-modal-" + item.id}
                                        >
                                            {item.name}
                                        </label>
                                    </div>
                                </Card.Header>

                                <Card.Body style={{ padding: "1rem .5rem" }}>
                                    {media}
                                </Card.Body>

                                <Card.Footer
                                    style={{
                                        fontSize: 16,
                                        margin: "auto .5rem 1rem .5rem",
                                        padding: "0",
                                        textAlign: "left",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "100%",
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div align="left" style={{ fontSize: 12 }}>
                                        {shortSentence(item.description, 6)} ...
                                    </div>
                                </Card.Footer>
                            </Card>
                        </div>
                    );
                })
            ) : (
                <div className="col-md-12 center-block">
                    <h4 className="text-center">There is no Assets</h4>
                </div>
            );
        return (
            <Modal
                show={this.props.show}
                onHide={() => this.onCloseModal()}
                size="xl"
                onShow={() => this.onShowModal()}
            >
                {this.state.loadingModal}
                <Modal.Header closeButton className="bg-primary">
                    {t(this.state.transPage + "title")}
                </Modal.Header>

                <Modal.Body>
                    <div className="d-flex flex-row" width="100%" style={{ padding: "0 2rem" }}>
                        <div className="form-group" style={{ width: "30%", marginRight: "1rem" }}>
                            <label>File Type</label>
                            <select className="form-control" defaultValue={this.state.inputType}
                                onChange={(event) => {
                                    this.handleChangeFileType(event.target.value);
                                }}
                            >
                                <option value=""> -- Select File Type -- </option>
                                {this.state.asset_types.map((item) => {
                                    return <option value={item.id}> {item.name} </option>;
                                })}
                            </select>
                        </div>

                        <div className="form-group" style={{ width: "30%" }}>
                            <label>Search</label>
                            <input
                                type="text"
                                placeholder={t(this.state.transPage + "search_asset")}
                                className="form-control"
                                name="search"
                                value={this.state.search}
                                onChange={(event) =>
                                    this.handleSearchChange(event.target.value)
                                }
                            />
                        </div>
                    </div>

                    <div style={{ float: "right" }}>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.limitPageData}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={4}
                            onChange={this.handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>
                    <div className="clearfix"></div>
                    <div className="row" style={{ padding: "0 2rem" }}>{AssetList}</div>
                    <div style={{ float: "right" }}>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.limitPageData}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={4}
                            onChange={this.handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={this.state.pairDisable}
                        onClick={() => this.addAssetArticle()}
                    >
                        {" "}
                        <i className="fa fa-paperclip">&nbsp;</i>{" "}
                        {t(this.state.transPage + "pair_these_assets")}{" "}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(ArticleAssetAddModal);
