import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"
import { withTranslation } from 'react-i18next';

class DeleteJobTypeConfirm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            transPage: "modals.job_type_delete."
        }

        this.deleteSelectedJobType = this.deleteSelectedjobType.bind(this)
        this.onOpenModal = this.onOpenModal.bind(this)
        // this.onHideModal = this.onHideModal.bind(this)
    }

    deleteSelectedjobType() {
        // dari props method nya
        this.props.deleteSelectedJobType() 
    }

    onOpenModal() {
    }

    
    render() {
        const {t} = this.props;
        const listJobTypes = this.props.selectedJobTypes.map(item => {
            return (<li key={item.id}>{ item.name }</li>)
        })

        return (
            <Modal show={this.props.show} onHide={() => this.props.onHide()} onShow={() => this.onOpenModal()}>
                {this.props.loadingModal}
                <Modal.Header closeButton className="bg-danger">
                    <Modal.Title>{t(this.state.transPage + "title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t(this.state.transPage + "confirmation_text")}</p>
                    <ul>
                        {listJobTypes}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.props.onHide()}>
                        {t('cancel')}
                    </Button>
                    <Button variant="danger" onClick={() => this.deleteSelectedJobType()}>
                        <i className="fa fa-trash">&nbsp;</i>
                        {t(this.state.transPage + "delete_job_type")}
                    </Button>   
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(DeleteJobTypeConfirm);