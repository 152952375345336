import React, { Component } from "react";
import { Button, Alert } from "react-bootstrap";
// import queryString from "query-string";
import { withCookies, Cookies } from "react-cookie";
import AuthApi from "./../api/AuthApi";
import { setUserSession } from "./../lib/Common";
import { instanceOf } from "prop-types";
import { Helmet } from "react-helmet";

class Login extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
    };
    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = {
            username: "",
            password: "",
            alert: "",
            client: cookies.get("client"),
            error: {},
        };

        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);

        this.setError = this.setError.bind(this);
        this.setAlert = this.setAlert.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }

    componentDidMount() {
        // if (this.props.location.search) {
        //   let search = this.props.location.search ? this.props.location.search : "";
        //   let resultQuery = queryString.parse(search);
        //   let data = {
        //     email: resultQuery.user,
        //     password: resultQuery.password,
        //     token: resultQuery.key,
        //   };
        //   if (resultQuery.redirect) {
        //     this.handleLoginAuth(data);
        //   } else {
        //     // redirect into previous url
        //   }
        // }
    }

    setError(err) {
        this.setState({
            error: err,
        });
    }

    setAlert(message) {
        this.setState({
            alert: message,
        });
    }

    handleUsernameChange(value) {
        this.setState({
            username: value,
        });
    }

    handlePasswordChange(value) {
        this.setState({
            password: value,
        });
    }

    handleLogin() {
        const objAuth = new AuthApi();

        const data = {
            email: this.state.username,
            password: this.state.password
        }

        let lang = localStorage.getItem("lang");
        let langId = localStorage.getItem("langId");

        objAuth
            .loginBzPublish(data)
            .then((res) => {
                if ((!lang || !langId) && res.data.client.languages.length > 0){
                    localStorage.setItem('lang', res.data.client.languages[0].abbreviation)
                    localStorage.setItem('langId', res.data.client.languages[0].id)
                } else {
                    localStorage.setItem('lang', 'en')
                    localStorage.setItem('langId', 1)
                }

                if (res.data.user?.is_superuser || !res.data.user?.is_superuser) {
                    this.setAlert(
                        <Alert variant="success"> You Successfully Login </Alert>
                    );
                    setUserSession(res.data.key, res.data.user);
                    this.props.getDataUser(
                        res.data.key,
                        res.data.user,
                        res.data.client
                    );
                    // window.location.href = "/";
                } else {
                    this.setAlert(
                        <Alert variant="danger">
                            {" "} You don't have a privilege to access this website{" "}
                        </Alert>
                    );
                }
            })
            .catch((err) => {
                console.log(err)
                var msg = "";

                if (err?.response?.data?.non_field_errors) {
                    msg = err?.response?.data?.non_field_errors.map((item) => {
                        return <li>{item}</li>;
                    });
                }

                if (err?.response?.data?.email) {
                    msg = err?.response?.data?.email
                }

                this.setAlert(
                    <Alert variant="danger" onClose={() => this.setAlert("")} dismissible>
                        {msg}
                    </Alert>
                );
            });
    }

    handleSubmit(e) {
        this.handleLogin();
        e.preventDefault();
    }

    render() {
        let data;

        if (this.state.client) {
            data = this.state.client.name;
        } else {
            data = "BZ Publish";
        }

        return (
            <div className="login-page" style={{ minHeight: 400 }}>
                <Helmet title={data} />
                <div className="login-box" style={{ height: "50vh" }}>
                    <div className="login-logo">
                        <a href="../../index2.html">
                            <b>BZ</b>Publish
            </a>
                    </div>
                    <div className="card login-card-body">
                        <p className="login-box-msg">
                            {this.state.alert || "Sign in to start your"}{" "}
                            <a
                                href="/test-route"
                                style={{ textDecoration: "none", color: "black" }}
                            >
                                {" "}
                session
              </a>
                        </p>

                        <form onSubmit={(e) => this.handleSubmit(e)}>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    value={this.state.username}
                                    className="form-control"
                                    placeholder="Username"
                                    onChange={(event) =>
                                        this.handleUsernameChange(event.target.value)
                                    }
                                ></input>
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <i className="fas fa-user"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input
                                    type="password"
                                    value={this.state.password}
                                    className="form-control"
                                    placeholder="Password"
                                    onChange={(event) =>
                                        this.handlePasswordChange(event.target.value)
                                    }
                                ></input>
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <i className="fas fa-lock"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    <div className="icheck-primary">
                                        <input type="checkbox" id="remember" />
                    &nbsp;
                    <label htmlFor="remember">Remember Me</label>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <Button type="submit" className="btn btn-primary btn-block">
                                        Sign In
                  </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withCookies(Login);
