import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Card, CardTitle, CardHeader } from "./../Card"
import ListCardRow from "./ListCardRow";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    var titleName = "Title";
    var labelTitle = "Title List : ";
    var className = "active";
    var tools = this.props.tools;
    var checkBox = this.props.checkBox;

    if (this.props.type === "section") {
      titleName = this.props.data.name || "Page Title";
      labelTitle = "Section List : ";
    } else if (this.props.type === "widget") {
      titleName = this.props.data.name || "Section Title";
      labelTitle = "Widget List : ";
    } else if (this.props.type === "element") {
      titleName = this.props.data.name || "Widget Title";
      if (this.props.data.from === "widget-page") {
        titleName = titleName + " \n id: " + this.props.data.id;
        if (!this.props.data.internal) {
          className = " bg-success";
        }
      }
      labelTitle = "Category : ";
    }

    const ItemList = this.props.items.map((item) => {
      return (
        <ListCardRow
          data={this.props.data}
          type={this.props.type}
          key={item}
          isLink={false}
          val={item}
        ></ListCardRow>
      );
    });

    var linkTo = "#";
    if (this.props.linkTo) {
      linkTo = this.props.linkTo;
    }

    if (this.props.data.is_internal) {
      className = " bg-secondary";
      tools = true; // KEMBALIKAN LAGI KE FALSE
    }

    var setItemId = this.props.setItemId;

    if (!setItemId) {
      setItemId = function () {
        return true;
      };
    }

    return (
      <ul className="list-group" style={{ marginBottom: 20 }}>
          {/* <ListCardRow
          className="list-card-row"
          header={false}
          data={this.props.data}
          checkBox={checkBox}
          setSelectedItem={this.props.setSelectedItem}
          setItemId={setItemId}
        ></ListCardRow> */}
        <ListCardRow
          className="list-card-row"
          header={false}
          tools={tools}
          data={this.props.data}
        //   checkBox={false}
          setSelectedItem={this.props.setSelectedItem}
          setItemId={setItemId}
        ></ListCardRow>
        <Link to={linkTo}>
          <ListCardRow
            key={0}
            header={true}
            className={className}
            val={<b>{titleName} </b>}
            data={this.props.data}
            checkBox={false}
            setSelectedItem={this.props.setSelectedItem}
            setItemId={setItemId}
          ></ListCardRow>
          <ListCardRow key={"title"} val={<b>{labelTitle}</b>}></ListCardRow>

          {ItemList}
        </Link>
      </ul>
    );
  }
}

// export { ListCardRow }
export default index;
