import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class PageDeleteConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transPage: "modals.page_delete.",
    };

    this.deleteSelectedPage = this.deleteSelectedPage.bind(this);
    // this.onOpenModal = this.onOpenModal.bind(this)
    // this.onHideModal = this.onHideModal.bind(this)
  }

  deleteSelectedPage() {
    this.props.deleteSelectedPage();
  }

  render() {
    const { t } = this.props;
    const listPages = this.props.selectedPages.map((item) => {
      return <li key={item.id}>{item.name}</li>;
    });

    return (
      <Modal show={this.props.show} onHide={() => this.props.onHide()}>
        <Modal.Header closeButton className="bg-danger">
          <Modal.Title>{t(this.state.transPage + "title")} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p> {t(this.state.transPage + "confirmation_text")} </p>
          <ul>{listPages}</ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.props.onHide()}>
            {t("cancel")}
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              this.deleteSelectedPage();
            }}
          >
            <i className="fa fa-trash">&nbsp;</i>
            {t(this.state.transPage + "delete_page")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(PageDeleteConfirm);
