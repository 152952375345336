import axios from "axios"
import config from "./../config/config"
export default class WidgetApi {

    // base_api = "http://192.168.13.177:7000/"
    base_api = config.base_api
    token = config.token

    getDetailWidget(widgetId, params) {

        return axios.get(this.base_api + `widgets/${widgetId}/`, {
            params: params,
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    getAllWidgets(params) {
        return axios.get(this.base_api + `widgets/`, {
            params: params,
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    getWidgetCategories() {
        return axios.get(this.base_api + `category_widgets/`, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    widgetDelete(widget_ids) {
        return axios.delete(this.base_api + "widgets/delete_widgets/", {
            data: {
                widgets: widget_ids
            },
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    widgetIndexUpdate(sectionId, widget_ids) {
        return axios.patch(this.base_api + `sections/${sectionId}/update_sectionwidget_indexes/`, {
            section_widgets: widget_ids,
        }, {

            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    widgetArticleUnpair(widget_id, articles) {
        return axios.patch(this.base_api + `widgets/${widget_id}/unpair_articles/`, {
            articles: articles // [19,22]
        }, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }


    widgetAssetPair(widgetId, asset) {
        // return axios.patch(this.base_api+`widget_asset_pair/`,{
        return axios.patch(this.base_api + `widgets/${widgetId}/pair_assets/`, {
            assets: asset // [19,22]
        }, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    widgetAssetUnpair(widgetId, asset) {
        return axios.patch(this.base_api + `widgets/${widgetId}/unpair_assets/`, {
            assets: asset // [19,22]
        }, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    widgetTextDetail(textId) {
        return axios.get(this.base_api + `text_elements/${textId}/`, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    widgetTextAdd(widgetId, name, text_languages) {
        return axios.post(this.base_api + `text_elements/`, {
            "widget": widgetId,
            name: name,
            text_languages: text_languages
        }, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    widgetTextUpdate(data) {
        return axios.patch(this.base_api + `text_elements/${data.textId}/`, {

            name: data.name,
            input_type: data.input_type,
            text_languages: data.text_languages
        }, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    widgetTextDelete(widgetId, ids) {
        return axios.delete(this.base_api + `widgets/${widgetId}/delete_text_elements/`, {
            data: {
                text_ids: ids
            },
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    widgetElementIndexUpdate(data) {
        return axios.patch(this.base_api + `widget_element_index_update/`, data, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    widgetElementUnpair(data) {
        return axios.patch(this.base_api + `widget_element_unpair/`, data, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    // http://beta-api.bzpublish.com/widgets/35/widget_asset_index_update/ update index asset
    // http://beta-api.bzpublish.com/widgets/2185/widget_article_index_update/ update index widget
    _widgetArticleOrder(id, data) {
        return axios.patch(this.base_api + `widgets/${id}/widget_article_index_update/`, data, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    widgetArticleOrder(id, data) {
        return axios.patch(this.base_api + `articles/${id}/update_articleasset_index/`, data, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    widgetAssetOrder(id, data) {
        return axios.patch(this.base_api + `widgets/${id}/update_widgetasset_index/`, data, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    widgetArticleIndexUpdate(id, data){
        return axios.patch(this.base_api + `widgets/${id}/update_widgetarticle_index/`, data, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }
}
