import React, { useState, useCallback } from 'react';
import { Card } from './Card';
import update from 'immutability-helper';
const style = {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '10px'
};
const ArticleOrder = (props) => {

    // const [cards, setCards] = useState([
    //     {
    //         id: 1,
    //         name: 'Write a cool name',
    //     },
    //     {
    //         id: 2,
    //         name: 'Make it',
    //     },
    //     {
    //         id: 3,
    //         name: 'Write README',
    //     },
    //     {
    //         id: 4,
    //         name: 'Create some examples',
    //     },
    //     {
    //         id: 5,
    //         name: 'Test',
    //     },
    //     {
    //         id: 6,
    //         name: '???',
    //     },
    //     {
    //         id: 7,
    //         name: 'PROFIT',
    //     },
    //     {
    //         id: 8,
    //         name: 'Test1',
    //     },
    //     {
    //         id: 9,
    //         name: 'Test2',
    //     },
    //     {
    //         id: 10,
    //         name: 'Test3',
    //     },
    //     {
    //         id: 11,
    //         name: 'Test4',
    //     },
    // ]);

    const [cards, setCards] = useState(props.data)

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        const dragCard = cards[dragIndex];
        setCards(update(cards, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
            ],
        }));
    }, [cards], props.orderChanges(cards)
    );

    const renderCard = (card, index) => {
        return (<Card key={card.id} index={index} id={card.id} text={card.article? card.article?.name : (card?.name)} moveCard={moveCard} />);
    };
    return (<>
        <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
    </>);

};

export default ArticleOrder
