import axios from "axios";
import config from "./../config/config";
// import Cookies from "js-cookie";

class SectionApi {
  // base_api = "http://192.168.13.177:7000/"
  base_api = config.base_api;
  token = config.token;
  //   key = Cookies.get("key");

  async getPageSections(pageId, params) {
    return await axios.get(this.base_api + `pages/${pageId}/`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  async getPageSectionsNew(pageId) {
    return await axios.get(this.base_api + `sections/?page__id=${pageId}`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getCatalogType() {
    return axios.get(this.base_api + `section_catalogs/types/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  updatePageSectionsSetting(id, data) {
    return axios.patch(
      this.base_api + `pages/${id}`,
      {
        is_draft: data.is_draft,
        page_type: data.page_type,
        params_qty: data.param_qty,
        has_params: data.has_param,
      },
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  getAllSections() {
    return axios.get(this.base_api + "sections/", {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getDetailSection(sectionId) {
    return axios.get(this.base_api + `sections/${sectionId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  //   getPageSectionsNew(pageId) {
  //     return axios.get(this.base_api + `sections/?page__id=${pageId}`, {
  //       headers: {
  //         Authorization: `Token ${this.token}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //   }

  sectionAddNew(data) {
    return axios.post(
      this.base_api + "sections/",
      {
        page: data.page,
        parent: data.parent,
        section_languages: data.lang,
      },
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  sectionAddCatalog(formData) {
    return axios.post(this.base_api + "sections/", formData, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  sectionAddCatalogNew(FormData) {
    let url = this.base_api + "section_catalogs/bulk_update/";
    return axios.post(url, FormData, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  sectionCatalogUpdateNew(sectionId, formData) {
    return axios.put(
      this.base_api + `section_catalogs/${sectionId}/`,
      formData,
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  sectionUpdateNew(sectionId, data) {
    return axios.patch(
      this.base_api + `sections/${sectionId}/`,
      {
        // page: data.page,
        // parent: data.parent,
        menu: data.menu,
        is_dark: data.is_dark,
        is_draft: data.is_draft === "true",
        has_shortcut: data.has_shortcut,
        section_languages: data.lang,
      },
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  sectionCatalogUpdate(sectionId, formData) {
    return axios.put(this.base_api + `sections/${sectionId}/`, formData, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  sectionCatalogDistribute(sectionId) {
    return axios.post(
      this.base_api + `sections/${sectionId}/distribute/`,
      {},
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  sectionCatalogDistributeNew(sectionId) {
    return axios.post(
      this.base_api + `section_catalogs/${sectionId}/distribute/`,
      {},
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  sectionWidgetPair(sectionId, widgets) {
    return axios.patch(
      this.base_api + `section_widget_pair/`,
      {
        section_id: sectionId,
        widget_ids: widgets,
      },
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  sectionDetailWidgetPair(sectionId, name, widget) {
    //http://54.255.70.35/section_widgets/
    return axios.post(
      this.base_api + `section_widgets/`,
      {
        section: sectionId,
        name: name,
        widget: widget.id,
      },
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  updateSectionDetailWidgetPair(sectionDetailId, sectionId, name, widget) {
    //http://54.255.70.35/section_widgets/
    return axios.put(
      this.base_api + `section_widgets/${sectionDetailId}/`,
      {
        section: sectionId,
        name: name,
        widget: widget,
      },
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  sectionDetailWidget(sectionDetailId) {
    return axios.get(this.base_api + `section_widgets/${sectionDetailId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  sectionWidgetAdd(data) {
    // let widget;
    // if (data.template) {
    //   widget = {
    //     name: data.name,
    //     category: data.category,
    //     section: data.section,
    //     is_internal: data.is_internal,
    //     urls: data.urls,
    //     status: data.status,
    //     template: data.template,
    //   };
    // } else {
    //   widget = {
    //     name: data.name,
    //     category: data.category,
    //     section: data.section,
    //     is_internal: data.is_internal,
    //     urls: data.urls,
    //     status: data.status,
    //   };
    // }

    return axios.post(this.base_api + `widgets/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  // sectionWidgetAdd(name, category, sections) {
  //     // {
  //     //     "category": 2,
  //     //     "section_ids": [3,4],
  //     //     "name": "Widget 7"
  //     // }

  //     return axios.post(this.base_api+`widgets/`,{

  //         "category": category,
  //         "section_ids": sections,
  //         "name": name

  //     },{
  //         headers:{
  //             "Authorization":`Token ${this.token}`,
  //             "Content-Type":"application/json"
  //         }
  //     })
  // }

  sectionWidgetUpdate(data) {
    // {
    //     "category": 2,
    //     "section_ids": [3,4],
    //     "name": "Widget 7"
    // }

    // {
    //   name: data.name,
    //   category: data.category,
    //   section: data.section,
    //   is_internal: data.is_internal,
    //   urls: data.urls,
    //   status: data.status,
    //   template: data.template,
    // }

    return axios.patch(this.base_api + `widgets/${data.id}/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  widgetDelete(id) {
    return axios.delete(this.base_api + `widgets/${id}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  sectionWidgetDelete(sectionId, sectionWidgetIds) {
    return axios.delete(this.base_api + `sections/${sectionId}/delete_sectionwidgets/`, {
      data: {
        section_widget_ids: sectionWidgetIds,
      },
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  sectionIndexUpdateNew(ids) {
    return axios.patch(
      this.base_api + `sections/index_update/`,
      {
        section_ids: ids,
      },
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  sectionCatalogListNew(params) {
    return axios.get(this.base_api + `section_catalogs/?folder_exists=True`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  sectionDeleteNew(data) {
    return axios.post(
      this.base_api + "section_catalogs/bulk_delete/",
      {
        catalogs_ids: data,
      },
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  sectionCatalogDetailNew(sectionId) {
    return axios.get(this.base_api + `section_catalogs/${sectionId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  sectionCatalogDetail(sectionId) {
    return axios.get(this.base_api + `sections/${sectionId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  sectionLanguage(params) {
    return axios.get(this.base_api + `sections/`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  sectionBasedPageActive(params) {
    return axios.get(this.base_api + `sections/`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  sectionBulkUpdate(data) {
    return axios.post(this.base_api + `sections_bulk_update/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  sectionBulkUpdateNew(data) {
    return axios.post(this.base_api + `section_catalogs/bulk_update/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  generateMetadata(sectionId) {
    return axios.post(this.base_api + `sections/${sectionId}/generate_metadata/`, {}, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default SectionApi;
