import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";

import ValueContext from "./../section/Context";
import classes from "./SidebarNew.module.css";

class MenuItem extends Component {
  static contextType = ValueContext;

  constructor(props) {
    super(props);
    this.state = {
      iconReport: "rotate(0deg)",
      bgReport: "",
    };
    this.state.toggleIcon = () => {
      this.setState({
        iconReport:
          this.state.iconReport === "rotate(0deg)"
            ? "rotate(-90deg)"
            : "rotate(0deg)",
      });
    };
    this.state.toggleBgRep = () => {
      this.setState({
        bgReport: this.state.bgReport === "" ? "#343434" : "",
      });
    };
  }

  render() {
    const contextValue = this.context;
    const icon = this.props.icon ? this.props.icon : "fa fa-circle";
    const iconc = this.props.iconc ? this.props.iconc : "";

    // const reportIcon = 'fa fa-chart-line'
    const url = this.props.url ? this.props.url : "";
    // const sidebarOpen = this.props.showSidebarValue ? "sidebar-max" : ""
    // const sidebar = localStorage.getItem("sidebar")

    let activeStyle = !this.props.showSidebarValue
      ? classes.padActive
      : classes.padInActive;
    let activeItem = this.props.isActive ? "sd1-active-items" : "";

    let content;
    if (this.props.onlyPages) {
      content = (
        <li
          key={this.props.idx}
          className={`nav-item`}
          data-widget="pushmenu"
          onClick={() => {
            contextValue.showingSidebar();
            if (url && url !== "#") {
              this.props.history.push(url);
            }
          }}
        >
          <div
            className={["nav-link", activeStyle].join(" ")}
            onClick={this.props.onClick}
            data-toggle={this.props.showSidebarValue ? "tooltip" : ""}
            data-placement="right"
            title={this.props.showSidebarValue ? this.props.children : ""}
          >
            <i className={`${icon} nav-icon `}></i>
            <p className={activeItem}>{this.props.children}</p>
          </div>
        </li>
      );
    } else {
      content =
        url !== "" ? (
          <li
            key={this.props.idx}
            className={`nav-item`}
            onClick={this.props.onClick}
          >
            <NavLink
              to={url}
              className={["nav-link", activeStyle].join(" ")}
              data-toggle={this.props.showSidebarValue ? "tooltip" : ""}
              data-placement="right"
              title={this.props.showSidebarValue ? this.props.children : ""}
            >
              <div
                className=""
                style={{ display: "inline-flex", width: "30px", justifyContent: 'center' }}
              >
                <i
                  className={`${icon} nav-icon `}
                  style={{ display: "inline" }}
                ></i>
              </div>
              <p className={activeItem}>{this.props.children}</p>
            </NavLink>
          </li>
        ) : (
          <li
            key={this.props.idx}
            className={`nav-item`}
            onClick={this.props.onClick}
          >
            <div
              to={url}
              className={["nav-link", activeStyle].join(" ")}
              data-toggle={this.props.showSidebarValue ? "tooltip" : ""}
              data-placement="right"
              title={this.props.showSidebarValue ? this.props.children : ""}
              onClick={() => {
                this.state.toggleIcon();
                this.state.toggleBgRep();
              }}
              style={{ background: `${this.state.bgReport}` }}
            >
              <i className={`${icon} nav-icon `}></i>
              <p
                className={activeItem}
                style={{
                  marginRight: "auto",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                {this.props.children}{" "}
              </p>
              <div className="" style={{ display: "inline" }}>
                <i
                  className={`${iconc} `}
                  style={{
                    transform: `${this.state.iconReport}`,
                    marginLeft: this.props.showSidebarValue ? "50px" : "-50px",
                  }}
                ></i>
              </div>

              {/* <i className="right fas fa-angle-left"></i> */}
            </div>
          </li>
        );
    }

    return content;
  }
}

export default withRouter(MenuItem);
