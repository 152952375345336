import { useContext, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Context from './Context';
import { Content, ContentHeader, ContentBody } from "../components/Content"
import TitlePages from './Elements/TitlePages'
import { Card as CustomCard, CardBody } from "../components/Card";
import { Card, Table } from "react-bootstrap";
import CRMApi from '../api/CRMApi';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    ArcElement,
    Legend
} from 'chart.js';
import { Link, withRouter } from 'react-router-dom';
import Memberships from '../components/Reports/Memberships';
import AttributeReportPage from '../components/Reports/AttributeReportPage';
import ChartDataLabels from "chartjs-plugin-datalabels";
import CardChart from '../components/Reports/CardChart/CardChart';


ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    Tooltip,
    Legend,
    ChartDataLabels,
);

function standardizeInputDate(date, timezoneOffset, isNow) {
    if (!date) return null

    const absTimezoneOffset = Math.abs(timezoneOffset)
    const sign = timezoneOffset > 0 ? "-" : "+"
    const offset = sign + Math.floor(absTimezoneOffset / 60).toString().padStart(2, '0') + ":" + (Math.floor(absTimezoneOffset) % 60).toString().padStart(2, '0');

    if (isNow) {
        return new Date().toISOString()
    }

    return date + "T00:00:00" + offset
}

function getStaticPeriod(index) {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth() + 1
    const date = now.getDate().toString().padStart(2, '0')

    // last 6 months
    function lastNMonth(n) {
        return (month - n > 0 ? year : year - 1) + "-" + (month - n > 0 ? month - n : month + 12 - n).toString().padStart(2, '0') + "-" + date
    }

    const strMonth = month.toString().padStart(2, '0')

    const period = {
        1: { // This year
            start_date: `${year}-01-01`,
            end_date: `${year}-${strMonth}-${date}`,
            isNow: true,
        },
        2: { // Last year
            start_date: `${year - 1}-${strMonth}-${date}`,
            end_date: `${year}-${strMonth}-${date}`
        },
        3: { // Last 6 months
            start_date: lastNMonth(6),
            end_date: `${year}-${strMonth}-${date}`
        },
        4: { // Last 3 months
            start_date: lastNMonth(3),
            end_date: `${year}-${strMonth}-${date}`
        },
        5: { // Last month
            start_date: lastNMonth(1),
            end_date: `${year}-${strMonth}-${date}`
        },
        6: { // Last 2 years
            start_date: `${year - 2}-${strMonth}-${date}`,
            end_date: `${year}-${strMonth}-${date}`
        },
        7: { // All time
            start_date: null,
            end_date: null
        },
        custom: {
            start_date: `${year}-${month}-${date}`,
            end_date: `${year}-${month}-${date}`
        }
    }

    return period[index]
}

function ReportPage(props) {
    const { t } = props;
    const transPage = "pages.report_page.";
    const crmApi = useMemo(() => new CRMApi(), [])
    const { activeClient } = useContext(Context)
    const now = new Date()
    const currentRoute = props.location.pathname
    const isDashboard = currentRoute.endsWith("/reports/dashboard")

    const [memberCountries, setMemberCountries] = useState([])
    const [membersJoined, setMembersJoined] = useState([])
    const [membersUpgrade, setMembersUpgrade] = useState([])
    const [membersDowngrade, setMembersDowngrade] = useState([])
    const [membersDiscontinue, setMembersDiscontinue] = useState([])
    const [membersMembershipType, setMembersMembershipType] = useState([])
    const [membersMembershipStatus, setMembersMembershipStatus] = useState([])
    const [period, setPeriod] = useState({ start_date: `${now.getFullYear()}-01-01`, end_date: `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`, isNow: true })
    const [staticPeriod, setStaticPeriod] = useState("1")

    useEffect(() => {
        const getCountryReport = async () => {
            const params = { client: activeClient }
            const data = { report: 1 } // hardcoded
            const response = await crmApi.getDynamicReport(params, data)
            setMemberCountries(response.data.results)
        }

        getCountryReport()
    }, [activeClient, crmApi])

    useEffect(() => {
        const timezoneOffset = new Date().getTimezoneOffset()
        const params = { client: activeClient }
        let data = {
            start_date: standardizeInputDate(period.start_date, timezoneOffset),
            end_date: standardizeInputDate(period.end_date, timezoneOffset, period.isNow)
        }

        const getMembersJoined = async () => {
            data.report = 2 // hardcoded
            const response = await crmApi.getDynamicReport(params, data)
            setMembersJoined(response?.data?.results)
        }

        const getMembersUpgrade = async () => {
            data.report = 3 // hardcoded
            const response = await crmApi.getDynamicReport(params, data)
            setMembersUpgrade(response?.data?.results)
        }

        const getMembersDowngrade = async () => {
            data.report = 4 // hardcoded
            const response = await crmApi.getDynamicReport(params, data)
            setMembersDowngrade(response?.data?.results)
        }

        const getMembersDiscontinue = async () => {
            data.report = 5 // hardcoded
            const response = await crmApi.getDynamicReport(params, data)
            setMembersDiscontinue(response?.data?.results)
        }

        const getMembersMembershipType = async () => {
            data.report = 8 // hardcoded
            const response = await crmApi.getDynamicReport(params, data)
            setMembersMembershipType(response?.data?.results)
        }

        const getMembersMembershipStatus = async () => {
            data.report = 9 // hardcoded
            const response = await crmApi.getDynamicReport(params, data)
            setMembersMembershipStatus(response?.data?.results)
        }

        getMembersJoined()
        getMembersUpgrade()
        getMembersDowngrade()
        getMembersDiscontinue()
        getMembersMembershipType()
        getMembersMembershipStatus()
    }, [activeClient, crmApi, period])

    useEffect(() => {
        setPeriod(getStaticPeriod(staticPeriod))
    }, [staticPeriod])

    const totalMembersJoined = useMemo(() => membersJoined.reduce((prev, value) => {
        return prev + value.count
    }, 0), [membersJoined])

    const totalMembersUpgrade = useMemo(() => membersUpgrade.reduce((prev, value) => {
        return prev + value.count
    }, 0), [membersUpgrade])

    const totalMembersDowngrade = useMemo(() => membersDowngrade.reduce((prev, value) => {
        return prev + value.count
    }, 0), [membersDowngrade])

    const totalMembersDiscontinue = useMemo(() => membersDiscontinue.reduce((prev, value) => {
        return prev + value.count
    }, 0), [membersDiscontinue])

    return (
        <Content>
            <ContentHeader>
                <TitlePages title={t(transPage + 'title')} />
            </ContentHeader>
            <ContentBody>
                <CustomCard>
                    <CardBody>
                        {
                            (isDashboard || currentRoute.includes("/memberships")) &&
                            <div className='d-flex'>
                                <div className='form-group mr-4'>
                                    <label>Period</label>
                                    <select value={staticPeriod} className='form-control' onChange={(e) => setStaticPeriod(e.target.value)}>
                                        <option value="1">This year</option>
                                        <option value="2">Past year</option>
                                        <option value="3">Past 6 months</option>
                                        <option value="4">Past 3 months</option>
                                        <option value="5">Past month</option>
                                        <option value="6">Past 2 years</option>
                                        <option value="7">All time</option>
                                        <option value="custom">Custom</option>
                                    </select>
                                </div>

                                <div className='form-group mr-2'>
                                    <label>From</label>
                                    <input disabled={staticPeriod !== "custom"} type='date' value={period.start_date || ""} onChange={(e) => setPeriod({ ...period, start_date: e.target.value })} className='form-control' />
                                </div>
                                <div className='form-group'>
                                    <label>To</label>
                                    <input disabled={staticPeriod !== "custom"} type='date' value={period.end_date || ""} onChange={(e) => setPeriod({ ...period, end_date: e.target.value })} className='form-control' />
                                </div>
                            </div>
                        }

                        {
                            isDashboard &&
                            <>
                                <div className='row'>
                                    {/* <Card className="text-center col-md-3" style={{ minWidth: "400px" }}>
                                        <Card.Header>Members Joined</Card.Header>
                                        <Card.Body>
                                            <Card.Text>
                                                <h1 style={{ fontSize: "10rem" }}>{totalMembersJoined}</h1>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card> */}
                                    <Card className='text-center col-md-4'>
                                        <Card.Header className='font-weight-bold'>Members Joined ({totalMembersJoined})</Card.Header>
                                        <div style={{ overflowY: "auto", maxHeight: "410px" }}>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "50%" }}>Company</th>
                                                        <th style={{ width: "50%" }}>Date Joined</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        membersJoined.map((variable) => (variable.members.map(member => {
                                                            return <tr>
                                                                <td><Link to={"/customers/detail/" + member.member__id} style={{ wordWrap: "break-word" }}>{member.member__name}</Link></td>
                                                                <td>{new Date(member.timestamp).toLocaleDateString("en-US", { dateStyle: "medium" })}</td>
                                                            </tr>
                                                        })))
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Card>
                                    <CardChart
                                        className="col-md-4"
                                        title={"Membership Type"}
                                        labels={["Upgrade", "Downgrade", "Discontinue"]}
                                        data={[totalMembersUpgrade, totalMembersDowngrade, totalMembersDiscontinue]}
                                        typeChart={["pie"]}
                                        chartStyle={{ pie: { maxHeight: "350px" } }}
                                    />
                                </div>

                                <div className='row'>
                                    <Card className='text-center col-md-4'>
                                        <Card.Header className='font-weight-bold'>Upgraded Members ({totalMembersUpgrade})</Card.Header>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Company</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    membersUpgrade.map((variable) => (variable.members.map(member => {
                                                        return <tr>
                                                            <td><Link to={"/customers/detail/" + member.member__id}>{member.member__name}</Link></td>
                                                            <td>{variable.variable}</td>
                                                        </tr>
                                                    })))
                                                }
                                            </tbody>
                                        </Table>
                                    </Card>

                                    <Card className='text-center col-md-4'>
                                        <Card.Header className='font-weight-bold'>Downgraded Members ({totalMembersDowngrade})</Card.Header>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Company</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    membersDowngrade.map((variable) => (variable.members.map(member => {
                                                        return <tr>
                                                            <td><Link to={"/customers/detail/" + member.member__id}>{member.member__name}</Link></td>
                                                            <td>{variable.variable}</td>
                                                        </tr>
                                                    })))
                                                }
                                            </tbody>
                                        </Table>
                                    </Card>

                                    <Card className='text-center col-md-4'>
                                        <Card.Header className='font-weight-bold'>Discontinued Members ({totalMembersDiscontinue})</Card.Header>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Company</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    membersDiscontinue.map((variable) => (variable.members.map(member => {
                                                        return <tr>
                                                            <td><Link to={"/customers/detail/" + member.member__id}>{member.member__name}</Link></td>
                                                            <td>{variable.variable}</td>
                                                        </tr>
                                                    })))
                                                }
                                            </tbody>
                                        </Table>
                                    </Card>
                                </div>
                            </>
                        }

                        {
                            isDashboard && !!memberCountries.length &&
                            <CardChart
                                title="Countries"
                                labels={memberCountries.map(item => item.variable)}
                                data={memberCountries.map(item => item.count)}
                                typeChart={["bar"]}
                            />
                        }

                        {
                            currentRoute.includes("/memberships") &&
                            <Memberships
                                data={{ membersJoined, membersUpgrade, membersDowngrade, membersDiscontinue, membersMembershipType, membersMembershipStatus }}
                            />
                        }
                        {
                            currentRoute.includes("/countries") &&
                            <AttributeReportPage
                                period={period}
                                attributeReports={[
                                    {
                                        id: 1,
                                        title: "Country"
                                    }
                                ]}
                            />
                        }
                        {
                            currentRoute.includes("/leads") &&
                            <AttributeReportPage
                                period={period}
                                attributeReports={[
                                    {
                                        id: 6,
                                        title: "Lead Status"
                                    },
                                    {
                                        id: 10,
                                        title: "Lead Source"
                                    }
                                ]}
                            />
                        }
                        {
                            currentRoute.includes("/sponsors") &&
                            <AttributeReportPage
                                period={period}
                                attributeReports={[
                                    {
                                        id: 7,
                                        title: "Sponsorship Status"
                                    }
                                ]}
                            />
                        }
                        {
                            currentRoute.includes("/sectors") &&
                            <AttributeReportPage
                                period={period}
                                attributeReports={[
                                    {
                                        id: 11,
                                        title: "Sectors"
                                    }
                                ]}
                            />
                        }
                        {
                            currentRoute.includes("/working-groups") &&
                            <AttributeReportPage
                                period={period}
                                attributeReports={[
                                    {
                                        id: 12,
                                        title: "Working groups"
                                    }
                                ]}
                            />
                        }
                        {
                            currentRoute.includes("/bilateral-chambers") &&
                            <AttributeReportPage
                                period={period}
                                attributeReports={[
                                    {
                                        id: 13,
                                        title: "Bilateral chambers"
                                    }
                                ]}
                            />
                        }
                    </CardBody>
                </CustomCard>
            </ContentBody>
        </Content>
    );
}

export default withRouter(withTranslation()(ReportPage));