import React from 'react'
import ContactSelectButton from './ContactSelectButton'

import css from './ContactSelect.module.css'

export { ContactSelectButton }

function ContactSelect(props) {
    let initial = ""
    if (props.name) {
        initial = props.name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
    }
    const selectedContacts = props.selectedContacts.filter(selectedContact => selectedContact)
    const contactList = props.contacts.filter(contact => !selectedContacts.includes(contact.id) || contact.id === props.contactId)
    return (
        <div>
            <div className={["d-flex align-items-center p-3 bg-white"].join(' ')}>
                <div
                    className="mr-3 d-flex align-items-center justify-content-between"
                    style={{ backgroundColor: "#E2E2E2", minWidth: "32px", height: "32px", width: "32px", borderRadius: "50%", border: "2px solid #223771" }}
                >
                    <p className="mb-0 mx-auto">{initial}</p>
                </div>
                <div className="row w-100" >
                    <div className={["col-md-6", css.text].join(' ')}>
                        <p className={css.name}>{props.name}</p>
                        <p className={css.title}>{props.title}</p>
                    </div>
                    <div className={["col-md-6", css.text].join(' ')}>
                        <p className={css.phone}>{props.phone}</p>
                        <p className={css.email}>{props.email}</p>
                    </div>
                </div>
            </div>
            <div>
                <select
                    value={props.contactId}
                    className={["form-control mt-2", css.select].join(' ')}
                    onChange={(e) => { props.handleSelectedContact(e.target.value, props.idx) }}
                >
                    <option value={null}>--- Please select contact ---</option>
                    {
                        contactList.map(contact => <option value={contact.id}>{contact.name}, {contact.position} - {contact.phone}</option>)
                    }
                </select>
            </div>
        </div>
    )
}

export default ContactSelect