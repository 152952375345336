import React, { Component } from 'react';

import ContentHeader from "./ContentHeader"
import ContentBody from "./ContentBody"

import './Content.css'

class Content extends Component {

    render() {
        return (
            <div className="content-wrapper" style={{ minHeight: "1136px" }}>
                {this.props.children}
            </div>
        );
    }
}

export { Content, ContentHeader, ContentBody }
export default Content;