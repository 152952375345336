import React, { Component } from 'react';
import { Button, Tabs, Tab } from "react-bootstrap"
import { withRouter } from "react-router-dom"
import { LoadScript, GoogleMap, Marker } from "@react-google-maps/api";

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor"

import DatePicker from "react-datepicker";
import swal from "sweetalert"
import Select from "react-dropdown-select"
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import { myGetYear, getMonth, myRange } from "./../lib/DateHelper"
import Card, { CardHeader, CardBody, CardFooter } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content"

import ArticleAssetListUpdate from "./../components/widgets/AssetList/ArticleAssetListUpdate"
import ArticleAssetListAdd from "./../components/widgets/AssetList/ArticleAssetListAdd"
import TitlePages from './Elements/TitlePages'
import ModalLocation from "../components/Modal/LocationModal";
import WidgetArticleAddLocationModal from "../components/Modal/WidgetArticleAddLocationModal";

import ArticleApi from "./../api/ArticleApi"
import WidgetApi from "./../api/WidgetApi"
import SectionApi from "./../api/SectionApi"
import UserApi from '../api/UserApi';
import LocationApi from "../api/LocationApi"

import AlertHelper from "./../lib/AlertHelper"

import UserContext from './Context'
import config from '../config/config'

import "react-datepicker/dist/react-datepicker.css";
import "./ArticleDetailPage.css"
class ArticleDetailPage extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props)
        this.state = {
            gmapsApiKey: 'AIzaSyCKqMs4hmNgoQtmIUsd7diBKHj6UV610Jg',
            mapsLib: ["drawing", "places"],
            articleId: this.props.match.params.articleId,

            name: "",
            author: [],
            widget: {},
            // category:"", // tadinya ini, untuk jaga2
            category: [],
            date: "",
            dateToApi: "",
            status: "Drafted",
            is_internal: false,
            is_nofollow: false,

            selectedLanguage: 1,
            widgetDisable: false,

            article_assets: [],
            widgetList: [],
            categoryList: [],
            languageList: [],
            employeeList: [],
            collectionList: [],

            selectedAssetAdd: [],
            selectedAsset: [],
            selectedWidget: [],

            AssetSection: "",
            HeaderTextPage: "",
            ButtonForm: "",

            tabKey: 0,
            articleLanguages: [],
            articleKeywords: [],
            loadedKeywords: [],
            activeKeyword: '',

            pages: [],
            sections: [],
            selectedPageId: null,
            selectedSectionId: null,

            urlType: "internal",
            deleteDisable: true,

            transPage: "pages.article_detail.",
            transAlert: "alert.",

            activeTemplate: 0
        }

        this.getAllEmployees = this.getAllEmployees.bind(this)
        this.getDetailArticle = this.getDetailArticle.bind(this)
        this.getDetailWidget = this.getDetailWidget.bind(this)
        this.getAllWidget = this.getAllWidget.bind(this)
        this.getArticleCategories = this.getArticleCategories.bind(this)
        this.getArticleCollection = this.getArticleCollection.bind(this)
        this.getLanguages = this.getLanguages.bind(this)
        this.getPages = this.getPages.bind(this)
        this.getSectionByPage = this.getSectionByPage.bind(this)
        this.getCurrentUser = this.getCurrentUser.bind(this)

        this.handleChangeName = this.handleChangeName.bind(this)
        this.handleChangeStatus = this.handleChangeStatus.bind(this)
        this.handleChangeWidget = this.handleChangeWidget.bind(this)
        this.handleChangeCategory = this.handleChangeCategory.bind(this)
        this.handleChangeStatus = this.handleChangeStatus.bind(this)
        this.handleChangeDate = this.handleChangeDate.bind(this)
        this.handleChangeTab = this.handleChangeTab.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.handleSectionChange = this.handleSectionChange.bind(this)
        this.handleUrlTypeChange = this.handleUrlTypeChange.bind(this)
        this.handleUrlExternalChange = this.handleUrlExternalChange.bind(this)
        this.handleAuthorChange = this.handleAuthorChange.bind(this)
        this.handleChangeNoFollow = this.handleChangeNoFollow.bind(this)

        this.handleChangeTitle = this.handleChangeTitle.bind(this)
        this.handleChangeSummary = this.handleChangeSummary.bind(this)
        this.handleChangeContent = this.handleChangeContent.bind(this)
        this.handleChangeKeyword = this.handleChangeKeyword.bind(this)
        this.handleChangeDescription = this.handleChangeDescription.bind(this)

        this.setSelectedAsset = this.setSelectedAsset.bind(this)
        this.setSelectedAssetAdd = this.setSelectedAssetAdd.bind(this)

        this.deleteSelectedAssetAdd = this.deleteSelectedAssetAdd.bind(this)

        this.addArticle = this.addArticle.bind(this)
        this.updateArticle = this.updateArticle.bind(this)
        this.addKeyword = this.addKeyword.bind(this)
        this.updateKeyword = this.updateKeyword.bind(this)
        this.handleKeyword = this.handleKeyword.bind(this)
        this.updateLoadedKeyword = this.updateLoadedKeyword.bind(this)
        this.deleteLoadedKeyword = this.deleteLoadedKeyword.bind(this)
        this.deleteAddedKeyword = this.deleteAddedKeyword.bind(this)

        this.clearInternal = this.clearInternal.bind(this)
        this.clearExternal = this.clearExternal.bind(this)

    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        const { t } = this.props;
        const { activeTemplate, sectionDetail } = this.context

        this.setState({ activeTemplate: activeTemplate })

        if (this.props.match.path === "/article/add") {
            this.setState({
                HeaderTextPage: "Article Add",
                ButtonForm: <Button className="float-right" onClick={this.addArticle}> <i className="fa fa-plus">&nbsp;</i> {t(this.state.transPage + "add_article")}</Button>
            }, () => {
                this.getLanguages()
                this.getAllWidget();
            })
        } else if (this.props.match.path === "/article/widgets/detail/:widgetId") {
            const widgetId = this.props.match.params.widgetId
            this.getDetailWidget()
                .then(res => {
                    this.setState({
                        widget: {
                            id: res.data.id,
                            name: res.data.name,
                            is_internal: res.data.is_internal,
                        }
                    }, () => {
                        var widgetDisable = false // disabled

                        if (widgetId) {
                            widgetDisable = true
                        }

                        this.setState({
                            is_internal: true,
                            selectedWidget: [this.state.widget],
                            widgetDisable: widgetDisable,
                            HeaderTextPage: "Article Add",
                            ButtonForm: <Button className="float-right" onClick={this.addArticle}> <i className="fa fa-plus">&nbsp;</i> {t(this.state.transPage + "add_article")}</Button>
                        }, () => {
                            this.getLanguages()
                            this.getAllWidget()
                        })
                    })
                })
        } else {
            this.getDetailArticle()
            this.getLanguages()

            this.setState({
                HeaderTextPage: "Article Detail Page",
                ButtonForm: <Button className="float-right" onClick={this.updateArticle}> <i className="fa fa-edit">&nbsp;</i> {t('update')} </Button>
            }, () => {
                this.getAllWidget()
            })
        }

        sectionDetail.methods.getPages()
        this.getAllEmployees()
        this.getArticleCategories()
        this.getArticleCollection()
        this.getPages()


    }

    componentDidUpdate() {
        const { activeTemplate, activeContentLang } = this.context

        if (this.props.match.path === "/article/add") {
            if (activeTemplate !== this.state.activeTemplate) {
                this.setState({
                    selectedWidget: [],
                    activeTemplate: activeTemplate
                }, () => {
                    this.getAllWidget()
                    this.renderTabLanguage()
                })
            }
        }

        if (activeContentLang.length > 0) {
            if (this.state.languageList !== activeContentLang) {
                this.setState({
                    languageList: activeContentLang
                })
            }
        }
    }

    handleChangeTitle(id, value) {

        let articleLanguages = [...this.state.articleLanguages]
        let articleLanguage = { ...articleLanguages[id] }

        articleLanguage.title = value
        articleLanguages[id] = articleLanguage

        this.setState({
            articleLanguages: articleLanguages
        })
    }

    handleChangeKeyword(id, value) {

        let articleLanguages = [...this.state.articleLanguages]
        let articleLanguage = { ...articleLanguages[id] }

        articleLanguage.keyword = value
        articleLanguages[id] = articleLanguage

        this.setState({
            articleLanguages: articleLanguages
        })
    }

    handleChangeDescription(id, value) {

        let articleLanguages = [...this.state.articleLanguages]
        let articleLanguage = { ...articleLanguages[id] }

        articleLanguage.description = value
        articleLanguages[id] = articleLanguage

        this.setState({
            articleLanguages: articleLanguages
        })
    }

    handleChangeName(value) {
        this.setState({
            name: value
        })
    }

    handleAuthorChange(value) {
        this.setState({
            author: value
        })
    }

    handlePageChange(value) {
        if (!value) {
            this.setState({
                selectedPageId: null,
                selectedSectionId: null,
                sections: []
            })
        } else {
            this.setState({
                selectedPageId: value
            }, () => {
                this.getSectionByPage()
            })

        }
    }

    handleSectionChange(value) {
        this.setState({
            selectedSectionId: value
        })
    }

    handleChangeSummary(id, value) {
        let articleLanguages = [...this.state.articleLanguages]
        let articleLanguage = { ...articleLanguages[id] }

        articleLanguage.summary = value
        articleLanguages[id] = articleLanguage

        this.setState({
            articleLanguages: articleLanguages
        })
    }

    handleChangeContent(id, value) {
        let articleLanguages = [...this.state.articleLanguages]
        let articleLanguage = { ...articleLanguages[id] }

        articleLanguage.content = value
        articleLanguages[id] = articleLanguage

        this.setState({
            articleLanguages: articleLanguages
        })
    }

    handleChangeWidget(value) {
        this.setState({
            widget: value
        })
    }

    handleChangeCategory(value) {
        this.setState({
            category: value
        })
    }

    handleChangeStatus(value) {
        this.setState({
            status: value
        })
    }

    handleUrlExternalChange(id, value) {
        let urlLanguages = [...this.state.articleLanguages]
        let urlLanguage = { ...urlLanguages[id] }
        urlLanguage.path = value
        urlLanguages[id] = urlLanguage

        this.setState({
            articleLanguages: urlLanguages
        })
    }

    handleChangeTab(value) {
        // get title and content base on the language
        this.setState({
            tabKey: value,
            selectedLanguage: value,
            // title:findArticleLanguage?.title,
            // content:findArticleLanguage?.content
        })
    }

    handleChangeDate(value) {

        var newDate = value ? new Date(value) : new Date()
        newDate.setDate(newDate.getDate() + 1)
        const result = JSON.stringify(newDate).slice(1, -1).split("T")

        this.setState({
            date: value,
            dateToApi: result[0]
        })
    }

    handleUrlTypeChange(value) {
        this.setState({
            urlType: value
        }, () => {
            if (this.state.urlType === "internal") {
                this.clearExternal()
            }
            else {
                this.clearInternal()
            }
        })
    }

    handleChangeNoFollow(value) {
        this.setState({
            is_no_follow: value
        })
    }

    setSelectedAssetAdd(asset) {

        var lastSelected = []
        lastSelected = [...this.state.selectedAssetAdd]

        lastSelected = lastSelected.concat(asset)

        this.setState({
            selectedAssetAdd: lastSelected,
        }, () => {
            this.setState({
                deleteDisable: false,
            })
        })
    }

    deleteSelectedAssetAdd(assets) {
        const { t } = this.props;

        var result = []
        var lastSelected = []
        lastSelected = [...this.state.selectedAssetAdd]

        lastSelected.forEach(ite => {

            var exist = false

            assets.forEach(item => {
                if (ite.id === item.id) {
                    exist = true
                }
            })

            if (exist === false) {
                result.push(ite)
            }

        })

        this.setState({
            selectedAssetAdd: result
        }, () => {

            swal("Success!", t(this.state.transAlert + "delete_selected_asset_in_article_success"), "success")
        })

    }

    setSelectedAsset(asset, value) {
        var lastSelected = []
        lastSelected = [...this.state.selectedAsset]
        var indexDelete = -1
        var stateSelected = "selectedAsset"

        if (value === true) {

            lastSelected.push(asset)
        } else {
            this.state[stateSelected].forEach((item, index) => {
                if (item.id === asset.id) {
                    indexDelete = index
                }
            })

            lastSelected.splice(indexDelete, 1)
        }

        this.setState({
            [stateSelected]: lastSelected,

        }, () => {

            if (this.state[stateSelected].length > 0) {
                this.setState({
                    deleteDisable: false,
                })
            } else {

                this.setState({
                    deleteDisable: true,

                })
            }
        })
    }

    getAllEmployees() {
        const objUser = new UserApi()
        const { activeSite } = this.context

        objUser.getAllAuthor({
            max_size: true,
            site: activeSite
        })
            .then(res => {

                const resEmployee = res.data.results.map(item => {

                    // var name = item.user.first_name+" "+item.user.last_name
                    // if(!item.user.first_name) {
                    //     name = item.user.username
                    // }

                    var image = ""
                    if (item.avatar) {
                        image = <div><img width="30" height="30" src={item.avatar} alt=""></img>{" " + item.name}</div>
                    } else {
                        image = item.name
                    }

                    return ({
                        val: item.id,
                        label: image
                    })
                })

                this.setState({
                    employeeList: resEmployee
                })
            })
    }

    getDetailArticle() {
        const objArticle = new ArticleApi()

        const articleId = this.props.match.params.articleId
        // const widgetId = this.props.match.params.widgetId
        console.log('props', this.props.match.params)
        console.log('articleId', articleId)
        return objArticle.getDetailArticles(articleId)
            .then(res => {
                var widgetDisable = false // able
                var urlType = ""

                if (res.data.widgets[0]?.is_internal === true) {
                    widgetDisable = true
                }

                if (res.data.article_languages[0].page) {
                    urlType = "internal"
                } else {
                    urlType = "external"
                }

                const selectedWidget = res.data.widgets.map(item => {

                    var page = ""
                    if (page) {
                        page = " - " + item.page
                    }

                    return {
                        ...item,
                        namePage: item.name + page
                    }
                })

                var image = ""
                if (res.data.author?.avatar) {
                    image = <div><img width="30" height="30" src={res.data.author.avatar} alt=""></img>{" " + res.data.author?.name}</div>
                } else {
                    image = res.data.author?.name
                }

                const author = [
                    {
                        val: res.data.author?.id,
                        label: image
                    }
                ]

                this.setState({
                    articleId: res.data.id,

                    category: res.data?.categories ? res.data?.categories : [],
                    name: res.data.name,

                    selectedWidget: selectedWidget,
                    author: author,
                    status: res.data.status,
                    date: res.data.date ? new Date(res.data.date) : new Date(),
                    dateToApi: res.data.date,
                    article_assets: res.data.article_assets,
                    widgetDisable: widgetDisable,
                    is_internal: res.data.widgets[0]?.is_internal,
                    urlType: urlType,
                    selectedLanguage: 1,
                    // title:res.data.title,
                    // content:res.data.content,
                    articleLanguages: res.data.article_languages,
                    loadedKeywords: res.data.article_keywords,
                    selectedPageId: res.data.article_languages[0]?.page,
                    selectedSectionId: res.data.article_languages[0]?.section,
                    is_nofollow: res.data.article_languages[0]?.is_nofollow,

                    pairLocation: res.data.location
                        ? res.data.location.id
                        : false,
                    location: res.data.location,
                }, () => {
                    this.getLanguages()
                    // this.handleAuthorChange()
                    if (this.state.author.length === 0) {
                        this.getCurrentUser()
                    }
                })
            })
            .catch(err => {

                const resErr = err?.response?.data ? err.response.data : err.message

                const objAlert = new AlertHelper()
                objAlert.errorHandlerAlert(resErr)
            })
    }

    clearInternal() {
        this.setState({
            selectedPageId: null,
            selectedSectionId: null
        })
    }

    clearExternal() {

        let urlLanguages = [...this.state.articleLanguages]
        urlLanguages.map((item, id) => {

            // let urlLanguage = { ...urlLanguages[ id ]}
            item.path = null
            urlLanguages[id] = item

            return item
        })

        this.setState({
            articleLanguages: urlLanguages
        })
    }

    addArticle() {
        const { t } = this.props;
        const contextValue = this.context;
        const objArticle = new ArticleApi()

        const article_languages = this.state.articleLanguages.map(item => {
            return ({
                language: item.language,
                title: item.title,
                summary: item.summary,
                content: item.content,
                description: item.description,
                page: this.state.selectedPageId ? this.state.selectedPageId : null,
                section: this.state.selectedSectionId ? this.state.selectedSectionId : null,
                path: item.path ? (item.path !== "" ? item.path : '/') : '/'
            })
        })

        const author = this.state.author.length > 0 ? this.state.author[0].val : null

        const categories = this.state.category.map(item => {
            return item.id
        })
        //const author = null

        var dt = {
            client: contextValue.activeClient,
            title: this.state.title,
            content: this.state.content,
            name: this.state.name,
            author: author,
            categories: categories,
            location: this.state.pairLocation
                ? this.state.pairLocation
                : this.state.removeLocation
                    ? null
                    : null,
            //widget:this.state.widget,
            status: this.state.status,
            article_languages: article_languages
            //date:this.state.date,
            //assets:this.state.assets
        }

        if (this.state.date) {
            dt["date"] = this.state.dateToApi
        }

        if (this.state.selectedAssetAdd.length > 0) {

            const ids = this.state.selectedAssetAdd.map(item => {
                return (item.id)
                // return ({
                //     asset: item.id
                // })
            })

            dt["article_assets"] = ids
        }

        if (this.state.selectedWidget) {
            dt["widgets"] = this.state.selectedWidget.map(item => item.id)
        }

        if (contextValue.activeClient) {
            objArticle.addArticle(dt)
                .then(res => {

                    const keywords_languages = this.state.articleKeywords.map(item => {
                        return ({
                            article: res.data.id,
                            language: parseInt(item.language),
                            keyword: item.keyword,
                        })
                    })

                    objArticle.addKeywords(keywords_languages)
                        .then(res => {
                            swal("Success !", t(this.state.transAlert + "article_add_success"), "success")

                            if (this.props.match.path === "/article/widgets/detail/:widgetId") {
                                const widgetId = this.props.match.params.widgetId
                                setTimeout(() => {
                                    this.props.history.push(`/widget/detail/${widgetId}`)
                                }, 3000)
                            }
                            else {
                                setTimeout(() => {
                                    this.props.history.push(`/article`)
                                }, 3000)
                            }
                        })

                })
                .catch(err => {
                    const objAlert = new AlertHelper()
                    objAlert.errorHandlerAlert(err.response.data)
                })
        }

    }

    updateArticle() {
        const { t } = this.props;
        const contextValue = this.context;
        const objArticle = new ArticleApi()
        const articleId = this.state.articleId

        const article_languages = this.state.articleLanguages.map(item => {
            return ({
                id: item.id,
                language: item.language,
                title: item.title,
                summary: item.summary,
                content: item.content,
                description: item.description,
                page: this.state.selectedPageId ? this.state.selectedPageId : null,
                section: this.state.selectedSectionId ? this.state.selectedSectionId : null,
                path: item.path ? (item.path !== "" ? item.path : '/') : '/'
            })
        })

        const author = this.state.author.length > 0 ? this.state.author[0].val : null

        const categories = this.state.category.map(item => {
            return item.id
        })

        var dt = {
            client: contextValue.activeClient,
            // title:this.state.title,
            // content:this.state.content,
            name: this.state.name,
            // language:this.state.language,
            categories: categories,
            author: author,
            location: this.state.pairLocation
                ? this.state.pairLocation
                : this.state.removeLocation
                    ? null
                    : null,
            //widget:this.state.widget,
            status: this.state.status,
            article_languages: article_languages,
            //date:this.state.date,
            //assets:this.state.assets

        }

        if (this.state.date) {
            dt["date"] = this.state.dateToApi
        }

        // if(this.state.assets.length > 0) {
        //     dt["assets"] = this.state.assets
        // }

        if (this.state.selectedWidget) {
            dt["widgets"] = this.state.selectedWidget.map(item => item.id)
        }

        this.state.loadedKeywords.forEach((k, i) => {
            const keywords_languages = {
                language: k.language.id,
                keyword: k.keyword,
            }

            objArticle.updateKeywords(k.id, keywords_languages)
                .then(res => {

                })
        })

        if (contextValue.activeClient) {
            objArticle.updateArticle(articleId, dt)
                .then(res => {

                    const keywords_languages = this.state.articleKeywords.map(item => {
                        return ({
                            article: articleId,
                            language: parseInt(item.language),
                            keyword: item.keyword,
                        })
                    })
                    objArticle.addKeywords(keywords_languages)
                        .then(res => {

                        })

                    swal("Success !", t(this.state.transAlert + "article_update_success"), "success")

                    if (this.props.match.path === "/article/detail/:articleId/:widgetId") {
                        const widgetId = this.props.match.params.widgetId
                        setTimeout(() => {
                            this.props.history.push(`/widget/detail/${widgetId}`)
                        }, 3000)
                    } else if (this.props.match.path === "/article/detail/:articleId") {
                        setTimeout(() => {
                            this.props.history.push(`/article`)
                        }, 3000)
                    }
                })
                .catch(err => {
                    const objAlert = new AlertHelper()
                    objAlert.errorHandlerAlert(err.response.data)
                })
        }
    }

    getArticleCategories() {
        const objArticle = new ArticleApi()


        objArticle.getArticleCategories()
            .then(res => {
                this.setState({
                    categoryList: res.data.results
                })
            })
    }

    getArticleCollection() {
        const objArticle = new ArticleApi()
        const contextValue = this.context;

        objArticle.getArticleCollection({
            client: contextValue.activeClient,
            is_internal: false,
            category: 1,
            status: 'Public'
        })
            .then(res => {
                this.setState({
                    collectionList: res.data.results
                })
            })
    }

    getAllWidget() {
        const objWidget = new WidgetApi()

        const urlParams = new URLSearchParams(window.location.search);
        const templateId = urlParams.get('template')

        const params = {
            template: templateId ? templateId : Number(localStorage.getItem("templateId")),
            max_size: true,
            category: 3,
            internal: this.state.is_internal
        }

        objWidget.getAllWidgets(params)
            .then(res => {


                // namePage setting
                const widgetList = res.data.results.map(item => {

                    var page = ""
                    if (item.page) {
                        page = " - " + item.page
                    }

                    return {
                        ...item,
                        namePage: item.name + page
                    }
                })

                this.setState({
                    widgetList: widgetList
                })
            })
    }

    getDetailWidget() {
        const objWidget = new WidgetApi()
        const widgetId = this.props.match.params.widgetId

        return objWidget.getDetailWidget(widgetId)

    }

    getLanguages() {
        const { activeContentLang } = this.context;
        this.setState({
            languageList: activeContentLang,

        }, () => {

            // ===================================================================================================================================
            var index = this.state.languageList.findIndex(item => item.abbreviation === localStorage.getItem("lang"))

            this.setState({
                tabKey: index,

            }, () => {
                this.renderTabLanguage()
                // this.handleChangeTab(this.state.tabKey)
            })

        })
    }

    getPages() {
        // const objPage = new PageApi()
        const { sectionDetail } = this.context;

        this.setState({
            pages: sectionDetail.pagesList
        }, () => {
            if (this.state.selectedPageId) {
                this.getSectionByPage()
            }
        })

        // const params = {
        //     language: localStorage.getItem("lang"),
        //     template: localStorage.getItem("templateId"),
        //     max_size: true
        // }

        // objPage.pageLanguageList(params)
        //     .then(res => {
        //         this.setState({
        //             pages: res.data.results
        //         }, () => {
        //             if (this.state.selectedPageId) {
        //                 this.getSectionByPage()
        //             }
        //         })
        //     })
    }

    getSectionByPage() {
        const objSection = new SectionApi()

        const params = {
            language: localStorage.getItem("lang"),
            page_id: this.state.selectedPageId,
            max_size: true
        }

        objSection.sectionLanguage(params)
            .then(res => {
                this.setState({
                    sections: res.data.results
                })
            })
    }

    getCurrentUser() {
        const objUser = new UserApi()

        objUser.getCurrentUser()
            .then(res => {

                const author = [{
                    val: res.data.id,
                    label: res.data.user.first_name + " " + res.data.user.last_name
                }]

                // alert(JSON.stringify(author))

                this.setState({
                    author: author
                })
            })
            .catch(err => {
                console.log("err getCurrent user => ", err.message)
            })
    }

    renderTabLanguage() {
        let injectId
        const articleLanguages = this.state.languageList.map((item) => {
            var result = this.state.articleLanguages.find(articleLang => item.id === articleLang.language)
            if (!result) {
                result = {
                    id: injectId,
                    article: this.state.articleId || 0,
                    language: item?.language?.id,
                    title: "",
                    summary: "",
                    content: '',
                    page: null,
                    section: null,
                    path: "",
                    description: "",
                    keyword: "",
                }
            } else {
                injectId = result.id + 2
            }

            result.languageName = item.name
            result.page = !this.state.selectedPageId ? null : this.state.selectedPageId
            result.section = !this.state.selectedSectionId ? null : this.state.selectedSectionId

            return result
        })

        this.setState({
            articleLanguages: articleLanguages
        }, () => {
            this.handleChangeTab(this.state.tabKey) // english default
        })

    }

    handleKeyword(value) {
        this.setState({
            activeKeyword: value
        })
    }

    addKeyword(index) {
        // let keywords = document.getElementById('fk-content-keywords').value
        if (this.state.activeKeyword !== "") {
            const articleKeyword = {
                id: `${this.state.articleKeywords.length + 1}`,
                language: index + 1,
                keyword: this.state.activeKeyword
            }
            this.setState({
                articleKeywords: this.state.articleKeywords.concat(articleKeyword),
                activeKeyword: ''
            }, () => {
                document.getElementById('fk-content-keywords0').value = ""
                document.getElementById('fk-content-keywords1').value = ""
            })
        }
    }

    updateKeyword(id, value) {

        let articleKeywords = [...this.state.articleKeywords]
        let articleKeyword = { ...articleKeywords[(id - 1)] }

        articleKeyword.keyword = value
        articleKeywords[(id - 1)] = articleKeyword

        this.setState({
            articleKeywords: articleKeywords
        })
    }

    updateLoadedKeyword(index, value) {
        let loadedKeywords = [...this.state.loadedKeywords]
        let loadedKeyword = { ...loadedKeywords[(index)] }

        loadedKeyword.keyword = value
        loadedKeywords[(index)] = loadedKeyword

        this.setState({
            loadedKeywords: loadedKeywords
        })
    }

    deleteAddedKeyword(index) {
        this.setState({
            articleKeywords: this.state.articleKeywords.filter(function (ele, i) {
                return i !== index;
            })
        })
    }

    deleteLoadedKeyword(id, index) {
        const objArticle = new ArticleApi()

        this.setState({
            loadedKeywords: this.state.loadedKeywords.filter(function (ele, i) {
                return i !== index;
            })
        })

        objArticle.deleteKeywords(id)
            .then(res => {
                // this.getDetailArticle()
            })
    }

    isDisabledSubmit() {
        return !this.state.author.length || !this.state.selectedWidget.length || !this.state.name
    }

    setShowLocationModal = () => {
        this.setState({
            setShowLocationModal: !this.state.setShowLocationModal
        });
    }

    setShowArticleAddLocationModal = () => {
        this.setState({
            setShowArticleAddLocationModal: !this.state.setShowArticleAddLocationModal
        });
    }

    addNewLocation = (newLocation) => {
        const locationObj = new LocationApi();

        locationObj
            .addLocation(newLocation)
            .then((res) => {
                if (res.data) {
                    this.setState({
                        pairLocation: res.data.id,
                        location: res.data,
                    });
                    // swal("Success !", t(this.state.transAlert + "newlocation_add_success"), "success")
                    this.setShowLocationModal();
                }
            })
            .catch((err) => {
                console.log("err => ", err);
            });
    }

    pairLocationWithArticle = (location) => {
        if (location) {
            this.setState({
                pairLocation: false,
                location: false,
            }, () => {
                this.setState({
                    pairLocation: location.id,
                    location: location,
                });
            })
        }
        this.setShowArticleAddLocationModal();
    }

    removeLocation = (locationId) => {
        if (locationId) {
            this.setState({
                pairLocation: false,
                location: false,
                removeLocation: true,
            });
        }
    }

    render() {
        const { t } = this.props

        const Categories = this.state.categoryList.map(item => {
            return {
                id: item.id,
                name: item.name,
            }
        })

        var categorySelected = []
        // SELECTED CATEGORY
        if (this.state.categoryList.length > 0) {
            categorySelected = this.state.category.map((item, index) => {
                for (var ii = 0; ii < this.state.categoryList.length; ii++) {
                    if (this.state.categoryList[ii]?.id === item || this.state.categoryList[ii]?.id === item?.id) {
                        return {
                            id: this.state.categoryList[ii]?.id,
                            name: this.state.categoryList[ii]?.name,
                        }
                    }
                }
                return { id: 0, name: "" }
            })
        }


        const PageSelect = this.state.pages.map(item => {
            return (<option key={item.id} value={item.id}>{item.name}</option>)
        })

        const SectionSelect = this.state.sections.map(item => {
            return (<option key={item.id} value={item.section}>{item.name}</option>)
        })

        const TabLanguage = this.state.articleLanguages.map((item, index) => {

            let ckeditorConfig = {

                toolbar: {
                    items: [
                        "heading",
                        "|",
                        'bold',
                        'italic',
                        "|",
                        'superscript',
                        'subscript',
                        '|',
                        "link",
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'insertTable',

                        "|",
                        'undo',
                        'redo'
                    ]
                },
                table: {
                    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
                },
                language: "",

            }

            if (item.language === 2) {
                ckeditorConfig.language = "vi"

            } else {
                ckeditorConfig.language = "en"
            }

            let renderLocation = (
                <div className="d-flex">
                    <button className="btn btn-primary mb-3" onClick={this.setShowArticleAddLocationModal}>
                        <i className="fa fa-edit mr-1"></i>{this.state.location ? "Change Location" : "Add Location"}
                    </button>
                    <div className="d-flex file-input-custom ml-3" onClick={this.setShowLocationModal}>
                        <label htmlFor="">
                            <i className="fa fa-plus mr-1"></i>Add new Location
                        </label>
                    </div>
                </div>
            );

            let defaultLocation;

            if (this.state.pairLocation && this.state.location) {
                defaultLocation = {
                    lat: parseFloat(this.state.location.latitude),
                    lng: parseFloat(this.state.location.longitude),
                };
            } else {
                defaultLocation = false
            }

            return (
                <Tab key={index} eventKey={index} title={item.languageName}>

                    <br></br>

                    <div className="form-group">
                        <label> {t('title')} </label>
                        <input className="form-control" placeholder={t(this.state.transPage + "title_placeholder")}
                            value={this.state.articleLanguages[index].title}
                            onChange={(event) => this.handleChangeTitle(index, event.target.value)}></input>
                    </div>

                    <div className="form-group">
                        <label> {t('summary')} </label>
                        <textarea
                            className="form-control"
                            value={this.state.articleLanguages[index].summary}
                            onChange={(event) => this.handleChangeSummary(index, event.target.value)}
                        ></textarea>
                    </div>

                    <div className="form-group">
                        <label> {t('content')}</label>
                        <CKEditor
                            id="editor"
                            config={ckeditorConfig}
                            editor={ClassicEditor}
                            data={this.state.articleLanguages[index]?.content ? this.state.articleLanguages[index]?.content : ''}
                            onInit={editor => {
                            }}
                            onChange={(event, editor) => {

                                const data = editor.getData()

                                this.handleChangeContent(index, data)
                            }}
                        />
                    </div>

                    <br></br>

                    <div className="seo-section">
                        <label> SEO </label>
                        <div className="form-group">
                            <label> {t('description')} </label>
                            <textarea
                                className="form-control"
                                value={this.state.articleLanguages[index].description}
                                onChange={(event) => this.handleChangeDescription(index, event.target.value)}
                            ></textarea>
                        </div>

                        <div id="form-keywords" className="form-group">
                            <label> {t('keyword')} </label>
                            <div className="fk-content">
                                {
                                    this.state?.loadedKeywords.map((a, i) => {
                                        return (
                                            parseInt(a.language - 1) === index ?
                                                <div className="fk-c-in">
                                                    <i className="fa fa-times fa-sm" onClick={(event) => this.deleteLoadedKeyword(a.id, i)}></i>
                                                    <input type="text" className="form-control" value={a.keyword} onChange={(event) => this.updateLoadedKeyword(i, event.target.value)} />
                                                </div>
                                                : <></>
                                        )
                                    })
                                }
                                {
                                    this.state?.articleKeywords.map((a, i) => {
                                        return (
                                            parseInt(a.language - 1) === index ?
                                                <div className="fk-c-in">
                                                    <i className="fa fa-times fa-sm" onClick={(event) => this.deleteAddedKeyword(i)}></i>
                                                    <input type="text" className="form-control" defaultValue={a.keyword} onChange={(event) => this.updateKeyword(a.id, event.target.value)} />
                                                </div>
                                                : <></>
                                        )
                                    })
                                }
                                <input id={`fk-content-keywords${index}`} type="text" className="form-control" defaultValue={''} onChange={(event) => this.handleKeyword(event.target.value)} />

                                <div className="fk-icn-add" onClick={() => this.addKeyword(index)}>
                                    <i className="fa fa-plus"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Location</label>
                        {this.state.pairLocation && this.state.location ? (
                            <div className="form-group position-relative">
                                <div style={{ position: "absolute", right: "1rem", top: ".5rem", zIndex: "1000" }}>
                                    <div className="fk-icn-remove" onClick={() => this.removeLocation(this.state.location.id)}>
                                        <i className="fa fa-times"></i>
                                    </div>
                                </div>
                                <p>
                                    {this.state.location.company_name ||
                                        this.state.location
                                            .location_languages[0]
                                            .location_type ||
                                        this.state.location
                                            .location_languages[1]
                                            .location_type}
                                </p>

                                {
                                    defaultLocation && <div style={{ width: "100%", height: "30vh", }}>
                                        <>
                                            <GoogleMap
                                                mapContainerStyle={{ width: "100%", height: "100%", }}
                                                center={defaultLocation}
                                                zoom={15}
                                                mapTypeId="roadmap"
                                                onLoad={this.onLoad}
                                            >
                                                <Marker position={defaultLocation} />
                                            </GoogleMap>
                                        </>
                                    </div>
                                }

                                <p>
                                    {this.state.location.address ||
                                        this.state.location
                                            .location_languages[0]
                                            .address ||
                                        this.state.location
                                            .location_languages[1].address}
                                </p>
                            </div>
                        ) : (
                            <></>
                        )}
                        {renderLocation}
                    </div>

                </Tab>
            )
        })

        var AssetSection = ""

        if (this.props.match.path === "/article/add") {
            AssetSection = <ArticleAssetListAdd
                selectedAssetAdd={this.state.selectedAssetAdd}
                setSelectedAssetAdd={this.setSelectedAssetAdd}
                //assets={this.state.assets}
                deleteSelectedAssetAdd={this.deleteSelectedAssetAdd}
                getDetailArticle={this.getDetailArticle}

            ></ArticleAssetListAdd>
        } else if (this.props.match.path === "/article/widgets/detail/:widgetId") {
            AssetSection = <ArticleAssetListAdd
                selectedAssetAdd={this.state.selectedAssetAdd}
                setSelectedAssetAdd={this.setSelectedAssetAdd}
                //assets={this.state.assets}
                deleteSelectedAssetAdd={this.deleteSelectedAssetAdd}
                getDetailArticle={this.getDetailArticle}

            ></ArticleAssetListAdd>

        } else {
            AssetSection = <ArticleAssetListUpdate
                articleId={this.state.articleId}

                article_assets={this.state.article_assets}
                getDetailArticle={this.getDetailArticle}
            ></ArticleAssetListUpdate>
        }

        const years = myRange(1990, myGetYear(new Date()) + 1, 1);
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];

        let URLInput

        if (this.state.urlType === "internal") {
            URLInput =
                <>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label> {t('page')} </label>
                            <select className="form-control" value={this.state.selectedPageId} onChange={(event) => this.handlePageChange(event.target.value)}>
                                <option value={null}> -- {t(this.state.transPage + "select_page")} -- </option>
                                {PageSelect}
                            </select>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label> {t('section')} </label>
                            <select className="form-control" value={this.state.selectedSectionId} onChange={(event) => this.handleSectionChange(event.target.value)}>
                                <option value="0"> -- {t(this.state.transPage + "select_section")} -- </option>
                                {SectionSelect}
                            </select>
                        </div>
                    </div>
                </>

        } else {
            URLInput = this.state.articleLanguages.map((item, index) => {
                return (
                    <div className="col-md-6">
                        <div className="form-group" key={index}>

                            <label> Url {item.languageName}</label>
                            <input value={item.path} type="text" className="form-control"
                                onChange={(event) => this.handleUrlExternalChange(index, event.target.value)}></input>
                        </div>
                    </div>

                )
            })
        }

        let submitButton;
        if (this.props.match.path === "/article/add") {
            submitButton = <Button disabled={this.isDisabledSubmit()} className="float-right" onClick={this.addArticle}> <i className="fa fa-plus">&nbsp;</i> {t(this.state.transPage + "add_article")}</Button>
        } else if (this.props.match.path === "/article/widgets/detail/:widgetId") {
            submitButton = <Button disabled={this.isDisabledSubmit()} className="float-right" onClick={this.addArticle}> <i className="fa fa-plus">&nbsp;</i> {t(this.state.transPage + "add_article")}</Button>
        } else {
            submitButton = <Button disabled={this.isDisabledSubmit()} className="float-right" onClick={this.updateArticle}> <i className="fa fa-edit">&nbsp;</i> {t('update')} </Button>
        }

        // ----------------------

        return (
            <LoadScript googleMapsApiKey={this.state.gmapsApiKey} libraries={this.state.mapsLib}>
                <Content>
                    <ContentHeader>
                        <div className="row" style={{ padding: "0 5px" }}>
                            <TitlePages title={this.state.name} />
                        </div>
                        <div className="clear"></div>
                    </ContentHeader>
                    <ContentBody>
                        <Card>
                            <CardHeader>
                            <Button onClick={() => this.props.history.goBack()} className="btn btn-default col-md-1 mt-3" >
                                <i className="fa fa-arrow-left">&nbsp;</i>
                                {t('back')}
                            </Button>

                            </CardHeader>
                            <CardBody>
                                <CardHeader>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t('date')}</label>
                                                <div >
                                                    <DatePicker
                                                        className="form-control"
                                                        wrapperClassName="form-control"

                                                        onChange={(date) => this.handleChangeDate(date)}
                                                        selected={this.state.date}

                                                        renderCustomHeader={({
                                                            date,
                                                            changeYear,
                                                            changeMonth,
                                                            decreaseMonth,
                                                            increaseMonth,
                                                            prevMonthButtonDisabled,
                                                            nextMonthButtonDisabled
                                                        }) => (
                                                            <div
                                                                style={{
                                                                    margin: 10,
                                                                    display: "flex",
                                                                    justifyContent: "center"
                                                                }}
                                                            >
                                                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                                    {"<"}
                                                                </button>
                                                                <select
                                                                    value={myGetYear(date)}
                                                                    onChange={({ target: { value } }) => changeYear(value)}
                                                                >
                                                                    {years.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                                <select
                                                                    value={months[getMonth(date)]}
                                                                    onChange={({ target: { value } }) =>
                                                                        changeMonth(months.indexOf(value))
                                                                    }
                                                                >
                                                                    {months.map(option => (
                                                                        <option key={option} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                                    {">"}
                                                                </button>
                                                            </div>
                                                        )}

                                                    ></DatePicker>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t('status')}</label>
                                                <select className="form-control" value={this.state.status} onChange={(event) => this.handleChangeStatus(event.target.value)}>
                                                    <option value="Drafted">{t('drafted')}</option>
                                                    <option value="Published">{t('published')}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardHeader>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t('collections')}<span className="text-danger">*</span></label>
                                                <Select
                                                    multi
                                                    disabled={this.state.widgetDisable}
                                                    searchable={true}
                                                    searchBy="name"
                                                    options={this.state.collectionList}
                                                    values={[...this.state.selectedWidget]}
                                                    // values={[...this.state.collectionList]}

                                                    valueField="id"
                                                    labelField="name"
                                                    onDropdownClose={(value) => {
                                                    }}
                                                    onChange={(selectedWidget) => {
                                                        this.setState({
                                                            selectedWidget
                                                        });
                                                    }}

                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>{t('name')}<span className="text-danger">*</span></label>
                                                <input type="text" name="" className="form-control" value={this.state.name} onChange={(event) => this.handleChangeName(event.target.value)}></input>
                                                {/* {this.state.name} */}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>{t('category')}</label>
                                                <Select
                                                    multi
                                                    //disabled={this.state.widgetDisable}
                                                    searchable={true}
                                                    searchBy="name"
                                                    options={Categories}
                                                    values={categorySelected}

                                                    valueField="id"
                                                    labelField="name"
                                                    onDropdownClose={(value) => { }}
                                                    onChange={(selectedcategory) => {
                                                        this.handleChangeCategory(selectedcategory)
                                                    }}

                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>{t('author')}<span className="text-danger">*</span></label>
                                                <Select
                                                    searchable={true}
                                                    searchBy="label"
                                                    name="form-field-name"
                                                    values={this.state.author}
                                                    options={this.state.employeeList}

                                                    valueField="val"
                                                    labelField="label"
                                                    onChange={(author) => {
                                                        this.setState({
                                                            author: author
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                </CardHeader>
                                <CardHeader >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label> {t('url_type')}</label><br></br>

                                                <label htmlFor="url-internal">
                                                    <input
                                                        type="radio"
                                                        className=""
                                                        id="url-internal"
                                                        name="url_type"
                                                        value={"internal"}
                                                        checked={this.state.urlType === "internal"}
                                                        onChange={() => this.handleUrlTypeChange("internal")}
                                                    ></input>
                                                    &nbsp; {t('internal')}
                                                </label>
                                                &nbsp; &nbsp;
                                                <label htmlFor="url-external">
                                                    <input
                                                        type="radio"
                                                        className=""
                                                        id="url-external"
                                                        name="url_type"
                                                        value="external"
                                                        checked={this.state.urlType === "external"}
                                                        onChange={() => this.handleUrlTypeChange("external")}
                                                    ></input>
                                                    &nbsp; {t('external')}</label>
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="checkbox" id="is_nofollow" onClick={() => this.handleChangeNoFollow} checked={this.state.is_nofollow}></input>
                                                &nbsp;
                                                <label htmlFor="is_nofollow"> {t('no_follow')} </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {URLInput}
                                    </div>

                                </CardHeader>
                                <CardBody>
                                    <Tabs
                                        id="controlled-tab-example"
                                        activeKey={this.state.tabKey}
                                        onSelect={(k) => this.handleChangeTab(k)}
                                    >
                                        {TabLanguage}
                                    </Tabs>
                                </CardBody>
                                <hr></hr>
                                <CardBody>
                                    {AssetSection}
                                </CardBody>
                                <CardFooter>
                                    {submitButton}
                                    <Button onClick={() => this.props.history.goBack()} className="btn btn-danger float-right" style={{ marginRight: 10 }}>
                                        <i className="fa fa-times">&nbsp;</i>
                                        {t('cancel')}
                                    </Button>
                                </CardFooter>
                            </CardBody>
                        </Card>
                    </ContentBody>
                </Content>
                <ModalLocation
                    show={this.state.setShowLocationModal}
                    onHide={this.setShowLocationModal}
                    addNewLocation={this.addNewLocation}
                ></ModalLocation>
                <WidgetArticleAddLocationModal
                    show={this.state.setShowArticleAddLocationModal}
                    onHide={this.setShowArticleAddLocationModal}
                    locationId={this.state.pairLocation}
                    pairLocationWithArticle={this.pairLocationWithArticle}
                ></WidgetArticleAddLocationModal>
            </LoadScript>
        );
    }
}

export default withRouter(withTranslation()(ArticleDetailPage));
