import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import swal from "sweetalert";

import Context from "../../pages/Context";

import SuperAdminApi from "../../api/SuperAdminApi";

class ClientDelete extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            active: true,
        };

        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    deleteSite() {

    }

    onCloseModal() {
        this.setState({
            active: true
        })
        this.props.onHide();
    }

    onOpenModal() {
        const data = this.props.data
        this.setState({
            active: data.is_active
        })
    }

    async changeClientStatus() {
        const Sa = new SuperAdminApi();
        const data = this.props.data
        const id = data.id
        let status
        if (id) {
            const data = {
                is_active: false
            }
            await Sa.updateClient(id, data)
                .then((res) => {
                    if (res.status === 200) {
                        status = true
                    }
                })
                .catch((err) => {
                    status = false
                });
        }
        return status
    }

    handleChangeActiveClient = (value, index) => {
        if (value === true) {
            this.changeClientStatus().then((res) => {
                if (res) {
                    this.props.getClientDetail()
                    this.setState({
                        active: false
                    })
                }
            });
        } else {
            this.setState({
                active: true
            })
        }
    }

    deleteClient = () => {
        const contextValue = this.context;
        const Sa = new SuperAdminApi();
        const data = this.props.data
        const id = data.id

        if (id && !this.state.active) {
            Sa.deleteClient(id)
                .then((res) => {
                    if (res) {
                        this.props.callback()
                        this.props.onHide();
                        swal("Success", `Delete Client`, "success");

                        if (contextValue.clientList){
                            contextValue.changeActiveClient(contextValue.clientList[0]?.id)
                        }
                        
                    }
                })
                .catch((err) => {
                    console.log("err", err)
                });
        }
    }

    render() {
        const { t } = this.props;
        const data = this.props.data
        const { active } = this.state

        let checked;
        if (active) {
            checked = (
                <input
                    type="checkbox"
                    onChange={(event) =>
                        this.handleChangeActiveClient(event.target.checked)
                    }
                />
            );
        } else {
            checked = (
                <input
                    type="checkbox"
                    checked
                    onChange={(event) =>
                        this.handleChangeActiveClient(event.target.checked)
                    }
                />
            );
        }
        return (
            <Modal
                show={this.props.show}
                size="sm"
                onHide={() => this.onCloseModal()}
                onShow={() => this.onOpenModal()}
            >
                {this.props.loadingModal}
                <Modal.Header closeButton className="bg-danger">
                    <Modal.Title>
                        Delete Client's
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>Before delete the client you should set their status to <strong>inactive</strong></p>
                        <div className="d-flex mb-3 justify-content-center" style={{ border: '1px solid #D4D4D4' }}>
                            <span className={active ? "active" : "inactive"}>
                                Active
                            </span>
                            <label className="switchCk">
                                {checked}
                                <div className="sliderCk round"></div>
                            </label>
                            <span className={!active ? "active" : "inactive"}>
                                Inactive
                            </span>
                        </div>
                    </div>
                    <p>Are you sure you want continue to delete client <strong>{data?.name}</strong></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => this.onCloseModal()}
                    >
                        {t("cancel")}
                    </Button>

                    {
                        !this.state.active &&
                        <Button variant="danger" onClick={this.deleteClient}>
                            <i className="fa fa-trash">&nbsp;</i>
                            Delete
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(ClientDelete);
