import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

import Context from "../../pages/Context";

import SuperAdminApi from "../../api/SuperAdminApi";
import SitemapApi from '../../api/SitemapApi';

import "./modalGlobal.css";

export default class SuperAdminClientModalCreate extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            subscriptionsList: [],
            activeSubscriptions: [],

            allfile: [false, false],
            preview: [false, false],

            clientLanguage: [],
            siteLanguage: [],

            canCreate: false,
            isLoadingModal: false
        };

        // this.onOpenModal = this.onOpenModal.bind(this)
        // this.onHideModal = this.onHideModal.bind(this)

        this.createClient = this.createClient.bind(this);
        this.handleChangeActiveSubs = this.handleChangeActiveSubs.bind(this);
    }

    componentDidMount() {
        this.sitemapApi = new SitemapApi()
    }

    onOpenModal() {
        let subs = [];
        this.props.subscriptionsList.forEach((sub) => {
            subs.push({
                id: sub.id,
                name: sub.name,
                active: false,
            });
        });

        this.setState({
            subscriptionsList: subs,
            activeSubscriptions: subs,
        });
    }

    handleChangeActiveSubs(value, index) {
        const { activeSubscriptions } = this.state;
        if (activeSubscriptions.length > 0) {
            let activeSubs = [...activeSubscriptions];
            let newactiveSubs = { ...activeSubs[index] };
            newactiveSubs.active = !value;
            activeSubs[index] = newactiveSubs;

            this.setState({
                activeSubscriptions: activeSubs,
            });
        }
    }

    validateClient = () => {
        let isValid
        const { clientLanguage , siteLanguage } = this.state

        const isSite = this.checkingIsSite()

        const name = document.getElementById("mSa-client-name").value
        const address = document.getElementById("mSa-address").value
        const phone = document.getElementById("mSa-phone").value

        const sitename = document.getElementById("mSa-site-name").value
        const site_url = document.getElementById("mSa-site-url").value
        const api_url = document.getElementById("mSa-api-url").value

        if (isSite){
            if (name && address && phone && clientLanguage.length && sitename && site_url && api_url && siteLanguage.length){
                isValid = true
            } 
        } else {
            if (name && address && phone && clientLanguage.length){
                isValid = true
            }
        }
        return isValid
    }

    createClient() {
        const isValid = this.validateClient()
        if (!isValid){
            swal("Failed", `You have to fill all needed information`, "error");
            return false
        }

        const Sa = new SuperAdminApi();
        const contextValue = this.context;
        const { activeSubscriptions, clientLanguage } = this.state;

        let subs = [];
        activeSubscriptions.forEach((sb) => {
            if (sb.active) {
                subs.push(sb.id);
            }
        });

        let langList = [];
        clientLanguage.forEach((cL) => {
            langList.push(cL.value);
        });

        const dataClient = {
            name: document.getElementById("mSa-client-name").value,
            address: document.getElementById("mSa-address").value,
            phone: document.getElementById("mSa-phone").value,
            subscriptions: subs,
            files: this.state.allfile,
            languages: langList,
        };

        const isSite = this.checkingIsSite()

        this.setState({
            isLoadingModal: true
        })
        Sa.addClient(contextValue.userKey, dataClient)
            .then((res) => {
                if (res.data) {
                    // contextValue.getAllClient();
                    this.props.callback();

                    if (isSite) {
                        this.createSite(res.data.id)
                    }
                }
            })
            .catch((err) => { });
    }

    createSite = async (client) => {
        const Sa = new SuperAdminApi();
        const contextValue = this.context;
        const { siteLanguage } = this.state;

        let langList = [];
        siteLanguage.forEach((cL) => {
            langList.push(cL.value);
        });

        const dataSite = {
            client: client,
            name: document.getElementById("mSa-site-name").value,
            site_url: document.getElementById("mSa-site-url").value,
            api_url: document.getElementById("mSa-api-url").value,
            languages: langList
        };

        if (client) {
            Sa.addSite(contextValue.userKey, dataSite)
                .then((res) => {
                    if (res.data) {
                        swal("Success", `Create new Client`, "success");
                        contextValue.changeActiveClient(client)
                        contextValue.getAllSitesByClient(client, " ");
                        this.setState({
                            isLoadingModal: false
                        }, () => this.props.onHide())
                    }
                })
                .catch((err) => { });
        }
    }

    renderSubsOption(subs, active, index) {
        let checked;
        if (active === true) {
            checked = (
                <input
                    type="checkbox"
                    onChange={(event) =>
                        this.handleChangeActiveSubs(event.target.checked, index)
                    }
                />
            );
        } else if (active === false) {
            checked = (
                <input
                    type="checkbox"
                    defaultChecked={true}
                    onChange={(event) =>
                        this.handleChangeActiveSubs(event.target.checked, index)
                    }
                />
            );
        }
        return (
            <div key={`${subs.name}-${index}`} className="row lg-list">
                <div className="w-50 title-lg">
                    <input
                        type="text"
                        className="form-control input-title-lg"
                        defaultValue={subs.name}
                        disabled
                    />
                </div>
                <div className="w-50 d-flex content-lg">
                    <div className="d-flex m-auto">
                        <span className={active ? "active" : "inactive"}>
                            Active
                        </span>
                        <label className="switchCk">
                            {checked}
                            <div className="sliderCk round"></div>
                        </label>
                        <span className={!active ? "active" : "inactive"}>
                            Inactive
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    onDrop = (files, index) => {
        const { allfile, preview } = this.state
        let file = [...allfile]
        file[index] = files
        let prev = [...preview]

        this.setState(
            {
                allfile: file,
            },
            () => {
                files.forEach((file) => {
                    prev[index] = URL.createObjectURL(Object.assign(file))
                    this.setState({
                        preview: prev,
                    });
                });
            }
        );
    };

    renderPreview = (file) => {
        let thumbs = (
            <div className='thumb' key={file}>
                <div className='thumbInner'>
                    <img src={file} alt="" />
                </div>
            </div>
        );
        return thumbs
    }

    modalDeleteLogo = (index) => {
        const { allfile, preview } = this.state
        let file = [...allfile]
        file[index] = false
        let prev = [...preview]
        prev[index] = false
        this.setState({
            allfile: file,
            preview: prev
        })
    }

    filterSubscription = (id, name) => {
        const { activeSubscriptions } = this.state;
        const val = activeSubscriptions.filter(as => (as.id === id || as.name === name))

        return val
    }

    checkingIsSite = () => {
        let isSite
        const cms = this.filterSubscription(1, "CMS")
        const jb = this.filterSubscription(2, "Job Poster")
        const crm = this.filterSubscription(5, "CRM")

        if (cms[0]?.active || jb[0]?.active) {
            isSite = true
        } else if (crm[0]?.active) {
            isSite = false
        }

        return isSite
    }

    handleChangeClientLanguage = (arr) => {
        this.setState({ clientLanguage: arr });
    }

    handleChangeSiteLanguage = (arr) => {
        this.setState({ siteLanguage: arr });
    }

    render() {
        const { activeSubscriptions } = this.state;

        let defaultSubscriptions, isSite;

        if (activeSubscriptions.length > 0) {
            defaultSubscriptions = activeSubscriptions.map((subs, i) => {
                return this.renderSubsOption(subs, false, i);
            });

            isSite = this.checkingIsSite()
        }

        let dropStyle = {
            marginBottom: "0rem",
        };

        const languageList = this.props.languageList
        const optionLanguage = []
        if (languageList.length) {
            for (const key in languageList) {
                optionLanguage.push({
                    value: languageList[key].id,
                    label: languageList[key].name
                })
            }
        }

        const req = <span style={{color: '#cc3300'}}>*</span>

        return (
            <Modal
                id="mglbl-modal-SACadd"
                size="xl"
                className="mglbl-modal"
                show={this.props.show}
                onHide={() => this.props.onHide()}
                onShow={() => this.onOpenModal()}
            >
                {this.state.isLoadingModal &&
                    <div className="overlay d-flex justify-content-center align-items-center">
                        <i className="fas fa-2x fa-sync fa-spin"></i>
                    </div>
                }
                <Modal.Header>
                    <Modal.Title>Create New Client</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex">
                        <div className="w-50">
                            <div className="form-group">
                                <label>Name {req}</label>
                                <input id="mSa-client-name" type="text" name="mSa-client-name" className="form-control" defaultValue={""} index="1"></input>
                            </div>
                            <div className="form-group">
                                <label>Address {req}</label>
                                <input id="mSa-address" type="text" name="mSa-address" className="form-control" defaultValue={""} index="2"></input>
                            </div>
                            <div className="form-group">
                                <label>Phone {req}</label>
                                <input id="mSa-phone" type="text" name="mSa-phone" className="form-control" defaultValue={""} index="3"></input>
                            </div>
                            <div className="form-group">
                                <label>Client Language {req}</label>
                                <Select
                                    value={this.state.clientLanguage}
                                    isMulti={true}
                                    name="collection"
                                    options={optionLanguage}
                                    className={["basic-multi-select"].join(" ")}
                                    classNamePrefix="select"
                                    onChange={(selected) =>
                                        this.handleChangeClientLanguage(selected)
                                    }
                                />
                            </div>

                        </div>
                        <div className="w-50 d-flex">
                            <div className="form-group w-50 p-0">
                                <label>Logo</label>
                                <Dropzone onDrop={(files) => this.onDrop(files, 0)} accept="image/*" maxFiles={1} multiple={false}>
                                    {({ getRootProps, getInputProps, open }) => (
                                        <>
                                            <section className="">
                                                <div style={dropStyle} {...getRootProps({ className: ["dropzone"].join(" ") })}>
                                                    <input {...getInputProps()} />
                                                    {this.state.preview[0] ? this.renderPreview(this.state.preview[0]) : <p>+ Logo</p>}
                                                </div>
                                            </section>
                                            {this.state.preview[0] && (
                                                <div className="dropzone-action">
                                                    <FaPencilAlt color="#17438B" className="mr-2" onClick={open} />
                                                    <FaTrashAlt color="#17438B" onClick={() => this.modalDeleteLogo(0)} />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </Dropzone>
                            </div>
                            <div className="form-group">
                                <label>Favicon</label>
                                <Dropzone onDrop={(files) => this.onDrop(files, 1)} accept="image/*" maxFiles={1} multiple={false}>
                                    {({ getRootProps, getInputProps, open }) => (
                                        <>
                                            <section className="">
                                                <div style={dropStyle} {...getRootProps({ className: ["dropzone"].join(" ") })}>
                                                    <input {...getInputProps()} />
                                                    {this.state.preview[1] ? this.renderPreview(this.state.preview[1]) : <p>+ Favicon</p>}
                                                </div>
                                            </section>
                                            {this.state.preview[1] && (
                                                <div className={"dropzone-action"}>
                                                    <FaPencilAlt color="#17438B" className="mr-2" onClick={open} />
                                                    <FaTrashAlt color="#17438B" onClick={() => this.modalDeleteLogo(1)} />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </Dropzone>
                            </div>
                        </div>
                    </div>

                    <div className="px-3">
                        {defaultSubscriptions}
                    </div>
                    {
                        isSite && <>
                            <div className="d-flex mt-3">
                                <div className="w-50">
                                    <div className="form-group">
                                        <label> Site name {req}</label>
                                        <input id="mSa-site-name" className="form-control" type="text" index="4"></input>
                                    </div>
                                    <div className="form-group">
                                        <label> Site url {req}</label>
                                        <input id="mSa-site-url" className="form-control" type="text" index="5"></input>
                                    </div>
                                </div>
                                <div className="w-50">
                                    <div className="form-group">
                                        <label> Api url {req}</label>
                                        <input id="mSa-api-url" className="form-control" type="text" index="6"></input>
                                    </div>
                                    <div className="form-group">
                                        <label>Site Language {req}</label>
                                        <Select
                                            value={this.state.siteLanguage}
                                            isMulti={true}
                                            name="collection"
                                            options={optionLanguage}
                                            className={["basic-multi-select"].join(" ")}
                                            classNamePrefix="select"
                                            onChange={(selected) =>
                                                this.handleChangeSiteLanguage(selected)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {
                        isSite ? <></> :
                            <></>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex w-100 justify-content-end">
                        <button type="button" className="mglbl-btn-cancel mr-3" onClick={() => this.props.onHide()}>
                            Cancel
                        </button>
                        <button type="button" className="mglbl-btn-add" onClick={this.createClient}>
                            Create Client
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}
