import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class LocationDelete extends Component {
  f() {
    this.props.delete();
    this.props.onHide();
  }
  render() {
    const { t } = this.props;
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header closeButton className="bg-danger">
          <Modal.Title>{t("modals.location_delete.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("modals.location_delete.confirmation_text")}{" "}
            <b>{this.props.names}</b> ?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onHide}>
            {t("cancel")}
          </Button>
          <Button variant="danger" onClick={() => this.f()}>
            <i className="fa fa-check">&nbsp;</i>
            {t("modals.location_delete.delete_location")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(LocationDelete);
