import React, { Component } from "react";
import { Button } from "react-bootstrap";
import _ from "lodash";
import { withRouter } from "react-router-dom";

import Card, { CardBody, CardFooter } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content";
import TitlePages from "./Elements/TitlePages";

import UserApi from "../api/UserApi";
import swal from "sweetalert";
import toTitle from "to-title";
import { withTranslation } from "react-i18next";
import config from "../config/config";
import ReactGA from "react-ga";
import Context from "./Context";

import SuperAdminApi from "../api/SuperAdminApi";

class UserDetailPage extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      userId: 0,
      username: "",
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
      active: false,
      avatar: "",
      previewAvatar: "",
      groups: [],
      selectedGroup: [],
      selectedSites: [],

      isGroups: true,
      activeGroups: [],
      isData: false,

      transPage: "pages.user_detail.",
      transAlert: "alert.",
    };

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleActiveChange = this.handleActiveChange.bind(this);
    this.handleSelectedSites = this.handleSelectedSites.bind(this);
    this.errMessage = this.errMessage.bind(this);
    this.getDetailUser = this.getDetailUser.bind(this);
    this.fileInput = React.createRef();
  }

  componentDidMount() {
    ReactGA.initialize(config.GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.getClientDetail();

    this.setState(
      {
        applicantId: this.props.match.params.applicantId,
        userId: this.props.match.params.employeeId,
        username: "",
        name: "",
        email: "",
        password: "",
        passwordConfirm: "",
        active: false,
        isStaff: false,
        isSuperuser: false,
        groups: [],
        selectedGroup: [],
        selectedSites: [],
        activeGroups: [],
      },
      () => {
        if (this.state.userId && this.state.isData) {
          this.getDetailUser(this.state.userId);
        }
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.userId && this.state.isData) {
      this.getDetailUser(this.state.userId);
    }
  }

  onCloseModal() {
    //     this.setState({
    //         userId:0
    //     },() => {
    //         this.props.onHide()
    //         this.props.setUserId(0)
    //     })
  }

  getAllGroups = async () => {
    const Sa = new SuperAdminApi();
    const contextValue = this.context;
    const { activeSubscription } = this.state;
    const myUser = contextValue.user;
    const userGroups = myUser?.groups?.map((e) => e.index);

    // if (myUser.is_superuser) {
    if (activeSubscription) {
      await Sa.getGroupsBasedSubscriptions(
        contextValue.userKey,
        activeSubscription
      )
        .then((res) => {
          let allgroups;

          if (myUser.is_superuser) {
            allgroups = res.data.results;
          } else if (_.intersection([7], userGroups)) {
            allgroups = res.data.results;
          } else {
            allgroups = res.data.results.filter(
              (g) => !g.name.includes("Admin")
            );
          }

          let activeGroups = [];

          if (allgroups && allgroups.length > 0) {
            allgroups.forEach((gr) => {
              activeGroups.push({
                id: gr.id,
                name: gr.name,
                active: false,
              });
            });
          }

          this.setState({
            isData: true,
            groups: myUser.is_superuser ? res.data.results : allgroups,
            activeGroups: activeGroups,
          });

          return true;
        })
        .catch((err) => {});
    }
    // } else {
    //     let allgroups = myUser.groups;
    //     let activeGroups = [];

    //     if (allgroups && allgroups.length > 0) {
    //         allgroups.forEach((gr) => {
    //             activeGroups.push({
    //                 id: gr.id,
    //                 name: gr.name,
    //                 active: true,
    //             });
    //         });
    //     }

    //     this.setState({
    //         isData: true,
    //         groups: allgroups,
    //         activeGroups: activeGroups,
    //     });

    //     return true
    // }
  };

  getClientDetail = async () => {
    const Sa = new SuperAdminApi();
    const contextValue = this.context;

    await Sa.getClientDetail(contextValue.userKey, contextValue.activeClient)
      .then((res) => {
        let allSubscription = res.data.subscriptions;
        let activeSubscription = [];

        if (allSubscription && allSubscription.length > 0) {
          allSubscription.forEach((sb, index) => {
            activeSubscription.push(sb.id);
          });
        }

        this.setState(
          {
            activeSubscription: activeSubscription,
          },
          () => {
            this.getAllGroups();
          }
        );
      })
      .catch((err) => {});
  };

  handleNameChange(value) {
    this.setState({
      name: value,
    });
  }

  handleActiveChange(value) {
    var val = false;
    if (value === "active") {
      val = true;
    }

    this.setState({
      active: val,
    });
  }

  handleUsernameChange(value) {
    this.setState({
      username: value,
    });
  }

  handleEmailChange(value) {
    this.setState({
      email: value,
    });
  }

  handlePasswordChange(value) {
    this.setState({
      password: value,
    });
  }

  handlePasswordConfirmChange(value) {
    this.setState({
      passwordConfirm: value,
    });
  }

  handleAvatarChange(value) {
    this.setState({
      avatar: value,
    });
  }

  handleSelectedSites(e) {
    const isChecked = e.target.checked;
    const value = e.target.value;

    const selectedSitesSet = new Set([...this.state.selectedSites]);
    if (isChecked) {
      selectedSitesSet.add(value);
    } else {
      selectedSitesSet.delete(value);
    }

    this.setState({
      selectedSites: [...selectedSitesSet],
    });
  }

  getDetailUser() {
    const userObj = new UserApi();
    const { activeGroups } = this.state;

    userObj
      .getDetailUser(this.state.userId)
      .then((res) => {
        let grp = res.data.groups;
        let activeGroup = [];

        if (activeGroups && activeGroups.length) {
          activeGroups.forEach((gr) => {
            if (grp.some((grs) => grs.name === gr.name)) {
              activeGroup.push({
                id: gr.id,
                name: gr.name,
                active: true,
              });
            } else {
              activeGroup.push({
                id: gr.id,
                name: gr.name,
                active: false,
              });
            }
          });
        }

        this.setState({
          username: res.data.username,
          name: res.data.name,
          email: res.data.email,

          activeGroups: activeGroup,
          groups: activeGroup,
          active: res.data.is_active,
          selectedSites: res.data.sites.map((e) => e.id.toString()),
          // "isStaff": res.data.is_staff,
          isSuperUser: res.data.is_superuser,
          previewAvatar: res.data.avatar,

          isData: false,
        });
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  addUser() {
    const contextValue = this.context;
    const { t } = this.props;
    const { activeGroups } = this.state;
    const userObj = new UserApi();
    const formData = new FormData();

    let groups = [];
    activeGroups.forEach((gr) => {
      if (gr.active) {
        groups.push(gr.id);
      }
    });
    const user = {
      client: contextValue.activeClient,
      site: contextValue.user?.site,
      username: this.state.email,
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      confirm_password: this.state.passwordConfirm,
      is_active: this.state.active,
      // "sites": this.state.selectedSites,

      groups: groups,
    };

    formData.append("client", user.client);
    formData.append("site", user.site);
    formData.append("name", user.name);
    formData.append("email", user.email);
    formData.append("password", user.password);
    formData.append("confirm_password", user.confirm_password);
    formData.append("is_active", user.is_active);
    if (this.state.avatar) {
      formData.append("avatar", this.state.avatar);
    }

    if (user.groups && user.groups.length) {
      for (const key in user.groups) {
        formData.append(`groups`, user.groups[key]);
      }
    }

    userObj
      .addUser(formData)
      .then((res) => {
        swal(
          "Success !",
          t(this.state.transAlert + "user_add_success"),
          "success"
        ).then(() => {
          this.props.history.push(`/user`);
        });
      })
      .catch((err) => {
        const errorResponse = err.response?.data;
        let errorMessage = "";

        if (errorResponse) {
          if (Array.isArray(errorResponse)) {
            errorMessage = errorResponse.join("\n");
          } else {
            errorMessage = this.errMessage(errorResponse, errorMessage);
          }
        }
        swal("Error !", errorMessage, "error");
      });
  }

  updateUser() {
    const contextValue = this.context;
    const { t } = this.props;
    const { activeGroups } = this.state;
    const userObj = new UserApi();
    const formData = new FormData();
    const myUser = contextValue.user;

    let groups = [];
    activeGroups.forEach((gr) => {
      if (gr.active) {
        groups.push(gr.id);
      }
    });

    const user = {
      client: contextValue.activeClient,
      site: contextValue.user?.site,
      username: this.state.username,
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      confirm_password: this.state.passwordConfirm,
      is_active: this.state.active,
      // sites: this.state.selectedSites
      // "is_staff": this.state.isStaff,
      // "is_superuser": this.state.isSuperuser
      groups: groups,
    };

    formData.append("client", user.client);
    formData.append("site", user.site);
    formData.append("username", user.username);
    formData.append("name", user.name);
    formData.append("email", user.email);
    formData.append("password", user.password);
    formData.append("confirm_password", user.confirm_password);
    formData.append("is_active", user.is_active);

    if (user.groups && user.groups.length) {
      for (const key in user.groups) {
        formData.append(`groups`, user.groups[key]);
      }
    }

    if (this.state.avatar) {
      formData.append("avatar", this.state.avatar);
    }

    if (user.email === "") {
      swal("Error !", "Email cannot be blank", "error");
      return;
    }

    userObj
      .updateUser(this.state.userId, formData)
      .then((res) => {
        swal(
          "Success !",
          t(this.state.transAlert + "user_update_success"),
          "success"
        ).then(() => {
          let isGroupsShowing;
          if (myUser.is_superuser) {
            isGroupsShowing = true;
          } else {
            const groups = (myUser?.groups || []).map((e) => e.id);
            const s = _.intersection([4, 5, 6, 8], groups).length > 0;
            isGroupsShowing = s;
          }
          isGroupsShowing
            ? this.props.history.push("/user")
            : this.props.history.push("/");
        });
      })
      .catch((err) => {
        swal("Error !", err.message, "error");
      });
  }

  applicantAdd() {}

  applicantUpdate() {}

  errMessage(obj, message) {
    const keys = Object.keys(obj);
    keys.forEach((key) => {
      if (Array.isArray(obj[key])) {
        message += toTitle(key) + ":\n" + obj[key].join("\n") + "\n\n";
      } else if (typeof obj[key] === "object" && Object.keys(obj[key]).length) {
        message = this.errMessage(obj[key], message);
      }
    });
    return message.trim();
  }

  renderGroupsOption(subs, active, index) {
    let checked;
    return (
      <div key={subs.name} className="row lg-list">
        <div className="w-50 title-lg pr-0 pl-2">
          <input
            type="text"
            className="form-control input-title-lg"
            defaultValue={subs.name}
            disabled
          />
        </div>
        <div className="w-50 d-flex content-lg pl-0">
          <div className="d-flex m-auto">
            <span className={active ? "active" : "inactive"}>Active</span>
            <label className="switchCk">
              <input
                type="checkbox"
                defaultChecked={this.state.userId ? !active : !checked}
                onChange={(event) =>
                  this.handleChangeActiveGroups(event.target.checked, index)
                }
              />
              <div className="sliderCk round"></div>
            </label>
            <span className={!active ? "active" : "inactive"}>Inactive</span>
          </div>
        </div>
      </div>
    );
  }

  handleChangeActiveGroups(value, index) {
    const { activeGroups } = this.state;
    if (activeGroups.length > 0) {
      let activeSubs = [...activeGroups];
      let newactiveSubs = { ...activeSubs[index] };
      newactiveSubs.active = !value;
      activeSubs[index] = newactiveSubs;

      this.setState({
        activeGroups: activeSubs,
      });
    } else {
    }
  }

  renderSubsOption = (groupsUsed) => {
    const userGroups = groupsUsed.map((subs, i) => {
      let active;
      if (subs.active || subs.active === "true") {
        active = true;
      } else {
        active = false;
      }
      return this.renderGroupsOption(subs, active, i);
    });

    return userGroups;
  };

  render() {
    const { t } = this.props;
    var HeaderText = "";
    var BtnSubmit = "";

    if (this.props.match.path === "/user/add") {
      HeaderText = t(this.state.transPage + "employee_add");
      BtnSubmit = (
        <Button
          className="btn-bz"
          style={{ float: "right" }}
          onClick={() => {
            this.addUser();
          }}
        >
          <i className="fa fa-plus">&nbsp;</i>
          {t(this.state.transPage + "employee_add")}
        </Button>
      );
    }
    if (this.props.match.path === "/applicant/update") {
      HeaderText = t(this.state.transPage + "applicant_add");
      BtnSubmit = (
        <Button
          className="btn-bz"
          style={{ float: "right" }}
          onClick={() => {
            this.applicantAdd();
          }}
        >
          <i className="fa fa-plus">&nbsp;</i>
          {t(this.state.transPage + "applicant_add")}
        </Button>
      );
    }
    if (this.state.userId) {
      HeaderText = t(this.state.transPage + "employee_update");
      BtnSubmit = (
        <Button
          className="btn-bz"
          style={{ float: "right" }}
          onClick={() => {
            this.updateUser();
          }}
        >
          <i className="fa fa-plus">&nbsp;</i>
          {t(this.state.transPage + "employee_update")}
        </Button>
      );
    }
    if (this.state.applicant) {
      HeaderText = t(this.state.transPage + "applicant_update");
      BtnSubmit = (
        <Button
          className="btn-bz"
          style={{ float: "right" }}
          onClick={() => {
            this.applicantUpdate();
          }}
        >
          <i className="fa fa-plus">&nbsp;</i>
          {t(this.state.transPage + "applicant_update")}
        </Button>
      );
    }

    const { groups, isGroups, activeGroups, email, name } = this.state;
    let userGroups;
    if (this.props.match.path === "/user/add" && groups.length > 0) {
      userGroups = this.renderSubsOption(groups);
    }

    if (
      this.state.userId &&
      activeGroups.length > 0 &&
      (email !== "" || name !== "")
    ) {
      userGroups = this.renderSubsOption(activeGroups);
    }

    const contextValue = this.context;
    const myUser = contextValue.user;

    let isGroupsShowing;
    if (myUser.is_superuser) {
      isGroupsShowing = true;
    } else {
      if (this.state.userId && this.state.isSuperUser) {
        isGroupsShowing = false;
      } else {
        const groups = (myUser?.groups || []).map((e) => e.index);
        const s = _.intersection([4, 5, 6, 7], groups).length > 0;
        isGroupsShowing = s; // change to s if groups admin want to edit groups
      }
    }

    return (
      <Content>
        <ContentHeader>
          <TitlePages title={HeaderText} />
        </ContentHeader>
        <ContentBody>
          <div className="col-md-8">
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col-md-8">
                    <div className="form-group">
                      <label> {t("name")} </label>
                      <input
                        className="form-control"
                        type="text"
                        value={this.state.name}
                        onChange={(event) =>
                          this.handleNameChange(event.target.value)
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label> {t("email")} </label>
                      <input
                        autoComplete="off"
                        type="email"
                        className="form-control"
                        value={this.state.email}
                        onChange={(event) =>
                          this.handleEmailChange(event.target.value)
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <img
                      title="Click to change the avatar"
                      width={"100%"}
                      src={this.state.previewAvatar || "/img/bz-publish.svg"}
                      alt="Click to change the avatar"
                      style={{
                        cursor: "pointer",
                        border: "1px solid grey",
                        maxHeight: "148px",
                        objectFit: "contain",
                      }}
                      onClick={() => {
                        this.fileInput.current.click();
                      }}
                    ></img>
                    <div style={{ textAlign: "center", width: "100%" }}>
                      {" "}
                      {this.state.avatar ? t("avatar") : "Upload here"}{" "}
                    </div>

                    <input
                      type="file"
                      name="image"
                      id="image"
                      style={{ display: "none" }}
                      onChange={(event) => {
                        this.setState({
                          avatar: event.target.files[0],
                          previewAvatar: URL.createObjectURL(
                            event.target.files[0]
                          ),
                        });
                      }}
                      ref={this.fileInput}
                    ></input>
                  </div>
                </div>
                {isGroupsShowing && (
                  <div className="row">
                    <div className="form-group col-md-8 pr-3">
                      <label className={!isGroups ? "sp-attention" : ""}>
                        Groups
                      </label>
                      {userGroups}
                      {!isGroups ? (
                        <span className="sp-attention">
                          <em> Please select at least 1 group</em>
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}
                {myUser.is_superuser && (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> {t("active")} </label>
                        <br></br>
                        <label id="active" className="mr-3">
                          {" "}
                          <input
                            type="radio"
                            onChange={() => this.handleActiveChange("active")}
                            id="active"
                            name="active-user"
                            checked={this.state.active === true}
                          ></input>{" "}
                          {t("active")}{" "}
                        </label>
                        <label id="active">
                          {" "}
                          <input
                            type="radio"
                            onChange={() => this.handleActiveChange("unactive")}
                            id="unactive"
                            name="active-user"
                            checked={this.state.active === false}
                          ></input>{" "}
                          {t("inactive")}{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                <hr></hr>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> {t("password")} </label>
                      <input
                        autoComplete="new-password"
                        type="password"
                        className="form-control"
                        value={this.state.password}
                        onChange={(event) =>
                          this.handlePasswordChange(event.target.value)
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label> {t("confirm_password")} </label>
                      <input
                        type="password"
                        autoComplete="new-password"
                        className="form-control"
                        value={this.state.passwordConfirm}
                        onChange={(event) =>
                          this.handlePasswordConfirmChange(event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                {BtnSubmit}
                <div
                  className="cancel-user"
                  onClick={() => this.props.history.push("/user")}
                >
                  Cancel
                </div>
              </CardFooter>
            </Card>
          </div>
        </ContentBody>
      </Content>
    );
  }
}

export default withRouter(withTranslation()(UserDetailPage));
