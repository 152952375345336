import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataTable, { defaultThemes } from "react-data-table-component";
// import DataTableExtensions from "react-data-table-component-extensions";
import swal from "sweetalert";

import Card, { CardHeader, CardBody } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "../components/Content";
import ArticleDeleteConfirm from "./../components/Modal/ArticleDeleteConfirm";
import WidgetAddNew from "./../components/Modal/WidgetAddNew";

import MultipleDropdownButton from "../components/Dropdown/MultipleDropdownButton"
import TitlePages from './Elements/TitlePages'
import ArticleApi from "./../api/ArticleApi";
import TemplateApi from "./../api/TemplateApi";
import "./ArticleListPage.css";
// import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
import { withTranslation } from 'react-i18next';
import UserContext from './Context'
import config from '../config/config'
import ReactGA from 'react-ga';

class ArticleListPage extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      filteredArticles: [],
      selectedArticles: [],
      filters: {
        name: "",
        author: "",
        category: [],
        collection: [],
        templateId: ""
      },
      inputField: {
        category: [],
        collection: [],
        templates: []
      },
      activeTemplate: 0,
      showModalConfirm: false,
      loadingModal: "",
      widthName: null,
      widthColl: null,
      maxWidthAction: null,
      transAlert: "alert.",
      setNewWidgetEx: false
    };

    this.getAllArticles = this.getAllArticles.bind(this);
    this.getAllTemplates = this.getAllTemplates.bind(this)
    this.showCategory = this.showCategory.bind(this);
    this.setShowModalConfirm = this.setShowModalConfirm.bind(this);
    this.deleteArticle = this.deleteArticle.bind(this);
    this.deleteSelectedArticle = this.deleteSelectedArticle.bind(this);
    this.filterArticles = this.filterArticles.bind(this);
    this.handleFilterTextBox = this.handleFilterTextBox.bind(this)
    this.handleFilterDropdown = this.handleFilterDropdown.bind(this)
    this.handleChangeTemplate = this.handleChangeTemplate.bind(this)
  }

  componentDidMount() {
    ReactGA.initialize(config.GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    const { activeTemplate } = this.context;

    this.getAllTemplates();

    this.setState({
      activeTemplate: activeTemplate,
      filters: {
        ...this.state.filters,
        templateId: activeTemplate
      }
    }, () => {
      this.getAllArticles()
    })

    if (window.screen.width <= 1440) {
      this.setState({
        widthName: "400px",
        widthColl: "170px",
        maxWidthAction: "130px",
      });
    } else if (window.screen.width <= 1920) {
      this.setState({
        widthName: "600px",
      });
    } else if (window.screen.width === 2560) {
      this.setState({
        widthName: "40vw",
      });
    }
  }

  componentDidUpdate() {
    const { activeTemplate } = this.context;

    if (activeTemplate !== this.state.activeTemplate) {
      this.setState({
        activeTemplate: activeTemplate,
        filters: {
          author: "",
          category: [],
          collection: [],
          name: "",
          templateId: activeTemplate
        }
      }, () => {
        this.getAllArticles();
        this.getAllTemplates();
      })
    }
  }

  setShowModalConfirm() {
    this.setState({
      showModalConfirm: !this.state.showModalConfirm,
    });
  }

  showCategory(categoryId) {
    var result = this.getArticleDetail(categoryId);
    return result;
  }

  getArticleDetail(categoryId) {
    const objArticle = new ArticleApi();

    return objArticle.getDetailArticleCategory(categoryId);
  }

  getAllArticles(params) {
    const { activeClient } = this.context
    const objArticle = new ArticleApi();
    let inputField = {};
    inputField.category = new Set()
    inputField.collection = new Set()

    objArticle
      .getAllArticles({
        max_size: true,
        widgets__is_internal: false,
        client: activeClient,
        ...params
      })
      .then((res) => {
        const articles = res.data.results.map((item, index, array) => {
          item.categories?.forEach(cat => {
            inputField.category.add(cat.name)
          })
          item.widgets?.forEach(widget => {
            inputField.collection.add(widget.name)
          })

          return {
            id: item.id,
            title: item.title,
            name: item.name,
            category: item.category,
            category_names: Array.from(inputField.category),
            widgets: item.widgets,
            author_name: item.author_name
          }
        });

        inputField.category = [...inputField.category]
        inputField.collection = [...inputField.collection]

        this.setState({
          articles: articles,
          filteredArticles: articles,
          inputField: {
            ...this.state.inputField,
            ...inputField
          }
        })

      });
  }

  getAllTemplates() {
    const templateApi = new TemplateApi();
    const activeSite = localStorage.getItem('siteId')

    templateApi
      .getSites(activeSite)
      .then(res => {
        const templates = res.data.templates;
        this.setState({
          inputField: {
            ...this.state.inputField,
            templates: templates
          }
        })
      })
  }

  deleteArticle(articleId) {
    const objArticle = new ArticleApi();

    objArticle
      .getDetailArticles(articleId)
      .then((res) => {
        this.setState({
          selectedArticles: [res.data],
          showModalConfirm: !this.state.showModalConfirm,
        });
      })
      .catch((err) => {
        swal("Error !", err.message, "error");
      });
  }

  deleteSelectedArticle() {
    const { t } = this.props;
    const objArticle = new ArticleApi();

    this.setState({
      loadingModal: (
        <div className="overlay d-flex justify-content-center align-items-center">
          <i className="fas fa-2x fa-sync fa-spin"></i>
        </div>
      ),
    });

    const ids = this.state.selectedArticles.map((item) => {
      return item.id;
    });

    objArticle
      .deleteMultiArticle(ids)
      .then((res) => {
        this.setState(
          {
            selectedArticles: [],
            showModalConfirm: !this.state.showModalConfirm,
            loadingModal: "",
          },
          () => {
            this.getAllArticles();
            swal("Success !", t(this.state.transAlert + "delete_multi_article"), "success");
          }
        );
      })
      .catch((err) => {
        swal("Error !", err.message, "error");
      });
  }

  handleFilterDropdown(e, filterName) {
    const value = e.target.dataset.value
    let state = new Set([...this.state.filters[filterName]])
    if (state.has(value)) {
      state.delete(value)
    } else {
      state.add(value)
    }

    let newFilters = { ...this.state.filters }
    newFilters[filterName] = [...state];
    this.setState({ filters: newFilters }, this.filterArticles)
  }

  handleFilterTextBox(e, filterName) {
    let newFilters = { ...this.state.filters }
    newFilters[filterName] = e.target.value;
    this.setState({ filters: newFilters }, this.filterArticles)
  }

  handleChangeTemplate(e) {
    this.setState({
      filters: {
        ...this.state.filters,
        templateId: e.target.value
      }
    }, () => {
      const params = { template: this.state.filters.templateId }
      this.getAllArticles(params)
    })
  }

  filterArticles() {
    const filters = { ...this.state.filters }
    const filteredArticles = [...this.state.articles].filter(article => {
      let categorySet = new Set()
      let collectionSet = new Set()
      article.category_names.forEach(cat => {
        categorySet.add(cat)
      })
      article.widgets.forEach(widget => {
        collectionSet.add(widget.name)
      })
      const articleName = article.name ? article.name : ""
      const author = article.author_name ? article.author_name : ""
      const categoryIntersection = new Set([...filters.category].filter(x => categorySet.has(x)))
      const collectionIntersection = new Set([...filters.collection].filter(x => collectionSet.has(x)))

      return (
        (articleName).toLowerCase().includes(filters.name?.toLowerCase())
        && (author).toLowerCase().includes(filters.author?.toLowerCase())
        && (filters.category.length === 0 || categoryIntersection.size > 0)
        && (filters.collection.length === 0 || collectionIntersection.size > 0)
      )
    })

    this.setState({
      filteredArticles: filteredArticles
    })
  }

  setNewWidgetEx = () => {
    this.setState({
      setNewWidgetEx: !this.state.setNewWidgetEx
    })
  }

  render() {
    const { t } = this.props;
    const { user } = this.context

    const columnsTable = [
      {
        name: "Id",
        selector: "id",
        sortable: true,
        width: "70px"
      },
      {
        name: t('name'),
        selector: "name",
        sortable: true,
        width: this.state.widthName,
        // maxWidth:""
        style: {
          width: 20,
        },
        cell: (row) => (
          <Link
            to={`/article/detail/${row.id}?template=${this.state.filters.templateId}`}
          >
            {row.name}
          </Link>
        ),
      },
      {
        name: t('author'),
        selector: "author_name",
        sortable: true,
        style: {
          color: "black",
        },
        cell: (row) => row.author_name,
      },
      {
        name: t('category'),
        selector: "category_names",
        sortable: true,
        style: {
          color: "black",
        },
        cell: (row) => {
          return (
            <ul style={{ padding: "0 0 0 10px" }}>
              {row.category_names.map((item) => {
                return <li>{item}</li>;
              })}
            </ul>
          )
        },
      },
      {
        name: t('collections'),
        selector: "widget_name",
        sortable: true,
        width: this.state.widthColl,
        style: {
          color: "black",
        },
        cell: (row) => {
          return (
            <ul>
              {row?.widgets?.map((item) => {
                return <li>{item.name}</li>;
              })}
            </ul>
          );
        },
      },

      {
        name: t('action'),
        selector: "action",
        maxWidth: this.state.maxWidthAction,
        // width: "200px",
        cell: (row) => (
          <div id={row.id}>
            <Button
              variant="danger"
              className="btn-sm"
              onClick={() => this.deleteArticle(row.id)}
            >
              <i className="fa fa-trash"></i>
            </Button>
          </div>
        ),
      },
    ];

    return (
      <Content>
        <ContentHeader>
          {/* <h1> Articles </h1> */}
          <TitlePages title={t('pages.articles.title')} />
        </ContentHeader>
        <ContentBody>
          <Card>
            <CardHeader>
              <div className="card-tools">
                <Button className="btn btn-primary" href={`/article/add?template=${this.state.filters.templateId}`}>
                  <i className="fa fa-plus">&nbsp;</i>
                  {t('pages.articles.add_article')}
                </Button>
              </div>
            </CardHeader>
            <CardBody id="body_article">
              {/* <DataTableExtensions
                columns={columnsTable}
                data={this.state.articles}
              > */}
              <div
                id="filter-articles"
                className="form-inline row"
              >
                <input
                  className="form-control d-inline-block col-2"
                  // style={{ maxWidth: "400px", fontSize: "13px" }}
                  type="text"
                  placeholder={t('pages.articles.search_name')}
                  onChange={(e) => this.handleFilterTextBox(e, "name")}
                />

                <input
                  className="form-control d-inline-block ml-2 col-2"
                  // style={{ width: "100px", fontSize: "13px" }}
                  type="text"
                  placeholder={t('pages.articles.search_author')}
                  onChange={(e) => this.handleFilterTextBox(e, "author")}
                />

                <MultipleDropdownButton
                  className="d-inline-block ml-2 p-0 col-3 col-xl-2"
                  // style={{ width: "124px" }}
                  placeholder={t('pages.articles.category_filters')}
                  onChange={(e) => { this.handleFilterDropdown(e, "category") }}
                  values={this.state.inputField.category}
                  selectedValue={this.state.filters.category}
                />

                <MultipleDropdownButton
                  className="d-inline-block ml-2 p-0 col-3 col-xl-2"
                  // style={{ width: "145px" }}
                  placeholder={t('pages.articles.collection_filters')}
                  onChange={(e) => { this.handleFilterDropdown(e, "collection") }}
                  values={this.state.inputField.collection}
                  selectedValue={this.state.filters.collection}
                />

                {
                  user.is_superuser ?
                    <div className="form-inline p-lg-2 ml-auto" onClick={this.setNewWidgetEx}
                      style={{ border: '1px solid #ced4da', borderRadius: '2px', cursor: 'pointer' }}
                    >
                      <span><strong>Add Collection &nbsp;</strong></span>
                      <i className='fa fa-plus'></i>
                    </div>
                    : <></>
                }


                {/* temporary disable for showing template exchange for collection */}
                {/* <div className="form-inline p-0 ml-4 col">
                  <label htmlFor="filter-template">Template: &nbsp;</label>
                  <select
                    className="form-control d-inline-block"
                    id="filter-template"
                    onChange={this.handleChangeTemplate}
                    value={this.state.filters.templateId}
                  >
                    {
                      this.state.inputField.templates.map(template => {
                        return (
                          <option
                            key={"" + template.id + template.key}
                            value={template.id}
                          >
                            {template.name}
                          </option>
                        )
                      })
                    }
                  </select>
                </div> */}
              </div>

              <DataTable
                columns={columnsTable}
                data={this.state.filteredArticles}
                className="table table-bordered dataTable"
                striped
                role="grid"
                noHeader
                defaultSortField="id"
                defaultSortAsc={true}
                pagination
                selectableRows={true}
                selectableRowsHighlight={true}
                highlightOnHover={true}
                customStyles={{
                  headCells: {
                    style: {
                      borderRightStyle: "solid",
                      borderRightWidth: "1px",
                      borderRightColor: defaultThemes.default.divider.default,
                    },
                  },
                  cells: {
                    style: {
                      borderRightStyle: "solid",
                      borderRightWidth: "1px",
                      borderRightColor: defaultThemes.default.divider.default,
                    },
                  },
                }}
              // progressPending
              />
              {/* </DataTableExtensions> */}
              <div className="clearfix"></div>
            </CardBody>
          </Card>
        </ContentBody>
        <ArticleDeleteConfirm
          selectedArticles={this.state.selectedArticles}
          deleteSelectedArticle={this.deleteSelectedArticle}
          loadingModal={this.state.loadingModal}
          show={this.state.showModalConfirm}
          onHide={this.setShowModalConfirm}
        ></ArticleDeleteConfirm>
        <WidgetAddNew
          widgetId={false}
          section={false}
          show={this.state.setNewWidgetEx}
          onHide={this.setNewWidgetEx}
          modalType="addCollection"

          // selectedWidget={this.state.selectedWidget}
          getAllWidgets={this.getAllArticles}

        ></WidgetAddNew>
      </Content>
    );
  }
}

export default withTranslation()(ArticleListPage);
