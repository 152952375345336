import { Redirect } from "react-router-dom";
import _ from 'lodash';

// SUPERUSER
import SuperAdminPage from "../pages/SuperAdminPage";
import SuperAdminUserListPage from "../pages/SuperAdminUserListPage";
import SuperAdminUserAdd from "../pages/SuperAdminUserAdd";

// CONTENT
import ArticleDetailPage from "../pages/ArticleDetailPage";
import ArticleListPage from "../pages/ArticleListPage";

import NewPageDetail from "../pages/NewPageDetail/NewPageDetail";

import MenuDetailPage from "../pages/MenuDetailPage";
import MenuListPage from "../pages/MenuListPage";

import AssetAdd from "../pages/AssetAdd";
import AssetListPage from "../pages/AssetListPage";
import AssetDetailPage from "../pages/AssetDetailPage";

import LocationDetailPage from "../pages/LocationDetailPage";
import LocationPage from "../pages/LocationPage";

// JOB POSTER
import ApplicantDetail from "../pages/Job/ApplicantDetail";
import JobPosterList from "../pages/Job/JobPosterList";
import JobPosterAdd from "../pages/Job/JobPosterAdd";

// SETTINGS
import UserDetail from "../pages/UserDetailPage";
import User from "../pages/User";
import CategoryPage from "../pages/CategoryPage";
import AboutPage from "../pages/AboutPage";
// import EmailSetting from "../pages/EmailSetting";
import EmailTemplate from "../pages/EmailTemplate";
import BucketSettingPage from "../pages/BucketSettingPage";
import GASettingPage from "../pages/GASettingPage";
import SitemapSettingsPage from "../pages/SitemapSettingsPage";
import LanguagePage from "../pages/LanguagePage";

// SETTINGS BZADMIN
import SectionDetailPage from "../pages/SectionDetail";
import WidgetList from "../pages/WidgetListPage";
import WidgetDetailPage from "../pages/WidgetDetailPage";
import SectionCatalog from "../pages/SectionCatalog";
import SectionCategoriesPage from "../pages/SectionCategoriesPage";
import WidgetsPage from "../pages/WidgetsPage";
import TemplateDetail from "../pages/TemplateDetail";
import TemplateList from "../pages/TemplateList";
import LanguageJSON from "../pages/LanguageJSON";

// CRM
import CustomerListPage from "../pages/CustomerListPage";

// Global

import SiteStructure from "../pages/SiteStructure";
// import Page404 from "../pages/Page404";

import PrivateRoute from "../components/Route/PrivateRoute";
import CustomerDetailPage from "../pages/CustomerDetailPage";
import ReportPage from "../pages/ReportPage";

const NormalRoute = (route) => {
    return (
        <PrivateRoute
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={route.component}
        />
    );
};

const SpecialRoute = (route, props) => {
    let NewComponent = route.component;
    return (
        <PrivateRoute
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={(props) => (
                <NewComponent
                    {...props}
                    activeTemplate={props.activeTemplate}
                    activeSite={props.activeSite}
                />
            )}
        />
    );
};

export default function Route(props) {
    let Routes = [
        {
            id: 99,
            name: "Super Admin",
            routes: [
                {
                    privelege: [99],
                    subscriptions: [1, 2, 5],
                    path: "/superadmin/users/add",
                    component: SuperAdminUserAdd,
                },
                {
                    privelege: [99],
                    subscriptions: [1, 2, 5],
                    path: "/superadmin/user/:userid",
                    component: SuperAdminUserListPage,
                },
                {
                    privelege: [99],
                    subscriptions: [1, 2, 5],
                    exact: true,
                    SpecialRoute: true,
                    path: "/superadmin",
                    component: SuperAdminPage,
                },
            ],
        },
        {
            id: 1,
            name: "Content",
            routes: [
                {
                    privelege: [1, 4, 6],
                    subscriptions: [1],
                    path: "/site-structure",
                    component: SiteStructure,
                },
                // PAGE  =>> CMS, Admin
                {
                    privelege: [1, 4, 6],
                    subscriptions: [1],
                    path: "/page/detail/:pageId",
                    component: NewPageDetail,
                },
                {
                    privelege: [1, 4, 6],
                    subscriptions: [1],
                    path: "/menu-list/:menuId",
                    component: MenuDetailPage,
                },
                // MENU =>> CMS, Admin
                {
                    privelege: [1, 4, 6],
                    subscriptions: [1],
                    path: "/menu-list",
                    exact: true,
                    component: MenuListPage,
                },
                // ARTICLE ==>> CMS, Articles
                {
                    privelege: [1, 2, 4, 6],
                    subscriptions: [1],
                    path: "/article/widgets/detail/:widgetId",
                    component: ArticleDetailPage,
                },
                {
                    privelege: [1, 2, 4, 6],
                    subscriptions: [1],
                    path: "/article/detail/:articleId/:widgetId",
                    component: ArticleDetailPage,
                },
                {
                    privelege: [1, 2, 4, 6],
                    subscriptions: [1],
                    path: "/article/detail/:articleId",
                    component: ArticleDetailPage,
                    exact: true
                },
                {
                    privelege: [1, 2, 4, 6],
                    subscriptions: [1],
                    path: "/article/add",
                    component: ArticleDetailPage,
                },
                {
                    privelege: [1, 2, 4, 6],
                    subscriptions: [1],
                    path: "/article",
                    exact: true,
                    component: ArticleListPage,
                },
                // ASSET =>> CMS, Articles, Job Poster
                {
                    privelege: [1, 2, 3, 4, 5, 6],
                    subscriptions: [1, 2],
                    path: "/asset/detail/:assetId",
                    component: AssetDetailPage,
                },
                {
                    privelege: [1, 2, 3, 4, 5, 6],
                    subscriptions: [1, 2],
                    path: "/asset/add",
                    component: AssetAdd,
                },
                {
                    privelege: [1, 2, 3, 4, 5, 6],
                    subscriptions: [1, 2],
                    path: "/asset",
                    exact: true,
                    component: AssetListPage,
                },
                // LOCATION =>> CMS, Articles, Job Poster
                {
                    privelege: [1, 2, 3, 4, 5, 6, 7, 8],
                    subscriptions: [1, 2, 5, 8],
                    path: "/location/detail/:locationId",
                    component: LocationDetailPage,
                },
                {
                    privelege: [1, 2, 3, 4, 5, 6, 7, 8],
                    subscriptions: [1, 2, 5, 8],
                    path: "/location/add",
                    component: LocationDetailPage,
                },
                {
                    privelege: [1, 2, 3, 4, 5, 6, 7, 8],
                    subscriptions: [1, 2, 5, 8],
                    path: "/location",
                    exact: true,
                    component: LocationPage,
                },
            ],
        },
        {
            id: 2,
            name: "Job Poster",
            routes: [
                {
                    privelege: [3, 5],
                    subscriptions: [2],
                    path: "/job-poster/applicant/detail/:applicantId",
                    component: ApplicantDetail,
                },
                {
                    privelege: [3, 5],
                    subscriptions: [2],
                    path: "/job-poster/detail/:jobPosterId",
                    component: JobPosterAdd,
                },
                {
                    privelege: [3, 5],
                    subscriptions: [2],
                    path: "/job-posters/add",
                    component: JobPosterAdd,
                },
                {
                    privelege: [3, 5],
                    subscriptions: [2],
                    path: "/job-poster" /*dont change this path with /job-posters */,
                    component: JobPosterList,
                },
            ],
        },
        {
            id: 3,
            name: "Settings",
            routes: [
                {
                    privelege: [1, 2, 3, 4, 5, 6, 7, 8],
                    subscriptions: [1, 2, 5, 8, 10],
                    path: "/user/detail/:employeeId",
                    component: UserDetail,
                },
                {
                    privelege: [4, 5, 6, 7],
                    subscriptions: [1, 2, 5, 8, 10],
                    path: "/user/add",
                    component: UserDetail,
                },
                {
                    privelege: [4, 5, 6, 7],
                    subscriptions: [1, 2, 5, 8, 10],
                    path: "/user",
                    exact: true,
                    component: User
                },
                {
                    privelege: [4, 5, 6],
                    subscriptions: [1],
                    path: "/category",
                    component: CategoryPage,
                },
                {
                    privelege: [4, 5, 6],
                    subscriptions: [1],
                    path: "/about",
                    component: AboutPage,
                },
                // {
                //     privelege: [4, 6, 5],
                //     subscriptions: [1],
                //     specialRoute: true,
                //     path: "/email-settings",
                //     component: EmailSetting,
                // },
                {
                    privelege: [4, 5, 6],
                    subscriptions: [1],
                    path: "/email-settings",
                    component: EmailTemplate,
                },
                {
                    privelege: [4, 5, 6],
                    subscriptions: [1],
                    specialRoute: true,
                    path: "/s3-bucket-settings",
                    component: BucketSettingPage,
                },
                {
                    privelege: [4, 5, 6],
                    subscriptions: [1],
                    specialRoute: true,
                    path: "/ga-number-settings",
                    component: GASettingPage,
                },
                {
                    privelege: [4, 5, 6],
                    subscriptions: [1],
                    path: "/sitemap-settings",
                    component: SitemapSettingsPage,
                },
                {
                    privelege: [4, 5, 6],
                    subscriptions: [1, 2],
                    path: "/language",
                    component: LanguagePage,
                },
            ],
        },
        {
            id: 4,
            name: "Settings BZ Admin",
            routes: [
                {
                    privelege: [99, 1, 4, 6],
                    subscriptions: [1],
                    path: "/section/detail/:sectionId",
                    component: SectionDetailPage,
                },
                {
                    privelege: [99, 6],
                    subscriptions: [1],
                    path: "/section/developer/:sectionId",
                    component: WidgetList,
                },
                {
                    privelege: [99, 1, 4, 6],
                    subscriptions: [1],
                    path: "/section/newdetail/:sectionId",
                    component: SectionDetailPage,
                },
                {
                    privelege: [99, 6],
                    subscriptions: [1],
                    specialRoute: true,
                    path: "/widget/detail/:widgetId",
                    component: WidgetDetailPage,
                },
                {
                    privelege: [99, 6],
                    subscriptions: [1, 2],
                    path: "/section/catalog",
                    component: SectionCatalog,
                },
                {
                    privelege: [99, 6],
                    subscriptions: [1],
                    path: "/section-categories",
                    component: SectionCategoriesPage,
                },
                {
                    privelege: [99, 6],
                    subscriptions: [1],
                    path: "/widgets",
                    component: WidgetsPage,
                },
                {
                    privelege: [99, 6],
                    subscriptions: [1, 2],
                    path: "/template/detail/:templateId",
                    component: TemplateDetail,
                },
                {
                    privelege: [99, 6],
                    subscriptions: [1],
                    specialRoute: true,
                    path: "/template",
                    component: TemplateList,
                },
                {
                    // privelege: [4],
                    privelege: [99, 6],
                    subscriptions: [1],
                    path: "/language-json",
                    component: LanguageJSON,
                },
            ],
        },
        {
            id: 5,
            name: "CRM",
            routes: [
                {
                    privelege: [99, 7, 8],
                    subscriptions: [5],
                    path: "/customers/detail/:customerId",
                    component: CustomerDetailPage,
                },
                {
                    privelege: [99, 7],
                    subscriptions: [5],
                    path: "/customers/add",
                    component: CustomerDetailPage,
                },
                {
                    privelege: [99, 7, 8],
                    subscriptions: [5],
                    exact: true,
                    path: "/customers",
                    component: CustomerListPage,
                },
                {
                    privelege: [99, 7, 8],
                    subscriptions: [5],
                    path: "/reports",
                    component: ReportPage,
                },
            ],
        }
    ];

    let AllRoute = [];
    let componentsRenderFirst;

    if (props?.user?.is_superuser) {
        // user without privilege will have same route because API not ready
        const subsc = (props?.clientInformation?.subscriptions || []).map(e => e.id)
        Routes.forEach((route) => {
            if (route.routes) {
                route.routes.forEach((route) => {
                    let routes;
                    if (route.specialRoute) {
                        routes = SpecialRoute(route, props);
                    } else {
                        routes = NormalRoute(route);
                    }
                    if (_.intersection(route.subscriptions, subsc).length > 0) {
                        AllRoute.push(routes);
                    }
                });
            }
        });

        AllRoute.push(
            <PrivateRoute
                key="login"
                exact
                path="/login"
                render={() => {
                    return <Redirect to="/" />;
                }}
            />
        );
        AllRoute.push(
            <PrivateRoute
                key="home"
                path="/"
                exact={true}
                component={SuperAdminPage}
            ></PrivateRoute>
        );
    } else if (!props?.user?.is_superuser) {
        const groups = (props.user.groups || []).map(e => e.index)

        if (props.user.groups && props.user.groups.length > 0) {
            Routes.forEach((route) => {
                if (route.routes) {
                    route.routes.forEach((route) => {
                        if (_.intersection(route.privelege, groups).length > 0) {
                            let routes;
                            if (route.specialRoute) {
                                routes = SpecialRoute(route, props);
                            } else {
                                routes = NormalRoute(route);
                            }

                            if (!AllRoute.includes(routes)) {
                                AllRoute.push(routes);
                            }
                        }
                    });
                }
            });
        }

        AllRoute.push(
            <PrivateRoute
                key="login"
                exact
                path="/login"
                render={() => {
                    return <Redirect to="/" />;
                }}
            />
        );

        if (groups.includes(2)) {
            componentsRenderFirst = ArticleListPage;
        } else if (_.intersection(groups, [3, 5]).length > 0) {
            componentsRenderFirst = JobPosterList;
        } else if (_.intersection(groups, [7, 8])) {
            componentsRenderFirst = CustomerListPage;
        }

        AllRoute.push(
            <PrivateRoute
                key=""
                path="/"
                exact={true}
                component={componentsRenderFirst}
            ></PrivateRoute>
        );
    }

    return AllRoute;
}