import axios from "axios";
import config from "./../config/config";

export default class SectionCategoryApi {
    // base_api = "http://192.168.13.177:7000/"
    base_api = config.base_api;
    token = config.token;

    getAllSectionCategories() {
        return axios.get(this.base_api + `category_sections/`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getSectionCategoryLanguages() {
        return axios.get(this.base_api + `section_category_languages/`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getDetailSectionCategory(categoryId) {
        return axios.get(this.base_api+`section_categories/${categoryId}/`,{
            headers:{
                "Authorization":`Token ${this.token}`,
                "Content-Type":"application/json"
            }
        })
    }

    addSectionCategory(data) {
        return axios.post(
            this.base_api + "section_categories/", data,
            {
                headers: {
                    Authorization: `Token ${this.token}`,
                    "Content-Type": "application/json",
                },
            }
        );
    }

    updateSectionCategory(categoryId,data) {
        return axios.patch(this.base_api+`section_categories/${categoryId}/`,data,{
            headers:{
                "Authorization":`Token ${this.token}`,
                "Content-Type":"application/json"
            }
        })
    }

    deleteSectionCategory(categoryId) {
        return axios.delete(this.base_api+`section_categories/${categoryId}/`,{
            headers:{
                "Authorization":`Token ${this.token}`,
                "Content-Type":"application/json"
            }
        })
    }
}