import React, { Component } from "react";
import swal from "sweetalert";
import ReactGA from "react-ga";

import TitlePages from "./Elements/TitlePages";
import { Content, ContentHeader, ContentBody } from "./../components/Content";
import Card from "../components/Card";

import { withTranslation } from "react-i18next";
import UserContext from "./Context";
import config from "../config/config";

import SiteApi from "../api/SiteApi";
import LanguageApi from "../api/LanguageApi";

import "./LanguagePage.css";

class LanguagePage extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);

    this.state = {
      sites: [],
      clientLanguages: [],
      webLanguages: [],
      languagesList: [],

      activeClientLang: [],
      activeWebsiteLang: [],

      activeClient: false,
      activeSite: false,
      transAlert: "alert.",
      transPage: "pages.language_settings.",
    };
    this.getLanguageWebsite = this.getLanguageWebsite.bind(this);
    this.getLanguageClientSites = this.getLanguageClientSites.bind(this);
    this.handleChangeSites = this.handleChangeSites.bind(this);
    this.getLanguageUsed = this.getLanguageUsed.bind(this);
    this.updateLanguageUsed = this.updateLanguageUsed.bind(this);
    this.handleChangeActiveLang = this.handleChangeActiveLang.bind(this);
  }

  componentDidMount() {
    const contextValue = this.context;

    ReactGA.initialize(config.GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (contextValue.languagesList.length > 0) {
      this.setState(
        {
          languagesList: contextValue.languagesList,
        },
        () => {
          if (contextValue.activeClient) {
            this.getLanguageClientSites(contextValue.activeClient);
          }

          if (contextValue.activeSite) {
            this.getLanguageWebsite(contextValue.activeSite);
          }
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const contextValue = this.context;

    if (this.state.activeClient !== contextValue.activeClient) {
      this.setState({
        clientLanguages: [],
      });
      this.setState(
        {
          activeClient: contextValue.activeClient,
        },
        () => {
          this.getLanguageClientSites(contextValue.activeClient);
        }
      );
    }

    if (!this.state.activeSite) {
      if (contextValue.activeSite !== this.state.activeSite) {
        this.setState(
          {
            activeSite: contextValue.activeSite,
          },
          () => {
            this.getLanguageWebsite(contextValue.activeSite);
          }
        );
      }
    }

    if (contextValue.languagesList !== this.state.languagesList) {
      this.setState({
        languagesList: contextValue.languagesList,
      });
    }

    if (prevState.activeSite !== this.state.activeSite) {
      this.getLanguageWebsite(this.state.activeSite);
    }
  }

  getLanguageClientSites(client) {
    const objSite = new SiteApi();

    objSite.getAllSitesByClient(client).then((res) => {
      const { languagesList } = this.state;
      let activeLanguage = [];

      languagesList.forEach((lang) => {
        if (res.data.languages.some((lg) => lg.name === lang.name)) {
          activeLanguage.push({
            id: lang.id,
            name: lang.name,
            active: true,
          });
        } else {
          activeLanguage.push({
            id: lang.id,
            name: lang.name,
            active: false,
          });
        }
      });
      this.setState(
        {
          activeSite: res.data.sites?.[0]?.id,
          sites: res.data.sites,
          clientLanguages: activeLanguage,
        },
        () => {
          this.getLanguageWebsite(res.data.sites?.[0]?.id);
        }
      );
    });
  }

  getLanguageWebsite(site) {
    const objSite = new SiteApi();

    objSite.getAllSitesByID(site).then((res) => {
      const { languagesList } = this.state;
      let activeLanguage = [];

      languagesList.forEach((lang) => {
        if (res.data.languages.some((lg) => lg.name === lang.name)) {
          activeLanguage.push({
            id: lang.id,
            name: lang.name,
            active: true,
          });
        } else {
          activeLanguage.push({
            id: lang.id,
            name: lang.name,
            active: false,
          });
        }
      });

      this.setState({
        webLanguages: activeLanguage,
      });
    });
  }

  handleChangeSites(value) {
    this.setState({
      webLanguages: [],
    });
    if (value) {
      this.setState(
        {
          activeSite: value,
        }
      );
    }
  }

  getLanguageUsed() { }

  updateLanguageUsed(lang, destination) {
    const La = new LanguageApi();
    const {
      activeClient,
      activeSite,
      clientLanguages,
      webLanguages,
    } = this.state;
    const contextValue = this.context;

    if (destination === "client") {
      let langs = [];

      clientLanguages.forEach((lg) => {
        if (lg.active) {
          langs.push(lg.id);
        }
      });

      const dataClient = {
        languages: langs,
      };

      if (activeClient) {
        La.updateClientDetail(activeClient, dataClient)
          .then((response) => {
            if (response.data) {
              // swal("Success!", 'client and site updated', "success");
              contextValue.getClientDetail(activeClient);
            }
          })
          .catch((err) => {
            if (err.response?.data) {
              swal(
                "Error!",
                `${err.response.data.api_url ? err.response.data.api_url : ""}`,
                "error"
              );
            }
          });
      }
    } else if (destination === "site") {
      let langs = [];

      webLanguages.forEach((lg) => {
        if (lg.active) {
          langs.push(lg.id);
        }
      });

      const dataSite = {
        languages: langs,
      };

      if (activeSite) {
        La.updateSiteDetail(activeSite, dataSite)
        La.pairSiteLanguage(activeSite, dataSite)
          .then((response) => {
            if (response.data){
              contextValue.getSiteDetail(activeSite);
              contextValue.getAllSitesByClient(activeClient);
            }
          })
          .catch((err) => {
            if (err.response.data) {
              swal(
                "Error!",
                `${err.response.data.api_url ? err.response.data.api_url : ""}`,
                "error"
              );
            }
          });
      }
    }
  }

  handleChangeActiveLang(value, index, destination) {
    const { clientLanguages, webLanguages } = this.state;

    if (destination === "client") {
      if (clientLanguages.length > 0) {
        let activeClientLang = [...clientLanguages];
        let newactiveClLang = { ...activeClientLang[index] };
        newactiveClLang.active = !value;
        activeClientLang[index] = newactiveClLang;

        this.setState(
          {
            clientLanguages: activeClientLang,
          },
          () => {
            this.updateLanguageUsed(activeClientLang, "client");
          }
        );
      }
    } else if (destination === "site") {
      if (webLanguages.length > 0) {
        let activeWebLang = [...webLanguages];
        let newactiveWebLang = { ...activeWebLang[index] };
        if (newactiveWebLang.active) {
          this.unpairSitelanguage(newactiveWebLang.id)
        } else {
          this.pairSitelanguage(newactiveWebLang.id)
        }
        newactiveWebLang.active = !value;
        activeWebLang[index] = newactiveWebLang;

        this.setState(
          {
            webLanguages: activeWebLang,
          },
          () => {
            // this.updateLanguageUsed(activeWebLang, "site");
          }
        );
      }
    }
  }

  pairSitelanguage = (site) => {
    const La = new LanguageApi();
    const { activeClient, activeSite } = this.state;
    const contextValue = this.context;

    let langs = [];
    langs.push(site);

    const dataSite = {
      languages: langs,
    };

    if (activeSite) {
      La.pairSiteLanguage(activeSite, dataSite)
        .then((response) => {
          if (response.data) {
            contextValue.getSiteDetail(activeSite);
            contextValue.getAllSitesByClient(activeClient);
          }
        })
        .catch((err) => {
          if (err.response.data) {
            swal(
              "Error!",
              `${err.response.data.api_url ? err.response.data.api_url : ""}`,
              "error"
            );
          }
        });
    }
  }

  unpairSitelanguage = (site) => {
    const La = new LanguageApi();
    const { activeClient, activeSite } = this.state;
    const contextValue = this.context;

    let langs = [];
    langs.push(site);

    const dataSite = {
      languages: langs,
    };

    if (activeSite) {
      La.unpairSitelanguage(activeSite, dataSite)
        .then((response) => {
          if (response.data) {
            contextValue.getSiteDetail(activeSite);
            contextValue.getAllSitesByClient(activeClient);
          }
        })
        .catch((err) => {
          if (err.response.data) {
            swal(
              "Error!",
              `${err.response.data.api_url ? err.response.data.api_url : ""}`,
              "error"
            );
          }
        });
    }
  }

  renderLangOption(lang, isActive, index, destination) {
    // let checked;
    // if (isActive) {
    //   checked = (
    //     <input
    //       type="checkbox"
    //       checked={isActive}
    //       onChange={(event) =>
    //         this.handleChangeActiveLang(
    //           event.target.checked,
    //           index,
    //           destination
    //         )
    //       }
    //     />
    //   );
    // }

    return (
      <>
        <div className="row">
          <div className="w-60 title-lg">
            <input
              type="text"
              className="form-control input-title-lg"
              defaultValue={lang.name}
              disabled
            />
          </div>
          <div className="w-40 d-flex content-lg">
            <div className="d-flex m-auto">
              <span className={isActive ? "isActive" : "inactive"}>Active</span>

              <label className="switchCk">
                {/* {checked} */}
                <input
                  type="checkbox"
                  checked={!isActive}
                  onChange={(event) =>
                    this.handleChangeActiveLang(
                      event.target.checked,
                      index,
                      destination
                    )
                  }
                />

                <div className="sliderCk round"></div>
              </label>
              <span className={!isActive ? "active" : "inactive"}>Inactive</span>
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { t } = this.props;
    const {
      clientLanguages,
      webLanguages,
      activeSite,
      activeClient,
    } = this.state;

    let webLanguagesActive, adminLanguagesActive;

    if (webLanguages.length === 0) {
    } else {
      if (activeSite) {
        webLanguagesActive = webLanguages.map((lang, i) => {
          let active;
          // if (clientLanguages.some(lg => lg.name === lang.name)) {
          if (lang.active) {
            active = true;
          } else {
            active = false;
          }

          return this.renderLangOption(lang, active, i, "site");
        });
      } else {
      }
    }

    if (clientLanguages.length === 0) {
    } else {
      if (activeClient) {
        adminLanguagesActive = clientLanguages.map((lang, i) => {
          let active;
          if (lang.active) {
            active = true;
          } else {
            active = false;
          }

          return this.renderLangOption(lang, active, i, "client");
        });
      } else {
      }
    }

    return (
      <Content>
        <ContentHeader>
          <TitlePages title={t(this.state.transPage + "title")} />
        </ContentHeader>
        <ContentBody>
          <Card style={{ padding: "1rem" }}>
            <label className="mb-3">Set language</label>
            <div className="w-50 p-0 mb-3">
              <span>Website</span>
              <select
                className="form-control nav-L-sel-c mt-2"
                value={this.state.activeSite}
                onChange={(event) => {
                  this.handleChangeSites(event.target.value);
                }}
              >
                {this.state.sites ? (
                  <option disabled value="">
                    {" "}
                    {t(this.state.transPage + "select_site")}{" "}
                  </option>
                ) : (
                  <option disabled value={""}>
                    {" "}
                    {t(this.state.transPage + "select_client")}{" "}
                  </option>
                )}
                {this.state.sites ? (
                  this.state.sites.map((item) => {
                    return (
                      <option key={item.name} value={item.id}>
                        {" "}
                        {item.name}{" "}
                      </option>
                    );
                  })
                ) : (
                  <></>
                )}
              </select>
            </div>
            <div className="w-100 row">
              <div className="w-50">
                <div className="row">
                  <div className="w-60 mb-2">
                    <span>Website Languages</span>
                  </div>
                  <div className="w-40 mb-2">
                    <span>Set Active Language</span>
                  </div>
                </div>

                {webLanguagesActive}
              </div>

              <div className="w-50 ml-auto">
                <div className="row">
                  <div className="w-60 mb-2">
                    <span>Admin Languages</span>
                  </div>
                  <div className="w-40 mb-2">
                    <span>Set Active Language</span>
                  </div>
                </div>

                {adminLanguagesActive}
              </div>
            </div>
          </Card>
        </ContentBody>
      </Content>
    );
  }
}
export default withTranslation()(LanguagePage);
