import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import swal from 'sweetalert';
import CRMApi from '../../api/CRMApi';
import AlertHelper from '../../lib/AlertHelper';


function HistoryChangeModal(props) {
    const { t } = props
    const transPage = "modals.crm_history_change."
    const [timestamp, setTimestamp] = useState("")
    const crmApi = new CRMApi()

    const onOpenModal = () => {
        const timestamp = props.historyData.timestamp.slice(0, 19)
        setTimestamp(timestamp)
    }

    const submitData = () => {
        const newTimestamp = new Date(timestamp).toISOString()
        const data = {
            timestamp: newTimestamp
        }
        crmApi
            .updateHistory(props.historyData.id, data)
            .then(() => {
                swal(
                    "Success !",
                    "You successfully edit history !",
                    "success"
                )
                    .then(() => {
                        props.onHide()
                        props.reloadHistory()
                    })
            })
            .catch(err => {
                const alertHelper = new AlertHelper()
                alertHelper.errorHandlerAlert(err.response.data)
            })
    }

    const deleteHistory = () => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this history!",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    crmApi
                        .deleteHistory(props.historyData.id)
                        .then(() => {
                            swal(
                                "Success !",
                                "You successfully deleted history !",
                                "success"
                            )
                                .then(() => {
                                    props.onHide()
                                    props.reloadHistory()
                                })
                        })
                        .catch(err => {

                        })
                }
            });
    }

    return (
        <Modal show={props.show} onHide={() => props.onHide()} onShow={() => onOpenModal()}>
            <Modal.Header closeButton className="bg-primary" >
                <Modal.Title>{t(transPage + "title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    props.historyData &&
                    <>
                        <div className='form-group'>
                            <label>{t('description')} :</label>
                            <div>{props.historyData.description}</div>
                        </div>
                        <div className='form-group'>
                            <label>{t('timestamp')} :</label>
                            <input className='form-control' type="datetime-local" value={timestamp} onChange={(e) => { setTimestamp(e.target.value) }} />
                        </div>
                    </>
                }
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between'>
                <div>
                    {
                        props.historyData &&
                        <Button title="Delete this history" onClick={deleteHistory} variant="danger" ><i className="fas fa-trash"></i></Button>
                    }
                </div>
                <div>
                    <Button className='mr-2' variant="secondary" onClick={() => props.onHide()}>
                        {t('cancel')}
                    </Button>
                    <Button variant="primary" onClick={submitData}>
                        {t("apply")}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default withTranslation()(HistoryChangeModal);