import React, { Component } from 'react';

import { Modal, Button } from "react-bootstrap"
import { withTranslation } from 'react-i18next';

class AssetDeleteConfirm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            transPage: "modals.asset_delete."
        }

        this.deleteSelectedAsset = this.deleteSelectedAsset.bind(this)
        this.onOpenModal = this.onOpenModal.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)
        // this.onHideModal = this.onHideModal.bind(this)
    }

    deleteSelectedAsset() {
        // dari props method nya
        this.props.deleteSelectedAsset()
    }

    onOpenModal() {
    }

    onCloseModal() {

        this.props.onHide()
    }


    render() {
        const { t } = this.props;

        const listFileName = this.props.selectedAssets.map(item => {
            return (<li key={item.id}>{item?.description ? item?.description : item.id || item.asset?.name ? item.asset?.name : item.id || item?.name ? item.name : item.id}</li>)
        })

        return (
            <Modal show={this.props.show} onHide={() => this.onCloseModal()} onShow={() => this.onOpenModal()}>
                {this.props.loadingModal}
                <Modal.Header closeButton className="bg-danger">
                    <Modal.Title>{t(this.state.transPage + "title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p> {t(this.state.transPage + "confirmation_text")} </p>
                    <ul>
                        {listFileName}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.props.onHide()}>
                        {t('cancel')}
                    </Button>
                    <Button variant="danger" onClick={() => this.deleteSelectedAsset()}>
                        <i className="fa fa-trash">&nbsp;</i>
                        {t(this.state.transPage + "delete_assets")}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(AssetDeleteConfirm);