import axios from "axios";
import config from "./../config/config";
import qs from "qs";
// import Cookies from "js-cookie";

export default class SuperAdminApi {
    base_api = config.base_api;
    // token = Cookies.get("key");
    token = config.token;

    getAllClient(token, param) {
        return axios.get(this.base_api + "clients/", {
            params: param,
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getAllSites(token, clientId) {
        return axios.get(this.base_api + `clients/${clientId}/`, {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getIsAdmin(token) {
        return axios.get(this.base_api + `sites/?is_admin=true`, {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getSitesByAdmin(token, id, params) {
        return axios.get(this.base_api + `sites/get_site_names/?client=${id}`, {
            params: params,
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getClientDetail(token, clientId) {
        return axios.get(this.base_api + `clients/${clientId}/`, {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getSiteDetail(token, siteId) {
        return axios.get(this.base_api + `sites/${siteId}/`, {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getAllLanguages(token) {
        return axios.get(this.base_api + "languages/", {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getAllDomain(token, siteId) {
        return axios.get(this.base_api + `site_domains/?site=${siteId}`, {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getAllSubscriptions(token) {
        return axios.get(this.base_api + "subscriptions/", {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getAllLanguageBySite(sites) {
        let params = new URLSearchParams();
        sites.forEach((s) => {
            params.append("site", s);
        });

        return axios.get(this.base_api + "languages/", {
            params: params,
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getSubscriptionByClient(params) {
        return axios.get(this.base_api + "subscriptions/", {
            params: params,
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    updateClientDetail(token, clientId, client) {
        var bodyFormData = new FormData();

        bodyFormData.append(`name`, client.name);
        bodyFormData.append(`address`, client.address);
        bodyFormData.append(`phone`, client.phone);

        if (client.subscriptions && client.subscriptions.length){
            client.subscriptions.forEach((s, i) => {
                bodyFormData.append(`subscriptions`, s);
            });
        }

        if (client.files && client.files[0] && client.preview && client.preview[0]) {
            bodyFormData.append(`logo`, client.files[0][0]);
        } else if (client.files && !client.files[0] && client.preview && !client.preview[0]) {
            bodyFormData.append(`logo`, "");
        }
        if (client.files && client.files[1] && client.preview && client.preview[1]) {
            bodyFormData.append(`favicon`, client.files[1][0]);
        } else if (client.files && !client.files[1] && client.preview && !client.preview[1]) {
            bodyFormData.append(`favicon`, "");
        }

        return axios.patch(this.base_api + `clients/${clientId}/`, bodyFormData, {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
    }

    updateClient(client, dataClient){
        return axios.patch(this.base_api + `clients/${client}/`, dataClient, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    deleteClient(client){
        return axios.delete(this.base_api + `clients/${client}/`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    updateSiteDetail(token, siteId, site) {
        return axios.patch(this.base_api + `sites/${siteId}/`, site, {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
    }

    addClient(token, client) {
        var bodyFormData = new FormData();

        bodyFormData.append(`name`, client.name);
        bodyFormData.append(`address`, client.address);
        bodyFormData.append(`phone`, client.phone);
        bodyFormData.append(`subscriptions`, client.subscriptions);

        if (client.files && client.files[0]) {
            bodyFormData.append(`logo`, client.files[0][0]);
        }
        if (client.files && client.files[1]) {
            bodyFormData.append(`favicon`, client.files[1][0]);
        }   

        if (client.languages && client.languages.length) {
            for (const key in client.languages) {
                bodyFormData.append(`languages`, client.languages[key]);
            }
        }

        return axios.post(this.base_api + "clients/", bodyFormData, {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "multipart/form-data",
            },
        });
    }

    addSite(token, site) {
        return axios.post(this.base_api + "sites/", site, {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
    }

    deleteSite(site){
        return axios.delete(this.base_api + `sites/${site}/`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    addDomain(token, data) {
        return axios.post(this.base_api + "site_domains/", data, {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getUsersBySite(token, userId, param) {
        return axios.get(
            this.base_api + `users/?sites=${userId}&is_token=false`,
            {
                params: param,
                headers: {
                    Authorization: `Token ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
    }

    getSites(token) {
        return axios.get(this.base_api + `sites/`, {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getGroups(token) {
        return axios.get(this.base_api + `groups/`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getGroupsBasedSubscriptions(token, param) {
        return axios.get(this.base_api + `groups/`, {
            params: {
                subscription: param,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getUsersDetail(token, userId) {
        return axios.get(this.base_api + `users/${userId}/`, {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
    }

    addNewUser(token, user) {
        return axios.post(this.base_api + "users/", user, {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
    }

    updateUser(token, userId, user) {
        return axios.patch(this.base_api + `users/${userId}/`, user, {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
    }

    deleteUser(token, user) {
        return axios.delete(this.base_api + `users/delete_users/`, {
            data: {
                users: user,
            },
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
            },
        });
    }
}
