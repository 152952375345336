import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert2";
// import withReactContent from 'sweetalert2-react-content'

import Context from "../../pages/Context"
import PageApi from "../../api/PageApi";
// import MenuApi from "../../api/MenuApi"
import SectionApi from "../../api/SectionApi";
// import GeneralApi from "../../api/GeneralApi"

import AlertHelper from "./../../lib/AlertHelper";
import { withTranslation } from "react-i18next";

class SectionCatalogMultipleUpdate extends Component {
  static contextType = Context
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      pageId: 0,
      name: "",
      pages: [],

      index: 0,
      files: [],

      selectedSections: [],

      overlay: "",
      isLoading: false,
      transPage: "modals.section_catalog_multiple_update.",
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    // this.handleMenuChange = this.handleMenuChange.bind(this)
    // this.handlePageChange = this.handlePageChange.bind(this)
    // this.handleIndexChange = this.handleIndexChange.bind(this)

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.setOverlay = this.setOverlay.bind(this);

    this.getPages = this.getPages.bind(this);
  }

  handleNameChange(id, value) {
    let names = [...this.state.names];
    let name = { ...names[id] };
    name.name = value;
    names[id] = name;

    this.setState(
      {
        name: names[0].name,
        names: names,
      }
    );
  }

  handleFileChange(e) {
    this.setState(
      {
        files: e.target.files,
      }
    );
  }

  onOpenModal() {
    const templateId = localStorage.getItem("templateId");

    this.getPages({
      template: templateId,
    });
  }

  onCloseModal() {
    this.setState(
      {
        id: 0,
        pageId: 0,
        name: "",
        pages: [],
      },
      () => {
        this.props.onHide();
      }
    );
  }

  getPages(params) {
    const objPage = new PageApi();

    objPage.getAllPages(params).then((res) => {
      const result = res.data.results.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });

      this.setState({
        pages: result,
      });
    });
  }

  setLoading() {
    this.setState(
      {
        isLoading: !this.state.isLoading,
      },
      () => {
        this.setOverlay();
      }
    );
  }

  setOverlay() {
    var overlay = "";
    if (this.state.isLoading) {
      overlay = (
        <div className="overlay d-flex justify-content-center align-items-center">
          <i className="fa fa-2x fa-sync fa-spin"></i>
        </div>
      );
    }

    this.setState({
      overlay: overlay,
    });
  }

  handleSubmit() {
		const { activeClient } = this.context;

    this.setLoading();
    const objSection = new SectionApi();

    const formData = new FormData();

    if (this.state.files.length > 0) {
      [...this.state.files].forEach((item) => {
        formData.append(`zip_files`, item, item.name);
      });
    }

    formData.append("client", activeClient);
    formData.append("catalog_type", "section");

    objSection
      .sectionBulkUpdateNew(formData)
      .then((res) => {
        const result = res.data.map((item, index) => {
          return `<li key={${index}}>${item}</li>`;
        });

        this.props.onHide();
        swal.fire({
          title: "Success!",
          html: `<ul>${result}</ul>`,
          icon: "success",
        });
        this.props.getSectionCatalog();
        this.setLoading();

        setTimeout(() => {
          window.location.reload();
        }, 0);
      })
      .catch((err) => {
        const objAlert = new AlertHelper();
        const resErr = err?.response?.data ? err?.response?.data : err.message;
        objAlert.errorHandlerAlert(resErr);
      });
  }

  render() {
    const { t } = this.props;
    var HeaderText = t(this.state.transPage + "add_multiple_section");
    var BtnSubmit = (
      <Button variant="primary" onClick={() => this.handleSubmit()}>
        <i className="fa fa-plus">&nbsp;</i>
        {t(this.state.transPage + "add_multiple_section")}
      </Button>
    );

    return (
      <Modal
        show={this.props.show}
        onHide={() => this.onCloseModal()}
        onShow={() => this.onOpenModal()}
      >
        {this.state.overlay}
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title> {HeaderText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label> {t("zip_file")} </label>
              <input
                type="file"
                className="form-control"
                multiple
                onChange={(event) => this.handleFileChange(event)}
                accept=".zip,.rar,.7zip"
              ></input>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>{BtnSubmit}</Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(SectionCatalogMultipleUpdate);
