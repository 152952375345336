import React, { Component } from "react";
import { Button, ButtonGroup, DropdownButton, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import swal from "sweetalert";

import AlertHelper from "../lib/AlertHelper";

// import Card,{ CardBody, CardHeader, CardTitle  } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "../components/Content";
import ArticleDeleteConfirm from "../components/Modal/ArticleDeleteConfirm";
import ArticleStatusConfirm from "../components/Modal/ArticleStatusConfirm";
import TextDeleteConfirm from "../components/Modal/TextDeleteConfirm";
import WidgetAssetDeleteConfirm from "../components/Modal/WidgetAssetDeleteConfirm";
import GeneralDeleteConfirm from "../components/Modal/GeneralDeleteConfirm";

import AssetsElement from "./Elements/AssetsElement";
import TextElement from "./Elements/TextElement";
import ArticlesElement from "./Elements/ArticlesElement";
import GeneralElement from "./Elements/GeneralElement";

import WidgetApi from "../api/WidgetApi";
import ArticleApi from "../api/ArticleApi";
import SectionApi from "../api/SectionApi";
import WidgetAssetAddModal from "../components/Modal/WidgetAssetAddModal";
import WidgetTextAddModal from "../components/Modal/WidgetTextAddModal";
// import ArticleElement from './Elements/ArticlesElement';
import config from "../config/config";
import ReactGA from "react-ga";
import TitlePages from "./Elements/TitlePages";
import Card, { CardBody, CardHeader } from "../components/Card";

class WidgetDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: true,
      widget: {},
      collectionsList: [],
      textId: 0,
      generals: [],
      selectedArticles: [],
      selectedAssets: [],
      selectedTextElement: [],
      selectedGeneral: [],
      selectedCollectionId: 0,
      deleteDisable: true,
      statusDisable: true,

      showAssetModal: false,
      showTextModal: false,
      showConfirmDelete: false,
      showConfirmStatus: false,
    };

    this.getGeneralData = this.getGeneralData.bind(this);
    this.setTextId = this.setTextId.bind(this);
    this.getWidgetDetail = this.getWidgetDetail.bind(this);
    this.getAllCollections = this.getAllCollections.bind(this);

    this.setSelectedItem = this.setSelectedItem.bind(this);
    this.setSelectedCollectionId = this.setSelectedCollectionId.bind(this);
    // this.setSelectedAsset = this.setSelectedAsset.bind(this)
    // this.setSelectedText = this.setSelectedText.bind(this)

    this.changeSelectedArticleStatus =
      this.changeSelectedArticleStatus.bind(this);
    this.deleteSelectedArticle = this.deleteSelectedArticle.bind(this);
    this.deleteSelectedAsset = this.deleteSelectedAsset.bind(this);
    this.deleteSelectedText = this.deleteSelectedText.bind(this);
    this.deleteSelectedGeneral = this.deleteSelectedGeneral.bind(this);

    this.setShowTextModal = this.setShowTextModal.bind(this);
    this.setShowAssetModal = this.setShowAssetModal.bind(this);
    this.setShowConfirmDelete = this.setShowConfirmDelete.bind(this);
    this.setShowConfirmStatus = this.setShowConfirmStatus.bind(this);

    this.updateSectionWidgetPair = this.updateSectionWidgetPair.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    ReactGA.initialize(config.GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    const widgetId = this.props.match.params.widgetId;
    this.getWidgetDetail(widgetId, this.getAllCollections);
  }

  componentWillReceiveProps(newPops) {
    const wId = newPops.match.params.widgetId;
    this.getWidgetDetail(wId, this.getAllCollections);
  }

  setSelectedItem(widgetCategory, itemData, value) {
    var lastSelected = [];
    var indexDelete = -1;
    var stateSelected = "";

    if (widgetCategory === "article") {
      lastSelected = [...this.state.selectedArticles];
      stateSelected = "selectedArticles";
    } else if (widgetCategory === "asset") {
      lastSelected = [...this.state.selectedAssets];
      stateSelected = "selectedAssets";
    } else if (widgetCategory === "text") {
      lastSelected = [...this.state.selectedTextElement];
      stateSelected = "selectedTextElement";
    } else if (widgetCategory === "general") {
      lastSelected = [...this.state.selectedGeneral];
      stateSelected = "selectedGeneral";
    }

    if (value === true) {
      lastSelected.push(itemData);
    } else {
      this.state[stateSelected].forEach((item, index) => {
        if (item.id === itemData.id) {
          indexDelete = index;
        }
      });

      lastSelected.splice(indexDelete, 1);
    }

    this.setState(
      {
        [stateSelected]: lastSelected,
      },
      () => {
        if (this.state[stateSelected].length > 0) {
          this.setState({
            deleteDisable: false,
            statusDisable: false,
          });
        } else {
          this.setState({
            deleteDisable: true,
            statusDisable: true,
          });
        }
      }
    );
  }

  setSelectedAsset(asset, value) {
    var lastAssets = [...this.setState.selectedAssets];
    lastAssets.push(asset);
    this.setState(
      {
        selectedAssets: lastAssets,
      },
      () => {
        if (this.setSelectedAsset.length > 0) {
          this.setState({
            deleteDisable: false,
            statusDisable: false,
          });
        } else {
          this.setState({
            deleteDisable: true,
            statusDisable: true,
          });
        }
      }
    );
  }

  deleteSelectedGeneral() {
    const widgetObj = new WidgetApi();

    const widgetId = this.props.match.params.widgetId;
    const elements = this.state.selectedGeneral.map((item) => {
      return {
        id: item.id,
        category: item.type,
      };
    });

    const data = {
      widget_id: widgetId,
      elements: elements,
    };

    widgetObj
      .widgetElementUnpair(data)
      .then((res) => {
        this.setState(
          {
            deleteDisable: true,
            selectedArticles: [],
            selectedAssets: [],
            selectedTextElement: [],
            selectedGeneral: [],
          },
          () => {
            this.getWidgetDetail(widgetId);
            this.setShowConfirmDelete();
            swal(
              "Success !",
              "You successfully delete Element on this widget ",
              "success"
            );
            window.location.reload();
          }
        );
      })
      .catch((err) => {
        this.setShowConfirmDelete();
        swal("Error !", err.message, "error");
      });
  }

  // setSelectedText(text,value){
  //     this.setState({
  //         selectedTextElement:this.state.selectedTextElement.concat(text)
  //     },() => {
  //         if(this.setSelectedText.length > 0) {
  //             this.setState({
  //                 deleteDisable:false,
  //                 statusDisable:false
  //             })
  //         } else {
  //             this.setState({
  //                 deleteDisable:true,
  //                 statusDisable:true
  //             })
  //         }
  //     })
  // }

  setTextId(value) {
    this.setState(
      {
        textId: value,
      },
      () => {
        this.setShowTextModal();
      }
    );
  }

  setShowAssetModal() {
    this.setState({
      showAssetModal: !this.state.showAssetModal,
    });
  }

  setShowTextModal() {
    this.setState({
      showTextModal: !this.state.showTextModal,
    });
  }

  setShowConfirmDelete() {
    this.setState({
      showConfirmDelete: !this.state.showConfirmDelete,
    });
  }

  setShowConfirmStatus() {
    this.setState({
      showConfirmStatus: !this.state.showConfirmStatus,
    });
  }

  setSelectedCollectionId(e) {
    this.setState({
      selectedCollectionId: parseInt(e.target.value),
    });
  }

  changeSelectedArticleStatus() {
    const articleObj = new ArticleApi();

    const widgetId = this.props.match.params.widgetId;
    const articles = this.state.selectedArticles.map((item) => {
      return item.id;
    });

    articleObj
      .changeMultipleArticleStatus(articles)
      .then((res) => {
        this.setState({
          deleteDisable: true,
          selectedArticles: [],
          selectedAssets: [],
          selectedTextElement: [],
        });
        this.getWidgetDetail(widgetId);
        this.setShowConfirmStatus();
        swal(
          "Success !",
          "You successfully change articles status on this widget ",
          "success"
        );
      })
      .catch((err) => {
        this.setShowConfirmStatus();
        swal("Error !", err.message, "error");
      });
  }

  deleteSelectedArticle() {
    const widgetObj = new WidgetApi();

    const widgetId = this.props.match.params.widgetId;
    const articles = this.state.selectedArticles.map((item) => {
      return item.id;
    });

    widgetObj
      .widgetArticleUnpair(widgetId, articles)
      .then((res) => {
        this.setState(
          {
            deleteDisable: true,
            selectedArticles: [],
            selectedAssets: [],
            selectedTextElement: [],
          },
          () => {
            this.getWidgetDetail(widgetId);
            this.setShowConfirmDelete();
            swal(
              "Success !",
              "You successfully delete articles on this widget ",
              "success"
            );
            window.location.reload();
          }
        );
      })
      .catch((err) => {
        this.setShowConfirmDelete();
        swal("Error !", err.message, "error");
      });
  }

  deleteSelectedAsset() {
    const widgetObj = new WidgetApi();

    const widgetId = this.props.match.params.widgetId;
    const assets = this.state.selectedAssets.map((item) => {
      return item.asset.id;
    });

    widgetObj
      .widgetAssetUnpair(widgetId, assets)
      .then((res) => {
        this.setState(
          {
            deleteDisable: true,
            selectedArticles: [],
            selectedAssets: [],
            selectedTextElement: [],
          },
          () => {
            this.getWidgetDetail(widgetId);
            this.setShowConfirmDelete();
            swal(
              "Success !",
              "You successfully delete assets on this widget ",
              "success"
            );
            window.location.reload();
          }
        );
      })
      .catch((err) => {
        this.setShowConfirmDelete();
        swal("Error !", err.message, "error");
      });
  }

  deleteSelectedText() {
    const widgetObj = new WidgetApi();

    const widgetId = this.props.match.params.widgetId;
    const texts = this.state.selectedTextElement.map((item) => {
      return item.id;
    });

    widgetObj
      .widgetTextDelete(widgetId, texts)
      .then((res) => {
        this.setState(
          {
            deleteDisable: true,
            selectedArticles: [],
            selectedAssets: [],
            selectedTextElement: [],
          },
          () => {
            this.getWidgetDetail(widgetId);
            this.setShowConfirmDelete();
            swal(
              "Success !",
              "You successfully delete text on this widget ",
              "success"
            );
            window.location.reload();
          }
        );
      })
      .catch((err) => {
        this.setShowConfirmDelete();
        swal("Error !", err.message, "error");
      });
  }

  updateSubmit() {}

  getWidgetDetail(neoWidgetId, callback) {
    const objWidget = new WidgetApi();
    const widgetId = neoWidgetId;

    const query = new URLSearchParams(this.props.location.search);

    const params = {
      section: query.get("section_widget"),
    };

    objWidget
      .getDetailWidget(widgetId, params)
      .then((res) => {
        this.setState(
          {
            widget: res.data,
            selectedCollectionId: res.data.id,
          },
          () => {
            if (typeof callback === "function") {
              callback();
            } else {
              this.getGeneralData();
            }
          }
        );
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  getGeneralData() {
    const general = []
      .concat(
        this.state.widget.text_elements?.map((item) => {
          return {
            ...item,
            type: "Text",
          };
        })
      )
      .concat(
        this.state.widget.widget_assets?.map((item) => {
          return {
            ...item,
            type: "Media",
          };
        })
      )
      .concat(
        this.state.widget.articles?.map((item) => {
          return {
            ...item,
            type: "Article",
          };
        })
      )
      .sort((a, b) => a.index - b.index);

    this.setState({
      generals: general,
    });
  }

  getAllCollections() {
    const objWidget = new WidgetApi();

    const params = {
      template: Number(localStorage.getItem("templateId")),
      // template: 41,
      max_size: true,
      category: 3,
      internal: this.state.widget.is_internal,
    };

    objWidget.getAllWidgets(params).then((res) => {
      const collectionsList = res.data.results.map((item) => {
        var page = "";
        if (item.page) {
          page = " - " + item.page;
        }

        return {
          ...item,
          namePage: item.name + page,
        };
      });

      this.setState({
        collectionsList: collectionsList,
      });
    });
  }

  updateSectionWidgetPair() {
    const objSection = new SectionApi();
    const query = new URLSearchParams(this.props.location.search);

    const sectionDetailId = query.get("section_widget");
    const widget = this.state.selectedCollectionId;
    const name = query.get("name");
    const sectionId = query.get("section");

    objSection
      .updateSectionDetailWidgetPair(sectionDetailId, sectionId, name, widget)
      .then((res) => {
        swal(
          "Success !",
          "You successfully updated Section Widget pair ",
          "success"
        );
        window.location.assign(`/section/developer/${sectionId}`);
      })
      .catch((err) => {
        const objAlert = new AlertHelper();
        const resErr = err?.response?.data ? err?.response?.data : err.message;
        objAlert.errorHandlerAlert(resErr);
      });
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    var ElementPad = "";
    var ButtonAdd = "";
    var ButtonDeleteSelected = "";
    var ButtonChangeStatus = "";

    var AssetAdd = "";
    var TextAdd = "";
    var DeleteConfirmation = "";
    var StatusConfirmation = "";

    const widgetId = this.props.match.params.widgetId;
    const query = new URLSearchParams(this.props.location.search);

    const params = {
      section: query.get("section_widget"),
    };

    if (this.state.widget.category_name === "Article") {
      if (!this.state.widget.is_internal) {
        ElementPad = (
          <ArticlesElement
            widgetId={widgetId}
            sectionWidgetId={params.section}
            setSelectedArticle={this.setSelectedItem}
            selectedArticles={this.state.selectedArticles}
            articles={this.state.widget.widget_articles}
          ></ArticlesElement>
        );

        AssetAdd = (
          <WidgetAssetAddModal
            show={this.state.showAssetModal}
            onHide={this.setShowAssetModal}
            widgetId={this.state.widget.id}
            getWidgetDetail={this.getWidgetDetail}
            selectedAssets={this.state.selectedAssets}
          ></WidgetAssetAddModal>
        );
      } else {
        ElementPad = (
          <ArticlesElement
            widgetId={widgetId}
            sectionWidgetId={params.section}
            setSelectedArticle={this.setSelectedItem}
            selectedArticles={this.state.selectedArticles}
            articles={this.state.widget.widget_articles}
          ></ArticlesElement>
        );
        ButtonAdd = (
          <Link to={`/article/widgets/detail/${widgetId}`}>
            <Button className="float-right">
              <i className="fa fa-plus">&nbsp;</i>Add New
            </Button>
          </Link>
        );
        ButtonDeleteSelected = (
          <Button
            disabled={this.state.deleteDisable}
            variant="danger"
            style={{ marginRight: 10 }}
            onClick={this.setShowConfirmDelete}
            className="float-right"
          >
            {" "}
            <i className="fa fa-trash">&nbsp;</i>
            Delete Seleceted
          </Button>
        );
        ButtonChangeStatus = (
          <Button
            disabled={this.state.statusDisable}
            variant="primary"
            style={{ marginRight: 10 }}
            onClick={this.setShowConfirmStatus}
            className="float-right"
          >
            {" "}
            <i className="fa fa-toggle-off">&nbsp;</i>
            Change Status
          </Button>
        );
        DeleteConfirmation = (
          <ArticleDeleteConfirm
            show={this.state.showConfirmDelete}
            onHide={this.setShowConfirmDelete}
            selectedArticles={this.state.selectedArticles}
            deleteSelectedArticle={this.deleteSelectedArticle}
          ></ArticleDeleteConfirm>
        );
        StatusConfirmation = (
          <ArticleStatusConfirm
            show={this.state.showConfirmStatus}
            onHide={this.setShowConfirmStatus}
            selectedArticles={this.state.selectedArticles}
            changeStatus={this.changeSelectedArticleStatus}
          ></ArticleStatusConfirm>
        );
      }
    } else if (this.state.widget.category_name === "Media") {
      ElementPad = (
        <AssetsElement
          setSelectedAsset={this.setSelectedItem}
          widget_assets={this.state.widget.widget_assets}
        ></AssetsElement>
      );
      ButtonAdd = (
        <Button
          className="float-right"
          onClick={() => {
            this.setShowAssetModal();
          }}
        >
          <i className="fa fa-plus">&nbsp;</i>Add New
        </Button>
      );
      ButtonDeleteSelected = (
        <Button
          disabled={this.state.deleteDisable}
          variant="danger"
          style={{ marginRight: 10 }}
          onClick={this.setShowConfirmDelete}
          className="float-right"
        >
          {" "}
          <i className="fa fa-trash">&nbsp;</i>
          Delete Seleceted
        </Button>
      );
      DeleteConfirmation = (
        <WidgetAssetDeleteConfirm
          show={this.state.showConfirmDelete}
          onHide={this.setShowConfirmDelete}
          selectedAssets={this.state.selectedAssets}
          deleteSelectedAsset={this.deleteSelectedAsset}
        ></WidgetAssetDeleteConfirm>
      );

      AssetAdd = (
        <WidgetAssetAddModal
          show={this.state.showAssetModal}
          onHide={this.setShowAssetModal}
          widgetId={this.state.widget.id}
          getWidgetDetail={this.getWidgetDetail}
          selectedAssets={this.state.selectedAssets}
        ></WidgetAssetAddModal>
      );
    } else if (this.state.widget.category_name === "Text") {
      ElementPad = (
        <TextElement
          setTextId={this.setTextId}
          setShowTextModal={this.setShowTextModal}
          setSelectedText={this.setSelectedItem}
          text_elements={this.state.widget.text_elements}
        ></TextElement>
      );
      ButtonAdd = (
        <Button
          className="float-right"
          onClick={() => {
            this.setState({ textId: 0 });
            this.setShowTextModal();
          }}
        >
          <i className="fa fa-plus">&nbsp;</i>Add New Text
        </Button>
      );
      ButtonDeleteSelected = (
        <Button
          disabled={this.state.deleteDisable}
          variant="danger"
          style={{ marginRight: 10 }}
          onClick={this.setShowConfirmDelete}
          className=" float-right"
        >
          <i className="fa fa-trash">&nbsp;</i>
          Delete Seleceted
        </Button>
      );
      TextAdd = (
        <WidgetTextAddModal
          widgetName={this.state.widget.name}
          show={this.state.showTextModal}
          onHide={this.setShowTextModal}
          widgetId={this.state.widget.id}
          getWidgetDetail={this.getWidgetDetail}
          textId={this.state.textId}
        ></WidgetTextAddModal>
      );
      DeleteConfirmation = (
        <TextDeleteConfirm
          show={this.state.showConfirmDelete}
          onHide={this.setShowConfirmDelete}
          selectedTextElement={this.state.selectedTextElement}
          deleteSelectedText={this.deleteSelectedText}
        ></TextDeleteConfirm>
      );
    } else if (this.state.widget.category_name === "General") {
      ElementPad = (
        <GeneralElement
          generals={this.state.generals}
          selectedGeneral={this.state.selectedGeneral}
          setSelectedGeneral={this.setSelectedItem}
          widgetId={this.state.widget.id}
          setTextId={this.setTextId}
          getGeneralData={this.getGeneralData}
        ></GeneralElement>
      );

      ButtonAdd = (
        <DropdownButton
          as={ButtonGroup}
          title={"Add Element "}
          id="bg-vertical-dropdown-2"
          className="float-right"
        >
          <Dropdown.Item
            eventKey="1"
            href={`/article/widgets/detail/${widgetId}`}
          >
            <i className="fa fa-newspaper">&nbsp;</i> Add Article
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="2"
            onClick={() => {
              this.setShowAssetModal();
            }}
          >
            <i className="fa fa-folder-open">&nbsp;</i> Add Asset
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="3"
            onClick={() => {
              this.setState({ textId: 0 });
              this.setShowTextModal();
            }}
          >
            <i className="fa fa-font">&nbsp;</i> Add Text
          </Dropdown.Item>
        </DropdownButton>
      );

      AssetAdd = (
        <WidgetAssetAddModal
          show={this.state.showAssetModal}
          onHide={this.setShowAssetModal}
          widgetId={this.state.widget.id}
          getWidgetDetail={this.getWidgetDetail}
          selectedAssets={this.state.selectedAssets}
        ></WidgetAssetAddModal>
      );

      TextAdd = (
        <WidgetTextAddModal
          widgetName={this.state.widget.name}
          show={this.state.showTextModal}
          onHide={this.setShowTextModal}
          widgetId={this.state.widget.id}
          getWidgetDetail={this.getWidgetDetail}
          textId={this.state.textId}
        ></WidgetTextAddModal>
      );

      ButtonDeleteSelected = (
        <Button
          disabled={this.state.deleteDisable}
          variant="danger"
          style={{ marginRight: 10 }}
          onClick={this.setShowConfirmDelete}
          className=" float-right"
        >
          <i className="fa fa-trash">&nbsp;</i>
          Delete Seleceted
        </Button>
      );
      // TextAdd = <WidgetTextAddModal
      //     widgetName={this.state.widget.name}
      //     show={this.state.showTextModal}
      //     onHide={this.setShowTextModal}
      //     widgetId={this.state.widget.id}
      //     getWidgetDetail={this.getWidgetDetail}
      //     textId={this.state.textId}

      // >
      // </WidgetTextAddModal>
      DeleteConfirmation = (
        <GeneralDeleteConfirm
          show={this.state.showConfirmDelete}
          onHide={this.setShowConfirmDelete}
          selectedGeneral={this.state.selectedGeneral}
          deleteSelectedGeneral={this.deleteSelectedGeneral}
        ></GeneralDeleteConfirm>
      );
    } else if (this.state.widget.category_name === "Job Poster") {
      ElementPad = (
        <ArticlesElement
          widgetId={widgetId}
          sectionWidgetId={params.section}
          setSelectedArticle={this.setSelectedItem}
          selectedArticles={this.state.selectedArticles}
          articles={this.state.widget.widget_articles}
        ></ArticlesElement>
      );
      ButtonAdd = <></>;
      //   (
      //     <Link to={`/job-posters/add/`}>
      //       <Button className='float-right'>
      //         <i className='fa fa-plus'>&nbsp;</i>Add New
      //       </Button>
      //     </Link>
      //   )
      ButtonDeleteSelected = (
        <Button
          disabled={this.state.deleteDisable}
          variant="danger"
          style={{ marginRight: 10 }}
          onClick={this.setShowConfirmDelete}
          className="float-right"
        >
          {" "}
          <i className="fa fa-trash">&nbsp;</i>
          Delete Seleceted
        </Button>
      );
      ButtonChangeStatus = (
        <Button
          disabled={this.state.statusDisable}
          variant="primary"
          style={{ marginRight: 10 }}
          onClick={this.setShowConfirmStatus}
          className="float-right"
        >
          {" "}
          <i className="fa fa-toggle-off">&nbsp;</i>
          Change Status
        </Button>
      );
      DeleteConfirmation = (
        <ArticleDeleteConfirm
          show={this.state.showConfirmDelete}
          onHide={this.setShowConfirmDelete}
          selectedArticles={this.state.selectedArticles}
          deleteSelectedArticle={this.deleteSelectedArticle}
        ></ArticleDeleteConfirm>
      );
      StatusConfirmation = (
        <ArticleStatusConfirm
          show={this.state.showConfirmStatus}
          onHide={this.setShowConfirmStatus}
          selectedArticles={this.state.selectedArticles}
          changeStatus={this.changeSelectedArticleStatus}
        ></ArticleStatusConfirm>
      );
    }

    return (
      <Content>
        <ContentHeader>
          <TitlePages title="Widgets Detail" />
        </ContentHeader>
        <ContentBody>
          <Card>
            <CardHeader>
              <div className="container-fluid">
                <div className="row">
                  <div
                    className="back-button col-6"
                    style={{ padding: "0 5px" }}
                  >
                    <Button
                      onClick={this.goBack}
                      className="btn btn-default col-md-2 mt-3 ml-auto"
                    >
                      <i className="fa fa-arrow-left">&nbsp;</i>
                      Back
                    </Button>
                    <h1>{this.state.widget.section_widget_name}</h1>
                  </div>

                  <div className="col-md-6">
                    {ButtonAdd}
                    {ButtonChangeStatus}
                    {ButtonDeleteSelected}
                  </div>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {ElementPad}
              {AssetAdd}
              {TextAdd}
              {DeleteConfirmation}
              {StatusConfirmation}
            </CardBody>
          </Card>
        </ContentBody>
      </Content>
    );
  }
}

export default WidgetDetailPage;
