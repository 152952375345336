import React, { Component } from 'react';
import CardHeader from "./CardHeader"
import CardTitle from "./CardTitle"
import CardBody from "./CardBody"
import CardFooter from "./CardFooter"

class Card extends Component {

    render() {

        const className = this.props.className || ""

        return (
            <div className={"card "+className} {...this.props}>
                {this.props.children}
            </div>
        );
    }
}

export { Card , CardHeader, CardTitle, CardBody, CardFooter };
export default Card;