import { Card, Table, Accordion, Button } from "react-bootstrap";
import PropTypes from "prop-types"
import { exportToExcel } from '../../plugins/exportToExcel/exportToExcel'
import { Link } from "react-router-dom";
import CardChart from "./CardChart/CardChart";
import React from "react";
import CRMApi from "../../api/CRMApi";
import Context from "../../pages/Context";

class AttributeReportPage extends React.Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.props.attributeReports.forEach(item => {
            this.getAttributeData(item)
        })
    }


    handleDownloadTable = (data) => {
        let dataToExport = []
        let num = 1
        data.forEach(category => {
            category?.members?.forEach(member => {
                dataToExport.push({
                    'No': num,
                    'Company': member.member__name,
                    'Description': category?.variable,
                    'Timestamp': member.timestamp
                })
                num++
            })
        })

        exportToExcel(dataToExport, "Reports")
    }

    standardizeInputDate(date, timezoneOffset) {
        if (!date) return null

        const absTimezoneOffset = Math.abs(timezoneOffset)
        const sign = timezoneOffset > 0 ? "-" : "+"
        const offset = sign + Math.floor(absTimezoneOffset / 60).toString().padStart(2, '0') + ":" + (Math.floor(absTimezoneOffset) % 60).toString().padStart(2, '0');

        return date + "T00:00:00" + offset
    }

    getAttributeData = async (reportObject) => {
        const timezoneOffset = new Date().getTimezoneOffset()
        const crmApi = new CRMApi();
        const { activeClient } = this.context
        const params = { client: activeClient }
        let data = { report: reportObject.id }

        if (reportObject.use_date_range) {
            data.start_date = this.standardizeInputDate(this.props.period.start_date, timezoneOffset)
            data.end_date = this.standardizeInputDate(this.props.period.end_date, timezoneOffset)
        }

        const response = await crmApi.getDynamicReport(params, data)

        this.setState({
            ["report-" + reportObject.id]: response?.data?.results
        })
    }

    render() {
        return <div>
            {
                this.props.attributeReports.map(attributeReport => {
                    const reportId = attributeReport.id
                    const attributeData = this.state["report-" + reportId]
                    const labels = attributeData?.map(item => item.variable)
                    const data = attributeData?.map(item => item.count)

                    return (
                        <div className="row">
                            <div className="col-lg-4">
                                <Card>
                                    <Card.Header>
                                        <Button
                                            size="sm"
                                            variant="outline-primary"
                                            onClick={() => this.handleDownloadTable(attributeData)}
                                        >
                                            <i className="fas fa-download"></i>
                                        </Button>
                                    </Card.Header>
                                    <Card.Body>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>{attributeReport.title}</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <Accordion as="tbody">
                                                {
                                                    attributeData?.map((report, idx) => (
                                                        <>
                                                            <Accordion.Toggle eventKey={report.variable + idx} as="tr" style={{ cursor: "pointer" }}>
                                                                <td>{report.variable}</td>
                                                                <td>{report.count}</td>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={report.variable + idx}>
                                                                <div className="pb-4 ml-3">
                                                                    {report.members.map(member => (<div><Link to={`/customers/detail/${member.member__id}`}>{member.member__name}</Link></div>))}
                                                                </div>
                                                            </Accordion.Collapse>
                                                        </>
                                                    ))
                                                }
                                            </Accordion>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-lg-8">
                                <CardChart
                                    title={attributeReport.title}
                                    labels={labels}
                                    data={data}
                                />
                            </div>
                        </div>
                    )
                })
            }
        </div>;
    }
}

AttributeReportPage.propTypes = {
    attributeReports: PropTypes.array.isRequired,
    period: PropTypes.object
}

export default AttributeReportPage;