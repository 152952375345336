import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class UserDeleteConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transPage: "modals.user_delete.",
        };

        this.deleteSelectedUser = this.deleteSelectedUser.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    deleteSelectedUser() {
        this.props.deleteSelectedUser();
    }

    onCloseModal() {
        this.props.onHide();
    }

    onOpenModal() {
    }

    render() {
        const { t } = this.props;

        const listUsers = this.props.selectedUser?.map((item) => {
            return <li key={item.id}>{item.email || item.name || item.username}</li>;
        });

        return (
            <Modal
                show={this.props.show}
                onHide={() => this.onCloseModal()}
                onShow={() => this.onOpenModal()}
            >
                {this.props.loadingModal}
                <Modal.Header closeButton className="bg-danger">
                    <Modal.Title>
                        {t(this.state.transPage + "title")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t(this.state.transPage + "confirmation_text")}</p>
                    <ul>{listUsers}</ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => this.onCloseModal()}
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        variant="danger"
                        onClick={(e) => this.props.deleteSelectedUser(e)}
                    >
                        <i className="fa fa-trash">&nbsp;</i>
                        {t(this.state.transPage + "delete_user")}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(UserDeleteConfirm);
