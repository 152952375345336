import axios from "axios";
import config from "./../config/config";
// import Cookies from "js-cookie";

export default class PageApi {
    base_api = config.base_api;
    token = config.token;
    //   key = config.token;

    async getDescription(param) {
        return await axios.get(this.base_api + `pages/${param}/`, {
            params: { max_size: true },
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    updateDescriptionPage(id, data) {
        return axios.patch(
            this.base_api + `pages/${id}/`,
            {
                page_languages: data,
            },
            {
                headers: {
                    Authorization: `Token ${this.token}`,
                    "Content-Type": "application/json",
                },
            }
        );
    }

    updateDescriptionUsed(id, data) {
        return axios.patch(
            this.base_api + `pages/${id}/`,
            {
                use_page_description: data,
            },
            {
                headers: {
                    Authorization: `Token ${this.token}`,
                    "Content-Type": "application/json",
                },
            }
        );
    }

    updatePageSectionsSetting(id, data) {
        return axios.patch(this.base_api + `pages/${id}/`, data, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getPageStyling() {
        return axios.get(this.base_api + `styles/`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    updatePageSectionsStyling(id, data) {
        let ids = parseInt(id);
        return axios.patch(
            this.base_api + `page_style_pair/`,
            {
                page_id: ids,
                page_styles: data,
            },
            {
                headers: {
                    Authorization: `Token ${this.token}`,
                    "Content-Type": "application/json",
                },
            }
        );
    }

    addKeywordPage(param) {
        return axios.post(this.base_api + `page_keywords/`, param, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    deleteKeywordPage(id) {
        return axios.delete(this.base_api + `page_keywords/${id}/`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getAllPages(params) {
        return axios.get(this.base_api + `pages/`, {
            params: params,
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getPageDetail(pageId) {
        return axios.get(this.base_api + `pages/${pageId}/`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    pageSectionDelete(sections) {
        return axios.delete(this.base_api + `section_delete/`, {
            data: {
                ids: sections,
            },
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    pageSectionDeleteNew(data) {
        // post -> patch
        return axios.patch(
            this.base_api + "sections/bulk_delete/",
            {
                section_ids: data,
            },
            {
                headers: {
                    Authorization: `Token ${this.token}`,
                    "Content-Type": "application/json",
                },
            }
        );
    }

    pageAddNew(data) {
        return axios.post(
            this.base_api + `pages/`,
            {
                parent: data.parent,
                template: data.template,
                has_params: data.has_params,
                page_languages: data.page_languages,
                name: data.name,
                page_type: data.page_type,
                param_qty: data.param_qty,
                widgets: data.widgets
            },
            {
                headers: {
                    Authorization: `Token ${this.token}`,
                    "Content-Type": "application/json",
                },
            }
        );
    }

    pageUpdate(pageId, data) {
        return axios.put(
            this.base_api + `pages/${pageId}/`,
            {
                name: data.name,
                parent: data.parent,
                template: data.template,
                has_params: data.has_params,
                page_languages: data.page_languages,
                page_style: data.page_styles,
                is_draft: data.is_draft,
                widgets: data.widgets
            },
            {
                headers: {
                    Authorization: `Token ${this.token}`,
                    "Content-Type": "application/json",
                },
            }
        );
    }

    pageDeleteNew(id) {
        return axios.delete(this.base_api + `pages/${id}`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    pageStylesCatalogSetting() {
        let url = this.base_api + `page_styles/catalog/`;

        return axios.get(url, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    pageDescAdd(descId, data) {
        return axios.post(
            this.base_api + `pages/${descId}/`,
            {
                page_languages: {
                    name: data.name,
                    language_name: data.lang,
                    description: data.description,
                },
            },
            {
                headers: {
                    Authorization: "Token " + this.token,
                    "Content-Type": "application/json",
                },
            }
        );
    }

    pageLanguages() {
        // job poster
        let url = this.base_api + `languages/`;
        return axios.get(url, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getLanguages() {
        return axios.get(this.base_api + `languages/`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    widgetTextDetail(textId) {
        return axios.get(this.base_api + `text_elements/${textId}/`, {
            headers: {
                Authorization: "Token " + this.token,
                "Content-Type": "application/json",
            },
        });
    }

    pageLanguageListSettings(params) {
        let url =
            this.base_api +
            `pages/get_page_names/?template=${params[0].template}&language=${params[0].language}&max_size=${params[0].max_size}`;

        return axios.get(url, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    pageLanguageList(params) {
        let url =
            // this.base_api + `pages/get_page_names/?template=${params.template}&language=${params.language}&max_size=${params.max_size}`;
            this.base_api +
            `pages/?template=${params.template}&language=${params.language}&max_size=${params.max_size}`;

        return axios.get(url, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }
}
