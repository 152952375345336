import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import swal from "sweetalert";
import _ from "lodash";

// Components
import { Content, ContentHeader, ContentBody } from "./../components/Content"
import TitlePages from './Elements/TitlePages'
import BoxSimple from '../components/BoxSimple/BoxSimple'
import { Tabs, Tab, Spinner, Dropdown } from 'react-bootstrap'
import DataTable from '../components/DataTable/DataTable'
import Select from 'react-dropdown-select'

// Context
import Context from "./Context"

// API
import CRMApi from '../api/CRMApi';
import GeneralApi from '../api/GeneralApi';

// Modals
import CustomerDeleteConfirm from '../components/Modal/CustomerDeleteConfirm';
import ContactDeleteConfirm from '../components/Modal/ContactDeleteConfirm';
import ContactAddModal from '../components/Modal/ContactAddModal';

// Plugins
import customDropdownRenderer from '../plugins/customDropdownRenderer/customDropdownRenderer'
import { exportToExcel, exportToExcelMultiple } from '../plugins/exportToExcel/exportToExcel'
import CrmBulkChangeModal from '../components/Modal/CrmBulkChangeModal';
import BzDataTable from '../components/BzDataTable/BzDataTable';

// import css from './CustomerListPage.module.css'

class CustomerListPage extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            activeClient: "",
            counts: [],
            countries: [],
            attributeFilters: [],
            attributeOptions: {},

            // Contacts
            contacts: [],
            selectedContacts: [],
            idContactToUpdate: 0,
            searchContacts: "",
            membersContacts: [],
            contactTitlesContacts: [],
            isLoadingContacts: false,
            allMembers: [],
            allAssignedContacts: [],

            // Members
            selectedTab: "companies",
            selectedMembers: [],
            members: [],
            searchMembers: "",
            countriesMembers: [],
            dynamicFiltersMembers: {},
            isLoadingMembers: false,
            isExportingMembers: false,
            pageMembers: 1,
            totalRowsMember: 0,
            rowsPerPageMember: 10,
            attributeSorting: [],
            sortingMembers: [],

            // Modals
            isShowCustomerDeleteConfirm: false,
            isShowContactDeleteConfirm: false,
            isShowContactAdd: false,
            isShowBulkChange: false,

            // Filters
            shows: 10,
        }

        this.setSelectedTab = this.setSelectedTab.bind(this)
        this.setShowCustomerDeleteConfirm = this.setShowCustomerDeleteConfirm.bind(this)
        this.setShowContactDeleteConfirm = this.setShowContactDeleteConfirm.bind(this)
        this.getMembers = this.getMembers.bind(this)
        this.getContacts = this.getContacts.bind(this)
        this.getCounts = this.getCounts.bind(this)
        this.getCountries = this.getCountries.bind(this)
        this.getAllMembers = this.getAllMembers.bind(this)
        this.getAttributeFilters = this.getAttributeFilters.bind(this)
        this.getAttributeOptions = this.getAttributeOptions.bind(this)
        this.getAllAssignedContacts = this.getAllAssignedContacts.bind(this)
        this.getParamsMembers = this.getParamsMembers.bind(this)
        this.deleteMembers = this.deleteMembers.bind(this)
        this.deleteContacts = this.deleteContacts.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handleDynamicFilter = this.handleDynamicFilter.bind(this)
        this.handleSelectedMembers = this.handleSelectedMembers.bind(this)
        this.exportMembers = this.exportMembers.bind(this)
        this.exportContacts = this.exportContacts.bind(this)
        this.exportContactsPerCompany = this.exportContactsPerCompany.bind(this)
        this.exportAllPerCompany = this.exportAllPerCompany.bind(this)
        this.exportCompanyPerContacts = this.exportCompanyPerContacts.bind(this)
        this.exportAllPerContacts = this.exportAllPerContacts.bind(this)

        this.timeouts = {}
    }

    componentDidMount() {
        const { activeClient } = this.context
        this.setState({ activeClient: activeClient })

        this.getMembers({ fromDidMount: true })
        this.getContacts();
        this.getCounts();
        this.getCountries();
        this.getAttributeFilters();
        this.getAllMembers();
        this.getAllAssignedContacts();

        document.addEventListener("keyup", this.onPressEnter);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedTab !== this.state.selectedTab) {
            this.setState({ selectedMembers: [] })
        }
        if (prevState.pageMembers !== this.state.pageMembers) {
            this.setState({ prevPageMembers: prevState.pageMembers })
        }
    }

    componentWillUnmount() {
        const { changeContextValue } = this.context

        const lastState = _.cloneDeep(this.state)
        lastState.members = []

        changeContextValue({ stateCustomerListPage: lastState })

        document.removeEventListener("keyup", this.onPressEnter);
    }

    getMembers(options) {
        const crmApi = new CRMApi();
        const { activeClient, stateCustomerListPage } = this.context
        const lastState = options?.fromDidMount ? stateCustomerListPage : {}
        if (!(options?.isChangePage) && !(options?.fromDidMount)) {
            lastState.pageMembers = 1
            lastState.selectedMembers = []
        }

        this.setState({ ...lastState, isLoadingMembers: true }, () => {
            const { searchMembers, countriesMembers, attributeFilters, dynamicFiltersMembers, pageMembers, rowsPerPageMember, sortingMembers } = this.state

            var params = new URLSearchParams();
            // params.append('max_size', true)
            params.append('page', pageMembers)
            params.append('page_size', rowsPerPageMember)
            params.append('client', activeClient)
            if (searchMembers) {
                params.append('search', searchMembers)
            }
            if (countriesMembers.length) {
                countriesMembers.forEach(item => {
                    params.append('countries', item.value)
                })
            }
            if (attributeFilters.length) {
                attributeFilters.forEach(attributeFilter => {
                    (dynamicFiltersMembers[attributeFilter.id] || []).forEach(item => {
                        params.append(attributeFilter.name, item.value)
                    })
                })
            }
            if (sortingMembers.length) {
                sortingMembers.forEach(item => {
                    params.append('ordering', item.value)
                })
            }

            crmApi
                .getAllMembers(params)
                .then(res => {
                    this.setState({ members: res.data.results, isLoadingMembers: false, totalRowsMember: res.data.count })
                })
                .catch(e => {
                    console.log(e)
                })
        })

    }

    getContacts() {
        const crmApi = new CRMApi();
        const { activeClient } = this.context
        const { searchContacts, membersContacts, contactTitlesContacts } = this.state

        let params = {
            max_size: true,
            'member__client': activeClient,
        }
        if (searchContacts) {
            params.search = searchContacts
        }
        if (membersContacts.length) {
            params["member__in"] = membersContacts.map(member => member.value).join(',')
        }
        if (contactTitlesContacts.length) {
            params["contact_title__in"] = contactTitlesContacts.map(contactTitle => contactTitle.value).join(',')
        }

        this.setState({ contacts: [], isLoadingContacts: true }, () => {
            crmApi
                .getAllContacts(params)
                .then(res => {
                    this.setState({ contacts: res.data.results, isLoadingContacts: false })
                })
                .catch(e => {
                    console.log(e)
                })
        })
    }

    getCounts() {
        const crmApi = new CRMApi();
        const { activeClient } = this.context

        crmApi
            .getCounts(activeClient)
            .then(res => {
                this.setState({ counts: res.data.counts })
            })
            .catch(e => {
                console.log(e)
            })
    }

    getCountries() {
        const generalApi = new GeneralApi();

        generalApi
            .getCountries({ max_size: true })
            .then(res => {
                this.setState({ countries: res.data.results })
            })
    }

    getAllMembers() {
        const crmApi = new CRMApi();
        const { activeClient } = this.context

        var params = new URLSearchParams();
        params.append('max_size', true)
        params.append('client', activeClient)

        crmApi
            .getAllMembers(params)
            .then(res => {
                this.setState({ allMembers: res.data.results })
            })
            .catch(e => {
                console.log(e)
            })
    }

    getAttributeFilters() {
        const crmApi = new CRMApi();
        const { activeClient } = this.context

        crmApi
            .getAttributeFilters({ 'attribute__category__client': activeClient, max_size: true })
            .then(res => {
                const attributeFilters = res.data.results.filter(result => result.category === "Filter").map(result => result.attribute)
                const attributeSorting = res.data.results.filter(result => result.category === "Order").map(result => result.attribute)
                this.setState({ attributeFilters: attributeFilters, attributeSorting: attributeSorting })

                attributeFilters.forEach(attributeFilter => {
                    this.getAttributeOptions(attributeFilter.id)
                })
            })
            .catch(err => console.log(err))
    }

    getAttributeOptions(idAttribute) {
        const crmApi = new CRMApi();
        let newAttributeOptions = this.state.attributeOptions

        crmApi
            .getAttributeOptions(idAttribute)
            .then(res => {
                newAttributeOptions[idAttribute] = res.data.results
                this.setState({ attributeOptions: newAttributeOptions })
            })
    }

    getAllAssignedContacts() {
        const crmApi = new CRMApi()
        const { activeClient } = this.context
        const clientId = activeClient

        crmApi.getAllAssignedContacts({ client: clientId })
            .then(res => {
                this.setState({ allAssignedContacts: res.data.results })
            })
    }

    handleDeleteButton() {
        const { selectedTab } = this.state
        if (selectedTab === "companies") {
            return () => { this.setShowCustomerDeleteConfirm(true) }
        } else if (selectedTab === "contacts") {
            return () => { this.setShowContactDeleteConfirm(true) }
        }
    }

    handleSearch(value, stateName) {
        if (!stateName) return

        this.setState({ [stateName]: value })
    }

    handleFilter(values, stateName) {
        this.setState({ [stateName]: values })
    }

    handleDynamicFilter(values, idAttribute) {
        let newDynamicFiltersMembers = _.cloneDeep(this.state.dynamicFiltersMembers)
        newDynamicFiltersMembers[idAttribute] = values

        this.setState({ dynamicFiltersMembers: newDynamicFiltersMembers })
    }

    handleSelectedMembers(rows, isChecked) {
        let newSelectedMembers = new Set([...this.state.selectedMembers])

        rows.forEach(row => {
            if (isChecked) {
                newSelectedMembers.add(row.id)
            } else {
                newSelectedMembers.delete(row.id)
            }
        })

        this.setState({ selectedMembers: [...newSelectedMembers] })
    }

    setSelectedTab(value) {
        this.setState({ selectedTab: value })
    }

    setShowCustomerDeleteConfirm(value) {
        this.setState({ isShowCustomerDeleteConfirm: value })
    }

    setShowContactDeleteConfirm(value) {
        this.setState({ isShowContactDeleteConfirm: value })
    }

    deleteMembers() {
        const { selectedMembers } = this.state
        const crmApi = new CRMApi();

        crmApi
            .bulkDeleteMembers(selectedMembers)
            .then(res => {
                this.setState({ selectedMembers: [], isShowCustomerDeleteConfirm: false }, () => {
                    swal(
                        "Success !",
                        "You sucessfully deleted selected members !",
                        "success"
                    );

                    this.getMembers()
                })

            })
            .catch(err => {
                swal(
                    "Error !",
                    "Something went wrong when deleting members !",
                    "error"
                );
            })
    }

    deleteContacts() {
        const { selectedContacts } = this.state
        const crmApi = new CRMApi();

        crmApi
            .bulkDeleteContacts(selectedContacts)
            .then(res => {
                this.setState({ selectedContacts: [], isShowContactDeleteConfirm: false }, () => {
                    swal(
                        "Success !",
                        "You sucessfully deleted selected contacts !",
                        "success"
                    );

                    this.getContacts()
                })

            })
            .catch(err => {
                swal(
                    "Error !",
                    "Something went wrong when deleting contacts !",
                    "error"
                );
            })
    }

    getFormattedMembersExcel(data) {
        return data.map(item => {
            let formattedItem = {}
            Object.keys(item).forEach(key => {
                if (key === "member_attributes") {
                    Object.keys(item[key]).forEach(member_attribute_key => {
                        const value = item[key][member_attribute_key]
                        formattedItem[member_attribute_key] = _.isArray(value) ? value.join('; ') : value
                    })
                } else if (key === "id") {
                    // pass
                } else if (key === "member_id") {
                    const value = item[key]
                    formattedItem["Id"] = _.isArray(value) ? value.join('; ') : value
                } else {
                    const value = item[key]
                    formattedItem[_.startCase(key)] = _.isArray(value) ? value.join('; ') : value
                }
            })

            return formattedItem
        })
    }

    getFormattedContactsExcel(data) {
        return data.map(contact => {
            return {
                Name: contact.name,
                Company: contact.member.name,
                Poisition: contact.position,
                Phone: contact.phone,
                Email: contact.email,
                'Contact Title': contact.contact_title?.name
            }
        })
    }

    getParamsMembers() {
        const { activeClient } = this.context
        const { searchMembers, countriesMembers, attributeFilters, dynamicFiltersMembers, sortingMembers } = this.state

        var params = new URLSearchParams();
        params.append('max_size', true)
        params.append('client', activeClient)
        if (searchMembers) {
            params.append('search', searchMembers)
        }
        if (countriesMembers.length) {
            countriesMembers.forEach(item => {
                params.append('countries', item.value)
            })
        }
        if (attributeFilters.length) {
            attributeFilters.forEach(attributeFilter => {
                (dynamicFiltersMembers[attributeFilter.id] || []).forEach(item => {
                    params.append(attributeFilter.name, item.value)
                })
            })
        }
        if (sortingMembers.length) {
            sortingMembers.forEach(item => {
                params.append('ordering', item.value)
            })
        }

        return params
    }

    exportMembers() {
        const crmApi = new CRMApi();
        const params = this.getParamsMembers()

        this.setState({ isExportingMembers: true }, () => {
            crmApi
                .exportData(params)
                .then(res => {
                    let membersData = res.data

                    if (this.state.selectedMembers.length) {
                        membersData = membersData.filter(member => this.state.selectedMembers.includes(member.id))
                    }

                    const data = this.getFormattedMembersExcel(membersData)

                    exportToExcel(data, "Exported companies")
                    this.setState({ isExportingMembers: false })
                })
                .catch(e => {
                    console.log(e)
                    this.setState({ isExportingMembers: false })
                })
        })
    }

    exportContacts(contacts) {
        const data = this.getFormattedContactsExcel(contacts)

        exportToExcel(data, "Exported contacts")
    }

    exportContactsPerCompany() {
        const crmApi = new CRMApi();
        const { activeClient } = this.context
        const params = this.getParamsMembers()

        this.setState({ isExportingMembers: true }, () => {
            crmApi
                .getAllMembers(params)
                .then(res => {
                    let memberIds = res.data.results.map(member => member.id)

                    if (this.state.selectedMembers.length) {
                        memberIds = memberIds.filter(memberId => this.state.selectedMembers.includes(memberId))
                    }


                    let params = {
                        max_size: true,
                        client: activeClient,
                    }
                    if (memberIds.length) {
                        params["member__in"] = memberIds.join(',')
                    }

                    crmApi
                        .getAllContacts(params)
                        .then(res => {
                            this.exportContacts(res.data.results)
                            this.setState({ isExportingMembers: false })
                        })
                        .catch(e => {
                            console.log(e)
                        })
                })
                .catch(e => {
                    console.log(e)
                })

        })

    }

    exportAllPerCompany() {
        const crmApi = new CRMApi();
        const { activeClient } = this.context

        this.setState({ isExportingMembers: true }, async () => {
            const membersParams = this.getParamsMembers()
            const resMembersData = await crmApi.exportData(membersParams)
            let membersData = resMembersData.data

            if (this.state.selectedMembers.length) {
                membersData = membersData.filter(member => this.state.selectedMembers.includes(member.id))
            }

            let contactsParams = { max_size: true, client: activeClient }
            const memberIds = membersData.map(member => member.id)
            if (memberIds.length) {
                contactsParams["member__in"] = memberIds.join(',')
            }
            const contactsData = await crmApi.getAllContacts(contactsParams)

            const apiDatas = [this.getFormattedMembersExcel(membersData), this.getFormattedContactsExcel(contactsData.data.results)]
            const sheetNames = ["Companies", "Contacts"]

            exportToExcelMultiple(apiDatas, sheetNames, "Exported all")
            this.setState({ isExportingMembers: false })
        })
    }

    exportCompanyPerContacts() {
        const crmApi = new CRMApi();
        const { activeClient } = this.context

        const memberIds = this.state.contacts.map(contact => contact.member.id)
        const uniqueMemberIds = new Set(memberIds)

        this.setState({ isExportingMembers: true }, async () => {
            const membersParams = {
                max_size: true,
                activeClient: activeClient
            }
            const responseMembers = await crmApi.exportData(membersParams)
            const filteredMembers = responseMembers.data.filter(member => uniqueMemberIds.has(member.id))
            const data = this.getFormattedMembersExcel(filteredMembers)

            exportToExcel(data, "Exported companies")
            this.setState({ isExportingMembers: false })
        })
    }

    exportAllPerContacts() {
        const crmApi = new CRMApi();
        const { activeClient } = this.context

        const memberIds = this.state.contacts.map(contact => contact.member.id)
        const uniqueMemberIds = new Set(memberIds)

        this.setState({ isExportingMembers: true }, async () => {
            const membersParams = {
                max_size: true,
                activeClient: activeClient
            }
            const responseMembers = await crmApi.exportData(membersParams)
            const filteredMembers = responseMembers.data.filter(member => uniqueMemberIds.has(member.id))

            const apiDatas = [this.getFormattedMembersExcel(filteredMembers), this.getFormattedContactsExcel(this.state.contacts)]
            const sheetNames = ["Companies", "Contacts"]

            exportToExcelMultiple(apiDatas, sheetNames, "Exported all")
            this.setState({ isExportingMembers: false })
        })
    }

    onPressEnter = (event) => {
        if (event.key === 'Enter') {
            if (this.state.selectedTab === 'companies') {
                this.getMembers()
            } else if (this.state.selectedTab === 'contacts') {
                this.getContacts()
            }
        }
    }

    render() {
        const { countries, allMembers, allAssignedContacts } = this.state

        const fixedCompanyColumns = [
            { name: "ID", selector: row => row.idPadded, maxWidth: "60px" },
            { name: "Name", selector: row => row.name },
            { name: "Phone", selector: row => row.phone }
        ]

        const companyColumns = this.state.members[0] ? [...fixedCompanyColumns, ...Object.keys(this.state.members[0].member_attributes).map(col => {
            return { name: col, selector: row => row[col] }
        })] : []

        const contactColumns = [
            { name: 'Name', selector: row => row.name, sortable: true, },
            { name: 'Company', selector: row => row.member?.name, sortable: true, },
            { name: 'Position', selector: row => row.position, sortable: true, },
            { name: 'Phone', selector: row => row.phone, sortable: true, },
            { name: 'Email', selector: row => row.email, sortable: true, },
            { name: 'Contact Title', selector: row => row.contact_title?.name, sortable: true, },
        ]

        const customContentRenderer = ({ props, state }) => (
            <div style={{ cursor: 'pointer', fontSize: "16px", padding: "6px 12px" }}>
                {
                    state.values.length
                        ? <>{state.values.length} of {props.options.length} selected</>
                        : <><i className="fas fa-filter"></i> {props.placeholder}</>
                }
            </div>
        );

        const customContentRendererSorting = ({ props, state }) => (
            <div style={{ cursor: 'pointer', fontSize: "16px", padding: "6px 12px" }}>
                {
                    state.values.length
                        ? <><i className="fas fa-sort-amount-down-alt"></i> <strong>{state.values[0].label}</strong></>
                        : <><i className="fas fa-sort-amount-down-alt"></i> {props.placeholder}</>
                }
            </div>
        );

        let sortingOptions = [];
        const _sortingOptions = ["ID", "Name", ...this.state.attributeSorting.filter(attr => ["Date joined"].includes(attr.name))]

        _sortingOptions.forEach(option => {
            const signs = ["", "-"]

            signs.forEach(sign => {
                if (_.isObject(option)) {
                    sortingOptions.push({ label: option.name + (sign ? " descending" : " ascending"), value: sign + option.id })
                } else {
                    let option_value = option
                    if (option === "ID") {
                        option_value = "member_id"
                    }

                    sortingOptions.push({ label: option + (sign ? " descending" : " ascending"), value: sign + option_value.toLowerCase() })
                }
            })
        })


        // -------------------------

        return (
            <Content>
                <ContentHeader>
                    <div className="row">
                        <TitlePages title="Customer List" />
                        {/* <div className="card-tools">

                        </div> */}
                    </div>
                </ContentHeader>
                <ContentBody>
                    <div className="d-flex">
                        {
                            this.state.counts.map((count, idx) => {
                                return <BoxSimple
                                    key={idx}
                                    status={count.name}
                                    content={count.count}
                                    idx={idx}
                                    className="mr-3"
                                />
                            })
                        }
                    </div>
                    <hr></hr>
                    <div>
                        <div
                            style={{ position: "relative" }}
                        >
                            <Tabs
                                activeKey={this.state.selectedTab}
                                onSelect={(k) => this.setSelectedTab(k)}
                                style={{ marginBottom: "1rem" }}
                            >
                                <Tab eventKey="companies" title="Companies">
                                    <div className="form-inline" style={{ position: "relative", rowGap: ".5rem", marginBottom: ".5rem" }}>
                                        <div className="form-group mr-2">
                                            <input
                                                className="form-control rounded-pill"
                                                type="text"
                                                placeholder="Search"
                                                value={this.state.searchMembers}
                                                onChange={(e) => { this.handleSearch(e.target.value, 'searchMembers') }}
                                            // onKeyUp={(e) => {
                                            //     if (e.key === 'Enter') {
                                            //         this.getMembers()
                                            //     }
                                            // }}
                                            />
                                        </div>
                                        <div className="mr-2">
                                            <Select
                                                className="bg-white rounded-pill"
                                                style={{ minWidth: "200px", padding: "0px 5px" }}
                                                placeholder="Sorting"
                                                values={this.state.sortingMembers}
                                                options={sortingOptions}
                                                onChange={values => { this.handleFilter(values, "sortingMembers") }}
                                                contentRenderer={customContentRendererSorting}
                                                dropdownRenderer={customDropdownRenderer}
                                                dropdownHeight="400px"
                                            />
                                        </div>
                                        <div className="mr-2">
                                            <Select
                                                className="bg-white rounded-pill"
                                                style={{ minWidth: "200px", padding: "0px 5px" }}
                                                multi
                                                placeholder="Countries"
                                                values={this.state.countriesMembers}
                                                options={countries.length ? countries.map(country => ({ label: country.name, value: country.id })) : []}
                                                onChange={values => { this.handleFilter(values, "countriesMembers") }}
                                                contentRenderer={customContentRenderer}
                                                dropdownRenderer={customDropdownRenderer}
                                                dropdownHeight="400px"
                                            />
                                        </div>
                                        {
                                            this.state.attributeFilters.map(attributeFilter => {
                                                const id = attributeFilter.id
                                                return <div key={`attribute-filter-${id}`} className="mr-2">
                                                    <Select
                                                        className="bg-white rounded-pill"
                                                        style={{ minWidth: "200px", padding: "0px 5px" }}
                                                        multi
                                                        placeholder={attributeFilter.name}
                                                        values={this.state.dynamicFiltersMembers[id] || []}
                                                        options={this.state.attributeOptions[id] ? this.state.attributeOptions[id].map(attrOption => ({ label: attrOption.name, value: attrOption.id })) : []}
                                                        onChange={values => { this.handleDynamicFilter(values, id) }}
                                                        contentRenderer={customContentRenderer}
                                                        dropdownRenderer={customDropdownRenderer}
                                                        dropdownHeight="400px"
                                                    />
                                                </div>
                                            })
                                        }
                                        <div className="mr-2">
                                            <button
                                                className="btn btn-primary rounded-pill border-0 px-4"
                                                style={{ backgroundColor: "#223771" }}
                                                onClick={this.getMembers}
                                            >
                                                Apply
                                            </button>
                                        </div>
                                        <div>
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    className="btn btn-primary rounded-pill border-0 mr-2 px-4"
                                                    style={{ background: "transparent linear-gradient(180deg, #F58220 0%, #F26522 100%) 0% 0% no-repeat padding-box" }}
                                                >
                                                    {this.state.isExportingMembers ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <i className="fas fa-circle"></i>} Export Excel
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" onClick={this.exportMembers}>Export Companies</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={this.exportContactsPerCompany} >Export Contacts</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={this.exportAllPerCompany} >Export All</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div>
                                            <button
                                                className="btn btn-light rounded-pill mr-2 px-4"
                                                style={{ border: "1px solid #ced4da" }}
                                                onClick={() => { this.setState({ isShowBulkChange: true }) }}
                                                disabled={this.state.selectedMembers.length}
                                            >
                                                <i className="far fa-circle"></i> Bulk Change
                                            </button>
                                        </div>
                                    </div>

                                    <BzDataTable
                                        columns={companyColumns}
                                        data={this.state.members.map(member => { return { id: member.id, idPadded: member.member_id.toString().padStart(5, '0'), name: member.name, phone: member.phone, ...member.member_attributes } })}
                                        progressPending={this.state.isLoadingMembers}
                                        paginationRowsPerPageOptions={[10, 15, 20, 50, 100]}
                                        onChangePage={(page) => { this.setState({ pageMembers: page }, () => { this.getMembers({ isChangePage: true }) }) }}
                                        onChangeRowsPerPage={(rows) => { this.setState({ rowsPerPageMember: rows }, this.getMembers) }}
                                        paginationDefaultPage={this.state.pageMembers}
                                        paginationTotalRows={this.state.totalRowsMember}

                                        onSelectedRowsChange={({ selectedRows, isChecked }) => { this.handleSelectedMembers(selectedRows, isChecked) }}
                                        selectableRowSelected={row => this.state.selectedMembers.includes(row.id)}
                                        onRowClicked={(row, e) => { this.props.history.push(`/customers/detail/${row.id}`) }}
                                    />

                                </Tab>
                                <Tab eventKey="contacts" title="Contacts">
                                    <div className="form-inline" style={{ position: "relative", rowGap: ".5rem", marginBottom: ".5rem" }}>
                                        <div className="form-group mr-2">
                                            <input
                                                className="form-control rounded-pill"
                                                type="text"
                                                placeholder="Search"
                                                value={this.state.searchContacts}
                                                onChange={(e) => { this.handleSearch(e.target.value, "searchContacts") }}
                                            // onKeyUp={(e) => {
                                            //     if (e.key === 'Enter') {
                                            //         this.getContacts()
                                            //     }
                                            // }}
                                            />
                                        </div>
                                        <div className="mr-2">
                                            <Select
                                                className="bg-white rounded-pill"
                                                style={{ minWidth: "200px", padding: "0px 5px" }}
                                                multi
                                                placeholder="Companies"
                                                values={this.state.membersContacts}
                                                options={allMembers.length ? allMembers.map(member => ({ label: member.name, value: member.id })) : []}
                                                onChange={values => { this.handleFilter(values, "membersContacts") }}
                                                contentRenderer={customContentRenderer}
                                                dropdownRenderer={customDropdownRenderer}
                                                dropdownHeight="400px"
                                            />
                                        </div>
                                        <div className="mr-2">
                                            <Select
                                                className="bg-white rounded-pill"
                                                style={{ minWidth: "200px", padding: "0px 5px" }}
                                                multi
                                                placeholder="Contact Titles"
                                                values={this.state.contactTitlesContacts}
                                                options={allAssignedContacts.length ? allAssignedContacts.map(contactTitle => ({ label: contactTitle.name, value: contactTitle.id })) : []}
                                                onChange={values => { this.handleFilter(values, "contactTitlesContacts") }}
                                                contentRenderer={customContentRenderer}
                                                dropdownRenderer={customDropdownRenderer}
                                                dropdownHeight="400px"
                                            />
                                        </div>
                                        <div>
                                            <button
                                                className="btn btn-primary rounded-pill border-0 mr-2 px-4"
                                                style={{ backgroundColor: "#223771" }}
                                                onClick={this.getContacts}
                                            >
                                                Apply
                                            </button>
                                        </div>
                                        <div>
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    className="btn btn-primary rounded-pill border-0 mr-2 px-4"
                                                    style={{ background: "transparent linear-gradient(180deg, #F58220 0%, #F26522 100%) 0% 0% no-repeat padding-box" }}
                                                >
                                                    {this.state.isExportingMembers ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <i className="fas fa-circle"></i>} Export Excel
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" onClick={this.exportCompanyPerContacts}>Export Companies</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => { this.exportContacts(this.state.contacts) }} >Export Contacts</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={this.exportAllPerContacts} >Export All</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <DataTable
                                        columns={contactColumns}
                                        data={this.state.contacts}
                                        selectableRows
                                        pagination
                                        progressPending={this.state.isLoadingContacts}
                                        paginationRowsPerPageOptions={[10, 15, 20, 50, 100]}

                                        clearSelectedRows={this.state.selectedTab !== "contacts"}
                                        onSelectedRowsChange={({ selectedRows }) => { this.setState({ selectedContacts: selectedRows.map(e => e.id) }) }}
                                        onRowClicked={(row, e) => { this.setState({ isShowContactAdd: true, idContactToUpdate: row.id }) }}
                                    />
                                </Tab>
                            </Tabs>
                            <div className="d-flex align-items-center" style={{ position: "absolute", top: 0, right: 0 }}>
                                <button
                                    className="btn btn-primary rounded-pill border-0 mr-2 px-4"
                                    style={{ backgroundColor: "#223771" }}
                                    onClick={() => { this.setState({ isShowContactAdd: true, idContactToUpdate: 0 }) }}
                                >
                                    <i className="fas fa-plus"></i> Add Contact
                                </button>
                                <Link
                                    to="/customers/add"
                                    className="btn btn-primary rounded-pill border-0 mr-2 px-4"
                                    style={{ backgroundColor: "#223771" }}>
                                    <i className="fas fa-plus"></i> Add Company
                                </Link>
                                <button
                                    className="btn btn-light rounded-pill px-4"
                                    style={{ border: "1px solid #ced4da" }}
                                    onClick={this.handleDeleteButton()}
                                    disabled={!this.state.selectedMembers.length && !this.state.selectedContacts.length}
                                >
                                    <i className="far fa-circle"></i> Delete
                                </button>
                            </div>
                        </div>
                    </div>

                    <ContactDeleteConfirm
                        show={this.state.isShowContactDeleteConfirm}
                        deleteSelectedContacts={this.deleteContacts}
                        onHide={() => { this.setShowContactDeleteConfirm(false) }}
                        selectedContacts={this.state.selectedContacts}
                    />
                    <CustomerDeleteConfirm
                        show={this.state.isShowCustomerDeleteConfirm}
                        deleteSelectedCustomer={this.deleteMembers}
                        onHide={() => { this.setShowCustomerDeleteConfirm(false) }}
                        selectedMembers={this.state.selectedMembers}
                    />
                    <ContactAddModal
                        show={this.state.isShowContactAdd}
                        onHide={() => { this.setState({ isShowContactAdd: false, idContactToUpdate: 0 }) }}
                        reload={() => { this.getContacts() }}
                        idContactToUpdate={this.state.idContactToUpdate}
                    />
                    <CrmBulkChangeModal
                        show={this.state.isShowBulkChange}
                        onHide={() => { this.setState({ isShowBulkChange: false }) }}
                        getFilterParams={this.getParamsMembers}
                        reload={() => {
                            this.getMembers()
                            this.getCounts()
                        }}
                    />
                </ContentBody>
            </Content>
        );
    }
}

export default withTranslation()(CustomerListPage);
