import React, { Component, createRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Autocomplete,
  Marker,
} from "@react-google-maps/api";
// import Geocode from "react-geocode";
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
// import { OpenStreetMapProvider } from "react-leaflet-geosearch";
import { Tabs, Tab } from "react-bootstrap";
import swal from "sweetalert";
import _ from "lodash"

import { withTranslation } from "react-i18next";

import Card from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content";
// import SearchControl from "../components/Map/SearchControl";
import TitlePages from "./Elements/TitlePages";
import CompanyAddModal from "../pages/Job/CompanyAddModal";
import DeleteCompanyConfirm from "../pages/Job/DeleteCompanyConfirm";
import Context from "./Context";

// import AlertHelper from "../lib/AlertHelper";
import GeneralApi from "../api/GeneralApi";
import LocationApi from "../api/LocationApi";
import JobApi from "../api/JobApi";

import "./LocationPage.css";



class LocationDetailPage extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);

    this.state = {
      activeClient: "",
      // gMapApiKey: "AIzaSyA6aQOcCYM5yXimMPDdTCopJD9wT0324L8", // Phenikaa
      // gMapApiKey: 'AIzaSyCo2mDUObZFhtBXJg_QTEGbcMAyvn4zKkY',
      gMapApiKey: 'AIzaSyC1j71BFJjVlb5FrAtOHUD2ewv21IrjSb8',
      queryLocation: "",
      mapsLib: ["drawing", "places"],
      currentLatLng: false,
      defaultCenter: false,
      bounds: null,
      isMarkerShown: false,
      countryDefault: false,
      languageList: [],
      locationLanguage: [],
      companies: [],
      selectedCompanies: [],
      countries: [],
      selectedCountries: {},
      names: [],
      address: [],
      addressNoLang: "",
      website: [],
      websiteNoLang: "",

      hasLanguage: true,
      tabKey: 1,
      selectedLanguage: 1,

      // update Loc
      locationLoaded: false,
      draggable: true,

      transPage: "modals.location_add_new.",
      transAlert: "alert.",
    };
    this.myMap = React.createRef();

    this.autocomplete = null;

    this.onLoad = this.onLoad.bind(this);
    this.onPlaceChanged = this.onPlaceChanged.bind(this);

    this.renderTabLanguage = this.renderTabLanguage.bind(this);
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
    this.handleChangeWebsite = this.handleChangeWebsite.bind(this);
    this.handleHasLanguage = this.handleHasLanguage.bind(this);
    // this.handleLocationChange = this.handleLocationChange.bind(this)
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
    this.handleChangeCountry = this.handleChangeCountry.bind(this);

    this.addNewLocation = this.addNewLocation.bind(this);
    this.getDetailLocation = this.getDetailLocation.bind(this);
    this.getCompanies = this.getCompanies.bind(this);
    this.setShowCompanyDelete = this.setShowCompanyDelete.bind(this);
    this.setShowCompany = this.setShowCompany.bind(this);
    this.deleteSelectedCompany = this.deleteSelectedCompany.bind(this);
  }

  markerRef = createRef()

  componentDidMount() {
    const { activeContentLang } = this.context;

    this.getCompanies();
    this.getAllCountries()

    if (this.props.match.params.locationId) {
      this.getDetailLocation(this.props.match.params.locationId);
      this.setState(
        {
          locationId: this.props.match.params.locationId,
          tabKey: 1,
          selectedLanguage: 1,
          languageList: activeContentLang,
          isMarkerShown: true,
        },
        () => { }
      );
    } else {
      this.showCurrentLocation();

      this.setState(
        {
          id: this.props.positionId | 0,
          tabKey: 1,
          selectedLanguage: 1,
          languageList: activeContentLang,
        },
        () => {
          // this.setState({
          //     address:""
          // })
          // if(this.props.locationId) {
          //     this.getDetailLocation()
          // } else {
          //     this.renderTabLanguage()
          // }
        }
      );
    }

    const urlParams = new URLSearchParams(window.location.search);
    const companyParam = urlParams.get("company")
    if (companyParam) {
      this.setState({
        selectedCompany: companyParam
      })
    }
  }

  showCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState((prevState) => ({
          currentLatLng: {
            ...prevState.currentLatLng,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
          isMarkerShown: true,
        }));
      }, (err) => {
        this.setState({
          currentLatLng: {
            lat: 0,
            lng: 0,
          },
          defaultCenter: {
            lat: -6.1741,
            lng: 106.8296,
          },
        })
      });
    } else {
      this.setState({
        currentLatLng: {
          lat: 0,
          lng: 0,
        },
        defaultCenter: {
          lat: -6.1741,
          lng: 106.8296,
        },
      })
    }
  };

  onLoad(autocomplete) {
    this.autocomplete = autocomplete;
  }

  onPlaceChanged(place) {
    const { countries } = this.state

    // ----- Use google maps
    place = this.autocomplete.getPlace()

    let selectedCountry = {}
    const addressComponents = place?.address_components;

    if (addressComponents) {
      const countriesName = this.state.countries.map(country => country.iso_code)
      const addressCountry = addressComponents.map(address => address.short_name)
      const foundedCountry = _.intersection(countriesName, addressCountry)
      const countryCode = foundedCountry.length ? foundedCountry[0] : ""
      selectedCountry = countries.find(({ iso_code }) => iso_code === countryCode);
    }


    this.setState((prevState) => ({
      addressNoLang: place?.formatted_address,
      address: Array(this.state.languageList.length).fill({ address: place?.formatted_address }),
      currentLatLng: {
        ...prevState.currentLatLng,
        lat: place?.geometry.location.lat(),
        lng: place?.geometry.location.lng(),
      },
      isMarkerShown: true,
      selectedCountries: selectedCountry,
    }))


    // // ------- Use leaflet
    // let country
    // if (place?.raw?.address?.country_code) {
    //   country = countries.find(({ iso_code }) => iso_code === place?.raw?.address?.country_code.toUpperCase());
    // } else {
    //   country = false
    // }

    // this.setState((prevState) => ({
    //   currentLatLng: {
    //     ...prevState.currentLatLng,
    //     lat: place?.y,
    //     lng: place?.x,
    //   },
    //   addressNoLang: place?.label,
    //   isMarkerShown: true,
    //   selectedCountries: country,
    //   countryDefault: place?.raw?.address?.country,
    //   address: Array(this.state.languageList.length).fill({ address: place?.label })
    // }));
  }

  updateMarker = event => {
    // const latLng = event.target.getLatLng(); //get marker LatLng
    // const markerIndex = event.target.options.marker_index; //get marker index
    // //update
    // this.setState(prevState => {
    //   const markerData = [...prevState.markerData];
    //   markerData[markerIndex] = latLng;
    //   return { markerData: markerData };
    // });
  };

  onUpdateMarker = () => {
    // const { lat, lng } = this.refmarker.current.leafletElement.getLatLng()
    // const marker = this.markerRef.current
    // if (marker != null) {
    //   const newPos = { ...marker.leafletElement.getLatLng() };
    // }
  }

  addNewLocation() {
    const { t } = this.props;
    const assetObj = new LocationApi();
    const contextValue = this.context;

    const location_languages = this.state.languageList.map((item, index) => {
      return {
        language: item?.language?.id,
        name: document.getElementById("m-loc-name").value,
        address: this.state.address[index]
          ? this.state.address[index].address
          : "",
      };
    });

    const newLocation = {
      client: contextValue.activeClient,
      has_languages: this.state.hasLanguage,
      // company: this.state.selectedCompany,
      country: this.state.selectedCountries.id,
      name: document.getElementById("m-loc-name").value, //location_type is NAME
      latitude: this.state.currentLatLng?.lat,
      longitude: this.state.currentLatLng?.lng,
      address: this.state.addressNoLang,
      location_languages: location_languages,
    };

    if (contextValue.activeClient) {
      assetObj
        .addLocation(newLocation)
        .then((res) => {
          if (res.data) {
            swal(
              "Success !",
              t(this.state.transAlert + "newlocation_add_success"),
              "success"
            );
            this.props.history.push("/location");
          }
        })
        .catch((err) => {
          console.log("err => ", err);
        });
    }
  }

  updateLocation() {
    const { t } = this.props;
    const assetObj = new LocationApi();
    const contextValue = this.context;

    const location_languages = this.state.languageList.map((item, index) => {
      return {
        language: item?.language?.id,
        // location_type: document.getElementById("m-loc-name").value,
        name: this.state.names[index]
          ? this.state.names[index].name
          : "",
        address: this.state.address[index]
          ? this.state.address[index].address
          : "",
        website: this.state.website[index]
          ? this.state.website[index].website
          : "",
      };
    });

    const newLocation = {
      client: contextValue.activeClient,
      has_languages: this.state.hasLanguage,
      // company: this.state.selectedCompany,
      country: this.state.selectedCountries.id,
      name: document.getElementById("m-loc-name").value, //location_type is NAME
      latitude: this.state.currentLatLng?.lat,
      longitude: this.state.currentLatLng?.lng,
      address: this.state.addressNoLang ? this.state.addressNoLang : "-",
      website: this.state.websiteNoLang ? this.state.websiteNoLang : "-",
      location_languages: location_languages,
    };

    assetObj
      .updateLocation(newLocation, this.state.locationId)
      .then((res) => {
        if (res.data) {
          swal(
            "Success !",
            t(this.state.transAlert + "newlocation_update_success"),
            "success"
          );
          this.props.history.push("/location");
        }
      })
      .catch((err) => {
        console.log("err => ", err);
      });
  }

  getDetailLocation(locationId) {
    const obj = new LocationApi();

    obj
      .getDetailLocation(locationId)
      .then((res) => {
        let address = []
        let website = []
        let names = []
        res.data.location_languages.forEach((loc, i) => {
          names.push({
            name: loc.name,
          });
          address.push({
            address: loc.address,
          });
          website.push({
            website: loc.website,
          });
        });

        this.setState(
          {
            currentLatLng: {
              lat: parseFloat(res.data.latitude),
              lng: parseFloat(res.data.longitude),
            },
            countryDefault: res.data.country?.name,
            selectedCountries: res.data.country || {},
            selectedCompany: res.data.company,
            name: res.data.name,
            description: res.data.description,
            email: res.data.email,
            phone: res.data.phone,
            hasLanguage: true,
            names: names,
            address: address,
            addressNoLang: res.data.address,
            website: website,
            websiteNoLang: res.data.website,
            locationLoaded: true,
          },
          () => {
            this.renderTabLanguage();
          }
        );
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  getLanguages() {
    const objLang = new GeneralApi();

    return objLang.getLanguages();
  }

  getAllCountries() {
    const locApi = new LocationApi();

    locApi
      .getAllCountries({
        max_size: true
      })
      .then((res) => {
        this.setState({
          countries: res.data.results,
        });
      })
      .catch((err) => { });
  }

  getCompanies() {
    const { activeClient } = this.context
    const obJob = new JobApi();

    obJob
      .getCompanies({
        client: activeClient,
        max_size: true,
      })
      .then((res) => {
        this.setState({
          companies: res.data.results,
        });
      })
      .catch((err) => { });
  }

  handleChangeNames(index, value) {
    let names = [...this.state.names];
    let new_names = { ...names[index] };
    new_names.name = value;
    names[index] = new_names;

    this.setState(
      {
        names: names,
      },
      () => { }
    );
  }


  handleChangeAddress(index, value) {
    let location_address = [...this.state.address];
    let newlocation_address = { ...location_address[index] };
    newlocation_address.address = value;
    location_address[index] = newlocation_address;

    this.setState(
      {
        // oldaddress: newlocation_address[0].address,
        address: location_address,
      },
      () => { }
    );
  }

  handleChangeAddressNoLang(value) {
    this.setState({
      addressNoLang: value,
    });
  }

  handleChangeWebsite(index, value) {
    let location_website = [...this.state.website];
    let newlocation_website = { ...location_website[index] };
    newlocation_website.website = value;
    location_website[index] = newlocation_website;

    this.setState(
      {
        website: location_website,
      },
      () => { }
    );
  }

  handleChangeWebsiteNoLang(value) {
    this.setState({
      websiteNoLang: value,
    });
  }

  handleChangeTab(value) {
    this.setState(
      {
        selectedLanguage: value,
        tabKey: value,
      },
      () => {
        this.renderTabLanguage();
      }
    );
  }

  handleChangeCompany(value) {
    this.setState({
      selectedCompany: value,
    });
  }

  handleChangeCountry(value) {
    const { countries } = this.state
    const selectedCountry = countries.find(({ id }) => id === parseInt(value));

    this.setState({ selectedCountries: selectedCountry })
  }

  handleHasLanguage() {
    this.setState({
      hasLanguage: !this.state.hasLanguage,
    });
  }

  renderTabLanguage() {
    const renderTabLanguage = this.state.languageList.map((item) => {
      return {
        language: item?.language?.id,
        languageName: item.name,
        description: "",
      };
    });

    this.setState({
      locationLanguage: renderTabLanguage,
    });
  }

  setShowCompanyDelete(companyId) {
    if (companyId) {
      const obj = new JobApi();

      obj
        .getDetailCompany(companyId)
        .then((res) => {
          this.setState({
            selectedCompanies: [res.data],
            showCompanyDelete: !this.state.showCompanyDelete,
          });
        })
        .catch((err) => {
          alert(err.message);

          console.log(err.messasge);
        });
    } else {
      this.setState({
        showCompanyDelete: !this.state.showCompanyDelete,
      });
    }
  }

  setShowCompany(companyId) {
    if (companyId) {
      this.setState({
        companyId: companyId,
        showCompany: !this.state.showCompany,
      });
    } else {
      this.setState({
        companyId: 0,
        showCompany: !this.state.showCompany,
      });
    }
  }

  deleteSelectedCompany() {
    const { t } = this.props;
    const obj = new JobApi();

    const [{ id }] = this.state.selectedCompanies;

    obj
      .deleteCompany(id)
      .then((res) => {
        swal(
          "Success!",
          t(this.state.transAlert + "company_delete_success"),
          "success"
        );
        this.getCompanies();
        this.setShowCompanyDelete();
      })
      .catch((err) => {
        swal("Error!", err.message, "error");
      });
  }

  render() {
    const { t } = this.props;
    const containerStyle = {
      height: "30vh",
    };

    let renderTabLanguage;
    if (this.state.hasLanguage) {
      if (this.state.languageList) {
        let tabLanguage;
        tabLanguage = this.state.languageList.map((item, index) => {
          return (
            <Tab key={index} eventKey={index + 1} title={item.name}>
              <br></br>

              <div className="form-group">
                <label> {t("name")} </label>
                <input type="text" className="form-control" value={this.state.names[index]?.name}
                  onChange={(event) =>
                    this.handleChangeNames(index, event.target.value)
                  }
                ></input>
              </div>
              <div className="form-group">
                <label> {t("address")} </label>
                <textarea className="form-control" value={this.state.address[index]?.address}
                  onChange={(event) =>
                    this.handleChangeAddress(index, event.target.value)
                  }
                  rows='3'
                >
                </textarea>
              </div>
              {/* <div className="form-group mt-2">
                <label>{t("website")}</label>
                <input type="text" name="asset_name" className="form-control" value={this.state.website[index]?.website}
                  onChange={(event) =>
                    this.handleChangeWebsite(index, event.target.value)
                  }
                ></input>
              </div> */}
            </Tab>
          );
        });

        renderTabLanguage = (
          <div className="form-group d-block">
            <Tabs
              id="controlled-tab-example-3"
              activeKey={this.state.tabKey}
              onSelect={(k) => this.handleChangeTab(k)}
            >
              {tabLanguage}
            </Tabs>
          </div>
        );
      } else {
        renderTabLanguage = (
          <div className="loc-body">
            <div>
              <div className="form-group">
                <label htmlFor="m-loc-name">
                  {t(this.state.transPage + "name")}
                </label>
                <input
                  id="m-loc-name"
                  type="text"
                  name="asset_name"
                  className="form-control"
                  defaultValue={this.state.name}
                ></input>
              </div>
              <div className="form-group ">
                <label htmlFor="m-loc-description">
                  {t(this.state.transPage + "description")}
                </label>
                <input
                  id="m-loc-description"
                  type="text"
                  name="asset_name"
                  className="form-control"
                  defaultValue={this.state.description}
                ></input>
              </div>
            </div>
          </div>
        );
      }
    }

    // let defaultLocation
    // if (this.state.locationLoaded) {
    //   defaultLocation = this.state.currentLatLng
    // } else if (this.state.isMarkerShown) {
    //   defaultLocation = this.state.currentLatLng
    // } else if (!this.state.isMarkerShown && this.state.defaultCenter) {
    //   defaultLocation = this.state.defaultCenter
    // }

    return (
      <Content>
        <ContentHeader>
          <TitlePages title="Add Location" />
        </ContentHeader>
        <ContentBody>
          <Card>
            <div className="loc-header">
              <div className="row">
                <label>Location Detail</label>
                <div className="d-flex flex-row btn-loc-header ml-auto">
                  <div onClick={() => this.props.history.push("/location")}>
                    {t(this.state.transPage + "cancel")}
                  </div>
                  {this.state.locationLoaded ? (
                    <div onClick={() => this.updateLocation()}>
                      {t(this.state.transPage + "update_location")}
                    </div>
                  ) : (
                    <div onClick={() => this.addNewLocation()}>
                      {t(this.state.transPage + "add_new_location")}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="loc-body row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="m-loc-name">
                    {t(this.state.transPage + "name")}
                  </label>
                  <input
                    id="m-loc-name"
                    type="text"
                    name="asset_name"
                    className="form-control"
                    defaultValue={this.state.name}
                  ></input>
                </div>
                <div className="form-group ">
                  <label htmlFor="m-loc-address">
                    {t('address')}
                  </label>
                  {/* <input
                    id="m-loc-address"
                    type="text"
                    name="asset_name"
                    className="form-control"
                    value={this.state.addressNoLang}
                    onChange={(e) => { this.handleChangeAddressNoLang(e.target.value) }}
                  ></input> */}
                  <textarea
                    id="m-loc-address"
                    name="asset_name"
                    className="form-control"
                    value={this.state.addressNoLang}
                    onChange={(e) => { this.handleChangeAddressNoLang(e.target.value) }}
                  />

                </div>
                <div className="form-group ">
                  <label htmlFor="m-loc-country">
                    {t('country')}
                  </label>
                  {/* <input
                    disabled
                    id="m-loc-country"
                    type="text"
                    name="asset_name"
                    className="form-control"
                    value={this.state.countryDefault ? this.state.countryDefault : ''}
                  // onChange={(e) => { this.handleChangeAddressNoLang(e.target.value) }}
                  ></input> */}
                  <select
                    id="m-loc-country"
                    name="asset_name"
                    className="form-control"
                    value={this.state.selectedCountries.id}
                    onChange={(e) => { this.handleChangeCountry(e.target.value) }}
                  >
                    <option value="">--- Please select country ---</option>
                    {
                      this.state.countries.map(country => <option value={country.id}>{country.name}</option>)
                    }
                  </select>
                </div>
                {/* <div className="form-group mt-3">
                  <label htmlFor="check-has-language">
                    {" "}
                    {t(this.state.transPage + "has_language")}{" "}
                  </label>
                  <div className="w-100">
                    <input
                      type="checkbox"
                      id="check-has-language"
                      checked={this.state.hasLanguage}
                      onClick={() => this.handleHasLanguage()}
                    ></input>{" "}
                    &nbsp;
                  </div>
                </div> */}

                {renderTabLanguage}
              </div>

              <div className="col-lg-6" style={containerStyle}>
                {/*
                  defaultLocation && <MapContainer center={defaultLocation} zoom={13} scrollWheelZoom={true}
                    style={{ height: '100%', width: '100%' }}>
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <SearchControl
                      provider={OpenStreetMapProvider()}
                      showMarker={true}
                      showPopup={false}
                      popupFormat={({ query, result }) => this.onPlaceChanged(result)}
                      resultFormat={({ result }) => result.label}
                      maxMarkers={1}
                      retainZoomLevel={false}
                      animateZoom={true}
                      autoClose={false}
                      searchLabel={"Enter address, please"}
                      keepResult={true}
                    />
                    <Marker
                      draggable={this.state.draggable}
                      onDragend={this.onUpdateMarker}
                      ref={this.markerRef}
                      position={defaultLocation}
                    >
                      <Popup>
                        Your Location
                      </Popup>
                    </Marker>
                  </MapContainer>
                */}

                <LoadScript
                  googleMapsApiKey='AIzaSyCKqMs4hmNgoQtmIUsd7diBKHj6UV610Jg'
                  libraries={this.state.mapsLib}
                >
                  <GoogleMap
                    ref={this.myMap}
                    id="g-maps"
                    mapContainerStyle={containerStyle}
                    center={
                      this.state.locationLoaded
                        ? this.state.currentLatLng
                        : this.state.isMarkerShown
                          ? this.state.currentLatLng
                          : this.state.defaultCenter
                    }
                    zoom={17}
                    clickableIcons={true}
                    onDblClick={this.onDblClick}
                    mapTypeId="roadmap"
                  >
                    <Autocomplete
                      onLoad={this.onLoad}
                      onPlaceChanged={this.onPlaceChanged}
                    // types={['(regions)']} only region showing
                    >
                      <input
                        id="loc-search-place"
                        type="text"
                        placeholder="Search place"
                      />
                    </Autocomplete>
                    <Marker
                      // onLoad={onLoad}
                      position={
                        this.state.isMarkerShown
                          ? this.state.currentLatLng
                          : this.state.defaultCenter
                      }
                    />
                  </GoogleMap>
                </LoadScript>
              </div>
            </div>
          </Card>
        </ContentBody>
        <CompanyAddModal
          companyId={this.state.companyId}
          show={this.state.showCompany}
          onHide={this.setShowCompany}
          callback={this.getCompanies}
        ></CompanyAddModal>
        <DeleteCompanyConfirm
          companyId={this.state.companyId}
          selectedCompanies={this.state.selectedCompanies}
          onHide={this.setShowCompanyDelete}
          show={this.state.showCompanyDelete}
          deleteSelectedCompany={this.deleteSelectedCompany}
        ></DeleteCompanyConfirm>
      </Content>
    );
  }
}

export default withTranslation()(LocationDetailPage);
