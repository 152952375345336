import React, { Component } from 'react'
import { Accordion } from "react-bootstrap"

import Context from '../../Context'
import AccordionToggle from "../Accordion/Accordion"

import iContent from '../assets/content.svg'
import iJob from '../assets/job poster.svg'
import iSetting from '../assets/settings.svg'
import iLogout from '../assets/logout.svg'
import classes from './SidebarPrimary.module.css'

export default class SidebarPrimary extends Component {
    static contextType = Context
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {
        const contextValue = this.context
        let icon
        // const listItems = []

        // let content = listItems.items.map((m, i) => {
        //     return (
        //         <div className={classes.boxItem}>
        //             <div className={classes.titleItem}>
        //                 <AccordionToggle eventKey={i + 1} icon={icon}>
        //                     {parent}
        //                 </AccordionToggle>
        //             </div>
        //             <Accordion.Collapse eventKey={i + 1}>{details}</Accordion.Collapse>
        //         </div>
        //     )
        // })

        let primaryClass = !contextValue.activeSidebar ? classes.activePrimary : ''

        return (
            <div className={[classes.sidebarPrimary, primaryClass].join(' ')}>
                <div className={classes.boxProfile}>
                    <div className={classes.profile}>
                        <img src={iSetting} alt="profile" />
                    </div>
                    <span>Dashboard</span>
                </div>
                <div className={classes.borderLine}>
                    <hr />
                </div>
                <Accordion id="accPrimary" className={classes.customAccordion} defaultActiveKey='0'>
                    <div className={classes.boxItem}>
                        <div className={classes.titleItem}>
                            <AccordionToggle eventKey='0' icon={icon} activeSidebar={contextValue.activeSidebar}>
                                <img className={classes.titleIcon} src={iContent} alt="icon" />
                                <span>Content</span>
                            </AccordionToggle>
                        </div>
                        <Accordion.Collapse className={classes.accDetail} eventKey='0'>
                            <>
                                <div className={classes.mainDetailContent}>
                                    <span className={classes.active}>Pages</span>
                                    <div className={classes.toogle} onClick={contextValue.toogleActive}></div>
                                </div>
                                <div className={classes.detailContent}>
                                    <span>Menus</span>
                                </div>
                                <div className={classes.detailContent}>
                                    <span>Article</span>
                                </div>
                                <div className={classes.detailContent}>
                                    <span>Assets</span>
                                </div>
                            </>
                        </Accordion.Collapse>
                    </div>

                    <div className={classes.boxItem}>
                        <div className={classes.titleItem}>
                            <AccordionToggle eventKey='1' icon={icon}>
                                <img className={classes.titleIcon} src={iJob} alt="icon" />
                                <span>Job Poster</span>
                            </AccordionToggle>
                        </div>
                        <Accordion.Collapse className={classes.accDetail} eventKey='1'>
                            <>
                                <div className={classes.detailContent}>
                                    <span>Job</span>
                                </div>
                                <div className={classes.detailContent}>
                                    <span>Applicant</span>
                                </div>
                                <div className={classes.detailContent}>
                                    <span>child 3</span>
                                </div>
                            </>
                        </Accordion.Collapse>
                    </div>
                </Accordion>
                <div className={[classes.boxProfile, classes.boxLogout].join(' ')}>
                    <div className={classes.profile}>
                        <img src={iLogout} alt="profile" />
                    </div>
                    <span>Logout</span>
                </div>
            </div>
        )
    }
}
