import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import Select from "react-select";

import Context from "../../pages/Context";
import SuperAdminApi from "../../api/SuperAdminApi";

import "./modalGlobal.css";

export default class SuperAdminSiteAddModal extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            siteLanguage: []
        };
        // this.onOpenModal = this.onOpenModal.bind(this)
        // this.onHideModal = this.onHideModal.bind(this)
        this.createSite = this.createSite.bind(this);
    }

    createSite() {
        const Sa = new SuperAdminApi();
        const contextValue = this.context;
        const {siteLanguage} = this.state

        let langList = [];
        siteLanguage.forEach((cL) => {
            langList.push(cL.value);
        });

        const dataSite = {
            client: this.props.activeClient,
            name: document.getElementById("mSa-site-name").value,
            site_url: document.getElementById("mSa-site-url").value,
            api_url: document.getElementById("mSa-api-url").value,
            languages: langList
        };

        if (this.props.activeClient) {
            Sa.addSite(contextValue.userKey, dataSite)
                .then((res) => {
                    if (res.data) {
                        swal("Success", `Add new Site`, "success");
                        contextValue.getAllSitesByClient(this.props.activeClient, " ");
                        // this.props.callback(this.props.activeClient);
                        this.props.onHide();
                    }
                })
                .catch((err) => { });
        }
    }

    handleChangeSiteLanguage = (arr) => {
        this.setState({ siteLanguage: arr });
    }

    render() {

        const languageList = this.props.languageList
        const optionLanguage = []
        if (languageList.length) {
            for (const key in languageList) {
                optionLanguage.push({
                    value: languageList[key].id,
                    label: languageList[key].name
                })
            }
        }

        const req = <span style={{color: '#cc3300'}}>*</span>

        return (
            <Modal
                id="mglbl-modal-SASadd"
                size="md"
                className="mglbl-modal"
                show={this.props.show}
                onHide={() => this.props.onHide()}
            >
                {this.props.loadingModal}
                <Modal.Header>
                    <Modal.Title>Add New Sites</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>Site Name {req}</label>
                        <input id="mSa-site-name" type="text" name="mSa-site-name" className="form-control" defaultValue={""} ></input>
                    </div>
                    <div className="form-group">
                        <label>Site Url {req}</label>
                        <input id="mSa-site-url" type="text" name="mSa-site-url" className="form-control" defaultValue={""} ></input>
                    </div>
                    <div className="form-group">
                        <label>API Url {req}</label>
                        <input id="mSa-api-url" type="text" name="mSa-api-url" className="form-control" defaultValue={""} ></input>
                    </div>
                    <div className="form-group">
                        <label>Site Language {req}</label>
                        <Select
                            value={this.state.siteLanguage}
                            isMulti={true}
                            name="collection"
                            options={optionLanguage}
                            className={["basic-multi-select"].join(" ")}
                            classNamePrefix="select"
                            onChange={(selected) =>
                                this.handleChangeSiteLanguage(selected)
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex w-100 justify-content-end">
                        <button type="button" className="mglbl-btn-cancel mr-3" onClick={() => this.props.onHide()}>
                            Cancel
                        </button>
                        <button type="button" className="mglbl-btn-add" onClick={this.createSite}>
                            Create Client
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}
