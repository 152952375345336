import React, { Component } from "react";
import swal from "sweetalert2";
import Pagination from "react-js-pagination";
import { Button } from "react-bootstrap";
import ReactGA from "react-ga";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withTranslation } from "react-i18next";

import Context from "./Context";
import Card, { CardHeader, CardTitle, CardBody } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "../components/Content";
import PageSectionCatalogAdd from "./../components/Modal/PageSectionCatalogAdd";
import PageSectionDeleteConfirm from "../components/Modal/PageSectionDeleteConfirm";
import SectionCatalogMultipleUpdate from "../components/Modal/SectionCatalogMultipleUpdate";
import MetadataViewerModal from "../components/Modal/MetadataViewerModal";

import config from "../config/config";
import TitlePages from "./Elements/TitlePages";
import SectionApi from "../api/SectionApi";


class SectionCatalog extends Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired,
	};
	static contextType = Context;
	constructor(props) {
		super(props);
		const { cookies } = props;
		this.state = {
			sectionId: 0,
			sections: [],
			catalogtype: [],
			sectionType: "",
			clientType: false,
			selectedSections: [],
			deleteDisable: true,
			client: cookies.get("client"),
			showModal: false,
			showMultipleUpdate: false,
			showModalDelete: false,
			activePage: 1,
			limitPageData: 9,
			totalCount: 0,
			heightCardCatalog: null,
			search: "",
			transAlert: "alert.",
		};

		this.timeouts = {};
		this.getSectionCatalog = this.getSectionCatalog.bind(this);
		this.deleteSelectedSection = this.deleteSelectedSection.bind(this);
		this.setSelectedItem = this.setSelectedItem.bind(this);
		this.setShowModal = this.setShowModal.bind(this);
		this.setShowMultipleUpdate = this.setShowMultipleUpdate.bind(this);
		this.setItemId = this.setItemId.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.distributeItemId = this.distributeItemId.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
	}

	componentDidMount() {
		ReactGA.initialize(config.GA_ID);
		ReactGA.pageview(window.location.pathname + window.location.search);

		this.getSectionCatalog();
		if (window.screen.width <= 1440) {
			this.setState({
				heightCardCatalog: 150,
			});
		} else if (window.screen.width <= 1920) {
			this.setState({
				heightCardCatalog: 300,
			});
		} else if (window.screen.width === 2560) {
			this.setState({
				heightCardCatalog: 400,
			});
		}
		this.getAllCatalogsType()
	}

	setShowModal() {
		this.setState({
			showModal: !this.state.showModal,
		});
	}

	setShowModalDelete() {
		this.setState({
			showModalDelete: !this.state.showModalDelete,
		});
	}

	setShowMultipleUpdate() {
		this.setState({
			showMultipleUpdate: !this.state.showMultipleUpdate,
		});
	}

	getSectionCatalog() {
		const s = new SectionApi();
		const { activeClient } = this.context;
		const { sectionType, clientType } = this.state

		s.sectionCatalogListNew({
			client: clientType ? clientType : activeClient,
			// category: sectionType ? sectionType.charAt(0).toUpperCase() + sectionType.slice(1) : "",
			category: sectionType,
			page: this.state.activePage,
			page_size: this.state.limitPageData,
			name: this.state.search,
		}).then((res) => {
			this.setState({
				sections: res.data.results,
				totalCount: res.data.count,
			});
		});
	}

	getAllCatalogsType() {
		const s = new SectionApi();
		s.getCatalogType().then((res) => {
			this.setState({
				catalogtype: res.data,
			});
		});
	}

	setItemId(value) {
		this.setState(
			{
				sectionId: value,
			},
			() => {
				this.setShowModal();
			}
		);
	}

	distributeItemId(value) {
		const objSection = new SectionApi();

		objSection
			.sectionCatalogDistributeNew(value)
			.then((result) => {
				var contentMessage = "";

				result.data.messages.forEach((item) => {
					var splitItem = item.split(": ");

					if (splitItem[0] === "INFO") {
						contentMessage += `<li> <div class="label label-info"></div> ${splitItem[1]}</li>`;
					} else if (splitItem[0] === "WARNING") {
						contentMessage += `<li> <div class="label label-warning"></div> ${splitItem[1]}</li>`;
					} else if (splitItem[0] === "DANGER") {
						contentMessage += `<li> <div class="label label-danger"></div> ${splitItem[1]}</li>`;
					} else if (splitItem[0] === "SUCCESS") {
						contentMessage += `<li> <div class="label label-success"></div> ${splitItem[1]}</li>`;
					}
				});

				var htmlResult = `<ul>${contentMessage}</ul> `;

				swal.fire({
					html: `${htmlResult}`,
				});
			})
			.catch((err) => {
				swal.fire("Error!", err.message, "error");
			});
	}

	setSelectedItem(section, value) {
		var lastSelected = [...this.state.selectedSections];
		var indexDelete = -1;
		// var stateSelected = ""

		if (value === true) {
			lastSelected.push(section);
		} else {
			this.state.selectedSections.forEach((item, index) => {
				if (item.id === section.id) {
					indexDelete = index;
				}
			});

			lastSelected.splice(indexDelete, 1);
		}

		this.setState(
			{
				selectedSections: lastSelected,
			},
			() => {
				if (this.state.selectedSections.length > 0) {
					this.setState({
						deleteDisable: false,
					});
				} else {
					this.setState({
						deleteDisable: true,
					});
				}
			}
		);
	}

	deleteSelectedSection() {
		const { t } = this.props;
		const s = new SectionApi();

		const id = this.state.selectedSections.map((item) => {
			return item.id;
		});

		s.sectionDeleteNew(id)
			.then((res) => {
				this.setState({
					showModalDelete: false,
					disableDelete: true,
					selectedSections: [],
				});
				swal.fire(
					"Success !",
					t(this.state.transAlert + "delete_widget_in_section"),
					"success"
				);
				this.getSectionCatalog();
			})
			.catch((err) => {
				swal.fire("Error!", err.message, "error");
			});
	}

	handlePageChange(pageNumber) {
		this.setState(
			{
				activePage: pageNumber,
			},
			() => {
				this.getSectionCatalog();
			}
		);
	}

	handleSearchChange(value) {
		clearTimeout(this.timeouts.search);
		this.setState(
			{
				activePage: 1,
				search: value,
			},
			() => {
				this.timeouts.search = setTimeout(() => {
					this.getSectionCatalog();
				}, 333);
			}
		);
	}

	handleChangeSectionType(value) {
		if (value !== "x") {
			this.setState({
				sectionType: value,
				activePage: 1,
			}, () => {
				this.getSectionCatalog();
			});
		} else {
			if (value === "x") {
				this.setState({
					sectionType: "",
				}, () => {
					this.getSectionCatalog();
				});
			}
		}
	}

	handleChangeClientType(value) {
		if (value !== "x") {
			this.setState({
				clientType: value,
				activePage: 1,
			}, () => {
				this.getSectionCatalog();
			});
		} else {
			if (value === "x") {
				this.setState({
					clientType: false,
				}, () => {
					this.getSectionCatalog();
				});
			}
		}
	}

	setShowModalSectionMetadata = (value) => {
		this.setState({
			isSectionMetadata: !this.state.isSectionMetadata,
			sectionMetadata: value
		})
	}

	render() {
		const { t } = this.props;
		const contextValue = this.context
		const { clientList } = this.context

		let isSuperUser
		if (contextValue?.user.is_superuser) {
			isSuperUser = true
		}

		const SectionList = this.state.sections.map((item) => {
			return (
				<div className="col-md-4">
					<Card className="card card-primary">
						<CardHeader className="">
							<CardTitle>
								<input
									id={item.name + "-" + item.id}
									className=""
									type="checkbox"
									onClick={(event) =>
										this.setSelectedItem(item, event.currentTarget.checked)
									}
								></input>
								&nbsp;
								<i className="fa fa-align-left" title="section">
									&nbsp;
								</i>
								<label htmlFor={item.name + "-" + item.id}> {item.name}</label>
							</CardTitle>
							<div className="card-tools">
								<Button
									className="btn-sm"
									variant="default"
									onClick={() => this.setItemId(item.id)}
								>
									<i className="fa fa-edit"></i>
								</Button>

								<span>&nbsp;</span>
								<Button
									className="btn-sm"
									variant="default"
									onClick={() => this.distributeItemId(item.id)}
								>
									<i className="fa fa-sync"></i>
								</Button>

								<span>&nbsp;</span>

								<Button className="btn-sm" variant="default" onClick={() => this.setShowModalSectionMetadata({ name: item.name, metadata: item.metadata })}>
									<i className="fa fa-arrow-right"></i>
								</Button>

								<span>&nbsp;</span>
							</div>
						</CardHeader>
						<CardBody style={{ height: this.state.heightCardCatalog }}>
							<img
								style={{ width: "100%", height: "100%", objectFit: "cover" }}
								src={
									item.screenshot ? `${item.screenshot}` : "/img/bz-publish.svg"
								}
								alt={item.name}
							></img>
						</CardBody>
					</Card>
				</div>
			);
		});

		return (
			<Content>
				<ContentHeader>
					<TitlePages title={t("pages.section_catalog.title")} />
				</ContentHeader>
				<ContentBody>
					<Card>
						<CardHeader>
							<CardTitle>{" "}{t("pages.section_catalog.section_list")} :{" "}</CardTitle>
							<div className="card-tools">
								<Button
									variant="primary"
									onClick={() => this.setShowMultipleUpdate()}
									style={{ marginRight: 5 }}
								>
									<i className="fa fa-plus">&nbsp;</i>
									{t("pages.section_catalog.add_multiple_section")}
								</Button>
								<Button
									variant="danger"
									onClick={() => this.setShowModalDelete()}
									disabled={this.state.deleteDisable}
									style={{ marginRight: 5 }}
								>
									<i className="fa fa-trash">&nbsp;</i>
									{t("delete_selected")}
								</Button>
								<Button
									onClick={() =>
										this.setState({ sectionId: 0 }, () => this.setShowModal())
									}
								>
									<i className="fa fa-plus">&nbsp;</i>
									{t("add_section")}
								</Button>
							</div>
						</CardHeader>
						<CardBody>
							<div style={{ float: "left" }}>
								<div className="d-flex ">
									<div className="form-group mr-3">
										<label>Search: </label>
										<input
											type="text"
											placeholder={t("pages.section_catalog.search_section")}
											className="form-control"
											name="search"
											value={this.state.search}
											onChange={(event) =>
												this.handleSearchChange(event.target.value)
											}
										/>
									</div>
									<div className="form-group mr-3">
										<label>Filter: </label>
										<select className="form-control" onChange={(event) => this.handleChangeSectionType(event.target.value)}>
											<option value="x">-- Select Section Type --</option>
											{this.state.catalogtype.map((item) => {
												return (
													<option value={item}>{item}</option>
												);
											})}
										</select>
									</div>
									{
										isSuperUser &&
										<div className="form-group">
											<label>Client: </label>
											<select className="form-control" onChange={(event) => this.handleChangeClientType(event.target.value)}>
												<option value="x">-- Select Client --</option>
												{(clientList && clientList.length > 0) && clientList.map((item) => {
													return (
														<option value={item.id}>{item.name}</option>
													);
												})}
											</select>
										</div>
									}
								</div>
							</div>
							<div style={{ float: "right", marginTop: "2rem" }}>
								<Pagination
									activePage={this.state.activePage}
									itemsCountPerPage={this.state.limitPageData}
									totalItemsCount={this.state.totalCount}
									pageRangeDisplayed={4}
									onChange={this.handlePageChange.bind(this)}
									itemClass="page-item"
									linkClass="page-link"
								/>
							</div>
							<div className="clearfix"></div>
							<div className="row">{SectionList}</div>
							<div style={{ float: "right" }}>
								<Pagination
									activePage={this.state.activePage}
									itemsCountPerPage={this.state.limitPageData}
									totalItemsCount={this.state.totalCount}
									pageRangeDisplayed={4}
									onChange={this.handlePageChange.bind(this)}
									itemClass="page-item"
									linkClass="page-link"
								/>
							</div>
							<div className="clearfix"></div>
						</CardBody>
					</Card>
				</ContentBody>

				<MetadataViewerModal
					jsonData={this.state.sectionMetadata?.metadata}
					show={this.state.isSectionMetadata}
					onHide={() => {
						this.setShowModalSectionMetadata(false)
					}}
				/>
				<SectionCatalogMultipleUpdate
					id={this.state.sectionId}
					client={this.state.client}
					show={this.state.showMultipleUpdate}
					onHide={this.setShowMultipleUpdate}
					getSectionCatalog={this.getSectionCatalog}
				></SectionCatalogMultipleUpdate>
				<PageSectionCatalogAdd
					id={this.state.sectionId}
					show={this.state.showModal}
					onHide={this.setShowModal}
					getSectionCatalog={this.getSectionCatalog}
					client={this.state.client}
				></PageSectionCatalogAdd>
				<PageSectionDeleteConfirm
					id={this.state.sectionId}
					show={this.state.showModalDelete}
					onHide={() => {
						this.setState({ showModalDelete: false });
					}}
					selectedSections={this.state.selectedSections}
					deleteSelectedSection={this.deleteSelectedSection}
				></PageSectionDeleteConfirm>
			</Content>
		);
	}
}

export default withTranslation()(withCookies(SectionCatalog));
