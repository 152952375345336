import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import CRMApi from "../../api/CRMApi";
import swal from "sweetalert"
// import { withTranslation } from "react-i18next";

class CustomerNoteAddModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: "add",
            data: {}
        }

        this.handleChangeData = this.handleChangeData.bind(this)
        this.submitNote = this.submitNote.bind(this)
        this.deleteNote = this.deleteNote.bind(this)
    }

    onOpenModal = () => {
        if (this.props.noteId) {
            this.getNoteDetail(this.props.noteId)
        }
    }

    onCloseModal = () => {
        this.setState({ data: {} })
        this.props.toggle(false)
    }

    getNoteDetail(noteId) {
        const crmApi = new CRMApi()

        crmApi
            .getNoteDetail(noteId)
            .then(res => {
                this.setState({ data: res.data })
            })
    }

    handleChangeData(key, value) {
        let newData = { ...this.state.data }
        newData[key] = value
        this.setState({ data: newData })
    }

    submitNote() {
        const crmApi = new CRMApi()

        const data = {
            member: this.props.memberId,
            ...this.state.data
        }

        if (this.props.noteId) {
            crmApi
                .updateNote(this.props.noteId, data)
                .then(res => {
                    this.onCloseModal()
                    this.props.reload()
                    swal(
                        "Success !",
                        "You sucessfully updated note !",
                        "success"
                    );
                })
        } else {
            crmApi
                .addNote(data)
                .then(res => {
                    this.onCloseModal()
                    this.props.reload()
                    swal(
                        "Success !",
                        "You sucessfully added note !",
                        "success"
                    );
                })
        }
    }

    deleteNote() {
        const crmApi = new CRMApi()

        if (this.props.noteId) {
            swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this note!",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        crmApi
                            .deleteNote(this.props.noteId)
                            .then(res => {
                                this.onCloseModal()
                                this.props.reload()
                                swal(
                                    "Success !",
                                    "You sucessfully deleted note !",
                                    "success"
                                );
                            })
                    }
                });
        }
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onShow={this.onOpenModal}
                onHide={this.onCloseModal}
            >
                <Modal.Header closeButton className="bg-primary">
                    <Modal.Title>Add New Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="note-context">Context</label>
                        <input value={this.state.data.context} className="form-control" type="text" id="note-context" onChange={(e) => { this.handleChangeData("context", e.target.value) }} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="note-date">Date</label>
                        <input value={this.state.data.date} className="form-control" type="date" id="note-date" onChange={(e) => { this.handleChangeData("date", e.target.value) }} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="note-reason">Reason</label>
                        <input value={this.state.data.reason} className="form-control" type="text" id="note-reason" onChange={(e) => { this.handleChangeData("reason", e.target.value) }} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="note-contacted-person">Contacted Person</label>
                        <input value={this.state.data.contacted_person} className="form-control" type="text" id="note-contacted-person" onChange={(e) => { this.handleChangeData("contacted_person", e.target.value) }} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="note-result">Result</label>
                        <input value={this.state.data.result} className="form-control" type="text" id="note-result" onChange={(e) => { this.handleChangeData("result", e.target.value) }} />
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between flex-row-reverse">
                    <Button variant="primary" onClick={this.submitNote}>Submit</Button>
                    {
                        !!this.props.noteId &&
                        <Button variant="danger" onClick={this.deleteNote} ><i className="fas fa-trash"></i></Button>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default CustomerNoteAddModal;
