import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class PageSectionDeleteConfirm extends Component {
  constructor(props) {
    super(props);

    this.deleteSelectedSection = this.deleteSelectedSection.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  deleteSelectedSection() {
    // dari props method nya
    this.props.deleteSelectedSection();
  }

  onOpenModal() {
  }

  onCloseModal() {
    this.props.onHide();
  }

  render() {
    const { t } = this.props;
    const listSection = this.props.selectedSections.map((item) => {
      return <li key={item.id}>{item.name}</li>;
    });

    return (
      <Modal
        show={this.props.show}
        onHide={this.onCloseModal}
        onShow={this.onOpenModal}
      >
        <Modal.Header closeButton className="bg-danger">
          <Modal.Title>{t("modals.section_delete.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("modals.section_delete.confirmation_text")}</p>
          <ul>{listSection}</ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onCloseModal}>
            {t("cancel")}
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              this.deleteSelectedSection();
            }}
          >
            <i className="fa fa-check">&nbsp;</i>
            {t("modals.section_delete.delete_section")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(PageSectionDeleteConfirm);
