import React, { Component } from "react";
// import { Button } from "react-bootstrap"

import SectionApi from "../../api/SectionApi";
import WidgetApi from "../../api/WidgetApi";

class ListCardRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleName: "",
      widget: {},
    };
  }

  getDetailSection(sectionId) {
    const objSection = new SectionApi();

    objSection.getDetailSection(sectionId).then((res) => {
      this.setState({
        titleName: res.data.name,
      });
    });
  }

  getWidgetDetail(widgetId) {
    const objWidget = new WidgetApi();

    objWidget.getDetailWidget(widgetId).then((res) => {
      this.setState({
        titleName: res.data.name,
        widget: res.data,
      });
    });
  }

  componentDidMount() {
    if (this.props.type === "section") {
      if (!isNaN(this.props.val)) {
        this.getDetailSection(this.props.val);
      } else {
        this.setState({
          titleName: this.props.val,
        });
      }
    } else if (this.props.type === "widget") {
      if (!isNaN(this.props.val)) {
        this.getWidgetDetail(this.props.val);
      } else {
        this.setState({
          titleName: "11. " + this.props.val,
        });
      }
    } else {
      this.setState({
        titleName: this.props.val,
      });
    }
  }

  render() {
    var className = this.props.className || "";
    var index = "";
    var checkBox = "";
    var tools = "";

    if (this.props.header) {
      index = this.props.data?.index ? (
        <b>{`${this.props.data.index}. `}</b>
      ) : (
        ""
      );

    }

    if (this.props.tools) {
    
    checkBox = (
      <input
        type="checkbox"
        className="checkbox"
        // style={{ marginRight: '100px' }}
        id={"label" + this.state.titleName}
        onClick={(event) => {
          this.props.setSelectedItem(
            this.props.data,
            event.currentTarget.checked
          );
        }}
      ></input>
    );
      }

    if (this.props.tools) {
      tools = (
        <div
          style={{
            float: "right",
            width: 5,
            cursor: "pointer",
            position: "absolute",
            top: "10px",
            right: "20px",
          }}
          onClick={() => this.props.setItemId(this.props.data.id)}
        >
          <i className="fa fa-edit"></i>
        </div>
      );
    }

    var link = this.state.titleName;
    if (this.props.type === "section") {
      link = (
        <span
          style={{ color: "black" }}
          htmlFor={"label" + this.state.titleName}
        >
          {" "}
          - {this.state.titleName}{" "}
        </span>
      );
    } else if (this.props.type === "widget") {
      link = (
        <span
          style={{ color: "black" }}
          htmlFor={"label" + this.state.titleName}
        >
          {" "}
          {this.state.titleName}
          id: {this.props.data.id}
        </span>
      );
    } else if (this.props.type === "element") {
      var icon = "fa fa-puzzle-piece";
      if (this.state.titleName === "Article") {
        icon = "fa fa-newspaper";
      }
      if (this.state.titleName === "Media") {
        icon = "fa fa-photo-video";
      }
      if (this.state.titleName === "Text") {
        icon = "fa fa-font";
      }
      if (this.state.titleName === "Job Poster") {
        icon = "fa fa-briefcase";
      }

      link = (
        <span
          style={{ color: "black" }}
          htmlFor={"label" + this.state.titleName}
        >
          <i className={" " + icon}>&nbsp;</i> {this.state.titleName}
        </span>
      );
    }

    let heighNum;
    if (this.props.className === "active") {
      heighNum = "100px";
    } else {
      heighNum = "unset";
    }
    return (
      <>

      
      {checkBox}
      <li
        className={"list-group-item " + className}
        style={{ height: heighNum }}
      >
        <div className="form-check form-check-inline">
          {index} {link}
        </div>
        {tools}
      </li>
      </>
    );
  }
}

export default ListCardRow;
