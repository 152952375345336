import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"
import { withTranslation } from 'react-i18next';

class MenuDeleteConfirm extends Component {

    constructor(props) {
        super(props)

        this.deleteSelectedMenu = this.deleteSelectedMenu.bind(this)
        this.onOpenModal = this.onOpenModal.bind(this)
        // this.onHideModal = this.onHideModal.bind(this)
    }

    deleteSelectedMenu() {
        // dari props method nya
        this.props.deleteSelectedMenu()
    }

    onOpenModal() {
    }


    render() {
        const { t } = this.props;
        const listFileName = this.props.selectedMenu.map(item => {
            return (<li key={item.id}>{item.name}</li>)
        })

        return (
            <Modal show={this.props.show} onHide={() => this.props.onHide()} onShow={() => this.onOpenModal()}>
                {this.props.loadingModal}
                <Modal.Header closeButton className="bg-danger">
                    <Modal.Title>{t('modals.menu_delete.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('modals.menu_delete.title')}</p>
                    <ul>
                        {listFileName}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.props.onHide()}>
                        {t('cancel')}
                    </Button>
                    <Button variant="danger" onClick={() => this.deleteSelectedMenu()}>
                        <i className="fa fa-trash">&nbsp;</i>
                        {t('modals.menu_delete.delete_menu')}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(MenuDeleteConfirm);