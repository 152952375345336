import React, { Component } from "react";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import swal from "sweetalert";

import JobApi from "./../../api/JobApi";
import { withTranslation } from "react-i18next";
import Context from "../Context";
import Cookies from "js-cookie";
class PositionAddModal extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            position: "",
            positionLanguages: [],
            languageList: [],
            clientID: parseInt(Cookies.get("active-client")),
            tabKey: 1,
            selectedLanguage: 1,
            idElement: 0,
            renderTabLanguage: "",
            transPage: "modals.position_add.",
            transAlert: "alert.",
        };

        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.handleChangePosition = this.handleChangePosition.bind(this);
        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.addPosition = this.addPosition.bind(this);
        this.updatePosition = this.updatePosition.bind(this);
        this.renderTabLanguage = this.renderTabLanguage.bind(this);
    }

    onOpenModal() {
        const { activeSiteLang } = this.context;

        this.setState(
            {
                id: this.props.positionId | 0,
                tabKey: 1,
                selectedLanguage: 1,
                languageList: activeSiteLang,
            },
            () => {
                if (this.props.positionId) {
                    this.getDetailPosition();
                } else {
                    this.renderTabLanguage();
                }
            }
        );
    }

    onCloseModal() {
        this.setState(
            {
                position: "",
                positionLanguages: [],
                languageList: [],

                tabKey: 1,
                selectedLanguage: 1,
                idElement: 0,
                renderTabLanguage: "",
            },
            () => {
                this.props.callback();
                this.props.onHide();
            }
        );
    }

    handleChangePosition(id, value) {
        let position_languages = [...this.state.positionLanguages];
        let position_language = { ...position_languages[id] };
        position_language.name = value;
        position_languages[id] = position_language;

        this.setState(
            {
                position: position_languages[0].name,
                positionLanguages: position_languages,
            }
        );
    }

    handleChangeTab(value) {
        this.setState(
            {
                selectedLanguage: value,
                tabKey: value,
            },
            () => {
                this.renderTabLanguage();
            }
        );
    }

    getDetailPosition() {
        const obJob = new JobApi();

        obJob
            .getDetailPosition(this.props.positionId)
            .then((res) => {
                // alert(JSON.stringify(res.data))

                this.setState(
                    {
                        id: res.data.id,
                        name: res.data.name,
                        positionLanguages: res.data.position_languages,
                    },
                    () => {
                        this.renderTabLanguage();
                    }
                );
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    addPosition() {
        const { t } = this.props;
        const obJob = new JobApi();

        const langs = [];
        this.state.positionLanguages.forEach((el) => {
            langs.push({
                language: el.language,
                name: el.name,
            });
        });

        const request = {
            client: this.state.clientID,
            name: this.state.position,
            position_languages: langs,
        };

        obJob
            .addPosition(request)
            .then((res) => {
                swal(
                    "Success!",
                    t(this.state.transAlert + "position_add_success"),
                    "success"
                );
                this.onCloseModal();
            })
            .catch((err) => {
                swal("Error!", err.message, "error");
            });
    }

    updatePosition() {
        const { t } = this.props;
        const obJob = new JobApi();
        const langs = [];
        this.state.positionLanguages.forEach((el) => {
            langs.push({
                language: el.language,
                name: el.name,
            });
        });

        const request = {
            client: this.state.clientID,
            name: this.state.position,
            position_languages: langs,
        };

        obJob
            .updatePosition(this.props.positionId, request)
            .then((res) => {
                swal(
                    "Success!",
                    t(this.state.transAlert + "position_update_success"),
                    "success"
                );
                this.onCloseModal();
            })
            .catch((err) => {
                swal("Error!", err.message, "error");
            });
    }

    renderTabLanguage() {
        const renderTabLanguage = this.state.languageList.map((item) => {
            var result = this.state.positionLanguages.find(
                (positionLang) => item.id === positionLang.language
            );

            if (!result) {
                result = {
                    language: item?.language?.id,
                    name: "",
                };
            }

            result.languageName = item.name;

            return result;
        });

        this.setState(
            {
                positionLanguages: renderTabLanguage,
            }
        );
    }

    render() {
        const { t } = this.props;

        var HeaderText = (
            <Modal.Title>
                {" "}
                {t(this.state.transPage + "add_position")}{" "}
            </Modal.Title>
        );

        var SubmitButton = (
            <Button variant="primary" onClick={() => this.addPosition()}>
                <i className="fa fa-plus">&nbsp;</i>
                {t(this.state.transPage + "add_position")}
            </Button>
        );

        if (this.props.positionId) {
            HeaderText = (
                <Modal.Title>
                    {" "}
                    {t(this.state.transPage + "update_position")}{" "}
                </Modal.Title>
            );
            SubmitButton = (
                <Button variant="primary" onClick={() => this.updatePosition()}>
                    <i className="fa fa-plus">&nbsp;</i>
                    {t(this.state.transPage + "update_position")}
                </Button>
            );
        }

        const TabLanguage = this.state.positionLanguages.map((item, index) => {
            return (
                <Tab
                    key={index}
                    eventKey={item.language}
                    title={item.languageName}
                >
                    <br></br>

                    <div className="form-group">
                        <label> {t("position")} </label>
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.positionLanguages[index].name}
                            onChange={(event) =>
                                this.handleChangePosition(
                                    index,
                                    event.target.value
                                )
                            }
                        ></input>
                        {/* { JSON.stringify( this.state.jobTypeLanguages )  } */}
                    </div>
                </Tab>
            );
        });

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                onShow={this.onOpenModal}
            >
                <Modal.Header closeButton className="bg-primary">
                    {HeaderText}
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <Tabs
                                id="controlled-tab-example-3"
                                activeKey={this.state.tabKey}
                                onSelect={(k) => this.handleChangeTab(k)}
                            >
                                {TabLanguage}
                            </Tabs>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>{SubmitButton}</Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(PositionAddModal);
