import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import LocationApi from "../../api/LocationApi";
import _ from 'lodash'
import swal from "sweetalert"
import AlertHelper from "./../../lib/AlertHelper"
import Select from "react-dropdown-select"
// import { withTranslation } from "react-i18next";

// Components
import { GoogleMap, Marker, Autocomplete } from "@react-google-maps/api";

// Context
import Context from "../../pages/Context"

class CrmLocationAddModal extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            countries: [],

            selectedCountry: {},
            name: "",
            address: "",
            latitude: -6.1741,
            longitude: 106.8296,
            isMain: false,
        }

        this.getAllCountries = this.getAllCountries.bind(this)
        this.handleChangePlace = this.handleChangePlace.bind(this)
        this.handleChangeValue = this.handleChangeValue.bind(this)
        this.handleChangeCountry = this.handleChangeCountry.bind(this)
        this.handleChangeMarker = this.handleChangeMarker.bind(this)
        this.createLocation = this.createLocation.bind(this)
        this.autocomplete = null
    }

    defaultValues = {
        selectedCountry: {},
        name: "",
        address: "",
        latitude: -6.1741,
        longitude: 106.8296,
        isMain: false,
    }

    onOpenModal = () => {
        this.getAllCountries()
    }

    onCloseModal = () => {
        this.props.onHide()
        this.setState(this.defaultValues)
    }

    onLoad = (autocomplete) => {
        this.autocomplete = autocomplete;
    }

    getAllCountries() {
        const locApi = new LocationApi();

        locApi
            .getAllCountries({
                max_size: true
            })
            .then((res) => {
                this.setState({
                    countries: res.data.results,
                });
            })
            .catch((err) => { console.log(err) });
    }

    handleChangePlace(place) {
        const { countries } = this.state

        // ----- Use google maps
        place = this.autocomplete.getPlace()

        let selectedCountry = {}
        const addressComponents = place?.address_components;

        if (addressComponents) {
            const countriesName = this.state.countries.map(country => country.iso_code)
            const addressCountry = addressComponents.map(address => address.short_name)
            const foundedCountry = _.intersection(countriesName, addressCountry)
            const countryCode = foundedCountry.length ? foundedCountry[0] : ""
            selectedCountry = countries.find(({ iso_code }) => iso_code === countryCode);
        }


        this.setState((prevState) => ({
            address: place?.formatted_address,
            latitude: place?.geometry.location.lat(),
            longitude: place?.geometry.location.lng(),
            selectedCountry: selectedCountry,
        }))
    }

    handleChangeValue(stateName, value) {
        this.setState({ [stateName]: value })
    }

    handleChangeCountry(value) {
        if (!value.length) {
            return
        }

        const { countries } = this.state
        const selectedCountry = countries.find(({ id }) => id === parseInt(value[0].value));

        this.setState({ selectedCountry: selectedCountry })
    }

    handleChangeMarker(event) {
        const latitude = event.latLng.lat()
        const longitude = event.latLng.lng()

        this.setState({ latitude, longitude })
    }

    createLocation() {
        const locationApi = new LocationApi();
        const { activeClient } = this.context
        const { selectedCountry, name, address, latitude, longitude, isMain } = this.state

        const data = {
            client: activeClient,
            country: selectedCountry.id,
            name: name,
            address: address,
            latitude: latitude,
            longitude: longitude,
            is_main: isMain,
        }

        locationApi
            .addLocation(data)
            .then(res => {
                swal(
                    "Success !",
                    "You successfully create new location !",
                    "success"
                )
                    .then(this.onCloseModal)
            })
            .catch(err => {
                const alertHelper = new AlertHelper()
                alertHelper.errorHandlerAlert(err.response.data)
            })
    }

    render() {
        const { latitude, longitude } = this.state

        const containerStyle = {
            width: "100%",
            height: "100%",
        };

        const center = {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
        };

        return (
            <Modal
                show={this.props.show}
                onShow={this.onOpenModal}
                onHide={this.onCloseModal}
                size="lg"
            >
                <Modal.Header closeButton className="bg-primary">
                    <Modal.Title>Create New Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group" style={{ height: "200px" }}>
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={15}
                            mapTypeId="roadmap"
                            onLoad={this.onLoad}
                            onRightClick={this.handleChangeMarker}
                        >
                            <Autocomplete
                                onLoad={this.onLoad}
                                onPlaceChanged={this.handleChangePlace}
                            >
                                <input
                                    id="loc-search-place"
                                    type="text"
                                    placeholder="Search place"
                                />
                            </Autocomplete>
                            <Marker position={center} />
                        </GoogleMap>
                    </div>
                    <div className="form-group">
                        <label htmlFor="create-location-name">Name</label>
                        <input
                            id="create-location-name"
                            type="text"
                            className="form-control"
                            value={this.state.name}
                            onChange={(e) => { this.handleChangeValue("name", e.target.value) }}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="create-location-address">Address</label>
                        <textarea
                            id="create-location-address"
                            type="text"
                            className="form-control"
                            value={this.state.address}
                            onChange={(e) => { this.handleChangeValue("address", e.target.value) }}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="create-location-country">Country</label>
                        {/* <select
                            id="create-location-country"
                            className="form-control"
                            value={this.state.selectedCountry.id}
                            onChange={(e) => { this.handleChangeCountry(e.target.value) }}
                        >
                            <option value="">--- Please select country ---</option>
                            {
                                this.state.countries.map(country => <option value={country.id}>{country.name}</option>)
                            }
                        </select> */}
                        <Select
                            className="bg-white"
                            searchable={true}
                            id="create-location-country"
                            options={this.state.countries.length && this.state.countries.map(country => ({ label: country.name, value: country.id }))}
                            placeholder="-- Please select country --"
                            values={this.state.selectedCountry.id ? [{ label: this.state.selectedCountry.name, value: this.state.selectedCountry.id }] : []}
                            closeOnSelect={true}
                            onChange={(values) => { this.handleChangeCountry(values) }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.createLocation} >Create</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default CrmLocationAddModal;