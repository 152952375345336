import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import CRMApi from "../../api/CRMApi";
import swal from "sweetalert"
import Select from "react-dropdown-select"
import _ from 'lodash'
import AlertHelper from "./../../lib/AlertHelper"
import Spinner from 'react-bootstrap/Spinner'
// import { withTranslation } from "react-i18next";

// Context
import Context from "../../pages/Context"

import './ContactAddModal.css'

class ContactAddModal extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            mode: "add",
            members: [],
            data: this.defaultData,
            isLoadingMembers: false,
        }

        this.getMembers = this.getMembers.bind(this)
        this.getContactDetail = this.getContactDetail.bind(this)
        this.handleChangeData = this.handleChangeData.bind(this)
        this.submitContact = this.submitContact.bind(this)
    }

    defaultData = {
        member: 0,
        contact_title: null,
        honorific: "",
        name: "",
        position: "",
        phone: "",
        email: ""
    }

    componentDidUpdate(prevProps) {
        if (prevProps.idContactToUpdate !== this.props.idContactToUpdate && this.props.idContactToUpdate) {
            this.getContactDetail()
            if (this.props.idContactToUpdate) {
                this.setState({ mode: "edit" })
            } else {
                this.setState({ mode: "add" })
            }
        }
    }

    onOpenModal = () => {
        if (!this.props.noSubmitApi) {
            this.getMembers()
        }
    }

    onCloseModal = () => {
        this.props.onHide()
        if (!this.props.noSubmitApi) {
            this.setState({ data: this.defaultData })
        }
    }

    getMembers() {
        const crmApi = new CRMApi();
        const { activeClient } = this.context
        const clientId = activeClient

        this.setState({ isLoadingMembers: true }, () => {
            crmApi
                .getAllMembersName({ client: clientId, max_size: true })
                .then(res => {
                    this.setState({ members: res.data, isLoadingMembers: false })
                })
                .catch(e => {
                    console.log(e)
                })
        })
    }

    getContactDetail() {
        const crmApi = new CRMApi();
        crmApi
            .getContactDetail(this.props.idContactToUpdate)
            .then(res => {
                const data = {
                    ...res.data,
                    member: res.data.member.id
                }
                this.setState({ data: data })
            })
            .catch(e => {
                console.log(e)
            })
    }

    getTitle() {
        if (this.state.mode === "add") {
            return "Add new contact"
        } else if (this.state.mode === "edit") {
            return "Edit contact"
        }
    }

    handleChangeData(key, value) {
        let newData = _.cloneDeep(this.state.data)

        if (key === "member" && value.length) {
            value = value[0].value
        }

        newData[key] = value
        this.setState({ data: newData })
    }

    submitContact() {
        const crmApi = new CRMApi()

        const data = {
            member: this.props.memberId,
            ...this.state.data,
            contact_title: this.state.data.contact_title?.id,
        }

        if (this.props.idContactToUpdate) {
            crmApi
                .updateContact(this.props.idContactToUpdate, data)
                .then(res => {
                    this.setState({ data: this.defaultData })
                    this.props.onHide()
                    this.props.reload()
                    swal(
                        "Success !",
                        "You sucessfully updated contact !",
                        "success"
                    );
                })
                .catch(err => {
                    const alertHelper = new AlertHelper()
                    alertHelper.errorHandlerAlert(err.response.data)
                })
        } else if (this.props.noSubmitApi) {
            this.props.handleAssignedContact(data, this.props.idx)
            this.props.onHide()
        } else {
            crmApi
                .addContact(data)
                .then(res => {
                    this.setState({ data: this.defaultData })
                    this.props.onHide()
                    this.props.reload()
                    swal(
                        "Success !",
                        "You sucessfully added contact !",
                        "success"
                    );
                })
                .catch(err => {
                    const alertHelper = new AlertHelper()
                    alertHelper.errorHandlerAlert(err.response.data)
                })
        }
    }

    render() {


        return (
            <Modal
                show={this.props.show}
                onShow={this.onOpenModal}
                onHide={this.onCloseModal}
            >
                <Modal.Header closeButton className="bg-primary">
                    <Modal.Title>{this.getTitle()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        (!!this.state.members.length || this.props.noSubmitApi) && <>
                            {
                                !this.props.noSubmitApi &&
                                <div className="form-group">
                                    <label htmlFor="contact-company">Company</label>
                                    <div id="contact-company">
                                        <Select
                                            className="bg-white pl-3"
                                            searchable
                                            options={this.state.members.length && this.state.members.map(member => ({ label: member.name, value: member.id }))}
                                            placeholder="-- Please select company --"
                                            values={this.state.members.filter(member => member.id === this.state.data.member).map(member => ({ label: member.name, value: member.id }))}
                                            closeOnSelect={true}
                                            onChange={(values) => { this.handleChangeData("member", values) }}
                                        />
                                    </div>
                                </div>
                            }

                            <div className="form-group">
                                <label htmlFor="contact-honorific">Honorific</label>
                                <select className="form-control" id="contact-honorific" value={this.state.data.honorific} onChange={(e) => { this.handleChangeData("honorific", e.target.value) }}>
                                    <option value="">--- Please select honorific ---</option>
                                    <option value="Mr.">Mr.</option>
                                    <option value="Mrs.">Mrs.</option>
                                    <option value="Ms.">Ms.</option>
                                    <option value="Dr.">Dr.</option>
                                    <option value="Prof.">Prof.</option>
                                    <option value="H.E.">H.E.</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="contact-name">Name</label>
                                <input className="form-control" type="text" id="contact-name" value={this.state.data.name} onChange={(e) => { this.handleChangeData("name", e.target.value) }} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="contact-position">Position</label>
                                <input className="form-control" type="text" id="contact-position" value={this.state.data.position} onChange={(e) => { this.handleChangeData("position", e.target.value) }} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="contact-phone">Phone</label>
                                <input className="form-control" type="text" id="contact-phone" value={this.state.data.phone} onChange={(e) => { this.handleChangeData("phone", e.target.value) }} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="contact-email">Email</label>
                                <input className="form-control" type="email" id="contact-email" value={this.state.data.email} onChange={(e) => { this.handleChangeData("email", e.target.value) }} />
                            </div>
                        </>
                    }
                    {
                        (!this.state.members.length && this.state.isLoadingMembers) &&
                        <div className="d-flex align-items-center justify-content-center">
                            <Spinner animation="border" />
                        </div>
                    }
                    {
                        !this.state.members.length && !this.state.isLoadingMembers && !this.props.noSubmitApi &&
                        <p>You have to add new company first!</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={this.state.isLoadingMembers} onClick={this.submitContact}>Submit</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ContactAddModal;
