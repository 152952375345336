import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import PageApi from "./../../api/PageApi";
import swal from "sweetalert";
import Context from "../../pages/Context";

class PageDeleteKeywords extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      name: "",
      content: "",
      input_type: "Small",
      text_languages: [],
      languageList: [],
      tabKey: 1,
      selectedLanguage: 1,
      idElement: 0,
      renderTabLanguage: "",
      selectedPageId: 0,
      selectedSectionId: 0,
    };
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleContentChange = this.handleContentChange.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleInputTypeChange = this.handleInputTypeChange.bind(this);
    this.renderTabLanguage = this.renderTabLanguage.bind(this);
    this.deleteKeywordPage = this.deleteKeywordPage.bind(this);
  }

  onOpenModal() {
    const { adminSiteLanguage } = this.context;

    this.setState(
      {
        id: this.props.textId | 0,
        tabKey: 1,
        selectedLanguage: 1,
        languageList: adminSiteLanguage,
      },
      () => {
        if (this.state.id) {
          //alert(this.state.id)
          this.getDetailTextElement();
        } else {
          this.renderTabLanguage();
        }
      }
    );
  }

  onCloseModal() {
    this.setState(
      {
        id: 0,
        name: "",
        // languageList:[],
        tabKey: 1,
        selectedLanguage: 1,
        text_languages: [],
        renderTabLanguage: "",
      },
      () => {
        this.props.onHide();
      }
    );
  }

  handleInputTypeChange(value) {
    this.setState({
      input_type: value,
    });
  }

  handleNameChange(value) {
    this.setState({
      name: value,
    });
  }

  handleContentChange(id, value) {
    let text_languages = [...this.state.text_languages];
    let text_language = { ...text_languages[id] };
    text_language.content = value;
    text_languages[id] = text_language;

    this.setState({
      text_languages: text_languages,
    });
  }

  handleChangeTab(value) {
    this.setState(
      {
        selectedLanguage: value,
        tabKey: value,
      },
      () => {
        this.renderTabLanguage();
      }
    );
  }

  deleteKeywordPage(id) {
    let idPage = this.props.id;
    const objPage = new PageApi();

    objPage
      .deleteKeywordPage(id)
      .then((res) => {
        this.props.onHide();
        swal("Success !", "You sucessfully deleted text SEO !", "success");
        this.props.getDataAll(idPage);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  renderTabLanguage() {
    const renderTabLanguage = this.state.languageList.map((item) => {
      var result = this.state.text_languages.find(
        (textLang) => item.id === textLang.language
      );

      if (!result) {
        result = {
          language: item?.language?.id,
          text_element: 4,
          content: "",
          languageName: item.name,
        };
      }

      result.languageName = item.name;
      return result;
    });

    this.setState(
      {
        text_languages: renderTabLanguage,
      }
    );
  }

  render() {
    var ButtonExecute = (
      <Button
        variant="danger"
        onClick={() => this.deleteKeywordPage(this.props.idKey)}
      >
        <i className="fa fa-trash">&nbsp;</i>
        OK
      </Button>
    );

    return (
      <Modal
        show={this.props.show}
        onHide={() => this.onCloseModal()}
        onShow={this.onOpenModal}
        size="md"
      >
        <Modal.Header closeButton className="bg-danger">
          <Modal.Title>Delete SEO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure want to delete <b>{this.props.keySelected}</b> ?
        </Modal.Body>
        <Modal.Footer>{ButtonExecute}</Modal.Footer>
      </Modal>
    );
  }
}

export default PageDeleteKeywords;
