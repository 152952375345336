import React, { Component } from "react";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import swal from "sweetalert";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import AlertHelper from "./../../lib/AlertHelper";

import WidgetApi from "../../api/WidgetApi";
import Context from "../../pages/Context"

class WidgetTextAddModal extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      name: "",
      content: "",
      input_type: "Small",

      text_languages: [],
      languageList: [],

      tabKey: 1,
      selectedLanguage: 1,
      idElement: 0,
      renderTabLanguage: "",
    };

    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleContentChange = this.handleContentChange.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleInputTypeChange = this.handleInputTypeChange.bind(this);

    this.getDetailTextElement = this.getDetailTextElement.bind(this);

    this.renderTabLanguage = this.renderTabLanguage.bind(this);
  }

  onOpenModal() {
    const { activeSiteLang } = this.context
    this.setState(
      {
        id: this.props.textId | 0,
        tabKey: 1,
        selectedLanguage: 1,
        languageList: activeSiteLang,
      },
      () => {

        if (this.state.id) {
          //alert(this.state.id)
          this.getDetailTextElement();
        } else {
          this.renderTabLanguage();
        }
      }
    );
  }

  onCloseModal() {
    this.setState(
      {
        id: 0,
        name: "",
        // languageList:[],
        tabKey: 1,
        selectedLanguage: 1,
        text_languages: [],
        renderTabLanguage: "",
      },
      () => {
        this.props.onHide();
      }
    );
  }

  getDetailTextElement() {
    const obj = new WidgetApi();
    const textId = this.props.textId;

    obj.widgetTextDetail(textId).then((res) => {
      this.setState(
        {
          id: res.data.id,
          name: res.data.name,
          text_languages: res.data.text_languages,
          input_type: res.data.input_type,
        },
        () => {
          this.renderTabLanguage();
        }
      );
    });
  }

  handleInputTypeChange(value) {
    this.setState({
      input_type: value,
    });
  }

  handleNameChange(value) {
    this.setState({
      name: value,
    });
  }

  handleContentChange(id, value) {
    let text_languages = [...this.state.text_languages];
    let text_language = { ...text_languages[id] };
    text_language.content = value;
    text_languages[id] = text_language;

    this.setState({
      text_languages: text_languages,
    });
  }

  handleChangeTab(value) {
    this.setState(
      {
        selectedLanguage: value,
        tabKey: value,
      },
      () => {
        this.renderTabLanguage();
      }
    );
  }

  addWidgetText() {
    const objWidget = new WidgetApi();

    this.setState({
      loadingModal: (
        <div className="overlay d-flex justify-content-center align-items-center">
          <i className="fas fa-2x fa-sync fa-spin"></i>
        </div>
      ),
    });

    const inputTextLanguage = this.state.text_languages.map((item) => {
      return {
        language: item.language,
        content: item.content,
      };
    });

    objWidget
      .widgetTextAdd(this.props.widgetId, this.state.name, inputTextLanguage)
      .then((res) => {
        //this.props.setSelectedAssetAdd(this.state.selectedAssets)
        this.props.getWidgetDetail(this.props.widgetId);
        this.setState({
          loadingModal: "",
        });
        this.props.onHide();
        swal(
          "Success !",
          "You sucessfully added text into this widget !",
          "success"
        );
      })
      .catch((err) => {
        const objAlert = new AlertHelper();
        const resErr = err?.response?.data ? err?.response?.data : err.message;
        objAlert.errorHandlerAlert(resErr);
      });
  }

  updateWidgetText() {
    const objWidget = new WidgetApi();

    this.setState({
      loadingModal: (
        <div className="overlay d-flex justify-content-center align-items-center">
          <i className="fas fa-2x fa-sync fa-spin"></i>
        </div>
      ),
    });

    const inputTextLanguage = this.state.text_languages.map((item) => {
      return {
        id: item.id,
        language: item.language,
        content: item.content,
      };
    });

    const data = {
      textId: this.state.id,
      name: this.state.name,
      input_type: this.state.input_type,
      text_languages: inputTextLanguage,
    };

    objWidget
      .widgetTextUpdate(data)
      .then((res) => {
        //this.props.setSelectedAssetAdd(this.state.selectedAssets)
        this.props.getWidgetDetail(this.props.widgetId);
        this.setState({
          loadingModal: "",
        });
        this.props.onHide();
        swal(
          "Success !",
          "You sucessfully added text into this widget !",
          "success"
        );
      })
      .catch((err) => {
        // const objAlert = new AlertHelper()
        // const resErr = err?.response?.data ? err?.response?.data : err.message
        console.log(" Error => ", err?.response?.data);
        // objAlert.errorHandlerAlert(resErr)
      });
  }

  renderTabLanguage() {
    const renderTabLanguage = this.state.languageList.map((item) => {
      var result = this.state.text_languages.find(
        (textLang) => item.id === textLang.language
      );

      if (!result) {
        result = {
          language: item?.language?.id,
          text_element: 4,
          content: "",
          languageName: item.name,
        };
      }

      result.languageName = item.name;
      return result;
    });

    this.setState(
      {
        text_languages: renderTabLanguage,
      }
    );
  }

  render() {
    var HeaderExecute = "Add";
    var ButtonExecute = (
      <Button variant="primary" onClick={() => this.addWidgetText()}>
        <i className="fa fa-plus">&nbsp;</i>
        Add Text Element
      </Button>
    );

    if (this.state.id) {
      HeaderExecute = "Update";
      ButtonExecute = (
        <Button variant="primary" onClick={() => this.updateWidgetText()}>
          <i className="fa fa-edit">&nbsp;</i>
          Update Text Element
        </Button>
      );
    }

    let ckeditorConfig = {
      toolbar: {
        items: [
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "superscript",
          "subscript",
          "|",
          "link",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",

          "|",
          "undo",
          "redo",
        ],
      },
      table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
      },
      language: "",
    };

    const TabLanguage = this.state.text_languages.map((item, index) => {
      var TextEditor = (
        <textarea
          className="form-control"
          onChange={(event) =>
            this.handleContentChange(index, event.target.value)
          }
          value={this.state.text_languages[index].content}
        ></textarea>
      );

      if (this.state.input_type === "Big") {
        TextEditor = TextEditor = (
          <CKEditor
            id="editor"
            config={ckeditorConfig}
            editor={ClassicEditor}
            data={this.state.text_languages[index].content}
            onInit={(editor) => {
              // You can store the "editor" and use when it is needed.
            }}
            onChange={(event, editor) => {
              const data = editor.getData();

              this.handleContentChange(index, data);
            }}
          />
        );
      }

      return (
        <Tab key={index} eventKey={item.language} title={item.languageName}>
          <br></br>

          <div className="form-group">
            <label> Content </label>
            {TextEditor}
            {/* { JSON.stringify( this.state.text_languages )  } */}
          </div>
        </Tab>
      );
    });

    return (
      <Modal
        show={this.props.show}
        onHide={() => this.onCloseModal()}
        onShow={this.onOpenModal}
        size="lg"
      >
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title>
            {HeaderExecute} {this.props.widgetName} Text Element{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label> Name </label>
              <input
                type="text"
                className="form-control"
                onChange={(event) => this.handleNameChange(event.target.value)}
                value={this.state.name}
              ></input>
            </div>

            <Tabs
              id="controlled-tab-example-3"
              activeKey={this.state.tabKey}
              onSelect={(k) => this.handleChangeTab(k)}
            >
              {TabLanguage}
            </Tabs>

            {/* <Tabs
                            id="controlled-tab-example-1"
                            activeKey={this.state.tabKey}
                            onSelect={(k) => this.handleChangeTab(k)}
                        >
                            <Tab key={1} eventKey={1} title={"Satu"}>
                                <p> Tab 1 </p>
                            </Tab>
                            <Tab key={2} eventKey={2} title={"Dua"}>
                                <p> Tab 2 </p>
                            </Tab>
                    
                        </Tabs> */}
          </form>
        </Modal.Body>
        <Modal.Footer>{ButtonExecute}</Modal.Footer>
      </Modal>
    );
  }
}

export default WidgetTextAddModal;
