import React, { Component } from "react";
import swal from "sweetalert";
import { Button } from "react-bootstrap";
import SortableTree from "react-sortable-tree";

// import ValueContext from '../components/section/Context'

import TitlePages from "./Elements/TitlePages";
import Card, { CardHeader, CardBody } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content";
import PageAddModal from "../components/Modal/PageAddModal";
import PageDeleteConfirm from "../components/Modal/PageDeleteConfirm";

import SiteApi from "./../api/SiteApi";
import PageApi from "./../api/PageApi";
import TemplateApi from "./../api/TemplateApi";
import BucketApi from "./../api/BucketApi";

import { shortSentence } from "./../lib/StringHelper";
import { withTranslation } from "react-i18next";
import UserContext from "./Context";
import { Link } from "react-router-dom";

class SiteStructure extends Component {
	// static contextType = ValueContext
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			site: {},
			template: {},
			pageId: 0,
			selectedLang: localStorage.getItem("lang"),
			//pages:[],
			selectedPages: [],
			templateInformation: [],
			disableDelete: true,
			showModal: false,
			showModalConfirm: false,
			buildType: "",

			treeData: [],
			treeDataTable: [],
			parent: null,
			overlay: "",
			isLoading: false,
			transAlert: "alert.",
			activeTemplate: 0,
			activeSite: 0,
			lengtOfPages: 0,
			namesOfTemplate: "",
			get: null,

			templateId: false
		};

		// this.getCompanyDetail = this.getCompanyDetail.bind(this)
		// this.getAllTemplates = this.getAllTemplates.bind(this)
		// this.selectedTemplate = this.selectedTemplate.bind(this)
		this.getSiteStructure = this.getSiteStructure.bind(this);
		this.getSiteDetail = this.getSiteDetail.bind(this);
		this.getTemplatePage = this.getTemplatePage.bind(this);
		// this.getSitePages = this.getSitePages.bind(this)
		this.deleteSelectedPage = this.deleteSelectedPage.bind(this);
		this.siteStructureUpdate = this.siteStructureUpdate.bind(this);

		this.getTemplateInformation = this.getTemplateInformation.bind(this);

		this.setLoading = this.setLoading.bind(this);
		this.setOverlay = this.setOverlay.bind(this);
		this.setSelectedPage = this.setSelectedPage.bind(this);
		this.setShowModal = this.setShowModal.bind(this);
		this.setShowModalConfirm = this.setShowModalConfirm.bind(this);

		this.buildTemplate = this.buildTemplate.bind(this);
		this.updateBucketSettings = this.updateBucketSettings.bind(this);

		this.setPageId = this.setPageId.bind(this);
	}

	componentDidMount() {
		this.setState(
			{
				selectedLang: localStorage.getItem("lang"),
				activeTemplate: localStorage.getItem("templateId"),
				activeSite: localStorage.getItem("siteId"),
			},
			() => {
				this.getTemplatePage();
				this.getSiteStructure();
				this.getSiteDetail(this.state.activeSite);
				this.getTemplateInformation();
			}
		);
	}

	componentDidUpdate() {
		const { activeTemplate } = this.context;
		const { activeSite } = this.context;

		if (this.state.selectedLang !== localStorage.getItem("lang")) {
			this.getSiteStructure();
		} else if (activeTemplate !== this.state.activeTemplate) {
			this.setState(
				{
					activeTemplate: activeTemplate,
				},
				this.getSiteStructure()
			);
			this.getSiteDetail(this.state.activeSite);
			this.getTemplateInformation();
		} else if (activeSite !== this.state.activeSite) {
			this.setState(
				{
					activeSite: activeSite,
				},
				this.getSiteStructure()
			);
			//   this.getTemplateInformation();
		}
		if (activeTemplate !== this.state.activeTemplate) {
			this.setState({
				activeTemplate: activeTemplate
			}, () => {
				this.getTemplatePage()
				this.getTemplateInformation();
			})
		}
	}

	setShowModal() {
		this.setState({
			showModal: !this.state.showModal,
		});
	}

	setShowModalConfirm() {
		this.setState({
			showModalConfirm: !this.state.showModalConfirm,
		});
	}

	setLoading() {
		this.setState(
			{
				isLoading: !this.state.isLoading,
			},
			() => {
				this.setOverlay();
			}
		);
	}

	setOverlay() {
		var overlay = "";
		if (this.state.isLoading) {
			overlay = (
				<div className="overlay d-flex justify-content-center align-items-center">
					<i className="fa fa-2x fa-sync fa-spin"></i>
				</div>
			);
		}

		this.setState({
			overlay: overlay,
		});
	}

	getSiteDetail(param) {
		const s = new SiteApi();

		// const siteId = parseInt(localStorage.getItem("siteId"));

		s.getSiteDetail(param).then((res) => {
			this.setState({
				site: res.data,
			});
		});
	}

	getTemplatePage(templateName) {
		const objTemplate = new TemplateApi();

		const templateId = parseInt(localStorage.getItem("templateId"));

		objTemplate.getTemplateDetail(templateId)
			.then((res) => {
				this.setState({
					templateId: res.data
				}, () => {
					// this.getTemplatePage()
				})
			})

	}

	getTemplateInformation() {
		const t = new TemplateApi();
		const templateId = parseInt(localStorage.getItem("templateId"));

		t.getTemplateInformationNew(templateId)
			.then((res) => {
				this.setState({
					templateInformation: res.data.results,
				});
			})
			.catch((err) => {
				console.log(err.response);
			});
	}

	getSiteStructure() {
		const objSite = new SiteApi();

		const templateId = this.props.match.params.templateId | localStorage.getItem("templateId");

		const params = {
			language: localStorage.getItem("langId"),
		};

		// alert(JSON.stringify(params))

		objSite
			.getSiteStructure(templateId, params)
			.then((res) => {
				if (res.data.pages.length === 0) {
					this.setState({
						lengtOfPages: null,
					});
				} else {
					this.setState({
						lengtOfPages: "ada",
					});
				}

				res.data.expanded = true;
				const newFormat = res.data.pages.map((item) => {
					return {
						...item,
						expanded: true,
					};
				});

				this.setState(
					{
						treeData: newFormat,
						namesOfTemplate: res.data.name,
						selectedLang: localStorage.getItem("lang"),
					},
					() => { }
				);
			})
			.catch((e) => {
				this.setState({
					treeData: [],
				});
			});
	}

	handleBuildTypeChange(value) {
		this.setState({
			buildType: value,
		});
	}

	buildTemplate() {
		this.setLoading();
		const objTemplate = new TemplateApi();
		const siteId = localStorage.getItem("siteId");
		const templateId = localStorage.getItem("templateId");
		const { templateInformation } = this.state;

		const data = {
			state: this.state.buildType,
			site_id: siteId,
		};

		let idx = this.state.buildType === "preview" ? 0 : 1;
		let idT = templateInformation[idx]?.id;

		objTemplate
			.buildTemplate(templateId, data)
			.then((res) => {
				if (res.data.message) {
					const template = {
						template: templateInformation[idx]?.template,
						bucket_url: res.data.s3_url,
					};
					swal("Success !", res.data.message, "success");
					this.updateBucketSettings(idT, template);
					this.setLoading();
				}
			})
			.catch((err) => {
				swal("Error !", err.message, "error");
				this.setLoading();
			});
	}

	updateBucketSettings(id, template) {
		const BS = new BucketApi();

		BS.updateBucketSettings(id, template)
			.then((res) => {
				if (res.data) {
					this.getTemplateInformation();
				}
			})
			.catch((err) => {
				if (err) {
					swal("Error!", err.message, "error");
				}
			});
	}

	setSelectedPage(itemData, value) {
		var lastSelected = [...this.state.selectedPages];
		var indexDelete = -1;

		if (value === true) {
			lastSelected.push(itemData);
		} else {
			this.state.selectedPages.forEach((item, index) => {
				if (item.id === itemData.id) {
					indexDelete = index;
				}
			});

			lastSelected.splice(indexDelete, 1);
		}

		this.setState(
			{
				selectedPages: lastSelected,
			},
			() => {
				if (this.state.selectedPages.length > 0) {
					this.setState({
						disableDelete: false,
					});
				} else {
					this.setState({
						disableDelete: true,
					});
				}
			}
		);
	}

	setPageId(value) {
		this.setState({
			pageId: value,
		});

		this.setShowModal();
	}

	deleteSelectedPage() {
		const { t } = this.props;
		const objPage = new PageApi();

		const pages = this.state.selectedPages.map((item) => {
			return item.id;
		});

		objPage
			.pageDeleteNew(pages)
			.then((res) => {
				this.getSiteStructure();
				swal("Success !", t(this.state.transAlert + "delete_page_in_template_success"), "success");
				this.setState({
					showModalConfirm: false,
					disableDelete: true,
					selectedPages: [],
				});
				setTimeout(() => {
					window.location.reload();
				}, 100);
			})
			.catch((err) => {
				swal("Error!", err.message, "error");
			});
	}

	siteStructureUpdate() {
		const siteObj = new SiteApi();
		// const siteId = this.props.match.params.siteId | localStorage.getItem("siteId");
		const tmp = localStorage.getItem("templateId");
		const data = {
			page_update: this.state.treeData,
		};

		siteObj
			.updateSiteStructure(tmp, data)
			.then((res) => { })
			.catch((err) => {
				console.log(err);
			});
	}

	render() {
		const { t } = this.props;
		const { templateInformation } = this.state;

		// this.state.get(parseInt(this.state.activeTemplate));

		let templateInfo;
		if (templateInformation.length > 0) {
			templateInfo = templateInformation.map((t) => {
				return (
					<div className="form-group">
						<label>{t.name} : </label>
						<a href={t.bucket_url} target="_blank" rel="noreferrer">
							{" "}
							{t.bucket_url}
						</a>
					</div>
				);
			});
		}

		return (
			<Content>
				<ContentHeader>
					<TitlePages title={t("pages.site_structure.title")} />
				</ContentHeader>

				<ContentBody>
					<Card>
						{this.state.overlay}
						<CardHeader>
							<h3 className="card-title">
								{t("site")} : {this.state.site.name}
								<br></br>
								{t("template")} : {this.state.namesOfTemplate}
								<br></br>
							</h3>

							<div className="card-tools">
								{/* <Button variant="danger" disabled={this.state.disableDelete} onClick={() =>{ this.setShowModalConfirm()}} style={{marginRight:5}}>
                                    <i className="fa fa-trash">&nbsp;</i>
                                    Delete Selected
                                </Button> */}
								<div className="row">
									<select className="form-control col-md-5" onChange={(event) => this.handleBuildTypeChange(event.target.value)}>
										<option value=""> -- {t("pages.site_structure.select_build")} --</option>
										<option value="preview"> {t("pages.site_structure.preview")} </option>
										<option value="publish"> {t("pages.site_structure.publish")} </option>
									</select>
									&nbsp;
									<Button className="float-right" onClick={() => this.buildTemplate()}>
										<i className="fa fa-industry">&nbsp;</i>
										{t("build")}
									</Button>
									&nbsp;
									<Button
										disabled={this.state.treeData.length > 0}
										className="float-right"
										onClick={() => {
											this.setState({ pageId: 0 }, () => this.setShowModal());
										}}
									>
										<i className="fa fa-plus">&nbsp;</i>
										{t("pages.site_structure.add_page")}
									</Button>
								</div>
							</div>
						</CardHeader>
						<CardBody style={{ height: "100vh" }}>
							<div style={{ height: "100%", width: "100%", position: "relative" }}>
								<SortableTree
									treeData={this.state.treeData}
									onChange={(treeData) => this.setState({ treeData })}
									onMoveNode={() => {
										this.siteStructureUpdate();
									}}
									generateNodeProps={({ node }) => ({
										title: (
											<div title={node.name}>
												<span className="float-left">{shortSentence(node.name, 15) + " ... "}</span>
												<div className="float-right">
													<button
														title="add Child"
														className="btn btn-success btn-xs"
														style={{ margin: "0 5px" }}
														onClick={() => {
															this.setState(
																{
																	pageId: 0,
																	parent: node.id,
																},
																() => {
																	this.setShowModal();
																}
															);
														}}
													>
														<i className="fa fa-plus"></i>
													</button>
													<button
														title="Update this item"
														className="btn btn-primary btn-xs"
														style={{ margin: "0 5px" }}
														onClick={() => {
															this.setState(
																{
																	pageId: node.id,
																},
																() => this.setShowModal()
															);
														}}
													>
														<i className="fa fa-edit"></i>
													</button>
													<Button
														disabled={node.parent === undefined}
														title="Delete this item"
														variant="danger"
														className="btn-xs"
														style={{ margin: "0 5px" }}
														onClick={() => {
															this.setState(
																{
																	selectedPages: [node],
																},
																() => this.setShowModalConfirm()
															);
														}}
													>
														<i className="fa fa-trash"></i>
													</Button>
													<button
														title={`To Page ${node.name}`}
														className="btn btn-primary btn-xs"
														style={{ margin: "0 5px" }}
														
													>
														
													<Link to={`/page/detail/${node.id}`}><i className="fa fa-arrow-right" 
														style={{  color: 'white' }}
													
													></i></Link>
													</button>

												</div>
											</div>
										),
									})}
								/>
								<div
									style={{
										width: "35%",
										position: "absolute",
										top: "0",
										right: "2rem",
										zIndex: "2",
									}}
								>
									<div
										style={{
											position: "relative",
											display: "flex",
											flexDirection: "column",
											padding: "1.25rem",
											border: "1px solid black",
										}}
									>
										<label>Template Information</label>
										{templateInfo}
									</div>
								</div>
							</div>
							<div className="clearfix"></div>
						</CardBody>
					</Card>
				</ContentBody>

				<PageAddModal pageId={this.state.pageId} parent={this.state.parent} show={this.state.showModal} onHide={this.setShowModal} lengtOfPages={this.state.lengtOfPages} getSiteStructure={this.getSiteStructure}></PageAddModal>
				<PageDeleteConfirm selectedPages={this.state.selectedPages} show={this.state.showModalConfirm} onHide={this.setShowModalConfirm} deleteSelectedPage={this.deleteSelectedPage}></PageDeleteConfirm>
			</Content>
		);
	}
}

export default withTranslation()(SiteStructure);
