import React, { useEffect, useContext, useState, useCallback, useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import CRMApi from '../../api/CRMApi';
import Context from '../../pages/Context';
import _ from 'lodash'
import swal from 'sweetalert';
import AlertHelper from "./../../lib/AlertHelper";

function CrmBulkChangeModal(props) {
    const { t } = props
    const transPage = "modals.crm_bulk_change."
    const { activeClient } = useContext(Context);
    const crmApi = useMemo(() => new CRMApi(), []);

    const [attributeCategories, setAttributeCategories] = useState([])
    const [attributeOptions, setAttributeOptions] = useState({})
    const [selectedOptions, setSelectedOptions] = useState({})

    const onOpenModal = () => {
        getAttributeCategories()
    }

    const getAttributeCategories = () => {
        const params = {
            max_size: true,
            client: activeClient
        }

        crmApi
            .getAttributeCategories(params)
            .then(res => {
                setAttributeCategories(res.data.results)
            })
    }

    const getAllAttributeOptions = useCallback(async (attributeIds) => {
        let newAttributeOptions = {}
        let newSelectedOptions = {}
        await attributeIds.reduce(async (promise, attrId) => {
            await promise
            const res = await crmApi.getAttributeOptions(attrId)
            newAttributeOptions[attrId] = res.data.results
            newSelectedOptions[attrId] = ""
        }, Promise.resolve())

        setSelectedOptions(newSelectedOptions)
        setAttributeOptions(newAttributeOptions)
    }, [crmApi])

    const handleChangeSelectedOption = (value, attributeId) => {
        let newSelectedOptions = _.cloneDeep(selectedOptions)
        newSelectedOptions[attributeId] = value

        setSelectedOptions(newSelectedOptions)
    }

    const submitData = async () => {
        const params = props.getFilterParams()
        const updatedData = Object.keys(selectedOptions)
            .filter(key => selectedOptions[key])
            .map(key => ({
                attribute: key,
                attribute_option: selectedOptions[key]
            }));

        try {
            let successMessage = ""
            await updatedData.reduce(async (promise, data) => {
                await promise
                const res = await crmApi.bulkUpdateMembersData(params, data)
                successMessage = res.data?.detail
            }, Promise.resolve())

            swal(
                "Success !",
                `${successMessage}`,
                "success"
            );

            props.onHide()
            props.reload()
        } catch (err) {
            const alertHelper = new AlertHelper()
            alertHelper.errorHandlerAlert(err.response.data);
        }
    }

    useEffect(() => {
        let attrThatHasOptions = []
        attributeCategories.forEach(category => {
            category.attributes.forEach(attr => {
                if (attr.value_type === "Option") {
                    attrThatHasOptions.push(attr.id)
                }
            })
        })

        getAllAttributeOptions(attrThatHasOptions)
    }, [getAllAttributeOptions, attributeCategories])

    return (
        <Modal show={props.show} onHide={() => props.onHide()} onShow={() => onOpenModal()}>
            <Modal.Header closeButton className="bg-primary" >
                <Modal.Title>{t(transPage + "title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    attributeCategories
                        .map(category => (
                            category.attributes
                                .filter(attribute => attribute.value_type === "Option" && !attribute.multiple_value)
                                .map(attribute => (
                                    <div className="form-group">
                                        <label>{attribute.name}</label>
                                        <select
                                            value={selectedOptions[attribute.id]}
                                            className="form-control"
                                            onChange={(e) => { handleChangeSelectedOption(e.target.value, attribute.id) }}
                                        >
                                            <option value="">---</option>
                                            {
                                                attributeOptions[attribute.id]?.map(option => (
                                                    <option value={option.id}>{option.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                ))
                        ))
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.onHide()}>
                    {t('cancel')}
                </Button>
                <Button variant="primary" onClick={submitData}>
                    {t("apply")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default withTranslation()(CrmBulkChangeModal);