import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import swal from "sweetalert";

import Context from "../../pages/Context";

import SuperAdminApi from "../../api/SuperAdminApi";

class SiteDelete extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {

        };

        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    deleteSite = () => {
        const Sa = new SuperAdminApi();
        const contextValue = this.context;
        const data = this.props.data
        const id = data.id

        if (id) {
            Sa.deleteSite(id)
                .then((res) => {
                    if (res.status === 204) {
                        // this.props.callback()
                        contextValue.getAllSitesByClient(this.props.activeClient," ");
                        this.props.onHide()
                        swal("Success", `Delete site`, "success");
                    }
                })
                .catch((err) => {
                    console.log('err', err)
                });
        }
    }

    onCloseModal() {
        this.props.onHide();
    }

    onOpenModal() {
    }

    render() {
        const { t } = this.props;
        const data = this.props.data

        return (
            <Modal
                show={this.props.show}
                onHide={() => this.onCloseModal()}
                onShow={() => this.onOpenModal()}
            >
                {this.props.loadingModal}
                <Modal.Header closeButton className="bg-danger">
                    <Modal.Title>
                        Delete Site's
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete site <strong>{data?.name}</strong></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => this.onCloseModal()}
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        variant="danger"
                        onClick={this.deleteSite}
                    >
                        <i className="fa fa-trash">&nbsp;</i>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(SiteDelete);
