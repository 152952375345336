import axios from "axios"
import config from "./../config/config"
class MenuApi {

    // base_api = "http://192.168.13.177:7000/"
    base_api = config.base_api
    token = config.token

    getAllMenu(params) {
        return axios.get(this.base_api + "menus/", {
            params: params,
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    getDetailMenu(menuId) {
        return axios.get(this.base_api + `menus/${menuId}/`, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    getDetailMenuItem(menuItemId) {
        return axios.get(this.base_api + `menu_items/${menuItemId}/`, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    menuAdd(menu) {
        return axios.post(this.base_api + `menus/`, {
            name: menu.name,
            template: menu.template,
        }, {

            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    menuUpdate(id, menu) {
        return axios.patch(this.base_api + `menus/${id}/`, {
            name: menu.name,
            item_update: menu.items
        },
            {
                headers: {
                    "Authorization": "Token " + this.token,
                    "Content-Type": "application/json"
                }
            }
        )
    }

    menuUpdates(id, menu) {
        return axios.patch(this.base_api + `menus/${id}/`, {
            name: menu.name,
            items: menu.items
        },
            {
                headers: {
                    "Authorization": "Token " + this.token,
                    "Content-Type": "application/json"
                }
            }
        )
    }


    menuItemAdd(data) {
        return axios.post(this.base_api + `menu_items/`, data, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    menuItemUpdate(menuId, data) {
        return axios.patch(this.base_api + `menu_items/${menuId}/`, data, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    menuItemDelete(menuItemId) {
        return axios.delete(this.base_api + `menu_items/${menuItemId}/`, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    menuMultiDelete(ids) {
        return axios.delete(this.base_api + `menu_delete/`, {
            data: {
                ids: ids
            },
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }


}

export default MenuApi