import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"

class GeneralDeleteConfirm extends Component {

    constructor(props) {
        super(props)

        this.deleteSelectedGeneral = this.deleteSelectedGeneral.bind(this)
        this.onOpenModal = this.onOpenModal.bind(this)
        // this.onHideModal = this.onHideModal.bind(this)
    }

    deleteSelectedGeneral() {
        // dari props method nya
        this.props.deleteSelectedGeneral()
    }

    onOpenModal() {
    }

    render() {
        console.log('selectedGeneral', this.props.selectedGeneral);
        const generalList = this.props.selectedGeneral.map((item,index) => {

            return(<li key={index}>{item.type} : {item.name || item.asset_name}</li>)
        })

        return (
            <Modal show={this.props.show} onHide={() => this.props.onHide()} onShow={() => this.onOpenModal()}>
                {this.props.loadingModal}
                <Modal.Header closeButton className="bg-danger">
                    <Modal.Title>Confirm Delete Elements </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p> Are you sure want to delete these Elements ? </p>
                    <ul>
                       {generalList}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.props.onHide()}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => this.deleteSelectedGeneral()}>
                        <i className="fa fa-trash">&nbsp;</i>
                        Delete Selected Elements
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default GeneralDeleteConfirm;
