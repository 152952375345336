import React, { Component } from "react";
// import { Redirect } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import TemplateApi from "../../api/TemplateApi";
import swal from "sweetalert";
import Context from "../../pages/Context";

class TemplateDeleteConfirm extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      transPage: "modals.template_delete.",
      isHide: false,
      loadingModal: (
        <div className="overlay d-flex justify-content-center align-items-center">
          <i className="fas fa-2x fa-sync fa-spin"></i>
        </div>
      ),
    };

    this.onOpenModal = this.onOpenModal.bind(this);
    this.deleteTemplateNew = this.deleteTemplateNew.bind(this);
  }

  deleteTemplateNew() {
    const objTemplate = new TemplateApi();
    const { activeSite, getSiteDetail } = this.context.appContext;

    const idt = [];
    const ids = parseInt(localStorage.getItem("siteId"));
    idt.push(this.props.selectedTemplate[0].id);

    const data = {
      site_id: ids,
      template_ids: idt,
    };

    objTemplate
      .deleteTemplate(data.template_ids)
      .then((res) => {
        swal("Success !", "You successfully unpair the template.", "success");
        getSiteDetail(activeSite);
        this.props.onHide();
        this.props.onSuccess();
      })
      .catch((err) => {
        console.log("err =>", err.response.data);
      });
  }

  onOpenModal() {
  }

  render() {
    const { t } = this.props;
    const listTemplate = this.props.selectedTemplate.map((item) => {
      return <li key={item.id}>{item.name}</li>;
    });

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onShow={() => this.onOpenModal()}
      >
        <Modal.Header closeButton className="bg-danger">
          <Modal.Title>{t(this.state.transPage + "title")} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p> {t(this.state.transPage + "confirmation_text")}</p>
          <ul>{listTemplate}</ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.props.onHide()}>
            {t("cancel")}
          </Button>
          <Button variant="danger" onClick={() => this.deleteTemplateNew()}>
            <i className="fa fa-trash">&nbsp;</i>
            {t(this.state.transPage + "delete_template")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(TemplateDeleteConfirm);
