import React, { Component } from 'react';
import { ListGroup } from "react-bootstrap"
// import { Link } from "react-router-dom"

class ListCardWidget extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    componentDidMount(){
        
    }

    render() {

        const { id, category_name, name } = this.props.data

        var link = ""
        var titleName = category_name
        var icon = "fa fa-puzzle-piece"
        var checkedVariant = "bg bg-primary"

        if(this.props.data.id === this.props.selectedWidget.id) {
            checkedVariant = "bg bg-success"
        }

        if(category_name === "Article") {
            icon = "fa fa-newspaper"
                            
        }
        if(category_name === "Media") {
            icon = "fa fa-photo-video"    
        }
        if( category_name === "Text"){
            icon = "fa fa-font"
        }
        if( category_name === "Job Poster"){
            icon = "fa fa-briefcase"
        }

        link = <span  style={{"color":"black"}} htmlFor={"label"+id}> <i className={" "+icon}>&nbsp;</i> {titleName} </span>
        return (
            
            <ListGroup as="ul" style={{marginBottom:20}}>
                <ListGroup.Item className={checkedVariant} active>
                    <div className="float-left">
                        <input type="radio" defaultChecked={this.props.data.id === this.props.selectedWidget.id}  name="select" id={`widget-${id}`} 
                        onClick={() => { this.props.setSelectedItem(this.props.data)}} ></input>
                        &nbsp;
                        <label htmlFor={`widget-${id}`} style={{ wordWrap:"break-word"}}>{name } <br></br> id : {id}</label> 
                    </div>
                   
                    <div className="float-right">
                        <span className={`btn text-white`} onClick={() => this.props.setShowSelectedWidget(this.props.data)}>
                            <i className="fa fa-edit"></i>
                        </span>
                    </div>
                    <div className="clearfix"></div>
                </ListGroup.Item>
                <ListGroup.Item> Category :  </ListGroup.Item>
                <ListGroup.Item> { link } </ListGroup.Item>
               
            </ListGroup>
            
           
        );
    }
}

export default ListCardWidget;