import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
import AlertHelper from "./../../lib/AlertHelper";
import TemplateApi from "./../../api/TemplateApi";
import { withTranslation } from "react-i18next";
import config from "./../../config/config";

class TemplateAddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      name: "",
      screenshot: "",
      transPage: "modals.template_add.",
      transAlert: "alert.",
      isActive: false,
      status: false,
      currentStatus: false,
      active: false,
      check: null,
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleActiveTemplate = this.handleActiveTemplate.bind(this);
    this.handleScreenshotFile = this.handleScreenshotFile.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.getTemplateDetail = this.getTemplateDetail.bind(this);
  }

  onOpenModal() {
    this.setState(
      {
        id: this.props.id,
        status: this.props.status_active,
        id_site: parseInt(this.props.id_site),
      },
      () => {
        if (this.state.id) {
          this.getTemplateDetail();
        }
      }
    );
  }

  handleNameChange(value) {
    this.setState({
      name: value,
    });
  }

  handleActiveTemplate(value) {
    if (value === "check") {
      this.setState({
        currentStatus: true,
      });
    }
  }

  handleScreenshotFile(e) {
    this.setState({
      site: e.target.files[0],
    });
  }

  getTemplateDetail() {
    const objTemplate = new TemplateApi();

    objTemplate.getTemplateDetail(this.state.id).then((res) => {
      this.setState({
        name: res.data.name,
      });
    });
  }

  updateTemplateNew() {
    const objTemplate = new TemplateApi();
    const id = this.props.id;
    let GA = config.GA_ID;
    const formData = new FormData();

    formData.append("id", id);
    formData.append("site", this.state.id_site);
    formData.append("name", this.state.name);
    formData.append("ga_code", GA);

    objTemplate
      .updateTemplateNew(id, formData)
      .then((res) => {
        swal("Success !", "You successfully updated this template", "success");
        setTimeout(() => {
          window.location.reload();
        }, 0);
      })
      .catch((err) => {
        const objAlert = new AlertHelper();
        const resErr = err?.response?.data ? err?.response?.data : err.message;
        objAlert.errorHandlerAlert(resErr);
      });
  }

  updateActiveTemplate() {
    const objTemplate = new TemplateApi();

    const data = {
      site_id: this.state.id_site,
      template_ids: this.state.id,
    };

    objTemplate
      .updateActiveTemplate(data)
      .then((res) => {
      })
      .catch((err) => {
        const objAlert = new AlertHelper();
        const resErr = err?.response?.data ? err?.response?.data : err.message;
        objAlert.errorHandlerAlert(resErr);
      });
  }

  render() {
    const { t } = this.props;
    var HeaderTitle = t(this.state.transPage + "title_add");
    var BtnSubmit = (
      <Button variant="primary" onClick={() => this.createTemplateNew()}>
        <i className="fa fa-plus">&nbsp;</i>
        {t(this.state.transPage + "add_template")}
      </Button>
    );

    if (this.state.id) {
      HeaderTitle = t(this.state.transPage + "title_update");
      BtnSubmit = (
        <Button variant="primary" onClick={() => this.updateTemplateNew()}>
          <i className="fa fa-edit">&nbsp;</i>
          {t(this.state.transPage + "update_template")}
        </Button>
      );
    }

    return (
      <Modal
        show={this.props.show}
        onHide={() => this.props.onHide()}
        onShow={() => this.onOpenModal()}
      >
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title> {HeaderTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label> {t("name")} </label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={this.state.name}
              onChange={(event) => this.handleNameChange(event.target.value)}
            ></input>
          </div>
        </Modal.Body>
        <Modal.Footer>{BtnSubmit}</Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(TemplateAddModal);
