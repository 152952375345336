import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import Iframe from 'react-iframe'
import classes from './PdfPreviewModal.module.css'

class PdfPreviewModal extends Component {
    render() {

        return (
            <div>
                <Modal
                    onHide={this.props.onHide}
                    show={this.props.show}
                    dialogClassName={classes.modal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title className={classes.title}>
                            {this.props.title}
                        </Modal.Title>
                    </Modal.Header>


                    {
                        this.props.file && (
                            <Iframe
                                url={`https://admin.phenikaa.com/flipbook.html?source=${this.props.file}`}
                                onLoad="this.width=screen.width;"
                                display="initial"
                                position="relative"
                            />
                        )
                    }
                </Modal>
            </div>
        );
    }
}

export default PdfPreviewModal;
