import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"

import { withTranslation } from 'react-i18next';

class SitemapSubmitModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            sitemapPath: "",

            loadingModal: "",
            transPage: "modals.sitemap_submit.",
            transAlert: "alert."
        }

        this.submitSitemap = this.submitSitemap.bind(this)

        this.onOpenModal = this.onOpenModal.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)

    }

    onOpenModal() {

    }

    onCloseModal() {
        this.setState({
            sitemapPath: ""
        }, () => {
            this.props.onHide()
        })
    }

    handleContentChange(value) {
        this.setState({
            sitemapPath: value
        })
    }

    submitSitemap() {
        if(this.state.sitemapPath) {
            this.props.submitSitemap(this.state.sitemapPath)
                .then(this.onCloseModal)
        }
    }

    render() {
        const { t } = this.props;
        var HeaderExecute = t(this.state.transPage + 'submit_sitemap')
        var ButtonExecute = <Button variant="primary" onClick={() => this.submitSitemap()}>
            <i className="fa fa-plus">&nbsp;</i>
            {t(this.state.transPage + 'submit_sitemap')}
        </Button>

        return (
            <Modal show={this.props.show} onShow={this.onOpenModal} onHide={this.onCloseModal}>
                {this.state.loadingModal}
                <Modal.Header closeButton className="bg-primary">
                    <Modal.Title>{HeaderExecute}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="form-group">
                        <label> {t('sitemap_path')} </label>
                        <textarea
                            className="form-control"
                            onChange={(event) => this.handleContentChange(event.target.value)}
                            value={this.state.sitemapPath}
                        >
                        </textarea>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    {ButtonExecute}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(SitemapSubmitModal);