import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"
import { withTranslation } from 'react-i18next';

class ContactDeleteConfirm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            transPage: "modals.contacts_delete."
        }

        this.deleteSelectedContacts = this.deleteSelectedContacts.bind(this)
        this.onOpenModal = this.onOpenModal.bind(this)
    }

    deleteSelectedContacts() {
        this.props.deleteSelectedContacts()
    }

    onOpenModal() {

    }

    render() {
        const { t, selectedContacts } = this.props;

        return (
            <Modal show={this.props.show} onHide={() => this.props.onHide()} onShow={() => this.onOpenModal()}>
                <Modal.Header closeButton className="bg-danger">
                    <Modal.Title>{t(this.state.transPage + "title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure delete to delete these {selectedContacts.length} contacts?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.props.onHide()}>
                        {t('cancel')}
                    </Button>
                    <Button variant="danger" onClick={() => this.deleteSelectedContacts()}>
                        <i className="fa fa-trash">&nbsp;</i>
                        {t(this.state.transPage + "delete_contact")}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(ContactDeleteConfirm);