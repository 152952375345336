import React, { Component } from "react";
import { Button } from "react-bootstrap";
import SortableTree from "react-sortable-tree";
// import ReactJson from 'react-json-view'

import Card, { CardHeader, CardBody } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "../components/Content";
import TitlePages from "./Elements/TitlePages";

import "react-sortable-tree/style.css"; // This only needs to be imported once in your app

import MenuApi from "../api/MenuApi";
import { shortSentence } from "./../lib/StringHelper";
import MenuItemAdd from "../components/Modal/MenuItemAdd";
import MenuItemConfirm from "../components/Modal/MenuItemConfirm";
import MenuAddModal from "../components/Modal/MenuAddModal";
import swal from "sweetalert";
import { withTranslation } from "react-i18next";
import config from "../config/config";
import ReactGA from "react-ga";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faListUl } from "@fortawesome/free-solid-svg-icons";
import ValueContext from "../components/section/Context";

import {BrowserRouter as Router, Route} from 'react-router-dom';
import classes from "../pages/Elements/TitlePages.module.css";
import previous from '../components/Assets/previous.svg'


class MenuDetailPage extends Component {
 
  
  static contextType = ValueContext;
  constructor(props) {
    super(props);
    this.state = {
      value: true,
      menu: {
        id: 0,
        parent: null,
        name: "",

        showModalAdd: false,
        update: false,
      },
      showModalConfirm: false,
      treeData: [],
      showModal: false,

      treeDataTable: [],
      parent: null,
      transPage: "pages.menu_list_detail.",
      transAlert: "alert.",
    };

    this.getMenuDetail = this.getMenuDetail.bind(this);
    this.setShowModalAdd = this.setShowModalAdd.bind(this);
    this.setShowModalConfirm = this.setShowModalConfirm.bind(this);
    this.deleteMenuItem = this.deleteMenuItem.bind(this);
    this.showModal = this.showModal.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  } 

  showModal() {
    this.setState(
      {
        showModal: !this.state.showModal,
      },
      () => {
        if (!this.state.showModal) {
          this.setState({
            id: 0,
          });
        }
      }
    );
  }


  converFormat() {}

  componentDidMount() {
    ReactGA.initialize(config.GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.getMenuDetail();
  }

  getMenuDetail() {
    const menuObj = new MenuApi();
    const menuId = this.props.match.params.menuId;

    menuObj.getDetailMenu(menuId).then((res) => {
      this.setState({
        menu: res.data,
        treeData: res.data.items,
      });
    });
  }

  menuUpdate() {
    const menuObj = new MenuApi();
    const menuId = this.props.match.params.menuId;
    const menu = {
      items: this.state.treeData,
    };

    menuObj
      .menuUpdates(menuId, menu)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  }

  setShowModalAdd(addOrUpdate) {
    var updateBoolean;

    switch (addOrUpdate) {
      case "add":
        updateBoolean = false;
        break;
      case "update":
        updateBoolean = true;
        break;
      default:
        updateBoolean = false;
        break;
    }

    this.setState({
      showModalAdd: !this.state.showModalAdd,
      update: updateBoolean,
    });
  }

  setShowModalConfirm() {
    this.setState({
      showModalConfirm: !this.state.showModalConfirm,
    });
  }

  deleteMenuItem() {
    const { t } = this.props;
    const objMenuItem = new MenuApi();

    objMenuItem.menuItemDelete(this.state.id).then((res) => {
      swal(
        "Success !",
        t(this.state.transAlert + "menu_item_delete_success"),
        "success"
      );
      this.setShowModalConfirm();
      this.getMenuDetail();
    });
  }

  

  render() {
    const { t } = this.props;
   

    const contextValue = this.context;

    let icon = contextValue.showSidebar ? (
      <FontAwesomeIcon
        data-widget="pushmenu"
        onClick={() => contextValue.showingSidebar()}
        color="#fff"
        className={classes.iconList}
        icon={faEllipsisV}
        size="lg"
      />
    ) : (
      <FontAwesomeIcon
        data-widget="pushmenu"
        onClick={() => contextValue.showingSidebar()}
        color="#fff"
        className={classes.iconList}
        icon={faListUl}
        size="lg"
      />
    );

    return (
      <Content>
        <ContentHeader>
          <div className="" style={{ position: "relative" }}>
            <div className={classes.topContent}>
              {icon}
              <span>
                Menu : {this.state.menu.name}{" "}
                <i
                  className="menu-detail-edit-wrapper fa fa-edit"
                  onClick={() => {
                    this.setState(
                      {
                        id: `${this.state.menu.id}`,
                      },
                      () => this.showModal()
                    );
                  }}
                ></i>
              </span>
            </div>
            {/* {this.state.menu.name} */}
            {/* <TitlePages title={`${t(this.state.transPage + "title")} : ${this.state.menu.name}`}  /> */}

            {/* <div className=" menu-list-edit-wrapper">
                                <i className="fa fa-edit"
                                    onClick={() => {
                                        this.setState({
                                            id: `${this.state.menu.id}`
                                        }, () => this.showModal())
                                    }}
                                ></i>
                            </div> */}
          </div>
        </ContentHeader>
        <ContentBody>
          <Card>
            <CardHeader>
            <div className="back-button" style={{ padding: "0 5px" }}>
                  <Button
                    onClick={this.goBack}
                    className="btn btn-default col-md-1 mt-3 ml-auto"
                  >
                    <i className="fa fa-arrow-left">&nbsp;</i>
                    Back
                  </Button>
                </div>
              <div className="card-tools">
                <Button
                  onClick={() => {
                    this.setState(
                      {
                        id: 0,
                        parent: null,
                      },
                      () => {
                        this.setShowModalAdd("add");
                      }
                    );
                  }}
                >
                  <i className="fa fa-plus">&nbsp;</i>
                  {t(this.state.transPage + "add_menu_item")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <div style={{ height: 700 }}>
                <SortableTree
                  treeData={this.state.treeData}
                  onMoveNode={() => {
                    this.menuUpdate();
                  }}
                  onDragStateChanged={() => {}}
                  onChange={(treeData) => this.setState({ treeData })} // JANGAN DIHAPUS
                  generateNodeProps={({ node }) => ({
                    title: (
                      <div title={node.title}>
                        <span className="float-left">
                          {shortSentence(node.title, 15) + " ... "}
                        </span>
                        <div className="float-right">
                          <button
                            title={t(this.state.transPage + "add_menu_item")}
                            className="btn btn-success btn-xs"
                            style={{ margin: "0 5px" }}
                            onClick={() => {
                              this.setState(
                                {
                                  id: 0,
                                  parent: node.id,
                                },
                                () => {
                                  this.setShowModalAdd("add");
                                }
                              );
                            }}
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                          <button
                            title={t(this.state.transPage + "update_this_item")}
                            className="btn btn-primary btn-xs"
                            style={{ margin: "0 5px" }}
                            onClick={() => {
                              this.setState(
                                {
                                  id: node.id,
                                },
                                () => this.setShowModalAdd("update")
                              );
                            }}
                          >
                            <i className="fa fa-edit"></i>
                          </button>
                          <Button
                            title={t(this.state.transPage + "delete_this_item")}
                            variant="danger"
                            className="btn-xs"
                            style={{ margin: "0 5px" }}
                            onClick={() => {
                              this.setState(
                                {
                                  id: node.id,
                                },
                                () => this.setShowModalConfirm()
                              );
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </Button>
                        </div>
                      </div>
                    ),
                  })}
                />
              </div>
              <div className="clearfix"></div>
              <hr></hr>
            </CardBody>
          </Card>
        </ContentBody>
        <MenuItemAdd
          id={this.state.id}
          parent={this.state.parent}
          menuId={this.props.match.params.menuId}
          show={this.state.showModalAdd}
          update={this.state.update}
          onHide={this.setShowModalAdd}
          getMenuDetail={this.getMenuDetail}
          open={true}
        ></MenuItemAdd>
        <MenuItemConfirm
          id={this.state.id}
          show={this.state.showModalConfirm}
          onHide={this.setShowModalConfirm}
          deleteMenuItem={this.deleteMenuItem}
        ></MenuItemConfirm>
        <MenuAddModal
          id={this.state.id}
          show={this.state.showModal}
          loadingModal={this.state.loadingModal}
          onHide={this.showModal}
          // getAllMenu={this.getAllMenu}
        ></MenuAddModal>
      </Content>
    );
  }
}

export default withTranslation()(MenuDetailPage);
