import React from 'react'
import DataTable from 'react-data-table-component'
import Spinner from 'react-bootstrap/Spinner'

const customStyleDataTable = {
    table: {
        style: {
            backgroundColor: "transparent",
        }
    },
    header: {
        style: {
            display: "none"
        }
    },
    headRow: {
        style: {
            backgroundColor: "transparent",
        }
    },
    headCells: {
        style: {
            fontSize: "1rem",
            fontWeight: "bold",
        }
    },
    rows: {
        style: {
            backgroundColor: "transparent",
            fontSize: "1rem",
            boxShadow: "none",
            transition: "box-shadow 150ms",
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: "rgba(0,0,0,.12)",
            '&:hover': {
                cursor: 'pointer',
                boxShadow: '0px 3px 6px #00000029'
            },
            '&:last-of-type': {
                marginBottom: "1rem"
            }
        }
    },
    pagination: {
        style: {
            backgroundColor: "transparent",
            border: "none"
        }
    },
    progress: {
        style: {
            backgroundColor: "transparent"
        }
    }
}

function _DataTable(props) {
    return <DataTable
        customStyles={customStyleDataTable}
        progressComponent={<Spinner animation="border" />}
        {...props}
    />
}

export default _DataTable