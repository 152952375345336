import React from 'react';
import classes from './MultipleDropdownButton.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import titleConverter from 'to-title'

function MultipleDropdownButton({ className, title, placeholder, values, contents, selectedValue, toTitle, onChange, style }) {
    className = className ? className : ""
    values = values ? values : []
    contents = contents ? contents : values;
    selectedValue = selectedValue ? selectedValue : []
    selectedValue = new Set([...selectedValue])

    const dropdownItems = values.map((e, i) => {
        const isSelected = selectedValue.has(e);
        const dropdownClass = isSelected ? "dropdown-item active" : "dropdown-item";
        let content = contents[i]
        content = toTitle ? titleConverter(content) : content;
        // content = toTitle ? content : content;

        return (
            <div 
                key={e+i}
                className={[dropdownClass, classes.item].join(" ")}
                onClick={onChange}
                data-value={e}
            >
                {content}
            </div>
        )
    })

    const textContent = selectedValue.size ? `${selectedValue.size} of ${values.length} selected` : placeholder

    return (
        <div className={className} style={style}>
            {/* <h4 className="titleFilter">{title}</h4> */}
            <div className={["dropdown"].join(" ")}>
                <button
                    className={["btn form-control", classes.boxBtn].join(" ")}
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <span>{textContent}</span>
                    <FontAwesomeIcon className={classes.icon} icon={faChevronDown} />
                </button>
                <div
                    className={["dropdown-menu", classes.dropdownMenu].join(" ")}
                    aria-labelledby="dropdownMenuButton"
                >
                    {dropdownItems}
                </div>
            </div>
        </div>
    );
}

export default MultipleDropdownButton;