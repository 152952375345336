import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import ArticleApi from '../../api/ArticleApi';
import WidgetApi from '../../api/WidgetApi';

import { shortSentence } from '../../lib/StringHelper';

import './ArticleElement.css';

class ArticlesElement extends Component {
	constructor(props) {
		super(props);

		this.state = {
			articles: [],
			selectedArticleId: {},
			count: '',
		};

		this.getWidgetDetail = this.getWidgetDetail.bind(this);
		this.onSortEnd = this.onSortEnd.bind(this);
		this.shouldCancelStart = this.shouldCancelStart.bind(this);
		this.checkSelectedArticle = this.checkSelectedArticle.bind(this);
		this.handleCheckedArticle = this.handleCheckedArticle.bind(this);
	}

	componentDidMount() {
		this.getWidgetDetail(this.props.widgetId);
	}

	componentWillReceiveProps(newProps) {
		// JANGAN DI UNCOMMENT INI
		// this.getWidgetDetail(newProps.widgetId)

		var res = {};
		for (var i = 0; i < newProps.selectedArticles.length; i++) {
			res[newProps.selectedArticles[i].id] = true;
		}

		this.setState({
			selectedArticleId: res,
		});
	}

	getWidgetDetail(neoWidgetId) {
		const objWidget = new WidgetApi();
		const widgetId = neoWidgetId;

		const params = {
			section: this.props.section,
		};

		objWidget
			.getDetailWidget(widgetId, params)
			.then((res) => {
				if (res.data.widget_articles) {
					this.setState({
						articles: res.data.widget_articles,
						count: res.data.widget_articles.length,
					});
				}
			})
			.catch((err) => {
				console.log(err.message);
			});
	}

	onSortEnd({ oldIndex, newIndex }) {
		const objArticle = new ArticleApi();

		const widgetId = this.props.widgetId;

		this.setState(
			({ articles }) => ({
				articles: arrayMove(articles, oldIndex, newIndex),
			}),
			() => {
				const ids = this.state.articles.map((item) => {
					return item.id;
				});

				objArticle
					.widgetArticleIndexUpdate(widgetId, ids)
					.then((res) => {
						this.getWidgetDetail(widgetId);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		);
	}

	shouldCancelStart(e) {
		// Cancel sorting if the event target is an anchor tag (`a`)
		if (e.target.tagName.toLowerCase() === 'a') {
			return true; // Return true to cancel sorting
		}
	}

	checkSelectedArticle(value) {
		return this.state.selectedArticles.findIndex((item) => item.id === value.id) > 0;
	}

	handleCheckedArticle(value) {
		var checked = this.props.selectedArticles.find((element) => {
			return element.id === value.id;
		});

		// because checked from checkbox doesnt work
		if (typeof checked === 'undefined') {
			checked = true;
		} else {
			checked = false;
		}

		this.props.setSelectedArticle('article', value, checked);
	}

	render() {
		const SortableItem = sortableElement((item) => {
			const value = item.value;

			if (this.props.articles) {
				const findArticle = this.props.articles.find((row) => row.id === value.id);

				var scontent = findArticle?.article.article_language ? findArticle.article.article_language[1].content : '';
				var stitle = findArticle?.article ? findArticle.article.name : '';

				var shortContent = shortSentence(scontent, 10);
				var shortTitle = shortSentence(stitle, 5);

				var headBackGround = 'bg-primary';

				return (
					<div className='col-md-2'>
						<Card>
							<Card.Header style={{ color: 'black' }} className={headBackGround} onClick={() => {}}>
								<div className='form-check form-check-inline'>
									<input
										onClick={(event) => this.handleCheckedArticle(value)}
										type='checkbox'
										value=''
										className='form-check-input'
										id={'label' + value.id}
										// selectedArticleId[value.id]
										defaultChecked={this.state.selectedArticleId[value.id]}
									></input>
									&nbsp;
									<label title={shortTitle} className='form-check-label' htmlFor={'label' + value.id}>
										<b>
											{value.index}. <span>{shortTitle}</span>
										</b>
									</label>
								</div>
							</Card.Header>
							<a href={`/article/detail/${value.article.id}/${this.props.widgetId}`}>
								<Card.Body
									style={{
										color: 'black',
										overflow: 'hidden',
									}}
								>
									<Card.Title>
										<p> {shortTitle} ...</p>
										<hr></hr>
									</Card.Title>
									<Card.Text
										style={{
											overflow: 'hidden',
											height: 160,
										}}
									>
										<span
											style={{ fontSize: 12 }}
											dangerouslySetInnerHTML={{
												__html: shortContent,
											}}
										></span>
									</Card.Text>
									<Card.Text style={{ fontSize: 12 }}>
										<b>{findArticle.article.status}</b>
									</Card.Text>
								</Card.Body>
							</a>
						</Card>
					</div>
				);
			}
		});

		const SortableContainer = sortableContainer(({ children }) => {
			return <ul className='row'>{children}</ul>;
		});

		const { articles, count } = this.state;

		return (
			<div style={{ marginLeft: '-2%' }}>
				<SortableContainer axis='xy' onSortEnd={this.onSortEnd} distance={1} shouldCancelStart={(e) => this.shouldCancelStart(e)}>
					{count !== 0 ? (
						articles.map((value, index) => <SortableItem key={`item-${value.name}`} index={index} value={value} />)
					) : (
						<div className={['col-md-12 center-block', 'justify-content-center', 'd-flex'].join(' ')}>
							<span className='text-center'>No data widget</span>
						</div>
					)}
				</SortableContainer>
			</div>
		);
	}
}

export default ArticlesElement;
