import React, { Component } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { withTranslation } from "react-i18next";
import Card, { CardHeader } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content";
import TitlePages from "./Elements/TitlePages";
import LocationApi from "../api/LocationApi";
// import ModalLocation from "../components/Modal/LocationModal";
import LocationDelete from "../components/Modal/LocationDelete";
import "./LocationPage.css";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import Context from "./Context";
// import { InputGroup } from "react-bootstrap"
import CompanyAddModal from "../pages/Job/CompanyAddModal";
import DeleteCompanyConfirm from "../pages/Job/DeleteCompanyConfirm";

class LocationPage extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);

    this.state = {
      activeClient: "",
      transPage: "pages.location.",
      transAlert: "alert.",
      allLocation: [],
      isEnglish: false,
      langLoc: [],
      mapsLib: ["drawing", "places"],
      modalAddOpen: false,
      modalDeleteOpen: false,
      ID: 0,
      company_name: "",
      search: "",
      totalCount: null,
      activePage: 1,
      limitPageData: 10,
      selectedCompany: "",
      companies: [],
      selectedCompanies: [],
      gmapsApiKey: "AIzaSyCKqMs4hmNgoQtmIUsd7diBKHj6UV610Jg",
    };
    this.getAllLocation = this.getAllLocation.bind(this);
    this.deleteLocation = this.deleteLocation.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.autocomplete = null;
    this.timeouts = {};
  }

  componentDidMount() {
    const { activeClient } = this.context;
    this.setState({ activeClient: activeClient });
  }

  componentDidUpdate() {
    const { activeClient } = this.context;
    const lang = localStorage.getItem("lang");
    var isEnglish = false;

    if (lang === "en") {
      isEnglish = true;
    }
    if (isEnglish !== this.state.isEnglish) {
      this.setState({
        isEnglish: isEnglish,
      });
      this.getAllLocation();
    }

    if (activeClient !== this.state.activeClient) {
      this.setState(
        {
          activeClient: activeClient,
          activePage: 1,
          search: "",
        },
        this.getAllLocation
      );
    }
  }

  onLoad(autocomplete) {
    this.autocomplete = autocomplete;
  }

  handlePageChange(pageNumber) {
    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.getAllLocation();
      }
    );
  }

  getAllLocation() {
    const api = new LocationApi();
    const { activeClient } = this.context;

    api
      .getAllLocations({
        page_size: this.state.limitPageData,
        page: this.state.activePage,
        search: this.state.search,
        client: activeClient,
      })
      .then((res) => {
        this.setState({
          totalCount: res.data.count,
          allLocation: res.data.results,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  deleteLocation() {
    const { t } = this.props;
    const api = new LocationApi();
    api
      .deleteLocation(this.state.ID)
      .then((res) => {
        swal("Success", t("alert.location_delete_success"), "success");
        this.getAllLocation();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSearch(val) {
    clearTimeout(this.timeouts.search);
    this.setState({ search: val, activePage: 1 }, () => {
      this.timeouts.search = setTimeout(() => {
        this.getAllLocation();
      }, 350);
    });
  }

  handleChangeCompany(value) {
    this.setState(
      {
        selectedCompany: value,
        selectedCompanies: this.state.companies.filter(
          (company) => company.id === parseInt(value)
        ),
      },
      this.getAllLocation
    );
  }

  render() {
    const { t } = this.props;
    const containerStyle = {
      width: "100%",
      height: "100%",
    };

    let cards = this.state.allLocation.map((el) => {
      // const comp_name = el.company_name !== null ? el.company_name : "-";
      let locs = el.name;
      let address = el.address;

      const center = {
        lat: parseFloat(el.latitude),
        lng: parseFloat(el.longitude),
      };

      const langId = parseInt(localStorage.getItem("langId"));
      el.location_languages.forEach((locationLanguage) => {
        if (locationLanguage.id === langId) {
          locs = locationLanguage.name;
          address = locationLanguage.address;
        }
      });

      return (
        <div className="col-md-6" key={el.id}>
          <Card>
            <div className="form-group" style={{ margin: "0" }}>
              <div className="d-flex flex-row">
              <div
                  className="col-md-4"
                  style={{
                    padding: "unset",
                    minHeight: "15vh",
                    // height: "15vh",
                    height: '75%',
                    position: 'absolute',
                    zIndex: '100',
                    marginTop: '3rem'
                  }}
                >
                  {/* <MapContainer center={center} zoom={13} scrollWheelZoom={true}
                    style={{ height: '100%', width: '100%' }}>
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker
                      position={center}
                    >
                      <Popup>
                        Your Location
                      </Popup>
                    </Marker>
                  </MapContainer> */}

                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={15}
                    mapTypeId="roadmap"
                    onLoad={this.onLoad}
                  >
                    <Marker position={center} />
                  </GoogleMap>
                </div>
               
                <div className="location-trash">
                      <i
                        onClick={() => {
                          this.setState({
                            modalDeleteOpen: !this.state.modalDeleteOpen,
                            ID: el.id,
                            company_name: el.name ? el.name : "",
                          });
                        }}
                        class="fa fa-trash"
                        aria-hidden="true"
                        style={{
                          width: "100%",
                          justifyContent: "flex-end",
                          display: "flex",
                          cursor: "pointer",
                          color: "#fff",
                          position: 'absolute'
                        }}
                      ></i>
                    </div>
                <Link style={{ width: "100%" }} to={`location/detail/${el.id}`}>
                  <div className="card-header  bg-primary">
                    <div className="">
                      {" "}
                      <p className="c">{el.name ? el.name : ""}</p>
                    </div>
                    
                  </div>
                  <div className="d-flex flex-row">
                  <div
                  className="col-md-4"
                  style={{
                    padding: "unset",
                    minHeight: "15vh",
                    height: "auto",
                  }}
                >
                  {/* <MapContainer center={center} zoom={13} scrollWheelZoom={true}
                    style={{ height: '100%', width: '100%' }}>
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker
                      position={center}
                    >
                      <Popup>
                        Your Location
                      </Popup>
                    </Marker>
                  </MapContainer> */}

                 
                </div>
                  <div
                    className="col-8"
                    style={{ margin: "1rem 0", padding: "0px 1rem 0px 2rem" }}
                  >
                    
                    <p className="link">{locs}</p>
                    <p className="add">{address.slice(0, 55)}...</p>

                    <div className="column">
                      <div className="row-web">
                        <p className="p">Phone : {el.phone ? el.phone : ""}</p>
                      </div>
                      <div className="row-web">
                        <p className="link">
                          <p className="p2">Email : &nbsp;</p>
                          {el.email ? el.email : ""}
                        </p>
                      </div>
                    </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Card>
        </div>
      );
    });

    return (
      <Content>
        <LoadScript
          googleMapsApiKey={this.state.gmapsApiKey}
          libraries={this.state.mapsLib}
        >
          <LocationDelete
            show={this.state.modalDeleteOpen}
            onHide={() => {
              this.setState({ modalDeleteOpen: false });
            }}
            locationID={this.state.ID}
            positionId={this.state.ID}
            names={this.state.company_name}
            delete={this.deleteLocation}
          />
          <ContentHeader>
            <TitlePages title={t(this.state.transPage + "title")} />
          </ContentHeader>

          <ContentBody>
            <Card>
              <CardHeader>
                <div className="">
                  <div className="row">
                    <div className="form-group" style={{ width: "30%" }}>
                      <label className="d-flex">{t("search")}</label>
                      <input
                        type="text"
                        placeholder={t(this.state.transPage + "search")}
                        className="form-control"
                        name="search"
                        value={this.state.search}
                        onChange={(event) =>
                          this.handleSearch(event.target.value)
                        }
                      />
                    </div>
                    {/* <label>{t(this.state.transPage + "location_list")}</label> */}
                    <div className="d-flex flex-row btn-loc-add ml-auto">
                      <Link
                        to={`location/add?company=${this.state.selectedCompany}`}
                      >
                        {" "}
                        <div style={{ cursor: "pointer" }}>
                          {t(this.state.transPage + "add_location")}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex flex-row"
                  width="100%"
                  style={{ padding: "0 2rem" }}
                ></div>
                {/* <div style={{ padding: "0 2rem" }}>
              {" "}
              <hr />
            </div> */}
              </CardHeader>
              <div className="clearfix" style={{ marginBottom: "3rem" }}></div>
              <div className="row" style={{ padding: "0 2rem" }}>
                {this.state.totalCount !== 0 ? (
                  cards
                ) : (
                  <div className="col-md-12 center-block">
                    <h4 className="text-center">There is no Location</h4>
                  </div>
                )}
              </div>
              <div className="d-flex flex-row">
                <div
                  style={{ float: "left", padding: "0 2rem", margin: "2rem 0" }}
                  id="pagination_custom"
                >
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.limitPageData}
                    totalItemsCount={this.state.totalCount}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    hideDisabled={true}
                    prevPageText={t("prev")}
                    nextPageText={t("next")}
                  />
                </div>
              </div>
            </Card>
          </ContentBody>
          <CompanyAddModal
            companyId={this.state.companyId}
            show={this.state.showCompany}
            onHide={this.setShowCompany}
            callback={this.getCompanies}
          ></CompanyAddModal>
          <DeleteCompanyConfirm
            companyId={this.state.companyId}
            selectedCompanies={this.state.selectedCompanies}
            onHide={this.setShowCompanyDelete}
            show={this.state.showCompanyDelete}
            deleteSelectedCompany={this.deleteSelectedCompany}
          ></DeleteCompanyConfirm>
        </LoadScript>
      </Content>
    );
  }
}

export default withTranslation()(LocationPage);
