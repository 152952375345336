import React, { useState, useMemo, useEffect } from "react";
import { Card, ButtonGroup, ToggleButton } from "react-bootstrap";
import toTitle from "to-title"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    ArcElement,
    Legend
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    Tooltip,
    Legend,
    ChartDataLabels,
);

function colorGenerator(length) {
    let colors = []
    const max = 255

    for (let i = 0; i < length; i++) {
        colors.push([
            `rgba(${Math.floor(Math.random() * max)}, ${Math.floor(Math.random() * max)}, ${Math.floor(Math.random() * max)}, `
        ])
    }

    return function (opacity) {
        return colors.map(color => color + opacity + ")")
    }
}


function CardChart(props) {
    const [typeChart, setTypeChart] = useState("")
    const [isShowingValue, setIsShowingValue] = useState(false)
    const charts = (props.typeChart && props.typeChart.length) || ["pie", "bar"]
    const colors = useMemo(() => colorGenerator(props.data?.length), [props.data?.length])

    useEffect(() => {
        if (charts) {
            setTypeChart(charts[0])
        }
    }, [])

    useEffect(() => {
        if ((props.typeChart && props.typeChart.length)) {
            setTypeChart(props.typeChart[0])
        }
    }, [props.typeChart])


    return (
        <Card className={['text-center', props.className].join(' ')}>
            <Card.Header>{props.title}</Card.Header>
            <Card.Body>
                {
                    charts.length > 1 &&
                    <ButtonGroup toggle className="mb-3">
                        {charts.map((chart, idx) => (
                            <ToggleButton
                                key={idx}
                                id={`chart-${idx}`}
                                type="radio"
                                variant='outline-primary'
                                name="membership-chart"
                                value={chart}
                                checked={typeChart === chart}
                                onChange={(e) => setTypeChart(e.currentTarget.value)}
                            >
                                {toTitle(chart)}
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                }

                <div className="d-flex align-items-center">
                    <span>Show Value</span>
                    <label className="switchCk">
                        {/* terbalik */}
                        <input type="checkbox" checked={!isShowingValue} onChange={(e) => { setIsShowingValue(!isShowingValue) }} />
                        <div className="sliderCk round"></div>
                    </label>
                </div>


                {
                    typeChart === "pie" &&
                    <Pie
                        style={props.chartStyle?.pie || { maxHeight: "25vw" }}
                        options={{
                            plugins: {
                                datalabels: {
                                    display: isShowingValue,
                                    color: "black",
                                    formatter: function (value) {
                                        return value ? value : " "
                                    },
                                    font: {
                                        size: "16px"
                                    },
                                    anchor: 'end',
                                    align: 'start',
                                },
                                legend: {
                                    position: 'left',
                                    labels: {
                                        boxWidth: 20
                                    },
                                    fullSize: true
                                }
                            }
                        }}
                        data={{
                            labels: props.labels,
                            datasets: [
                                {
                                    data: props.data,
                                    backgroundColor: colors(0.2),
                                    borderColor: colors(1),
                                    borderWidth: 1
                                }
                            ]
                        }}
                    />
                }

                {
                    typeChart === "bar" &&
                    <Bar
                        style={props.chartStyle?.bar}
                        options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    display: false
                                },
                                datalabels: {
                                    display: isShowingValue,
                                    color: "black",
                                    font: {
                                        size: "16px"
                                    }
                                }
                            },
                            scale: {
                                ticks: {
                                    precision: 0
                                }
                            }
                        }}
                        data={{
                            labels: props.labels,
                            datasets: [
                                {
                                    data: props.data,
                                    backgroundColor: props.data.length > 4
                                        ? ['rgba(255, 99, 132, 0.2)']
                                        : colors(0.2),
                                    borderColor: props.data.length > 4
                                        ? ['rgba(255, 99, 132, 1)']
                                        : colors(1),
                                    borderWidth: 1
                                }
                            ]
                        }}
                    />
                }
            </Card.Body>
        </Card>
    );
}

export default CardChart;