import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from "./../../lib/Common";

// handle the private routes
function PrivateRoute({ component: Component, ...rest }) {
  if (getToken()) {
    return (
      <Route
        {...rest}
        component={Component}
      />
    )
  } else {
    return (
      <Redirect to={{ pathname: '/dashboard' }}></Redirect>
    )
  }
}

export default PrivateRoute;