import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import swal from "sweetalert";
import _ from 'lodash'
import { LoadScript } from "@react-google-maps/api";
import { getBlankImageSrc } from '../lib/Common';

// Components
import Select from "react-dropdown-select"
import { Content, ContentHeader, ContentBody } from "./../components/Content"
import Card, { CardBody, CardHeader } from "../components/Card";
import CustomerNoteAddModal from '../components/Modal/CustomerNoteAddModal';
import LocationDetail from '../components/LocationDetail/LocationDetail';
import ContactSelect, { ContactSelectButton } from '../components/ContactSelect/ContactSelect';
import TitlePages from './Elements/TitlePages'
import LocationPair from '../components/Modal/LocationPair';
import CrmLocationAddModal from '../components/Modal/CrmLocationAddModal';
import AlertHelper from "../lib/AlertHelper";

// Context
import Context from "./Context"

// API
import CRMApi from '../api/CRMApi';
import LocationApi from '../api/LocationApi'

// CSS
import css from './CustomerDetailPage.module.css'
import HistoryModal from '../components/Modal/HistoryModal';
import HistoryChangeModal from '../components/Modal/HistoryChangeModal';

class CustomerDetailPage extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            mode: "",
            attributeCategories: [],
            attributeOptions: {},
            attributeValues: [],
            attributes: [],
            countries: [],

            // fixed fields
            fixedFieldsData: {},

            // locations
            gmapsApiKey: 'AIzaSyCKqMs4hmNgoQtmIUsd7diBKHj6UV610Jg',
            mapsLib: ["drawing", "places"],
            locations: [],
            isShowLocationPairModal: false,
            selectedLocations: [],
            selectedMainLocation: "",
            isShowCrmLocationModal: false,

            // documents
            checkedDocuments: {},

            // contacts
            contacts: [],
            assignedContacts: [],

            // history
            histories: [],
            pageHistory: 1,
            isShowHistoryModal: false,
            isShowHistoryChangeModal: false,
            historyData: {},

            // notes
            notes: [],
            isShowCustomerNoteModal: false,
            noteId: 0,
        }

        this.getAttributeCategories = this.getAttributeCategories.bind(this)
        this.getAllAttributeOptions = this.getAllAttributeOptions.bind(this)
        this.getMemberDetail = this.getMemberDetail.bind(this)
        this.getAllNotes = this.getAllNotes.bind(this)
        this.getAllLocations = this.getAllLocations.bind(this)
        this.getSelectedLocations = this.getSelectedLocations.bind(this)
        this.getAllContacts = this.getAllContacts.bind(this)
        this.getAllAssignedContacts = this.getAllAssignedContacts.bind(this)
        this.getAllCountries = this.getAllCountries.bind(this)
        this.getAllHistories = this.getAllHistories.bind(this)
        this.toggleCustomerNoteModal = this.toggleCustomerNoteModal.bind(this)
        this.handleChangeValue = this.handleChangeValue.bind(this)
        this.handleFileUpload = this.handleFileUpload.bind(this)
        this.handleSelectedContact = this.handleSelectedContact.bind(this)
        this.handleAssignedContact = this.handleAssignedContact.bind(this)
        this.handleChangeLogo = this.handleChangeLogo.bind(this)
        this.handleRemoveLogo = this.handleRemoveLogo.bind(this)
        this.handlePageSetState = this.handlePageSetState.bind(this)
        this.deleteDocuments = this.deleteDocuments.bind(this)
        this.validationSubmit = this.validationSubmit.bind(this)
        this.submitLocations = this.submitLocations.bind(this)
        this.submitData = this.submitData.bind(this)
    }

    componentDidMount() {
        if (this.props.match.path === "/customers/add") {
            this.setState({ mode: "add" })
        } else if (this.props.match.path === "/customers/detail/:customerId") {
            this.setState({ mode: "edit" })
            this.getMemberDetail(this.props.match.params?.customerId)
            this.getAllNotes()
            this.getAllHistories()
            this.getAllLocations()
        }
        this.getAttributeCategories()
        this.getAllContacts()
        this.getAllAssignedContacts()
        this.getAllCountries()
    }

    getAttributeCategories() {
        const crmApi = new CRMApi();
        const { activeClient } = this.context;

        const params = {
            max_size: true,
            client: activeClient
        }

        crmApi
            .getAttributeCategories(params)
            .then(res => {
                this.setState({ attributeCategories: res.data.results }, () => {
                    let attrThatHasOptions = []
                    this.state.attributeCategories.forEach(category => {
                        category.attributes.forEach(attr => {
                            if (attr.value_type === "Option") {
                                attrThatHasOptions.push(attr)
                            }
                        })
                    })

                    this.getAllAttributeOptions(attrThatHasOptions)
                })
            })
    }

    getAllAttributeOptions(attribute) {
        const crmApi = new CRMApi();
        attribute.forEach(attr => {
            let customParams = {}
            if (attr.name === "Membership type") {
                customParams.ordering = undefined
            }

            crmApi
                .getAttributeOptions(attr.id, customParams)
                .then(res => {
                    const newAttributeOptions = {
                        ...this.state.attributeOptions,
                        [attr.id]: res.data.results
                    }
                    this.setState({ attributeOptions: newAttributeOptions })
                })
        })
    }

    getMemberDetail(id) {
        const crmApi = new CRMApi();
        let attributeValues = []

        crmApi
            .getMemberDetail(id)
            .then(res => {
                res.data.attribute_categories.forEach((category, idxCategory) => {
                    let attrs = []
                    category.attributes.forEach((attribute, idxAttribute) => {
                        attrs.push(attribute.member_attribute?.attribute_values)
                    })
                    attributeValues.push(attrs)
                })
                this.setState({
                    fixedFieldsData: { logoPreview: res.data.logo, name: res.data.name, phone: res.data.phone, countries: res.data.countries, member_id: res.data.member_id },
                    attributeValues: attributeValues,
                    attributeCategories: res.data.attribute_categories
                })
            })
    }

    getAllNotes() {
        const crmApi = new CRMApi();
        const memberId = this.props.match.params?.customerId

        crmApi
            .getAllNotesByMemberId(memberId)
            .then(res => {
                this.setState({ notes: res.data.results })
            })
    }

    async getAllHistories() {
        const crmApi = new CRMApi();
        const memberId = this.props.match.params?.customerId
        const nameAttributes = ["Date joined", "Membership type", "Membership status"]

        const resAttributes = await crmApi.getAllAttributes({ max_size: true })
        let objectAttributes = {}
        let attributes = []
        resAttributes.data?.results.forEach(attribute => {
            objectAttributes[attribute.id] = attribute
            const nameAttributesIdx = nameAttributes.indexOf(attribute.name)
            if (nameAttributesIdx > -1) {
                attributes[nameAttributesIdx] = attribute
            }
        })

        let histories = []

        await attributes.reduce(async (promise, attribute) => {
            await promise
            const res = await crmApi.getAllHistoriesByMemberId(memberId, { max_size: true, ordering: "-id", attribute: attribute.id })
            histories.push(res.data.results)
        }, Promise.resolve())

        this.setState({ histories: histories, attributes: objectAttributes })
    }

    getAllLocations() {
        const locationApi = new LocationApi()
        const memberId = this.props.match.params?.customerId
        const { activeClient } = this.context;

        locationApi.getAllLocations({ member: memberId, client: activeClient })
            .then(res => {
                this.setState({ locations: res.data.results })
            })
    }

    getAllCountries() {
        const locationApi = new LocationApi()

        locationApi
            .getAllCountries({ max_size: true })
            .then(res => {
                this.setState({ countries: res.data.results })
            })
    }

    getSelectedLocations() {
        const locationApi = new LocationApi()
        const { activeClient } = this.context;

        locationApi.getAllLocations({ client: activeClient })
            .then(res => {
                const locations = res.data.results
                    .filter(location => this.state.selectedLocations.includes(location.id))
                    .map(location => {
                        return {
                            ...location,
                            is_main: location.id === this.state.selectedMainLocation
                        }
                    })
                this.setState({ locations: locations })
            })
    }

    getAllContacts() {
        const crmApi = new CRMApi()
        const memberId = this.props.match.params?.customerId

        crmApi.getAllContacts({ member: memberId })
            .then(res => {
                this.setState({ contacts: res.data.results })
            })
    }

    getAllAssignedContacts() {
        const crmApi = new CRMApi()
        const memberId = this.props.match.params?.customerId
        const { activeClient } = this.context
        const clientId = activeClient

        crmApi.getAllAssignedContacts({ member: memberId, client: clientId })
            .then(res => {
                this.setState({ assignedContacts: res.data.results })
            })
    }

    toggleCustomerNoteModal(value, customState) {
        this.setState({
            isShowCustomerNoteModal: value ? value : !this.state.isShowCustomerNoteModal,
            ...customState
        })
    }

    handleChangeValue(value, inputType, idxCategory, idxAttribute, isMultiple) {
        let newAttributeValues = _.cloneDeep(this.state.attributeValues)

        if (!newAttributeValues[idxCategory]) {
            newAttributeValues[idxCategory] = []
        }

        if (!newAttributeValues[idxCategory][idxAttribute]) {
            newAttributeValues[idxCategory][idxAttribute] = [{ option_value: null, string_value: null, date_value: null, file_value: null }]
        }

        if (inputType === "String") {
            newAttributeValues[idxCategory][idxAttribute][0].string_value = value
        } else if (inputType === "Option") {
            const values = value
            if (isMultiple) {
                const valuesId = values.map(value => value.value)
                let valuesIdSet = new Set(valuesId)
                const existingValues = newAttributeValues[idxCategory][idxAttribute]
                    .filter(attributeValue => {
                        const optionId = attributeValue?.option_value?.id
                        if (valuesIdSet.has(optionId)) {
                            valuesIdSet.delete(optionId)
                            return true
                        }
                        return false
                    })

                newAttributeValues[idxCategory][idxAttribute] = existingValues;

                values.forEach(val => {
                    if (valuesIdSet.has(val.value)) {
                        newAttributeValues[idxCategory][idxAttribute].push({
                            option_value: {
                                id: val.value,
                                name: val.label
                            }
                        })
                    }
                })
            } else {
                newAttributeValues[idxCategory][idxAttribute][0].option_value = {}
                newAttributeValues[idxCategory][idxAttribute][0].option_value.id = value
            }
        } else if (inputType === "Date") {
            newAttributeValues[idxCategory][idxAttribute][0].date_value = value
        }

        this.setState({ attributeValues: newAttributeValues })
    }

    handleFileUpload(event, idxCategory, idxAttr) {
        let newAttributeValues = _.cloneDeep(this.state.attributeValues)

        if (!newAttributeValues[idxCategory]) {
            newAttributeValues[idxCategory] = []
        }

        for (let i = 0; i < event.target.files.length; i++) {
            if (!newAttributeValues[idxCategory][idxAttr]) {
                newAttributeValues[idxCategory][idxAttr] = []
            }
            newAttributeValues[idxCategory][idxAttr].push({ id: "", file_value: event.target.files[i] })
        }

        this.setState({ attributeValues: newAttributeValues })
    }

    handleCheckedDocuments(event, id, idxAttr) {
        const isChecked = event.target.checked
        let newCheckedDocuments = _.cloneDeep(this.state.checkedDocuments)

        if (!newCheckedDocuments[idxAttr]) {
            newCheckedDocuments[idxAttr] = []
        }

        let setCheckedDocuments = new Set(newCheckedDocuments[idxAttr])

        if (isChecked) {
            setCheckedDocuments.add(id)
        } else {
            setCheckedDocuments.delete(id)
        }

        newCheckedDocuments[idxAttr] = [...setCheckedDocuments]

        this.setState({ checkedDocuments: newCheckedDocuments })
    }

    handleSelectedContact(contactId, idx) {
        let newAssignedContacts = _.cloneDeep(this.state.assignedContacts)
        let selectedContact = this.state.contacts.filter(contact => contact.id === parseInt(contactId))

        if (selectedContact.length) {
            selectedContact = selectedContact[0]
            newAssignedContacts[idx].contact = selectedContact
        } else {
            newAssignedContacts[idx].contact = null
        }

        this.setState({ assignedContacts: newAssignedContacts })
    }

    handleAssignedContact(contactTitleId) {
        return (data, idx) => {
            let newAssignedContacts = _.cloneDeep(this.state.assignedContacts)
            data.contact_title = contactTitleId
            newAssignedContacts[idx].contact = data

            this.setState({ assignedContacts: newAssignedContacts })
        }
    }

    handleChangeFixedFieldsValue(value, stateName) {
        let newFixedFieldsData = _.cloneDeep(this.state.fixedFieldsData)

        if (stateName === "countries") {
            value = value.map(val => ({ id: val.value, name: val.label }))
        }

        newFixedFieldsData[stateName] = value;

        this.setState({ fixedFieldsData: newFixedFieldsData })
    }

    async handleChangeLogo(e) {
        const file = e.target.files?.[0]
        if (file) {
            const imageSrc = URL.createObjectURL(file)

            const updatedLogo = [
                {
                    stateName: "logo",
                    data: file
                },
                {
                    stateName: "logoPreview",
                    data: imageSrc
                }
            ]
            await updatedLogo.reduce(async (promise, logo) => {
                await promise
                await this.handleChangeFixedFieldsValue(logo.data, logo.stateName)
            }, Promise.resolve())
        }
    }

    async handleRemoveLogo() {
        let newFixedFieldsData = _.cloneDeep(this.state.fixedFieldsData)

        delete newFixedFieldsData.logo
        delete newFixedFieldsData.logoPreview

        this.setState({ fixedFieldsData: newFixedFieldsData })
    }

    handlePageSetState(state) {
        this.setState(state)
    }

    deleteDocuments(idxCategory, idxAttr) {
        let newAttributeValues = _.cloneDeep(this.state.attributeValues)
        const checkedFile = this.state.checkedDocuments[idxAttr]
        const remainFiles = newAttributeValues[idxCategory][idxAttr].filter(item => !checkedFile.includes(item.id))

        newAttributeValues[idxCategory][idxAttr] = remainFiles

        this.setState({ attributeValues: newAttributeValues }, () => {
            const checkedFile = document.getElementsByName(`uploaded-documents-${idxAttr}`)
            for (let i = 0; i < checkedFile.length; i++) {
                checkedFile[i].checked = false
            }
        })
    }

    isDisabledDeleteDocuments(idxAttr) {
        const checkedFile = this.state.checkedDocuments[idxAttr]

        return !checkedFile?.length
    }

    validationSubmit() {
        const { fixedFieldsData, attributeCategories, attributeValues } = this.state
        let isError = false

        if (!fixedFieldsData.name || !fixedFieldsData.countries?.length) {
            isError = true
        }

        try {
            attributeCategories.forEach((attributeCategory, idxCategory) => {
                attributeCategory.attributes.forEach((attr, idxAttr) => {
                    if (attr.is_required) {
                        const value = attributeValues[idxCategory][idxAttr]
                        if (attr.value_type === "String") {
                            if (!value[0].string_value) {
                                isError = true
                            }
                        } else if (attr.value_type === "Option") {
                            if (!value.length || !value[0].option_value.id) {
                                isError = true
                            }
                        }
                    }
                })
            })
        } catch (e) {
            isError = true
        }

        if (isError) {
            swal(
                "Error !",
                "The required fields (*) cannot be blank.",
                "error"
            )
        } else {
            this.submitData()
        }
    }

    submitLocations(memberId) {
        const locationApi = new LocationApi()

        const data = this.state.selectedLocations.map(selectedLocation => {
            return {
                member: memberId,
                id: selectedLocation,
                is_main: this.state.selectedMainLocation === selectedLocation,
            }
        })

        locationApi
            .bulkUpdateLocation([...data])
            .then(() => {
                swal(
                    "Success !",
                    "You successfully add new member !",
                    "success"
                )
                    .then(() => {
                        this.props.history.push("/customers/detail/" + memberId)
                    });
            })
            .catch(e => { console.log(e) })
    }

    submitData() {
        const crmApi = new CRMApi()
        const memberId = this.props.match.params?.customerId
        const { attributeCategories, fixedFieldsData, assignedContacts } = this.state
        const { activeClient } = this.context
        const clientId = activeClient;
        let bodyFormData = new FormData();
        const attributeValues = _.cloneDeep(this.state.attributeValues)

        Object.keys(fixedFieldsData).forEach(key => {
            const value = fixedFieldsData[key]
            if (Array.isArray(value)) {
                value.forEach((val, idx) => {
                    bodyFormData.append(key, val.id)
                })
            } else if (value === "logoPreview") {

            } else {
                bodyFormData.append(key, value)
            }
        })

        let count = 0
        attributeCategories.forEach((category, idxCategory) => {
            category.attributes.forEach((attr, idxAttr) => {
                if ((attributeValues[idxCategory]?.[idxAttr] || []).length || attr.value_type === "File") {
                    bodyFormData.append(`member_attributes[${count}]attribute`, attr.id);

                    (attributeValues[idxCategory]?.[idxAttr] || []).forEach((value, idx) => {
                        if (!value.id) {
                            bodyFormData.append(`member_attributes[${count}]attribute_values[${idx}]id`, "")
                        }
                        for (let [key, val] of Object.entries(value)) {
                            if (val && key !== "member_attribute") {
                                if (key === "option_value") {
                                    val = val.id
                                }
                                if (key === "file_value" && _.isString(val)) {
                                    val = ""
                                }
                                bodyFormData.append(`member_attributes[${count}]attribute_values[${idx}]${key}`, val)
                            }
                        }
                    })

                    count++
                }
            })
        })

        if (!memberId) {
            bodyFormData.append(`client`, clientId);

            assignedContacts.forEach((assignedContact, idxAssignedContact) => {
                if (assignedContact.contact) {
                    Object.keys(assignedContact.contact).forEach(key => {
                        if (key !== 'member') {
                            bodyFormData.append(`contacts[${idxAssignedContact}]${key}`, assignedContact.contact[key])
                        }
                    })
                }
            })

            crmApi
                .addMemberDetail(bodyFormData)
                .then(res => {
                    const memberId = res.data?.id
                    this.submitLocations(memberId)

                    // swal(
                    //     "Success !",
                    //     "You successfully added new member !",
                    //     "success"
                    // )
                    //     .then(() => {
                    //         this.props.history.push("/customers/detail/" + memberId)
                    //     });
                })
                .catch(err => {
                    // swal(
                    //     "Error !",
                    //     "Create new member unsuccessful !" + err,
                    //     "error"
                    // )
                    const objAlert = new AlertHelper();
                    objAlert.errorHandlerAlert(err.message);
                })
        } else {
            crmApi
                .updateMemberDetail(memberId, bodyFormData)
                .then(res => {
                    this.componentDidMount()
                    swal(
                        "Success !",
                        "You successfully updated member !",
                        "success"
                    )
                        .then(() => {
                            this.props.history.push("/customers")
                        });
                })
                .catch(err => {
                    const alertHelper = new AlertHelper();
                    alertHelper.errorHandlerAlert(err.response.data)
                })

            let assignedContactToSubmit = this.state.assignedContacts.map(assignedContact => {
                return {
                    id: assignedContact.contact ? assignedContact.contact.id : null,
                    contact_title: assignedContact.id,
                }
            }).filter(e => e.id)

            const arrAssignedContactIds = assignedContactToSubmit.map(e => e.id)

            const nullContacts = this.state.contacts
                .filter(contact => !arrAssignedContactIds.includes(contact.id) && contact.contact_title)
                .map(contact => {
                    return {
                        id: contact.id,
                        contact_title: null
                    }
                })

            assignedContactToSubmit = [...assignedContactToSubmit, ...nullContacts]

            crmApi
                .bulkUpdateAssignedContacts(assignedContactToSubmit)
                .then((res) => { })
        }
    }

    getFilename(url, options) {
        url = _.isString(url) ? url : url.name
        const filename = url.split("/")
        if (options?.onlyExtension) {
            const ext = filename[filename.length - 1].split(".")
            return "." + ext[ext.length - 1]
        }
        return filename[filename.length - 1]
    }

    render() {
        const requiredStar = <span className="text-danger">*</span>

        const fixedFields = <div className="d-flex">
            <div style={{ position: "relative", width: "200px", margin: "0 1.5rem 1.5rem 0" }}>
                <label className={[css.label].join(' ')} >Logo</label>
                {
                    this.state.fixedFieldsData.logoPreview &&
                    <button
                        className='btn btn-link text-danger'
                        style={{ position: "absolute", right: -11, top: 22 }}
                        onClick={this.handleRemoveLogo}
                    >
                        <i className='fas fa-times-circle'></i>
                    </button>
                }
                <label className='mb-0 d-block'>
                    <img
                        src={this.state.fixedFieldsData.logoPreview || getBlankImageSrc()}
                        alt=""
                        style={{ width: "100%", height: "135px", objectFit: "contain", cursor: "pointer", border: "1px solid black" }}
                    />
                    <input
                        type="file"
                        style={{ display: "none" }}
                        name="uploaded_logo"
                        accept="image/*"
                        onChange={(e) => { this.handleChangeLogo(e) }}
                    />
                </label>
            </div>

            <div className='row' style={{ flexGrow: "1" }}>
                <div className="col-md-6 form-group">
                    <label className={[css.label].join(' ')} htmlFor="company-phone">ID</label>
                    <input
                        className="form-control"
                        type="number"
                        min="1"
                        id="member-id-phone"
                        value={this.state.fixedFieldsData.member_id}
                        onChange={(e) => { this.handleChangeFixedFieldsValue(e.target.value, "member_id") }}
                    />
                </div>
                <div className="col-md-6 form-group">
                    <label className={[css.label].join(' ')} htmlFor="company-countries">Countries{requiredStar}</label>
                    <Select
                        className="bg-white"
                        multi
                        searchable={true}
                        id="company-countries"
                        options={this.state.countries.length && this.state.countries.map(country => ({ label: country.name, value: country.id }))}
                        placeholder="-- Please select countries --"
                        values={!!this.state.fixedFieldsData.countries
                            ? this.state.fixedFieldsData.countries.map(country => ({ label: country.name, value: country.id }))
                            : []}
                        closeOnSelect={true}
                        onChange={(values) => { this.handleChangeFixedFieldsValue(values, "countries") }}
                    />
                </div>
                <div className="col-md-6 form-group">
                    <label className={[css.label].join(' ')} htmlFor="company-name">Company Name{requiredStar}</label>
                    <input
                        className="form-control"
                        type="text"
                        id="company-name"
                        value={this.state.fixedFieldsData.name}
                        onChange={(e) => { this.handleChangeFixedFieldsValue(e.target.value, "name") }}
                    />
                </div>
                <div className="col-md-6 form-group">
                    <label className={[css.label].join(' ')} htmlFor="company-phone">Phone</label>
                    <input
                        className="form-control"
                        type="text"
                        id="company-phone"
                        value={this.state.fixedFieldsData.phone}
                        onChange={(e) => { this.handleChangeFixedFieldsValue(e.target.value, "phone") }}
                    />
                </div>
            </div>
        </div>

        const companyFields = this.state.attributeCategories.map((category, idxCategory) => {
            return (
                <div className="row mb-4">
                    {
                        category.attributes.map((attr, idxAttr) => {
                            const attributeNameLabel = <label className={[css.label].join(' ')} htmlFor={attr.id + attr.name}>
                                {attr.name}
                                {attr.is_required && requiredStar}
                            </label>

                            if (attr.value_type === "String") {
                                return <div className="col-md-6 form-group" style={{ breakInside: "avoid-column" }}>
                                    {attributeNameLabel}
                                    <input
                                        className="form-control"
                                        type="text"
                                        id={attr.id + attr.name}
                                        value={(this.state.attributeValues.length && this.state.attributeValues[idxCategory]?.[idxAttr]?.[0]?.string_value) || ""}
                                        onChange={(e) => { this.handleChangeValue(e.target.value, attr.value_type, idxCategory, idxAttr) }}
                                    />
                                </div>
                            } else if (attr.value_type === "Option") {
                                if (attr.multiple_value) {
                                    const options = (this.state.attributeOptions[attr.id] || []).map(attr => ({ label: attr.name, value: attr.id }))
                                    const values = ((this.state.attributeValues.length && this.state.attributeValues[idxCategory]?.[idxAttr]) || [])
                                        .map(item => ({ label: item.option_value?.name, value: item.option_value?.id }));
                                    return <div className="col-md-6 form-group" style={{ breakInside: "avoid-column" }}>
                                        {attributeNameLabel}
                                        <Select
                                            className="bg-white"
                                            multi
                                            searchable={true}
                                            id={attr.id + attr.name}
                                            options={options}
                                            placeholder={`-- Please select ${attr.name} --`}
                                            values={values}
                                            closeOnSelect={true}
                                            onChange={(values) => { this.handleChangeValue(values, attr.value_type, idxCategory, idxAttr, attr.multiple_value) }}
                                        />
                                    </div>
                                } else {
                                    return <div className="col-md-6 form-group" style={{ breakInside: "avoid-column" }}>
                                        {attributeNameLabel}
                                        <select
                                            className="form-control"
                                            type="text"
                                            id={attr.id + attr.name}
                                            value={this.state.attributeValues.length && this.state.attributeValues[idxCategory]?.[idxAttr]?.[0].option_value.id}
                                            onChange={(e) => { this.handleChangeValue(e.target.value, attr.value_type, idxCategory, idxAttr) }}
                                        >
                                            <option value="">-- Please select {attr.name} --</option>
                                            {
                                                (this.state.attributeOptions[attr.id] || [])
                                                    .map(attr => <option key={attr.id} value={attr.id}>{attr.name}</option>)
                                            }
                                        </select>
                                    </div>
                                }
                            } else if (attr.value_type === "Date") {
                                return <div className="col-md-6 form-group" style={{ breakInside: "avoid-column" }}>
                                    {attributeNameLabel}
                                    <input
                                        className="form-control"
                                        type="date"
                                        id={attr.id + attr.name}
                                        value={this.state.attributeValues.length && this.state.attributeValues[idxCategory]?.[idxAttr]?.[0].date_value}
                                        onChange={(e) => { this.handleChangeValue(e.target.value, attr.value_type, idxCategory, idxAttr) }}
                                    />
                                </div>
                            }

                            return null
                        })
                    }
                </div>
            )
        })

        const documentsUploaded = this.state.attributeCategories.map((category, idxCategory) => {
            return (
                <div>
                    {
                        category.attributes.map((attr, idxAttr) => {
                            if (attr.value_type === "File") {
                                return <>
                                    <hr></hr>
                                    <div className="d-flex justify-content-between mb-2">
                                        <label className={[css.label].join(' ')}>{attr.name}</label>
                                        <div className="d-flex">
                                            <button
                                                className="btn btn-danger mr-2"
                                                onClick={() => { this.deleteDocuments(idxCategory, idxAttr) }}
                                                disabled={this.isDisabledDeleteDocuments(idxAttr)}
                                            >
                                                Delete
                                            </button>
                                            {/* <button className="btn btn-link">Download all</button> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        {
                                            (this.state.attributeValues[idxCategory]?.[idxAttr] || []).map(item => {
                                                return (
                                                    <div className="col-md-6 px-2">
                                                        <div className="d-flex align-items-center py-2 px-3 mb-2" style={{ backgroundColor: "white", borderRadius: "3px", breakInside: "avoid-column" }}>
                                                            <input
                                                                className={["mr-3", css.DocumentCheckbox].join(' ')}
                                                                type="checkbox"
                                                                value={item.id}
                                                                checked={(this.state.checkedDocuments?.[idxAttr] || []).includes(item.id)}
                                                                onChange={(e) => { this.handleCheckedDocuments(e, item.id, idxAttr) }}
                                                            />
                                                            <div className="mr-3" style={{ backgroundColor: "#E2E2E2", height: "32px", width: "32px", borderRadius: "50%", border: "2px solid #223771" }}></div>
                                                            <div className="font-weight-bold" style={{ width: "40%", color: "#707070" }}>{this.getFilename(item.file_value)}</div>
                                                            <div className="d-flex align-items-center justify-content-between" style={{ width: "calc(100% - 40% - 48px - 29px)" }}>
                                                                <div style={{ color: "#707070" }}>{this.getFilename(item.file_value, { onlyExtension: true })}</div>
                                                                <a
                                                                    href={item.file_value}
                                                                    download
                                                                    className="btn btn-link d-flex align-items-center justify-content-center"
                                                                    style={{ backgroundColor: "#E2E2E2", height: "32px", width: "32px", borderRadius: "50%" }} >
                                                                    <i className="fa fa-download" aria-hidden="true"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className={["btn btn-secondary mt-2 form-control font-weight-normal d-flex align-items-center justify-content-center", css.ButtonSecondary].join(' ')}>
                                                <input
                                                    type="file"
                                                    style={{ display: "none" }}
                                                    name="uploaded_documents"
                                                    multiple
                                                    onChange={(e) => { this.handleFileUpload(e, idxCategory, idxAttr) }}
                                                />
                                                Add new documents
                                            </label>
                                        </div>
                                    </div>
                                </>
                            }

                            return null
                        })
                    }
                </div>
            )
        })

        const locationsList = <div>
            <hr></hr>
            <div className="d-flex justify-content-between mb-2">
                <label className={[css.label].join(' ')}>Locations</label>
            </div>
            <div className="row">
                {
                    this.state.locations.map(location => {
                        return <div className="col-md-6">
                            <LocationDetail
                                latitude={location.latitude}
                                longitude={location.longitude}
                                isMain={location.is_main}
                                title={location.name}
                                subtitle2={location.address}
                            />
                        </div>
                    })
                }
            </div>
            <div className="row">
                <div className="col-md-6">
                    <button
                        className={["btn btn-secondary mt-2 form-control d-flex align-items-center justify-content-center", css.ButtonSecondary].join(' ')}
                        onClick={() => { this.setState({ isShowLocationPairModal: true }) }}
                    >
                        Set locations
                    </button>
                </div>
            </div>
        </div>

        const customFeatures = <div className="col-md-3">
            <Card>
                <CardHeader className={[css.CardHeader, css.removeAfter].join(' ')}>History</CardHeader>
                <CardBody style={{ backgroundColor: "#F9F9F9" }}>
                    <div className={css.History}>
                        {
                            this.state.histories.map((section, sectionIdx) => {
                                const bgColor = ["#D4FFD4", "#FFFBE1", "#E1E5FF"]

                                return !!section.length && <Card>
                                    <CardHeader>{this.state.attributes[section?.[0].attribute].name}</CardHeader>
                                    <CardBody>
                                        {
                                            section.map(history => {
                                                let description = history.description

                                                const values = {
                                                    previousGrade: {},
                                                    newGrade: {},
                                                }

                                                if (this.state.attributeOptions[history.attribute]?.[0]?.grade) {
                                                    this.state.attributeOptions[history.attribute].forEach(option => {
                                                        if (option.id === history.previous_value) {
                                                            values.previousGrade = option
                                                        } else if (option.id === history.new_value) {
                                                            values.newGrade = option
                                                        }
                                                    })

                                                    const upgradeDowngrade = values.newGrade.grade > values.previousGrade.grade ? "Downgrade" : "Upgrade"
                                                    description = `${upgradeDowngrade} Membership from ${values.previousGrade.name} to ${values.newGrade.name}`
                                                }

                                                return (
                                                    <div className="mb-2" style={{ backgroundColor: bgColor[sectionIdx], padding: "0.5rem 1rem" }}>
                                                        <div className={["mb-1"].join(" ")} style={{ fontWeight: "500" }}>
                                                            <span className={`badge badge-pill border border-dark`}>{history.action}</span> {description}
                                                        </div>
                                                        <div className="d-flex justify-content-between" style={{ fontSize: "12px", fontWeight: "500" }}>
                                                            <div>{(new Date(history.timestamp)).toLocaleString('en-US', { dateStyle: "long", timeStyle: "short", hour12: true })}</div>
                                                            <i className="fas fa-edit" style={{ cursor: "pointer" }} onClick={() => { this.setState({ isShowHistoryChangeModal: true, historyData: history }) }} ></i>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </CardBody>
                                </Card>
                            })
                        }
                        <div className="form-group">
                            <button
                                className={["btn btn-primary form-control", css.ButtonPrimary].join(' ')}
                                onClick={() => { this.setState({ isShowHistoryModal: true }) }}
                            >
                                View more
                            </button>
                        </div>
                    </div>
                </CardBody>
            </Card>

            <Card>
                <CardHeader className={[css.CardHeader, css.removeAfter].join(' ')}>Notes List</CardHeader>
                <CardBody style={{ backgroundColor: "#F9F9F9" }}>
                    {
                        this.state.notes.map((note, idx) => {
                            const cssBgNote = idx % 2 === 0 ? css.bgPink : css.bgGreen;

                            return (
                                <div className={["mb-3", css.Note, cssBgNote].join(' ')} key={note.id}>
                                    <div className="pb-1">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="mb-1 font-weight-bold">{note.context}</div>
                                            <i className="fas fa-edit" style={{ cursor: "pointer" }} onClick={() => { this.toggleCustomerNoteModal(true, { noteId: note.id }) }}></i>
                                        </div>
                                        {
                                            note.contacted_person &&
                                            <div className="mb-1" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                <div>Contacted Person:</div>
                                                <div className="font-weight-normal">{note.contacted_person}</div>
                                            </div>
                                        }
                                        {
                                            note.reason &&
                                            <div className="mb-1" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                <div>Reason:</div>
                                                <div className="font-weight-normal">{note.reason}</div>
                                            </div>
                                        }
                                        {
                                            note.result &&
                                            <div className="mb-1" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                <div>Result:</div>
                                                <div className="font-weight-normal">{note.result}</div>
                                            </div>
                                        }
                                    </div>
                                    <div
                                        className="d-flex justify-content-between"
                                        style={{ fontSize: "12px", fontWeight: "500" }}
                                    >
                                        <div>{note.date}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="form-group">
                        <button className={["btn btn-primary form-control", css.ButtonPrimary].join(' ')} onClick={() => { this.toggleCustomerNoteModal(true, { noteId: 0 }) }}>Add note</button>
                    </div>
                </CardBody>
            </Card>
        </div>

        // -------------
        return (
            <Content>
                <LoadScript
                    googleMapsApiKey={this.state.gmapsApiKey}
                    libraries={this.state.mapsLib}
                >
                    <ContentHeader>
                        <div className="row">
                            <TitlePages title="Customer Detail" />
                        </div>
                    </ContentHeader>
                    <ContentBody>
                        <Link to="/customers" className="btn btn-default col-md-1 mb-3" >
                            <i className="fa fa-arrow-left"></i>&nbsp;
                            Back
                        </Link>
                        <div className="row pb-4">
                            <div className={this.state.mode === "add" ? "col-md-12" : "col-md-9"}>
                                <Card>
                                    <CardHeader
                                        className={[css.CardHeader, css.removeAfter].join(' ')}
                                    >
                                        Company <i className="fas fa-ellipsis-v"></i>
                                    </CardHeader>
                                    <CardBody style={{ backgroundColor: "#F9F9F9" }}>
                                        {fixedFields}
                                        {companyFields}
                                        {locationsList}
                                        {documentsUploaded}
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardHeader
                                        className={[css.CardHeader, css.removeAfter].join(' ')}
                                    >
                                        Contact <i className="fas fa-ellipsis-v"></i>
                                    </CardHeader>
                                    <CardBody style={{ backgroundColor: "#F9F9F9" }}>
                                        <div className="row">
                                            {
                                                this.state.assignedContacts.map((assignedContact, idxContact) => {
                                                    const path = this.props.match.path;
                                                    const contact = assignedContact.contact

                                                    if (path === "/customers/add") {
                                                        return <div className="col-md-6">
                                                            <label className={[css.label].join(' ')} >{assignedContact.name}</label>
                                                            <ContactSelectButton
                                                                idx={idxContact}
                                                                name={contact?.name}
                                                                title={contact?.position}
                                                                phone={contact?.phone}
                                                                email={contact?.email}
                                                                handleAssignedContact={this.handleAssignedContact(assignedContact.id)}
                                                            />
                                                        </div>
                                                    } else {
                                                        return <div className="col-md-6">
                                                            <label className={[css.label].join(' ')} >{assignedContact.name}</label>
                                                            <ContactSelect
                                                                idx={idxContact}
                                                                name={contact?.name}
                                                                title={contact?.position}
                                                                phone={contact?.phone}
                                                                email={contact?.email}
                                                                contactId={contact?.id}
                                                                contacts={this.state.contacts}
                                                                selectedContacts={this.state.assignedContacts.map(assignedContact => assignedContact.contact?.id)}
                                                                handleSelectedContact={this.handleSelectedContact}
                                                            />
                                                        </div>
                                                    }
                                                })
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                                <button className={["btn btn-primary float-right", css.ButtonPrimary].join(' ')} onClick={this.validationSubmit}>Save</button>
                            </div>
                            {this.state.mode !== "add" && customFeatures}
                        </div>
                    </ContentBody>

                    <CustomerNoteAddModal
                        show={this.state.isShowCustomerNoteModal}
                        toggle={this.toggleCustomerNoteModal}
                        memberId={this.props.match.params?.customerId}
                        reload={this.getAllNotes}
                        noteId={this.state.noteId}
                    />
                    <LocationPair
                        memberId={this.props.match.params?.customerId}
                        show={this.state.isShowLocationPairModal}
                        onHide={() => { this.setState({ isShowLocationPairModal: false }) }}
                        reload={this.state.mode === "edit" ? this.getAllLocations : this.getSelectedLocations}
                        noSubmitApi={this.state.mode === "add"}
                        customerDetailSetState={this.handlePageSetState}
                        selectedLocations={this.state.selectedLocations}
                        selectedMainLocation={this.state.selectedMainLocation}
                    />
                    <CrmLocationAddModal
                        show={this.state.isShowCrmLocationModal}
                        onHide={() => { this.setState({ isShowCrmLocationModal: false, isShowLocationPairModal: true }) }}
                    />
                    <HistoryChangeModal
                        show={this.state.isShowHistoryChangeModal}
                        onHide={() => { this.setState({ isShowHistoryChangeModal: false }) }}
                        historyData={this.state.historyData}
                        reloadHistory={this.getAllHistories}
                    />
                    <HistoryModal
                        show={this.state.isShowHistoryModal}
                        onHide={() => { this.setState({ isShowHistoryModal: false }) }}
                        memberId={this.props.match.params?.customerId}
                    />
                </LoadScript>
            </Content>
        );
    }
}

export default withRouter(withTranslation()(CustomerDetailPage));
