import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import Context from "../../pages/Context";
import EmailApi from "../../api/EmailApi";
import AlertHelper from '../../lib/AlertHelper';
import swal from 'sweetalert';

class EmailContextAdd extends Component {
    static contextType = Context;
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            fromEmail: "",
            transPage: "modals.email_context.",
        }

        this.handleValue = this.handleValue.bind(this)
        this.save = this.save.bind(this)
    }

    onOpenModal = () => {

    }

    onCloseModal = () => {
        this.props.onHide()
        this.setState({name: "", fromEmail: ""})
    }

    handleValue(value, stateName) {
        this.setState({ [stateName]: value })
    }

    save() {
        const emailApi = new EmailApi();
        const { activeClient, activeSite, adminSiteLanguages, activeSiteLang } = this.context;
        const siteLang = activeSite ? activeSiteLang : adminSiteLanguages;

        const content_languages = siteLang.map(lang => {
            return {
                language: lang.id,
                subject: "-",
                body: "<html></html>",
                content_design: "",
            }
        })

        const data = {
            "client": activeClient,
            "site": activeSite,
            "context": this.state.name,
            "from_email": this.state.fromEmail,
            "recipient_party": "",
            "has_dynamic_recipient": false,
            "recipients": [],
            "content_languages": content_languages
        }

        emailApi
            .addTemplateEmail(data)
            .then(res => {
                this.props.reload()
                this.onCloseModal()
                swal(
                    "Success !",
                    "You sucessfully added email context !",
                    "success"
                );
            })
            .catch((err) => {
                const alertHelper = new AlertHelper();
                alertHelper.errorHandlerAlert(err.response.data);
            });
    }

    render() {
        const { t } = this.props;

        return (
            <Modal size="md" show={this.props.show} onHide={() => this.props.onHide()} onShow={() => this.onOpenModal()}>
                <Modal.Header closeButton className="bg-primary">
                    <Modal.Title>{t(this.state.transPage + "title")} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="context-name">{t("name")}</label>
                        <input
                            className="form-control"
                            type="text"
                            id="context-name"
                            value={this.state.name}
                            onChange={(e) => { this.handleValue(e.target.value, 'name') }}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="context-name">{t("from_email")}</label>
                        <input
                            className="form-control"
                            type="email"
                            id="context-name"
                            value={this.state.fromEmail}
                            onChange={(e) => { this.handleValue(e.target.value, 'fromEmail') }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.onCloseModal}>
                        {t("cancel")}
                    </Button>
                    <Button variant="primary" onClick={this.save}>
                        {t("add")}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default withTranslation()(EmailContextAdd);