import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";

import Context from "../../pages/Context";
import SuperAdminApi from "../../api/SuperAdminApi";

import "./modalGlobal.css";

export default class SuperAdminSiteAddModal extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {};
        // this.onOpenModal = this.onOpenModal.bind(this)
        // this.onHideModal = this.onHideModal.bind(this)
        this.addDomain = this.addDomain.bind(this);
    }

    addDomain() {
        const Sa = new SuperAdminApi();
        const contextValue = this.context;

        const data = {
            site: parseInt(this.props.activeSite),
            domain: document.getElementById("d-name").value,
            id: null,
        };

        Sa.addDomain(contextValue.userKey, data)
            .then((res) => {
                if (res.data) {
                    swal("Success", `Add new domain`, "success");
                    this.props.callback(this.props.activeSite);
                    this.props.onHide();
                }
            })
            .catch((err) => {});
    }

    render() {
        return (
            <Modal
                id="mglbl-modal-SASadd"
                size="md"
                className="mglbl-modal"
                show={this.props.show}
                onHide={() => this.props.onHide()}
            >
                {this.props.loadingModal}
                <Modal.Header>
                    <Modal.Title>Add New Domain</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>Domain Name</label>
                        <input
                            id="d-name"
                            type="text"
                            name="d-name"
                            className="form-control"
                            defaultValue={""}
                        ></input>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex w-100 justify-content-end">
                        <button
                            type="button"
                            className="mglbl-btn-cancel"
                            onClick={() => this.props.onHide()}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="mglbl-btn-add"
                            onClick={this.addDomain}
                        >
                            Add Domain
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}
