import React, { Component } from "react";

import {
    Table,
    Button,
    Tab,
    Tabs,
    Dropdown,
    DropdownButton,
    InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Card, { CardBody } from "../../components/Card";
import {
    Content,
    ContentHeader,
    ContentBody,
} from "./../../components/Content";

import swal from "sweetalert";
import Pagination from "react-js-pagination";

import TitlePages from "../Elements/TitlePages";
import JobPosterModalDelete from "./JobPosterModalDelete";
import JobPosterModalClose from "./JobPosterModalClose";
import JobCollectionAddModal from "./JobCollectionAddModal";
import JobCollectionDeleteModal from "./JobCollectionDeleteModal";
import AppliedCount from "./AppliedCount";

import JobApi from "./../../api/JobApi";
import TemplateApi from "./../../api/TemplateApi";
import WidgetApi from "./../../api/WidgetApi";
import ApplicantList from "./ApplicantList";
import { withTranslation } from "react-i18next";
import UserContext from "../Context";
import config from "../../config/config";
import ReactGA from "react-ga";
import Cookies from "js-cookie";

class JobPosterList extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        const savedTab = localStorage.getItem("savedTab")
            ? JSON.parse(localStorage.getItem("savedTab"))
            : {};
        const savedJobPostersTab = savedTab.jobPosters
            ? savedTab.jobPosters
            : 1;

        this.state = {
            jobList: [],
            selectedJobs: [],

            totalCount: 0,
            limitPageData: 10,
            activePage: 1,
            clientID: parseInt(Cookies.get("active-client")),
            search: "",
            order: "-article__date",
            companyFilter: undefined,
            positionFilter: undefined,
            keywordFilter: undefined,
            jobTypeFilter: undefined,
            select: [],
            statusFilter: "Published",
            templateFilter: localStorage.getItem("templateId"),
            jobCollectionFilter: undefined,
            currencyFilter: undefined,
            minSalary: undefined,
            maxSalary: undefined,

            showDeleteModal: false,
            showCloseConfirm: false,
            showJobCollectionAddModal: false,
            showJobCollectionDeleteModal: false,

            companies: [],
            positions: [],
            keywords: [],
            currencies: [],
            templates: [],
            jobCollections: [],

            savedJobPostersTab: savedJobPostersTab,
            transPage: "pages.job_posters.",
            transAlert: "alert.",
            activeTemplate: localStorage.getItem("templateId"),
            activeClient: false,
        };

        this.getJobList = this.getJobList.bind(this);

        this.getCompanies = this.getCompanies.bind(this);
        this.getLocations = this.getLocations.bind(this);
        this.getPositions = this.getPositions.bind(this);
        this.getCurrencies = this.getCurrencies.bind(this);
        this.getKeywords = this.getKeywords.bind(this);
        this.getTemplates = this.getTemplates.bind(this);
        this.getJobCollections = this.getJobCollections.bind(this);

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePageTab = this.handlePageTab.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);

        this.setShowConfirm = this.setShowConfirm.bind(this);
        this.setShowCloseConfirm = this.setShowCloseConfirm.bind(this);
        this.setSelectedJob = this.setSelectedJob.bind(this);
        this.setMultiSelectedJob = this.setMultiSelectedJob.bind(this);

        this.deleteSelectedJob = this.deleteSelectedJob.bind(this);
        this.jobPosterUpdateStatus = this.jobPosterUpdateStatus.bind(this);

        this.setOrder = this.setOrder.bind(this);
    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
        const { activeClient } = this.context;
        this.setState({
            activeClient: activeClient,
        });
        this.getPositions();
        this.getCompanies();
        this.getLocations();
        this.getCurrencies();
        this.getKeywords();
        this.getTemplates();
        this.getJobCollections();

        this.getJobList();
        // this.getApplicantList()
    }

    componentDidUpdate(prevProps, prevState) {
        const { activeTemplate } = this.context;
        const { activeClient } = this.context;

        if (prevState.templateFilter !== this.state.templateFilter) {
            this.getJobCollections();
        } else if (activeTemplate !== this.state.activeTemplate) {
            this.setState(
                {
                    activeTemplate: activeTemplate,
                    templateFilter: activeTemplate,
                },
                () => {
                    this.getTemplates();
                    this.getJobCollections();
                }
            );
        }

        if (activeClient) {
            if (this.state.clientID !== activeClient) {
                this.setState(
                    {
                        clientID: activeClient,
                        activeClient: activeClient,
                    },
                    () => {
                        this.getJobList();
                        this.getJobCollections();
                    }
                );
            }
        }
    }

    handleSearchChange(value) {
        this.setState(
            {
                search: value,
            },
            () => {
                this.getJobList();
            }
        );
    }

    getJobList() {
        const obJob = new JobApi();

        let lang = localStorage.getItem("langId");
        obJob
            .getJobList({
                language: lang ? lang : 1,
                article__client: this.state.activeClient,
                template: this.state.templateFilter,

                page_size: this.state.limitPageData,
                page: this.state.activePage,

                company: this.state.companyFilter,
                position: this.state.positionFilter,
                keyword: this.state.keywordFilter,

                job_type: this.state.jobTypeFilter,
                currency: this.state.currencyFilter,
                status: this.state.statusFilter,
                search: this.state.search,
                widget: this.state.jobCollectionFilter,

                min_salary: this.state.minSalary,
                max_salary: this.state.maxSalary,

                order: this.state.order,
            })
            .then((res) => {
                this.setState({
                    jobList: res.data.results,
                    totalCount: res.data.count,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getCompanies() {
        const obJob = new JobApi();

        obJob
            .getCompanies({
                max_size: true,
            })
            .then((res) => {
                this.setState({
                    companies: res.data.results,
                });
            })
            .catch((err) => {});
    }

    getLocations() {
        const obJob = new JobApi();

        obJob
            .getLocationList({
                max_size: true,
            })
            .then((res) => {
                this.setState({
                    locations: res.data.results,
                });
            })
            .catch((err) => {});
    }

    getPositions() {
        const obJob = new JobApi();

        obJob
            .getPositionList({
                max_size: true,
            })
            .then((res) => {
                this.setState({
                    positions: res.data.results,
                });
            })
            .catch((err) => {});
    }

    getCurrencies() {
        const obJob = new JobApi();

        obJob
            .getCurrencies({
                max_size: true,
            })
            .then((res) => {
                this.setState({
                    currencies: res.data.results,
                });
            })
            .catch((err) => {});
    }

    getKeywords() {
        const obJob = new JobApi();

        obJob
            .getKeywords({
                max_size: true,
            })
            .then((res) => {
                this.setState({
                    keywords: res.data.results,
                });
            })
            .catch((err) => {});
    }

    getTemplates() {
        const templateApi = new TemplateApi();
        const activeSite = localStorage.getItem("siteId");

        templateApi.getSites(activeSite).then((res) => {
            const templates = res.data.templates;
            this.setState({
                templates: templates,
            });
        });
    }

    getJobCollections() {
        const widgetApi = new WidgetApi();

        const params = {
            client: this.state.clientID,
            max_size: true,
            category: 5,
            internal: "False",
        };

        widgetApi.getAllWidgets(params).then((res) => {
            const widgetList = res.data.results.map((item) => {
                var page = "";

                if (item.page) {
                    page = " - " + item.page;
                }

                return {
                    ...item,
                    namePage: item.name + page,
                };
            });

            this.setState(
                {
                    jobCollections: widgetList,
                    jobCollectionFilter: widgetList.length
                        ? widgetList[0].id
                        : "",
                },
                this.getJobList
            );
        });
    }

    setOrder(column) {
        if (!this.state.order) {
            this.setState(
                {
                    order: column,
                },
                () => {
                    this.getJobList();
                }
            );
        } else if (this.state.order !== column) {
            this.setState(
                {
                    order: column,
                },
                () => {
                    this.getJobList();
                }
            );
        } else if (this.state.order === column) {
            this.setState(
                {
                    order: `-${column}`,
                },
                () => {
                    this.getJobList();
                }
            );
        }
    }

    setSelectedJob(jobId) {
        this.setState(
            {
                selectedJob: this.state.selectedJob.concat(jobId),
            },
            () => {}
        );
    }

    setMultiSelectedJob(jobId, value) {

        var lastSelected = [...this.state.selectedJobs];
        var indexDelete = -1;

        if (value === true) {
            lastSelected.push(jobId);
        } else {
            this.state.selectedJobs.forEach((item, index) => {
                if (item.id === jobId.id) {
                    indexDelete = index;
                }
            });

            lastSelected.splice(indexDelete, 1);
        }

        this.setState(
            {
                selectedJobs: lastSelected,
            },
            () => {
                if (this.state.selectedJobs.length > 0) {
                    this.setState({
                        disableAction: false,
                    });
                } else {
                    this.setState({
                        disableAction: true,
                    });
                }
            }
        );
    }

    deleteSelectedJob() {
        const { t } = this.props;
        const obj = new JobApi();

        obj.deleteSelectedJob(this.state.selectedJobs)
            .then((res) => {
                swal(
                    "Success!",
                    t(this.state.transAlert + "job_delete_success"),
                    "success"
                );
                this.setShowConfirm();
                this.getJobList();
            })
            .catch((err) => {
                swal("Error!", err.message, "error");
            });
    }

    jobPosterUpdateStatus() {
        const { t } = this.props;
        const obj = new JobApi();

        const data = this.state.selectedJobs.map((item) => {
            return item.id;
        });

        obj.updateJobPosterStatus({
            job_posters: data,
        })
            .then((res) => {
                swal(
                    "Success!",
                    t(this.state.transAlert + "job_close_success"),
                    "success"
                );
                this.setShowConfirm();
                this.getJobList();

                this.setState({
                    selectedJobs: [],
                });
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    setShowConfirm() {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
        });
    }

    setShowCloseConfirm() {
        this.setState({
            showCloseConfirm: !this.state.showCloseConfirm,
        });
    }

    handleShowModal(stateName) {
        this.setState({
            [stateName]: !this.state[stateName],
        });
    }

    handlePageChange(pageNumber) {
        this.setState(
            {
                activePage: pageNumber,
            },
            () => {
                this.getJobList();
            }
        );
    }

    handleFilterChange(state, value) {
        this.setState(
            {
                [state]: value,
            },
            () => {
                this.getJobList();
            }
        );
    }

    handleChangeStatus(value) {
        this.setState(
            {
                status: value,
            },
            () => {
                this.getJobList();
            }
        );
    }

    handlePageTab(value) {
        let savedTab = localStorage.getItem("savedTab")
            ? JSON.parse(localStorage.getItem("savedTab"))
            : {};
        savedTab.jobPosters = value;
        this.setState({ savedTab: value }, () => {
            localStorage.setItem("savedTab", JSON.stringify(savedTab));
        });
    }

    render() {
        const { t } = this.props;
        const { jobCollections } = this.state;
        const { employee_privileges } = this.context;
        const isOnlyMonitor =
            employee_privileges?.filter((e) => e.id === 5).length > 0;

        const JobList = this.state.jobList.map((item) => {
            return (
                <tr key={item.id}>
                    <td>
                        <input
                            type="checkbox"
                            value={item.id}
                            onClick={(event) =>
                                this.setMultiSelectedJob(
                                    item,
                                    event.target.checked
                                )
                            }
                        ></input>
                        &nbsp;
                        {item.id}
                    </td>
                    <td>
                        {" "}
                        <a href={`/job-poster/detail/${item.id}`}>
                            {item.title}
                        </a>
                    </td>
                    <td> {item.position}</td>
                    <td> {item.company}</td>
                    <td> {item.location}</td>

                    <td> {item.job_type}</td>
                    <td>
                        <AppliedCount jobId={item.id}></AppliedCount>
                    </td>
                    <td>
                        <div style={{ float: "right" }}>
                            <Link
                                style={
                                    isOnlyMonitor
                                        ? { pointerEvents: "none" }
                                        : null
                                }
                                to={`/job-poster/detail/${item.id}`}
                                className={
                                    "btn btn-primary btn-xs " +
                                    (isOnlyMonitor ? "disabled" : "")
                                }
                            >
                                <i className="fa fa-edit"></i>
                            </Link>
                            &nbsp;
                            <Button
                                disabled={isOnlyMonitor}
                                variant="danger"
                                className="btn btn-xs"
                                onClick={() => {
                                    this.setState(
                                        {
                                            selectedJobs:
                                                this.state.selectedJobs.concat([
                                                    item,
                                                ]),
                                        },
                                        () => this.setShowConfirm()
                                    );
                                }}
                            >
                                <i className="fa fa-trash"></i>
                            </Button>
                        </div>
                    </td>
                </tr>
            );
        });

        return (
            <Content>
                <ContentHeader>
                    {/* <h1> Job Vacancy </h1> */}
                    <TitlePages title={t("pages.job_posters.title")} />
                </ContentHeader>
                <ContentBody>
                    <Card>
                        <CardBody>
                            <Tabs
                                onSelect={(value) => {
                                    this.handlePageTab(value);
                                }}
                                defaultActiveKey={this.state.savedJobPostersTab}
                            >
                                <Tab
                                    eventKey={1}
                                    title={t("pages.job_posters.job_poster")}
                                >
                                    <br></br>
                                    {/* { JSON.stringify(this.state.selectedJobs)} */}

                                    <DropdownButton
                                        disabled={
                                            this.state.disableAction ||
                                            isOnlyMonitor
                                        }
                                        className="float-right"
                                        style={{ marginLeft: 10 }}
                                        title={t("action")}
                                    >
                                        {/* <Dropdown.Toggle variant="info" id="dropdown-basic">
                                            Action <i className="fa fa-caret-down"></i>
                                        </Dropdown.Toggle> */}
                                        <Dropdown.Item
                                            onClick={() => {
                                                this.setShowCloseConfirm();
                                            }}
                                        >
                                            <i className="fa fa-times-circle">
                                                &nbsp;
                                            </i>{" "}
                                            {t("pages.job_posters.close_job")}
                                        </Dropdown.Item>
                                    </DropdownButton>

                                    <div className="float-right">
                                        <Link
                                            style={
                                                isOnlyMonitor
                                                    ? { pointerEvents: "none" }
                                                    : {}
                                            }
                                            to={`/job-posters/add/`}
                                            className={
                                                "btn btn-primary " +
                                                (isOnlyMonitor
                                                    ? "disabled"
                                                    : "")
                                            }
                                        >
                                            <i className="fa fa-plus">&nbsp;</i>
                                            {t("pages.job_posters.add_job")}
                                        </Link>
                                    </div>

                                    <div className="clearfix"></div>
                                    <b>{t("filter")}</b>
                                    <hr></hr>

                                    <div className="row">
                                        {/* <div className="col-md-4">
                                            <div className="form-group">
                                                <label> {t("template")} </label>
                                                <select
                                                    className="form-control"
                                                    value={
                                                        this.state
                                                            .templateFilter
                                                    }
                                                    onChange={(event) =>
                                                        this.handleFilterChange(
                                                            "templateFilter",
                                                            event.target.value
                                                        )
                                                    }
                                                >
                                                    {this.state.templates.map(
                                                        (item) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </select>
                                            </div>
                                        </div> */}
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label> Job Collection </label>
                                                <button
                                                    disabled={isOnlyMonitor}
                                                    className="btn btn-info btn-xs float-right"
                                                    onClick={() => {
                                                        this.setState(
                                                            {
                                                                jobCollectionFilter: 0,
                                                            },
                                                            () => {
                                                                this.handleShowModal(
                                                                    "showJobCollectionAddModal"
                                                                );
                                                            }
                                                        );
                                                    }}
                                                >
                                                    + Add Job Collection
                                                </button>
                                                <span className="clearfix"></span>
                                                <InputGroup>
                                                    <select
                                                        className="form-control"
                                                        value={
                                                            this.state
                                                                .jobCollectionFilter
                                                        }
                                                        onChange={(event) =>
                                                            this.handleFilterChange(
                                                                "jobCollectionFilter",
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        {this.state.jobCollections.map(
                                                            (item) => {
                                                                return (
                                                                    <option
                                                                        value={
                                                                            item.id
                                                                        }
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                    </select>
                                                    <InputGroup.Prepend
                                                        onClick={
                                                            !isOnlyMonitor
                                                                ? () => {
                                                                      this.setState(
                                                                          {
                                                                              jobCollectionFilter:
                                                                                  !this
                                                                                      .state
                                                                                      .jobCollectionFilter
                                                                                      ? jobCollections[0]
                                                                                            .id
                                                                                      : this
                                                                                            .state
                                                                                            .jobCollectionFilter,
                                                                          },
                                                                          () => {
                                                                              this.handleShowModal(
                                                                                  "showJobCollectionAddModal"
                                                                              );
                                                                          }
                                                                      );
                                                                  }
                                                                : null
                                                        }
                                                        style={
                                                            isOnlyMonitor
                                                                ? {
                                                                      display:
                                                                          "none",
                                                                  }
                                                                : {}
                                                        }
                                                    >
                                                        <InputGroup.Text>
                                                            {" "}
                                                            <i className="fa fa-edit"></i>
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <InputGroup.Prepend
                                                        onClick={
                                                            !isOnlyMonitor
                                                                ? () => {
                                                                      this.setState(
                                                                          {
                                                                              jobCollectionFilter:
                                                                                  !this
                                                                                      .state
                                                                                      .jobCollectionFilter
                                                                                      ? jobCollections[0]
                                                                                            .id
                                                                                      : this
                                                                                            .state
                                                                                            .jobCollectionFilter,
                                                                          },
                                                                          () => {
                                                                              this.handleShowModal(
                                                                                  "showJobCollectionDeleteModal"
                                                                              );
                                                                          }
                                                                      );
                                                                  }
                                                                : null
                                                        }
                                                        style={
                                                            isOnlyMonitor
                                                                ? {
                                                                      display:
                                                                          "none",
                                                                  }
                                                                : {}
                                                        }
                                                    >
                                                        <InputGroup.Text>
                                                            {" "}
                                                            <i className="fa fa-trash"></i>
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                </InputGroup>
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label> {t("company")} </label>
                                                <select
                                                    className="form-control"
                                                    value={
                                                        this.state.companyFilter
                                                    }
                                                    onChange={(event) =>
                                                        this.handleFilterChange(
                                                            "companyFilter",
                                                            event.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="">
                                                        {" "}
                                                        --{" "}
                                                        {t(
                                                            this.state
                                                                .transPage +
                                                                "all_companies"
                                                        )}{" "}
                                                        --{" "}
                                                    </option>
                                                    {this.state.companies.map(
                                                        (item) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>
                                                    {" "}
                                                    {t("min_salary")}{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    value={this.state.minSalary}
                                                    onChange={(event) =>
                                                        this.handleFilterChange(
                                                            "minSalary",
                                                            event.target.value
                                                        )
                                                    }
                                                    className="form-control"
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div
                                                className="form-group"
                                                value={
                                                    this.state.positionFilter
                                                }
                                                onChange={(event) =>
                                                    this.handleFilterChange(
                                                        "positionFilter",
                                                        event.target.value
                                                    )
                                                }
                                            >
                                                <label> {t("position")} </label>
                                                <select className="form-control">
                                                    <option value="">
                                                        {" "}
                                                        --{" "}
                                                        {t(
                                                            this.state
                                                                .transPage +
                                                                "all_positions"
                                                        )}{" "}
                                                        --{" "}
                                                    </option>
                                                    {this.state.positions.map(
                                                        (item) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>
                                                    {" "}
                                                    {t("max_salary")}{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.maxSalary}
                                                    onChange={(event) =>
                                                        this.handleFilterChange(
                                                            "maxSalary",
                                                            event.target.value
                                                        )
                                                    }
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label> {t("keyword")} </label>
                                                <select
                                                    className="form-control"
                                                    value={
                                                        this.state.keywordFilter
                                                    }
                                                    onChange={(event) =>
                                                        this.handleFilterChange(
                                                            "keywordFilter",
                                                            event.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="">
                                                        {" "}
                                                        --{" "}
                                                        {t(
                                                            this.state
                                                                .transPage +
                                                                "all_keywords"
                                                        )}{" "}
                                                        --{" "}
                                                    </option>
                                                    {this.state.keywords.map(
                                                        (item) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label> {t("currency")} </label>
                                                <select
                                                    className="form-control"
                                                    value={
                                                        this.state
                                                            .currencyFilter
                                                    }
                                                    onChange={(event) =>
                                                        this.handleFilterChange(
                                                            "currencyFilter",
                                                            event.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="">
                                                        {" "}
                                                        --{" "}
                                                        {t(
                                                            this.state
                                                                .transPage +
                                                                "all_currency"
                                                        )}{" "}
                                                        --{" "}
                                                    </option>
                                                    {this.state.currencies.map(
                                                        (item) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label> {t("job_type")} </label>
                                                <select
                                                    className="form-control"
                                                    value={
                                                        this.state.jobTypeFilter
                                                    }
                                                    onChange={(event) =>
                                                        this.handleFilterChange(
                                                            "jobTypeFilter",
                                                            event.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="">
                                                        {" "}
                                                        --{" "}
                                                        {t(
                                                            this.state
                                                                .transPage +
                                                                "all_job_type"
                                                        )}{" "}
                                                        --{" "}
                                                    </option>
                                                    <option value="Full Time">
                                                        {" "}
                                                        Full Time{" "}
                                                    </option>
                                                    <option value="Part Time">
                                                        {" "}
                                                        Part Time{" "}
                                                    </option>
                                                    <option value="Internship">
                                                        {" "}
                                                        Internship{" "}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    {" "}
                                                    {t("title_search")}{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="search"
                                                    value={this.state.search}
                                                    onChange={(event) =>
                                                        this.handleSearchChange(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label> {t("status")} </label>
                                                <select
                                                    className="form-control"
                                                    value={
                                                        this.state.statusFilter
                                                    }
                                                    onChange={(event) =>
                                                        this.handleFilterChange(
                                                            "statusFilter",
                                                            event.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="Published">
                                                        --{" "}
                                                        {t(
                                                            this.state
                                                                .transPage +
                                                                "all_status"
                                                        )}{" "}
                                                        --
                                                    </option>
                                                    <option value="Published">
                                                        {" "}
                                                        Published{" "}
                                                    </option>
                                                    <option value="Drafted">
                                                        Drafted
                                                    </option>
                                                    <option value="Closed">
                                                        {" "}
                                                        Closed{" "}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>

                                    <div className="row">
                                        <div className="col-md-10"></div>
                                        <div className="col-md-2">
                                            <div>
                                                <Pagination
                                                    activePage={
                                                        this.state.activePage
                                                    }
                                                    itemsCountPerPage={
                                                        this.state.limitPageData
                                                    }
                                                    totalItemsCount={
                                                        this.state.totalCount
                                                    }
                                                    pageRangeDisplayed={4}
                                                    onChange={
                                                        this.handlePageChange
                                                    }
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* { JSON.stringify(this.state.selectedJobs.map(item => item.article.name))} */}
                                        <Table striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th width="7%">
                                                        {" "}
                                                        ID
                                                        <button
                                                            class="btn btn float-right"
                                                            onClick={() =>
                                                                this.setOrder(
                                                                    "id"
                                                                )
                                                            }
                                                        >
                                                            <i className="fa fa-sort "></i>
                                                        </button>
                                                    </th>
                                                    <th width="20%">
                                                        <span className="float-left">
                                                            {t("title")}
                                                        </span>
                                                        <button
                                                            class="btn btn float-right"
                                                            onClick={() =>
                                                                this.setOrder(
                                                                    "title"
                                                                )
                                                            }
                                                        >
                                                            <i className="fa fa-sort "></i>
                                                        </button>
                                                    </th>
                                                    <th>
                                                        {t("position")}
                                                        <button
                                                            class="btn btn float-right"
                                                            onClick={() =>
                                                                this.setOrder(
                                                                    "position"
                                                                )
                                                            }
                                                        >
                                                            <i className="fa fa-sort "></i>
                                                        </button>
                                                    </th>
                                                    <th>
                                                        {t("company")}
                                                        <button
                                                            class="btn btn float-right"
                                                            onClick={() =>
                                                                this.setOrder(
                                                                    "location__company__name"
                                                                )
                                                            }
                                                        >
                                                            <i className="fa fa-sort "></i>
                                                        </button>
                                                    </th>
                                                    <th width="20%">
                                                        {" "}
                                                        {t("location")}
                                                        <button
                                                            class="btn btn float-right"
                                                            onClick={() =>
                                                                this.setOrder(
                                                                    "location"
                                                                )
                                                            }
                                                        >
                                                            <i className="fa fa-sort "></i>
                                                        </button>
                                                    </th>
                                                    <th>
                                                        {" "}
                                                        {t("job_type")}
                                                        <button
                                                            class="btn btn float-right"
                                                            onClick={() =>
                                                                this.setOrder(
                                                                    "job_type"
                                                                )
                                                            }
                                                        >
                                                            <i className="fa fa-sort "></i>
                                                        </button>
                                                    </th>
                                                    <th> {t("applied")} </th>
                                                    <th> {t("action")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>{JobList}</tbody>
                                        </Table>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-10"></div>
                                        <div className="col-md-2">
                                            <div>
                                                <Pagination
                                                    activePage={
                                                        this.state.activePage
                                                    }
                                                    itemsCountPerPage={
                                                        this.state.limitPageData
                                                    }
                                                    totalItemsCount={
                                                        this.state.totalCount
                                                    }
                                                    pageRangeDisplayed={4}
                                                    onChange={
                                                        this.handlePageChange
                                                    }
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab
                                    eventKey={2}
                                    title={t(
                                        "pages.job_posters.applicant_list"
                                    )}
                                >
                                    <br></br>
                                    <ApplicantList
                                        isReloadApplicants={
                                            this.state.isReloadApplicants
                                        }
                                        handleReloadApplicants={
                                            this.handleReloadApplicants
                                        }
                                        activeApplicant={true}
                                    ></ApplicantList>
                                </Tab>
                                <Tab
                                    eventKey={3}
                                    title={t(
                                        "pages.job_posters.applicant_archive"
                                    )}
                                >
                                    <br></br>
                                    <ApplicantList
                                        isReloadApplicants={
                                            this.state.isReloadApplicants
                                        }
                                        handleReloadApplicants={
                                            this.handleReloadApplicants
                                        }
                                        activeApplicant={false}
                                    ></ApplicantList>
                                </Tab>
                            </Tabs>
                        </CardBody>
                    </Card>
                </ContentBody>
                <JobPosterModalDelete
                    show={this.state.showDeleteModal}
                    selectedJobs={this.state.selectedJobs}
                    deleteSelectedJob={this.deleteSelectedJob}
                    onHide={this.setShowConfirm}
                ></JobPosterModalDelete>
                <JobPosterModalClose
                    show={this.state.showCloseConfirm}
                    selectedJobs={this.state.selectedJobs}
                    onHide={this.setShowCloseConfirm}
                    select={this.state.select}
                    jobPosterUpdateStatus={this.jobPosterUpdateStatus}
                ></JobPosterModalClose>
                <JobCollectionAddModal
                    show={this.state.showJobCollectionAddModal}
                    onHide={() => {
                        this.handleShowModal("showJobCollectionAddModal");
                    }}
                    widgetId={this.state.jobCollectionFilter}
                    templateId={this.state.templateFilter}
                    callbackSuccess={() => {
                        this.getJobCollections();
                    }}
                />
                <JobCollectionDeleteModal
                    show={this.state.showJobCollectionDeleteModal}
                    widgetId={this.state.jobCollectionFilter}
                    onHide={() => {
                        this.handleShowModal("showJobCollectionDeleteModal");
                    }}
                    selectedJobCollection={this.state.jobCollections.filter(
                        (item) =>
                            item.id === parseInt(this.state.jobCollectionFilter)
                    )}
                    callbackSuccess={() => {
                        this.getJobCollections();
                    }}
                />
            </Content>
        );
    }
}

export default withTranslation()(JobPosterList);
