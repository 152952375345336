import React, { Component } from 'react';

import Card,{ CardBody  } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content"

class Page404 extends Component {
    render() {
        return (
            <Content>
                <ContentHeader>
                    <h1> Error 404 - Not Found </h1>
                </ContentHeader>
                <ContentBody>
                    <Card>
                       
                        <CardBody>
                            <p> This Page doesnt Exists </p>
                            <div className="clearfix"></div>
                        </CardBody>
                       
                    </Card>
                </ContentBody>
            </Content>
        );
    }
}

export default Page404;