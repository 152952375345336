import React, { Component } from 'react';
import { Badge, Dropdown } from "react-bootstrap"
import { CardHeader } from "../../components/Card";

import WidgetAddNew from "./../../components/Modal/WidgetAddNew"

class SectionFormHeader extends Component {

    constructor(props) {
        super(props)

        this.state = { 
            showWidgetSetting:false
        }

        this.setShowWidgetSetting = this.setShowWidgetSetting.bind(this)
    }

    setShowWidgetSetting() {
        this.setState({
            showWidgetSetting:!this.state.showWidgetSetting
        })
    }

    render() {

        var arrow = <button className="btn btn-xs"><i className="fas fa-chevron-up"></i></button>
        var bgColor = ""
        var badgeVariant = "primary"
        if (this.props.openCollapse === false) {
            arrow = <button className="btn btn-xs"><i className="fas fa-chevron-down text-white"></i></button>
            bgColor = "bg-primary"
            badgeVariant = "light"
        }

        return (
            <CardHeader title={this.props.title} className={`${bgColor} ${this.props.className}`}>

                <div style={{float: "left" }}>
                    <div style={{
                        width: "100%",
                        float: "left",
                        marginLeft: 0,
                        fontWeight: "bold"
                    }}>
                        {this.props.widgetName} <i className="fas fa-pencil"> &nbsp;</i>
                    </div>

                </div>

                <div style={{marginLeft: "2%"}} className="card-tools">
                    <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                           <i className="fa fa-ellipsis-v"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => this.setShowWidgetSetting()} > <i className="fa fa-cog">&nbsp;</i> Widget Setting </Dropdown.Item>
                           
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <div style={{float: "right", marginLeft:"2%"}} className="arrow-toggle" onClick={() => { this.props.setOpenCollapse() }}>
                    {arrow}
                </div>

                <div style={{ float: "right" }}>
                    <Badge pill variant={badgeVariant}>
                        <i className={`fa ${this.props.icon}`}>&nbsp;</i> {this.props.widgetElementType}
                    </Badge>
                    <div style={{ display: "inline-block", width: 10 }}></div>
                    <Badge pill variant={badgeVariant} >
                        <i className="fa fa-lock">&nbsp;</i>  &nbsp; {this.props.widgetElementStatic}
                    </Badge>
                    
                </div>

                <WidgetAddNew
                    show={this.state.showWidgetSetting}
                    onHide={this.setShowWidgetSetting}
                    widgetId={this.props.widget.id || this.props.widget}
                    widget={this.props.widget}
                    sectionWidgetPair={true}
                >

                </WidgetAddNew>
            </CardHeader>
        );
    }
}

export default SectionFormHeader;