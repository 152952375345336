import axios from "axios"
import config from "./../config/config"

class CRMApi {
    base_api = config.base_api
    token = config.token

    getAttributeCategories(params) {
        return axios.get(this.base_api + "crm/attribute_categories/", {
            params: params,
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    getAttributeOptions(attributeId, customParams) {
        return axios.get(this.base_api + "crm/attribute_options/", {
            params: {
                attribute: attributeId,
                max_size: true,
                ordering: "name",
                ...customParams
            },
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    getAttributeFilters(params) {
        return axios.get(this.base_api + "crm/attribute_filters/", {
            params: params,
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    getAllAttributes(params) {
        return axios.get(this.base_api + "crm/attributes/", {
            params: params,
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    getAllMembers(params) {
        return axios.get(this.base_api + "crm/members/", {
            params: params,
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    getAllMembersName(params) {
        return axios.get(this.base_api + "crm/members/get_names/", {
            params: params,
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    getCounts(clientId) {
        return axios.get(this.base_api + "crm/members/get_counts/", {
            params: {
                client: clientId
            },
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    getMemberDetail(id) {
        return axios.get(this.base_api + `crm/members/${id}`, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    addMemberDetail(data) {
        return axios({
            method: "post",
            url: this.base_api + `crm/members/`,
            data: data,
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "multipart/form-data"
            }
        })
    }

    updateMemberDetail(id, data) {
        return axios({
            method: "patch",
            url: this.base_api + `crm/members/${id}/`,
            data: data,
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "multipart/form-data"
            }
        })
    }

    getAllNotesByMemberId(memberId) {
        return axios.get(this.base_api + "crm/notes/", {
            params: {
                member: memberId
            },
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    getNoteDetail(noteId) {
        return axios.get(this.base_api + `crm/notes/${noteId}/`, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    addNote(data) {
        return axios.post(this.base_api + `crm/notes/`, data, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    updateNote(noteId, data) {
        return axios.patch(this.base_api + `crm/notes/${noteId}/`, data, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    deleteNote(noteId) {
        return axios.delete(this.base_api + `crm/notes/${noteId}/`, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    getAllHistoriesByMemberId(memberId, params) {
        return axios.get(this.base_api + "crm/histories/", {
            params: {
                member: memberId,
                ...params,
            },
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    updateHistory(historyId, data) {
        return axios.patch(this.base_api + `crm/histories/${historyId}/`, data, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    deleteHistory(historyId) {
        return axios.delete(this.base_api + `crm/histories/${historyId}/`, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    bulkDeleteMembers(ids) {
        return axios({
            method: "delete",
            url: this.base_api + `crm/members/bulk_delete/`,
            data: {
                ids: ids,
            },
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    getAllContacts(params) {
        return axios.get(this.base_api + "crm/contacts/", {
            params: params,
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    bulkDeleteContacts(ids) {
        return axios({
            method: "delete",
            url: this.base_api + `crm/contacts/bulk_delete/`,
            data: {
                ids: ids,
            },
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    getAllAssignedContacts(params) {
        return axios.get(this.base_api + "crm/contact_titles/", {
            params: params,
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    bulkUpdateAssignedContacts(data) {
        return axios.patch(this.base_api + `crm/contacts/bulk_update/`, data, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    getContactDetail(id) {
        return axios.get(this.base_api + `crm/contacts/${id}/`, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    addContact(data) {
        return axios.post(this.base_api + `crm/contacts/`, data, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    updateContact(id, data) {
        return axios.patch(this.base_api + `crm/contacts/${id}/`, data, {
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    exportData(params) {
        return axios.get(this.base_api + `crm/members/export/`, {
            params: params,
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    bulkUpdateMembersData(params, data) {
        return axios.put(this.base_api + `crm/members/bulk_update/`, data, {
            params: params,
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }

    getDynamicReport(params, data) {
        return axios.post(this.base_api + `crm/members/get_dynamic_report/`, data, {
            params: params,
            headers: {
                "Authorization": "Token " + this.token,
                "Content-Type": "application/json"
            }
        })
    }
}

export default CRMApi;
