import React, { Component } from "react";
import Card, { CardHeader, CardTitle, CardBody } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content";
import { LoadScript } from "@react-google-maps/api";

import "./SectionDetail.css";

// import SectionFormHeader from './SectionForm/SectionFormHeader';
import AssetForm from "./SectionForm/AssetForm";
import TextCard from "./SectionForm/TextCard";
import ArticleCard from "./SectionForm/ArticleCard";
import TitlePages from "./Elements/TitlePages";

import SectionApi from "../api/SectionApi";
import { withTranslation } from "react-i18next";
import config from "../config/config";
import ReactGA from "react-ga";
import Context from "./Context";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

class SectionDetail extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      gmapsApiKey: "AIzaSyCKqMs4hmNgoQtmIUsd7diBKHj6UV610Jg",
      mapsLib: ["drawing", "places"],

      section: {
        section_widgets: [],
      },
      sectionId: false,
      transPage: "pages.section_detail.",
      innerwidth: "",
      innerwidthBig: "",
      isLoaded: {},
    };

    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.getGeneralSectionDetail = this.getGeneralSectionDetail.bind(this);
  }

  componentDidMount() {
    ReactGA.initialize(config.GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    let w = window.screen.width;

    const { sectionDetail } = this.context;
    sectionDetail.methods.getPages();
    sectionDetail.methods.getArticleCategories();

    if (this.props.match.params.sectionId) {
      if (w <= 1440) {
        this.setState({
          innerwidth: "15%",
          innerwidthBig: "50%",
        });
      } else if (w > 1440) {
        this.setState({
          innerwidth: "10%",
          innerwidthBig: "55%",
        });
      }

      this.setState(
        {
          sectionId: this.props.match.params.sectionId,
        },
        () => {
          this.getSectionDetail();
        }
      );
    }
  }

  getGeneralSectionDetail() {
    const { sectionDetail } = this.context;
    const hasArticle = this.state.section?.section_widgets.filter(
      (widget) => widget.category_name === "Article"
    );
    const hasText = this.state.section?.section_widgets.filter(
      (widget) => widget.category_name === "Text"
    );

    if (hasArticle.length) {
      sectionDetail.methods.getAllWidget();
    }

    if (hasArticle.length || hasText.length) {
      sectionDetail.methods.getPages();
    }

    if (hasArticle.length) {
      sectionDetail.methods.getArticleCategories();
    }

    if (hasText.length) {
      sectionDetail.methods.getAllSections();
    }

    if (hasArticle.length) {
      sectionDetail.methods.getAllEmployees();
    }
  }

  getSectionDetail() {
    const objSection = new SectionApi();

    objSection
      .getDetailSection(this.state.sectionId)
      .then((res) => {
        this.setState({ section: res.data }, this.getGeneralSectionDetail);
      })
      .catch((err) => {
        // alert(err.message)
      });
  }

  render() {
    const { t } = this.props;

    return (
      <LoadScript
        googleMapsApiKey={this.state.gmapsApiKey}
        libraries={this.state.mapsLib}
      >
        <Content>
          <ContentHeader className="content-header">
            {/* <h1 className="left-content"> Section Detail </h1> */}
            <TitlePages
              title={`${t(this.state.transPage + "title")} : Section (${
                this.state.section.name
              })`}
            />
            <div className="right-content"></div>
            <div className="clearfix"></div>
          </ContentHeader>
          <ContentBody>
            <Card>
              <CardHeader>
                <div className="row ">
                  <Button onClick={() => this.props.history.goBack()} className="btn col-md-1 btn-default mt-3" >
                                <i className="fa fa-arrow-left">&nbsp;</i>
                                {t('back')}
                            </Button>
                {this.state.section.menu && (
                  <div style={{  marginLeft: "1rem" }} className="mt-3 sect-menu p-2 ">
                    <CardTitle>
                      <div style={{ width: 100, display: "inline", marginRight: '1rem' }}>{` Section 
                        ${t("menu")}`}
                      </div>
                      <div style={{ display: "inline-block" }}>:</div>
                      <div style={{ display: "inline-block", marginRight: '1rem' }}>
                        <b>
                          &nbsp;
                          {this.state.section.menu
                            ? ` ${this.state.section.menu_name}`
                            : null}
                        </b>
                      </div>
                    </CardTitle>
                    <button
                      title={`To Menu ${this.state.section.menu_name}`}
                      className="btn btn-primary btn-xs"
                      style={{  display: "flex", marginLeft: 'auto' }}
                    >
                      <Link to={`/menu-list/${this.state.section.menu}`}>
                        <i
                          className="fa fa-arrow-right"
                          style={{ color: "white" }}
                        ></i>
                      </Link>
                    </button>
                  </div>
                )}
                </div>
              </CardHeader>
              <CardBody>
                {this.state.section.section_widgets.map((item, index) => {
                  if (item.widget.category_name === "Text") {
                    return (
                      <div key={index}>
                        <TextCard
                          section={item.section}
                          widget={item.widget.id}
                          widgetName={item.name}
                          widgetElementType={item.widget.category_name}
                          widgetElementStatic="Static"
                          data={item}
                        ></TextCard>
                        <hr></hr>
                      </div>
                    );
                  } else if (item.widget.category_name === "Article") {
                    return (
                      <div key={index}>
                        <ArticleCard
                          section={this.state.section}
                          widget={item.widget.id}
                          widgetName={item.name}
                          widgetElementType={item.widget.category_name}
                          widgetElementStatic="Static"
                          data={item}
                          getSectionDetail={this.getSectionDetail}
                        ></ArticleCard>
                        <hr></hr>
                      </div>
                    );
                  } else if (item.widget.category_name === "Media") {
                    return (
                      <div key={index}>
                        <AssetForm
                          section={this.state.section}
                          widget={item.widget.id}
                          widgetName={item.name}
                          widgetElementType={item.widget.category_name}
                          widgetElementStatic="Static"
                          data={item}
                          getSectionDetail={this.getSectionDetail}
                        ></AssetForm>
                        <hr />
                      </div>
                    );
                  } else {
                    return <div></div>;
                  }
                })}
              </CardBody>
            </Card>
          </ContentBody>
        </Content>
      </LoadScript>
    );
  }
}

export default withTranslation()(SectionDetail);
