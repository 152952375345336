import React, { Component } from "react";
import { Modal, Button, Tab, Tabs, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Card, { CardHeader, CardTitle, CardBody } from "../Card";
import swal from "sweetalert";
import AlertHelper from "./../../lib/AlertHelper";
import SectionApi from "../../api/SectionApi";
import { withTranslation } from "react-i18next";
import Context from "../../pages/Context";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
// import { Content, ContentHeader, ContentBody } from "../components/Content"
// import SectionCategoryApi from "./../../api/SectionCategoryApi";
// import GeneralApi from "./../../api/GeneralApi";

class SectionPair extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      names: [],
      sections: [],
      selectedSections: [],
      selectedSection: {},
      catalog: [],

      languageList: [],
      tabKey: 1,
      selectedLanguage: 1,
      renderTabLanguage: "",
      idElement: 0,

      activePage: 1,
      limitPageData: 9,
      category: "",
      totalCount: 0,

      transPage: "modals.section_pair.",
      transAlert: "alert.",
    };

    this.getSectionList = this.getSectionList.bind(this);
    this.getCatalogType = this.getCatalogType.bind(this);
    this.setSelectedItem = this.setSelectedItem.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.pairSection = this.pairSection.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.renderTabLanguage = this.renderTabLanguage.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeSiteLang } = this.context;

    if (activeSiteLang.length > 0) {
      if (this.state.languageList !== activeSiteLang) {
        this.setState({
          languageList: activeSiteLang
        })
      }
    }

  }

  onOpenModal() {
    const { activeSiteLang } = this.context;
    this.setState(
      {
        languageList: activeSiteLang,
      },
      () => {
        this.getSectionList();
        this.getCatalogType();
        this.renderTabLanguage();
      }
    );
  }

  onCloseModal() {
    this.setState(
      {
        sections: [],
        names: [],
      },
      () => {
        this.props.onHide();
      }
    );
  }

  getSectionList() {
    const objSection = new SectionApi();

    objSection
      .sectionCatalogListNew({
        page_size: this.state.limitPageData,
        page: this.state.activePage,
        category: this.state.category,
      })
      .then((res) => {
        this.setState({
          sections: res.data.results,
          totalCount: res.data.count,
        });
      });
  }

  getCatalogType() {
    this.setState({
      catalog: [],
    });

    const sectionObj = new SectionApi();

    sectionObj.getCatalogType().then((res) => {
      this.setState({
        catalog: res.data,
      });
    });
  }

  setSelectedItem(section) {
    this.setState({
      selectedSection: section,
    });
  }

  pairSection() {
    const { t } = this.props;
    const s = new SectionApi();

    const sectionLanguages = this.state.names.map((item) => {
      return {
        language: item.language,
        name: item.name,
        title: item.languageName,
      };
    });

    const dt = {
      page: parseInt(this.props.pageId),
      parent: this.state.selectedSection.id,
      lang: sectionLanguages,
    };

    s.sectionAddNew(dt)
      .then((res) => {
        this.props.getPageDetail(parseInt(this.props.pageId));
        swal(
          "Success !",
          t(this.state.transAlert + "section_pair_add_success"),
          "success"
        );
        this.props.onHide();
        // window.location.reload();
      })
      .catch((err) => {
        const objAlert = new AlertHelper();
        const resErr = err?.response?.data ? err?.response?.data : err.message;
        objAlert.errorHandlerAlert(resErr);
      });
  }

  handleNameChange(id, value, lang) {
    let names = [...this.state.names];
    let name = { ...names[id] };
    let langs = { ...names[lang] };
    name.name = value;
    names[id] = name;
    names[lang] = langs;

    this.setState({
      name: names[0].name,
      names: names,
    });
  }

  handlePageChange(pageNumber) {
    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.getSectionList();
      }
    );
  }

  handleChangeTab(value) {
    this.setState(
      {
        selectedLanguage: value,
        tabKey: value,
      },
      () => {
        this.renderTabLanguage();
      }
    );
  }

  handleCategoryChange(value) {
    const newValue = value !== this.state.category ? value : "";
    this.setState(
      {
        category: newValue,
        activePage: 1,
      },
      () => {
        this.getSectionList();
      }
    );
  }

  renderTabLanguage() {
    const renderTabLanguage = this.state.languageList.map((item, idx) => {
      var findName = this.state.names.find(
        (textLang, idx) => item.id === textLang.language
      );
      return {
        id: item.id,
        language: item?.language?.id,
        languageName: item.name,
        name: findName?.name,
      };
    });

    this.setState(
      {
        names: renderTabLanguage,
      }
    );
  }

  render() {
    const { t } = this.props;
    const TabLanguage = this.state.names.map((item, index) => {
      return (
        <Tab key={index} eventKey={item.language} title={item.languageName}>
          <br></br>
          <div className="form-group">
            <label> {t("name")} </label>
            <textarea
              className="form-control"
              onChange={(event) =>
                this.handleNameChange(
                  index,
                  event.target.value,
                  item.languageName
                )
              }
              value={this.state.names[index].name}
            ></textarea>
          </div>
        </Tab>
      );
    });

    const SectionList = this.state.sections.map((item) => {
      return (
        <div className="col-md-4" key={item.id}>
          <Card className="card card-primary">
            <CardHeader className="">
              <CardTitle>
                <input
                  id={item.name + "-" + item.id}
                  name="selectedSection"
                  className=""
                  type="radio"
                  onClick={(event) => this.setSelectedItem(item)}
                ></input>
                &nbsp;
                <label htmlFor={item.name + "-" + item.id}>{item.name}</label>
              </CardTitle>
              <div className="card-tools">
                <span>&nbsp;</span>
                <Link to={`/section/detail/${item.id}`}>
                  <Button className="btn-sm" variant="default">
                    <i className="fa fa-arrow-right"></i>
                  </Button>
                </Link>
                <span>&nbsp;</span>
              </div>
            </CardHeader>
            <CardBody
              style={{
                backgroundImage: `url("${item.screenshot}")`,
                backgroundColor: "#f1f1f1",
              }}
            >
              <img
                width="100%"
                height={100}
                src={
                  item.screenshot ? `${item.screenshot}` : "/img/bz-publish.svg"
                }
                alt={item.name}
              ></img>
            </CardBody>
          </Card>
        </div>
      );
    });

    const SectionCategoriesItems = this.state.catalog.map((item) => {
      //   const langId = localStorage.getItem("lang") === "en" ? 0 : 1;
      return (
        <ListGroup.Item
          key={item.id + item.name}
          onClick={() => this.handleCategoryChange(item.id)}
          active={item.id === this.state.category}
          action
        >
          {item}
        </ListGroup.Item>
      );
    });

    return (
      <Modal
        show={this.props.show}
        onHide={() => this.onCloseModal()}
        onShow={() => this.onOpenModal()}
        size="xl"
      >
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title>{t(this.state.transPage + "section_pair")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-3">
              <h5 className="mb-4">
                {t(this.state.transPage + "section_category")}
              </h5>
              <ListGroup>{SectionCategoriesItems}</ListGroup>
            </div>
            <div className="col-9">
              {/* <div className="form-group">
                          <label> Name </label>
                          <input type="text" className="form-control" value={this.state.name} onChange={(event) => this.handleNameChange(event.target.value)}></input>
                      </div> */}
              {/* { JSON.stringify(this.state.names)} */}
              <Tabs>{TabLanguage}</Tabs>

              <Tabs
                className="mb-2"
                defaultActiveKey="all"
                id="uncontrolled-tab-example"
              >
                <Tab eventKey="home" title="Used Sections"></Tab>
                <Tab eventKey="profile" title="Favorite Sections"></Tab>
                <Tab eventKey="contact" title="My Private Sections"></Tab>
                <Tab eventKey="all" title="All Sections"></Tab>
              </Tabs>

              <div className="row">{SectionList}</div>
              <div className="d-flex align-items-center">
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.limitPageData}
                  totalItemsCount={this.state.totalCount}
                  pageRangeDisplayed={4}
                  onChange={this.handlePageChange.bind(this)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
                <p className="ml-3">
                  {t("total")} {this.state.totalCount}
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => this.pairSection()}>
            <i className="fa fa-plus">&nbsp;</i>
            {t(this.state.transPage + "pair_section")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(SectionPair);
