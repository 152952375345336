import React, { Component } from 'react';
import swal from "sweetalert";
import { Tab, Nav, Button } from "react-bootstrap"
import Card, { CardHeader, CardBody } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content"
import Context from "./Context";
import Flag from "react-flags"
import axios from "axios"

// import writeJsonFile from "write-json-file"
// import SortableTree from "react-sortable-tree";
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

import LanguageApi from "./../api/LanguageApi"
import TitlePages from "./Elements/TitlePages"

// import { shortSentence } from "./../lib/StringHelper";
import { withTranslation } from "react-i18next";

// import LanguageItemAddModal from "./../components/Modal/LanguageItemAddModal"
// import LanguageItemDeleteConfirm from "./../components/Modal/LanguageItemDeleteConfirm"

class LanguageJSON extends Component {
    static contextType = Context;
    constructor(props) {
        super(props)

        this.state = {

            languageList: [],
            languageFlag:[],

            selectLang: {
                json:{foo:"bar"}
            },
            selectedLanguage: "en",
            selectedKey: "",
            jsonLanguage: {},

            treeData: [],
            showModal: false,
            showConfirm: false,
            methodModal: "Add",

            selectedFlag:"", // add lang 
            selectedLanguageName:"", // add lang
            abbreviationAdd:""
        }

        this.getLanguages = this.getLanguages.bind(this)
        this.fetchJSON = this.fetchJSON.bind(this)
        this.downloadJsonFile = this.downloadJsonFile.bind(this)
        // this.setTreeData = this.setTreeData.bind(this)

        this.setShowModal = this.setShowModal.bind(this)
        this.jsonUpdate = this.jsonUpdate.bind(this)
        this.getJsonLang = this.getJsonLang.bind(this)

        this.getFlag = this.getFlag.bind(this)

        this.searchLanguage = this.searchLanguage.bind(this)

        this.handleSelectedFlagAdd = this.handleSelectedFlagAdd.bind(this)
    }

    componentDidMount() {
        this.getLanguages()
        this.getJsonLang()
        this.getFlag()
       
    }

    setShowModal() {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    setShowConfirm() {
        this.setState({
            showConfirm: !this.state.showConfirm
        })
    }

    setSelelectedLanguage(selectedLanguage) {
        this.setState({
            selectedLanguage: selectedLanguage.abbreviation,
            selectLang: selectedLanguage
        }, () => {
            this.getJsonLang()
        })
    }

    getLanguages() {
        const { languagesList } = this.context

        this.setState({
            languageList: languagesList,

        }, () => {
            this.setSelelectedLanguage(languagesList[0])
        })

    }

    fetchJSON() {

        fetch(`/locales/default/translation.json`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(res => {

                return res.json()
            })
            .then(res => {
                // alert(JSON.stringify(res))
                this.setState({
                    jsonLanguage: res
                }, () => {
                    // this.setTreeData()
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    jsonUpdate() {
        const objLang = new LanguageApi()
        const { t } = this.props;

        objLang.languageUpdate(this.state.selectLang.id, {
            ...this.state.selectLang,
            json_field: this.state.jsonLanguage
        })
            .then(res => {
                swal(
                    "Success !",
                    t("you successfully update language"),
                    "success"
                );
            })
            .catch(err => {
                console.log("err => ", err)
            })
    }

    jsonAdd( ) { 
        const objLang = new LanguageApi()
        const { t } = this.props;

        objLang.languageAdd({
            name:this.state.selectLang.abbreviation.toLowerCase,// according to selected flag => change to abbreviation from name
            flag:this.state.selectLang.abbreviation,
            abbreviation:this.state.selectLang.abbreviation,
            json_field: this.state.jsonLanguage
        })
            .then(res => {
                swal(
                    "Success !",
                    t("you successfully add language"),
                    "success"
                );

                setTimeout(function(){
                    window.location.reload()
                },2000)
            })
            .catch(err => {
                console.log("err => ", err)
            })
    }

    getFlag() { 
        
        axios.get("https://unpkg.com/country-flag-emoji-json@1.0.2/json/flag-emojis.pretty.json")
        .then(res => {
            this.setState({
                languageFlag:res.data 
            })
        })
    }

    getJsonLang() {
        const objLang = new LanguageApi()

        objLang.getDetailLanguage(this.state.selectLang?.id ? this.state.selectLang.id : 1)
            .then(res => {
                this.setState({
                    jsonLanguage: res.data.json_field
                })
            })
            .catch(err => {
                console.log("err => ", err)
            })
    }

    // form add 
    handleSelectedFlagAdd(value) { 
        this.setState({
            selectedFlag:value,
        },() => {
            this.searchLanguage()
        })
    }

    // form add 
    handleChangeAbbreviation(value) { 
        this.setState({
            abbreviationAdd:value
        })
    }

    searchLanguage() { 
        const search = this.state.languageFlag.filter(item => {
            return item.code  === this.state.selectedFlag
        })

        // alert(JSON.stringify(search))

        this.setState({
            selectedLanguage: search.code,
            // selectLang:{ foo:"bar"}
            selectLang: { 
                name:search[0].name || "thisName",
                abbreviation:search[0].code || "thisCode",
                json:{ }
            }
        },() => {
            // alert(JSON.stringify(this.state.selectLang))
        })
    }

    downloadJsonFile() {
        const fileData = JSON.stringify(this.state.jsonLanguage);
        const blob = new Blob([fileData], { type: "text/json" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = "translation-" + this.state.selectedLanguage + '.json';
        link.href = url;
        link.click();
    }

    render() {

        const TabLanguages = this.state.languageList.map(item => {
            return (<Tab.Pane eventKey={item.abbreviation}>
                <div className="row">
                    <div className="col-md-6"> 
                        
                        <div className="form-group">
                            <label> Language Name </label>
                            <select disabled className="form-control" value={item.flag} 
                                onChange={() => {}}
                            >
                                <option value=""> -- Please Select Flag -- </option>
                                { this.state.languageFlag.map(item => {
                                    return (<option value={item.code}>{ item.code} - { item.name }</option>)
                                })}
                            </select>
                        </div>

                       
                        
                    </div >
                    {/* <div className="col-md-6" >
                       
                        <div className="form-group">
                            <label> Abbreviation </label>
                            <input type="text" value={item.abbreviation} onChange={() => {}} className="form-control" ></input>
                        </div>
                    </div> */}
                    {/* <button className="btn btn-sm btn-outline-primary" style={{ margin: "20px 0", float: "right", }}>
                        <i className="fa fa-upload"> &nbsp; </i> Upload JSON
                    </button> */}
                    
                   
                    <div className="clearfix" style={{ margin: "0 1%" }}></div>
                   
                    <JSONInput
                        id={'a_unique_' + item.id}
                        locale={locale}
                        height='550px'
                        width="100%"
                        placeholder={this.state.jsonLanguage}
                        onChange={(res) => {
                            this.setState({
                                jsonLanguage: res.jsObject
                            })
                        }}
                    />

                    <button onClick={() => this.jsonUpdate()} className="btn btn-sm btn-outline-primary" style={{ margin: "20px 0", float: "right", }}>
                        <i className="fa fa-edit">&nbsp;</i>Update JSON
                    </button>
                </div>
            </Tab.Pane>)
        })

        /*
            <Nav.Item  className="" style={{ marginRight:10 }} > 
                <Nav.Link eventKey="GB" className="btn btn-xs bg-secondary" style={{padding:"2px 10px"}}>
                    <Flag name="GB" format="png" pngSize={24} shiny={false} alt="Great Britain Flag" basePath="/img/flags" title="Vietnam" /> English 
                </Nav.Link>
            </Nav.Item>
        */

        const NavLanguages = this.state.languageList.map(item => {
            return (<Nav.Item className="" style={{ marginRight: 10 }} onClick={() => this.setSelelectedLanguage(item)}>
                <Nav.Link eventKey={item.abbreviation} className="btn btn-xs bg-primary" style={{ padding: "2px 10px" }} >
                    <Flag name={item.flag} format="png" pngSize={24} shiny={false} alt="Vietnam Flag" basePath="/img/flags" title="English" /> {item.name}
                </Nav.Link>
            </Nav.Item>
            )
        })

        return (
                <Content>
                    <ContentHeader>
                    <TitlePages title="Languages" />
                    </ContentHeader>
                    <ContentBody>
                        <Card>

                            <Tab.Container id="left-tabs-example" defaultActiveKey={this.state.selectedLanguage}>
                                <CardHeader>
                                    <div className="row">
                                        <div className="col-md-9">
                                            <div className="float-left" style={{ marginRight: 10 }}>Preview</div>
                                            <Nav variant="pills">
                                                {NavLanguages}
                                                <Nav.Item style={{ marginRight: 10 }} title="add new language" onClick={() => {
                                                    this.setState({
                                                        jsonLanguage: {},
                                                        selectLang:{}
                                                    },() => {
                                                        this.fetchJSON()
                                                    })
                                                }}>
                                                    <Nav.Link eventKey="New" className="btn btn-xs bg-primary" style={{ padding: "5px 10px" }}>
                                                        <i className="fas fa-plus"></i>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                        <div className="col-md-3">
                                            <button className="float-right btn btn-sm btn-primary" onClick={() => this.downloadJsonFile()}>
                                                <i className="fa fa-download"></i> Download Json
                                            </button>
                                        </div>

                                    </div>

                                </CardHeader>
                                <CardBody>                                     
                                           
                                    {/* <SortableTree
                                        treeData={this.state.treeData}
                                        onChange={(treeData) => this.setState({ treeData })}
                                        onMoveNode={() => {

                                        }}
                                        generateNodeProps={({ node }) => ({
                                            title: (
                                                <div>
                                                    <span className="float-left">
                                                        Key : {node.title ? node.title : "undefined"}
                                                    </span>
                                                    <div className='float-right'>
                                                        <button
                                                            title={"add_language_item"}
                                                            className='btn btn-success btn-xs'
                                                            style={{ margin: "0 5px" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    methodModal: "Add Item ",
                                                                    selectedKey: node.title,
                                                                }, () => {
                                                                    this.setShowModal()
                                                                })
                                                            }}
                                                        >
                                                            <i className='fa fa-plus'></i>
                                                        </button>
                                                        <button
                                                            title={"update_this_item"}
                                                            className='btn btn-primary btn-xs'
                                                            style={{ margin: "0 5px" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    methodModal: "Update",
                                                                    selectedKey: node.title,
                                                                }, () => {
                                                                    this.setShowModal()
                                                                })
                                                            }}
                                                        >
                                                            <i className='fa fa-edit'></i>
                                                        </button>
                                                        <Button
                                                            title={"delete_this_item"}
                                                            variant='danger'
                                                            className='btn-xs'
                                                            style={{ margin: "0 5px" }}
                                                            onClick={() => {
                                                                //this.jsonUpdate()
                                                            }}
                                                        >
                                                            <i className='fa fa-trash'></i>
                                                        </Button>
                                                    </div>
                                                </div>
                                            ),
                                        })}
                                        style={{}}
                                    /> */}
                                       
                                    <Tab.Content >
                                        {TabLanguages}
                                        <Tab.Pane eventKey="New">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    
                                                    <div className="form-group">
                                                        <label> Language Flags </label>
                                                        <select className="form-control" 
                                                            onChange={(event) => this.handleSelectedFlagAdd(event.target.value)} 
                                                            value={this.state.selectedFlag}
                                                        >
                                                            <option value=""> -- Please Select Flag -- </option>
                                                            { this.state.languageFlag.map(item => {
                                                                return (<option value={item.code}>{ item.code} - { item.name }</option>)
                                                            })}
                                                        </select>
                                                        { JSON.stringify(this.state.selectLang)}
                                                    </div>

                                                    { /* https://unpkg.com/country-flag-emoji-json@1.0.2/json/flag-emojis.pretty.json */ }
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label> Abbreviation </label>
                                                        <input type="text" onChange={(event) => { this.handleChangeAbbreviation(event.target.value)}} className="form-control" ></input>
                                                    </div>
                                                </div>  */}

                                            </div>
                                            <div className="row">
                                                {/* <button className="btn btn-sm btn-outline-primary" style={{ margin: "20px 0", float: "right", }}>
                                                    <i className="fa fa-upload"> &nbsp; </i> Upload JSON
                                                </button> */}
                                                <div className="clearfix" style={{ margin: "0 1%" }}></div>
                                                <JSONInput
                                                    id={'a_unique_add'}
                                                    locale={locale}
                                                    height='550px'
                                                    width="100%"
                                                    placeholder={this.state.jsonLanguage}
                                                    onChange={(res) => {
                                                        this.setState({
                                                            jsonLanguage: res.jsObject
                                                        })
                                                    }}
                                                />

                                                <Button
                                                    onClick={() => {this.jsonAdd()}}
                                                    className="btn btn-sm "
                                                    style={{ margin: "20px 0", float: "right", }}
                                                >
                                                    <i className="fa fa-edit">&nbsp;</i>Add JSON
                                                </Button>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>

                                </CardBody>

                            </Tab.Container>
                        </Card>

                        {/* <LanguageItemAddModal
                            show={this.state.showModal}
                            methodModal={this.state.methodModal}
                            onHide={this.setShowModal}
                            selectedKey={this.state.selectedKey}
                           
                        >

                        </LanguageItemAddModal> */}
                        {/* <LanguageItemDeleteConfirm
                            show={this.state.showConfirm}
                            onHide={this.setShowConfirm}
                            selectedKey={this.state.selectedKey}
                        >

                        </LanguageItemDeleteConfirm> */}
                    </ContentBody>
                </Content>
        );
    }
}

export default withTranslation()(LanguageJSON);
