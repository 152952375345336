import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"
import { withTranslation } from 'react-i18next';

class ArticleDeleteConfirm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            transPage: "modals.article_delete."
        }

        this.deleteSelectedArticle = this.deleteSelectedArticle.bind(this)
        this.onOpenModal = this.onOpenModal.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)
        // this.onHideModal = this.onHideModal.bind(this)
    }

    deleteSelectedArticle() {
        // dari props method nya
        this.props.deleteSelectedArticle()
    }

    onOpenModal() {

    }

    onCloseModal() {
        this.props.onHide()
    }


    render() {
        const { t } = this.props;
        const listArticleName = this.props.selectedArticles.map(item => {
            return (<li key={item.id}>{item?.article?.name || '-'}</li>)
        })

        return (
            <Modal show={this.props.show} onHide={() => this.onCloseModal()} onShow={() => this.onOpenModal()}>
                {this.props.loadingModal}
                <Modal.Header closeButton className="bg-danger">
                    <Modal.Title>{t(this.state.transPage + "title")} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p> {t(this.state.transPage + "confirmation_text")} </p>
                    <ul>
                        {listArticleName}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.props.onHide()}>
                        {t('cancel')}
                    </Button>
                    <Button variant="danger" onClick={() => this.deleteSelectedArticle()}>
                        <i className="fa fa-trash">&nbsp;</i>
                        {t(this.state.transPage + "delete_article")}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(ArticleDeleteConfirm);
