import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";

import JobApi from "./../../api/JobApi";
import { withTranslation } from "react-i18next";

class KeywordAddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      transPage: "modals.keyword_add.",
      transAlert: "alert.",
    };

    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);

    this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
    this.addKeyword = this.addKeyword.bind(this);
  }

  onOpenModal() {
    this.setState({
      name: "",
    });
  }

  onCloseModal() {
    this.props.callback();
    this.props.onHide();
  }

  handleChangeKeyword(value) {
    this.setState({
      name: value,
    });
  }

  addKeyword() {
    const { t } = this.props;
    const obJob = new JobApi();

    const id = localStorage.getItem("siteId");

    const request = {
      site: id,
      name: this.state.name,
    };

    obJob
      .addKeyword(request)
      .then((res) => {
        swal(
          "Success!",
          t(this.state.transAlert + "keyword_add_success"),
          "success"
        );
        this.onCloseModal();
      })
      .catch((err) => {
        swal("Error!", err.message, "error");
      });
  }

  render() {
    const { t } = this.props;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onShow={this.onOpenModal}
      >
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title> {t(this.state.transPage + "add_keyword")} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label> {t("keyword")} </label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.keyword}
                  onChange={(event) =>
                    this.handleChangeKeyword(event.target.value)
                  }
                ></input>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => this.addKeyword()}>
            <i className="fa fa-plus">&nbsp;</i>
            {t(this.state.transPage + "add_keyword")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(KeywordAddModal);
