export function myGetYear(date) {
    return date.getFullYear()
}

export function getMonth(date) {
    return date.getMonth() | new Date(date).getMonth()
} 

export function myRange(start, stop, step=1) {
    var result = []
    for(var i = start; i <= stop; i+= step){
        result.push(i)
    }

    return result
}