import React, { Component } from "react";
import { Collapse, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import JobApi from "../../api/JobApi";
import Card, { CardHeader, CardBody } from "../../components/Card";
import {
    Content,
    ContentHeader,
    ContentBody,
} from "./../../components/Content";

import ApplicantArchiveConfirm from "./ApplicantArchiveConfirm";

import CardBorder from "./../../components/CardBorder";

import "./ApplicationDetail.css";

import swal from "sweetalert";
import { withTranslation } from "react-i18next";
import UserContext from "../Context";
import config from "../../config/config";
import ReactGA from "react-ga";

class ApplicantDetail extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            applicant: {},
            applicantApplied: [],

            appliedToggle: true,
            biodataToggle: true,
            portfolioToggle: true,
            experienceToggle: true,
            educationToggle: true,

            applicantArchiveConfirm: false,
            selectedApplicant: [],
            transPage: "pages.applicant_detail.",
            transAlert: "alert.",
        };

        this.getApplicantDetail = this.getApplicantDetail.bind(this);
        this.getApplicantApplied = this.getApplicantApplied.bind(this);

        this.setBiodataToggle = this.setBiodataToggle.bind(this);
        this.setAppliedToggle = this.setAppliedToggle.bind(this);
        this.setAttachmentToggle = this.setAttachmentToggle.bind(this);
        this.setExperienceToggle = this.setExperienceToggle.bind(this);
        this.setEducationToggle = this.setEducationToggle.bind(this);

        this.setShowArchiveConfirm = this.setShowArchiveConfirm.bind(this);
        this.archiveSelectedApplicant = this.archiveSelectedApplicant.bind(
            this
        );
    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        this.getApplicantDetail();
        this.getApplicantApplied();
    }

    setAppliedToggle() {
        this.setState({
            appliedToggle: !this.state.appliedToggle,
        });
    }

    setBiodataToggle() {
        this.setState({
            biodataToggle: !this.state.biodataToggle,
        });
    }

    setAttachmentToggle() {
        this.setState({
            portfolioToggle: !this.state.portfolioToggle,
        });
    }

    setExperienceToggle() {
        this.setState({
            experienceToggle: !this.state.experienceToggle,
        });
    }

    setEducationToggle() {
        this.setState({
            educationToggle: !this.state.educationToggle,
        });
    }

    getApplicantApplied() {
        const obJob = new JobApi();

        const params = {
            applicant: this.props.match.params.applicantId,
        };

        obJob
            .getApplicationList(params)
            .then((res) => {

                this.setState({
                    applicantApplied: res.data.results,
                });
            })

            .catch((err) => {
                console.log(err.message);
            });
    }

    getApplicantDetail() {
        const obJob = new JobApi();

        const applicantId = this.props.match.params.applicantId;

        obJob
            .getApplicantDetail(applicantId)
            .then((res) => {
                this.setState({
                    applicant: res.data,
                });
            })
            .catch((err) => {
                console.log("err => ", err);
            });
    }

    setShowArchiveConfirm() {
        this.setState({
            applicantArchiveConfirm: !this.state.applicantArchiveConfirm,
        });
    }

    handleSelectedApplicant(applicant, value) {
        var lastSelected = [...this.state.selectedApplicant];
        var indexDelete = -1;
        applicant = {
            id: applicant.id,
            first_name: applicant.user.first_name,
            last_name: applicant.user.last_name,
        };

        // var stateSelected = ""

        if (value === true && applicant.id !== lastSelected[0]?.id) {
            lastSelected.push(applicant);
        } else {
            this.state.selectedApplicant.forEach((item, index) => {
                if (item.id === applicant.id) {
                    indexDelete = index;
                }
            });

            lastSelected.splice(indexDelete, 1);
        }

        this.setState(
            {
                selectedApplicant: lastSelected,
            },
            () => {
                if (this.state.selectedApplicant.length > 0) {
                    this.setShowArchiveConfirm();
                }

                // if (this.state.selectedApplicant.length > 0) {
                //     this.setState({
                //         deleteDisable: false,
                //     });
                // } else {
                //     this.setState({
                //         deleteDisable: true,
                //     });
                // }
            }
        );
    }

    archiveSelectedApplicant(selectedApplicant) {
        const { t } = this.props;
        const obj = new JobApi();

        selectedApplicant = selectedApplicant.map((item) => {
            return item.id;
        });

        obj.AppicantArchieveMulti(selectedApplicant, false)
            .then((res) => {
                swal(
                    "Success",
                    t(this.state.transAlert + "archive_applicant_success"),
                    "success"
                );

                setTimeout(function () {
                    window.location.reload();
                }, 2000);
            })
            .catch((err) => {});
    }

    render() {
        const { t } = this.props;
        const { employee_privileges } = this.context;
        const isOnlyMonitor =
            employee_privileges.filter((e) => e.id === 5).length > 0;

        const applicant = this.state.applicant;

        var image = this.state.applicant.avatar
            ? this.state.applicant.avatar
            : `${process.env.PUBLIC_URL}/img/bz-publish.svg`;

        const biodataToggle = this.state.biodataToggle ? (
            <button
                title="minimize"
                onClick={this.setBiodataToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-minimize"></span>
            </button>
        ) : (
            <button
                title="maximize"
                onClick={this.setBiodataToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-maximize"></span>
            </button>
        );

        const appliedToggle = this.state.appliedToggle ? (
            <button
                title="minimize"
                onClick={this.setAppliedToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-minimize"></span>
            </button>
        ) : (
            <button
                title="maximize"
                onClick={this.setAppliedToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-maximize"></span>
            </button>
        );

        const portfolioToggle = this.state.portfolioToggle ? (
            <button
                title="minimize"
                onClick={this.setAttachmentToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-minimize"></span>
            </button>
        ) : (
            <button
                title="maximize"
                onClick={this.setAttachmentToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-maximize"></span>
            </button>
        );

        const experienceToggle = this.state.experienceToggle ? (
            <button
                title="minimize"
                onClick={this.setExperienceToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-minimize"></span>
            </button>
        ) : (
            <button
                title="maximize"
                onClick={this.setExperienceToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-maximize"></span>
            </button>
        );

        const educationToggle = this.state.educationToggle ? (
            <button
                title="minimize"
                onClick={this.setEducationToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-minimize"></span>
            </button>
        ) : (
            <button
                title="maximize"
                onClick={this.setEducationToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-maximize"></span>
            </button>
        );

        const Applied = (
            <Card>
                <CardHeader className="bg-primary">
                    <i className="fa fa-font">&nbsp;</i>{" "}
                    <b> {t("applied_job")} </b>
                    <div className="card-tools">{appliedToggle}</div>
                </CardHeader>
                <Collapse
                    in={this.state.appliedToggle}
                    style={{ padding: "1.25rem" }}
                >
                    <CardBody>
                        <div className="row">
                            {this.state.applicantApplied.map((item, index) => {
                                return (
                                    <CardBorder
                                        key={index}
                                        href={`/job-poster/detail/${item.job_poster}`}
                                        title={item.job_title}
                                        description={item?.position_name}
                                        confirmation_name={
                                            item?.confirmation_name
                                        }
                                    ></CardBorder>
                                );
                            })}
                        </div>
                    </CardBody>
                </Collapse>
            </Card>
        );

        var Portfolio = "";
        if (applicant.attachments?.length > 0) {
            Portfolio = (
                <Card>
                    <CardHeader className="bg-primary">
                        <i className="fa fa-file-powerpoint">&nbsp;</i>{" "}
                        <b> {t("portfolio")} </b>
                        <div className="card-tools">{portfolioToggle}</div>
                    </CardHeader>
                    <Collapse
                        in={this.state.portfolioToggle}
                        style={{ padding: "1.25rem" }}
                    >
                        <CardBody>
                            <div className="row">
                                {applicant.attachments.map((item) => {
                                    var file_type = "";
                                    var content_box = (
                                        <i
                                            className="fa fa-times centered"
                                            title="no attachment"
                                            style={{ color: "red" }}
                                        >
                                            {" "}
                                        </i>
                                    );
                                    if (item.attachment_file) {
                                        file_type = item.attachment_file.substring(
                                            item.attachment_file.lastIndexOf(
                                                "."
                                            )
                                        );

                                        content_box = (
                                            <b
                                                className="centered"
                                                style={{ color: "black" }}
                                            >
                                                {item.attachment_file.substring(
                                                    item.attachment_file.lastIndexOf(
                                                        "."
                                                    )
                                                )}{" "}
                                            </b>
                                        );
                                        if (
                                            file_type === ".jpg" ||
                                            file_type === ".png" ||
                                            file_type === ".jpeg"
                                        ) {
                                            content_box = (
                                                <img
                                                    src={item.attachment_file}
                                                    alt="attachment"
                                                    width={"100%"}
                                                    height={"100%"}
                                                    className="centered-image"
                                                ></img>
                                            );
                                        }
                                    }

                                    if (item.attachment_type === "Portfolio") {
                                        // return (JSON.stringify( item ))
                                        return (
                                            <a
                                                href={item.attachment_file}
                                                style={{
                                                    display: "inline-block",
                                                    marginRight: 20,
                                                }}
                                                className="border"
                                            >
                                                <div
                                                    style={{
                                                        display: "inline-block",
                                                        width: "5rem",
                                                        height: "5rem",
                                                        margin: ".25rem",
                                                        backgroundColor:
                                                            "#f5f5f5",
                                                    }}
                                                    className="border box-asset"
                                                >
                                                    {content_box}
                                                </div>
                                            </a>
                                        );
                                    }

                                    return null
                                })}
                            </div>
                        </CardBody>
                    </Collapse>
                </Card>
            );
        }

        var Resume = "";
        if (applicant.attachments?.length > 0) {
            Resume = (
                <Card>
                    <CardHeader className="bg-primary">
                        <i className="fa fa-file">&nbsp;</i>{" "}
                        <b> {t("resume")} </b>
                        <div className="card-tools">{portfolioToggle}</div>
                    </CardHeader>
                    <Collapse
                        in={this.state.portfolioToggle}
                        style={{ padding: "1.25rem" }}
                    >
                        <CardBody>
                            <div className="row">
                                {applicant.attachments.map((item) => {
                                    var file_type = "";
                                    var content_box = (
                                        <i className="fa fa-times"> </i>
                                    );
                                    if (item.attachment_file) {
                                        file_type = item.attachment_file.substring(
                                            item.attachment_file.lastIndexOf(
                                                "."
                                            )
                                        );

                                        content_box = (
                                            <b
                                                className="centered"
                                                style={{ color: "black" }}
                                            >
                                                {item.attachment_file.substring(
                                                    item.attachment_file.lastIndexOf(
                                                        "."
                                                    )
                                                )}{" "}
                                            </b>
                                        );
                                        if (
                                            file_type === ".jpg" ||
                                            file_type === ".png" ||
                                            file_type === ".jpeg"
                                        ) {
                                            content_box = (
                                                <img
                                                    src={item.attachment_file}
                                                    alt="attachment"
                                                    width={"100%"}
                                                    height={"100%"}
                                                    className="centered-image"
                                                ></img>
                                            );
                                        }
                                    }

                                    if (item.attachment_type === "Resume") {
                                        // return (JSON.stringify( item ))
                                        return (
                                            <a
                                                href={item.attachment_file}
                                                style={{
                                                    display: "inline-block",
                                                    marginRight: 20,
                                                }}
                                                className="border"
                                            >
                                                <div
                                                    style={{
                                                        display: "inline-block",
                                                        width: "5rem",
                                                        height: "5rem",
                                                        margin: ".25rem",
                                                        backgroundColor:
                                                            "#f5f5f5",
                                                    }}
                                                    className="border box-asset"
                                                >
                                                    {content_box}
                                                </div>
                                            </a>
                                        );
                                    }

                                    return null
                                })}
                            </div>
                        </CardBody>
                    </Collapse>
                </Card>
            );
        }

        var Experience = "";
        if (applicant.experiences?.length > 0) {
            Experience = (
                <Card>
                    <CardHeader className="bg-primary">
                        <i className="fa fa-vial">&nbsp;</i>{" "}
                        <b> {t("experience")} </b>
                        <div className="card-tools">{experienceToggle}</div>
                    </CardHeader>
                    <Collapse
                        in={this.state.experienceToggle}
                        style={{ padding: "1.25rem" }}
                    >
                        <div clasName="row">
                            <ListGroup variant="flush">
                                {applicant.experiences.map((item) => {
                                    var date_start = new Date(item.date_start);
                                    var start_month = date_start.toLocaleString(
                                        "default",
                                        { month: "long" }
                                    );
                                    var start_year = date_start.getFullYear();

                                    var date_finish = new Date(
                                        item.date_finish
                                    );
                                    var finish_month = date_finish.toLocaleString(
                                        "default",
                                        { month: "long" }
                                    );
                                    var finish_year = date_finish.getFullYear();

                                    return (
                                        <ListGroup.Item>
                                            <h5>{item.title}</h5>
                                            <div>{item.company}</div>
                                            <div
                                                style={{
                                                    color: "grey",
                                                    fontSize: 12,
                                                }}
                                            >
                                                {`${start_month} ${start_year}`}{" "}
                                                -{" "}
                                                {`${finish_month} ${finish_year}`}
                                            </div>
                                            <div>&nbsp;</div>
                                            <p>{item.description}</p>
                                        </ListGroup.Item>
                                    );
                                })}
                            </ListGroup>
                        </div>
                    </Collapse>
                </Card>
            );
        }

        var Education = "";
        if (applicant.educations?.length > 0) {
            Education = (
                <Card>
                    <CardHeader className="bg-primary">
                        <i className="fa fa-university">&nbsp;</i>{" "}
                        <b> {t("education")} </b>
                        <div className="card-tools">{educationToggle}</div>
                    </CardHeader>
                    <Collapse
                        in={this.state.educationToggle}
                        style={{ padding: "1.25rem" }}
                    >
                        <div clasName="row">
                            <ListGroup variant="flush">
                                {applicant.educations.map((item) => {
                                    var date_start = new Date(item.date_start);
                                    var start_month = date_start.toLocaleString(
                                        "default",
                                        { month: "long" }
                                    );
                                    var start_year = date_start.getFullYear();

                                    var date_finish = new Date(
                                        item.date_finish
                                    );
                                    var finish_month = date_finish.toLocaleString(
                                        "default",
                                        { month: "long" }
                                    );
                                    var finish_year = date_finish.getFullYear();

                                    return (
                                        <ListGroup.Item>
                                            <h5>{item.title}</h5>
                                            <div>{item.company}</div>
                                            <div
                                                style={{
                                                    color: "grey",
                                                    fontSize: 12,
                                                }}
                                            >
                                                {`${start_month} ${start_year}`}{" "}
                                                -{" "}
                                                {`${finish_month} ${finish_year}`}
                                            </div>
                                            <div>&nbsp;</div>
                                            <p>{item.description}</p>
                                        </ListGroup.Item>
                                    );
                                })}
                            </ListGroup>
                        </div>
                    </Collapse>
                </Card>
            );
        }

        var ArchiveButton = (
            <button
                disabled={isOnlyMonitor}
                className="btn btn-primary inline-block"
                onClick={() => {
                    this.handleSelectedApplicant(this.state.applicant, true);
                }}
            >
                {t("archive")}
            </button>
        );

        if (!this.state.applicant.active) {
            ArchiveButton = (
                <button disabled className="btn btn-secondary inline-block">
                    {" "}
                    Archived{" "}
                </button>
            );
        }

        return (
            <Content>
                <ContentHeader>
                    <h1 style={{ float: "left" }}>
                        {" "}
                        {t(this.state.transPage + "title")}{" "}
                    </h1>
                    <div
                        style={{ float: "right", width: "10%", marginRight: 5 }}
                    >
                        <Link
                            className="btn btn-default btn-block inline-block"
                            to="/job-poster"
                        >
                            {t("back")}
                        </Link>
                    </div>
                    <div style={{ float: "right", width: "6%" }}>
                        {ArchiveButton}
                    </div>
                    <div className="clearfix"></div>
                </ContentHeader>
                <ContentBody>
                    <Card>
                        <CardHeader className="bg-primary">
                            <i className="fa fa-user">&nbsp;</i>{" "}
                            <b>{t("biodata")}</b>
                            <div className="card-tools">{biodataToggle}</div>
                        </CardHeader>
                        <Collapse
                            in={this.state.biodataToggle}
                            style={{ padding: "1.25rem" }}
                        >
                            <CardBody>
                                <div className="row">
                                    <div className="col-md-2">
                                        <img src={image} width={"100%"} alt=""></img>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-9">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label> {t("name")} </label>
                                                <div>
                                                    {applicant.user
                                                        ?.first_name +
                                                        applicant.user
                                                            ?.last_name || "-"}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <label> {t("gender")} </label>
                                                <div>
                                                    {applicant.gender || "-"}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <label> {t("birthday")} </label>
                                                <div>
                                                    {applicant.birth_date ||
                                                        "-"}
                                                </div>
                                            </div>
                                        </div>

                                        <div>&nbsp;</div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label> {t("email")} </label>
                                                <div>
                                                    {applicant.user?.email ||
                                                        "-"}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <label>
                                                    {" "}
                                                    {t("phone_number")}{" "}
                                                </label>
                                                <div>
                                                    {applicant.phone || "-"}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <label> {t("country")} </label>
                                                <div>
                                                    {applicant.country_name}
                                                </div>
                                            </div>
                                        </div>

                                        <div>&nbsp;</div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label> {t("bio")} </label>
                                                <div>
                                                    {" "}
                                                    {applicant.bio || "-"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Collapse>
                    </Card>
                    <div className="row">
                        <div className="col-md-6">{Experience}</div>
                        <div className="col-md-6">{Education}</div>
                    </div>
                    {Resume}
                    {Portfolio}
                    {Applied}
                    <ApplicantArchiveConfirm
                        selectedApplicant={this.state.selectedApplicant}
                        archiveSelectedApplicant={this.archiveSelectedApplicant}
                        getApplicantList={() => {}}
                        show={this.state.applicantArchiveConfirm}
                        onHide={this.setShowArchiveConfirm}
                    ></ApplicantArchiveConfirm>
                </ContentBody>
            </Content>
        );
    }
}

export default withTranslation()(ApplicantDetail);
