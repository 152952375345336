import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faListUl } from "@fortawesome/free-solid-svg-icons";
import ValueContext from '../../components/section/Context'

import classes from './TitlePages.module.css'

export default class TitlePages extends Component {
    static contextType = ValueContext
    constructor(props) {
        super(props)

        this.state = {
        }
    }
    render() {
        const contextValue = this.context

        let icon = contextValue.showSidebar ?
                <FontAwesomeIcon data-widget="pushmenu" onClick={() => contextValue.showingSidebar()} color="#fff" className={classes.iconList} icon={faListUl} size="lg" />
                :
                <FontAwesomeIcon data-widget="pushmenu" onClick={() => contextValue.showingSidebar()} color="#fff" className={classes.iconList} icon={faListUl} size="lg" />

        return (
            <div className={classes.topContent}>
                {icon}
                <span>{this.props.title}</span>
            </div>
        )
    }
}
