import React, { Component } from "react";
import { Table, Button, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from 'lodash';

import Card, { CardBody } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import { FaTrashAlt, FaPencilAlt } from "react-icons/fa";

import TitlePages from "./Elements/TitlePages";
// import UserAddModal from "../components/Modal/UserAddModal"

import UserApi from "./../api/UserApi";

import UserDeleteConfirm from "../components/Modal/UserDeleteConfirm";
import AuthorAddModal from "../components/Modal/AuthorAddModal";
import AuthorDeleteConfirm from "../components/Modal/AuthorDeleteConfirm";

import ApplicantList from "../pages/Job/ApplicantList";
import { withTranslation } from "react-i18next";
import config from "../config/config";
import ReactGA from "react-ga";
import Context from "./Context";
import Cookies from "js-cookie";

class User extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            userId: 0,
            authorId: 0,

            users: [],
            applicants: [],
            authors: [],
            site_ID: parseInt(Cookies.get("active-site")),
            selectedUser: [],
            selectedApplicant: [],
            selectedAuthor: [],

            selectedSite: "",
            search: "",
            searchAuthor: "",

            showModal: false,
            showConfirm: false,

            showModalAuthor: false,
            showConfirmAuthor: false,

            tabkey: 1,

            limitPageData: 10,
            activePage: 1,
            totalCount: 0,

            limitPageAuthor: 10,
            activePageAuthor: 1,
            totalCountAuthor: 0,

            deleteDisable: true,
            transAlert: "alert.",
        };

        this.setUserId = this.setUserId.bind(this);
        this.getUser = this.getUser.bind(this);
        this.getAuthor = this.getAuthor.bind(this);

        this.deleteSelectedUser = this.deleteSelectedUser.bind(this);
        this.deleteSelectedAuthor = this.deleteSelectedAuthor.bind(this);

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSiteChange = this.handleSiteChange.bind(this);

        this.setShowModal = this.setShowModal.bind(this);
        this.setShowConfirm = this.setShowConfirm.bind(this);

        this.setShowModalAuthor = this.setShowModalAuthor.bind(this);
        this.setShowConfirmAuthor = this.setShowConfirmAuthor.bind(this);
        this.setSelectedAuthor = this.setSelectedAuthor.bind(this);
    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        const { clientSites } = this.context;

        this.setState(
            {
                selectedSite: clientSites[0]?.id,
            },
            this.getUser
        );

        this.getAuthor();
    }

    componentDidUpdate() {
        const { activeSite } = this.context;

        if (activeSite) {
            if (this.state.site_ID !== activeSite) {
                this.setState(
                    {
                        site_ID: activeSite,
                    },
                    () => {
                        this.getUser();
                        this.getAuthor();
                    }
                );
            }
        }
    }
    getUser() {
        const userObj = new UserApi();
        const { activeClient, user } = this.context;

        userObj
            .getAllUsers({
                client: activeClient,
                site: user?.site,
                is_token: false,
                page_size: this.state.limitPageData,
                page: this.state.activePage,
                search: this.state.search,
            })
            .then((res) => {
                this.setState({
                    users: res.data.results,
                    totalCount: res.data.count,
                });
            });
    }

    // ===== author =========

    getAuthor() {
        const userObj = new UserApi();
        const { activeClient } = this.context;

        userObj
            .getAllAuthor({
                client: activeClient,
                page_size: this.state.limitPageAuthor,
                page: this.state.activePageAuthor,
                search: this.state.searchAuthor,
                site: this.state.site_ID,
            })
            .then((res) => {
                this.setState({
                    authors: res.data.results,
                    totalCountAuthor: res.data.count,
                });
            });
    }

    getDetailAuthor() {
        const userObj = new UserApi();

        userObj.getDetailAuthor(this.state.authorId).then((res) => {
            this.setState({
                selectedAuthor: res.data,
            });
        });
    }

    getUserDetail() {
        const userObj = new UserApi();

        userObj.getDetailUser(this.state.userId).then((res) => {
            this.setState({
                selectedUser: res.data,
            });
        });
    }

    setUserId(value) {
        this.setState({
            userId: value,
        });
    }

    // ===== employee ======

    setShowModal() {
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    setShowConfirm() {
        this.setState({
            showConfirm: !this.state.showConfirm,
        });
    }

    // ========= Author ============

    setShowModalAuthor() {
        this.setState({
            showModalAuthor: !this.state.showModalAuthor,
        });
    }

    setShowConfirmAuthor() {
        this.setState({
            showConfirmAuthor: !this.state.showConfirmAuthor,
        });
    }

    // ===========================

    handleSearchChange(value) {
        this.setState(
            {
                search: value,
            },
            () => {
                this.getUser();
            }
        );
    }

    handlePageChange(pageNumber) {
        this.setState(
            {
                activePage: pageNumber,
            },
            () => {
                this.getUser();
            }
        );
    }

    handleSiteChange(siteId) {
        this.setState({
            selectedSite: siteId,
        });
    }

    deleteSelectedUser() {
        const { t } = this.props;
        const userObj = new UserApi();

        const user = this.state.selectedUser[0];

        userObj
            .deleteSelectedUser(user)
            .then((res) => {
                swal(
                    "Success !",
                    t(this.state.transAlert + "user_delete_success"),
                    "success"
                );
                this.setShowConfirm();
                this.getUser();
            })
            .catch((err) => {
                console.log(" err => ", err);
                // const objAlert = new AlertHelper()
                // objAlert.errorHandlerAlert(err.response.data)
            });
    }

    // author handle

    handleSearchAuthorChange(value) {
        this.setState(
            {
                searchAuthor: value,
            },
            () => {
                this.getAuthor();
            }
        );
    }

    handlePageAuthorChange(pageNumber) {
        this.setState(
            {
                activePageAuthor: pageNumber,
            },
            () => {
                this.getAuthor();
            }
        );
    }

    setSelectedAuthor(author, value) {
        var lastSelected = [...this.state.selectedAuthor];
        var indexDelete = -1;

        if (value === true) {
            lastSelected.push(author);
        } else {
            this.state.selectedAuthor.forEach((item, index) => {
                if (item.id === author.id) {
                    indexDelete = index;
                }
            });

            lastSelected.splice(indexDelete, 1);
        }

        this.setState(
            {
                selectedAuthor: lastSelected,
            },
            () => {
                if (this.state.selectedAuthor.length > 0) {
                    this.setState({
                        deleteDisable: false,
                    });
                } else {
                    this.setState({
                        deleteDisable: true,
                    });
                }
            }
        );
    }

    deleteSelectedAuthor() {
        const { t } = this.props;
        const userObj = new UserApi();

        const author = this.state.selectedAuthor;

        userObj
            .deleteSelectedAuthor(author)
            .then((res) => {
                swal(
                    "Success !",
                    t(this.state.transAlert + "author_delete_success"),
                    "success"
                );
                this.setShowConfirmAuthor();
                this.getAuthor();
                this.setState({
                    selectedAuthor: [],
                });
            })
            .catch((err) => {
                console.log(" err => ", err);
            });
    }

    // ==========

    render() {
        const { t } = this.props;
        const { users } = this.state
        const contextValue = this.context;

        const myUser = contextValue.user
        const groups = (myUser?.groups || []).map(e => e.id)
        const subsc = contextValue?.clientSubscriptions.length ? (contextValue?.clientSubscriptions || []).map(e => e.id) : []
        const isSuperUser = myUser?.is_superuser
        let activeSubs = isSuperUser ? subsc : groups

        let UserList
        if (users && users.length) {
            UserList = users.map((user, index) => {
                return (
                    <div className={["s-adm-users-tbl-con"].join(" ")} key={`table-${index}`}>
                        <div className="w-40">
                            <p>{user.email}</p>
                        </div>
                        <div className="w-40">
                            <p>{user.name}</p>
                        </div>
                        <div className="d-flex dropzone-action w-10" >
                            <Link to={`/user/detail/${user.id}`} className="mr-1">
                                <FaPencilAlt color="#17438B" />
                            </Link>

                            <FaTrashAlt onClick={() => {
                                this.setState(
                                    {
                                        selectedUser: [user],
                                    },
                                    () => this.setShowConfirm()
                                );
                            }}
                                color="#17438B" />
                        </div>
                    </div>
                );
            });
        }

        let AuthorList =
            this.state.authors.length !== 0 ? (
                this.state.authors.map((item, index) => {
                    const lang = [parseInt(localStorage.getItem("langId"))];
                    const a_langs = item.author_languages;
                    let filtered = a_langs.filter(function (item) {
                        return lang.indexOf(item.language) !== -1;
                    });

                    let name = filtered ? filtered[0] ? filtered[0].name : "" : " ";

                    return (
                        <div className={["s-adm-users-tbl-con"].join(" ")} key={`table-${index}`}>
                            <div className="w-5">
                                <p className="c-ck-custom">
                                    <input type="checkbox" onClick={(e) => this.setSelectedAuthor(item, e.target.checked)} />
                                    <span className="checkmark"></span>
                                </p>
                            </div>
                            <div className="w-40">
                                <img src={item.avatar} alt={item.name} style={{ width: "auto", height: "64px" }} />
                            </div>
                            <div className="w-40">
                                <p>{name}</p>
                            </div>
                            <div className="d-flex dropzone-action w-10" >
                                <FaPencilAlt color="#17438B"
                                    className="mr-1"
                                    onClick={() => {
                                        this.setState({
                                            authorId: item.id,
                                        }, () => {
                                            this.setShowModalAuthor();
                                        }
                                        );
                                    }}
                                />

                                <FaTrashAlt onClick={() => {
                                    this.setState({
                                        selectedUser: [item],
                                    },
                                        () => this.setShowConfirmAuthor()
                                    );
                                }}
                                    color="#17438B" />
                            </div>
                        </div>
                    );
                })
            ) : (
                <div className={["s-adm-users-tbl-con"].join(" ")}>
                    <div className="w-5">
                    </div>
                    <div className="w-40">
                        No authors found
                    </div>
                    <div className="w-40">
                        
                    </div>
                    <div className="d-flex dropzone-action w-10" >
                    </div>
                </div>
            );

        return (
            <Content>
                <ContentHeader>
                    <TitlePages title={t("pages.user_page.title")} />
                </ContentHeader>
                <ContentBody>
                    <Card>
                        <CardBody>
                            <Tabs>
                                {
                                    (_.intersection(isSuperUser ? [1, 2, 5] : [4, 5, 6, 8], activeSubs).length > 0) &&
                                    <Tab eventKey={1} title={"Users"}>
                                        <br></br>
                                        <div className="row justify-content-between">
                                            <div className={["form-group col-md-4", "p-0"].join(" ")} >
                                                <label> {t("search")} </label>
                                                <input type="text" className="form-control" name="search" placeholder={"Search User"} value={this.state.search}
                                                    onChange={(event) => this.handleSearchChange(event.target.value)}
                                                />
                                            </div>
                                            <Link className="btn btn-primary btn-bz w-auto" to={"/user/add"} style={{ float: "right", height: "fit-content", margin: "auto 0" }}>
                                                <i className="fa fa-plus">
                                                    &nbsp;
                                                </i>{" "}Add User
                                            </Link>
                                        </div>
                                        <div className="row">
                                            <div className="s-adm-user-tbl-head">
                                                <div className="w-40">
                                                    <p>Email</p>
                                                </div>
                                                <div className="w-40">
                                                    <p>Name</p>
                                                </div>
                                                <div className="w-10">
                                                    <p>Action</p>
                                                </div>
                                            </div>
                                            {UserList}
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="row">
                                            <div className="col-md-10"></div>
                                            <div className="col-md-2">
                                                <div id="pagination_custom" style={{ float: "right" }}>
                                                    <Pagination
                                                        activePage={this.state.activePage}
                                                        itemsCountPerPage={this.state.limitPageData}
                                                        totalItemsCount={this.state.totalCount}
                                                        pageRangeDisplayed={4}
                                                        onChange={this.handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="clearfix"></div>
                                    </Tab>
                                }
                                {
                                    (_.intersection(isSuperUser ? [2] : [5], activeSubs).length > 0) &&
                                    <Tab eventKey={2} title={t("applicant")}>
                                        <br></br>
                                        <ApplicantList
                                            activeApplicant={true}
                                        ></ApplicantList>
                                    </Tab>
                                }
                                {
                                    (_.intersection(isSuperUser ? [1, 2] : [4, 5], activeSubs).length > 0) &&
                                    <Tab eventKey={3} title={t("author")}>
                                        <br></br>
                                        <div className="row justify-content-between">
                                            <div className={["form-group", "p-0",].join(" ")} >
                                                <label> {t("search")} </label>
                                                <input type="text" className="form-control" name="search" placeholder={t("pages.user_page.search_author")} value={this.state.searchAuthor}
                                                    onChange={(event) => this.handleSearchAuthorChange(event.target.value)}
                                                />
                                            </div>

                                            <div className="row">
                                                <Button className="btn btn-danger" disabled={this.state.deleteDisable}
                                                    style={{ height: "fit-content", margin: "auto 1rem auto 0" }}
                                                    onClick={() => {
                                                        this.setShowConfirmAuthor();
                                                    }}
                                                >
                                                    <i className="fa fa-trash-alt">&nbsp;</i>{" "}
                                                    {t("pages.user_page.delete_selected_author")}
                                                </Button>
                                                <Button className="btn btn-primary btn-bz"
                                                    style={{ height: "fit-content", margin: "auto 0" }}
                                                    onClick={() => {
                                                        this.setState({
                                                            authorId: 0,
                                                        }, () => {
                                                            this.setShowModalAuthor();
                                                        }
                                                        );
                                                    }}
                                                >
                                                    <i className="fa fa-plus">&nbsp;</i>{" "}
                                                    {t("pages.user_page.add_author")}
                                                </Button>
                                            </div>
                                        </div>

                                        <div className="row w-100">
                                            <div className="row w-100">
                                                <div className="s-adm-user-tbl-head">
                                                    <div className="w-5">
                                                        <p></p>
                                                    </div>
                                                    <div className="w-40">
                                                        <p>Avatar</p>
                                                    </div>
                                                    <div className="w-40">
                                                        <p>Author</p>
                                                    </div>
                                                    <div className="w-10">
                                                        <p>Action</p>
                                                    </div>
                                                </div>
                                                {AuthorList}
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="row">
                                            <div className="col-md-10"></div>
                                            <div className="col-md-2">
                                                <div id="pagination_custom" style={{ float: "right" }}>
                                                    <Pagination
                                                        activePage={this.state.activePageAuthor}
                                                        itemsCountPerPage={this.state.limitPageAuthor}
                                                        totalItemsCount={this.state.totalCountAuthor}
                                                        pageRangeDisplayed={4}
                                                        onChange={this.handlePageAuthorChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="clearfix"></div>
                                    </Tab>
                                }
                            </Tabs>
                        </CardBody>
                    </Card>
                </ContentBody>
                <AuthorAddModal
                    show={this.state.showModalAuthor}
                    onHide={this.setShowModalAuthor}
                    authorId={this.state.authorId}
                    getAuthor={this.getAuthor}
                ></AuthorAddModal>
                <UserDeleteConfirm
                    show={this.state.showConfirm}
                    onHide={this.setShowConfirm}
                    getUser={this.getUser}
                    selectedUser={this.state.selectedUser}
                    deleteSelectedUser={this.deleteSelectedUser}
                ></UserDeleteConfirm>
                <AuthorDeleteConfirm
                    show={this.state.showConfirmAuthor}
                    onHide={this.setShowConfirmAuthor}
                    getAuthor={this.getAuthor}
                    selectedAuthor={this.state.selectedAuthor}
                    deleteSelectedAuthor={this.deleteSelectedAuthor}
                ></AuthorDeleteConfirm>
            </Content>
        );
    }
}

export default withTranslation()(User);
