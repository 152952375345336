import React, { Component } from "react";
import { Table, Button } from "react-bootstrap";
import Card, { CardBody } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content";

import swal from "sweetalert";
import Pagination from "react-js-pagination";
import SectionCategoryApi from "./../api/SectionCategoryApi";

import SectionCategoryAddModal from "./../components/Modal/SectionCategoryAddModal";
import SectionCategoryDeleteConfirm from "./../components/Modal/SectionCategoryDeleteConfirm";
import TitlePages from './Elements/TitlePages'
import { withTranslation } from 'react-i18next';
import config from '../config/config'
import ReactGA from 'react-ga';


class SectionCategoriesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryId: 0,
      categories: [],
      selectedCategory: [],

      search: "",

      showModal: false,
      showConfirm: false,

      limitPageData: 10,
      activePage: 1,
      totalCount: 0,
      transAlert: "alert."
    };

    this.setCategoryId = this.setCategoryId.bind(this);
    this.getCategories = this.getCategories.bind(this);

    this.deleteSelectedCategory = this.deleteSelectedCategory.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    this.setShowModal = this.setShowModal.bind(this);
    this.setShowConfirm = this.setShowConfirm.bind(this);
  }

  componentDidMount() {
    ReactGA.initialize(config.GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.getCategories();
  }

  getCategories() {
    this.setState({
      categories: [],
    });

    const sectionObj = new SectionCategoryApi();

    sectionObj
      .getAllSectionCategories({
        page_size: this.state.limitPageData,
        page: this.state.activePage,
        search: this.state.search,
      })
      .then((res) => {
        this.setState({
          categories: res.data.results,
          totalCount: res.data.count,
        });
      });
  }

  getCategoryDetail() {
    const categoryObj = new SectionCategoryApi();

    categoryObj.getDetailCategory(this.state.categoryId).then((res) => {
      this.setState({
        selectedCategory: res.data,
      });
    });
  }

  setCategoryId(value) {
    this.setState(
      {
        categoryId: value,
      },
      () => {
        this.setShowModal();
      }
    );
  }

  setShowModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  setShowConfirm() {
    this.setState({
      showConfirm: !this.state.showConfirm,
    });
  }

  handleSearchChange(value) {
    this.setState(
      {
        search: value,
      },
      () => {
        this.getCategories();
      }
    );
  }

  handlePageChange(pageNumber) {
    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.getCategories();
      }
    );
  }

  deleteSelectedCategory() {
    const { t } = this.props;
    const categoryObj = new SectionCategoryApi();

    const category = this.state.selectedCategory[0].id;

    categoryObj
      .deleteSectionCategory(category)
      .then((res) => {
        swal("Success !", t(this.state.transAlert + "section_category_delete_success"), "success");
        this.setShowConfirm();
        this.getCategories();
      })
      .catch((err) => {
        console.log(" err => ", err);
        // const objAlert = new AlertHelper()
        // objAlert.errorHandlerAlert(err.response.data)
      });
  }

  render() {
    const { t } = this.props;
    const CategoryList = this.state.categories.map((item) => {
      const langindex = localStorage.getItem("lang") === "vi" ? 1 : 0;

      return (
        <tr key={item.id}>
          <td> {item.id}</td>
          <td> {item.category_languages[langindex].name}</td>

          <td>
            <div className="d-flex align-items-center justify-content-end">
              <Button
                onClick={() => this.setCategoryId(item.id)}
                className="btn btn-primary btn-xs"
              >
                <i className="fa fa-edit"></i>
              </Button>
                  &nbsp;
                  <Button
                variant="danger"
                className="btn btn-xs"
                onClick={() => {
                  this.setState(
                    {
                      selectedCategory: [item],
                    },
                    () => this.setShowConfirm()
                  );
                }}
              >
                <i className="fa fa-trash"></i>
              </Button>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <Content>
        <ContentHeader>
          <TitlePages title={t('pages.section_categories.title')} />
        </ContentHeader>

        <ContentBody>
          <Card>
            <CardBody>
              <br></br>
              <div className="row">
                <div className="col-md-8"></div>
                <div className="col-md-4">
                  <Button
                    className="btn btn-primary"
                    onClick={() => {
                      this.setState(
                        {
                          categoryId: 0,
                        },
                        () => {
                          this.setShowModal();
                        }
                      );
                    }}
                    style={{ float: "right" }}
                  >
                    <i className="fa fa-plus">&nbsp;</i> {t('pages.section_categories.add_section_category')}
                  </Button>
                </div>
              </div>

              <div className="row">
                <div className={["col-md-10", "p-0"].join(" ")}>
                  <div className={["form-group col-md-4", "p-0"].join(" ")}>
                    <label> {t('search')} </label>
                    <input
                      type="text"
                      className="form-control"
                      name="search"
                      placeholder={t('pages.section_categories.search_section_category')}
                      value={this.state.search}
                      onChange={(event) =>
                        this.handleSearchChange(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-2" style={{ position: "relative" }}>
                  <div
                    style={{
                      float: "right",
                      position: "absolute",
                      bottom: 0,
                      right: 6,
                    }}
                  >
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limitPageData}
                      totalItemsCount={this.state.totalCount}
                      pageRangeDisplayed={4}
                      onChange={this.handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th> ID </th>
                      <th> {t('pages.section_categories.section_category_name')} </th>
                      <th> {t('action')} </th>
                    </tr>
                  </thead>
                  <tbody>{CategoryList}</tbody>
                </Table>
              </div>
              <div className="clearfix"></div>
              <div className="row">
                <div className="col-md-10"></div>
                <div className="col-md-2">
                  <div style={{ float: "right" }}>
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limitPageData}
                      totalItemsCount={this.state.totalCount}
                      pageRangeDisplayed={4}
                      onChange={this.handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              </div>

              <div className="clearfix"></div>
            </CardBody>
          </Card>
        </ContentBody>

        <SectionCategoryAddModal
          show={this.state.showModal}
          onHide={this.setShowModal}
          categoryId={this.state.categoryId}
          getCategories={this.getCategories}
        ></SectionCategoryAddModal>
        <SectionCategoryDeleteConfirm
          show={this.state.showConfirm}
          onHide={this.setShowConfirm}
          categoryId={this.state.categoryId}
          selectedCategory={this.state.selectedCategory}
          deleteSelectedCategory={this.deleteSelectedCategory}
        ></SectionCategoryDeleteConfirm>
      </Content>
    );
  }
}

export default withTranslation()(SectionCategoriesPage);