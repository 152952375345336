import React, { Component } from "react"
import { Link, withRouter } from 'react-router-dom'
import { Nav } from "react-bootstrap"
import Flag from "react-flags"
import { withTranslation } from 'react-i18next';
import { removeUserSession } from "../../lib/Common";
// import { withCookies, Cookies } from 'react-cookie';

import UserContext from '../../pages/Context'
import SiteAddModal from '../Modal/SiteAddModal'

import bzLogo from './assets/bzpublish.png'
import bzSetting from './assets/settings.svg'

import "./Header.css"
class HeaderNew extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props)
        const { t } = this.props;
        const transPage = "pages.header."
        this.state = {
            sites: [],
            activeSites: false,
            activeSiteData: false,
            transPage: "pages.header.",
            navItems: [
                {
                    index: 1,
                    name: t(transPage + "create_new_site"),
                },
                {
                    index: 2,
                    name: t(transPage + "upload_site"),
                },
                {
                    index: 3,
                    name: t(transPage + "manage_sitemap"),
                },
                {
                    index: 4,
                    name: t(transPage + "user_profile"),
                },
                {
                    index: 5,
                    name: t(transPage + "logout"),
                },
            ],
            setShowSiteAdd: false,
            setShowSiteUpdate: false,
        }
        this.getSites = this.getSites.bind(this)
        this.handleChangeSites = this.handleChangeSites.bind(this)
        this.setShowSiteAdd = this.setShowSiteAdd.bind(this)
        this.setShowSiteUpdate = this.setShowSiteUpdate.bind(this)
    }

    componentDidMount() {
        this.getSites()
    }

    componentDidUpdate(prevProps, prevState) {
        const contextValue = this.context;

        if (this.state.sites !== contextValue.clientSites) {
            this.setState({
                sites: contextValue.clientSites
            })
        }
    }

    getSites() {
        // const objSite = new SiteApi()
        const contextValue = this.context;

        this.setState({
            sites: contextValue.clientSites,
        })

        // objSite.getAllSites().then((res) => {

        //     res.data.results.forEach((a, i) => {
        //         let activeTemplate = localStorage.getItem('templateId')
        //         if (activeTemplate) {
        //             if (contextValue.activeSite) {
        //                 if (a.id === parseInt(contextValue.activeSite)) {
        //                     this.handleChangeSites(parseInt(contextValue.activeSite), a, activeTemplate)
        //                 }
        //             } else {
        //                 if (a.id === 5) {
        //                     this.handleChangeSites(a.id, a, activeTemplate)
        //                 }
        //             }
        //         }

        //     })
        //     this.setState({
        //         sites: res.data.results,

        //     })
        // })
    }

    handleChangeSites(value, param, template) {
        const contextValue = this.context;

        this.setState({
            activeSites: value
        }, () => {
            contextValue.changeActiveSite(parseInt(value))
        })
        // let activeSiteData, activeTemplate
        // activeTemplate = template
        // this.state.sites.forEach((a, i) => {
        //     if (a.id === parseInt(value)) {
        //         activeSiteData = a
        //         a.templates.forEach(aT => {
        //             if (aT.active) {
        //                 activeTemplate = aT.id
        //             }
        //         })
        //     }
        // })

        // this.setState({
        //     activeSiteData: param !== '' ? param : activeSiteData,
        //     activeSites: value
        // }, () => {
        //     contextValue.changeActiveSite(parseInt(value))
        //     setTimeout(() => {
        //         contextValue.changeActiveTemplate(parseInt(activeTemplate))
        //     }, 100);
        // })
    }

    handleLogout = () => {
        const contextValue = this.context;

        removeUserSession();
        contextValue.removeDataUser()
        setTimeout(() => {
            this.props.history.push("/login");
        }, 500);
    };

    setShowSiteAdd(value) {
        this.setState({
            setShowSiteAdd: value
        })
    }

    setShowSiteUpdate(value) {
        this.setState({
            setShowSiteUpdate: value
        })
    }

    render() {
        const { t } = this.props;
        const {
            navItems,
            // activeSites 
        } = this.state
        const contextValue = this.context;

        let optionItem
        if (contextValue.user) {
            optionItem = navItems.map((s, i) => {
                let items
                // if (s.index === 1) {
                //     items = <li onClick={() => { this.setShowSiteAdd(true) }}>
                //         <span>{s.name}</span>
                //     </li>
                // } else if (s.index === 2) {
                //     items = activeSites ?
                //         <li onClick={() => { this.setShowSiteUpdate(true) }}>
                //             <span>{s.name}</span>
                //         </li> :
                //         <li style={{ cursor: "not-allowed" }}>
                //             <span style={{ opacity: ".6" }}>{s.name}</span>
                //         </li>
                // } else if (s.index === 3) {
                //     items = <li>
                //         <Link to="/sitemap-settings">{s.name}</Link>
                //     </li>
                // } else if (s.index === 4) {
                //     items = <li onClick={this.handleLogout}>
                //         <span>{s.name}</span>
                //     </li>
                // }
                if (i === 3) {
                    items = <li>
                        <Link to={`/user/detail/${contextValue.user.id}`}>
                            <span>{s.name}</span>
                        </Link>
                    </li>
                } else if (i === 4) {
                    items = <li onClick={this.handleLogout}>
                        <span>{s.name}</span>
                    </li>
                }
                return items
            })
        }


        let languages
        if (contextValue.adminSiteLanguages.length > 0) {
            languages = contextValue.adminSiteLanguages.map(lang => {
                return (
                    <Nav.Item className="nav-item mt-auto mb-auto" >
                        <Nav.Link className=" lang" active={localStorage.getItem("lang") === lang.abbreviation.toLowerCase() ? 1 : ""} data-widget="control-sidebar" data-slide="true" href="#"
                            onClick={() => { this.props.changeLanguage(lang) }} role="button">
                            {/* <span>{lang.abbreviation}</span> */}
                            <Flag name={lang.flag} format="png" pngSize={24} shiny={false} alt={lang.name} basePath="/img/flags" title={lang.name} />
                        </Nav.Link>
                    </Nav.Item>
                )
            })
        }

        let langItem, navItem
        let isSiteListShowing = true
        if (contextValue.user) {
            langItem = <div>
                <select className="form-control nav-L-sel-c" value={this.state.activeSites} onChange={(event) => { this.handleChangeSites(event.target.value, '', '') }}>
                    <option disabled value=""> -- {t(this.state.transPage + "select_site")} -- </option>
                    {
                        this.state.sites.map(item => {
                            return (<option value={item.id}> {item.name} </option>)
                        })
                    }
                </select>
            </div>

            navItem = <div className='navSettings'>
                <div className={'slNav'}>
                    <div className={'contentDropdown'}>
                        <ul>
                            <li>
                                <div className='navIcon'>
                                    <img src={bzSetting} alt="icon" />
                                </div>
                                <div className={'block'}></div>
                                <div className={'triangle'}></div>
                                <ul>
                                    {optionItem}
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            const groups = (contextValue?.user?.groups || []).map(e => e.id)
            if (!contextValue?.user?.is_superuser && groups.includes(8)) {
                isSiteListShowing = false
            }
        } else if (contextValue.user && !contextValue.user.is_superuser && contextValue.user.sites.length > 0) {
            langItem = <div>
                <select className="form-control nav-L-sel-c" value={this.state.activeSites} onChange={(event) => { this.handleChangeSites(event.target.value, '', '') }}>
                    {
                        contextValue.user.sites.map(item => {
                            return (<option value={item.id}> {item.name} </option>)
                        })
                    }
                </select>
            </div>
        }

        return (
            <div className='topNav'>
                <div className='navLogo'>
                    <Link to={'/'}>
                        <img src={bzLogo} alt="logo" />
                    </Link>
                </div>
                <Nav variant="pills" className="navbar-nav ml-auto navLanguage">
                    {
                        isSiteListShowing && langItem
                    }
                    {languages}
                </Nav>
                <div className="uname">{contextValue.user?.name}</div>
                {navItem}

                <SiteAddModal
                    show={this.state.setShowSiteAdd}
                    onHide={() => { this.setShowSiteAdd(false) }}
                    getSites={this.getSites}
                ></SiteAddModal>
                <SiteAddModal
                    show={this.state.setShowSiteUpdate}
                    activeSites={this.state.activeSites}
                    activeSiteData={this.state.activeSiteData}
                    getSites={this.getSites}
                    onHide={() => { this.setShowSiteUpdate(false) }}
                ></SiteAddModal>
            </div>
        )
    }
}

export default withRouter(withTranslation()(HeaderNew));
