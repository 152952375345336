import React, { Component } from "react";
import { Button, Dropdown } from "react-bootstrap";
import Card, { CardHeader, CardBody } from "../../Card";
// import AssetsElement from './../../../pages/Elements/AssetsElement';
// import swal from "sweetalert"
import ArticleAssetAddModal from "./../../../components/Modal/ArticleAssetAddModal";
import AssetDeleteConfirm from "./../../../components/Modal/AssetDeleteConfirm";
// import ArticleApi from '../../../api/ArticleApi';
// import ArticleDeleteConfirm from '../../Modal/ArticleDeleteConfirm';
// import AssetApi from "./../../../api/AssetApi"
import { withTranslation } from "react-i18next";
import UserContext from "../../../pages/Context";

class ArticleAssetListAdd extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            articleId: 0,
            selectedAsset: [],
            selectedAssetAdd: [],
            deleteDisable: true,
            showAssetModal: false,
            showConfirmModal: false,
            loadingModal: "",
            transPage: "widgets.article_asset_add.",
        };

        this.setSelectedAsset = this.setSelectedAsset.bind(this);
        // this.setSelectedAssetAdd = this.setSelectedAssetAdd.bind(this)
        this.setShowAssetModal = this.setShowAssetModal.bind(this);
        this.setShowConfirmModal = this.setShowConfirmModal.bind(this);
        this.deleteSelectedAssetAdd = this.deleteSelectedAssetAdd.bind(this);
    }

    componentDidMount() {
        // alert(" componentDMount => "+this.props.articleId)
        this.setState({
            articleId: this.props.articleId,
        });
    }

    setShowAssetModal() {
        this.setState({
            showAssetModal: !this.state.showAssetModal,
        });
    }

    setShowConfirmModal() {
        this.setState({
            showConfirmModal: !this.state.showConfirmModal,
        });
    }

    setSelectedAsset(asset, value) {
        var lastSelected = [];
        lastSelected = [...this.state.selectedAssetAdd];
        var indexDelete = -1;
        var stateSelected = "selectedAssetAdd";

        if (value === true) {
            lastSelected.push(asset);
        } else {
            this.state[stateSelected].forEach((item, index) => {
                if (item.id === asset.id) {
                    indexDelete = index;
                }
            });

            lastSelected.splice(indexDelete, 1);
        }

        this.setState(
            {
                [stateSelected]: lastSelected,
            },
            () => {
                if (this.state[stateSelected].length > 0) {
                    this.setState({
                        deleteDisable: false,
                    });
                } else {
                    this.setState({
                        deleteDisable: true,
                    });
                }
            }
        );
    }

    deleteSelectedAssetAdd() {
        // const objArticle = new ArticleApi()
        this.setState({
            loadingModal: (
                <div className="overlay d-flex justify-content-center align-items-center">
                    <i className="fas fa-2x fa-sync fa-spin"></i>
                </div>
            ),
        });

        this.props.deleteSelectedAssetAdd(this.state.selectedAssetAdd);

        this.setState({
            selectedAsset: [],
            selectedAssetAdd: [],
            loadingModal: "",
            showConfirmModal: !this.state.showConfirmModal,
        });

        // objArticle.articleAssetUnpair(this.state.articleId,selectedAssetId)
        // .then(res => {

        //     return this.props.getDetailArticle()

        // })
        // .then(resB => {
        //     this.setState({
        //         loadingModal:"",
        //         showConfirmModal:!this.state.showConfirmModal
        //     },() => {
        //         swal("Success!","You sucessfully deleted asset on this article","success")
        //     })
        // })
        // .catch(err => {
        //     swal("Error !",err.message,"error")
        // })
    }

    render() {
        const { t } = this.props;
        const { employee_privileges } = this.context;
        const isOnlyMonitor =
            employee_privileges?.filter((e) => e.id === 5).length > 0;

        const Assets = this.props.selectedAssetAdd.map((item, index) => {
            var media = <img width={"100%"} alt="" src={item.asset_file}></img>;
            if (item.file_type === "URL") {
                media = (
                    <iframe
                        title={`asset-media-embedded-${index}`}
                        width={"100%"}
                        src={item.asset_link}
                        allowFullScreen
                    ></iframe>
                );
            } else if (item.file_type === "Video") {
                media = (
                    <video width={"100%"} height={"50%"} title="Video" controls>
                        <source src={item.asset_file} />
                    </video>
                );
            }

            return (
                <div className="col-md-2" key={item.id}>
                    <Card id={item.name + "-" + item.id}>
                        <CardHeader className="bg-primary">
                            <div className="form-check form-check-inline">
                                <input
                                    type="checkbox"
                                    value=""
                                    onClick={(event) =>
                                        this.setSelectedAsset(
                                            item,
                                            event.currentTarget.checked
                                        )
                                    }
                                    className="form-check-input"
                                    id={"label" + item.id}
                                ></input>
                                &nbsp;
                                <label
                                    className="form-check-label"
                                    htmlFor={"label" + item.id}
                                >
                                    <b>{item.name}</b>{" "}
                                </label>
                            </div>
                            <div className="card-tools">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        id="dropdown-basic"
                                        as={"span"}
                                    >
                                        <a
                                            href={
                                                "#" + item.name + "-" + item.id
                                            }
                                            style={{ color: "black" }}
                                        >
                                            <i className="fa fa-ellipsis-v"></i>
                                        </a>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => {
                                                this.setState(
                                                    {
                                                        selectedAsset: this.state.selectedAsset.concat(
                                                            [item]
                                                        ),
                                                    },
                                                    () => {
                                                        this.setShowConfirmModal();
                                                    }
                                                );
                                            }}
                                        >
                                            Delete
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className="container-fluid" align="center">
                                {media}
                            </div>
                        </CardBody>
                    </Card>
                </div>
            );
        });

        // const selectedAsset = this.props.selectedAssetAdd.map((item,index) => {
        //     return (<li key={index}>{item.name}</li>)
        // })

        return (
            <div>
                <div className="row">
                    <div className="float-right col-md-6">&nbsp;</div>
                    <div className="float-right col-md-6">
                        <Button
                            variant="danger"
                            disabled={this.state.deleteDisable || isOnlyMonitor}
                            onClick={() => this.setShowConfirmModal()}
                            className="float-right"
                        >
                            <i className="fa fa-trash"></i>{" "}
                            {t(this.state.transPage + "delete_all")}
                        </Button>
                        <Button
                            disabled={isOnlyMonitor}
                            variant="primary"
                            className="float-right"
                            style={{ marginRight: 10 }}
                            onClick={() => this.setShowAssetModal()}
                        >
                            <i className="fa fa-plus"></i>{" "}
                            {t(this.state.transPage + "add_new_asset")}
                        </Button>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <div>&nbsp;</div>
                <div
                    style={{
                        border: "3px dashed grey",
                        padding: 5,
                        minHeight: 260,
                    }}
                >
                    <div className="row">{Assets}</div>
                </div>
                <ArticleAssetAddModal
                    type="add"
                    setSelectedAssetAdd={this.props.setSelectedAssetAdd}
                    getDetailArticle={this.props.getDetailArticle}
                    articleId={this.state.articleId}
                    show={this.state.showAssetModal}
                    onHide={this.setShowAssetModal}
                ></ArticleAssetAddModal>
                <AssetDeleteConfirm
                    selectedAssets={this.state.selectedAssetAdd}
                    deleteSelectedAsset={this.deleteSelectedAssetAdd}
                    show={this.state.showConfirmModal}
                    onHide={this.setShowConfirmModal}
                ></AssetDeleteConfirm>
            </div>
        );
    }
}

export default withTranslation()(ArticleAssetListAdd);
