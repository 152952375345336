import React, { Component } from 'react';

import { Modal, Button } from "react-bootstrap"

class TextDeleteConfirm extends Component {

    constructor(props) {
        super(props)

        this.deleteSelectedText = this.deleteSelectedText.bind(this)
        this.onOpenModal = this.onOpenModal.bind(this)
        // this.onHideModal = this.onHideModal.bind(this)
    }

    deleteSelectedText() {
        // dari props method nya
        this.props.deleteSelectedText() 
    }

    onOpenModal() {
    }

    
    render() {

        const listTexts = this.props.selectedTextElement.map(item => {
            return (<li key={item.id}>{ item.name }</li>)
        })

        return (
            <Modal show={this.props.show} onHide={() => this.props.onHide()} onShow={() => this.onOpenModal()}>
                {this.props.loadingModal}
                <Modal.Header closeButton className="bg-danger">
                    <Modal.Title>Confirm Delete Text </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p> Are you sure want to delete these / this texts/text ? </p>
                    <ul>
                        {listTexts}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.props.onHide()}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => this.deleteSelectedText()}>
                        <i className="fa fa-trash">&nbsp;</i>
                        Delete Selected Texts
                    </Button>   
                </Modal.Footer>
            </Modal>
        );
    }
}

export default TextDeleteConfirm;