import React, { Component } from "react";
import swal from "sweetalert";
import { Table, Button } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { withTranslation } from 'react-i18next';
import UserContext from '../../pages/Context';
import SitemapApi from '../../api/SitemapApi';
import SiteApi from '../../api/SiteApi';
import SitemapSubmitModal from '../Modal/SitemapSubmitModal'
import SitemapDeleteConfirm from '../Modal/SitemapDeleteConfirm'

class Sitemap extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            idTemplate: 0,

            sitemaps: [],
            filteredSitemaps: [],
            activePage: 1,
            limitPageData: 10,
            totalCount: 0,
            selectedSitemap: [],
            siteUrl: "",

            isShowSitemapSubmitModal: false,
            isShowSitemapDeleteConfirm: false,
            isDisableSubmit: true,

            search: "",

            transPage: "pages.sitemap_settings.",
            transAlert: "alert."
        };

        this.defaultState = {
            isDisableSubmit: true,
            sitemaps: []
        }

        this.deleteSitemap = this.deleteSitemap.bind(this)
        this.getSitemap = this.getSitemap.bind(this)
        this.listSitemap = this.listSitemap.bind(this)
        this.submitSitemap = this.submitSitemap.bind(this)
        this.loadSitemap = this.loadSitemap.bind(this)
    }

    componentDidMount() {
        const contextProps = this.context
        this.setState({ idTemplate: contextProps.activeTemplate })
        this.getSites()

        this.sitemapApi = new SitemapApi()
    }

    componentDidUpdate() {
        const contextProps = this.context
        if (contextProps.activeTemplate !== this.state.idTemplate) {
            this.setState({
                idTemplate: contextProps.activeTemplate,
                ...this.defaultState
            })
            this.getSites()
        }
    }

    getSites() {
        const siteApi = new SiteApi()
        const { activeSite } = this.context

        siteApi.getSiteDetail(activeSite)
            .then(res => {
                this.setState({
                    siteUrl: res.data.site_url
                })
            })
    }

    removeWww(url) {
        return url.replace('://www.', '://');
    }

    deleteSitemap(feedPath) {
        const nonWwwUrl = this.removeWww(this.state.siteUrl);
        const nonWwwfeedPath = this.removeWww(feedPath)
        if (this.state.siteUrl.indexOf("://www.") !== -1) {
            this.sitemapApi.deleteSitemap(nonWwwUrl, nonWwwfeedPath)
                .then(response => { })
        }

        return this.sitemapApi.deleteSitemap(this.state.siteUrl, feedPath)
            .then(() => {
                swal("Success !", "You successfully deleted sitemap ", "success")
            })
            .then(this.listSitemap)
    }

    getSitemap(siteUrl, feedPath) {
        this.sitemapApi.getSitemap(siteUrl, feedPath)
    }

    listSitemap() {
        this.sitemapApi.listSitemap(this.state.siteUrl)
            .then(response => {
                this.setState({
                    sitemaps: response.result.sitemap ? response.result.sitemap : [],
                    isDisableSubmit: false
                })
            }, err => {
                const errorMessage = err.result.error.message
                swal("Error !", errorMessage, "error")
            });
    }

    loadSitemap() {
        this.sitemapApi.logIn()
            .then(this.listSitemap)
    }

    submitSitemap(feedPath) {
        const nonWwwUrl = this.removeWww(this.state.siteUrl);
        const nonWwwfeedPath = this.removeWww(feedPath)
        if (this.state.siteUrl.indexOf("://www.") !== -1) {
            this.sitemapApi.submitSitemap(nonWwwUrl, nonWwwfeedPath)
                .then(response => { })
        }

        return this.sitemapApi.submitSitemap(this.state.siteUrl, feedPath)
            .then(response => {
                swal("Success !", "You successfully submitted a sitemap to Google Search Console ", "success")
            },
                function (err) { console.error("Execute error", err); })
            .then(this.listSitemap)
    }

    render() {
        const { t } = this.props;

        const SitemapsList = this.state.sitemaps?.map((item, index) => {
            return (
                <tr key={index}>
                    <td> {item.path} </td>
                    <td> {item.lastSubmitted}</td>
                    <td> {item.isPending ? "Yes" : "No"}</td>
                    <td> {item.isSitemapIndex ? "Yes" : "No"}</td>
                    <td> {item.errors}</td>
                    <td> {item.warnings}</td>
                    <td>
                        <div style={{ float: "right" }}>
                            <Button
                                variant="danger"
                                className="btn btn-xs"
                                onClick={() => {
                                    this.setState({
                                        selectedSitemap: [{ path: item.path }],
                                        isShowSitemapDeleteConfirm: !this.state.isShowSitemapDeleteConfirm
                                    });
                                }}
                            >
                                <i className="fa fa-trash"></i>
                            </Button>
                        </div>
                    </td>
                </tr>
            );
        });

        return (
            <>
                <div className="d-flex justify-content-between">
                    <div>
                        <Button
                            className="btn btn-primary border-0"
                            onClick={this.loadSitemap}
                            style={{
                                backgroundColor: "#223771",
                                color: "#ffffff",
                            }}
                        >
                            <i className="fa fa-magnet">&nbsp;</i> {t(this.state.transPage + 'load_sitemap_from_gsc')}
                        </Button>
                    </div>
                    <div>
                        <Button
                            disabled={this.state.isDisableSubmit}
                            className="btn btn-primary border-0"
                            onClick={() => {
                                this.setState({
                                    isShowSitemapSubmitModal: !this.state.isShowSitemapSubmitModal
                                });
                            }}
                            style={{
                                backgroundColor: "#223771",
                                color: "#ffffff",
                            }}
                        >
                            <i className="fa fa-plus">&nbsp;</i> {t(this.state.transPage + 'submit_sitemap')}
                        </Button>
                    </div>
                </div>

                <div className="mt-4">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th> Path </th>
                                <th> Last Submitted </th>
                                <th> Pending </th>
                                <th> Sitemap Index </th>
                                <th> Errors </th>
                                <th> Warnings </th>
                                <th> {t('action')} </th>
                            </tr>
                        </thead>
                        <tbody>
                            {SitemapsList}
                            <tr style={!this.state.isDisableSubmit ? { display: "none" } : null}>
                                <td colspan="7" style={{ textAlign: "center" }}>
                                    Sitemaps have not been loaded.
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div className="clearfix"></div>
                <div className="row">
                    <div className="col-md-10"></div>
                    <div className="col-md-2">
                        <div style={{ float: "right" }}>
                            <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.limitPageData}
                                totalItemsCount={this.state.totalCount}
                                pageRangeDisplayed={4}
                                onChange={this.handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div>
                    </div>
                </div>


                <SitemapSubmitModal
                    show={this.state.isShowSitemapSubmitModal}
                    onHide={() => this.setState({ isShowSitemapSubmitModal: false })}
                    submitSitemap={this.submitSitemap}
                />
                <SitemapDeleteConfirm
                    show={this.state.isShowSitemapDeleteConfirm}
                    onHide={() => this.setState({ isShowSitemapDeleteConfirm: false })}
                    // deleteSelectedSitemap={this.deleteSelectedSitemap}
                    deleteSitemap={() => this.deleteSitemap(this.state.selectedSitemap[0].path)}
                    selectedSitemap={this.state.selectedSitemap}
                />
            </>
        );
    }
}

export default withTranslation()(Sitemap);