import React, { Component } from 'react'

import Context from '../../Context'

import iW from '../assets/arrW.svg'
import iB from '../assets/arrB.svg'
import classes from './SidebarSecondary.module.css'

export default class SidebarSecondary extends Component {
    static contextType = Context
    constructor(props) {
        super(props)
        this.state = {
            activePage: 1
        }
    }

    componentDidMount() {

    }
    
    render() {
        const contextValue = this.context
        let secondaryClass = contextValue.activeSidebar ? classes.activeSecondary : ''

        let contentItem
        if (this.state.activePage === 1) {
            contentItem = <div className={[classes.titleItem, classes.activePage].join(' ')}>
                <div className={classes.accHead}>
                    <p className={classes.titleAccordion}>Content</p>
                    <img className={classes.titleIcon} src={iW} alt="icon" />
                </div>
            </div>
        } else {
            contentItem = <div className={classes.titleItem}>
                <div className={classes.accHead}>
                    <p className={classes.titleAccordion}>Content</p>
                    <img className={classes.titleIcon} src={iB} alt="icon" />
                </div>
            </div>
        }

        return (
            <div className={[classes.sidebarSecondary, secondaryClass].join(' ')}>
                <div className={classes.contentSidebarSecondary}>
                    {contentItem}
                    <div className={classes.titleItem}>
                        <div className={classes.accHead}>
                            <p className={classes.titleAccordion}>Content</p>
                            <img className={classes.titleIcon} src={iB} alt="icon" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
