import React from 'react';
import css from './BoxSimple.module.css'

const backgroundColor = ["#223771", "#878787", "#C2C2C2"]

function BoxSimple(props) {
    return (
        <div {...props} style={{backgroundColor: backgroundColor[props.idx % 3], ...props.style}} className={[css.BoxSimple, props.className].join(' ')} >
            <div className={css.status}>{props.status}</div>
            <div className={css.content}>{props.content}</div>
        </div>
    );
}

export default BoxSimple;