import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import _ from "lodash";

import MenuItem from "./../SidebarNew/MenuItem";
import NavItem from "./../SidebarNew/NavItem";

import { removeUserSession } from "./../../lib/Common";
// import ValueContext from "./Context";
import UserContext from "../../pages/Context";
import DashboardContext from "./Context";
import TemplateApi from "../../api/TemplateApi";

import "./SidebarNew.css";
import classes from "./../SidebarNew/SidebarNew.module.css";
import bz from "./assets/bz white.svg";

class SidebarNew extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const { t } = this.props;
    const transPage = "sidebar.";

    this.state = {
      contentDropdown: false,
      jobPosterDropdown: false,
      settingDropdown: false,
      settingBZDropdown: false,
      crmDropdown: false,
      reportDropdown: false,
      templateDropdown: false,
      adminDropdown: true,
      iconReport: "rotate(0deg)",
      linkReport: "white",
      privilege: false,
      templates: [],
      siteSelected: {},
      tmp_id: 0,
      activeTmp: null,
      isClickTmp: false,
      imageSrc: "/img/bzpublish.png",
      // showSidebarValue: true,
      activeItems: "",
      thisRoute: "/",
      items: [
        {
          name: t(transPage + "content"),
          action: "contentDropdown",
          icon: "fa fa-palette",
          privilege: [1, 2, 3, 4, 5, 6, 7, 8],
          subscriptions: [1, 2, 5],
          child: [
            {
              index: 0,
              url: "/site-structure",
              icon: "fa fa-sitemap",
              privilege: [1, 4, 6],
              subscriptions: [1],
              name: t(transPage + "structure"),
            },
            // {
            //     index: 1,
            //     url: "#",
            //     icon: "fa fa-book",
            //     privilege: [1, 4, 6],
            //     subscriptions: [1],
            //     name: t(transPage + "pages"),
            // },
            {
              index: 2,
              url: "/menu-list",
              icon: "fa fa-location-arrow",
              privilege: [1, 4, 6],
              subscriptions: [1],
              name: t(transPage + "menu"),
            },
            {
              index: 3,
              url: "/article",
              icon: "fa fa-newspaper",
              privilege: [1, 2, 4, 6],
              subscriptions: [1],
              name: t(transPage + "articles"),
            },
            {
              index: 4,
              url: "/asset",
              icon: "fa fa-photo-video",
              privilege: [1, 2, 3, 4, 5, 6],
              subscriptions: [1, 2],
              name: t(transPage + "assets"),
            },
            {
              index: 20,
              url: "/location",
              icon: "fa fa-map-marker",
              privilege: [1, 2, 3, 4, 5, 6, 7, 8],
              subscriptions: [1, 2, 5],
              name: t(transPage + "location"),
            },
          ],
        },
        {
          name: t(transPage + "job_poster"),
          action: "jobPosterDropdown",
          icon: "fa fa-briefcase",
          activeState: this.jobPosterDropdown,
          privilege: [3, 5],
          subscriptions: [2],
          child: [
            {
              index: 5,
              url: "/job-poster",
              icon: "fa fa-circle",
              privilege: [3, 5],
              subscriptions: [2],
              name: t(transPage + "jobs"),
            },
          ],
        },
        {
          name: t(transPage + "settings"),
          action: "settingDropdown",
          icon: "fa fa-briefcase",
          activeState: this.settingDropdown,
          privilege: [4, 6, 5, 7],
          subscriptions: [1, 2, 5],
          child: [
            {
              index: 6,
              url: "/user",
              icon: "fa fa-user",
              privilege: [4, 5, 6, 7, 8],
              subscriptions: [1, 2, 5],
              name: t(transPage + "user"),
            },
            {
              index: 7,
              url: "/category",
              icon: "fa fa-layer-group",
              privilege: [4, 6],
              subscriptions: [1],
              name: t(transPage + "article_category"),
            },
            {
              index: 8,
              url: "/about",
              icon: "fa fa-info-circle",
              privilege: [4, 6],
              subscriptions: [1],
              name: t(transPage + "company_information"),
            },
            {
              index: 9,
              url: "/email-settings",
              icon: "fa fa-envelope",
              privilege: [4, 6],
              subscriptions: [1],
              name: t(transPage + "email_settings"),
            },
            // {
            //     index: 10,
            //     url: "/email-template",
            //     icon: "fa fa-envelope",
            //     privilege: [4, 6, 5],
            //     subscriptions: [1],
            //     name: t(transPage + "email_template"),
            // },
            // {
            //   index: 11,
            //   url: "/s3-bucket-settings",
            //   icon: "fa fa-archive",
            //   privilege: [4, 6],
            //   subscriptions: [1],
            //   name: t(transPage + "s3_bucket_settings"),
            // },
            {
              index: 12,
              url: "/ga-number-settings",
              icon: "far fa-chart-bar fa-fw",
              privilege: [4, 6],
              subscriptions: [1],
              name: t(transPage + "ga_number_code_settings"),
            },
            {
              index: 13,
              url: "/language",
              icon: "fas fa-language",
              privilege: [4, 5, 6],
              subscriptions: [1, 2],
              name: t(transPage + "language_settings"),
            },
          ],
        },
        {
          name: t(transPage + "settings_bz_admin"),
          action: "settingBZDropdown",
          icon: "fa fa-user-cog",
          activeState: this.settingBZDropdown,
          privilege: [99, 6],
          subscriptions: [1],
          child: [
            {
              index: 14,
              url: "/section/catalog",
              icon: "fa fa-align-left",
              privilege: [99, 6],
              subscriptions: [1],
              name: t(transPage + "sections_catalogue"),
            },
            {
              index: 15,
              url: "/section-categories",
              icon: "fa fa-tag",
              privilege: [99, 6],
              subscriptions: [1],
              name: t(transPage + "section_categories"),
            },
            {
              index: 16,
              url: "/widgets",
              icon: "fa fa-puzzle-piece",
              privilege: [99, 6],
              subscriptions: [1],
              name: t(transPage + "widgets"),
            },
            {
              index: 17,
              url: "/template",
              icon: "fa fa-pager",
              privilege: [99, 6],
              subscriptions: [1],
              name: t(transPage + "templates"),
            },
            // {
            //     index: 18,
            //     url: "/user",
            //     icon: "fa fa-user",
            //     privilege: [99, 6],
            //     subscriptions: [1, 2],
            //     name: t(transPage + "user"),
            // },
            {
              index: 19,
              url: "/language-json",
              icon: "fa fa-language",
              privilege: [99, 6],
              subscriptions: [1],
              name: t(transPage + "language_json"),
            },
          ],
        },

        {
          name: "CRM",
          action: "crmDropdown",
          icon: "fa fa-users",
          activeState: this.crmDropdown,
          privilege: [99, 5, 7, 8],
          subscriptions: [5],
          child: [
            {
              index: 21,
              url: "/customers",
              icon: "fa fa-user-tie",
              privilege: [99, 5, 7, 8],
              subscriptions: [5],
              name: "Customers",
            },
            {
              // index: 22,
              //   url: "/reports",
              icon: "fa fa-chart-line",
              iconc: " fas fa-angle-left",

              // action: "reportDropdown",
              // activeState: this.reportDropdown,

              privilege: [99, 5, 7, 8],
              subscriptions: [5],
              name: "Reports",
              child: [
                {
                  id: 1,
                  index: 1,
                  name: "Dashboard",
                  title: "Dashboard",
                  url: "/reports/dashboard",
                  icon: "fas fa-tachometer-alt",
                },
                {
                  id: 2,
                  index: 2,
                  name: "Memberships",
                  title: "Memberships",
                  url: "/reports/memberships",
                  icon: "fa fa-user-friends",
                },
                {
                  id: 3,
                  index: 3,
                  name: "Countries",
                  title: "Countries",
                  url: "/reports/countries",
                  icon: "fas fa-globe-asia",
                },
                {
                  id: 4,
                  index: 4,
                  name: "Leads",
                  title: "Leads",
                  url: "/reports/leads",
                  icon: "fa fa-headset",
                },
                {
                  id: 5,
                  index: 5,
                  name: "Sponsors",
                  title: "Sponsors",
                  url: "/reports/sponsors",
                  icon: "fa fa-money-check-alt",
                },
                {
                  id: 6,
                  index: 6,
                  name: "Sectors",
                  title: "Sectors",
                  url: "/reports/sectors",
                  icon: "fa fa-layer-group",
                },
                {
                  id: 7,
                  index: 7,
                  name: "Working groups",
                  title: "Working groups",
                  url: "/reports/working-groups",
                  icon: "fa fa-users-cog",
                },
                {
                  id: 8,
                  index: 8,
                  name: "Bilateral chambers",
                  title: "Bilateral chambers",
                  url: "/reports/bilateral-chambers",
                  icon: "fa fa-user-tie",
                },
              ],
            },
          ],
        },
      ],

      activeClientSite: false,
    };

    this.state.toggleClass = () => {
      this.setState({ reportDropdown: !this.state.reportDropdown });
    };

    this.state.toggleIcon = () => {
      this.setState({
        iconReport:
          this.state.iconReport === "rotate(0deg)"
            ? "rotate(-90deg)"
            : "rotate(0deg)",
      });
    };

    this.state.toggleActive = () => {
      this.setState({
        linkReport: this.state.linkReport === "white" ? "red" : "white",
      });
    };

    this.setDropdown = this.setDropdown.bind(this);
    this.getTemplates = this.getTemplates.bind(this);
  }

  componentDidMount() {
    const contextValue = this.context;
    const url = window.location.href;
    const newUrl = new URL(url);
    const pathN = newUrl.pathname;
    const siteId = localStorage.getItem("siteId");

    const groups = (contextValue?.user?.groups || []).map((e) => e.id);
    if (contextValue?.user?.is_superuser && !groups.includes(8)) {
      this.getTemplates(siteId);
    }

    if (pathN !== "/") {
      this.setDefaultActiveMenu(pathN);
    }

    this.setState(
      {
        privilege: contextValue?.user?.is_superuser
          ? contextValue?.user?.is_superuser
          : contextValue?.user?.groups
          ? contextValue?.user?.groups
          : false,
        activeClientSite: contextValue.activeClient,
        tmp_id: contextValue.activeTemplate,
      },
      () => {
        if (contextValue.activeClient) {
          contextValue.getSiteDetail(contextValue.activeSite);
        }
      }
    );
  }

  componentDidUpdate(prevState) {
    const contextValue = this.context;
    const thisRoute = this.state.thisRoute;
    const currentRoute = this.props.currentRoute;

    var isChange = true;
    const nextID = parseInt(localStorage.getItem("siteId"));
    // const prevID = parseInt(prevState.activeSite);
    const prevTmp = parseInt(prevState.activeTemplate);
    const currentTmpID = parseInt(localStorage.getItem("templateId"));

    if (this.state.activeClientSite !== contextValue.activeClient) {
      this.setState({
        activeClientSite: contextValue.activeClient,
      });
    }

    if (prevTmp !== currentTmpID) {
      isChange = true;
    } else {
      isChange = false;
    }

    if (this.state.isChange !== isChange) {
      this.setState({
        isChange: isChange,
        tmp_id: currentTmpID,
      });

      this.getTemplates(nextID);
    }

    if (thisRoute !== currentRoute) {
      this.setState(
        {
          thisRoute: currentRoute,
        },
        () => {
          this.setDefaultActiveMenu(currentRoute);
        }
      );
    }
  }

  getTemplates(value) {
    const contextValue = this.context;

    const groups = (contextValue?.user?.groups || []).map((e) => e.id);
    if (contextValue?.user?.is_superuser && !groups.includes(8)) {
      const objSite = new TemplateApi();

      objSite
        .getSites(value)
        .then((res) => {
          this.setState({
            templates: res.data.templates,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  setDefaultActiveMenu = (currentRoute) => {
    let index, side;
    this.state.items.forEach((dropdown) => {
      dropdown.child.forEach((route) => {
        if (route.url === currentRoute) {
          index = route.index;
          side = dropdown.action;
        }
      });
    });
    if (!index === undefined) {
      index = "";
      side = "";
    }

    this.setState(
      (prevState) => {
        return {
          activeItems: index,
          [side]: true,
        };
      },
      () => {
        window.scroll(0, 0);
      }
    );
  };

  setDropdown = (myState) => {
    this.setState((prevState) => {
      return {
        [myState]: !prevState[myState],
      };
    });
  };

  handleLogout = () => {
    const contextValue = this.context;

    removeUserSession();
    contextValue.removeDataUser();
    setTimeout(() => {
      this.props.history.push("/login");
    }, 500);
  };

  setActiveItems = (index) => {
    this.setState({
      activeItems: index,
    });
  };

  setActiveTmp = (val) => {
    const contextValue = this.context;
    localStorage.setItem("templateId", val);
    contextValue.changeActiveTemplate(val);
    this.setState({
      activeTmp: val,
      tmp_id: val,
    });
  };

  handleChangeClient = (value) => {
    const contextValue = this.context;

    if (value !== "x") {
      contextValue.changeActiveClient(value);
      this.setState(
        {
          activeClientSite: value,
        },
        () => {
          this.props.history.push("/");
        }
      );
    }
  };

  handleMenuItems = (x, menuItems) => {
    const { privilege } = this.state;
    let html;

    if (privilege && !privilege.length) {
      html = <>{menuItems}</>;
    } else if (privilege.length > 0) {
      let items = [];
      let idxItems = [];
      privilege.forEach((p) => {
        if (x.privilege.includes(p.index) && !idxItems.includes(x.name)) {
          idxItems.push(x.name);
          items.push(menuItems);
        }
      });
      html = <>{items}</>;
    }

    return html;
  };

  render() {
    const contextValue = this.context;
    const myUser = contextValue.user;
    const groups = (myUser?.groups || []).map((e) => e.index);
    const subsc = contextValue?.clientSubscriptions.length
      ? (contextValue?.clientSubscriptions || []).map((e) => e.id)
      : [];

    let Title = "Template";
    let action = "templateDropdown";
    let openMenuTemplate = this.state.templateDropdown;
    let iconTemplate = "fa fa-layer-group";
    let TemplateAll;
    if (contextValue.templateList.length > 0) {
      TemplateAll = contextValue.templateList.map((el) => {
        // console.log('t', el)
        const t = parseInt(localStorage.getItem("templateId"));

        let isActived = t === el.id ? true : false;

        let activeStyle = !this.props.showSidebarValue
          ? classes.padActive
          : classes.padInActive;

        return (
          <li
            className={`nav-item`}
            key={el.id}
            title={el.name}
            defaultValue={this.state.tmp_id}
            onClick={() => {
              this.setState(
                {
                  tmp_id: el.id,
                },
                () => this.setActiveTmp(el.id)
              );
            }}
          >
            <Link
              className={["nav-link", activeStyle].join(" ")}
              data-toggle={this.props.showSidebarValue ? "tooltip" : ""}
              data-placement="right"
            >
              <i className={isActived ? "fa fa-pager sd1-active-templates"  :  "fa fa-pager"}></i>
              <p className={isActived ? "sd1-active-templates" : ""}>{el.name}</p>
            </Link>
          </li>
        );
      });
    } else {
      TemplateAll = <></>;
    }

    let TemplateNav = (
      <NavItem
        menuOpen={openMenuTemplate}
        onClick={() => this.setDropdown(action)}
        icon={iconTemplate}
        title={Title}
        navClass={`nav-item-c`}
        showSidebarValue={this.props.showSidebarValue}
      >
        {TemplateAll}
      </NavItem>
    );

    //get report data
    let menuReport = [];
    this.state.items.map((itm, idx) => {
      if (itm.child && itm.child.length > 0) {
        itm.child.map((x, i) => {
          menuReport.push(x);
        });
      }
    });

    let dataRep = [];
    menuReport.map((data) => {
      if (data.name === "Reports") {
        data.child?.map((data) => {
          dataRep.push(data);
        });
      }
    });

    // end get report data

    // Privilege Checking

    let allItems = this.state.items.map((itm, idx) => {
      let children;
      if (itm.child && itm.child.length > 0) {
        children = itm.child.map((x, i) => {
          // console.log("index", x);
          let active = this.state.activeItems === x.index;
          let menuItems = (
            <DashboardContext.Consumer>
              {(dashboardContext) => (
                <>
                  <MenuItem
                    showSidebarValue={this.props.showSidebarValue}
                    icon={x.icon}
                    iconc={x.iconc}
                    url={x.url}
                    onClick={() => { x.name === 'Reports' &&
                      this.state.toggleClass();
                      this.state.toggleIcon();
                    }}
                    isActive={active}
                    onlyPages={[1, 22].includes(x.index)}
                    idx={i}
                  >
                    {x.name}
                    
                  </MenuItem>
                  {x.child && (
                    <>
                      {this.state.reportDropdown && (
                        <div
                          className="sidebarReportLink"
                          style={{
                            marginLeft: this.props.showSidebarValue
                              ? "0px"
                              : "20px",
                          }}
                        >
                          {dataRep.map((data, i) => (
                            <MenuItem
                              showSidebarValue={this.props.showSidebarValue}
                              icon={data.icon}
                              url={data.url}
                              // onClick={() => {
                              //   this.setActiveItems(data.index);
                              //   dashboardContext.setActiveSidebarItem(
                              //     data.index
                              //   );
                              // }}
                              exact
                              activeClassName="activeLink"
                              isActive={active}
                              idx={i}
                            >
                              {data.name}
                              {/* <NavLink
                                onClick={this.props.onClick}
                                to={data.url}
                                exact
                                activeClassName="activeLink"
                              >
                                {data.name}
                              </NavLink> */}
                            </MenuItem>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </DashboardContext.Consumer>
          );

          // Privilege Checking
          if (myUser?.is_superuser) {
            if (_.intersection(x.subscriptions, subsc).length > 0) {
              let html = this.handleMenuItems(x, menuItems);
              return html;
            }
          } else {
            if (_.intersection(x.privilege, groups).length > 0) {
              let html = this.handleMenuItems(x, menuItems);
              return html;
            }
          }

          return null;
        });
      }
      let openMenu;
      switch (idx) {
        case 0:
          openMenu = this.state.contentDropdown;
          break;
        case 1:
          openMenu = this.state.jobPosterDropdown;
          break;
        case 2:
          openMenu = this.state.settingDropdown;
          break;
        case 3:
          openMenu = this.state.settingBZDropdown;
          break;
        case 4:
          openMenu = this.state.crmDropdown;
          break;

        default:
          break;
      }

      let navItems = (
        <NavItem
          menuOpen={openMenu}
          onClick={() => this.setDropdown(itm.action)}
          icon={itm.icon}
          title={itm.name}
          navClass={`nav-item-c${idx}`}
          showSidebarValue={this.props.showSidebarValue}
        >
          {children}
        </NavItem>
      );

      if (myUser?.is_superuser) {
        if (_.intersection(itm.subscriptions, subsc).length > 0) {
          let html = this.handleMenuItems(itm, navItems);
          return html;
        }
      } else {
        if (_.intersection(itm.privilege, groups).length > 0) {
          let html = this.handleMenuItems(itm, navItems);
          return html;
        }
      }

      return null;
    });

    let superUserClient;
    if (contextValue.user?.is_superuser) {
      let isClient = !contextValue.activeClient &&
        !this.state.activeClientSite && (
          <i
            className="fa fa-arrow-left fa-lg bounce-right-animation"
            aria-hidden="true"
          ></i>
        );

      let clientList = (
        <select
          id="side-client-list"
          value={this.state.activeClientSite}
          className="form-control"
          onChange={(event) => this.handleChangeClient(event.target.value)}
        >
          <option value="x"> --- </option>
          {contextValue.clientList.map((item) => {
            return (
              <option value={item.id} key={item.id}>
                {" "}
                {item.name}
              </option>
            );
          })}
        </select>
      );

      superUserClient = (
        <div>
          {isClient}
          {clientList}
        </div>
      );
    } else {
      superUserClient = (
        <div id="side-client-list" className="form-control">
          {" "}
          {contextValue.clientInformation?.name}{" "}
        </div>
      );
    }

    let isTemplateShowing = true;

    if (!contextValue?.user?.is_superuser && groups.includes(7)) {
      isTemplateShowing = false;
    } else {
      if (
        contextValue?.user?.is_superuser &&
        _.intersection([1, 2], subsc).length > 0
      ) {
        isTemplateShowing = true;
      } else if (
        !contextValue?.user?.is_superuser &&
        _.intersection([1, 2, 3, 4, 6], groups).length > 0
      ) {
        isTemplateShowing = true;
      } else {
        isTemplateShowing = false;
      }
    }

    return (
      <div style={{ overflow: "hidden" }}>
        <aside className="main-sidebar main-sidebar-top sidebar-dark-primary elevation-4">
          <div
            className="sidebar"
            style={{
              height: "100%",
              overflow: "hidden",
              paddingBottom: "10vh",
            }}
          >
            <div className="user-panel mt-3 mb-3 d-flex dashboardCustom">
              <Link
                to={contextValue.user?.is_superuser ? "/superadmin" : "/"}
                style={{
                  position: "relative",
                  display: "flex",
                }}
              >
                {contextValue.clientInformation?.logo ? (
                  <div style={{ margin: "auto 0 auto 0.8rem" }}>
                    <img
                      src={contextValue.clientInformation?.logo}
                      alt=""
                      style={{ objectFit: "fill" }}
                    />
                  </div>
                ) : (
                  <div className="image img-avatar">
                    <i className="fa fa-user fa-2x"></i>
                  </div>
                )}
              </Link>
              <div className="info">{superUserClient}</div>
            </div>

            {isTemplateShowing && (
              <>
                <nav className="mt-2">
                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false"
                  >
                    {TemplateNav}
                  </ul>
                </nav>
                <div className={"borderLine"}>
                  <hr />
                </div>
              </>
            )}

            {/* picture */}
            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
                style={{ cursor: 'pointer' }}
              >
                {allItems}
                <MenuItem
                  showSidebarValue={this.props.showSidebarValue}
                  icon="fa fa-power-off"
                  onClick={this.handleLogout}
                >
                  Logout
                </MenuItem>
              </ul>
            </nav>
          </div>
          {/* <div className="logo-bz-publish">
            <div className="footer-bz-publish">
              <a href="https://baezeni.com/" target="_blank" rel="noreferrer">
                <img src={bz} alt="" />
              </a>
            </div>
          </div> */}
        </aside>
      </div>
    );
  }
}

export default withRouter(withTranslation()(SidebarNew));
