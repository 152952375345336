import axios from "axios";
import config from "./../config/config";
// import Cookies from "js-cookie";

export default class SiteApi {
    // base_api = "http://192.168.13.177:7000/"
    base_api = config.base_api;
    // token = config.token
    key = config.token;

    getAllSitesByClient(client) {
        return axios.get(this.base_api + `clients/${client}/`, {
            headers: {
                Authorization: `Token ${this.key}`,
                "Content-Type": "application/json",
            },
        });
    }

    getAllSitesByID(site) {
        return axios.get(this.base_api + `sites/${site}/`, {
            headers: {
                Authorization: `Token ${this.key}`,
                "Content-Type": "application/json",
            },
        });
    }

    getAllSites() {
        return axios.get(this.base_api + "sites/", {
            headers: {
                Authorization: `Token ${this.key}`,
                "Content-Type": "application/json",
            },
        });
    }

    getSiteDetail(siteId) {
        return axios.get(this.base_api + `sites/${siteId}/`, {
            headers: {
                Authorization: `Token ${this.key}`,
                "Content-Type": "application/json",
            },
        });
    }

    addSite(site) {
        return axios.post(this.base_api + "sites/", site, {
            headers: {
                Authorization: `Token ${this.key}`,
                "Content-Type": "application/json",
            },
        });
    }

    updateSite(siteID, site) {
        return axios.patch(this.base_api + `sites/${siteID}/`, site, {
            headers: {
                Authorization: `Token ${this.key}`,
                "Content-Type": "application/json",
            },
        });
    }

    getSiteStructure(templateId, params) {
        return axios.get(
            this.base_api + `templates/${templateId}/get_template_structure/`,
            {
                params: params,
                headers: {
                    Authorization: `Token ${this.key}`,
                    "Content-Type": "application/json",
                },
            }
        );
    }

    updateSiteStructure(id, data) {
        return axios.patch(
            this.base_api + `templates/${id}/get_template_structure/`,
            {
                page_update: data.page_update,
            },
            {
                headers: {
                    Authorization: `Token ${this.key}`,
                    "Content-Type": "application/json",
                },
            }
        );
    }
}
