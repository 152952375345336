import React from 'react'
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { GoogleMap, Marker } from "@react-google-maps/api";

import css from './LocationDetail.module.css'

function LocationDetail(props) {
    const containerStyle = {
        width: "100%",
        height: "100%",
    };

    const center = {
        lat: parseFloat(props.latitude) || -6.2088,
        lng: parseFloat(props.longitude) || 106.816,
    };

    return (
        <div className={["mb-2", css.base].join(' ')}>
            <div style={{ height: "120px" }}>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={15}
                    mapTypeId="roadmap"
                >
                    <Marker position={center} />
                </GoogleMap>
            </div>
            <div className={css.textBody}>
                <h3 className={css.title}>{props.title}</h3>
                {props.isMain && <p className={css.mainLocation}>Main Location</p>}
                <p className={css.subtitle1}>{props.subtitle1}</p>
                <p className={css.subtitle2}>{props.subtitle2}</p>
                <div className={css.detailBox}>
                    <p>P: {props.phone || "-"}</p>
                    <p>W: {props.website || "-"}</p>
                    <p>F: {props.fax || "-"}</p>
                    <p>E: {props.email || "-"}</p>
                </div>
            </div>
        </div>
    )
}

export default LocationDetail;