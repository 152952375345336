import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import swal from 'sweetalert';

import { withCookies, Cookies } from 'react-cookie';
import TitlePages from '../Elements/TitlePages';
import Card, { CardHeader, CardTitle, CardBody } from '../../components/Card';
import { Content, ContentHeader, ContentBody } from '../../components/Content';
import PageSettings from '../../components/Page/PageSettings';
import PageSectionAdd from '../../components/Modal/PageSectionAdd';
import SectionPair from '../../components/Modal/SectionPair';
import PageSectionDeleteConfirm from '../../components/Modal/PageSectionDeleteConfirm';
import PageApi from '../../api/PageApi';
import SectionApi from '../../api/SectionApi';
import { withTranslation } from 'react-i18next';
import config from '../../config/config';
import ReactGA from 'react-ga';
import { instanceOf } from 'prop-types';
import classes from './NewPageDetail.module.css';
import AlertHelper from '../../lib/AlertHelper';
import MetadataViewerModal from '../../components/Modal/MetadataViewerModal';
import AppContext from '../Context';

class NewPageDetail extends Component {
	static contextType = AppContext;
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired,
	};
	constructor(props) {
		super(props);
		const { cookies } = props;
		this.state = {
			sectionId: 0,
			sectionParentId: 0,

			page: {
				sections: [],
			},
			selectedSections: [],
			deleteDisable: true,
			showPairModal: false,
			showModal: false,
			showModalDelete: false,
			showMetadataViewerModal: false,
			pageHasParams: false,
			isDraft: false,
			idPages: null,
			styles: [],
			totalSectionAdded: 0,
			columns: {
				'droppable-1': {
					id: 'droppable-1',
					title: 'sections',
					sectionIds: [],
				},
			},
			columnOrder: ['droppable-1'],
			isCollapse: true,
			use_page_desc: false,
			transAlert: 'alert.',
			client: cookies.get('client'),
			names: '',
		};

		// this.getPageSections = this.getPageSections.bind(this)
		// this.getPageDetailNew = this.getPageDetailNew.bind(this);
		this.getPageDetailNew = this.getPageDetailNew.bind(this);
		this.useQuery = this.useQuery.bind(this);
		this.setSelectedItem = this.setSelectedItem.bind(this);
		this.setShowModal = this.setShowModal.bind(this);
		this.setShowPairModal = this.setShowPairModal.bind(this);
		this.setShowModalDelete = this.setShowModalDelete.bind(this);
		this.deleteSelectedSection = this.deleteSelectedSection.bind(this);
		this.setItemId = this.setItemId.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.generateMetadata = this.generateMetadata.bind(this);
	}

	useQuery() {
		// return new URLSearchParams(useLocation().search);
	}

	componentDidMount() {
		ReactGA.initialize(config.GA_ID);
		ReactGA.pageview(window.location.pathname + window.location.search);
		const pageId = this.props.match.params.pageId;

		this.setState({
			idPages: pageId,
			names: this.props.location?.state?.data,
		});
		this.getPageDetailNew(pageId);
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			const pageId = this.props.match.params.pageId;
			this.setState({ names: this.props.location?.state?.data });
			this.getPageDetailNew(pageId);
		}
	}

	setShowModal() {
		this.setState({
			showModal: !this.state.showModal,
		});
	}

	setShowPairModal() {
		this.setState({
			showPairModal: !this.state.showPairModal,
		});
	}

	setShowModalDelete() {
		this.setState({
			showModalDelete: !this.state.showModalDelete,
		});
	}

	getPageDetailNew(pageId) {
		const s = new SectionApi();
		s.getPageSections(pageId).then((res) => {
			const page = res.data;
			let dataStyle = res.data.styles;
			const total = res.data.sections.length;

			this.setState({
				page: page,
				totalSectionAdded: total,
				pageHasParams: res.data.has_params,
				paramsQuantity: res.data.params_qty,
				use_page_desc: res.data.use_page_description,
				pageType: res.data.page_type,
				isDraft: res.data.is_draft,
				styles: dataStyle,
				columns: {
					...this.state.columns,
					'droppable-1': {
						...this.state.columns['droppable-1'],
						sectionIds: res.data.sections,
					},
				},
			});
		});
	}

	setSelectedItem(section, value) {
		var lastSelected = [...this.state.selectedSections];
		var indexDelete = -1;

		if (value === true) {
			lastSelected.push(section);
		} else {
			this.state.selectedSections.forEach((item, index) => {
				if (item.id === section.id) {
					indexDelete = index;
				}
			});

			lastSelected.splice(indexDelete, 1);
		}

		this.setState(
			{
				selectedSections: lastSelected,
			},
			() => {
				if (this.state.selectedSections.length > 0) {
					this.setState({
						deleteDisable: false,
					});
				} else {
					this.setState({
						deleteDisable: true,
					});
				}
			}
		);
	}

	deleteSelectedSection() {
		const { t } = this.props;
		const objPage = new PageApi();
		const pageId = this.props.match.params.pageId;

		const sections = this.state.selectedSections.map((item) => {
			return item.id;
		});

		objPage
			.pageSectionDeleteNew(sections)
			.then((res) => {
				this.setState({
					showModalDelete: false,
					disableDelete: true,
					selectedSections: [],
				});
				this.getPageDetailNew(pageId);
				swal('Success !', t(this.state.transAlert + 'delete_widget_in_section'), 'success');

				setTimeout(() => {
					window.location.reload();
				}, 100);
			})
			.catch((err) => {
				swal('Error!', err.message, 'error');
			});
	}

	setItemId(value) {
		this.setState(
			{
				sectionId: value,
			},
			() => {
				this.setShowModal();
			}
		);
	}

	setShowMetadataViewer(sectionId, isShowing) {
		this.setState({ sectionParentId: sectionId }, () => {
			this.setState({ showMetadataViewerModal: isShowing });
		});
	}

	onDragEnd(result) {
		const { destination, source } = result;

		if (!destination) {
			return;
		}

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		const column = this.state.columns[source.droppableId];

		const newSectionIds = Array.from(column.sectionIds);
		newSectionIds.splice(source.index, 1);
		newSectionIds.splice(destination.index, 0, column.sectionIds[source.index]);

		const objSection = new SectionApi();

		const ids = newSectionIds.map((item) => {
			return item.id;
		});

		objSection
			.sectionIndexUpdateNew(ids)
			.then((res) => {
				const result = res.data.map((item) => {
					const findSec = newSectionIds.find((row) => row.id === item.id);

					return {
						...findSec,
						index: item.index,
					};
				});

				// ===== pengubah state ====

				const newColumn = {
					...column,
					sectionIds: result,
				};

				const newColumnState = {
					...this.state.columns,
					[source.droppableId]: newColumn,
				};

				this.setState({
					columns: newColumnState,
				});

				// ======= pengubah state =============
			})
			.catch((err) => {});
	}

	generateMetadata(sectionId) {
		const sectionApi = new SectionApi();

		sectionApi
			.generateMetadata(sectionId)
			.then((res) => {
				swal('Success !', res.data.message, 'success');
			})
			.catch((err) => {
				const objAlert = new AlertHelper();
				objAlert.errorHandlerAlert(err.response.data);
			});
	}

	render() {
		const contextValue = this.context;
		const myUser = contextValue.user;
		const groups = (myUser?.groups || []).map((e) => e.index);
		const { t } = this.props;

		let value_behavior, value_block, is_checked_scroll, id_opt_behavior, id_opt_block;

		let hasParams = this.state.pageHasParams;
		let paramQty = this.state.paramsQuantity;

		const pageId = this.props.match.params.pageId;

		const SectionList = this.state.columns['droppable-1'].sectionIds.map((item, index) => {
			var menuWidgetButton = '';
			if (item.menu !== null) {
				//const menuWidget = item.menu
				menuWidgetButton = (
					<Link to={`/menu-list/${item.menu}`} title='Section Menu '>
						<Button className='btn-sm mr-1' variant='default'>
							<i className='fas fa-bars'>&nbsp;</i>
						</Button>
					</Link>
				);
			}

			return (
				<Draggable draggableId={'draggable-' + index} index={index} key={index}>
					{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							{...provided.draggableProps}
							{...provided.dragHandleProps}
							// disableinteractiveelementblocking
						>
							<div className='col-md-12'>
								<Card className='card  card-primary'>
									<CardHeader className='bg bg-default'>
										<CardTitle>
											<input
												id={item.name + '-' + item.id}
												className=''
												type='checkbox'
												onClick={(event) => this.setSelectedItem(item, event.currentTarget.checked)}
											></input>
											&nbsp;
											<label htmlFor={item.name + '-' + item.id}>
												{' '}
												{item.index}. {item.name}
											</label>
										</CardTitle>
										<div className='card-tools'>
											<AppContext.Consumer>
												{(app) =>
													app.user?.is_superuser ||
													(groups.includes(6) && (
														<>
															<Button
																className='btn-sm mr-1'
																variant='default'
																title='Generate Metadata'
																onClick={() => {
																	this.generateMetadata(item.id);
																}}
															>
																<i className='fas fa-sync'></i>
															</Button>
															<Button
																className='btn-sm mr-1'
																variant='default'
																title='View Metadata'
																onClick={() => {
																	this.setShowMetadataViewer(item.parent, true);
																}}
															>
																<i className='fas fa-code'></i>
															</Button>
															<Link to={`/section/developer/${item.id}`}>
																<Button className='btn-sm mr-1' variant='default' title='To Developer Mode'>
																	<i className='fas fa-external-link-alt'></i>
																</Button>
															</Link>
														</>
													))
												}
											</AppContext.Consumer>
											<Button className='btn-sm mr-1' variant='default' onClick={() => this.setItemId(item.id)} title='Edit Section'>
												<i className='fa fa-edit'></i>
											</Button>
											{menuWidgetButton}
											<Link to={`/section/detail/${item.id}`}>
												<Button className='btn-sm mr-1' variant='default' title={`To Section ${item.name} `}>
													<i className='fa fa-arrow-right'></i>
												</Button>
											</Link>
										</div>
									</CardHeader>
									<CardBody>
										<div
											className='row'
											style={{
												backgroundColor: '#E9E9E9',
												padding: '-40% -60%',
											}}
										>
											<img
												height='300'
												src={item.screenshot ? `${item.screenshot}` : '/img/bz-publish.svg'}
												alt={item.name}
												style={{
													marginRight: 'auto',
													marginLeft: 'auto',
													width: '100%',
													height: '100%',
												}}
												className
											></img>
										</div>
									</CardBody>
								</Card>
							</div>
						</div>
					)}
				</Draggable>
			);
		});

		return (
			<Content>
				<ContentHeader>
					{/* <h1> Page : {this.state.page.name} </h1> */}
					<TitlePages
						title={`${t('pages.new_page_detail.section_list')} : ${t('pages.new_page_detail.title')}  (${this.state.page?.name || ''})`}
					/>
				</ContentHeader>

				<ContentBody>
					<Card>
						<CardHeader>
							{/* <CardTitle>
                                {" "}
                                {t("pages.new_page_detail.section_list")} :{" "}
                            </CardTitle> */}
							<Button onClick={() => this.props.history.goBack()} className='btn btn-default col-md-1 mt-3'>
								<i className='fa fa-arrow-left'>&nbsp;</i>
								{t('back')}
							</Button>

							<div className='card-tools'>
								<Button
									variant='danger'
									onClick={() => this.setShowModalDelete()}
									disabled={this.state.deleteDisable}
									style={{ marginRight: 5 }}
								>
									<i className='fa fa-trash'>&nbsp;</i>
									{t('delete_selected')}
								</Button>
								<Button onClick={() => this.setState({ sectionId: 0 }, () => this.setShowPairModal())}>
									<i className='fa fa-plus'>&nbsp;</i>
									{t('add_section')}
								</Button>
							</div>
						</CardHeader>
						<CardBody>
							<div className='row'>
								<div className='col-md-9'>
									<DragDropContext className='row' onDragEnd={(result) => this.onDragEnd(result)}>
										<Droppable droppableId='droppable-1' type='SECTION'>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													style={{
														backgroundColor: snapshot.isDraggingOver ? 'white' : 'white',
														height: '100%',
													}}
													{...provided.droppableProps}
												>
													{/* {SectionList} */}

													{this.state.totalSectionAdded !== 0 ? (
														SectionList
													) : (
														<div className='col-md-12 center-block'>
															<h4 className='text-center'>There is no Section added</h4>
														</div>
													)}

													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</DragDropContext>
								</div>
								<div className='col-md-3'>
									<PageSettings
										id={this.props.match.params.pageId}
										hasParams={hasParams}
										isDraft={this.state.isDraft}
										paramQty={paramQty}
										pageType={this.state.pageType}
										getPageDetailNew={this.getPageDetailNew}
										valueBehavior={value_behavior}
										valueBlock={value_block}
										isChecked={is_checked_scroll}
										idBehavior={id_opt_behavior}
										idBlock={id_opt_block}
										use_page={this.state.use_page_desc}
									></PageSettings>
								</div>
							</div>

							<div className='clearfix'></div>
						</CardBody>
					</Card>
				</ContentBody>
				<PageSectionAdd
					id={this.state.sectionId}
					pageId={pageId}
					client={this.state.client}
					show={this.state.showModal}
					onHide={this.setShowModal}
					getPageDetailNew={this.getPageDetailNew}
				></PageSectionAdd>
				<SectionPair
					id={this.state.sectionId}
					pageId={pageId}
					client={this.state.client}
					show={this.state.showPairModal}
					onHide={this.setShowPairModal}
					getPageDetail={this.getPageDetailNew}
				></SectionPair>
				<PageSectionDeleteConfirm
					show={this.state.showModalDelete}
					onHide={this.setShowModalDelete}
					selectedSections={this.state.selectedSections}
					deleteSelectedSection={this.deleteSelectedSection}
				></PageSectionDeleteConfirm>
				<MetadataViewerModal
					sectionId={this.state.sectionParentId}
					show={this.state.showMetadataViewerModal}
					onHide={() => {
						this.setShowMetadataViewer(0, false);
					}}
				/>
			</Content>
		);
	}
}

export default withTranslation()(withCookies(NewPageDetail));
