import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export function exportToExcel(apiData, fileName) {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};

export function exportToExcelMultiple(apiDatas, sheetNames, fileName) {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    let wb = { Sheets: {}, SheetNames: sheetNames };

    sheetNames.forEach((sheetName, idx) => {
        wb.Sheets[sheetName] = XLSX.utils.json_to_sheet(apiDatas[idx]);
    })

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};