import axios from "axios"
import config from "./../config/config"

class ArticleApi {

    // base_api = "http://192.168.13.177:7000/"
    base_api = config.base_api
    token = config.token
    //token = "7e142a22850ebf92be390c473ce16cb4593651f7"

    getAllArticles(params){
        return axios.get(this.base_api+"articles/",{
            params:params,
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    getDetailArticles(articleId){
        let lang = localStorage.getItem('langId')
        return axios.get(this.base_api+`articles/${articleId}/`,{
            params:{
                language: lang ? lang : 1
            },
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    getArticleCategories(params) { 
        return axios.get(this.base_api+`category_articles/`,{
            params:params,
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    getArticleCollection(params) { 
        return axios.get(this.base_api+`widgets/`,{
            params:params,
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    getDetailArticleCategory(categoryId) {
        return axios.get(this.base_api+`category_articles/${categoryId}/`,{
         
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    addArticle(data){
        return axios.post(this.base_api+`articles/`,data,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    updateArticle(articleId, data){
        return axios.patch(this.base_api+`articles/${articleId}/`,data,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    addKeywords(data){
        return axios.post(this.base_api+`article_keywords/`,data,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    updateKeywords(keywordId, data){
        return axios.patch(this.base_api+`article_keywords/${keywordId}/`,data,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    deleteKeywords(keywordId) {
        return axios.delete(this.base_api+`article_keywords/${keywordId}/`,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    changeMultipleArticleStatus(articles ){
        return axios.patch(this.base_api+`article_status/`,{
            ids:articles
        },{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    } 

    articleAssetChange(articleAssetId, assetId) {
        return axios.patch(this.base_api+`article_assets/${articleAssetId}/`,{
            asset:assetId
        },{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    // the assets form is [1,2,3]
    // if assets empty / [] , which mean the article didnt had assets
    articleAssetPair(articleId, assets) {
        return axios.patch(this.base_api+`articles/${articleId}/pair_assets/`,{
            assets:assets
        },{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    articleAssetUnpair(articleId, assets) {
        return axios.patch(this.base_api+`articles/${articleId}/unpair_assets/`,{
            assets:assets
        },{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    deleteArticle(articleId) {
        return axios.delete(this.base_api+`articles/${articleId}/`,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    deleteMultiArticle(articles) {
        return axios.delete(this.base_api+`articles/delete_articles/`,{
            data:{
                articles:articles
            },
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }
    
    // {"widget_id":1, "article_ids":[41, 39]}
    widgetArticleIndexUpdate(widget_id, article_ids) {
        return axios.patch(this.base_api+`widget_article_index_update/`,{
            
            widget_id:widget_id,
            article_ids:article_ids
            
        },{
        
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

}

export default ArticleApi