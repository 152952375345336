import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"
import { withTranslation } from 'react-i18next';

class DeletePositionConfirm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            transPage: "modals.position_delete."
        }

        this.deleteSelectedPosition = this.deleteSelectedposition.bind(this)
        this.onOpenModal = this.onOpenModal.bind(this)
        // this.onHideModal = this.onHideModal.bind(this)
    }

    deleteSelectedposition() {
        // dari props method nya
        this.props.deleteSelectedPosition() 
    }

    onOpenModal() {
    }

    onCloseModal() {
        this.props.onHide()
    }

    
    render() {
        const {t} = this.props;
        const listPositions = this.props.selectedPositions.map(item => {
            return (<li key={item.id}>{ item.name }</li>)
        })

        return (
            <Modal show={this.props.show} onHide={() => this.onCloseModal()} onShow={() => this.onOpenModal()}>
                {this.props.loadingModal}
                <Modal.Header closeButton className="bg-danger">
                    <Modal.Title>{t(this.state.transPage + "title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t(this.state.transPage + "confirmation_text")}</p>
                    <ul>
                        {listPositions}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.props.onHide()}>
                        {t('cancel')}
                    </Button>
                    <Button variant="danger" onClick={() => this.deleteSelectedPosition()}>
                        <i className="fa fa-trash">&nbsp;</i>
                        {t(this.state.transPage + "delete_position")}
                    </Button>   
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(DeletePositionConfirm);