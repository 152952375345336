import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
// import withReactContent from "sweetalert2-react-content";
import PageApi from "../../api/PageApi";
// import MenuApi from "../../api/MenuApi"
import SectionApi from "../../api/SectionApi";
// import SectionCategoryApi from "../../api/SectionCategoryApi";
// import GeneralApi from "../../api/GeneralApi";
import AlertHelper from "./../../lib/AlertHelper";
import { withTranslation } from "react-i18next";
import Context from "../../pages/Context";

class PageSectionCatalogAdd extends Component {
	static contextType = Context;
	constructor(props) {
		super(props);

		this.state = {
			id: 0,
			pageId: 0,
			name: "",
			pages: [],
			index: 0,
			file: {
				name: "",
			},
			selectedSections: [],
			names: [],
			languageList: [],
			tabKey: 1,
			selectedLanguage: 1,
			renderTabLanguage: "",
			isMetadataLocked: false,

			clientType: false,
			catalogtype: [],
			catalog: "",
			transPage: "modals.section_catalog_add.",
			transAlert: "alert.",
			file_ss: {},
			file_metadata: {},
			isAvailableValue: null,
			isAvailableMetadata: null,
			isAvailableImages: null,
			isDisplayMetadata: null,
			isImages: false,
			errMsg: "",
			isDone: false,
			overlay: "",
			isLoading: false,
		};

		this.handleNameChangeNew = this.handleNameChangeNew.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
		this.handleCatalogChange = this.handleCatalogChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChangeTab = this.handleChangeTab.bind(this);
		this.onOpenModal = this.onOpenModal.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);
		this.getPages = this.getPages.bind(this);
		this.getAllCatalogsType = this.getAllCatalogsType.bind(this);
		// this.setSelectedItem = this.setSelectedItem.bind(this)
		this.sectionCatalogDetail = this.sectionCatalogDetail.bind(this);
		this.setOverlay = this.setOverlay.bind(this);
	}

	componentDidUpdate() {
		var isDone = false;
		if (this.state.isDone !== isDone) {
			this.setState({
				isDone: isDone,
			});
		}
	}

	handleNameChangeNew(value) {
		this.setState(
			{
				name: value,
			}
		);
	}

	handleFileChange(e, key) {
		if (key === "zip") {
			this.setState({
				file: e.target.files[0],
				isAvailableValue: e.target.value,
			});
		} else if (key === "metadata") {
			this.setState({
				file_metadata: e.target.files[0],
				isAvailableMetadata: e.target.value,
			});
		} else if (key === "images") {
			if (e.target.files) {
				if (
					e.target.files[0].name === "screenshot.jpg" ||
					e.target.files[0].name === "screenshot.png" ||
					e.target.files[0].name === "screenshot.jpeg"
				) {
					this.setState({
						errMsg: "",
					});
				} else {
					this.setState({
						errMsg: "Please input file with name `screenshot.png/.jpg/.jpeg`",
					});
				}
				this.setState({
					file_ss: e.target.files[0],
					isAvailableImages: e.target.value,
				});
			}
		}
	}

	onOpenModal() {
		this.getAllCatalogsType();
		const { adminSiteLanguage } = this.context;
		const _this = this;

		this.setState(
			{
				languageList: adminSiteLanguage,
				id: this.props.id,
				isAvailableValue: null,
				isDisplayMetadata: null,
				isAvailableImages: null,
			},
			() => {
				if (this.state.id) {
					_this
						.sectionCatalogDetail(this.state.id)
						.then((res) => {
							this.setState({
								clientType: res.data.client,
								name: res.data.name,
								catalog: res.data.catalog_type ? res.data.catalog_type : "",
								isMetadataLocked: res.data.metadata_locked,
							});
							//     () => {
							//       this.renderTabLanguage();
							//     }
							//   );
						})
						.catch((err) => {
							console.log(err.response);
							//   const objAlert = new AlertHelper();
							//   const resErr = err?.response?.data
							//     ? err?.response?.data
							//     : err.message;

							//   objAlert.errorHandlerAlert(resErr);
						});
				} else {

				}
			}
		);
	}

	onCloseModal() {
		this.setState(
			{
				id: 0,
				pageId: 0,
				name: "",
				pages: [],
			},
			() => {
				this.props.onHide();
			}
		);
	}

	getPages(params) {
		const objPage = new PageApi();

		objPage.getAllPages(params).then((res) => {
			const result = res.data.results.map((item) => {
				return {
					id: item.id,
					name: item.name,
				};
			});

			this.setState({
				pages: result,
			});
		});
	}

	getAllCatalogsType(callback) {
		const s = new SectionApi();
		s.getCatalogType().then((res) => {
			this.setState(
				{
					catalogtype: res.data,
				},
				callback
			);
		});
	}

	setLoading() {
		this.setState(
			{
				isLoading: !this.state.isLoading,
			},
			() => {
				this.setOverlay();
			}
		);
	}

	setOverlay() {
		var overlay = "";
		if (this.state.isLoading) {
			overlay = (
				<div className="overlay d-flex justify-content-center align-items-center">
					<i className="fa fa-2x fa-sync fa-spin"></i>
				</div>
			);
		} else {
			overlay = "";
		}

		this.setState({
			overlay: overlay,
		});
	}

	sectionCatalogDetail(sectionId) {
		const objSection = new SectionApi();

		return objSection.sectionCatalogDetailNew(sectionId);
	}

	handleSubmit() {
		this.setLoading();
		const objSection = new SectionApi();
		const { t } = this.props;
		const formData = new FormData();
		const { activeClient } = this.context;


		formData.append("zip_files", this.state.file);
		formData.append("client", activeClient);
		formData.append("catalog_type", "section");

		objSection
			.sectionAddCatalogNew(formData)
			.then((res) => {
				this.props.onHide();
				swal("Success !", t(this.state.transAlert + "section_add_success"), "success");
				this.setLoading();
				this.props.getSectionCatalog();
				setTimeout(() => {
					window.location.reload();
				}, 0);
			})
			.catch((err) => {
				console.log(err.response);
			});
	}

	handleSectionUpdate() {
		this.setLoading();
		const { t } = this.props;
		const objSection = new SectionApi();
		const sectionId = this.state.id;
		const formData = new FormData();
		const { activeClient } = this.context;
		const { clientType } = this.state

		if (this.state.file.name) {
			formData.append("id", this.state.id);
			// formData.append("client", this.props.client.id);
			formData.append("client", clientType ? clientType : activeClient);
			formData.append("name", this.state.name);
			formData.append("catalog_type", this.state.catalog);
			formData.append("zip_file", this.state.file);

			//   this.state.names.forEach((item, index) => {
			//     formData.append(`section_languages[${index}]language`, item.language);
			//     formData.append(`section_languages[${index}]name`, item.name);
			//   });
		} else {
			if (this.state.file_ss && !this.state.isAvailableMetadata) {
				formData.append("id", this.state.id);
				// formData.append("client", this.props.client.id);
				formData.append("client", activeClient);
				formData.append("name", this.state.name);
				formData.append("catalog_type", this.state.catalog);
				formData.append("screenshot", this.state.file_ss);
			} else if (this.state.file_metadata && !this.state.isAvailableImages) {
				formData.append("id", this.state.id);
				// formData.append("client", this.props.client.id);
				formData.append("client", activeClient);
				formData.append("name", this.state.name);
				formData.append("catalog_type", this.state.catalog);
				formData.append("metadata_file", this.state.file_metadata);
			} else if (this.state.file_metadata && this.state.file_ss) {
				formData.append("id", this.state.id);
				// formData.append("client", this.props.client.id);
				formData.append("client", activeClient);
				formData.append("name", this.state.name);
				formData.append("catalog_type", this.state.catalog);
				formData.append("metadata_file", this.state.file_metadata);
				formData.append("screenshot", this.state.file_ss);
			}
		}
		formData.append("metadata_locked", this.state.isMetadataLocked)

		objSection
			.sectionCatalogUpdateNew(sectionId, formData)
			.then((res) => {
				this.props.onHide();
				this.setLoading();
				swal("Success !", t(this.state.transAlert + "section_update_success"), "success");
				this.props.getSectionCatalog()
				this.setState({
					isDone: true,
				});
			})
			.catch((err) => {
				this.setLoading();
				const objAlert = new AlertHelper();
				const resErr = err?.response?.data ? err?.response?.data : err.message;
				objAlert.errorHandlerAlert(resErr);
			});
	}

	renderTabLanguage() {
		const renderTabLanguage = this.state.languageList.map((item, index) => {
			var findName = this.state.names.find(
				(textLang, idx) => item.id === textLang.language
			);
			return {
				language: item?.language?.id,
				languageName: item.name,
				name: findName?.name,
			};
		});

		this.setState({
			names: renderTabLanguage,
		});
	}

	handleChangeTab(value) {
		this.setState(
			{
				selectedLanguage: value,
				tabKey: value,
			},
			() => {
				this.renderTabLanguage();
			}
		);
	}

	handleCatalogChange(e) {
		this.setState({ catalog: e });
	}

	handleChangeClientType = (value) => {
		this.setState({ clientType: value });
	}

	render() {
		var isImage = this.state.isAvailableValue !== null ? true : false;
		var isMeta = this.state.isAvailableValue !== null ? true : false;
		const { t } = this.props;

		let FormTwo, SectionName, HeaderText, BtnSubmit;
		// let isNone = this.state.isAvailableValue !== null ? "none" : "inherit";
		let isNoneZIP =
			this.state.isAvailableImages !== null ||
				this.state.isAvailableMetadata !== null
				? "none"
				: "inherit";

		if (this.state.id) {
			SectionName = (
				<div className="form-group">
					<label> Name </label>
					<input
						type="text"
						className="form-control"
						onChange={(event) => this.handleNameChangeNew(event.target.value)}
						defaultValue={this.state.name}
					></input>
				</div>
			);

			HeaderText = t(this.state.transPage + "update_section");
			BtnSubmit = (
				<Button variant="primary" onClick={() => this.handleSectionUpdate()}>
					<i className="fa fa-edit">&nbsp;</i>
					{t(this.state.transPage + "update_section")}
				</Button>
			);

			FormTwo = (
				<>
					{" "}
					<div className="form-group">
						<label> {t("screenshot")} </label>
						<input
							type="file"
							className="form-control"
							onChange={(event) => this.handleFileChange(event, "images")}
							accept=".svg,.png,.jpg,.jpeg"
							disabled={isImage}
						></input>
						<p style={{ color: "#b60a0a" }}>{this.state.errMsg}</p>
					</div>
					<div className="form-group">
						<label> {t("metadata")} </label>
						<input
							type="file"
							className="form-control"
							onChange={(event) => this.handleFileChange(event, "metadata")}
							accept=".json"
							disabled={isMeta}
						></input>
					</div>
					<div className="form-group form-check">
						<input
							type="checkbox"
							className="form-check-input"
							id="metadata-locked-checkbox"
							checked={this.state.isMetadataLocked}
							onChange={(event) => { this.setState({ isMetadataLocked: event.target.checked }) }}
						></input>
						<label htmlFor="metadata-locked-checkbox"> {t(this.state.transPage + "metadata_locked")} </label>
					</div>
				</>
			);
		} else {
			HeaderText = t(this.state.transPage + "add_section");
			BtnSubmit = (
				<Button variant="primary" onClick={() => this.handleSubmit()}>
					<i className="fa fa-plus">&nbsp;</i>
					{t(this.state.transPage + "add_section")}
				</Button>
			);
			SectionName = "";
			FormTwo = <></>;
		}

		const CatalogOptions = this.state.catalogtype.map((item, i) => {
			return (
				<option key={i} value={item}>
					{item}
				</option>
			);
		});

		const contextValue = this.context
		const { clientList } = this.context
		const { clientType } = this.state

		let isSuperUser
		if (contextValue?.user.is_superuser) {
			isSuperUser = true
		}
		return (
			<Modal
				show={this.props.show}
				onHide={() => this.onCloseModal()}
				onShow={() => this.onOpenModal()}
			>
				{this.state.overlay}
				<Modal.Header closeButton className="bg-primary">
					<Modal.Title> {HeaderText} </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form>
						{SectionName}

						<div className="form-group" style={{ display: isNoneZIP }}>
							<label> {t("zip_file")} </label>
							<input
								type="file"
								className="form-control"
								onChange={(event) => this.handleFileChange(event, "zip")}
								accept=".zip,.rar,.7zip"
							></input>
						</div>

						{this.state.id ? (
							<>
								{
									isSuperUser &&
									<div className="form-group">
										<label>Client: </label>
										<select className="form-control" value={clientType ? clientType : "x"} onChange={(event) => this.handleChangeClientType(event.target.value)}>
											<option value="x">-- Select Client --</option>
											{(clientList && clientList.length > 0) && clientList.map((item) => {
												return (
													<option value={item.id}>{item.name}</option>
												);
											})}
										</select>
									</div>
								}
								<div className="form-group">
									<label htmlFor="select-catalog">Catalog Type</label>
									<select
										className="form-control"
										id="select-catalog"
										onChange={(event) =>
											this.handleCatalogChange(event.target.value)
										}
										value={this.state.catalog}
									>
										<option value="">{t("none")}</option>
										{CatalogOptions}
									</select>
								</div>
							</>
						) : (
							<></>
						)}

						{FormTwo}
					</form>
				</Modal.Body>
				<Modal.Footer>{BtnSubmit}</Modal.Footer>
			</Modal>
		);
	}
}

export default withTranslation()(PageSectionCatalogAdd);
