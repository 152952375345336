import React, { useMemo } from "react";
import { Card, Table, Accordion, Button } from "react-bootstrap";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    ArcElement,
    Legend
} from 'chart.js';
import { Link } from "react-router-dom";
import { exportToExcel } from '../../plugins/exportToExcel/exportToExcel'
import CardChart from './CardChart/CardChart'

ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    Tooltip,
    Legend,
);

function Memberships(props) {
    const { membersUpgrade, membersDowngrade, membersDiscontinue, membersJoined, membersMembershipType, membersMembershipStatus } = props.data

    const totalMembersJoined = useMemo(() => membersJoined.reduce((prev, value) => {
        return prev + value.count
    }, 0), [membersJoined])

    const totalMembersUpgrade = useMemo(() => membersUpgrade.reduce((prev, value) => {
        return prev + value.count
    }, 0), [membersUpgrade])

    const totalMembersDowngrade = useMemo(() => membersDowngrade.reduce((prev, value) => {
        return prev + value.count
    }, 0), [membersDowngrade])

    const totalMembersDiscontinue = useMemo(() => membersDiscontinue.reduce((prev, value) => {
        return prev + value.count
    }, 0), [membersDiscontinue])

    const membersData = [...membersJoined, ...membersUpgrade, ...membersDowngrade, ...membersDiscontinue]

    const handleDownloadTable = (data) => {
        let dataToExport = []
        let num = 1
        data.forEach(category => {
            category?.members?.forEach(member => {
                dataToExport.push({
                    'No': num,
                    'Company': member.member__name,
                    'Description': category?.variable,
                    'Timestamp': member.timestamp
                })
                num++
            })
        })

        exportToExcel(dataToExport, "Reports")
    }

    return (
        <div>
            <div className='row'>
                <div className="col-lg-4">
                    <Card>
                        <Card.Header>
                            <Button
                                size="sm"
                                variant="outline-primary"
                                onClick={() => handleDownloadTable(membersData)}
                            >
                                <i className="fas fa-download"></i>
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Membership</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <Accordion as="tbody">
                                    {
                                        membersData.map((report, idx) => (
                                            <>
                                                <Accordion.Toggle eventKey={report.variable + idx} as="tr" style={{ cursor: "pointer" }}>
                                                    <td>{report.variable}</td>
                                                    <td>{report.count}</td>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={report.variable + idx}>
                                                    <div className="pb-4 ml-3">
                                                        {report.members.map(member => (<div><Link to={`/customers/detail/${member.member__id}`}>{member.member__name}</Link></div>))}
                                                    </div>
                                                </Accordion.Collapse>
                                            </>
                                        ))
                                    }
                                </Accordion>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-lg-8">
                    <CardChart
                        title="Memberships"
                        labels={["New", "Upgrade", "Downgrade", "Discontinue"]}
                        data={[totalMembersJoined, totalMembersUpgrade, totalMembersDowngrade, totalMembersDiscontinue]}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <Card>
                        <Card.Header>
                            <Button
                                size="sm"
                                variant="outline-primary"
                                onClick={() => handleDownloadTable(membersMembershipType)}
                            >
                                <i className="fas fa-download"></i>
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Membership Type</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <Accordion as="tbody">
                                    {
                                        membersMembershipType.map((report, idx) => (
                                            <>
                                                <Accordion.Toggle eventKey={report.variable + idx} as="tr" style={{ cursor: "pointer" }}>
                                                    <td>{report.variable}</td>
                                                    <td>{report.count}</td>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={report.variable + idx}>
                                                    <div className="pb-4 ml-3">
                                                        {report.members.map(member => (<div><Link to={`/customers/detail/${member.member__id}`}>{member.member__name}</Link></div>))}
                                                    </div>
                                                </Accordion.Collapse>
                                            </>
                                        ))
                                    }
                                </Accordion>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-lg-8">
                    <CardChart
                        title="Membership Type"
                        labels={membersMembershipType.map(membershipType => membershipType.variable)}
                        data={membersMembershipType.map(membershipType => membershipType.count)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <Card>
                        <Card.Header>
                            <Button
                                size="sm"
                                variant="outline-primary"
                                onClick={() => handleDownloadTable(membersMembershipStatus)}
                            >
                                <i className="fas fa-download"></i>
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Membership Status</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <Accordion as="tbody">
                                    {
                                        membersMembershipStatus.map((report, idx) => (
                                            <>
                                                <Accordion.Toggle eventKey={report.variable + idx} as="tr" style={{ cursor: "pointer" }}>
                                                    <td>{report.variable}</td>
                                                    <td>{report.count}</td>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={report.variable + idx}>
                                                    <div className="pb-4 ml-3">
                                                        {report.members.map(member => (<div><Link to={`/customers/detail/${member.member__id}`}>{member.member__name}</Link></div>))}
                                                    </div>
                                                </Accordion.Collapse>
                                            </>
                                        ))
                                    }
                                </Accordion>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-lg-8">
                    <CardChart
                        title="Membership Status"
                        labels={membersMembershipStatus.map(membershipStatus => membershipStatus.variable)}
                        data={membersMembershipStatus.map(membershipStatus => membershipStatus.count)}
                    />
                </div>
            </div>
        </div>
    );
}

export default Memberships;