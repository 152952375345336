import axios from "axios";
import config from "./../config/config";
class BucketApi {
  base_api = config.base_api;
  token = config.token;

  getBucketSettings(params) {
    return axios.get(this.base_api + `templates/${params}/`, {
      headers: {
        Authorization: "Token " + this.token,
        "Content-Type": "application/json",
      },
    });
  }

  updateBucketSettings(Id, data) {
    return axios.patch(this.base_api + `template_informations/${Id}/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  addBucketSettings(data) {
    return axios.post(this.base_api + `template_information/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }
}

export default BucketApi;
