import { useState } from 'react';
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en'
import SectionApi from '../../api/SectionApi';


function MetadataViewerModal(props) {
    const sectionApi = new SectionApi();

    const [jsonData, setJsonData] = useState(null)

    const stringToJson = (str) => {
        if(!str) return 
        return str.replaceAll('True', 'true').replaceAll('False', 'false').replaceAll(`'`, `"`)
    }

    const getSectionCatalogDetail = () => {
        sectionApi
            .sectionCatalogDetailNew(props.sectionId)
            .then(res => {
                const fixedMetadata = stringToJson(res.data.metadata)
                setJsonData(JSON.parse(fixedMetadata))
            })
            .catch(err => {
                setJsonData(null)
            })
    }

    const onOpenModal = () => {
        if (props.jsonData) {
            const fixedMetadata = stringToJson(props.jsonData)
            setJsonData(JSON.parse(fixedMetadata))
        } else {
            getSectionCatalogDetail()
        }
    }

    return <Modal
        show={props.show}
        onHide={props.onHide}
        onShow={onOpenModal}
    >
        <Modal.Header closeButton className="bg-primary">
            <Modal.Title>Metadata Viewer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {(jsonData || props.jsonData) &&
                <JSONInput
                    locale={locale}
                    viewOnly={true}
                    height='550px'
                    width="100%"
                    placeholder={jsonData}
                />
            }
        </Modal.Body>
    </Modal>;
}

export default withTranslation()(MetadataViewerModal);