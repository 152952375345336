import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    InputGroup,
    Tabs,
    Tab,
    Dropdown,
    Collapse,
} from "react-bootstrap";
import NumberFormat from "react-number-format";

// import DatePicker from "react-bootstrap-date-picker"
import DatePicker from "react-datepicker";
import swal from "sweetalert";
// import Select from "react-dropdown-select";
import Select from "react-select";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";

import Card, { CardHeader, CardTitle, CardBody } from "./../../components/Card";
import {
    Content,
    ContentHeader,
    ContentBody,
} from "./../../components/Content";

import ArticleAssetListAdd from "./../../components/widgets/AssetList/ArticleAssetListAdd";
import ArticleAssetListUpdate from "./../../components/widgets/AssetList/ArticleAssetListUpdate";
import JobApplicationList from "./JobApplicationList";

import PageApi from "./../../api/PageApi";
import SectionApi from "./../../api/SectionApi";
import ArticleApi from "./../../api/ArticleApi";
import WidgetApi from "./../../api/WidgetApi";
import UserApi from "./../../api/UserApi";

import { myGetYear, getMonth, myRange } from "./../../lib/DateHelper";

import "react-datepicker/dist/react-datepicker.css";
import JobApi from "../../api/JobApi";
import LocationAddModal from "./LocationAddModal";
import KeywordAddModal from "./KeywordAddModal";
import PositionAddModal from "./PositionAddModal";
import CompanyAddModal from "./CompanyAddModal";
import CurrencyAddModal from "./CurrencyAddModal";

import DeleteCompanyConfirm from "./DeleteCompanyConfirm";
import DeleteLocationConfirm from "./DeleteLocationConfirm";
import DeletePositionConfirm from "./DeletePositionConfirm";
import DeleteJobTypeConfirm from "./DeleteJobTypeConfirm";
import JobTypeAddModal from "./JobTypeAddModal";
// import DeleteCurrencyConfirm from "./DeleteCompanyConfirm";

import TitlePages from "../Elements/TitlePages";
import { withTranslation } from "react-i18next";
import UserContext from "../Context";
import config from "../../config/config";
import ReactGA from "react-ga";
import Cookies from "js-cookie";
import "./Logs.css";

class JobPosterAdd extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            articleId: 0,
            author: [],
            clientID: parseInt(Cookies.get("active-client")),
            siteId: localStorage.getItem("siteId"),
            category: "",
            date: "",
            dateToApi: JSON.stringify(new Date()).slice(1, -1).split("T")[0],
            status: "Drafted",
            articleLanguages: [],
            article_assets: [],
            selectedWidget: [],
            selectedAsset: [],
            selectedAssetAdd: [],
            // urlType:{},
            selectedPageId: 0,
            selectedPositions: [],
            selectedSectionId: 0,
            selectedPosition: 0,
            positionId: 0,
            selectedCompanies: [],
            selectedCompany: 0,
            companyId: 0,
            selectedLocations: [],
            selectedLocation: null,
            locationId: 0,
            selectedCurrency: 1,
            selectedCurrencyName: "đồng",
            selectedKeyword: [],
            jobTypes: [],
            selectedJobType: "",
            selectedJobTypes: [],

            articleKeywords: [],
            loadedKeywords: [],
            activeKeyword: "",

            minSalary: 0,
            maxSalary: 0,
            jobType: "",
            vacancies: 0,
            postDate: new Date(),
            dueDate: null,

            showCompany: false,
            showPosition: false,
            showLocation: false,
            showJobType: false,
            showKeyword: false,
            showCurrency: false,

            showCompanyDelete: false,
            showPositionDelete: false,
            showLocationDelete: false,
            showJobTypeDelete: false,
            showCurrencyDelete: false,

            companies: [],
            positions: [],
            locations: [],
            currencies: [],
            keywords: [],
            languageList: [],
            categoryList: [],
            pages: [],
            sections: [],

            jobInfoToggle: false,
            jobDescription: false,
            jobSettingToggle: false,
            mediaToggle: false,
            applicationToggle: true,

            AssetElement: "",
            test: ["foo"],
            closed: false,

            transPage: "pages.job_poster_add.",
            transAlert: "alert.",
        };

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeCategory = this.handleChangeCategory.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
        // this.handleChangeDate = this.handleChangeDate.bind(this)
        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.handleChangeTitle = this.handleChangeTitle.bind(this);
        this.handleChangeContent = this.handleChangeContent.bind(this);
        this.handleChangeClosed = this.handleChangeClosed.bind(this);
        this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);

        this.getDetailJob = this.getDetailJob.bind(this);
        this.getAuthor = this.getAuthor.bind(this);
        this.getAllWidget = this.getAllWidget.bind(this);
        this.getArticleCategories = this.getArticleCategories.bind(this);
        this.getLanguages = this.getLanguages.bind(this);
        this.getPages = this.getPages.bind(this);
        this.getSectionByPage = this.getSectionByPage.bind(this);
        this.getAllEmployees = this.getAllEmployees.bind(this);
        this.getCurrencies = this.getCurrencies.bind(this);
        this.getJobTypes = this.getJobTypes.bind(this);

        this.handleChangePosition = this.handleChangePosition.bind(this);
        this.handleChangeLocation = this.handleChangeLocation.bind(this);
        this.handleChangeCurrency = this.handleChangeCurrency.bind(this);
        this.handleChangeMinSalary = this.handleChangeMinSalary.bind(this);
        this.handleChangeMaxSalary = this.handleChangeMaxSalary.bind(this);
        this.handleChangePostDate = this.handleChangePostDate.bind(this);
        this.handleChangeDueDate = this.handleChangeDueDate.bind(this);
        this.handleChangeJobType = this.handleChangeJobType.bind(this);
        this.handleChangeVacancies = this.handleChangeVacancies.bind(this);
        this.handleChangeCompany = this.handleChangeCompany.bind(this);

        this.setSelectedAsset = this.setSelectedAsset.bind(this);
        this.setSelectedAssetAdd = this.setSelectedAssetAdd.bind(this);
        this.deleteSelectedAssetAdd = this.deleteSelectedAssetAdd.bind(this);

        this.setShowCompany = this.setShowCompany.bind(this);
        this.setShowPosition = this.setShowPosition.bind(this);
        this.setShowLocation = this.setShowLocation.bind(this);
        this.setShowKeyword = this.setShowKeyword.bind(this);
        this.setShowJobType = this.setShowJobType.bind(this);
        this.setShowCurrency = this.setShowCurrency.bind(this);

        this.setShowCompanyDelete = this.setShowCompanyDelete.bind(this);
        this.setShowPositionDelete = this.setShowPositionDelete.bind(this);
        this.setShowLocationDelete = this.setShowLocationDelete.bind(this);
        this.setShowJobTypeDelete = this.setShowJobTypeDelete.bind(this);

        this.deleteSelectedPosition = this.deleteSelectedPosition.bind(this);
        this.deleteSelectedLocation = this.deleteSelectedLocation.bind(this);
        this.deleteSelectedCompany = this.deleteSelectedCompany.bind(this);
        this.deleteSelectedJobType = this.deleteSelectedJobType.bind(this);

        this.setJobInfoToggle = this.setJobInfoToggle.bind(this);
        this.setJobDescriptionToggle = this.setJobDescriptionToggle.bind(this);
        this.setJobSettingToggle = this.setJobSettingToggle.bind(this);
        this.setMediaToggle = this.setMediaToggle.bind(this);
        this.setApplicationToggle = this.setApplicationToggle.bind(this);

        this.addJobPoster = this.addJobPoster.bind(this);
        this.addKeyword = this.addKeyword.bind(this);
        this.updateKeyword = this.updateKeyword.bind(this);
        this.handleKeyword = this.handleKeyword.bind(this);
        this.updateLoadedKeyword = this.updateLoadedKeyword.bind(this);
        this.deleteLoadedKeyword = this.deleteLoadedKeyword.bind(this);
        this.deleteAddedKeyword = this.deleteAddedKeyword.bind(this);

        this.getCompanies = this.getCompanies.bind(this);
        this.getPositions = this.getPositions.bind(this);
        this.getLocations = this.getLocations.bind(this);
        this.getKeywords = this.getKeywords.bind(this);

        this.clearExternal = this.clearExternal.bind(this);
        this.clearInternal = this.clearInternal.bind(this);
    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        const jobId = this.props.match.params.jobPosterId;
        if (jobId) {
            this.getDetailJob();
        } else {
            let d = new Date();
            const y = d.getFullYear();
            const m = d.getMonth();
            const da = d.getDate();

            let customDate;

            if (da === 1) {
                let lastday = function (y, m) {
                    return new Date(y, m - 1, 0).getDate();
                };
                customDate = new Date(y, m - 1, lastday(y, m + 1));
            } else if (da > 1) {
                customDate = new Date(y, m, da - 1);
            }
            this.setState({
                postDate: customDate,
                dueDate: customDate,
            });
        }

        this.getCompanies();
        this.getAllWidget();
        this.getArticleCategories();
        this.getLanguages();
        this.getPages();
        this.getAllEmployees();
        this.getPositions();
        this.getLocations();
        this.getCurrencies();
        this.getKeywords();
        this.getJobTypes();
        this.getAuthor();
    }

    // ==============================================================

    setJobInfoToggle() {
        this.setState({
            jobInfoToggle: !this.state.jobInfoToggle,
        });
    }

    setJobDescriptionToggle() {
        this.setState({
            jobDescription: !this.state.jobDescription,
        });
    }

    setJobSettingToggle() {
        this.setState({
            jobSettingToggle: !this.state.jobSettingToggle,
        });
    }

    setMediaToggle() {
        this.setState({
            mediaToggle: !this.state.mediaToggle,
        });
    }

    setApplicationToggle() {
        this.setState({
            applicationToggle: !this.state.applicationToggle,
        });
    }

    // ===============================================================

    handleChangeKeyword(id, value) {
        let articleLanguages = [...this.state.articleLanguages];
        let articleLanguage = { ...articleLanguages[id] };

        articleLanguage.keyword = value;
        articleLanguages[id] = articleLanguage;

        this.setState({
            articleLanguages: articleLanguages,
        });
    }

    handleChangeDescription(id, value) {
        let articleLanguages = [...this.state.articleLanguages];
        let articleLanguage = { ...articleLanguages[id] };

        articleLanguage.description = value;
        articleLanguages[id] = articleLanguage;

        this.setState({
            articleLanguages: articleLanguages,
        });
    }

    handleChangeName(value) {
        this.setState({
            name: value,
        });
    }

    handleChangeCategory(value) {
        this.setState({
            category: value,
        });
    }

    handleChangeStatus(value) {
        this.setState({
            status: value,
        });
    }

    handleChangeTab(value) {
        this.setState({
            tabKey: value,
            selectedLanguage: value,
        });
    }

    handleUrlTypeChange(value) {
        this.setState(
            {
                urlType: value,
            },
            () => {
                if (this.state.urlType === "internal") {
                    this.clearExternal();
                } else {
                    this.clearInternal();
                }
            }
        );
    }

    handleChangeNoFollow(value) {
        this.setState({
            is_no_follow: value,
        });
    }

    handleChangeTitle(id, value) {
        let articleLanguages = [...this.state.articleLanguages];
        let articleLanguage = { ...articleLanguages[id] };

        articleLanguage.title = value;
        articleLanguages[id] = articleLanguage;

        this.setState({
            articleLanguages: articleLanguages,
        });
    }

    handleChangeSummary(id, value) {
        let articleLanguages = [...this.state.articleLanguages];
        let articleLanguage = { ...articleLanguages[id] };

        articleLanguage.summary = value;
        articleLanguages[id] = articleLanguage;

        this.setState({
            articleLanguages: articleLanguages,
        });
    }

    handleChangeContent(id, value) {
        let articleLanguages = [...this.state.articleLanguages];
        let articleLanguage = { ...articleLanguages[id] };

        articleLanguage.content = value;
        articleLanguages[id] = articleLanguage;

        this.setState({
            articleLanguages: articleLanguages,
        });
    }

    handleChangePosition(value) {
        this.setState({
            selectedPosition: value,
        });
    }

    handleChangeLocation(value) {
        this.setState({
            selectedLocation: value,
        });
    }

    handleChangeCurrency(value) {
        this.setState({
            selectedCurrency: value.id,
            selectedCurrencyName: value.name,
        });
    }

    handleChangeMinSalary(value) {
        const val = value.replace(/,/g, "");
        this.setState({
            minSalary: val,
        });
    }

    handleChangeMaxSalary(value) {
        const val = value.replace(/,/g, "");
        this.setState({
            maxSalary: val,
        });
    }

    handleChangePostDate(value) {
        const result = JSON.stringify(value).slice(1, -1).split("T");

        value = value ? value : new Date();

        this.setState({
            postDate: value,
            postDateToApi: result[0],
        });
    }

    handleChangeDueDate(value) {
        const result = JSON.stringify(value).slice(1, -1).split("T");

        value = value ? value : new Date();

        this.setState({
            dueDate: value,
            dueDateToApi: result[0],
        });
    }

    handleChangeJobType(value) {
        this.setState({
            jobType: value,
        });
    }

    handleChangeVacancies(value) {
        this.setState({
            vacancies: value,
        });
    }

    handleChangeCompany(value) {
        this.setState({
            selectedCompany: value,
        });
    }

    handleChangeClosed() {
        this.setState({
            closed: !this.state.closed,
        });
    }

    clearInternal() {
        this.setState({
            selectedPageId: null,
            selectedSectionId: null,
        });
    }

    clearExternal() {
        let urlLanguages = [...this.state.articleLanguages];
        urlLanguages.map((item, id) => {
            // let urlLanguage = { ...urlLanguages[ id ]}
            item.path = null;
            urlLanguages[id] = item;

            return item;
        });

        this.setState({
            articleLanguages: urlLanguages,
        });
    }

    // ===============================================================================================================================================

    setShowCompany(companyId) {
        if (companyId) {
            this.setState({
                companyId: companyId,
                showCompany: !this.state.showCompany,
            });
        } else {
            this.setState({
                companyId: 0,
                showCompany: !this.state.showCompany,
            });
        }
    }

    setShowPosition() {
        this.setState({
            showPosition: !this.state.showPosition,
        });
    }

    setShowLocation() {
        this.setState({
            showLocation: !this.state.showLocation,
        });
    }

    setShowKeyword() {
        this.setState({
            showKeyword: !this.state.showKeyword,
        });
    }

    setShowJobType() {
        this.setState({
            showJobType: !this.state.showJobType,
        });
    }

    setShowCurrency() {
        this.setState({
            showCurrency: !this.state.showCurrency,
        });
    }

    // ===============================================================================================================================================

    setShowCompanyDelete(companyId) {
        if (companyId) {
            const obj = new JobApi();

            obj.getDetailCompany(companyId)
                .then((res) => {
                    // alert(JSON.stringify(res.data))

                    this.setState({
                        selectedCompanies: [res.data],
                        showCompanyDelete: !this.state.showCompanyDelete,
                    });
                })
                .catch((err) => {
                    console.log(err.messasge);
                });
        } else {
            this.setState({
                showCompanyDelete: !this.state.showCompanyDelete,
            });
        }
    }

    setShowPositionDelete(positionId) {
        if (positionId) {
            const obj = new JobApi();

            obj.getDetailPosition(this.state.selectedPosition)
                .then((res) => {
                    this.setState({
                        selectedPositions: [res.data],
                        showPositionDelete: !this.state.showPositionDelete,
                    });
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } else {
            this.setState({
                showPositionDelete: !this.state.showPositionDelete,
            });
        }
    }

    setShowLocationDelete(locationId) {
        if (locationId) {
            const obj = new JobApi();

            obj.getDetailLocation(this.state.selectedLocation)
                .then((res) => {
                    this.setState({
                        selectedLocations: [res.data],
                        showLocationDelete: !this.state.showLocationDelete,
                    });
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } else {
            this.setState({
                showLocationDelete: !this.state.showLocationDelete,
            });
        }
    }

    setShowJobTypeDelete(jobTypeId) {
        if (jobTypeId) {
            const obj = new JobApi();

            obj.getJobTypeDetail(jobTypeId)
                .then((res) => {
                    this.setState({
                        selectedJobTypes: [res.data],
                        showJobTypeDelete: !this.state.showJobTypeDelete,
                    });
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } else {
            this.setState({
                showJobTypeDelete: !this.state.showJobTypeDelete,
            });
        }
    }

    // ============================ Delete Confirm ===================================================================================================

    deleteSelectedPosition() {
        const { t } = this.props;
        const obj = new JobApi();

        const [{ id }] = this.state.selectedPositions;

        obj.deletePosition(id)
            .then((res) => {
                swal(
                    "Success!",
                    t(this.state.transAlert + "position_delete_success"),
                    "success"
                );
                this.getPositions();
                this.setShowPositionDelete();
            })
            .catch((err) => {
                swal("Error!", err.message, "error");
            });
    }

    deleteSelectedLocation() {
        const { t } = this.props;
        const obj = new JobApi();

        const [{ id }] = this.state.selectedLocations;

        obj.deleteLocation(id)
            .then((res) => {
                swal(
                    "Success!",
                    t(this.state.transAlert + "location_delete_success"),
                    "success"
                );
                this.getLocations();
                this.setShowLocationDelete();
            })
            .catch((err) => {
                swal("Error!", err.message, "error");
            });
    }

    deleteSelectedCompany() {
        const { t } = this.props;
        const obj = new JobApi();

        const [{ id }] = this.state.selectedCompanies;

        obj.deleteCompany(id)
            .then((res) => {
                swal(
                    "Success!",
                    t(this.state.transAlert + "company_delete_success"),
                    "success"
                );
                this.getCompanies();
                this.setShowCompanyDelete();
            })
            .catch((err) => {
                swal("Error!", err.message, "error");
            });
    }

    deleteSelectedJobType() {
        const { t } = this.props;
        const obj = new JobApi();

        const [{ id }] = this.state.selectedJobTypes;

        obj.deleteJobType(id)
            .then((res) => {
                swal(
                    "Success!",
                    t(this.state.transAlert + "job_type_delete_success"),
                    "success"
                );
                this.getJobTypes();
                this.setShowJobTypeDelete();
            })
            .catch((err) => {
                swal("Error!", err.message, "error");
            });
    }

    // ============================ Delete Action =====================================================================================================

    setSelectedAssetAdd(asset) {
        var lastSelected = [];
        lastSelected = [...this.state.selectedAssetAdd];


        lastSelected = lastSelected.concat(asset);

        this.setState(
            {
                selectedAssetAdd: lastSelected,
            },
            () => {
                this.setState({
                    deleteDisable: false,
                });
            }
        );
    }

    deleteSelectedAssetAdd(assets) {
        const { t } = this.props;

        var result = [];
        var lastSelected = [];
        lastSelected = [...this.state.selectedAssetAdd];

        lastSelected.forEach((ite) => {
            var exist = false;

            assets.forEach((item) => {
                if (ite.id === item.id) {
                    exist = true;
                }
            });

            if (exist === false) {
                result.push(ite);
            }
        });

        this.setState(
            {
                selectedAssetAdd: result,
            },
            () => {
                swal(
                    "Success!",
                    t(
                        this.state.transAlert +
                        "delete_asset_in_article_success"
                    ),
                    "success"
                );
            }
        );
    }

    setSelectedAsset(asset, value) {
        var lastSelected = [];
        lastSelected = [...this.state.selectedAsset];
        var indexDelete = -1;
        var stateSelected = "selectedAsset";

        if (value === true) {
            lastSelected.push(asset);
        } else {
            this.state[stateSelected].forEach((item, index) => {
                if (item.id === asset.id) {
                    indexDelete = index;
                }
            });

            lastSelected.splice(indexDelete, 1);
        }

        this.setState(
            {
                [stateSelected]: lastSelected,
            },
            () => {
                if (this.state[stateSelected].length > 0) {
                    this.setState({
                        deleteDisable: false,
                    });
                } else {
                    this.setState({
                        deleteDisable: true,
                    });
                }
            }
        );
    }

    getDetailJob() {
        const obJob = new JobApi();

        const jobId = this.props.match.params.jobPosterId;

        obJob
            .getJobDetail(jobId)
            .then((res) => {
                this.setState(
                    {
                        name: res.data.article.name,
                        author: [
                            {
                                val: res.data.article.author,
                            },
                        ],
                        date: res.data.article.date
                            ? new Date(res.data.article.date)
                            : new Date(),
                        dateToApi: res.data.article.date,
                        status: res.data.article.status,
                        selectedWidget: res.data.article.widgets.map((item) => {
                            return {
                                id: item.id,
                                namePage: item.name,
                            };
                        }),
                        articleId: res.data.article.id,
                        // urlType: || "internal",
                        // selectedPageId:res.data.article.article_languages[0]?.page,
                        // selectedSectionId:res.data.article.article_languages[0]?.section,
                        articleLanguages: res.data.article.article_languages.map(
                            (item) => {
                                return {
                                    id: item.id,
                                    article: item.article,
                                    language: item.language,
                                    title: item.title,
                                    summary: item.summary,
                                    content: item.content,
                                    description: item.description,
                                };
                            }
                        ),
                        article_assets: res.data.article.article_assets,
                        loadedKeywords: res.data.article.article_keywords,
                        selectedCompany: res.data.company_name,
                        selectedPosition: res.data.position,
                        selectedLocation: res.data.location,
                        selectedCurrency: res.data.currency || 1,
                        selectedCurrencyName: res.data.currency_name || "đồng",
                        selectedKeyword: res.data.keywords.map((item) => {
                            return {
                                id: item.id,
                                name: item.name,
                            };
                        }),
                        minSalary: res.data.min_salary,
                        maxSalary: res.data.max_salary,
                        jobType: res.data.job_type,
                        vacancies: res.data.vacancies,
                        postDate: res.data.post_date
                            ? new Date(res.data.post_date)
                            : new Date(),
                        postDateToApi: res.data.post_date,
                        dueDate: res.data.due_date
                            ? new Date(res.data.due_date)
                            : new Date(),
                        dueDateToApi: res.data.due_date,
                    },
                    () => {
                        if (this.props.match.params.jobPosterId) {
                            this.setState({
                                AssetElement: (
                                    <ArticleAssetListUpdate
                                        articleId={this.state.articleId}
                                        article_assets={
                                            this.state.article_assets
                                        }
                                        getDetailArticle={this.getDetailJob}
                                    ></ArticleAssetListUpdate>
                                ),
                            });
                        }
                    }
                );
            })
            .catch((err) => {
                console.log("err => ", err);
            });
    }

    getArticleCategories() {
        const objArticle = new ArticleApi();

        objArticle
            .getArticleCategories({
                max_size: true,
            })
            .then((res) => {
                this.setState({
                    categoryList: res.data.results,
                });
            });
    }

    getAllWidget() {
        const objWidget = new WidgetApi();


        const params = {
            max_size: true,
            client: this.state.clientID,
            category: 5,
            internal: "False",
        };


        objWidget.getAllWidgets(params).then((res) => {
            const dataWidget = [];

            res.data.results.forEach((el) => {
                dataWidget.push({ value: el.id, label: el.name });
            });

            this.setState({
                widgetList: dataWidget,
            });
        });
    }

    getLanguages() {
        const { activeSiteLang } = this.context;

        this.setState(
            {
                languageList: activeSiteLang,
            },
            () => {
                // ===================================================================================================================================
                var index = this.state.languageList.findIndex(
                    (item) => item.abbreviation === localStorage.getItem("lang")
                );

                this.setState(
                    {
                        tabKey: index,
                    },
                    () => {
                        this.renderTabLanguage();
                        // this.handleChangeTab(this.state.tabKey)
                    }
                );
            }
        );
    }

    getPages() {
        const objPage = new PageApi();

        const params = {
            language: localStorage.getItem("lang"),
            template: localStorage.getItem("templateId"),
            max_size: true,
        };

        objPage.pageLanguages(params).then((res) => {
            this.setState(
                {
                    pages: res.data.results,
                },
                () => {
                    if (this.state.selectedPageId) {
                        this.getSectionByPage();
                    }
                }
            );
        });
    }

    getSectionByPage() {
        const objSection = new SectionApi();

        const params = {
            language: localStorage.getItem("lang"),
            page_id: this.state.selectedPageId,
            max_size: true,
        };

        objSection.sectionLanguage(params).then((res) => {
            this.setState({
                sections: res.data.results,
            });
        });
    }

    getAllEmployees() {
        const objUser = new UserApi();
        objUser
            .getAllEmployees({
                max_size: true,
            })
            .then((res) => {
                const resEmployee = res.data.results.map((item) => {
                    // var name = item.user.first_name+" "+item.user.last_name
                    // if(!item.user.first_name) {
                    //     name = item.user.username
                    // }

                    var image = "";
                    if (item.avatar) {
                        image = (
                            <div>
                                <img
                                    width="30"
                                    height="30"
                                    src={item.avatar}
                                    alt=""
                                ></img>
                                {" " +
                                    item.user.first_name +
                                    " " +
                                    item.user.last_name}
                            </div>
                        );
                    } else {
                        image =
                            item.user.first_name + " " + item.user.last_name;
                    }

                    return {
                        val: item.id,
                        label: image,
                    };
                });

                this.setState({
                    employeeList: resEmployee,
                });
            });
    }

    getCompanies() {
        const obJob = new JobApi();

        obJob
            .getCompanies({
                max_size: true,
                client: this.state.clientID,
            })
            .then((res) => {
                this.setState({
                    companies: res.data.results,
                });
            })
            .catch((err) => { });
    }

    getLocations() {
        const obJob = new JobApi();

        obJob
            .getLocationList({
                lang: localStorage.getItem("lang"),
                client: this.state.clientID,
                max_size: true,
            })
            .then((res) => {
                this.setState({
                    locations: res.data.results,
                });
            })
            .catch((err) => { });
    }

    getPositions() {
        const obJob = new JobApi();

        obJob
            .getPositionList({
                max_size: true,
                client: this.state.clientID,
            })
            .then((res) => {
                this.setState({
                    positions: res.data.results,
                });
            })
            .catch((err) => { });
    }

    getCurrencies() {
        const obJob = new JobApi();

        obJob
            .getCurrencies({
                max_size: true,
            })
            .then((res) => {
                this.setState({
                    currencies: res.data.results,
                });
            })
            .catch((err) => { });
    }

    getAuthor() {
        const obJob = new JobApi();

        obJob
            .getAuthor({
                max_size: true,
                site: this.state.siteId,
            })
            .then((res) => {
                const dataKey = [];

                res.data.results.forEach((el) => {
                    dataKey.push({ value: el.id, label: el.name });
                });

                this.setState({
                    author: dataKey,
                });
            })
            .catch((err) => { });
    }

    getKeywords() {
        const obJob = new JobApi();

        obJob
            .getKeywords({
                max_size: true,
                site: this.state.siteId,
            })
            .then((res) => {
                const dataKey = [];

                res.data.results.forEach((el) => {
                    dataKey.push({ value: el.id, label: el.name });
                });

                this.setState({
                    keywords: dataKey,
                });
            })
            .catch((err) => { });
    }

    getJobTypes() {
        const obJob = new JobApi();

        obJob
            .getJobTypes({
                max_size: true,
            })
            .then((res) => {
                this.setState({
                    jobTypes: res.data.results,
                });
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    renderTabLanguage() {
        const articleLanguages = this.state.languageList.map((item) => {
            var result = this.state.articleLanguages.find(
                (articleLang) => item.id === articleLang.language
            );
            if (!result) {
                result = {
                    article: this.state.articleId,
                    language: item?.language?.id,
                    title: "",
                    content: "",
                    summary: "",
                    // page:null,
                    // section:null,
                    // path:"",
                    description: "",
                    keyword: "",
                };
            }

            result.languageName = item.name;
            return result;
        });

        this.setState(
            {
                articleLanguages: articleLanguages,
            },
            () => {
                this.handleChangeTab(this.state.tabKey); // english default
            }
        );
    }

    addJobPoster() {
        const { t } = this.props;
        const obJob = new JobApi();
        const objArticle = new ArticleApi();

        const request = {
            article: {
                client: parseInt(Cookies.get("active-client")),
                widgets: this.state.selectedWidget.map((item) => {
                    return item.id;
                }),
                author: this.state.author[0]?.val,
                name: this.state.articleLanguages[0]?.title,
                date: this.state.dateToApi,
                status: this.state.status,
                article_languages: this.state.articleLanguages.map((item) => {
                    return {
                        language: item.language,
                        title: item.title,
                        summary: item.summary,
                        content: item.content,
                        description: item.description,
                    };
                }),
                article_assets: this.state.selectedAssetAdd.map((item) => {
                    return item.id;
                }),
                location: parseInt(this.state.selectedLocation),
            },
            company: parseInt(this.state.selectedCompany),
            position: parseInt(this.state.selectedPosition),
            // location: parseInt(this.state.selectedLocation),
            job_type: parseInt(this.state.jobType),
            currency: this.state.selectedCurrency,
            keywords: this.state.selectedKeyword.map((item) => {
                return item.id;
            }),
            vacancies: parseInt(this.state.vacancies),
            min_salary: isNaN(this.state.minSalary)
                ? parseFloat(this.state.minSalary.replace(/,/g, ""))
                : this.state.minSalary,
            max_salary: isNaN(this.state.maxSalary)
                ? parseFloat(this.state.maxSalary.replace(/,/g, ""))
                : this.state.maxSalary,
            post_date: this.state.postDateToApi,
            due_date: this.state.dueDateToApi,
            company_name: this.state.selectedCompany,
        };

        obJob
            .addJobPoster(request)
            .then((res) => {
                const keywords_languages = this.state.articleKeywords.map(
                    (item) => {
                        return {
                            article: res.data.article.id,
                            language: parseInt(item.language),
                            keyword: item.keyword,
                        };
                    }
                );

                objArticle.addKeywords(keywords_languages).then((res) => {
                    swal(
                        "Success!",
                        t(this.state.transAlert + "job_poster_add_success"),
                        "success"
                    );
                    setTimeout(() => {
                        window.location.reload();
                    }, 0);
                    // setTimeout(() => {
                    //     this.props.history.push(`/job-posters`);
                    // }, 3000);
                });
            })
            .catch((err) => {
                swal("Error!", err.message, "error");
            });
    }

    updateJobPoster() {
        const { t } = this.props;
        const obJob = new JobApi();
        const objArticle = new ArticleApi();

        const jobId = this.props.match.params.jobPosterId;

        const min_sal = parseInt(this.state.minSalary);
        const max_sal = parseInt(this.state.maxSalary);

        if (min_sal > max_sal) {
            swal(
                "Error!",
                t(this.state.transAlert + "salary_input_error"),
                "error"
            );
            return false;
        }

        const request = {
            article: {
                widgets: this.state.selectedWidget.map((item) => {
                    return item.id;
                }),
                author: this.state.author[0]?.val,
                name: this.state.articleLanguages[0]?.title,
                date: this.state.dateToApi,
                status: this.state.status,
                article_languages: this.state.articleLanguages.map((item) => {
                    return {
                        language: item.language,
                        title: item.title,
                        summary: item.summary,
                        content: item.content,
                        description: item.description,
                    };
                }),
            },
            client: Cookies.get("active-client"),
            company_name: this.state.selectedCompany,
            position: parseInt(this.state.selectedPosition),
            location: this.state.selectedLocation,
            keywords: this.state.selectedKeyword.map((item) => {
                return item.id;
            }),
            currency: this.state.selectedCurrency,
            vacancies: parseInt(this.state.vacancies),
            min_salary: isNaN(this.state.minSalary)
                ? parseFloat(this.state.minSalary.replace(/,/g, ""))
                : this.state.minSalary,
            max_salary: isNaN(this.state.maxSalary)
                ? parseFloat(this.state.maxSalary.replace(/,/g, ""))
                : this.state.maxSalary,
            job_type: this.state.jobType,
            post_date: this.state.postDateToApi,
            due_date: this.state.dueDateToApi,
        };

        this.state.loadedKeywords.forEach((k, i) => {
            const keywords_languages = {
                language: k.language.id,
                keyword: k.keyword,
            };

            objArticle
                .updateKeywords(k.id, keywords_languages)
                .then((res) => { });
        });

        obJob
            .updateJobPoster(jobId, request)
            .then((res) => {
                const keywords_languages = this.state.articleKeywords.map(
                    (item) => {
                        return {
                            article: res.data.article.id,
                            language: parseInt(item.language),
                            keyword: item.keyword,
                        };
                    }
                );

                objArticle.addKeywords(keywords_languages).then((res) => { });
                swal(
                    "Success!",
                    t(this.state.transAlert + "job_poster_update_success"),
                    "success"
                );
            })
            .catch((err) => {
                swal("Error!", err.message, "error");
            });
    }

    digitSeparator(number) {
        return number.toLocaleString("en-US", { maximumFractionDigits: 2 });
    }

    handleKeyword(value) {
        this.setState({
            activeKeyword: value,
        });
    }

    addKeyword(index) {
        // let keywords = document.getElementById('fk-content-keywords').value
        if (this.state.activeKeyword !== "") {
            const articleKeyword = {
                id: `${this.state.articleKeywords.length + 1}`,
                language: index + 1,
                keyword: this.state.activeKeyword,
            };
            this.setState(
                {
                    articleKeywords: this.state.articleKeywords.concat(
                        articleKeyword
                    ),
                    activeKeyword: "",
                },
                () => {
                    document.getElementById("fk-job-keywords0").value = "";
                    document.getElementById("fk-job-keywords1").value = "";
                }
            );
        }
    }

    updateKeyword(id, value) {
        let articleKeywords = [...this.state.articleKeywords];
        let articleKeyword = { ...articleKeywords[id - 1] };

        articleKeyword.keyword = value;
        articleKeywords[id - 1] = articleKeyword;

        this.setState({
            articleKeywords: articleKeywords,
        });
    }

    updateLoadedKeyword(index, value) {
        let loadedKeywords = [...this.state.loadedKeywords];
        let loadedKeyword = { ...loadedKeywords[index] };

        loadedKeyword.keyword = value;
        loadedKeywords[index] = loadedKeyword;

        this.setState({
            loadedKeywords: loadedKeywords,
        });
    }

    deleteAddedKeyword(index) {
        this.setState({
            articleKeywords: this.state.articleKeywords.filter(function (
                ele,
                i
            ) {
                return i !== index;
            }),
        });
    }

    deleteLoadedKeyword(id, index) {
        const objArticle = new ArticleApi();

        let keys = this.state.loadedKeywords.filter(function (ele, i) {
            return i !== index;
        });

        this.setState(
            {
                loadedKeywords: keys,
            },
            () => {
                objArticle.deleteKeywords(id).then((res) => { });
            }
        );
    }

    render() {
        const { t } = this.props;
        const { keywords, widgetList, author } = this.state;
        const { employee_privileges } = this.context;
        const isOnlyMonitor =
            employee_privileges.filter((e) => e.id === 5).length > 0;

        // const Categories = this.state.categoryList.map((item) => {
        //     return (
        //         <option value={item.id} key={item.id}>
        //             {" "}
        //             {item.category}
        //         </option>
        //     );
        // });

        let ckeditorConfig = {
            toolbar: {
                items: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "|",
                    "link",
                    "|",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "insertTable",

                    "|",
                    "undo",
                    "redo",
                ],
            },
            table: {
                contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
            },
            language: "",
        };

        const TabLanguage = this.state.articleLanguages.map((item, index) => {
            return (
                <Tab key={index} eventKey={index} title={item.languageName}>
                    <br></br>

                    <div className="form-group">
                        <label> {t("title")} </label>
                        <input
                            className="form-control"
                            placeholder={t(
                                this.state.transPage + "title_placeholder"
                            )}
                            value={this.state.articleLanguages[index].title}
                            onChange={(event) =>
                                this.handleChangeTitle(
                                    index,
                                    event.target.value
                                )
                            }
                        ></input>
                        {/* {this.state.title} */}
                    </div>

                    <div className="form-group">
                        <label> {t("summary")} </label>
                        <textarea
                            className="form-control"
                            placeholder={t(
                                this.state.transPage + "summary_placeholder"
                            )}
                            value={this.state.articleLanguages[index].summary}
                            onChange={(event) =>
                                this.handleChangeSummary(
                                    index,
                                    event.target.value
                                )
                            }
                        ></textarea>
                    </div>

                    <div className="form-group pb-2">
                        <label> {t("content")}</label>

                        <CKEditor
                            id="editor"
                            config={ckeditorConfig}
                            editor={ClassicEditor}
                            data={this.state.articleLanguages[index].content}
                            onInit={(editor) => {
                                // You can store the "editor" and use when it is needed.
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();

                                this.handleChangeContent(index, data);
                            }}
                        />
                    </div>

                    <div className="seo-section">
                        <label> SEO </label>
                        <div className="form-group">
                            <label> {t("description")} </label>
                            <textarea
                                className="form-control"
                                value={
                                    this.state.articleLanguages[index]
                                        .description
                                }
                                onChange={(event) =>
                                    this.handleChangeDescription(
                                        index,
                                        event.target.value
                                    )
                                }
                            ></textarea>
                        </div>

                        <div id="form-keywords" className="form-group">
                            <label> {t("keyword")} </label>
                            <div className="fk-content">
                                {this.state?.loadedKeywords.map((a, i) => {
                                    return parseInt(a.language - 1) ===
                                        index ? (
                                        <div className="fk-c-in">
                                            <i
                                                className="fa fa-times fa-sm"
                                                onClick={(event) =>
                                                    this.deleteLoadedKeyword(
                                                        a.id,
                                                        i
                                                    )
                                                }
                                            ></i>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={a.keyword}
                                                onChange={(event) =>
                                                    this.updateLoadedKeyword(
                                                        i,
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <></>
                                    );
                                })}
                                {this.state?.articleKeywords.map((a, i) => {
                                    return parseInt(a.language - 1) ===
                                        index ? (
                                        <div className="fk-c-in">
                                            <i
                                                className="fa fa-times fa-sm"
                                                onClick={(event) =>
                                                    this.deleteAddedKeyword(i)
                                                }
                                            ></i>
                                            <input
                                                type="text"
                                                className="form-control"
                                                defaultValue={a.keyword}
                                                onChange={(event) =>
                                                    this.updateKeyword(
                                                        a.id,
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <></>
                                    );
                                })}
                                <input
                                    id={`fk-job-keywords${index}`}
                                    type="text"
                                    className="form-control"
                                    defaultValue={""}
                                    onChange={(event) =>
                                        this.handleKeyword(event.target.value)
                                    }
                                />

                                <div
                                    className="fk-icn-add"
                                    onClick={() => this.addKeyword(index)}
                                >
                                    <i className="fa fa-plus"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab>
            );
        });

        const years = myRange(1990, myGetYear(new Date()) + 1, 1);
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        // ================ button card tools =====================================

        const jobInfoToggle = this.state.jobInfoToggle ? (
            <button
                title="minimize"
                onClick={this.setJobInfoToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-minimize"></span>
            </button>
        ) : (
            <button
                title="maximize"
                onClick={this.setJobInfoToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-maximize"></span>
            </button>
        );

        const jobDescriptionToggle = this.state.jobDescription ? (
            <button
                title="minimize"
                onClick={this.setJobDescriptionToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-minimize"></span>
            </button>
        ) : (
            <button
                title="maximize"
                onClick={this.setJobDescriptionToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-maximize"></span>
            </button>
        );

        const jobSettingToggle = this.state.jobSettingToggle ? (
            <button
                title="minimize"
                onClick={this.setJobSettingToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-minimize"></span>
            </button>
        ) : (
            <button
                title="maximize"
                onClick={this.setJobSettingToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-maximize"></span>
            </button>
        );

        const mediaToggle = this.state.mediaToggle ? (
            <button
                title="minimize"
                onClick={this.setMediaToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-minimize"></span>
            </button>
        ) : (
            <button
                title="maximize"
                onClick={this.setMediaToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-maximize"></span>
            </button>
        );

        const applicationToggle = this.state.applicationToggle ? (
            <button
                title="minimize"
                onClick={this.setApplicationToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-minimize"></span>
            </button>
        ) : (
            <button
                title="maximize"
                onClick={this.setApplicationToggle}
                className="btn btn-xs btn-primary"
            >
                <span className="fa fa-window-maximize"></span>
            </button>
        );

        // ================ button card tools ======================================

        var Header = (
            <div>
                <TitlePages title={t(this.state.transPage + "title")} />
                {/* <h1 style={{float:"left"}}> Job Poster Add </h1> */}

                <div
                    style={{ float: "right", width: "10%", marginTop: "1rem" }}
                >
                    <button
                        className="btn btn-primary btn-block inline-block"
                        onClick={() => this.addJobPoster()}
                    >
                        <i className="fa fa-plus">&nbsp;</i> {t("add")}
                    </button>
                </div>

                <div
                    style={{
                        float: "right",
                        width: "10%",
                        marginRight: 10,
                        marginTop: "1rem",
                    }}
                >
                    <Link
                        className="btn btn-default btn-block inline-block"
                        to="/job-poster"
                    >
                        {t("back")}
                    </Link>
                </div>

                <div className="clearfix"></div>
            </div>
        );

        var JobApplication = "";

        if (this.props.match.params.jobPosterId) {
            Header = (
                <div>
                    <h1 style={{ float: "left" }}>
                        {" "}
                        {t(this.state.transPage + "job_poster_update")}{" "}
                    </h1>

                    <div style={{ float: "right", width: "10%" }}>
                        <button
                            disabled={isOnlyMonitor}
                            className="btn btn-primary btn-block inline-block"
                            onClick={() => this.updateJobPoster()}
                        >
                            {t("update")}
                        </button>
                    </div>
                    <div
                        style={{
                            float: "right",
                            width: "10%",
                            marginRight: 10,
                        }}
                    >
                        <Link
                            className="btn btn-default btn-block inline-block"
                            to="/job-poster"
                        >
                            {t("back")}
                        </Link>
                    </div>
                    <div className="clearfix"></div>
                </div>
            );

            JobApplication = (
                <JobApplicationList
                    jobId={this.props.match.params.jobPosterId}
                    appToggle={this.state.applicationToggle}
                    applicationToggle={applicationToggle}
                ></JobApplicationList>
            );
        }

        var AssetElement = this.state.AssetElement;

        if (this.state.articleId === 0) {
            AssetElement = (
                <ArticleAssetListAdd
                    selectedAssetAdd={this.state.selectedAssetAdd}
                    setSelectedAssetAdd={this.setSelectedAssetAdd}
                    //assets={this.state.assets}
                    deleteSelectedAssetAdd={this.deleteSelectedAssetAdd}
                    getDetailArticle={this.getDetailJob}
                ></ArticleAssetListAdd>
            );
        } else {
        }

        return (
            <Content>
                <ContentHeader>{Header}</ContentHeader>
                <ContentBody>
                    {JobApplication}

                    <Card>
                        <CardHeader className="bg-primary">
                            <CardTitle>
                                {" "}
                                <b>
                                    {" "}
                                    <i className="fa fa-info-circle">
                                        &nbsp;
                                    </i>{" "}
                                    {t(this.state.transPage + "job_info")}{" "}
                                </b>{" "}
                            </CardTitle>
                            <div className="card-tools">{jobInfoToggle}</div>
                        </CardHeader>
                        <Collapse
                            in={this.state.jobInfoToggle}
                            style={{ padding: "1.25rem" }}
                        >
                            <CardBody>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label> {t("position")} </label>
                                        <button
                                            disabled={isOnlyMonitor}
                                            className="btn btn-info btn-xs float-right"
                                            onClick={() => {
                                                this.setState(
                                                    {
                                                        positionId: 0,
                                                    },
                                                    () => {
                                                        this.setShowPosition();
                                                    }
                                                );
                                            }}
                                        >
                                            +{" "}
                                            {t(
                                                this.state.transPage +
                                                "add_position"
                                            )}{" "}
                                        </button>
                                        <span className="clearfix"></span>
                                        <InputGroup>
                                            <select
                                                className="form-control"
                                                value={
                                                    this.state.selectedPosition
                                                }
                                                onChange={(event) =>
                                                    this.handleChangePosition(
                                                        event.target.value
                                                    )
                                                }
                                            >
                                                <option value={null}>
                                                    {" "}
                                                    --{" "}
                                                    {t(
                                                        this.state.transPage +
                                                        "select_position"
                                                    )}{" "}
                                                    --
                                                </option>
                                                {this.state.positions.map(
                                                    (item) => {
                                                        return (
                                                            <option
                                                                value={item.id}
                                                                key={item.id}
                                                            >
                                                                {" "}
                                                                {item.name}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </select>
                                            <InputGroup.Prepend>
                                                <button
                                                    disabled={isOnlyMonitor}
                                                    className="btn btn-outline-secondary"
                                                    onClick={() => {
                                                        this.setState(
                                                            {
                                                                positionId: this
                                                                    .state
                                                                    .selectedPosition,
                                                            },
                                                            () => {
                                                                this.setShowPosition();
                                                            }
                                                        );
                                                    }}
                                                >
                                                    <i className="fa fa-edit"></i>
                                                </button>
                                                {/* <InputGroup.Text> <i className="fa fa-edit" ></i></InputGroup.Text> */}
                                            </InputGroup.Prepend>
                                            <InputGroup.Prepend>
                                                <button
                                                    disabled={isOnlyMonitor}
                                                    className="btn btn-outline-secondary"
                                                    onClick={() =>
                                                        this.setShowPositionDelete(
                                                            this.state
                                                                .selectedPosition
                                                        )
                                                    }
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </InputGroup.Prepend>
                                        </InputGroup>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                {" "}
                                                <label> {t("location")} </label>
                                                <button
                                                    disabled={isOnlyMonitor}
                                                    className="btn btn-info btn-xs float-right"
                                                    onClick={() => {
                                                        this.setState(
                                                            {
                                                                locationId: 0,
                                                            },
                                                            () => {
                                                                this.setShowLocation();
                                                            }
                                                        );
                                                    }}
                                                >
                                                    +{" "}
                                                    {t(
                                                        this.state.transPage +
                                                        "add_location"
                                                    )}{" "}
                                                </button>
                                                <span className="clearfix"></span>
                                                <InputGroup>
                                                    <select
                                                        className="form-control"
                                                        value={
                                                            this.state
                                                                .selectedLocation
                                                        }
                                                        onChange={(event) => {
                                                            this.handleChangeLocation(
                                                                event.target
                                                                    .value
                                                            );
                                                        }}
                                                    >
                                                        <option value={null}>
                                                            {" "}
                                                            --{" "}
                                                            {t(
                                                                this.state
                                                                    .transPage +
                                                                "select_location"
                                                            )}{" "}
                                                            --
                                                        </option>
                                                        {this.state.locations.map(
                                                            (item) => {
                                                                return (
                                                                    <option
                                                                        value={
                                                                            item.id
                                                                        }
                                                                        key={
                                                                            item.id
                                                                        }
                                                                    >
                                                                        {
                                                                            item.location_type
                                                                        }{" "}
                                                                        -{" "}
                                                                        {
                                                                            item.address
                                                                        }
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                    </select>
                                                    <InputGroup.Prepend>
                                                        <button
                                                            disabled={
                                                                isOnlyMonitor
                                                            }
                                                            className="btn btn-outline-secondary"
                                                            onClick={() => {
                                                                this.setState(
                                                                    {
                                                                        locationId: this
                                                                            .state
                                                                            .selectedLocation,
                                                                    },
                                                                    () => {
                                                                        this.setShowLocation();
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <i className="fa fa-edit"></i>
                                                        </button>
                                                    </InputGroup.Prepend>
                                                    <InputGroup.Prepend>
                                                        <button
                                                            disabled={
                                                                isOnlyMonitor
                                                            }
                                                            className="btn btn-outline-secondary"
                                                            onClick={() => {
                                                                this.setShowLocationDelete(
                                                                    this.state
                                                                        .selectedLocations
                                                                );
                                                            }}
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </InputGroup.Prepend>
                                                </InputGroup>
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label> {t("company")} </label>
                                                <button
                                                    className="btn btn-info btn-xs float-right"
                                                    onClick={() =>
                                                        this.setShowCompany()
                                                    }
                                                >
                                                    + {t("add_company")}{" "}
                                                </button>
                                                <div className="clearfix"></div>
                                                <InputGroup>
                                                    <select
                                                        value={
                                                            this.state
                                                                .selectedCompany
                                                        }
                                                        className="form-control"
                                                        onChange={(event) =>
                                                            this.handleChangeCompany(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <option>
                                                            {" "}
                                                            -- Select Company --{" "}
                                                        </option>
                                                        {this.state.companies.map(
                                                            (item) => {
                                                                return (
                                                                    <option
                                                                        value={
                                                                            item.id
                                                                        }
                                                                        key={
                                                                            item.id
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                    </select>
                                                    <InputGroup.Prepend
                                                        onClick={() => {
                                                            this.props.setShowCompany(
                                                                this.state
                                                                    .selectedCompany
                                                            );
                                                        }}
                                                    >
                                                        <InputGroup.Text>
                                                            {" "}
                                                            <i className="fa fa-edit"></i>
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <InputGroup.Prepend
                                                        onClick={() => {
                                                            this.props.setShowCompanyDelete(
                                                                this.state
                                                                    .selectedCompany
                                                            );
                                                        }}
                                                    >
                                                        <InputGroup.Text>
                                                            {" "}
                                                            <i className="fa fa-trash"></i>
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                </InputGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label>
                                                    {" "}
                                                    {t("min_salary")}{" "}
                                                </label>
                                                <button
                                                    disabled={isOnlyMonitor}
                                                    className="btn btn-info btn-xs float-right"
                                                    onClick={() => {
                                                        this.setState(
                                                            {
                                                                selectedCurrency: 0,
                                                            },
                                                            () => {
                                                                this.setShowCurrency();
                                                            }
                                                        );
                                                    }}
                                                >
                                                    +{" "}
                                                    {t(
                                                        this.state.transPage +
                                                        "add_currency"
                                                    )}{" "}
                                                </button>
                                                <InputGroup.Prepend>
                                                    <NumberFormat
                                                        thousandSeparator={true}
                                                        value={
                                                            this.state.minSalary
                                                        }
                                                        onChange={(event) => {
                                                            this.handleChangeMinSalary(
                                                                event.target
                                                                    .value
                                                            );
                                                        }}
                                                        className="form-control"
                                                        aria-label=""
                                                        aria-describedby="basic-addon1"
                                                    />
                                                    <InputGroup.Text id="basic-addon1">
                                                        <Dropdown>
                                                            <Dropdown.Toggle
                                                                as="a"
                                                                id="dropdown-custom-components"
                                                            >
                                                                {
                                                                    this.state
                                                                        .selectedCurrencyName
                                                                }
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                {this.state.currencies.map(
                                                                    (item) => {
                                                                        return (
                                                                            <Dropdown.Item
                                                                                key={
                                                                                    item.id
                                                                                }
                                                                                onClick={() => {
                                                                                    this.handleChangeCurrency(
                                                                                        {
                                                                                            id:
                                                                                                item.id,
                                                                                            name:
                                                                                                item.name,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </Dropdown.Item>
                                                                        );
                                                                    }
                                                                )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>
                                                    {" "}
                                                    {t("max_salary")}
                                                </label>
                                                <InputGroup.Prepend>
                                                    <NumberFormat
                                                        thousandSeparator={true}
                                                        value={
                                                            this.state.maxSalary
                                                        }
                                                        onChange={(event) => {
                                                            this.handleChangeMaxSalary(
                                                                event.target
                                                                    .value
                                                            );
                                                        }}
                                                        className="form-control"
                                                    />
                                                    <InputGroup.Text id="basic-addon1">
                                                        <Dropdown>
                                                            <Dropdown.Toggle
                                                                as="a"
                                                                id="dropdown-custom-components"
                                                            >
                                                                {
                                                                    this.state
                                                                        .selectedCurrencyName
                                                                }
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                {this.state.currencies.map(
                                                                    (item) => {
                                                                        return (
                                                                            <Dropdown.Item
                                                                                key={
                                                                                    item.id
                                                                                }
                                                                                eventKey="1"
                                                                                onClick={() => {
                                                                                    this.handleChangeCurrency(
                                                                                        {
                                                                                            id:
                                                                                                item.id,
                                                                                            name:
                                                                                                item.name,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </Dropdown.Item>
                                                                        );
                                                                    }
                                                                )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <label> {t("job_type")} </label>
                                                <button
                                                    disabled={isOnlyMonitor}
                                                    className="btn btn-info btn-xs float-right"
                                                    onClick={() => {
                                                        this.setState(
                                                            {
                                                                jobTypeId: 0,
                                                            },
                                                            () => {
                                                                this.setShowJobType();
                                                            }
                                                        );
                                                    }}
                                                >
                                                    +{" "}
                                                    {t(
                                                        this.state.transPage +
                                                        "add_job_type"
                                                    )}{" "}
                                                </button>
                                                <div className="clearfix"></div>
                                                <InputGroup className="">
                                                    <select
                                                        className="form-control"
                                                        value={
                                                            this.state.jobType
                                                        }
                                                        onChange={(event) =>
                                                            this.handleChangeJobType(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <option value="" key="">
                                                            {" "}
                                                            --{" "}
                                                            {t(
                                                                this.state
                                                                    .transPage +
                                                                "choose"
                                                            )}{" "}
                                                            --{" "}
                                                        </option>
                                                        {this.state.jobTypes.map(
                                                            (item) => {
                                                                return (
                                                                    <option
                                                                        value={
                                                                            item.id
                                                                        }
                                                                        key={
                                                                            item.id
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        {
                                                                            item.name
                                                                        }{" "}
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                    </select>
                                                    <InputGroup.Prepend>
                                                        <button
                                                            disabled={
                                                                isOnlyMonitor
                                                            }
                                                            className="btn btn-outline-secondary"
                                                            onClick={() => {
                                                                this.setState(
                                                                    {
                                                                        jobTypeId: this
                                                                            .state
                                                                            .jobType,
                                                                    },
                                                                    () => {
                                                                        this.setShowJobType();
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <i className="fa fa-edit"></i>
                                                        </button>
                                                    </InputGroup.Prepend>
                                                    <InputGroup.Prepend>
                                                        <button
                                                            disabled={
                                                                isOnlyMonitor
                                                            }
                                                            className="btn btn-outline-secondary"
                                                            onClick={() =>
                                                                this.setShowJobTypeDelete(
                                                                    this.state
                                                                        .jobType
                                                                )
                                                            }
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </InputGroup.Prepend>
                                                </InputGroup>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>
                                                    {" "}
                                                    {t("vacancies")}{" "}
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={this.state.vacancies}
                                                    onChange={(event) =>
                                                        this.handleChangeVacancies(
                                                            event.target.value
                                                        )
                                                    }
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label>
                                                    {" "}
                                                    {t("post_date")}{" "}
                                                </label>
                                                <DatePicker
                                                    className="form-control"
                                                    wrapperClassName="form-control"
                                                    onChange={(date) =>
                                                        this.handleChangePostDate(
                                                            date
                                                        )
                                                    }
                                                    selected={
                                                        this.state.postDate
                                                    }
                                                    renderCustomHeader={({
                                                        date,
                                                        changeYear,
                                                        changeMonth,
                                                        decreaseMonth,
                                                        increaseMonth,
                                                        prevMonthButtonDisabled,
                                                        nextMonthButtonDisabled,
                                                    }) => (
                                                        <div
                                                            style={{
                                                                margin: 10,
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                            }}
                                                        >
                                                            <button
                                                                onClick={
                                                                    decreaseMonth
                                                                }
                                                                disabled={
                                                                    prevMonthButtonDisabled
                                                                }
                                                            >
                                                                {"<"}
                                                            </button>
                                                            <select
                                                                value={myGetYear(
                                                                    date
                                                                )}
                                                                onChange={({
                                                                    target: {
                                                                        value,
                                                                    },
                                                                }) =>
                                                                    changeYear(
                                                                        value
                                                                    )
                                                                }
                                                            >
                                                                {years.map(
                                                                    (
                                                                        option
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                option
                                                                            }
                                                                            value={
                                                                                option
                                                                            }
                                                                        >
                                                                            {
                                                                                option
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>

                                                            <select
                                                                value={
                                                                    months[
                                                                    getMonth(
                                                                        date
                                                                    )
                                                                    ]
                                                                }
                                                                onChange={({
                                                                    target: {
                                                                        value,
                                                                    },
                                                                }) =>
                                                                    changeMonth(
                                                                        months.indexOf(
                                                                            value
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                {months.map(
                                                                    (
                                                                        option
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                option
                                                                            }
                                                                            value={
                                                                                option
                                                                            }
                                                                        >
                                                                            {
                                                                                option
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>

                                                            <button
                                                                onClick={
                                                                    increaseMonth
                                                                }
                                                                disabled={
                                                                    nextMonthButtonDisabled
                                                                }
                                                            >
                                                                {">"}
                                                            </button>
                                                        </div>
                                                    )}
                                                ></DatePicker>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label> {t("due_date")} </label>
                                                <DatePicker
                                                    className="form-control"
                                                    wrapperClassName="form-control"
                                                    onChange={(date) =>
                                                        this.handleChangeDueDate(
                                                            date
                                                        )
                                                    }
                                                    selected={
                                                        this.state.dueDate
                                                    }
                                                    renderCustomHeader={({
                                                        date,
                                                        changeYear,
                                                        changeMonth,
                                                        decreaseMonth,
                                                        increaseMonth,
                                                        prevMonthButtonDisabled,
                                                        nextMonthButtonDisabled,
                                                    }) => (
                                                        <div
                                                            style={{
                                                                margin: 10,
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                            }}
                                                        >
                                                            <button
                                                                onClick={
                                                                    decreaseMonth
                                                                }
                                                                disabled={
                                                                    prevMonthButtonDisabled
                                                                }
                                                            >
                                                                {"<"}
                                                            </button>
                                                            <select
                                                                value={myGetYear(
                                                                    date
                                                                )}
                                                                onChange={({
                                                                    target: {
                                                                        value,
                                                                    },
                                                                }) =>
                                                                    changeYear(
                                                                        value
                                                                    )
                                                                }
                                                            >
                                                                {years.map(
                                                                    (
                                                                        option
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                option
                                                                            }
                                                                            value={
                                                                                option
                                                                            }
                                                                        >
                                                                            {
                                                                                option
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>

                                                            <select
                                                                value={
                                                                    months[
                                                                    getMonth(
                                                                        date
                                                                    )
                                                                    ]
                                                                }
                                                                onChange={({
                                                                    target: {
                                                                        value,
                                                                    },
                                                                }) =>
                                                                    changeMonth(
                                                                        months.indexOf(
                                                                            value
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                {months.map(
                                                                    (
                                                                        option
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                option
                                                                            }
                                                                            value={
                                                                                option
                                                                            }
                                                                        >
                                                                            {
                                                                                option
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>

                                                            <button
                                                                onClick={
                                                                    increaseMonth
                                                                }
                                                                disabled={
                                                                    nextMonthButtonDisabled
                                                                }
                                                            >
                                                                {">"}
                                                            </button>
                                                        </div>
                                                    )}
                                                ></DatePicker>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label> {t("keyword")} </label>
                                            <button
                                                disabled={isOnlyMonitor}
                                                className="btn btn-info btn-xs float-right"
                                                onClick={() =>
                                                    this.setShowKeyword()
                                                }
                                            >
                                                +{" "}
                                                {t(
                                                    this.state.transPage +
                                                    "add_keyword"
                                                )}{" "}
                                            </button>

                                            <Select
                                                // defaultValue={this.props.list}
                                                isMulti
                                                name="user"
                                                options={keywords}
                                                className={[
                                                    "basic-multi-select",
                                                    "mt-3",
                                                ].join(" ")}
                                                classNamePrefix="select"
                                                onChange={(selectedKeyword) => {
                                                    this.setState({
                                                        selectedKeyword,
                                                    });
                                                }}
                                            />

                                            {/* <Select
                                                multi={true}
                                                searchable={true}
                                                searchBy="name"
                                                options={keywords}
                                                values={[
                                                    ...this.state
                                                        .selectedKeyword,
                                                ]}
                                                valueField="id"
                                                labelField="name"
                                                onChange={(selectedKeyword) => {
                                                    this.setState({
                                                        selectedKeyword,
                                                    });
                                                }}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Collapse>
                    </Card>

                    <Card>
                        <CardHeader className="bg-primary">
                            <CardTitle>
                                {" "}
                                <b>
                                    {" "}
                                    <i className="fa fa-newspaper">
                                        &nbsp;
                                    </i>{" "}
                                    {t(
                                        this.state.transPage + "job_description"
                                    )}
                                </b>{" "}
                            </CardTitle>
                            <div className="card-tools">
                                {jobDescriptionToggle}
                            </div>
                        </CardHeader>
                        <Collapse
                            in={this.state.jobDescription}
                            style={{ padding: "1.25rem" }}
                        >
                            <CardBody>
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={this.state.tabKey}
                                    onSelect={(k) => this.handleChangeTab(k)}
                                >
                                    {TabLanguage}
                                </Tabs>
                            </CardBody>
                        </Collapse>
                    </Card>

                    <Card>
                        <CardHeader className="bg-primary">
                            <CardTitle>
                                <b>
                                    {" "}
                                    <i className="fa fa-cogs">&nbsp;</i>{" "}
                                    {t(this.state.transPage + "job_settings")}{" "}
                                </b>
                            </CardTitle>
                            <div className="card-tools">{jobSettingToggle}</div>
                        </CardHeader>
                        <Collapse
                            in={this.state.jobSettingToggle}
                            style={{ padding: "1.25rem" }}
                        >
                            <CardBody>
                                <div className="row" id="collection-prefix">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                {t(
                                                    this.state.transPage +
                                                    "collection_job"
                                                )}
                                            </label>

                                            <Select
                                                // defaultValue={this.props.list}
                                                isMulti
                                                name="collection"
                                                options={widgetList}
                                                className={[
                                                    "basic-multi-select",
                                                    "mt-3",
                                                ].join(" ")}
                                                classNamePrefix="select"
                                                onChange={(selectedWidget) => {
                                                    this.setState({
                                                        selectedWidget,
                                                    });
                                                }}
                                            />
                                            {/* <Select
                                                multi
                                                disabled={
                                                    this.state.widgetDisable
                                                }
                                                searchable={true}
                                                searchBy="namePage"
                                                options={this.state.widgetList}
                                                values={[
                                                    ...this.state
                                                        .selectedWidget,
                                                ]}
                                                valueField="id"
                                                labelField="namePage"
                                                onDropdownClose={(value) => {}}
                                                onChange={(selectedWidget) => {
                                                    this.setState({
                                                        selectedWidget,
                                                    });
                                                }}
                                            /> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label> {t("status")}</label>
                                            <select
                                                className="form-control"
                                                value={this.state.status}
                                                onChange={(event) =>
                                                    this.handleChangeStatus(
                                                        event.target.value
                                                    )
                                                }
                                            >
                                                <option value="Drafted">
                                                    Drafted
                                                </option>
                                                <option value="Published">
                                                    {" "}
                                                    Published{" "}
                                                </option>
                                                <option value="Closed">
                                                    {" "}
                                                    Closed{" "}
                                                </option>
                                            </select>
                                            {/* {this.state.status} */}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{t("author")}</label>

                                            <Select
                                                // defaultValue={this.props.list}
                                                isMulti
                                                name="collection"
                                                options={author}
                                                className={[
                                                    "basic-multi-select",
                                                    "mt-3",
                                                ].join(" ")}
                                                classNamePrefix="select"
                                                onChange={(selectedWidget) => {
                                                    this.setState({
                                                        selectedWidget,
                                                    });
                                                }}
                                            />

                                            {/* <Select
                                                searchable={true}
                                                searchBy="label"
                                                name="form-field-name"
                                                values={this.state.author}
                                                options={
                                                    this.state.employeeList
                                                }
                                                valueField="val"
                                                labelField="label"
                                                onChange={(author) => {
                                                    this.setState({
                                                        author: [
                                                            {
                                                                val:
                                                                    author[0]
                                                                        .val,
                                                            },
                                                        ],
                                                    });
                                                }}
                                            /> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6"></div>
                                </div>
                            </CardBody>
                        </Collapse>
                    </Card>

                    <Card>
                        <CardHeader className="bg-primary">
                            <CardTitle>
                                <b>
                                    {" "}
                                    <i className="fa fa-photo-video">
                                        &nbsp;
                                    </i>{" "}
                                    {t("media")}{" "}
                                </b>
                            </CardTitle>
                            <div className="card-tools">{mediaToggle}</div>
                        </CardHeader>
                        <Collapse
                            in={this.state.mediaToggle}
                            style={{ padding: "1.25rem" }}
                        >
                            <CardBody>{AssetElement}</CardBody>
                        </Collapse>
                    </Card>
                </ContentBody>
                <CompanyAddModal
                    companyId={this.state.companyId}
                    show={this.state.showCompany}
                    onHide={this.setShowCompany}
                    callback={this.getCompanies}
                ></CompanyAddModal>
                <PositionAddModal
                    positionId={this.state.positionId}
                    show={this.state.showPosition}
                    onHide={this.setShowPosition}
                    callback={this.getPositions}
                ></PositionAddModal>
                <LocationAddModal
                    locationId={this.state.locationId}
                    companyId={this.state.companyId}
                    show={this.state.showLocation}
                    onHide={this.setShowLocation}
                    callback={this.getLocations}
                    setShowCompany={this.setShowCompany}
                    setShowCompanyDelete={this.setShowCompanyDelete}
                    companies={this.state.companies}
                ></LocationAddModal>
                <KeywordAddModal
                    show={this.state.showKeyword}
                    onHide={this.setShowKeyword}
                    callback={this.getKeywords}
                ></KeywordAddModal>
                <JobTypeAddModal
                    jobTypeId={this.state.jobTypeId}
                    show={this.state.showJobType}
                    onHide={this.setShowJobType}
                    callback={this.getJobTypes}
                ></JobTypeAddModal>
                <CurrencyAddModal
                    positionId={this.state.selectedCurrency}
                    show={this.state.showCurrency}
                    onHide={this.setShowCurrency}
                    callback={this.getCurrencies}
                ></CurrencyAddModal>
                <DeleteCompanyConfirm
                    companyId={this.state.companyId}
                    selectedCompanies={this.state.selectedCompanies}
                    onHide={this.setShowCompanyDelete}
                    show={this.state.showCompanyDelete}
                    deleteSelectedCompany={this.deleteSelectedCompany}
                ></DeleteCompanyConfirm>
                <DeleteLocationConfirm
                    locationId={this.state.locationId}
                    selectedLocations={this.state.selectedLocations}
                    onHide={this.setShowLocationDelete}
                    show={this.state.showLocationDelete}
                    deleteSelectedLocation={this.deleteSelectedLocation}
                ></DeleteLocationConfirm>
                <DeletePositionConfirm
                    positionId={this.state.positionId}
                    selectedPositions={this.state.selectedPositions}
                    onHide={this.setShowPositionDelete}
                    show={this.state.showPositionDelete}
                    deleteSelectedPosition={this.deleteSelectedPosition}
                ></DeletePositionConfirm>
                <DeleteJobTypeConfirm
                    jobTypeId={this.state.jobTypeId}
                    selectedJobTypes={this.state.selectedJobTypes}
                    onHide={this.setShowJobTypeDelete}
                    show={this.state.showJobTypeDelete}
                    deleteSelectedJobType={this.deleteSelectedJobType}
                ></DeleteJobTypeConfirm>
            </Content>
        );
    }
}

export default withTranslation()(JobPosterAdd);
