import React, { useContext } from "react";

import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { AccordionContext } from "react-bootstrap";

import Context from '../../Context'

import iW from '../assets/arrW.svg'
import iB from '../assets/arrB.svg'
import classes from "./Accordion.module.css";

export default function Accordion({ children, eventKey, callback }) {
    const contextValue = useContext(Context)
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey)
    );

    const isCurrentEventKey = parseInt(currentEventKey) === parseInt(eventKey);
    let icon;

    icon = isCurrentEventKey ?
        <img src={iW} alt="icon" />
        :
        <img src={iB} alt="icon" />

    let primaryClass = !contextValue.activeSidebar ? classes.activeSecondary : ''

    return (
        <div onClick={decoratedOnClick} className={[classes.accHead, primaryClass].join(' ')}>
            <p className={classes.titleAccordion}>{children}</p>
            {icon}
        </div>
    );
}
