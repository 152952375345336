import React, { Component } from "react";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import { GoogleMap, Marker, Autocomplete } from "@react-google-maps/api";
import _ from "lodash"

import Context from '../../pages/Context'

import GeneralApi from "./../../api/GeneralApi";
import JobApi from "./../../api/JobApi";
import LocationApi from "./../../api/LocationApi";

import { withTranslation } from "react-i18next";

import "./LocationModal.css";

class LocationModal extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);

        this.state = {
            gMapApiKey: "AIzaSyA6aQOcCYM5yXimMPDdTCopJD9wT0324L8",
            // gMapApiKey: 'AIzaSyCo2mDUObZFhtBXJg_QTEGbcMAyvn4zKkY',
            queryLocation: "",
            mapsLib: ["drawing", "places"],
            currentLatLng: false,
            defaultCenter: false,
            bounds: null,
            isMarkerShown: false,
            languageList: [],
            locationLanguage: [],
            companies: [],
            countries: [],
            selectedCountries: false,
            names: [],
            nameNoLang: "",
            address: [],
            addressNoLang: "",
            website: [],
            websiteNoLang: "",

            hasLanguage: true,
            tabKey: 1,
            selectedLanguage: 1,

            // update Loc
            locationLoaded: false,

            transPage: "modals.location_add_new.",
            transAlert: "alert.",
        };
        this.myMap = React.createRef();
        this.autocomplete = null;

        this.onOpenModal = this.onOpenModal.bind(this);

        this.onLoad = this.onLoad.bind(this);
        this.onPlaceChanged = this.onPlaceChanged.bind(this);

        this.handleChangeAddress = this.handleChangeAddress.bind(this);
        this.handleChangeWebsite = this.handleChangeWebsite.bind(this);
        this.handleHasLanguage = this.handleHasLanguage.bind(this);
        // this.handleLocationChange = this.handleLocationChange.bind(this)
        this.renderTabLanguage = this.renderTabLanguage.bind(this);
        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.handleChangeCompany = this.handleChangeCompany.bind(this);

        this.addNewLocation = this.addNewLocation.bind(this);
        this.getDetailLocation = this.getDetailLocation.bind(this);
        this.getCompanies = this.getCompanies.bind(this);
    }

    componentDidMount() {
    }

    getLanguages() {
        const objLang = new GeneralApi();

        return objLang.getLanguages();
    }

    getCompanies() {
        const obJob = new JobApi();

        obJob
            .getCompanies({
                max_size: true,
            })
            .then((res) => {
                this.setState({
                    companies: res.data.results,
                });
            })
            .catch((err) => { });
    }

    getAllCountries() {
        const locApi = new LocationApi();

        locApi
            .getAllCountries({
                max_size: true
            })
            .then((res) => {
                this.setState({
                    countries: res.data.results,
                });
            })
            .catch((err) => { });
    }

    onOpenModal() {
        const { activeContentLang } = this.context;

        this.showCurrentLocation();
        this.getAllCountries()

        if (activeContentLang) {
            this.setState({
                id: this.props.positionId | 0,
                tabKey: 1,
                selectedLanguage: 1,
                languageList: activeContentLang,
            })
        }
    }

    showCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState((prevState) => ({
                    currentLatLng: {
                        ...prevState.currentLatLng,
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    },
                    isMarkerShown: true,
                }));
            });
        } else {
            this.setState({
                currentLatLng: {
                    lat: 0,
                    lng: 0,
                },
                defaultCenter: {
                    lat: -6.1741,
                    lng: 106.8296,
                },
            })
        }
    };

    onLoad = (autocomplete) => {
        this.autocomplete = autocomplete;
    }

    onPlaceChanged = (place) => {
        const { countries } = this.state

        place = this.autocomplete.getPlace()

        let selectedCountry = {}
        const addressComponents = place?.address_components;

        if (addressComponents) {
            const countriesName = this.state.countries.map(country => country.iso_code)
            const addressCountry = addressComponents.map(address => address.short_name)
            const foundedCountry = _.intersection(countriesName, addressCountry)
            const countryCode = foundedCountry.length ? foundedCountry[0] : ""
            selectedCountry = countries.find(({ iso_code }) => iso_code === countryCode);
        }

        this.setState((prevState) => ({
            addressNoLang: place?.formatted_address,
            address: Array(this.state.languageList.length).fill({ address: place?.formatted_address }),
            currentLatLng: {
                ...prevState.currentLatLng,
                lat: place?.geometry.location.lat(),
                lng: place?.geometry.location.lng(),
            },
            isMarkerShown: true,
            selectedCountries: selectedCountry,
        }))
    }

    showCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState((prevState) => ({
                    currentLatLng: {
                        ...prevState.currentLatLng,
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    },
                    isMarkerShown: true,
                }));
            });
        } else {
            console.log("error");
        }
    };

    addNewLocation() {
        const contextValue = this.context;
        const location_languages = this.state.languageList.map((item, index) => {
            return {
                language: item?.language?.id,
                name: this.state.names[index]
                    ? this.state.names[index].name
                    : "-",
                address: this.state.address[index]
                    ? this.state.address[index].address
                    : "",
                website: this.state.website[index]
                    ? this.state.website[index].website
                    : "",
            };
        });

        const newLocation = {
            client: contextValue.activeClient,
            company: this.state.selectedCompany,
            name: this.state.nameNoLang ? this.state.nameNoLang : "-", //location_type is NAME   
            country: this.state.selectedCountries?.id,
            latitude: this.state.currentLatLng?.lat,
            longitude: this.state.currentLatLng?.lng,
            // description: document.getElementById("m-loc-description").value || "-",
            // email: document.getElementById("m-loc-email").value || "-",
            // phone: document.getElementById("m-loc-phone").value || "-",
            address: this.state.addressNoLang || "-",
            // website: this.state.websiteNoLang || "-",
            location_languages: location_languages || "-",
        };
        this.props.addNewLocation(newLocation);
    }

    getDetailLocation(locationId) {
        const obj = new LocationApi();

        obj
            .getDetailLocation(locationId)
            .then((res) => {
                this.setState(
                    {
                        address: res.data.address,
                        currentLatLng: {
                            lat: parseFloat(res.data.latitude),
                            lng: parseFloat(res.data.longitude),
                        },
                        // locationType: res.data.location_type,
                        // longitude: res.data.longitude,
                        // latitude: res.data.latitude,
                        // selectedCompany: res.data.company,
                        // selectedCountry: res.data.country,
                        // locationLanguages: res.data.location_languages
                        locationLoaded: true,
                    },
                    () => {
                        this.renderTabLanguage();
                    }
                );
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    handleChangeNames(index, value) {
        let names = [...this.state.names];
        let new_names = { ...names[index] };
        new_names.name = value;
        names[index] = new_names;

        this.setState(
            {
                names: names,
            },
            () => { }
        );
    }

    handleChangeNameNoLang(value) {
        this.setState({
            nameNoLang: value,
        });
    }

    handleChangeAddress(index, value) {
        let location_address = [...this.state.address];
        let newlocation_address = { ...location_address[index] };
        newlocation_address.address = value;
        location_address[index] = newlocation_address;

        this.setState(
            {
                // oldaddress: newlocation_address[0].address,
                address: location_address,
            },
            () => { }
        );
    }

    handleChangeAddressNoLang(value) {
        this.setState({
            addressNoLang: value,
        });
    }

    handleChangeWebsite(index, value) {
        let location_website = [...this.state.website];
        let newlocation_website = { ...location_website[index] };
        newlocation_website.website = value;
        location_website[index] = newlocation_website;

        this.setState(
            {
                website: location_website,
            },
            () => { }
        );
    }

    handleChangeWebsiteNoLang(value) {
        this.setState({
            websiteNoLang: value,
        });
    }

    handleChangeTab(value) {
        this.setState(
            {
                selectedLanguage: value,
                tabKey: value,
            },
            () => {
                this.renderTabLanguage();
            }
        );
    }

    handleChangeCompany(value) {
        this.setState({
            selectedCompany: value,
        });
    }

    handleHasLanguage() {
        this.setState({
            hasLanguage: !this.state.hasLanguage,
        });
    }

    renderTabLanguage() {
        const renderTabLanguage = this.state.languageList.map((item) => {
            return {
                language: item?.language?.id,
                languageName: item.name,
                description: "",
            };
        });

        this.setState({
            locationLanguage: renderTabLanguage,
        });
    }

    render() {
        const { t } = this.props;

        const containerStyle = {
            width: "100%",
            height: "40vh",
        };

        let renderTabLanguage;
        if (this.state.hasLanguage) {
            if (this.state.languageList) {
                let tabLanguage;
                tabLanguage = this.state.languageList.map((item, index) => {
                    return (
                        <Tab key={index} eventKey={index + 1} title={item.name}>
                            <br></br>

                            <div className="form-group">
                                <label>
                                    {t(this.state.transPage + "name")}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.names[index]?.name}
                                    onChange={(event) =>
                                        this.handleChangeNames(index, event.target.value)
                                    }
                                ></input>
                            </div>
                            <div className="form-group">
                                <label> {t("address")} </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.address[index]?.address}
                                    onChange={(event) =>
                                        this.handleChangeAddress(index, event.target.value)
                                    }
                                ></input>
                            </div>
                        </Tab>
                    );
                });

                renderTabLanguage = (
                    <div className="form-group">
                        <Tabs
                            id="controlled-tab-example-3"
                            activeKey={this.state.tabKey}
                            onSelect={(k) => this.handleChangeTab(k)}
                        >
                            {tabLanguage}
                        </Tabs>
                    </div>
                );
            }
        } else {
            renderTabLanguage = (
                <>
                    <div className="form-group">
                        <label> {t(this.state.transPage + "name")} </label>
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.nameNoLang}
                            onChange={(event) => this.handleChangeNameNoLang(event.target.value)}
                        ></input>
                    </div>
                    <div className="form-group">
                        <label> {t("address")} </label>
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.addressNoLang}
                            onChange={(event) =>
                                this.handleChangeAddressNoLang(event.target.value)
                            }
                        ></input>
                    </div>
                    <div className="form-group mt-2">
                        <label for="m-loc-name">
                            {t("website")}
                        </label>
                        <input
                            id="m-loc-name"
                            type="text"
                            name="asset_name"
                            className="form-control"
                            value={this.state.websiteNoLang}
                            onChange={(event) =>
                                this.handleChangeWebsiteNoLang(event.target.value)
                            }
                        ></input>
                    </div>
                </>
            );
        }

        let defaultLocation
        if (this.state.locationLoaded) {
            defaultLocation = this.state.currentLatLng
        } else if (this.state.isMarkerShown) {
            defaultLocation = this.state.currentLatLng
        } else if (!this.state.isMarkerShown && this.state.defaultCenter) {
            defaultLocation = this.state.defaultCenter
        }

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                onShow={this.onOpenModal}
            >
                <Modal.Header closeButton className="bg-primary">
                    <Modal.Title> Location </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        defaultLocation &&
                        <div style={{ height: "100%" }}>
                            <GoogleMap
                                ref={this.myMap}
                                mapContainerStyle={containerStyle}
                                center={defaultLocation}
                                zoom={10}
                            >
                                <Autocomplete
                                    onLoad={this.onLoad}
                                    onPlaceChanged={this.onPlaceChanged}
                                >
                                    <input
                                        id="loc-search-place"
                                        type="text"
                                        placeholder="Search place"
                                    />
                                </Autocomplete>
                                <Marker position={this.state.isMarkerShown ? this.state.currentLatLng : this.state.defaultCenter} />
                            </GoogleMap>
                        </div>
                    }

                    <div className="form-group mt-3">
                        <label htmlFor="m-loc-name">{t("name")}</label>
                        <input
                            id="m-loc-name"
                            type="text"
                            name="m-loc-name"
                            className="form-control"
                            value={this.state.nameNoLang}
                            onChange={(event) => this.handleChangeNameNoLang(event.target.value)}
                        ></input>
                    </div>

                    <div className="form-group ">
                        <label htmlFor="m-loc-address">{t("address")}</label>
                        <input
                            id="m-loc-address"
                            type="text"
                            name="m-loc-address"
                            className="form-control"
                            value={this.state.addressNoLang}
                            onChange={(event) =>
                                this.handleChangeAddressNoLang(event.target.value)
                            }
                        ></input>
                    </div>

                    <div className="form-group ">
                        <label htmlFor="m-loc-country">{t("country")}</label>
                        <input
                            id="m-loc-country"
                            type="text"
                            name="m-loc-country"
                            className="form-control"
                            value={this.state.selectedCountries?.name ? this.state.selectedCountries?.name : ''}
                        ></input>
                    </div>

                    {renderTabLanguage}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={this.props.onHide}>
                        <i className="fa fa-times">&nbsp;</i>
                        {t(this.state.transPage + "cancel")}
                    </Button>
                    <Button variant="primary" onClick={() => this.addNewLocation()}>
                        <i className="fa fa-plus">&nbsp;</i>
                        {t(this.state.transPage + "add_new_location")}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(LocationModal);
