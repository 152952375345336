import React, { Component } from 'react';
import { Tabs, Tab, Button } from "react-bootstrap"

import swal from 'sweetalert'
import AlertHelper from "./../../lib/AlertHelper"

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor"

import WidgetApi from "../../api/WidgetApi"
import SectionApi from "./../../api/SectionApi"

import "./TextForm.css"
import Context from "../Context";

class TextForm extends Component {
    static contextType = Context;
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            name: "",
            content: "",
            input_type: "Small",
            urlType: "internal",
            selectedPage: "",
            selectedSection: "",
            urlEnglish: "",
            urlVietname: "",

            pages: [],
            sections: [],
            text_languages: [],
            languageList: [],

            tabKey: 1,
            selectedLanguage: 1,
            idElement: 0,
            renderTabLanguage: "",

            widget: {}
        }


        // this.onOpenModal = this.onOpenModal.bind(this)
        // this.onCloseModal = this.onCloseModal.bind(this)

        this.handleNameChange = this.handleNameChange.bind(this)
        this.handleContentChange = this.handleContentChange.bind(this)
        this.handleChangeTab = this.handleChangeTab.bind(this)
        this.handleUrlTypeChange = this.handleUrlTypeChange.bind(this)
        this.handleUrlExternalChange = this.handleUrlExternalChange.bind(this)
        // this.handleInputTypeChange = this.handleInputTypeChange.bind(this)

        this.getDetailTextElement = this.getDetailTextElement.bind(this)

        this.renderTabLanguage = this.renderTabLanguage.bind(this)

    }

    componentDidMount() {
        const { activeContentLang, activeSiteLang } = this.context;

        let defaultLang = this.props.is_internal ? activeSiteLang : activeContentLang

        this.setState({
            id: this.props.text_element?.id | 0,
            tabKey: 1,
            selectedLanguage: 1,
            languageList: defaultLang,
            widget: this.props.widget

        }, () => {

            this.getAllSections()
            this.getPages()

            if (this.state.id) {
                //alert(this.state.id)
                this.getDetailTextElement()
            } else {
                this.renderTabLanguage()
            }

        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { activeContentLang, activeSiteLang } = this.context;

        if (this.props.is_internal) {
            if (activeSiteLang.length > 0) {
                if (this.state.languageList !== activeSiteLang) {
                    this.setState({
                        languageList: activeSiteLang
                    })
                }
            }
        } else {
            if (activeContentLang.length > 0) {
                if (this.state.languageList !== activeContentLang) {
                    this.setState({
                        languageList: activeContentLang
                    })
                }
            }
        }
    }

    componentWillUnmount() {
        this.setState({
            id: 0,
            name: "",
            // languageList:[],
            tabKey: 1,
            selectedLanguage: 1,
            text_languages: [],
            renderTabLanguage: ""
        }, () => {
            this.props.onHide()
        })
    }

    getPages() {
        const { sectionDetail } = this.context;

        this.setState({
            pages: sectionDetail.pagesList
        })
    }

    getSectionByPage() {
        const objSection = new SectionApi()

        const params = {
            language: localStorage.getItem("lang"),
            page_id: this.state.selectedPageId,
            max_size: true
        }

        objSection.sectionLanguage(params)
            .then(res => {
                this.setState({
                    sections: res.data.results
                })
            })
    }

    getAllSections() {
        const { sectionDetail } = this.context;

        this.setState({
            sectionList: sectionDetail.sectionList
        })
    }


    getDetailTextElement() {
        const obj = new WidgetApi()
        const textId = this.props.text_element.id

        obj.widgetTextDetail(textId)
            .then(res => {
                this.setState({
                    id: res.data.id,
                    name: res.data.name,
                    text_languages: res.data.text_languages,
                    input_type: res.data.input_type,
                    widget: this.props.widget
                }, () => {

                    this.renderTabLanguage()
                })
            })
    }

    handleNameChange(value) {
        this.setState({
            name: value
        })
    }

    handleUrlTypeChange(value) {
        this.setState({
            urlType: value
        })
    }

    handleUrlExternalChange(id, value) {
        let urlLanguages = [...this.state.urlLanguages]
        let urlLanguage = { ...urlLanguages[id] }
        urlLanguage.path = value
        urlLanguages[id] = urlLanguage

        this.setState({
            urlLanguages: urlLanguages
        })
    }

    handleContentChange(id, value) {
        let text_languages = [...this.state.text_languages]
        let text_language = { ...text_languages[id] }
        text_language.content = value
        text_languages[id] = text_language

        this.setState({
            text_languages: text_languages
        })
    }

    handleChangeTab(value) {

        this.setState({

            selectedLanguage: value,
            tabKey: value,
        }, () => {
            this.renderTabLanguage()
        })
    }

    handlePageChange(value) {
        if (!value) {
            this.setState({
                selectedPageId: null,
                selectedSectionId: null,
                sections: []
            }, () => {
                //this.getSectionByPage()
            })
        } else {
            this.setState({
                selectedPageId: value
            }, () => {
                this.getSectionByPage()
            })

        }
    }

    handleSectionChange(value) {
        this.setState({
            selectedSectionId: value
        })
    }

    addWidgetText() {

        const objWidget = new WidgetApi()

        this.setState({
            loadingModal: <div className="overlay d-flex justify-content-center align-items-center">
                <i className="fas fa-2x fa-sync fa-spin"></i>
            </div>
        })

        const inputTextLanguage = this.state.text_languages.map(item => {
            return ({
                "language": item.language,
                content: item.content
            })
        })

        objWidget.widgetTextAdd(this.props.widget.id, this.state.name, inputTextLanguage)
            .then(res => {


                swal("Success !", "You sucessfully added text into this widget !", "success");
                this.props.getWidgetDetail()
            })
            .catch(err => {
                const objAlert = new AlertHelper()
                const resErr = err?.response?.data ? err?.response?.data : err.message
                objAlert.errorHandlerAlert(resErr)
            })

    }

    updateWidgetText() {

        const objWidget = new WidgetApi()

        this.setState({
            loadingModal: <div className="overlay d-flex justify-content-center align-items-center">
                <i className="fas fa-2x fa-sync fa-spin"></i>
            </div>
        })

        const inputTextLanguage = this.state.text_languages.map(item => {
            return ({
                id: item.id,
                "language": item.language,
                content: item.content
            })
        })

        const data = {
            textId: this.state.id,
            name: this.state.name,
            input_type: this.state.input_type,
            text_languages: inputTextLanguage
        }

        objWidget.widgetTextUpdate(data)
            .then(res => {

                swal("Success !", "You sucessfully added text into this widget !", "success");
                this.props.getWidgetDetail()
            })
            .catch(err => {
                // const objAlert = new AlertHelper()
                // const resErr = err?.response?.data ? err?.response?.data : err.message
                console.log(" Error => ", err?.response?.data)
                // objAlert.errorHandlerAlert(resErr)
            })

    }

    renderTabLanguage() {

        const renderTabLanguage = this.state.languageList.map(item => {
            var result = this.state.text_languages.find(textLang => item.id === textLang.language)

            if (!result) {
                result = {
                    language: item?.language?.id,
                    text_element: 4,
                    content: "",
                    languageName: item.name
                }
            }

            result.languageName = item.name
            return result
        })

        this.setState({
            text_languages: renderTabLanguage
        })


    }

    render() {

        var ButtonExecute = <Button className="float-right" variant="primary" onClick={() => this.addWidgetText()}>
            <i className="fa fa-plus">&nbsp;</i>
            Add Text Element
        </Button>

        if (this.state.id) {
            ButtonExecute = <Button className="float-right" variant="primary" onClick={() => this.updateWidgetText()}>
                <i className="fa fa-edit">&nbsp;</i>
                Update Text Element
            </Button>
        }

        let ckeditorConfig = {

            toolbar: {
                items: [
                    "heading",
                    "|",
                    'bold',
                    'italic',
                    "|",
                    "link",
                    '|',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'insertTable',

                    "|",
                    'undo',
                    'redo'
                ]
            },
            table: {
                contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
            },
            language: "",

        }

        const TabLanguage = this.state.text_languages.map((item, index) => {

            var TextEditor = <textarea
                className="form-control"
                onChange={(event) => this.handleContentChange(index, event.target.value)}
                value={this.state.text_languages[index].content}
            >
            </textarea>

            if (this.state.input_type === "Big") {
                TextEditor = <CKEditor

                    id="editor"
                    config={ckeditorConfig}
                    editor={ClassicEditor}
                    data={this.state.text_languages[index].content}
                    onInit={editor => {
                        // You can store the "editor" and use when it is needed.

                    }}
                    onChange={(event, editor) => {

                        const data = editor.getData()

                        this.handleContentChange(index, data)
                    }}
                />

            }

            return (

                <Tab key={index} eventKey={item.language} title={item.languageName}>
                    <br></br>

                    <div className="form-group">
                        <label> Content </label>
                        {TextEditor}
                        {/* { JSON.stringify( this.state.text_languages )  } */}
                    </div>

                </Tab>
            )
        })

        return (
            <div>
                <div id="text-form" className="row">

                    <div className="col-md-6">
                        <div className="form-group">
                            <label> Name </label>
                            <input type="text" className="form-control" onChange={(event) => this.handleNameChange(event.target.value)} value={this.state.name}></input>
                        </div>
                        <Tabs
                            id="controlled-tab-example-3"
                            activeKey={this.state.tabKey}
                            onSelect={(k) => this.handleChangeTab(k)}
                        >
                            {TabLanguage}
                        </Tabs>
                    </div>
                </div>
                <div className="row">
                    <div className="pull-left col-md-6">

                    </div>
                    <div className="pull-right col-md-6">
                        {ButtonExecute}
                    </div>
                </div>
            </div>

        );
    }
}

export default TextForm;