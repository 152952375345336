import React, { Component } from 'react'
import { Modal, Button } from "react-bootstrap"
import { withTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import ArticleOrder from '../ArticleOrder/ArticleOrder'

class ArticleOrderModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            transPage: "modals.article_order.",
            orderChanges: false
        }
        this.onOpenModal = this.onOpenModal.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)
        this.applyOrder = this.applyOrder.bind(this)
        this.orderChanges = this.orderChanges.bind(this)

    }

    onOpenModal() {
        this.setState({
            orderChanges: false
        })
    }

    onCloseModal() {
        this.props.onHide()
    }

    applyOrder() {
        this.props.confirmArticleOrder(this.props.widgetId, this.state.orderChanges)
        setTimeout(() => {
            this.props.onHide()
        }, 300);
    }

    orderChanges(data) {
        if (this.state.orderChanges !== data) {
            this.setState({
                orderChanges: data
            })
        }
    }

    render() {
        const { t } = this.props;

        return (
            <Modal size="lg"
                show={this.props.show} onHide={() => this.onCloseModal()} onShow={() => this.onOpenModal()}>
                <Modal.Header closeButton className="bg-primary">
                    <Modal.Title>Articles Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DndProvider backend={HTML5Backend}>
                        <ArticleOrder data={this.props.data} orderChanges={this.orderChanges} />
                    </DndProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.props.onHide()}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={this.applyOrder}>
                        <i className="fa fa-sort">&nbsp;</i>
                        Apply Order
                    </Button>
                </Modal.Footer>

            </Modal>
        )
    }
}

export default withTranslation()(ArticleOrderModal);

