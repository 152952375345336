import React, { Component } from "react";
import { Modal, Tab, Tabs, Button } from "react-bootstrap";
import swal from "sweetalert";
import AlertHelper from "./../../lib/AlertHelper";
import { withTranslation } from "react-i18next";

import Select from "react-dropdown-select"

import Context from "../../pages/Context";
import PageApi from "./../../api/PageApi";
import WidgetApi from "../../api/WidgetApi"

// import GeneralApi from "../../api/GeneralApi";

class PageAddModal extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);

        this.state = {
            pageId: 0,
            parent: null,
            name: "",
            title: "",
            template: "",
            description: "",
            languageList: [],

            names: [],
            selectedLanguage: 1,
            tabKey: 1,

            widgetList: [],
            selectedWidget: [],
            is_internal: false,

            param_qty: 0,
            hasParams: false,
            isPublished: false,
            pageSetting: {},
            pageCatalogSetting: [],
            transPage: "modals.page_add.",
            transAlert: "alert.",
            lengtOfPages: "",
        };

        this.onShowModal = this.onShowModal.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
        this.addPage = this.addPage.bind(this);
        this.updatePage = this.updatePage.bind(this);
        this.getPageDetail = this.getPageDetail.bind(this);
        this.getAllWidget = this.getAllWidget.bind(this);

        this.handleHasParams = this.handleHasParams.bind(this);
        this.handleIsPublished = this.handleIsPublished.bind(this);
        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleDescChange = this.handleDescChange.bind(this);
        this.onChangeHandle = this.onChangeHandle.bind(this);

        this.renderSetting = this.renderSetting.bind(this);
        this.renderTabLanguage = this.renderTabLanguage.bind(this);
        this.getPageStyleCatalogSetting = this.getPageStyleCatalogSetting.bind(
            this
        );
    }

    onShowModal() {
        const { activeSiteLang } = this.context;
        this.getAllWidget()

        this.setState(
            {
                languageList: activeSiteLang,

                pageId: this.props.pageId,
                parent: this.props.parent,
                lengtOfPages: this.props.lengtOfPages,
                template: localStorage.getItem("templateId"),
            },
            () => {
                if (this.state.pageId) {
                    this.getPageDetail();
                } else {
                    var urlLangList = this.state.languageList.map((item) => {
                        var result = {
                            id: null,
                            language: item?.language?.id,
                            languageName: item.name,
                            name: "",
                            title: ""
                        };

                        return result;
                    });

                    this.setState({
                        names: urlLangList,
                    }, () => {
                        this.renderTabLanguage();
                    });
                }

                this.getPageStyleCatalogSetting();
            }
        );

    }

    componentDidUpdate(prevProps, prevState) {
        const { activeSiteLang } = this.context;

        if (activeSiteLang.length > 0) {
            if (this.state.languageList !== activeSiteLang) {
                this.setState({
                    languageList: activeSiteLang
                })
            }
        }
    }

    handleHasParams() {
        this.setState({
            hasParams: !this.state.hasParams,
        });
    }

    handleChangeTab(value) {
        this.setState(
            {
                selectedLanguage: value,
                tabKey: value,
            },
            () => {
                this.renderTabLanguage();
            }
        );
    }

    onHideModal() {
        this.setState(
            {
                pageId: 0,
                parent: null,
                name: "",
                title: "",
                template: "",
                description: "",
                languageList: [],

                names: [],
                selectedWidget: [],
                selectedLanguage: 1,
                tabKey: 1,
            },
            () => {
                this.props.onHide();
            }
        );
    }

    onChangeHandle(key, itemType, value) {
        this.setState((prevState) => ({
            pageSetting: {
                ...prevState.pageSetting,
                [key]: value,
            },
        }));
    }

    getPageStyleCatalogSetting() {
        const objPage = new PageApi();

        objPage.pageStylesCatalogSetting().then((res) => {
            this.setState(
                {
                    pageCatalogSetting: res.data.results,
                },
                () => {
                    this.state.pageCatalogSetting.forEach((item) => {
                        this.setState((prevState) => ({
                            pageStyle: {
                                ...prevState.pageStyle,
                                [item.name]: "",
                            },
                        }));
                    });
                }
            );
        });
    }

    renderTabLanguage() {
        const renderTabLanguage = this.state.languageList.map((item, index) => {
            var findName = this.state.names.find(
                (textLang, idx) => textLang.language === item.id
            );

            if (this.state.pageId) {
                if (findName) {
                    return {
                        id: findName?.id,
                        language: item?.language?.id,
                        name: findName ? findName.name : "",
                        title: findName ? findName.title : "",
                        description: findName ? findName.description : "",
                        languageName: item.name,
                    };
                }
            } else {
                return {
                    id: findName?.id,
                    language: item?.language?.id,
                    name: findName ? findName.name : "",
                    title: findName ? findName.title : "",
                    description: findName ? findName.description : "",
                    languageName: item.name,
                    //   page: this.state.selectedPage,
                    //   section: this.state.selectedSection,
                    //   path: this.state.names[index].path,
                };
            }

            return null
        });

        this.setState({
            names: renderTabLanguage,
        });
    }

    renderSetting() {
        return this.state.pageCatalogSetting.map((item) => {
            var itemType = "dropdown";

            switch (itemType) {
                case "dropdown":
                    return (
                        <div className="form-group">
                            <input
                                type="checkbox"
                                value="enable"
                                id={"enable-" + item.name}
                            ></input>{" "}
                            &nbsp;
                            <label htmlFor={"enable-" + item.name}>
                                {" "}
                                {item.name}{" "}
                            </label>
                            <select
                                className="form-control"
                                value={this.state[item.name]}
                                onChange={(event) =>
                                    this.onChangeHandle(
                                        item.name,
                                        itemType,
                                        event.target.value
                                    )
                                }
                            >
                                <option value="">
                                    {" "}
                                    -- Please Select one --{" "}
                                </option>
                                {item.options.map((atem) => {
                                    return (
                                        <option value={atem.id} key={atem.id}>
                                            {atem.value}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    );

                case "checkbox":
                    return (
                        <div className="form-group">
                            <label> {item.name} </label>
                            <br />
                            Enable:{" "}
                            <input
                                type="checkbox"
                                name={item.name}
                                onChange={(event) =>
                                    this.onChangeHandle(
                                        item.name,
                                        itemType,
                                        event.target.checked
                                    )
                                }
                            />
                        </div>
                    );

                default:
                    return "text";
            }
        });
    }

    handleIsPublished(e) {
        this.setState({
            isPublished: e,
        });
    }

    handleNameChange(id, value) {
        let names = [...this.state.names];
        let name = { ...names[id] };
        name.name = value;
        names[id] = name;

        this.setState({
            name: names[0].name,
            // title:name[0].title,
            names: names,
        });
    }

    handleTitleChange(id, value) {
        let names = [...this.state.names];
        let name = { ...names[id] };
        name.title = value;
        names[id] = name;

        this.setState({
            // name:names[0].name,
            title: names[0].title,
            names: names,
        });
    }

    handleDescChange(id, value) {
        let names = [...this.state.names];
        let name = { ...names[id] };
        name.description = value;
        names[id] = name;

        this.setState({
            // name:names[0].name,
            description: names[0].description,
            names: names,
        });
    }

    getPageDetail() {
        const objPage = new PageApi();

        objPage.getPageDetail(this.state.pageId).then((res) => {
            const names = res.data.page_languages.map((item) => {
                return {
                    id: item.id ? item.id : null,
                    language: item.language,
                    languageName: item.language_name,
                    name: item.name,
                    title: item.title,
                    description: item.description,
                };
            });

            let widgets
            if (res.data.widgets) {
                widgets = res.data.widgets.map(w => {
                    return {
                        id: w.id,
                        name: w.name
                    }
                })
            }

            this.setState({
                pageId: res.data.id,
                name: res.data.name,
                // title: res.data.title,
                template: res.data.template,
                parent: res.data.parent,
                hasParams: res.data.has_params,
                isPublished: res.data.is_draft,
                names: names,
                selectedWidget: widgets
            }, () => {
                this.renderTabLanguage();
            });
        });
    }

    getAllWidget() {
        const { activeClient } = this.context;

        const objWidget = new WidgetApi()

        // const urlParams = new URLSearchParams(window.location.search);
        // const templateId = urlParams.get('template')

        const params = {
            // template: templateId ? templateId : Number(localStorage.getItem("templateId")),
            client: activeClient,
            max_size: true,
            category: 1,
            internal: this.state.is_internal
        }

        objWidget.getAllWidgets(params)
            .then(res => {
                const widgetList = res.data.results.map(item => {

                    var page = ""
                    if (item.page) {
                        page = " - " + item.page
                    }

                    return {
                        ...item,
                        namePage: item.name + page
                    }
                })

                this.setState({
                    widgetList: widgetList
                })
            })
    }

    addPage() {
        const { t } = this.props;
        const objPage = new PageApi();

        const names = this.state.names.map((item) => {
            return {
                language: item.language,
                name: item.name,
                title: item.title,
                description: item.description,
            };
        });

        // const page_styles = this.state.pageCatalogSetting.map((item) => {
        //   var options = []; // change this logic

        //   return {
        //     style_id: item.id,
        //     options: options,
        //   };
        // });

        let widgets = []
        if (this.state.selectedWidget) {
            this.state.selectedWidget.forEach(item => {
                widgets.push(item.id)
            })
        }

        const l = this.state.lengtOfPages === null ? null : this.state.parent;

        const data = {
            parent: l,
            template: this.state.template,
            page_type: "General",
            params_qty: 0,
            name: this.state.name,
            has_params: this.state.hasParams,
            page_languages: names,
            //   page_styles: page_styles,
            widgets: widgets
        };

        objPage
            .pageAddNew(data)
            .then((res) => {
                swal(
                    "Success !",
                    t(this.state.transAlert + "page_add_sucess"),
                    "success"
                );
                this.props.onHide();
                this.props.getSiteStructure();
                setTimeout(() => {
                    window.location.reload();
                }, 0);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }

    updatePage() {
        const { t } = this.props;
        const objPage = new PageApi();

        const pageId = this.state.pageId;

        const names = this.state.names.map((item) => {
            return {
                language: item.language,
                name: item.name,
                title: item.title,
                description: item.description,
            };
        });

        const page_styles = this.state.pageCatalogSetting.map((item) => {
            var options = []; // change this logic

            return {
                style_id: item.id,
                options: options,
            };
        });

        let widgets = []
        if (this.state.selectedWidget) {
            this.state.selectedWidget.forEach(item => {
                widgets.push(item.id)
            })
        }

        const data = {
            name: this.state.name,
            title: this.state.title,
            parent: this.state.parent,
            template: this.state.template,
            has_params: this.state.hasParams,
            is_draft: this.state.isPublished,
            page_languages: names,
            page_styles: page_styles,
            widgets: widgets
        };

        objPage
            .pageUpdate(pageId, data)
            .then((res) => {
                swal(
                    "Success !",
                    t(this.state.transAlert + "page_update_success"),
                    "success"
                );

                this.props.onHide();
                this.props.getSiteStructure();
                setTimeout(() => {
                    window.location.reload();
                }, 0);
            })
            .catch((err) => {
                const objAlert = new AlertHelper();
                const resErr = err?.response?.data
                    ? err?.response?.data
                    : err.message;
                objAlert.errorHandlerAlert(resErr);
            });
    }

    render() {
        const { t } = this.props;

        var HeaderText, BtnSubmit;

        const TabLanguage = this.state.names.map((item, index) => {
            return (
                <Tab
                    key={item?.language}
                    eventKey={item?.language}
                    title={item?.languageName}
                >
                    <br></br>

                    <div className="form-group">
                        <label> {t("page_title")} </label>
                        <input
                            type="text"
                            className="form-control"
                            onChange={(event) =>
                                this.handleNameChange(index, event.target.value)
                            }
                            value={this.state.names[index]?.name}
                        ></input>
                    </div>

                    <div className="form-group">
                        <label> {t("url_text")} </label>
                        <textarea
                            className="form-control"
                            onChange={(event) =>
                                this.handleTitleChange(
                                    index,
                                    event.target.value
                                )
                            }
                            value={this.state.names[index]?.title}
                        ></textarea>
                    </div>

                    <div className="form-group">
                        <label> {t("description")} </label>
                        <textarea
                            className="form-control"
                            onChange={(event) =>
                                this.handleDescChange(index, event.target.value)
                            }
                            value={this.state.names[index]?.description}
                        ></textarea>
                    </div>
                </Tab>
            );
        });

        if (this.state.pageId) {
            HeaderText = t(this.state.transPage + "title_update");
            BtnSubmit = (
                <Button variant="primary" onClick={() => this.updatePage()}>
                    <i className="fa fa-edit">&nbsp;</i>
                    {t(this.state.transPage + "button_update")}
                </Button>
            );
        } else {
            HeaderText = t(this.state.transPage + "title_add");
            BtnSubmit = (
                <Button variant="primary" onClick={() => this.addPage()}>
                    <i className="fa fa-plus">&nbsp;</i>
                    {t(this.state.transPage + "button_add")}
                </Button>
            );
        }

        return (
            <Modal
                show={this.props.show}
                onHide={() => this.onHideModal()}
                onShow={() => this.onShowModal()}
            >
                <Modal.Header closeButton className="bg-primary">
                    <Modal.Title> {HeaderText} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* { JSON.stringify(this.state.pageCatalogSetting) }
                    <hr/> */}
                    {/* { JSON.stringify(this.state.names) } */}
                    <Tabs
                        id="controlled-tab-example-3"
                        activeKey={this.state.tabKey}
                        onSelect={(k) => this.handleChangeTab(k)}
                    >
                        {TabLanguage}
                    </Tabs>

                    <hr></hr>

                    <div className="form-group collection-list">
                        <label>{t('collections')}</label>
                        {
                            this.state.widgetList ?
                                <Select
                                    multi
                                    disabled={false}
                                    searchable={true}
                                    searchBy="name"
                                    className='basic-multi-select'
                                    options={this.state.widgetList}
                                    values={[...this.state.selectedWidget]}
                                    style={{ width: '100%' }}
                                    valueField="id"
                                    labelField="name"
                                    onDropdownClose={(value) => {
                                    }}
                                    onChange={(selectedWidget) => {
                                        this.setState({
                                            selectedWidget
                                        });
                                    }}

                                /> : <></>
                        }

                    </div>

                    <h5> {t(this.state.transPage + "page_settings")} </h5>

                    {/* <div className="form-group">
                        <input type="checkbox" id="has-params" 
                            className="" 
                            onChange={() => this.handleHasParams()} 
                            value="hasParams"  
                            checked={this.state.hasParams === true}
                        /> 
                        &nbsp; &nbsp;
                        <label htmlFor="has-params"> Has params </label> 
                        

                    </div>  */}

                    <div class="form-group">
                        <label> {t("publicity")} </label>
                        <br />
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id={t("draft")}
                                value={this.state.isPublished}
                                onChange={(e) =>
                                    this.handleIsPublished(e.target.checked)
                                }
                                checked={this.state.isPublished}
                            />
                            <label class="form-check-label" for={t("draft")}>
                                {t("draft")}
                            </label>
                        </div>
                        {/* <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                id={t("publish")}
                                value={this.state.isPublished}
                                onChange={() => this.handleIsPublished()}
                                // onChange={(event) =>
                                //   this.handleChangeIsPublish(event.target.checked)
                                // }
                                checked={
                                    this.state.isPublished === false
                                        ? true
                                        : false
                                }
                            />
                            <label class="form-check-label" for={t("publish")}>
                                {t("publish")}
                            </label>
                        </div> */}
                    </div>

                    {/* <div className="form-group">
                        <input type="checkbox" id="is-pub" 
                            className="" 
                            onChange={() => this.handleIsPublished()} 
                            value="is-pub"  
                            checked={this.state.isPublished === true}
                        />
                        &nbsp; &nbsp;
                        <label htmlFor="is-pub"> {t(this.state.transPage + "published_draft")}</label> 
                    </div>  */}

                    {/* <hr></hr>
                    <h5> Page Style </h5>
                    { this.renderSetting() } */}
                </Modal.Body>
                <Modal.Footer>{BtnSubmit}</Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(PageAddModal);
