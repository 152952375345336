import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"
import swal from 'sweetalert'
import AlertHelper from "./../../lib/AlertHelper"
import Pagination from "react-js-pagination";

// import ListCard from "./../../components/ListCard"
import ListCardWidget from "./../../components/ListCard/ListCardWidget"
import WidgetAddNew from "./../Modal/WidgetAddNew"

import WidgetApi from "./../../api/WidgetApi"
import SectionApi from "./../../api/SectionApi"

class SectionDetailWidgetPair extends Component {
    constructor(props){
        super(props)

        this.state = {
            id:this.props.id,
            widgets:[],
            // widget_id:0,
            isWidgetInternal:false,
            name:"",
            selectedWidget:{},

            activePage: 1,
            limitPageData: 20,
            totalCount:0,

            showWidgetAdd:false,
            search:""
        }

        this.onOpenModal = this.onOpenModal.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)

        this.handleNameChange = this.handleNameChange.bind(this)
        this.getAllWidgets = this.getAllWidgets.bind(this)

        this.sectionWidgetPair = this.sectionWidgetPair.bind(this)
        this.updateSectionWidgetPair = this.updateSectionWidgetPair.bind(this)

        this.setShowSelectedWidget = this.setShowSelectedWidget.bind(this)
        this.setSelectedWidget = this.setSelectedWidget.bind(this)
        this.setShowWidgetAdd = this.setShowWidgetAdd.bind(this)
        this.getSectionDetailWidget = this.getSectionDetailWidget.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
       
    }

    getSectionDetailWidget() {
        const objSection = new SectionApi()
        
        const sectionWidgetId = this.state.id;

        objSection.sectionDetailWidget(sectionWidgetId)
        .then(res => {

            const widget = {
                id:res.data.widget,
                name:res.data.widget_name,
                category:res.data.category,
                category_name:res.data.category,
                
            }

            this.setState({
                name:res.data.name, 
                isWidgetInternal:res.data.is_internal,
                selectedWidget:widget
            },() => {
                this.getAllWidgets()
            })
        })
    }

    getAllWidgets(){

        this.setState({
            widgets:[]
        })

        const objWidget = new WidgetApi()
        // alert(this.state.id)
        var isWidgetInternal = this.state.isWidgetInternal 
        var widget_id = undefined 
        if(isWidgetInternal === true) {
            widget_id = this.state.selectedWidget.id; // ganti 
            
        }


        objWidget.getAllWidgets({
            page_size:this.state.limitPageData, 
            page:this.state.activePage,
            internal:isWidgetInternal,
            widget_id:widget_id,
            search:this.state.search
        })
        .then(res => {
            this.setState({
                totalCount:res.data.count,
                widgets:res.data.results
            })
        })
        .catch(err => {
            const objAlert = new AlertHelper()
            const resErr = err?.response?.data ? err?.response?.data : err.message
            objAlert.errorHandlerAlert(resErr)
        })
    }

    setShowWidgetAdd() {
        this.setState({
            showWidgetAdd:!this.state.showWidgetAdd
        })
    }

    sectionWidgetPair() {
        const objSection = new SectionApi()
        const sectionId = this.props.section.id 

        const widget = this.state.selectedWidget.id
        const name = this.state.name

        objSection.sectionDetailWidgetPair(sectionId,name,widget)
        .then(res => {
            this.props.getSectionDetail(sectionId)
            
            swal("Success !", "You successfully pair widget on this section ", "success")
            this.props.onHide()
        })
        .catch(err => {
            const objAlert = new AlertHelper()
            const resErr = err?.response?.data ? err?.response?.data : err.message
            objAlert.errorHandlerAlert(resErr)
        })
    }

    updateSectionWidgetPair() {
        const objSection = new SectionApi()
        const sectionId = this.props.section.id 

        const sectionDetailId = this.state.id
        const widget = this.state.selectedWidget.id
        const name = this.state.name

        

        objSection.updateSectionDetailWidgetPair(sectionDetailId,sectionId,name,widget)
        .then(res => {

            this.props.getSectionDetail(sectionId)
            window.location.reload()
            
            swal("Success !", "You successfully updated Section Widget pair ", "success")
            this.props.onHide()
        })
        .catch(err => {
            const objAlert = new AlertHelper()
            const resErr = err?.response?.data ? err?.response?.data : err.message
            objAlert.errorHandlerAlert(resErr)
        })
    }

    setSelectedWidget(value) {

        this.setState({
            selectedWidget:value
        },() => {
            
        })
    }

    setShowSelectedWidget(value) {
        this.setState({
            selectedWidget:value
        },() => {
            this.setShowWidgetAdd()
        })
    }

    onOpenModal(){
        
        this.setState({
            id:this.props.id
        },() => {

            if(this.state.id){
                this.getSectionDetailWidget();
            } else {
                this.getAllWidgets()
            }
        })         
        
    }

    onCloseModal() {
        this.setState({
            id:0,
            widgets:[],

            name:"",
            selectedWidget:{},

        },() => {
            this.props.onHide()
        })
    }

    handlePageChange(pageNumber){
        this.setState({
            activePage: pageNumber,
           
        }, () => {
            this.getAllWidgets() 
        });
    }

    handleNameChange(value) {
        this.setState({
            name:value
        })
    }

    handleSearchChange(value) {
        this.setState({
            activePage:1,
            search:value
        },() => {
            this.getAllWidgets()
        })
    }

    render() {

        const WidgetList = this.state.widgets.map(item => {
            var widgetFormatData = []
            if(item.category_name === "Article") {
                widgetFormatData.push(item.category_name)
            }
            if(item.category_name === "Media") {
                widgetFormatData.push(item.category_name)
            }
            if(item.category_name === "Text"){
                widgetFormatData.push(item.category_name)
            }
            

            return (
                <div className="col-md-3" key={item.id}>
                    <ListCardWidget 
                    
                        data={item}
                        selectedWidget={this.state.selectedWidget}
                        setSelectedItem={this.setSelectedWidget}
                        setShowSelectedWidget={this.setShowSelectedWidget}
                    ></ListCardWidget>
                </div>
                
            )
        }) 
        // <div className="col-md-12 center-block"><h4 className="text-center" >There is no widgets</h4></div>

        var ButtonSubmit =  <Button variant="primary" onClick={() => this.sectionWidgetPair()}>
            <i className="fa fa-check">&nbsp;</i>
                Pair
        </Button>

        if(this.state.id) {
            ButtonSubmit = <Button variant="primary" onClick={() => this.updateSectionWidgetPair()}>
                <i className="fa fa-check">&nbsp;</i>
                    Update Pair
            </Button>
        }

        return (
            <Modal show={this.props.show} onHide={() => this.onCloseModal() } onShow={() => this.onOpenModal()} size="xl">
                <Modal.Header closeButton className="bg-primary">
                    <Modal.Title> Section Widget Pair </Modal.Title>
                   
                </Modal.Header>
                <Modal.Body>
                   
                    <div className="form-group">
                        <label> Name </label>
                        <input type="text" className="form-control" value={this.state.name} onChange={(event) => this.handleNameChange(event.target.value)} ></input>
                    </div>
                    <div style={{float:"left"}}>
                        <div className="form-group">
                            
                            <input type="text" placeholder="search..." className="form-control" name="search" value={this.state.search} onChange={(event) => this.handleSearchChange(event.target.value)} />
                        </div>
                    </div>
                    <div style={{ float: "right" }}>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.limitPageData}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={4}
                            onChange={this.handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                            
                        />
                    </div>
                    <div className="clearfix"></div>
                    <div className="row">
                        { WidgetList} 
                    </div>
                    <div style={{ float: "right" }}>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.limitPageData}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={4}
                            onChange={this.handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                            
                        />
                    </div>
                    <div className="clearfix"></div>
                   
                  
                </Modal.Body>
                <Modal.Footer>
                  
                      { ButtonSubmit }
                </Modal.Footer>
                <WidgetAddNew
                    widgetId={this.state.selectedWidget.id}
                    section={this.props.section}
                    getSectionDetail={this.props.getSectionDetail}
                    show={this.state.showWidgetAdd}
                    onHide={this.setShowWidgetAdd}
                    selectedWidget={this.state.selectedWidget}

                    sectionWidgetPair={true}
                ></WidgetAddNew>
            </Modal>
        );
    }
}

export default SectionDetailWidgetPair;