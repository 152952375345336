import React, { Component } from 'react';
import { Collapse, Tabs, Tab, Button } from 'react-bootstrap';
import Card, { CardBody } from '../../components/Card';
import swal from 'sweetalert';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import WidgetApi from './../../api/WidgetApi';
import AssetApi from './../../api/AssetApi';

import WidgetAssetAddModal from './../../components/Modal/WidgetAssetAddModal';
import WidgetAssetAddThumbnailModal from './../../components/Modal/WidgetAssetAddThumbnailModal';
import WidgetAssetUpdateWidgetModal from './../../components/Modal/WidgetAssetAddThumbnailModal';
import WidgetAssetDeleteConfirm from './../../components/Modal/WidgetAssetDeleteConfirm';
import Panorama from './../../components/Panorama/Panorama';
import AssetOrderModal from '../../components/Modal/AssetOrderModal';

import SectionFormHeader from './SectionFormHeader';
import './AssetForm.css';
import './FormCard.css';
import defaultImg from '../Assets/default-image.svg';
import Context from '../Context';

class AssetForm extends Component {
	static contextType = Context;
	constructor(props) {
		super(props);

		this.state = {
			widgetAssets: [],
			openCollapse: false,
			selectedAssets: false,

			name: '',
			description: '',
			hasLanguage: false,
			hasSlogan: false,
			file: '',
			asset_link: '',
			asset_type: '',

			asset_types: [],
			assets_file: [],
			assets_link: [],
			hasDarkLogo: false,
			darkLight: false,

			inputType: 'Image', // url

			descriptions: [],
			languageList: [],
			tabKey: 1,
			selectedLanguage: 1,
			renderTabLanguage: '',

			showConfirmDelete: false,
			showAssetModal: false,
			widgetId: false,
			newWidgetAssetId: false,
			fileNewThumbnail: false,
			setShowAssetThumbnailModal: false,
			thumbnailUrl: false,
			thumbnailUrl1: false,
			thumbnailUrl2: false,
			thumbnailID: false,
			setShowAssetUpdateWidget: false,
			activeLang: 'en',

			showOrderAssets: false,

			src: null,
			srcEn: null,
			srcVn: null,
			crop: {
				unit: '%',
				x: (100 - 40) / 2,
				y: 0,
				width: 40,
				height: 100,
				aspect: 9 / 16,
			},
			assets_crop: [],
			mobile_variable: {
				mobile_aspect: 0,
				mobile_unit: 0,
				mobile_height: 0,
				mobile_width: 0,
				mobile_x: 0,
				mobile_y: 0,
			},
		};

		this.setOpenCollapse = this.setOpenCollapse.bind(this);
		this.setShowConfirmDelete = this.setShowConfirmDelete.bind(this);
		this.setShowAssetModal = this.setShowAssetModal.bind(this);
		this.setShowAssetThumbnailModal = this.setShowAssetThumbnailModal.bind(this);
		this.setShowAssetUpdateWidget = this.setShowAssetUpdateWidget.bind(this);
		this.setShowOrderAssets = this.setShowOrderAssets.bind(this);
		this.getDetailWidget = this.getDetailWidget.bind(this);

		this.handleChangeName = this.handleChangeName.bind(this);
		this.handleChangeDescription = this.handleChangeDescription.bind(this);
		this.handleChangeFile = this.handleChangeFile.bind(this);
		this.handleChangeTab = this.handleChangeTab.bind(this);
		this.handleChangeLink = this.handleChangeLink.bind(this);
		this.handleChangeFileType = this.handleChangeFileType.bind(this);
		this.handleHasLanguage = this.handleHasLanguage.bind(this);
		this.handleHasSlogan = this.handleHasSlogan.bind(this);
		this.handleUnmatchWidgetPair = this.handleUnmatchWidgetPair.bind(this);
		this.handleDarkLogo = this.handleDarkLogo.bind(this);
		this.handleDarkLight = this.handleDarkLight.bind(this);
		this.handleSelectedAssetThumbnail = this.handleSelectedAssetThumbnail.bind(this);
		this.handleSelectedAssetUpdateWidget = this.handleSelectedAssetUpdateWidget.bind(this);
		this.updatePairAsset = this.updatePairAsset.bind(this);

		this.uploadNewAsset = this.uploadNewAsset.bind(this);
		this.updateAsset = this.updateAsset.bind(this);
		this.getAssetTypeLanguage = this.getAssetTypeLanguage.bind(this);
		this.renderTabLanguage = this.renderTabLanguage.bind(this);

		this.pairToWidget = this.pairToWidget.bind(this);

		this.onCropChange = this.onCropChange.bind(this);
		this.makeClientCrop = this.makeClientCrop.bind(this);
	}

	componentDidMount() {
		const { activeContentLang, activeSiteLang } = this.context;

		let defaultLang = this.props.data.widget?.is_internal ? activeSiteLang : activeContentLang;

		this.getAssetTypeLanguage();
		this.getDetailWidget();

		const descriptions = defaultLang.map((item) => {
			return {
				language: item.id,
				languageName: item.name,
				description: '',
			};
		});

		this.setState({
			languageList: defaultLang,
			descriptions: descriptions,

			selectedAssets: false,
			name: '',
			hasLanguage: false,
			inputType: '',
			activeLang: localStorage.getItem('lang'),
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const { activeContentLang, activeSiteLang } = this.context;

		if (this.props.data.widget?.is_internal) {
			if (activeSiteLang.length > 0) {
				if (this.state.languageList !== activeSiteLang) {
					this.setState({
						languageList: activeSiteLang,
					});
				}
			}
		} else {
			if (activeContentLang.length > 0) {
				if (this.state.languageList !== activeContentLang) {
					this.setState({
						languageList: activeContentLang,
					});
				}
			}
		}
	}

	setOpenCollapse() {
		this.setState({
			openCollapse: !this.state.openCollapse,
		});
	}

	setSelectedAsset(asset) {
		this.setState({
			selectedAssets: asset,
		});
	}

	setShowConfirmDelete() {
		this.setState({
			showConfirmDelete: !this.state.showConfirmDelete,
		});
	}

	setShowAssetModal() {
		this.setState({
			showAssetModal: !this.state.showAssetModal,
		});
	}

	setShowAssetThumbnailModal(value) {
		this.setState({
			thumbnailType: value,
			setShowAssetThumbnailModal: !this.state.setShowAssetThumbnailModal,
		});
	}

	setShowAssetUpdateWidget() {
		this.setState({
			setShowAssetUpdateWidget: !this.state.setShowAssetUpdateWidget,
		});
	}

	setShowOrderAssets = () => {
		this.setState({
			showOrderAssets: !this.state.showOrderAssets,
		});
	};

	handleChangeName(value) {
		this.setState({
			name: value,
		});
	}

	handleChangeDescription(id, value) {
		let descriptions = [...this.state.descriptions];
		let description = { ...descriptions[id] };
		description.description = value;
		descriptions[id] = description;

		this.setState({
			description: descriptions[0].description,
			descriptions: descriptions,
		});
	}

	handleChangeLink(value) {
		var newValue = '';
		if (value.substring(8, 23) === 'www.youtube.com') {
			newValue = value.replace('/watch?v=', '/embed/');
		} else {
			newValue = value;
		}

		this.setState({
			asset_link: newValue,
		});
	}

	handleChangeLinkLanguage(value, index) {
		const { assets_link } = this.state;

		if (value.substring(8, 23) === 'www.youtube.com') {
			assets_link[index] = value.replace('/watch?v=', '/embed/');
		} else {
			assets_link[index] = value;
		}

		this.setState({
			asset_link: assets_link,
		});
	}

	onImageLoaded = (image) => {
		this.imageRef = image;
	};

	onImageLoaded1 = (image) => {
		this.imageRef1 = image;
	};

	onCropComplete = (crop, param, index) => {
		this.makeClientCrop(crop, param, index);
	};

	onCropChange = (crop, param, index) => {
		// You could also use percentCrop:
		// this.setState({ crop: percentCrop });
		// this.setState({ crop });

		if (param === 'nolang') {
			this.setState({ crop });
		} else {
			let assets_crop = [...this.state.assets_crop];
			assets_crop[index] = crop;
			this.setState({ assets_crop });
		}
	};

	async makeClientCrop(crop, param, index) {
		if (this.imageRef && crop.width && crop.height) {
			if (param === 'nolang') {
				const croppedImage = await this.getCroppedImg(this.imageRef, crop, this.state.file.name, param, index);
				const croppedImageUrl = this.blobToFile(croppedImage, `mobile-${this.state.file.name}`);
				this.setState({ croppedImageUrl });
			} else {
				if (index === 0) {
					const croppedImage = await this.getCroppedImg(this.imageRef, crop, this.state.assets_file[index].name, param, index);
					const croppedImageUrlEn = this.blobToFile(croppedImage, `mobile-${this.state.assets_file[index].name}`);
					this.setState({ croppedImageUrlEn });
				} else {
					const croppedImage = await this.getCroppedImg(this.imageRef1, crop, this.state.assets_file[index].name, param, index);
					const croppedImageUrlVi = this.blobToFile(croppedImage, `mobile-${this.state.assets_file[index].name}`);
					this.setState({ croppedImageUrlVi });
				}
			}
		}
	}

	getCroppedImg(image, crop, fileName, param, index) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

		return new Promise((resolve, reject) => {
			canvas.toBlob((blob) => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(this.fileUrl);
				this.fileUrl = window.URL.createObjectURL(blob);
				// resolve(this.fileUrl); use this if you want to display result of cropped
				resolve(blob); // this return only blob file which will be saved to db
			}, 'image/jpeg');
		});
	}

	blobToFile(theBlob, fileName) {
		theBlob.lastModifiedDate = new Date();
		theBlob.name = fileName;

		const file = new File([theBlob], fileName);
		// return theBlob;
		return file;
	}

	convertSelectedCrop(crop, index) {
		let limiter;
		let edge = parseInt(this.state.assets_file_size[index]);
		if (edge < 1000) {
			limiter = 80;
		} else if (edge > 1000 && edge < 2000) {
			limiter = 70;
		} else if (edge > 2000) {
			limiter = 50;
		} else {
			limiter = 100;
		}

		let newCrop = {
			mobile_aspect: crop.aspect,
			mobile_height: Math.ceil((crop.height * limiter) / 100),
			mobile_unit: crop.unit,
			mobile_width: Math.ceil((crop.width * limiter) / 100),
			mobile_x: Math.ceil((crop.x * (limiter + 10)) / 100),
			mobile_y: Math.ceil((crop.y * limiter) / 100),
		};

		return newCrop;
	}

	handleChangeFile(event) {
		this.setState({
			file: event.target.files[0],
		});

		const reader = new FileReader();
		reader.addEventListener('load', () => this.setState({ src: reader.result }));
		reader.readAsDataURL(event.target.files[0]);
	}

	handleChangeNewThumbnail(event) {
		this.setState({
			fileNewThumbnail: event.target.files[0],
			thumbnailUrl: URL.createObjectURL(event.target.files[0]),
			thumbnailID: false,
		});
	}

	handleChangeFileLang(event, index) {
		let assets_file = [...this.state.assets_file];
		assets_file[index] = event.target.files[0];

		this.setState({
			assets_file: assets_file,
		});

		const reader = new FileReader();
		reader.addEventListener('load', () =>
			parseInt(index) === 0 ? this.setState({ srcEn: reader.result }) : this.setState({ srcVn: reader.result })
		);
		reader.readAsDataURL(event.target.files[0]);
	}

	handleChangeFileType(value) {
		// this.setState({
		//     inputType:value
		// })

		var inputType = 'FILE';
		if (parseInt(value) === 3) {
			inputType = 'URL';
		}

		this.setState({
			// asset_type:value,
			asset_type: value,
			inputType: inputType,
		});
	}

	handleDarkLogo() {
		this.setState({
			hasDarkLogo: !this.state.hasDarkLogo,
		});
	}

	handleDarkLight(value) {
		this.setState({
			darkLight: value,
		});
	}

	handleHasLanguage() {
		this.setState({
			hasLanguage: !this.state.hasLanguage,
		});
	}

	handleHasSlogan() {
		this.setState({
			hasSlogan: !this.state.hasSlogan,
		});
	}

	getAssetTypeLanguage() {
		const obj = new AssetApi();
		const lang = localStorage.getItem('langId');

		obj
			.assetTypeLanguage({
				language: lang ? lang : 1,
			})
			.then((res) => {
				this.setState({
					asset_types: res.data.results,
				});
			});
	}

	uploadNewAsset() {
		const assetObj = new AssetApi();
		const contextValue = this.context;

		const asset_mobiles = [this.state.croppedImageUrlEn, this.state.croppedImageUrlVi];
		const asset_languages = this.state.descriptions.map((item, index) => {
			return {
				language: item.language,
				description: item.description,
				asset_file: this.state.assets_file[index],
				asset_link: this.state.assets_link[index],
				asset_mobile: asset_mobiles[index],

				// mobile_variable: this.state.assets_crop[index] ? this.convertSelectedCrop(this.state.assets_crop[index], index) : this.state.mobile_variable
				mobile_variable: this.state.assets_crop[index]
					? {
							mobile_aspect: this.state.assets_crop[index]?.aspect,
							mobile_unit: this.state.assets_crop[index]?.unit,
							mobile_height: Math.ceil(this.state.assets_crop[index]?.height),
							mobile_width: Math.ceil(this.state.assets_crop[index]?.width),
							mobile_x: Math.ceil(this.state.assets_crop[index]?.x),
							mobile_y: Math.ceil(this.state.assets_crop[index]?.y),
					  }
					: this.state.mobile_variable,
			};
		});

		const newAsset = {
			client: contextValue.activeClient,
			name: this.state.name,
			description: this.state.description,
			has_languages: this.state.hasLanguage,
			has_slogan: this.state.hasSlogan,
			thumbnail: parseInt(this.state.asset_type) !== 1 ? (this.state.thumbnailID ? this.state.thumbnailID : null) : null,
			file_type: this.state.inputType,
			asset_type: this.state.asset_type,
			set_logo_opacity: this.state.hasDarkLogo,
			logo_opacity: this.state.darkLight,
			asset_languages: asset_languages,

			mobile_variable: this.state.crop
				? {
						mobile_aspect: this.state.crop.aspect,
						mobile_unit: this.state.crop.unit,
						mobile_height: Math.ceil(this.state.crop.height),
						mobile_width: Math.ceil(this.state.crop.width),
						mobile_x: Math.ceil(this.state.crop.x),
						mobile_y: Math.ceil(this.state.crop.y),
				  }
				: this.state.mobile_variable,
		};

		if (!this.state.hasLanguage) {
			newAsset['asset_file'] = this.state.file || '';
			newAsset['asset_mobile'] = this.state.croppedImageUrl;
			newAsset['asset_link'] = this.state.asset_link;
		}

		if (this.state.fileNewThumbnail) {
			const newThumbnail = {
				name: this.state.name,
				description: '',
				has_languages: '',
				has_slogan: false,
				thumbnail: null,
				file_type: 'FILE',
				asset_type: 1,
				set_logo_opacity: false,
				logo_opacity: false,
				asset_mobile: null,
				asset_file: this.state.fileNewThumbnail,
				asset_languages: asset_languages,
			};

			assetObj
				.assetAdd(newThumbnail)
				.then((res) => {
					let newArray = { ...newAsset, thumbnail: res.data.id };
					this.addNewAsset(newArray);
				})
				.catch((err) => {
					console.log('err => ', err);
				});
		} else {
			this.addNewAsset(newAsset);
		}
	}

	addNewAsset(newAsset) {
		const assetObj = new AssetApi();
		assetObj
			.assetAdd(newAsset)
			.then((res) => {
				this.pairToWidget(res.data.id);
			})
			.catch((err) => {
				console.log('err => ', err);
			});
	}

	updateAsset() {
		const assetObj = new AssetApi();
		const contextValue = this.context;

		const asset_languages = this.state.descriptions.map((item, index) => {
			return {
				language: item.language,
				description: item.description,
				asset_file: this.state.assets_file[index],
				asset_link: this.state.assets_link[index],
			};
		});

		const newAsset = {
			client: contextValue.activeClient,
			name: this.state.name,
			description: this.state.description,
			has_languages: this.state.hasLanguage,
			has_slogan: this.state.hasSlogan,
			thumbnail: parseInt(this.state.asset_type) !== 1 ? (this.state.thumbnailID ? this.state.thumbnailID : null) : null,
			file_type: this.state.inputType,
			asset_type: this.state.asset_type,
			set_logo_opacity: this.state.hasDarkLogo,
			logo_opacity: this.state.darkLight,
			asset_languages: asset_languages,
		};

		if (!this.state.hasLanguage) {
			newAsset['asset_file'] = this.state.file || '';
			newAsset['asset_link'] = this.state.asset_link;
		}

		if (this.state.fileNewThumbnail) {
			const newThumbnail = {
				name: this.state.name,
				description: '',
				has_languages: '',
				has_slogan: false,
				thumbnail: null,
				file_type: 'FILE',
				asset_type: 1,
				set_logo_opacity: false,
				logo_opacity: false,
				asset_mobile: null,
				asset_file: this.state.fileNewThumbnail,
				asset_languages: asset_languages,
			};

			assetObj
				.addNewAsset(newThumbnail)
				.then((res) => {
					let newArray = { ...newThumbnail, thumbnail: res.data.id };
					this.updateExistingAsset(newArray);
				})
				.catch((err) => {
					console.log('err => ', err);
				});
		} else {
			this.updateExistingAsset(newAsset);
		}
	}

	updateExistingAsset(newAsset) {
		const assetObj = new AssetApi();

		assetObj
			.assetUpdate(this.state.selectedAssets.id, newAsset)
			.then((res) => {
				this.getDetailWidget();
				swal('Success !', 'You sucessfully Updated this asset !', 'success');
			})
			.catch((err) => {
				console.log('err => ', err);
			});
	}

	pairToWidget(asset) {
		const objWidget = new WidgetApi();

		const widgetId = this.props.data.widget.id;
		const assets = [asset];

		objWidget
			.widgetAssetPair(widgetId, assets)
			.then((res) => {
				//this.props.setSelectedAssetAdd(this.state.selectedAssets)
				this.getDetailWidget();

				swal('Success !', 'You sucessfully added asset into this widget !', 'success');
			})
			.catch((err) => {
				console.log(err.message);
			});
	}

	renderTabLanguage() {
		const renderTabLanguage = this.state.languageList.map((item) => {
			return {
				language: item.id,
				languageName: item.name,
				description: '',
			};
		});

		this.setState({
			descriptions: renderTabLanguage,
		});
	}

	handleChangeTab(value) {
		this.setState(
			{
				selectedLanguage: value,
				tabKey: value,
			},
			() => {
				this.renderTabLanguage();
			}
		);
	}

	getDetailWidget() {
		const objWidget = new WidgetApi();
		const widgetId = this.props.data.widget.id;
		const langID = localStorage.getItem('langId');

		const params = {
			language: langID ? langID : 1,
			// section: this.props.data.id
		};

		objWidget
			.getDetailWidget(widgetId, params)
			.then((res) => {
				this.setState({
					widgetAssets: res.data.widget_assets,
				});
			})
			.catch((err) => {
				console.log('err ASSET FORM', err.message);
			});
	}

	handleSelectedAsset(assetId, widgetId) {
		const obj = new AssetApi();
		obj
			.getDetailAsset(assetId)
			.then((res) => {
				const descriptions = res.data.asset_languages?.map((item) => {
					const languageList = this.state.languageList.find((i) => i?.id === item.language);

					return {
						description: item.description,
						language: item.language,
						languageName: languageList?.name,
					};
				});

				this.setState(
					{
						selectedAssets: res.data,
						name: res.data.name,
						hasLanguage: res.data.has_languages,
						thumbnailUrl: res.data.thumbnail_file,
						inputType: res.data.inputType,
						asset_type: res.data.asset_type.id,
						descriptions: descriptions,
						thumbnailID: res.data.thumbnail,
						widgetId: widgetId,
						hasDarkLogo: res.data.set_logo_opacity,
						hasSlogan: res.data.has_slogan,
					},
					() => {}
				);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	handleUnmatchWidgetPair() {
		const widgetObj = new WidgetApi();

		const asset = this.state.selectedAssets?.id;

		const widgetId = this.props.data.widget.id;
		const assets = [asset];

		widgetObj
			.widgetAssetUnpair(widgetId, assets)
			.then((res) => {
				this.setState({}, () => {
					this.getDetailWidget();
					this.setShowConfirmDelete();
					swal('Success !', 'You successfully delete assets on this widget ', 'success');
					//window.location.reload()
				});
			})
			.catch((err) => {
				this.setShowConfirmDelete();
				swal('Error !', err.message, 'error');
			});
	}

	handleSelectedAssetThumbnail(thumbnailType, asset, id) {
		switch (thumbnailType) {
			case 'noLang':
				this.setState({
					thumbnailUrl: asset,
				});
				break;
			case 'enLang':
				this.setState({
					thumbnailUrl1: asset,
				});
				break;
			case 'vnLang':
				this.setState({
					thumbnailUrl2: asset,
				});
				break;
			default:
				break;
		}
		this.setState({
			thumbnailID: id,
			fileNewThumbnail: false,
		});
	}

	handleSelectedAssetUpdateWidget(thumbnailType, asset, id) {
		this.setState({
			newWidgetAssetId: id,
		});
	}

	updatePairAsset() {
		if (this.props.widget && this.state.widgetId && this.state.newWidgetAssetId && this.state.newWidgetAssetId > 0) {
			const assetObj = new AssetApi();
			assetObj
				.assetUpdatePairWidget(this.props.widget, this.state.widgetId, this.state.newWidgetAssetId)
				.then((res) => {
					this.getDetailWidget();
					this.setShowAssetUpdateWidget();
					swal('Success !', 'You sucessfully Updated this asset !', 'success');
				})
				.catch((err) => {
					console.log('err => ', err);
				});
		}
	}

	checkingExtension(file, thumbnail) {
		let htmlFile;
		let typeVid = ['webm', 'mkv', 'flv', 'gif', 'avi', 'mov', 'wmv', 'mp4', '3gp'];
		let typeImg = ['jpg', 'jpeg', 'png', 'svg', 'webp', 'bmp', 'tiff', 'exif'];
		let typeDoc = ['doc', 'docx', 'odt', 'pdf', 'xls', 'xlsx', 'ods', 'ppt', 'pptx', 'txt'];
		let extension = file.substring(file.lastIndexOf('.') + 1);
		if (thumbnail) {
			if (typeVid.includes(extension) || typeDoc.includes(extension)) {
				htmlFile = <img alt='media-list' width={'100%'} height={'100%'} className={'img-thumbnail'} src={thumbnail} />;
			} else if (typeImg.includes(extension)) {
				htmlFile = <img alt='media-list' width={'100%'} height={'100%'} className={'img-thumbnail'} src={file} />;
			} else {
				htmlFile = <img alt='media-list' width={'100%'} height={'100%'} className={'img-thumbnail'} src={thumbnail} />;
			}
		} else {
			htmlFile = <img alt='media-list' width={'100%'} height={'100%'} className={'img-thumbnail'} src={defaultImg} />;
		}

		return htmlFile;
	}

	checkingFileExtension(file) {
		let fileType;
		let typeVid = ['webm', 'mkv', 'flv', 'gif', 'avi', 'mov', 'wmv', 'mp4', '3gp'];
		let typeImg = ['jpg', 'jpeg', 'png', 'svg', 'webp', 'bmp', 'tiff', 'exif'];
		let typeDoc = ['doc', 'docx', 'odt', 'pdf', 'xls', 'xlsx', 'ods', 'ppt', 'pptx', 'txt'];
		let extension = file !== 'null' ? file.substring(file.lastIndexOf('.') + 1) : 'default';
		if (typeVid.includes(extension)) {
			fileType = 'vid';
		} else if (typeImg.includes(extension)) {
			fileType = 'img';
		} else if (typeDoc.includes(extension)) {
			fileType = 'doc';
		} else {
			fileType = 'img';
		}
		return fileType;
	}

	checkingPreviewFile(file, index, lang) {
		let media, asset_image_big;
		switch (file.asset_type?.id) {
			case 1:
				if (lang) {
					asset_image_big = file?.asset_languages
						? file?.asset_languages[index]?.asset_file
							? file?.asset_languages[index]?.asset_file
							: defaultImg
						: defaultImg;
				} else {
					asset_image_big = this.state.selectedAssets?.asset_file
						? this.state.selectedAssets?.asset_file
						: this.state.selectedAssets?.asset_languages
						? this.state.selectedAssets?.asset_languages[0].asset_file
						: this.state.selectedAssets?.asset_languages
						? this.state.selectedAssets?.asset_languages[1].asset_file
						: '';
				}
				media = (
					<img
						src={asset_image_big}
						alt='big-mage'
						height='100%'
						style={{ display: 'block', height: '100%', width: '100%', margin: 'auto', objectFit: 'contain' }}
					></img>
				);
				break;
			case 22:
				let extFile;
				if (lang) {
					extFile = file?.asset_languages ? this.checkingFileExtension(file?.asset_languages[index].asset_file) : 'null';
				} else {
					extFile = file?.asset_file ? this.checkingFileExtension(file?.asset_file) : 'null';
				}

				if (extFile === 'img') {
					media = (
						<img
							src={lang ? file?.asset_languages[index].asset_file : file.asset_file}
							alt='big-mage'
							height='100%'
							style={{ display: 'block', height: '100%', width: '100%', margin: 'auto', objectFit: 'cover' }}
						></img>
					);
				} else if (extFile === 'doc') {
					// enable if you want to display thumbnail if there
					// if (file.thumbnail_file) {
					//     asset_image_big = file?.thumbnail_file
					//     media = <img src={asset_image_big} alt="big-mage" height="100%" style={{ display: "block", height: "100%", width: "100%", margin: "auto", objectFit: "contain" }}></img>
					// } else {
					let files = lang ? file?.asset_languages[index].asset_file.split('/').pop() : file.asset_file.split('/').pop();
					let ext = files.split('.').pop();
					media = (
						<div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<span style={{ fontSize: '32px' }}>{ext}</span>
						</div>
					);
					// }
				} else if (extFile === 'vid') {
					media = (
						<video style={{ height: '100%', width: '100%' }} controls loop={false}>
							<source src={lang ? file?.asset_languages[index].asset_file : file.asset_file} type='video/mp4' />
						</video>
					);
				} else if (extFile === 'null') {
					media = (
						<img
							src={defaultImg}
							alt='big-mage'
							height='100%'
							style={{ display: 'block', height: '100%', width: '100%', margin: 'auto', objectFit: 'contain' }}
						></img>
					);
				}

				break;
			case 2:
				media = (
					<iframe
						src={lang ? file?.asset_languages[index].asset_link : file.asset_link}
						style={{ height: '100%', width: '100%' }}
						title={file.name}
						controls
						loop={false}
					></iframe>
				);
				break;
			case 3:
				if (lang) {
					asset_image_big = file?.asset_languages ? file?.asset_languages[index]?.asset_file : defaultImg;
				} else {
					asset_image_big = this.state.selectedAssets?.asset_file
						? this.state.selectedAssets?.asset_file
						: this.state.selectedAssets?.asset_languages
						? this.state.selectedAssets?.asset_languages[0].asset_file
						: this.state.selectedAssets?.asset_languages
						? this.state.selectedAssets?.asset_languages[1].asset_file
						: '';
				}

				media = <Panorama src={asset_image_big} pStyle='100%' />;
				break;
			case 4:
				media = (
					<video style={{ height: '100%', width: '100%' }} controls loop={false}>
						<source src={lang ? file?.asset_languages[index].asset_file : file.asset_file} type='video/mp4' />
					</video>
				);
				break;
			default:
				break;
		}
		return media;
	}

	removeThumbnail = () => {
		this.setState({
			thumbnailID: false,
			thumbnailUrl: false,
		});
	};

	confirmAssetOrder = (widgetId, dataOrder, prop) => {
		const objWidget = new WidgetApi();

		const arrId = [];
		if (dataOrder) {
			dataOrder.forEach((or) => {
				arrId.push(or.id);
			});
		}

		if (arrId.length > 0) {
			const data = {
				widget_assets: arrId,
			};

			objWidget
				.widgetAssetOrder(widgetId, data)
				.then((res) => {
					if (res.data) {
						const widgetId = prop.data.widget?.id;
						const params = {
							section: prop.data.id,
						};
						objWidget
							.getDetailWidget(widgetId, params)
							.then((res) => {
								this.setState({
									widgetAssets: res.data.widget_assets,
								});
							})
							.catch((err) => {
								console.log('err ASSET FORM', err.message);
							});
					}
				})
				.catch((err) => {
					console.log(err.message);
				});
		}
	};

	render() {
		const { crop, src, srcEn, srcVn, assets_crop } = this.state;

		const widgetAssets = this.state.widgetAssets.map((item, index) => {
			let assetPreview;
			if (item.asset.asset_type === 1) {
				// assetPreview = <img alt="media-list" width={"100%"} height={"100%"} style={{ objectFit: "cover" }} className={'img-thumbnail'} src={item.asset.asset_file || item.asset.asset_languages[0].asset_file || item.asset.asset_languages[0].asset_file} />
				assetPreview = (
					<img
						alt='media-list'
						width={'100%'}
						height={'100%'}
						style={{ objectFit: 'cover' }}
						className={'img-thumbnail'}
						src={item.asset.asset_file}
					/>
				);
			} else if (item.asset.asset_type === 5) {
				// assetPreview = this.checkingExtension((item.asset_file || item.asset_languages[0].asset_file || item.asset_languages[0].asset_file), (item.asset_thumbnail || item.asset_languages[0].thumbnail_file))
				let thumbFile;
				let thumb = item.asset.asset_thumbnail ? item.asset.asset_thumbnail : defaultImg;
				thumbFile = thumb;

				if (item.asset) {
					thumbFile = item.asset.thumbnail_file ? item.asset.thumbnail_file : thumb;
				} else {
					thumbFile = item?.asset?.asset_languages[index]
						? item?.asset?.asset_languages[index]?.thumbnail_file
							? item?.asset?.asset_languages[index]?.thumbnail_file
							: thumb
						: thumb;
				}

				assetPreview = this.checkingExtension(
					item.asset.asset_file || item.asset.asset_languages[0].asset_file || item.asset.asset_languages[0].asset_file,
					thumbFile
				);
			} else if (item.asset.asset_type === 2) {
				let thumb;
				if (item.asset_thumbnail) {
					thumb = item.asset.asset_thumbnail;
				} else {
					thumb = item.asset?.asset_languages ? item.asset?.asset_languages[0]?.thumbnail_file : defaultImg;
				}
				assetPreview = <img alt='media-list' width={'100%'} height={'100%'} src={thumb} />;
			} else if (item.asset.asset_type === 3) {
				assetPreview = (
					<img
						alt='media-list'
						width={'100%'}
						height={'100%'}
						style={{ objectFit: 'cover' }}
						className={'img-thumbnail'}
						src={item.asset.asset_file || item.asset.asset_languages[0].asset_file || item.asset.asset_languages[0].asset_file}
					/>
				);
			} else if (item.asset.asset_type === 4) {
				let thumbFile;
				let thumb = item.asset.thumbnail_file ? item.asset.thumbnail_file : defaultImg;
				thumbFile = thumb;

				assetPreview = this.checkingExtension(
					item.asset.asset_file || item.asset.asset_languages[0].asset_file || item.asset.asset_languages[0].asset_file,
					thumbFile
				);
			}
			return (
				<div className='box-asset' style={{ cursor: 'pointer' }} onClick={() => this.handleSelectedAsset(item.asset.id, item.id)}>
					{assetPreview}
				</div>
			);
		});

		var FileInputLanguage = '';
		var FileInput = '';

		let assetFile;
		const { selectedAssets } = this.state;
		if (selectedAssets) {
			let assLang;
			if (this.state.asset_type && parseInt(this.state.asset_type) !== 2) {
				assLang = (
					<>
						<label>Asset File</label>
						<div className='ass-sel-update'>
							<p>
								{!selectedAssets.hasLanguage
									? selectedAssets.asset_file
										? selectedAssets.asset_file.split('/').pop()
										: selectedAssets.asset_file
									: ''}
							</p>
							<div>
								<p
									className='m-auto'
									style={{ color: 'black' }}
									onClick={() => {
										this.setShowAssetUpdateWidget();
									}}
								>
									Update File
								</p>
							</div>
						</div>
					</>
				);
			} else {
				assLang = (
					<>
						<label>Asset Url</label>
						<input
							type='url'
							name='asset_url'
							defaultValue={selectedAssets?.asset_link}
							className='form-control'
							onChange={(event) => this.handleChangeLink(event.target.value)}
						></input>
					</>
				);
			}
			assetFile = <div className={''}>{assLang}</div>;
		} else {
			assetFile = (
				<>
					<label>Asset File</label>
					<input type='file' name='asset_file' className='form-control' onChange={(event) => this.handleChangeFile(event)}></input>
				</>
			);
		}

		var input = (
			<div className='form-group'>
				{assetFile}

				{!this.state.selectedAssets && parseInt(this.state.asset_type) === 1 && src && (
					<div className='mt-3'>
						<label style={{ position: 'relative', display: 'block' }}>Assets for mobile</label>
						<div className='c_crop--fixed'>
							<ReactCrop
								src={src}
								crop={crop}
								ruleOfThirds
								crossorigin={'true'}
								onImageLoaded={this.onImageLoaded}
								onComplete={(crop) => this.onCropComplete(crop, 'nolang', '')}
								onChange={(crop) => this.onCropChange(crop, 'nolang', '')}
							/>
						</div>
					</div>
				)}
			</div>
		);

		if (this.state.inputType === 'URL') {
			input = (
				<div className='form-group'>
					<label>Asset Url</label>
					<input
						type='url'
						name='asset_url'
						className='form-control'
						onChange={(event) => this.handleChangeLink(event.target.value)}
					></input>
				</div>
			);
		}

		let SetThumbnail, FileThumbnail;
		if (this.state.asset_type && parseInt(this.state.asset_type) !== 1) {
			SetThumbnail = (
				<div className='d-flex flex-column'>
					<label>Asset Thumbnail</label>
					{/* enable if you want to show thumbnail */}
					{/* {
                        this.state.thumbnailUrl ? <img src={this.state.thumbnailUrl} style={{ height: '300px', width: '50%', marginBottom: '1.5rem' }} alt="" /> : <></>
                    } */}
					<div className='d-flex'>
						<button
							className='btn btn-primary mb-3'
							onClick={() => {
								this.setShowAssetThumbnailModal('noLang');
							}}
						>
							<i className='fa fa-plus'></i> {this.state.thumbnailUrl ? 'Update' : 'Add'}
						</button>
						<div className='d-flex file-input-custom ml-3 mr-3'>
							<input
								type='file'
								name='asset_file'
								className='form-control custom-file-input'
								onChange={(event) => this.handleChangeNewThumbnail(event)}
							></input>
							<label for='asset_file'>
								<i className='fa fa-plus mr-1'></i>Add new
								<p class='file-name-custom'></p>
							</label>
						</div>
						{this.state.thumbnailUrl ? (
							<button
								className='btn btn-primary mb-3'
								onClick={() => {
									this.removeThumbnail();
								}}
							>
								<i className='fa fa-times'></i> Remove
							</button>
						) : (
							<></>
						)}
					</div>
				</div>
			);
		} else {
			<></>;
		}

		if (!this.state.hasLanguage) {
			FileInputLanguage = '';
			FileInput = input;
			FileThumbnail = SetThumbnail;
		} else {
			FileInput = '';
			FileThumbnail = SetThumbnail;
		}

		const TabLanguage = this.state.descriptions.map((item, index) => {
			// let FileThumbnails
			if (this.state.hasLanguage) {
				if (this.state.asset_type && parseInt(this.state.asset_type) !== 2) {
					FileInputLanguage = (
						<div className='form-group'>
							<label>Asset File</label>
							{this.state.selectedAssets.id ? (
								<div className='ass-sel-update'>
									<p>
										{selectedAssets.asset_languages[index]
											? selectedAssets.asset_languages[index].asset_file
												? selectedAssets.asset_languages[index].asset_file.split('/').pop()
												: ''
											: ''}
									</p>
									<div>
										<p
											className='m-auto'
											style={{ color: 'black' }}
											onClick={() => {
												this.setShowAssetUpdateWidget();
											}}
										>
											Update File
										</p>
									</div>
								</div>
							) : (
								<div>
									<input
										type='file'
										name='asset_file'
										className='form-control'
										onChange={(event) => this.handleChangeFileLang(event, index)}
									></input>
									{index === 0
										? parseInt(this.state.asset_type) === 1 &&
										  srcEn && (
												<div className='mt-3'>
													<label style={{ position: 'relative', display: 'block' }}>Assets for mobile</label>
													<div className='c_crop--fixed'>
														<ReactCrop
															src={srcEn}
															crop={assets_crop[0] ? assets_crop[0] : crop}
															ruleOfThirds
															onImageLoaded={this.onImageLoaded}
															onComplete={(crop) => this.onCropComplete(crop, 'lang', index)}
															onChange={(crop) => this.onCropChange(crop, 'lang', index)}
														/>
													</div>
												</div>
										  )
										: parseInt(this.state.asset_type) === 1 &&
										  srcVn && (
												<div className='mt-3'>
													<label style={{ position: 'relative', display: 'block' }}>Assets for mobile</label>
													<div className='c_crop--fixed'>
														<ReactCrop
															src={srcVn}
															crop={assets_crop[1] ? assets_crop[1] : crop}
															ruleOfThirds
															onImageLoaded={this.onImageLoaded1}
															onComplete={(crop) => this.onCropComplete(crop, 'lang', index)}
															onChange={(crop) => this.onCropChange(crop, 'lang', index)}
														/>
													</div>
												</div>
										  )}
								</div>
							)}
						</div>
					);
				} else {
					FileInputLanguage = (
						<input
							type='url'
							name='asset_url'
							defaultValue={selectedAssets?.asset_link}
							className='form-control'
							onChange={(event) => this.handleChangeLink(event.target.value)}
						></input>
					);
				}

				// FileInputLanguage = <div className="form-group">
				//     <label>Asset Filesss</label>
				//     <input type="file" name="asset_file" className="form-control" onChange={(event) => this.handleChangeFileLang(event, index)}></input>
				// </div>

				// ENABLE IF YOU WANT TO SUPPORT MULTIPLE THUMBNAIL AT SAMETIME
				// if (this.state.asset_type && parseInt(this.state.asset_type) !== 1) {
				//     FileThumbnails =
				//         <div className="d-flex flex-column">
				//             <label>Asset Thumbnail</label>
				//             {
				//                 parseInt(index) === 0 ?
				//                     (this.state.thumbnailUrl1 ? <img src={this.state.thumbnailUrl1} style={{ height: '300px', width: '50%', marginBottom: '1.5rem' }} alt="" /> : <></>)
				//                     :
				//                     (this.state.thumbnailUrl2 ? <img src={this.state.thumbnailUrl2} style={{ height: '300px', width: '50%', marginBottom: '1.5rem' }} alt="" /> : <></>)

				//             }
				//             <button className="btn btn-primary" onClick={() => { this.setShowAssetThumbnailModal(parseInt(index) === 0 ? 'enLang' : 'vnLang') }}>
				//                 <i className="fa fa-plus"></i> Add Thumbnail
				//             </button>
				//         </div>
				// } else {
				//     <></>
				// }

				// LINK IS NOT EXITS IN DATABASE
				if (this.state.inputType === 'URL') {
					FileInputLanguage = (
						<div className='form-group'>
							<label>Asset Url</label>
							<input
								type='url'
								name='asset_url'
								className='form-control'
								defaultValue={this.selectedAssets ? this.selectedAssets.asset_link : ''}
								onChange={(event) => this.handleChangeLinkLanguage(event.target.value, index)}
							></input>
						</div>
					);
				}
			}

			return (
				<Tab key={index} eventKey={item.language} title={item.languageName}>
					<br></br>

					{FileInputLanguage}
					{/* {FileThumbnails} */}
					<div className='form-group'>
						<label> Description </label>
						<textarea
							className='form-control'
							onChange={(event) => this.handleChangeDescription(index, event.target.value)}
							value={this.state.descriptions[index].description}
						></textarea>
						{/* { JSON.stringify( this.state.text_languages )  } */}
					</div>
				</Tab>
			);
		});

		var assetPreview;
		if (this.state.selectedAssets && this.state.selectedAssets.has_languages) {
			let assPreview = this.state.languageList.map((item, index) => {
				return (
					<Tab key={index} eventKey={item.abbreviation} title={item.name}>
						{this.checkingPreviewFile(this.state.selectedAssets, index, true)}
					</Tab>
				);
			});

			assetPreview = <Tabs>{assPreview}</Tabs>;
		} else {
			assetPreview = this.checkingPreviewFile(this.state.selectedAssets, '', false);
		}

		var ButtonAction = (
			<div className='col-md-5'>
				<Button className='float-right' onClick={() => this.uploadNewAsset()}>
					{' '}
					<i className='fa fa-plus'>&nbsp;</i>Upload New Asset{' '}
				</Button>
			</div>
		);

		var ButtonUnmatch = '';
		var ContentPreview = (
			<div className='content' style={{}}>
				<div> Add New Asset by filling the form </div>
				<div> OR </div>
				<button
					className='btn btn-primary'
					onClick={() => {
						this.setShowAssetModal();
					}}
				>
					{' '}
					<i className='fa fa-plus'></i> Add Existing Asset{' '}
				</button>
			</div>
		);

		if (this.state.selectedAssets?.id) {
			ButtonAction = (
				<div className='col-md-5'>
					<Button className='float-right' onClick={() => this.updateAsset()}>
						{' '}
						<i className='fa fa-edit'>&nbsp;</i>Update Asset{' '}
					</Button>
				</div>
			);
			ButtonUnmatch = (
				<button className='btn btn-xs btn-danger' title='unmatch the asset' onClick={() => this.setShowConfirmDelete()}>
					<i className='fa fa-trash'></i>
				</button>
			);
			ContentPreview = assetPreview;
		}

		var DarkLogoInput = '';

		if (this.state.hasDarkLogo) {
			DarkLogoInput = (
				<div>
					<input
						type='radio'
						value='dark'
						id='dark-radio'
						name='has-dark-logo'
						checked={this.state.darkLight === 'Dark'}
						onClick={() => this.handleDarkLight('Dark')}
					></input>
					&nbsp;
					<label for='dark-radio'> Dark </label>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<input
						type='radio'
						value='light'
						id='light-radio'
						name='has-dark-logo'
						checked={this.state.darkLight === 'Light'}
						onClick={() => this.handleDarkLight('Light')}
					></input>
					&nbsp;
					<label for='light-radio'> Light </label>
				</div>
			);
		}

		return (
			<Card>
				<SectionFormHeader
					widget={this.props.widget}
					section={this.props.section}
					widgetName={this.props.widgetName}
					widgetElementType={this.props.widgetElementType}
					widgetElementStatic={this.props.widgetElementStatic}
					setOpenCollapse={this.setOpenCollapse}
					openCollapse={this.state.openCollapse}
					icon={'fa-photo-video'}
				></SectionFormHeader>
				<Collapse in={this.state.openCollapse} style={{ padding: '1.25rem' }}>
					<CardBody id={`asset-form-${this.props.widgetName}`}>
						<div className='row'>
							<div className='col-md-6'>
								<div id='box-upload-assets' className='box-upload-image'>
									{ContentPreview}
									<div class='top-right'>{ButtonUnmatch}</div>
								</div>
								<div>
									<div className='d-flex'>
										<div
											title='Add New Widget'
											className='box-asset'
											style={{ cursor: 'pointer', position: 'relative' }}
											onClick={() => {
												this.handleChangeDescription(0, '');
												this.handleChangeDescription(1, '');
												this.setState(
													{
														selectedAssets: false,
														name: '',
														hasLanguage: false,
														inputType: '',
													},
													() => {}
												);
											}}
										>
											<div className='centered'>
												{' '}
												<i className='fa fa-plus-circle'></i>{' '}
											</div>
										</div>
										{this.state.widgetAssets.length > 1 && (
											<div className='btn-w-ass-or mt-auto' style={{ position: 'relative' }} onClick={this.setShowOrderAssets}>
												<span style={{ textAlign: 'center' }}>Order Assets</span>
											</div>
										)}
									</div>

									<div className='scrollmenu' style={{ float: 'left', width: '100%', height: '130px' }}>
										{widgetAssets}
									</div>
								</div>
							</div>
							<div className='col-md-6'>
								<div className='form-group '>
									<label>Asset Name</label>
									<input
										type='text'
										name='asset_name'
										className='form-control'
										value={this.state.name}
										onChange={(event) => this.handleChangeName(event.target.value)}
									></input>
								</div>
								<div className='form-group'>
									<input
										type='checkbox'
										id='check-has-language'
										checked={this.state.hasLanguage}
										onChange={() => this.handleHasLanguage()}
									></input>{' '}
									&nbsp;
									<label for='check-has-language'> Has Language </label>
								</div>
								<div className='form-group'>
									<input
										type='checkbox'
										value='dark-logo'
										id='check-dark-logo'
										checked={this.state.hasDarkLogo}
										onClick={() => this.handleDarkLogo()}
									></input>{' '}
									&nbsp;
									<label for='check-dark-logo'> Logo Override Color </label>
									{DarkLogoInput}
								</div>
								<div className='form-group'>
									<input
										type='checkbox'
										id='check-has-slogan'
										checked={this.state.hasSlogan}
										onClick={() => this.handleHasSlogan()}
									></input>{' '}
									&nbsp;
									<label for='check-has-slogan'> Has Slogan </label>
								</div>
								<div className='form-group'>
									<label>File Type</label>
									<select
										className='form-control'
										value={this.state.asset_type}
										onChange={(event) => {
											this.handleChangeFileType(event.target.value);
										}}
									>
										<option value=''> -- Select File Type -- </option>
										{this.state.asset_types.map((item) => {
											return <option value={item.id}> {item.name} </option>;
										})}
									</select>
								</div>

								{FileInput}

								<Tabs>{TabLanguage}</Tabs>

								{FileThumbnail}

								<div className='row'>
									<div className='col-md-7'></div>
									{ButtonAction}
								</div>
							</div>
						</div>

						<WidgetAssetAddModal
							show={this.state.showAssetModal}
							onHide={this.setShowAssetModal}
							widgetId={this.props.data.widget}
							getWidgetDetail={this.getDetailWidget}
							selectedAssets={this.state.selectedAssets}
						></WidgetAssetAddModal>
						<WidgetAssetAddThumbnailModal
							show={this.state.setShowAssetThumbnailModal}
							onHide={this.setShowAssetThumbnailModal}
							widgetId={this.props.data.widget}
							getWidgetDetail={this.getDetailWidget}
							selectedAssets={this.state.selectedAssets}
							thumbnailType={this.state.thumbnailType}
							type={''}
							selectedAsset={this.handleSelectedAssetThumbnail}
						></WidgetAssetAddThumbnailModal>
						<WidgetAssetUpdateWidgetModal
							show={this.state.setShowAssetUpdateWidget}
							onHide={this.setShowAssetUpdateWidget}
							widgetId={this.props.data.widget.id}
							getWidgetDetail={this.getDetailWidget}
							selectedAssets={this.state.selectedAssets}
							thumbnailType={this.state.thumbnailType}
							type={'update asset'}
							selectedAsset={this.handleSelectedAssetUpdateWidget}
							updatePairAsset={this.updatePairAsset}
						></WidgetAssetUpdateWidgetModal>
						<WidgetAssetDeleteConfirm
							show={this.state.showConfirmDelete}
							onHide={this.setShowConfirmDelete}
							selectedAssets={[this.state.selectedAssets]}
							deleteSelectedAsset={this.handleUnmatchWidgetPair}
						></WidgetAssetDeleteConfirm>
						<AssetOrderModal
							show={this.state.showOrderAssets}
							onHide={this.setShowOrderAssets}
							data={this.state.widgetAssets.map((widgetAsset) => ({ ...widgetAsset, name: widgetAsset.asset.name }))}
							widgetId={this.props.widget}
							prop={this.props}
							confirmAssetOrder={this.confirmAssetOrder}
						></AssetOrderModal>
					</CardBody>
				</Collapse>
			</Card>
		);
	}
}

export default AssetForm;
