import React, { Component, createRef } from "react";
import swal from "sweetalert";
import toTitle from "to-title";
import _ from 'lodash';

import TitlePages from "./Elements/TitlePages";
import EmailEditor from "react-email-editor";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import EmailApi from "../api/EmailApi";
import { Content, ContentHeader, ContentBody } from "./../components/Content";

import { withTranslation } from "react-i18next";
import config from "../config/config";
import ReactGA from "react-ga";
import Context from "./Context";

import Cookies from "js-cookie";
import EmailContextAdd from "../components/Modal/EmailContextAdd";

class EmailTemplate extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            templateDesign: {},
            activeTab: "",
            activeTemplate: {},
            templates: [],
            langId: 1,
            isShowAddTemplate: false,
            transAlert: "alert.",
            transPage: "pages.email_template.",
        };

        this.editor = createRef();

        this.changeLanguage = this.changeLanguage.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.updateTemplateEmail = this.updateTemplateEmail.bind(this);
        this.getAllTemplateEmail = this.getAllTemplateEmail.bind(this);
        this.handleTab = this.handleTab.bind(this);
        this.handleFormValue = this.handleFormValue.bind(this);
        this.handleChangeRecipient = this.handleChangeRecipient.bind(this)
        this.handleChangeSubject = this.handleChangeSubject.bind(this)
        this.addRecipient = this.addRecipient.bind(this)
        this.removeRecipient = this.removeRecipient.bind(this)
        this.getLanguages = this.getLanguages.bind(this);
    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        this.getAllTemplateEmail();
        this.getLanguages();
    }

    changeLanguage(value) {
        const { activeSite } = this.context
        const oldLangId = parseInt(this.state.langId);
        const newLangId = parseInt(value)

        let newActiveTemplate = { ...this.state.activeTemplate };
        this.editor.current.editor.exportHtml((data) => {
            const { design, html } = data;
            const strDesign = JSON.stringify(design);

            //need req to backend `email_contents` api

            const idxLangId = newActiveTemplate?.content_languages?.findIndex(content_language => content_language.language === oldLangId)
            if (idxLangId >= 0) {
                newActiveTemplate.content_languages[idxLangId] = {
                    ...newActiveTemplate.content_languages[idxLangId],
                    body: html,
                    content_design: strDesign,
                    site: activeSite || Cookies.get("active-site"),
                };
            }
        });

        this.setState(
            {
                langId: newLangId,
                activeTemplate: newActiveTemplate || {},
            },
            () => {
                this.handleTab(this.state.activeTab);
            }
        );
    }

    getAllTemplateEmail() {
        const Em = new EmailApi();
        const { activeSite, activeClient } = this.context

        Em.getAllTemplateEmail({ client: activeClient, site: activeSite }).then((res) => {
            const langId = this.state.langId;

            const results = res.data.results;

            let templateDesign = results.length
                ? results[0].content_languages.find(content_language => content_language.language === langId).content_design
                : "";
            templateDesign = templateDesign
                ? JSON.parse(templateDesign)
                : templateDesign;

            this.setState(
                {
                    activeTab: 0,
                    activeTemplate: results[0] || {},
                    templateDesign: templateDesign,
                    templates: results,
                },
                this.onLoad
            );
        });
    }

    getLanguages() {
        const { activeSiteLang } = this.context;

        this.setState({
            langId: activeSiteLang.length ? activeSiteLang[0].id : this.state.langId,
        });
    }

    handleTab(e) {
        let langId, activeTemplate;
        const results = this.state.templates;
        const { activeSiteLang } = this.context;

        if (this.state.activeTab === e) {
            langId = this.state.langId;
            activeTemplate = this.state.activeTemplate;
        } else {
            langId = activeSiteLang.length ? activeSiteLang[0].id : 1;
            activeTemplate = results[e];
        }

        let templateDesign = results.length
            ? results[e].content_languages.find(content_language => content_language.language === langId).content_design
            : "";
        templateDesign = templateDesign
            ? JSON.parse(templateDesign)
            : templateDesign;

        this.setState(
            {
                langId: langId,
                activeTab: e,
                activeTemplate: activeTemplate || {},
                templateDesign: templateDesign,
            },
            this.onLoad
        );
    }

    handleFormValue(value, key) {
        let newActiveTemplate = _.cloneDeep(this.state.activeTemplate)

        newActiveTemplate[key] = value

        this.setState({ activeTemplate: newActiveTemplate })
    }

    handleChangeSubject(value, idx) {
        let newActiveTemplate = _.cloneDeep(this.state.activeTemplate)

        newActiveTemplate.content_languages[idx].subject = value

        this.setState({ activeTemplate: newActiveTemplate })
    }

    handleChangeRecipient(value, idx) {
        let newActiveTemplate = _.cloneDeep(this.state.activeTemplate)

        newActiveTemplate.recipients[idx] = value

        this.setState({ activeTemplate: newActiveTemplate })
    }

    addRecipient() {
        let newActiveTemplate = _.cloneDeep(this.state.activeTemplate)

        const isNotFilledAll = newActiveTemplate.recipients.some(recipient => !recipient)

        if (isNotFilledAll) {
            swal(
                "Error !",
                "You must filled all recipients before add more",
                "error"
            )
            return
        }

        newActiveTemplate.recipients.push('')
        this.setState({ activeTemplate: newActiveTemplate })
    }

    removeRecipient(idx) {
        let newActiveTemplate = _.cloneDeep(this.state.activeTemplate)

        newActiveTemplate.recipients.splice(idx, 1)

        this.setState({ activeTemplate: newActiveTemplate })
    }

    updateTemplateEmail(param) {
        const { t } = this.props;
        const { activeSite } = this.context;
        const Em = new EmailApi();

        this.editor.current.editor.exportHtml((data) => {
            const { design, html } = data;
            const strDesign = JSON.stringify(design);

            let emailContents = { ...this.state.activeTemplate };
            const idxLangId = emailContents.content_languages.findIndex(content_language => content_language.language === this.state.langId)
            emailContents.content_languages[idxLangId] = {
                ...emailContents.content_languages[idxLangId],
                body: html,
                content_design: strDesign,
                site: activeSite || Cookies.get("active-site"),
            };

            Em.updateTemplateEmail(param, emailContents)
                .then((res) => {
                    if (res) {
                        swal(
                            "Success !",
                            t(
                                this.state.transAlert +
                                "email_design_template_update_success"
                            ),
                            "success"
                        );
                    }
                })
                .catch((err) => {
                    swal("Error!", err.message, "error");
                });
        });
    }

    onLoad() {
        const templateJson = this.state.templateDesign;
        if (Object.keys(templateJson).length) {
            if (this.editor.current.editor) {
                this.editor.current.editor.loadDesign(templateJson);
            } else {
                setTimeout(() => {
                    this.onLoad();
                }, 1000);
            }
        }
    }

    render() {
        const { t } = this.props;
        const { activeSiteLang } = this.context;
        const { activeTemplate } = this.state;

        return (
            <Content>
                <ContentHeader>
                    <TitlePages title={t("pages.email_template.title")} />
                </ContentHeader>
                <ContentBody>
                    <ToggleButtonGroup
                        className="mb-2 mr-1"
                        type="radio"
                        name="languageTemplate"
                        value={this.state.activeTab}
                        onChange={(e) => this.handleTab(e)}
                    >
                        {this.state.templates.map((template, i) => {
                            return (
                                <ToggleButton key={template.id} value={i}>
                                    {toTitle(template.context)}
                                </ToggleButton>
                            );
                        })}
                    </ToggleButtonGroup>
                    <Button variant="primary" className="mb-2" onClick={() => { this.setState({ isShowAddTemplate: true }) }}>
                        <i className="fas fa-plus"></i> {!this.state.templates.length && t(this.state.transPage + "add_context")}
                    </Button>

                    {
                        this.state.templates.length ? <>
                            <Tabs
                                onSelect={this.changeLanguage}
                                activeKey={this.state.langId}
                            >
                                {activeSiteLang.map((lang, idxLang) => {
                                    return (
                                        <Tab
                                            key={lang.id}
                                            eventKey={lang.id}
                                            title={lang.name}
                                        >
                                            <div>
                                                <div className="form-group">
                                                    <label>{t('from')}</label>
                                                    <input
                                                        value={activeTemplate?.from_email}
                                                        type="email"
                                                        className="form-control"
                                                        onChange={e => { this.handleFormValue(e.target.value, "from_email") }}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>{t(this.state.transPage + 'recipient_party')}</label>
                                                    <input
                                                        value={activeTemplate?.recipient_party}
                                                        type="text"
                                                        className="form-control"
                                                        onChange={e => { this.handleFormValue(e.target.value, "recipient_party") }}
                                                    />
                                                </div>
                                                <div className="form-group form-check">
                                                    <input
                                                        id={`dynamic-recipient-${activeTemplate?.id}`}
                                                        checked={activeTemplate?.has_dynamic_recipient}
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        onChange={e => { this.handleFormValue(e.target.checked, "has_dynamic_recipient") }}
                                                    />
                                                    <label htmlFor={`dynamic-recipient-${activeTemplate?.id}`} className="form-check-label">{t(this.state.transPage + 'dynamic_recipient')}</label>
                                                </div>
                                                {
                                                    !activeTemplate?.has_dynamic_recipient &&
                                                    <div id="form-keywords" className="form-group">
                                                        <label>{t('recipient')}</label>
                                                        <div className="fk-content">
                                                            {
                                                                activeTemplate?.recipients.map((recipient, idxRecipient) => (
                                                                    <div className="fk-c-in">
                                                                        {
                                                                            activeTemplate?.recipients.length > 1 &&
                                                                            <i className="fa fa-times fa-sm" onClick={() => { this.removeRecipient(idxRecipient) }}></i>
                                                                        }
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={recipient}
                                                                            onChange={(e) => { this.handleChangeRecipient(e.target.value, idxRecipient) }}
                                                                        />
                                                                    </div>
                                                                ))
                                                            }
                                                            <div className="fk-icn-add" onClick={this.addRecipient}>
                                                                <i className="fa fa-plus"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="form-group">
                                                    <label>Subject</label>
                                                    <input
                                                        value={activeTemplate?.content_languages?.[idxLang].subject}
                                                        type="text"
                                                        className="form-control"
                                                        onChange={e => { this.handleChangeSubject(e.target.value, idxLang) }}
                                                    />
                                                </div>
                                            </div>
                                        </Tab>
                                    );
                                })}
                            </Tabs>

                            <EmailEditor ref={this.editor} style={{ height: "75vh" }} />

                            <div className="pb-3 d-flex justify-content-end">
                                <Button
                                    onClick={() => {
                                        this.updateTemplateEmail(
                                            this.state.activeTemplate?.id
                                        );
                                    }}
                                    className="mt-3"
                                    variant="primary"
                                >
                                    {t("pages.email_template.save_design")}
                                </Button>
                            </div>
                        </> : <p>No context found!</p>
                    }
                </ContentBody>

                <EmailContextAdd
                    show={this.state.isShowAddTemplate}
                    onHide={() => { this.setState({ isShowAddTemplate: false }) }}
                    reload={this.getAllTemplateEmail}
                />
            </Content>
        );
    }
}

export default withTranslation()(EmailTemplate);
