import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";

import JobApi from "./../../api/JobApi";
import { withTranslation } from "react-i18next";
import Context from "../Context";

class CompanyAddModal extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      transPage: "modals.company_add.",
      transAlert: "alert.",
    };

    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
    this.addCompany = this.addCompany.bind(this);
    this.getCompanyDetail = this.getCompanyDetail.bind(this);
  }

  onOpenModal() {
    if (this.props.companyId) {
      this.getCompanyDetail();
    } else {
      this.setState({
        name: "",
      });
    }
  }

  onCloseModal() {
    this.props.callback();
    this.props.onHide();
    this.setState({
      name: "",
    });
  }

  handleChangeCompany(value) {
    this.setState({
      name: value,
    });
  }

  getCompanyDetail() {
    const obJob = new JobApi();

    obJob
      .getDetailCompany(this.props.companyId)
      .then((res) => {

        this.setState({
          name: res.data.name,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  addCompany() {
    const { t } = this.props;
    const { activeClient } = this.context;
    const obJob = new JobApi();

    const request = {
      client: activeClient,
      name: this.state.name,
    };

    obJob
      .addCompany(request)
      .then((res) => {
        swal(
          "Success!",
          t(this.state.transAlert + "company_add_success"),
          "success"
        );
        this.onCloseModal();
      })
      .catch((err) => {
        swal("Error!", err.message, "error");
      });
  }

  updateCompany() {
    const { t } = this.props;
    const obJob = new JobApi();

    const request = {
      name: this.state.name,
    };

    obJob
      .updateCompany(this.props.companyId, request)
      .then((res) => {
        swal(
          "Success!",
          t(this.state.transAlert + "company_update_success"),
          "success"
        );
        this.onCloseModal();
      })
      .catch((err) => {
        swal("Error!", err.message, "error");
      });
  }

  render() {
    const { t } = this.props;

    var HeaderText = (
      <Modal.Title> {t(this.state.transPage + "add_company")} </Modal.Title>
    );

    var SubmitButton = (
      <Button variant="primary" onClick={() => this.addCompany()}>
        <i className="fa fa-plus">&nbsp;</i>
        {t(this.state.transPage + "add_company")}
      </Button>
    );

    if (this.props.companyId) {
      HeaderText = (
        <Modal.Title>
          {" "}
          {t(this.state.transPage + "update_company")}{" "}
        </Modal.Title>
      );
      SubmitButton = (
        <Button variant="primary" onClick={() => this.updateCompany()}>
          <i className="fa fa-plus">&nbsp;</i>
          {t(this.state.transPage + "update_company")}
        </Button>
      );
    }

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onShow={this.onOpenModal}
      >
        <Modal.Header closeButton className="bg-primary">
          {HeaderText}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label> {t(this.state.transPage + "new_company")} </label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.name}
                  onChange={(event) =>
                    this.handleChangeCompany(event.target.value)
                  }
                ></input>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>{SubmitButton}</Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(CompanyAddModal);
