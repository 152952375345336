import React, { Component } from "react";
import swal from "sweetalert";
import Dropzone from "react-dropzone";
import { FaPlus, FaTrashAlt, FaPencilAlt } from "react-icons/fa";

import { withTranslation } from "react-i18next";

import Context from "./Context";
import { Content, ContentHeader, ContentBody } from "./../components/Content";
import TitlePages from "./Elements/TitlePages";

import SuperAdminClientModalCreate from "../components/Modal/SuperAdminClientModalCreate";
import SuperAdminSiteAddModal from "../components/Modal/SuperAdminDomainAddModal";
import SuperAdminDomainAddModal from "../components/Modal/SuperAdminSiteAddModal";
import ModalClientDelete from "../components/Modal/ClientDelete";
import ModalSiteDelete from "../components/Modal/SiteDelete";

import SuperAdminApi from "../api/SuperAdminApi";

import "./SuperAdminPage.css";
import Select from "react-select";
import Sitemap from "../components/Sitemap/Sitemap";

// import SelectOption from "./Select";

class SuperAdminPage extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);

        this.state = {
            token: null,
            clients: [],
            activeClient: "",
            dataSelectedClient: [],
            clientDetail: [],
            sites: [],
            siteDetail: [],
            activeSite: false,
            apiUrl: "",
            user: "",
            languages: [],
            languageList: [],
            activeLanguage: [],
            subscriptionsList: [],
            activeSubscriptions: [],
            allfile: [false, false],
            preview: [false, false],

            showClientCreate: false,
            showSiteAdd: false,

            listDomain: [],

            currentDomain: [],
            showDomainAdd: false,
            selected: [],

            options: [],
        };

        this.getAllClient = this.getAllClient.bind(this);
        this.getAllSites = this.getAllSites.bind(this);
        this.getAllLanguages = this.getAllLanguages.bind(this);
        this.getAllSubscriptions = this.getAllSubscriptions.bind(this);
        this.getClientDetail = this.getClientDetail.bind(this);
        this.updateSiteDetail = this.updateSiteDetail.bind(this);
        this.updateClientDetail = this.updateClientDetail.bind(this);
        this.getAllDomain = this.getAllDomain.bind(this);

        this.setClientCreate = this.setClientCreate.bind(this);
        this.setSiteAdd = this.setSiteAdd.bind(this);
        this.setDomainAdd = this.setDomainAdd.bind(this);

        this.handleChangeClient = this.handleChangeClient.bind(this);
        this.handleChangeActiveSubs = this.handleChangeActiveSubs.bind(this);
        this.handleChangeDomain = this.handleChangeDomain.bind(this);
    }

    componentDidMount() {
        const contextValue = this.context;
        const client = contextValue.activeClient;
        const sites = contextValue.activeSite;
        const tkn = contextValue.userKey;
        this.setState(
            {
                activeClient: client,
                activeSite: sites,
                token: tkn,
            },
            () => {
                this.getAllSites(client);
                this.getAllClient();
                this.getAllLanguages();
                this.getAllSubscriptions();
                this.getAllDomain();
                this.getClientDetail(client);
                this.getSiteDetail(sites);
            }
        );
    }

    componentDidUpdate() {
        const contextValue = this.context;
        if (this.state.token !== contextValue.userKey) {
            this.setState({
                token: contextValue.userKey,
            });
        }

        if (this.state.activeClient !== contextValue.activeClient) {
            this.setState({ activeClient: contextValue.activeClient }, () => {
                this.getAllSites(this.state.activeClient);
                this.getClientDetail(this.state.activeClient);
            });
        }

        if (this.state.activeSite !== contextValue.activeSite) {
            this.setState({ activeSite: contextValue.activeSite }, () => {
                this.getAllSites(this.state.activeClient);
                this.getSiteDetail(this.state.activeSite);
            });
        }

        if (this.state.clients !== contextValue.clientList) {
            this.setState({
                clients: contextValue.clientList,
            });
        }

        if (this.state.sites !== contextValue.clientSites) {
            this.setState({
                sites: contextValue.clientSites,
            });
        }
    }

    getAllLanguages() {
        const Sa = new SuperAdminApi();
        const contextValue = this.context;

        Sa.getAllLanguages(contextValue.userKey)
            .then((res) => {
                this.setState({
                    languageList: res.data.results,
                });
            })
            .catch((err) => { });
    }

    getAllSubscriptions() {
        const Sa = new SuperAdminApi();
        const contextValue = this.context;

        Sa.getAllSubscriptions(contextValue.userKey)
            .then((res) => {
                this.setState({
                    subscriptionsList: res.data.results,
                });
            })
            .catch((err) => { });
    }

    getAllDomain() {
        const Sa = new SuperAdminApi();
        const contextValue = this.context;

        Sa.getAllDomain(contextValue.userKey, contextValue.activeSite)
            .then((res) => {
                const options = [];

                if (res.data) {
                    res.data.results.forEach((el) => {
                        options.push({
                            value: el.id,
                            label: el.domain,
                        });
                    });
                }

                this.setState({ options: options });
            })
            .catch((err) => {
                console.log(err.response);
            });
    }

    getAllClient() {
        const contextValue = this.context;

        contextValue.getAllClient();
    }

    handleChangeClient(value) {

    }

    getAllSites(clientId) {
        const Sa = new SuperAdminApi();

        Sa.getAllSites(this.state.token, clientId)
            .then((res) => {
                this.setState({
                    sites: res.data.sites,
                });
            })
            .catch((err) => { });
    }

    handleChangeSite(value) {

    }

    handleChangeDomain(arr) {
        this.setState({ selected: arr });
    }

    getClientDetail(clientId) {
        const Sa = new SuperAdminApi();

        Sa.getClientDetail(this.state.token, clientId)
            .then((res) => {
                const { subscriptionsList } = this.state;

                let client = [];
                let activeSubscriptions = [];
                let preview = []

                subscriptionsList.forEach((subs) => {
                    if (
                        res.data.subscriptions.some(
                            (sub) => sub.name === subs.name
                        )
                    ) {
                        activeSubscriptions.push({
                            id: subs.id,
                            name: subs.name,
                            active: true,
                        });
                    } else {
                        activeSubscriptions.push({
                            id: subs.id,
                            name: subs.name,
                            active: false,
                        });
                    }
                });

                client.push({ name: res.data.name });
                client.push({ address: res.data.address });
                client.push({ phone: res.data.phone });
                preview.push(res.data.logo)
                preview.push(res.data.favicon)

                this.setState({
                    dataSelectedClient: res.data,
                    clientDetail: client,
                    activeSubscriptions: activeSubscriptions,
                    preview: preview
                });
            })
            .catch((err) => { });
    }

    getSiteDetail(id) {
        const Sa = new SuperAdminApi();

        Sa.getSiteDetail(this.state.token, id)
            .then((res) => {
                const { languageList } = this.state;

                let site = [];
                let activeLanguage = [];

                languageList.forEach((lang) => {
                    if (
                        res.data.languages.some((lg) => lg.name === lang.name)
                    ) {
                        activeLanguage.push({
                            id: lang.id,
                            name: lang.name,
                            active: true,
                        });
                    } else {
                        activeLanguage.push({
                            id: lang.id,
                            name: lang.name,
                            active: false,
                        });
                    }
                });

                const listdomain =
                    res.data.site_domains.length !== 0
                        ? res.data.site_domains.map((el) => {
                            return {
                                value: el.id,
                                label: el.domain,
                            };
                        })
                        : [];

                site.push({ site_name: res.data.name });
                site.push({ api_url: res.data.api_url });
                site.push({ site_domain: res.data.site_domains });
                site.push({ token: res.data.user?.token });
                site.push({ site_url: res.data.site_url })

                this.setState({
                    siteDetail: site,
                    apiUrl: res.data.api_url,
                    selected: listdomain,
                    languages: res.data.languages,
                    activeLanguage: activeLanguage,
                });

                Sa.getUsersBySite(this.state.token, this.state.activeSite)
                    .then((res) => {
                        this.setState({
                            user: res.data.results.length,
                        });
                    })
                    .catch((err) => { });
            })
            .catch((err) => { });
    }

    handleChangeActiveSubs(value, index) {
        const { activeSubscriptions } = this.state;
        if (activeSubscriptions.length > 0) {
            let activeSubs = [...activeSubscriptions];
            let newactiveSubs = { ...activeSubs[index] };
            newactiveSubs.active = !value;
            activeSubs[index] = newactiveSubs;

            this.setState({
                activeSubscriptions: activeSubs,
            });
        }
    }

    updateSiteDetail() {
        const Sa = new SuperAdminApi();
        const { token, activeSite, selected } = this.state;
        const contextValue = this.context

        const siteD = selected.map((el) => {
            return {
                id: el.value,
                site: activeSite,
                domain: el.label,
            };
        });

        const dataSite = {
            name: document.getElementById("sa-site-name").value,
            api_url: document.getElementById("sa-api-url").value,
            site_url: document.getElementById("sa-site-url").value,
            site_domains: siteD,
        };

        if (this.state.activeClient && this.state.activeSite) {
            Sa.updateSiteDetail(token, this.state.activeSite, dataSite)
                .then((res) => {
                    if (res.data) {
                        swal("Success!", "site updated", "success");
                        this.getSiteDetail(this.state.activeSite);
                        if (contextValue.activeClient) {
                            contextValue.getAllSitesByClient(contextValue.activeClient)
                        }
                    }
                })
                .catch((err) => {
                    if (err.response.data) {
                        swal(
                            "Error!",
                            `${err.response.data.api_url
                                ? err.response.data.api_url
                                : ""
                            }`,
                            "error"
                        );
                    }
                });
        }
    }

    updateClientDetail() {
        const Sa = new SuperAdminApi();
        const { activeSubscriptions, token, activeClient } = this.state;

        let subs = [];
        activeSubscriptions.forEach((sb) => {
            if (sb.active) {
                subs.push(sb.id);
            }
        });

        const dataClient = {
            name: document.getElementById("sa-cl-name").value,
            address: document.getElementById("sa-cl-address").value,
            phone: document.getElementById("sa-cl-phone").value,
            subscriptions: subs,
            files: this.state.allfile,
            preview: this.state.preview
        };

        if (this.state.activeClient) {
            Sa.updateClientDetail(token, this.state.activeClient, dataClient)
                .then((response) => {
                    if (response.data) {
                        swal("Success!", "client updated", "success");
                        this.getClientDetail(activeClient);
                    }
                })
                .catch((err) => {
                    if (err.response.data) {
                        swal(
                            "Error!",
                            `${err.response.data.api_url
                                ? err.response.data.api_url
                                : ""
                            }`,
                            "error"
                        );
                    }
                });
        }
    }

    addNewClient() {
        const Sa = new SuperAdminApi();

        Sa.updateClient(this.state.token)
            .then((res) => {
                this.setState({
                    sites: res.data.sites,
                });
            })
            .catch((err) => { });
    }

    renderSubsOption(subs, active, index) {
        let checked = (
            <input
                type="checkbox"
                checked={active ? false : true}
                onChange={(event) =>
                    this.handleChangeActiveSubs(event.target.checked, index)
                }
            />
        );
        return (
            <div className="row lg-list" key={`row-lg-${subs.name}`}>
                <div className="w-60 title-lg">
                    <input
                        type="text"
                        className="form-control input-title-lg"
                        defaultValue={subs.name}
                        disabled
                    />
                </div>
                <div className="w-40 d-flex content-lg">
                    <div className="d-flex m-auto">
                        <span className={active ? "active" : "inactive"}>
                            Active
                        </span>
                        <label className="switchCk">
                            {/* <input type="checkbox" defaultChecked={checked} onChange={(event) => this.handleChangeActiveLang(event.target.checked, index)} /> */}
                            {checked}
                            <div className="sliderCk round"></div>
                        </label>
                        <span className={!active ? "active" : "inactive"}>
                            Inactive
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    setClientCreate() {
        this.setState({
            showClientCreate: !this.state.showClientCreate,
        });
    }

    setSiteAdd() {
        this.setState({
            showSiteAdd: !this.state.showSiteAdd,
        });
    }

    setDomainAdd() {
        this.setState({
            showDomainAdd: !this.state.showDomainAdd,
        });
    }

    onDrop = (files, index) => {
        const { allfile, preview } = this.state
        let file = [...allfile]
        file[index] = files
        let prev = [...preview]

        this.setState(
            {
                allfile: file,
            },
            () => {
                files.forEach((file) => {
                    prev[index] = URL.createObjectURL(Object.assign(file))
                    this.setState({
                        preview: prev,
                    });
                });
            }
        );
    };

    renderPreview = (file) => {
        let thumbs = (
            <div className='thumb' key={file}>
                <div className='thumbInner'>
                    <img src={file} alt="" />
                </div>
            </div>
        );
        return thumbs
    }

    modalDeleteLogo = (index) => {
        const { allfile, preview } = this.state
        let file = [...allfile]
        file[index] = false
        let prev = [...preview]
        prev[index] = false
        this.setState({
            allfile: file,
            preview: prev
        })
    }

    setModalClientDelete = () => {
        const { activeClient, clients } = this.state
        const cl = clients.find(c => parseInt(activeClient) === c.id)
        this.setState({
            isActiveClientDelete: cl,
            showModalClientDelete: !this.state.showModalClientDelete,
        });
    }

    setModalSiteDelete = () => {
        const { activeSite, sites } = this.state
        const st = sites.find(s => parseInt(activeSite) === s.id)
        this.setState({
            isActiveSiteDelete: st,
            showModalSiteDelete: !this.state.showModalSiteDelete,
        });
    }

    clientStatusOnChange = (e) => {
        const Sa = new SuperAdminApi();

        if (e.target.checked) {
            const data = {
                is_active: true
            }
            Sa.updateClient(this.state.activeClient, data)
                .then((res) => {
                    console.log('res', res)
                    if (res.status === 200) {
                        this.getClientDetail(this.state.activeClient);
                    }
                })
                .catch((err) => {
                    console.log('err', err)
                });
        }
    }

    render() {
        // const contextValue = this.context;
        const { subscriptionsList, activeSubscriptions, options } = this.state;

        let subscriptionsActive;
        if (subscriptionsList) {
            if (subscriptionsList.length === 0) {
                subscriptionsActive = subscriptionsList.map((subs, i) => {
                    return this.renderSubsOption(subs, true, i);
                });
            } else {
                if (activeSubscriptions.length > 0) {
                    subscriptionsActive = activeSubscriptions.map((subs, i) => {
                        let active;
                        if (subs.active) {
                            active = true;
                        } else {
                            active = false;
                        }
                        return this.renderSubsOption(subs, active, i);
                    });
                } else {
                    subscriptionsActive = subscriptionsList.map((subs, i) => {
                        return this.renderSubsOption(subs, true, i);
                    });
                }
            }
        }

        let dropStyle = {
            marginBottom: "0rem",
        };

        return (
            <Content>
                <ContentHeader>
                    <TitlePages title={"Super Admin"} />
                </ContentHeader>
                <ContentBody>
                    <div id="s-adm-page" className="col-md-12">
                        <div className="row">
                            <div className="col-md-6 d-flex flex-column border-right">
                                <div className="form-group position-relative">
                                    <label>Client</label>

                                    <select id="sa-cl-list" value={this.state.activeClient} className="form-control" disabled>
                                        {this.state.clients.map((item) => {
                                            return (
                                                <option
                                                    value={item.id}
                                                    key={item.id}
                                                // selected={
                                                //     this.state
                                                //         .activeClient ===
                                                //         item.id
                                                //         ? true
                                                //         : false
                                                // }
                                                >
                                                    {item.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <div className="action w-100 mt-1">
                                        <div className="d-flex justify-content-between">
                                            <div className="custom-ck">
                                                {
                                                    this.state.dataSelectedClient.is_active ?
                                                        <input id='four' type='checkbox' disabled={this.state.dataSelectedClient.is_active ? true : false} checked onChange={(e) => this.clientStatusOnChange(e)} /> :
                                                        <input id='four' type='checkbox' disabled={this.state.dataSelectedClient.is_active ? true : false} onChange={(e) => this.clientStatusOnChange(e)} />
                                                }
                                                <label htmlFor='four'>
                                                    <span></span>
                                                    active
                                                    <ins><i>active</i></ins>
                                                </label>
                                            </div>
                                            <div className="action-content">
                                                <FaPlus onClick={this.setClientCreate} color="#17438B" />
                                                <FaTrashAlt onClick={this.setModalClientDelete} color="#17438B" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        id="sa-cl-name"
                                        type="text"
                                        name="sa-cl-name"
                                        className="form-control"
                                        defaultValue={
                                            this.state.clientDetail
                                                ? this.state.clientDetail[0]
                                                    ? this.state.clientDetail[0]
                                                        .name
                                                    : ""
                                                : ""
                                        }
                                    ></input>
                                </div>

                                <div className="form-group">
                                    <label>Address</label>
                                    <input
                                        id="sa-cl-address"
                                        type="text"
                                        name="sa-cl-address"
                                        className="form-control"
                                        defaultValue={
                                            this.state.clientDetail
                                                ? this.state.clientDetail[1]
                                                    ? this.state.clientDetail[1]
                                                        .address
                                                    : ""
                                                : ""
                                        }
                                    ></input>
                                </div>

                                <div className="form-group">
                                    <label>Phone</label>
                                    <input
                                        id="sa-cl-phone"
                                        type="text"
                                        name="sa-cl-phone"
                                        className="form-control"
                                        defaultValue={
                                            this.state.clientDetail
                                                ? this.state.clientDetail[2]
                                                    ? this.state.clientDetail[2]
                                                        .phone
                                                    : ""
                                                : ""
                                        }
                                    ></input>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <label>Logo</label>
                                        <Dropzone onDrop={(files) => this.onDrop(files, 0)} accept="image/*" maxFiles={1} multiple={false}>
                                            {({ getRootProps, getInputProps, open }) => (
                                                <>
                                                    <section className="">
                                                        <div style={dropStyle} {...getRootProps({ className: ["dropzone"].join(" ") })}>
                                                            <input {...getInputProps()} />
                                                            {this.state.preview[0] ? this.renderPreview(this.state.preview[0]) : <p>+ Logo</p>}
                                                        </div>
                                                    </section>
                                                    {this.state.preview[0] && (
                                                        <div className="dropzone-action">
                                                            <FaPencilAlt color="#17438B" className="mr-2" onClick={open} />
                                                            <FaTrashAlt color="#17438B" onClick={() => this.modalDeleteLogo(0)} />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </Dropzone>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Favicon</label>
                                        <Dropzone onDrop={(files) => this.onDrop(files, 1)} accept="image/*" maxFiles={1} multiple={false}>
                                            {({ getRootProps, getInputProps, open }) => (
                                                <>
                                                    <section className="">
                                                        <div style={dropStyle} {...getRootProps({ className: ["dropzone"].join(" ") })}>
                                                            <input {...getInputProps()} />
                                                            {this.state.preview[1] ? this.renderPreview(this.state.preview[1]) : <p>+ Favicon</p>}
                                                        </div>
                                                    </section>
                                                    {this.state.preview[1] && (
                                                        <div className={"dropzone-action"}>
                                                            <FaPencilAlt color="#17438B" className="mr-2" onClick={open} />
                                                            <FaTrashAlt color="#17438B" onClick={() => this.modalDeleteLogo(1)} />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </Dropzone>
                                    </div>
                                </div>

                                <div className="w-100 pr-2 pl-2">
                                    <div className="row">
                                        <div className="w-60 mb-2">
                                            <span>Module Subscription</span>
                                        </div>
                                        <div className="w-40 mb-2">
                                            <span>Set status</span>
                                        </div>
                                    </div>

                                    {subscriptionsActive}

                                    <button
                                        type="button"
                                        className="form-control w-40 mr-n1 mt-2 float-right"
                                        style={{
                                            backgroundColor: "#223771",
                                            color: "#ffffff",
                                        }}
                                        onClick={this.updateClientDetail}
                                    >
                                        Update Client
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex flex-column">
                                <div className="form-group">
                                    <label>Website</label>
                                    <select
                                        id="sa-web-list"
                                        value={this.state.activeSite}
                                        className="form-control"
                                        disabled
                                    >
                                        {this.state.sites.map((item) => {
                                            return (
                                                <option value={item.id} key={item.id}>
                                                    {" "}
                                                    {item.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {(this.state.activeClient) ? (

                                        <>
                                            <div className="action mt-1">
                                                <div className="action-content">
                                                    <FaPlus onClick={this.setSiteAdd} color="#17438B" />
                                                    {
                                                        (this.state.sites.length > 0) &&
                                                        <FaTrashAlt onClick={this.setModalSiteDelete} color="#17438B" />
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>

                                <div className="form-group">
                                    <label>Site Name</label>
                                    <input
                                        id="sa-site-name"
                                        type="text"
                                        name="sa-site-name"
                                        className="form-control"
                                        defaultValue={
                                            this.state.activeSite
                                                ? this.state.siteDetail[0]
                                                    ? this.state.siteDetail[0]
                                                        .site_name
                                                    : ""
                                                : ""
                                        }
                                    ></input>
                                </div>

                                <div className="form-group">
                                    <label>Api Url</label>
                                    <input
                                        id="sa-api-url"
                                        type="text"
                                        name="sa-api-url"
                                        className="form-control"
                                        defaultValue={
                                            this.state.activeSite
                                                ? this.state.siteDetail[1]
                                                    ? this.state.siteDetail[1]
                                                        .api_url
                                                    : ""
                                                : ""
                                        }
                                    ></input>
                                </div>

                                <div className="form-group">
                                    <label>Site Url</label>
                                    <input
                                        id="sa-site-url"
                                        type="text"
                                        name="sa-site-url"
                                        className="form-control"
                                        defaultValue={
                                            this.state.activeSite
                                                ? this.state.siteDetail?.[4]?.site_url
                                                : ""
                                        }
                                    ></input>
                                </div>

                                <div className="form-group">
                                    <label>Client Api Key</label>
                                    <input
                                        id="sa-cl-key"
                                        type="text"
                                        name="sa-cl-key"
                                        className="form-control"
                                        defaultValue={
                                            this.state.activeSite
                                                ? this.state.siteDetail[3]?.token
                                                : ""
                                        }
                                        disabled
                                    ></input>
                                </div>

                                <div className="form-group">
                                    <label>Site Domain</label>
                                    <Select
                                        value={this.state.selected}
                                        isMulti
                                        name="collection"
                                        options={options}
                                        className={[
                                            "basic-multi-select",
                                            "mt-3",
                                        ].join(" ")}
                                        classNamePrefix="select"
                                        onChange={(selected) =>
                                            this.handleChangeDomain(selected)
                                        }
                                    />

                                    <span
                                        onClick={this.setDomainAdd}
                                        style={{
                                            float: "right",
                                            color: "#223771",
                                            cursor: "pointer",
                                        }}
                                    >
                                        Add new domain
                                    </span>
                                </div>

                                {/* <div className="form-group">
                                    <label>See user</label>
                                    <div className="d-flex">
                                        <input
                                            id="sa-user"
                                            type="text"
                                            name="sa-user"
                                            className="form-control w-25"
                                            defaultValue={
                                                this.state.activeSite
                                                    ? this.state.user
                                                    : ""
                                            }
                                            disabled
                                        ></input>
                                        {this.state.activeSite ? (
                                            <Link
                                                to={{
                                                    pathname: `superadmin/user/${this.state.activeSite}`,
                                                    state: this.state
                                                        .activeSite,
                                                }}
                                                // to={`superadmin/user/${this.state.activeSite}`}
                                                className="w-100"
                                            >
                                                <button
                                                    type="button"
                                                    className="form-control w-25 ml-2"
                                                    style={{
                                                        backgroundColor:
                                                            "#223771",
                                                        color: "#ffffff",
                                                    }}
                                                >
                                                    User
                                                </button>
                                            </Link>
                                        ) : (
                                            <button
                                                disabled
                                                type="button"
                                                className="form-control w-25 ml-2"
                                                style={{
                                                    backgroundColor: "#223771",
                                                    color: "#ffffff",
                                                }}
                                            >
                                                User
                                            </button>
                                        )}
                                    </div>
                                </div> */}

                                <div className="w-100">
                                    <button
                                        type="button"
                                        className="form-control w-40 mr-n1 mt-2 float-right"
                                        style={{
                                            backgroundColor: "#223771",
                                            color: "#ffffff",
                                        }}
                                        onClick={this.updateSiteDetail}
                                    >
                                        Update Site
                                    </button>
                                </div>

                                <div className="mt-4">
                                    <Sitemap />
                                </div>
                            </div>

                        </div>
                    </div>
                </ContentBody>

                <SuperAdminClientModalCreate show={this.state.showClientCreate} onHide={this.setClientCreate} callback={this.getAllClient} subscriptionsList={this.state.subscriptionsList} token={this.state.token} languageList={this.state.languageList}></SuperAdminClientModalCreate>
                <SuperAdminSiteAddModal show={this.state.showSiteAdd} onHide={this.setSiteAdd} callback={(e) => this.getAllSites(e)} token={this.state.token} activeClient={this.state.activeClient} activeSite={this.state.activeSite} languageList={this.state.languageList}></SuperAdminSiteAddModal>
                <SuperAdminDomainAddModal show={this.state.showDomainAdd} onHide={this.setDomainAdd} callback={this.getAllDomain} token={this.state.token} activeClient={this.state.activeClient} activeSite={this.state.activeSite}></SuperAdminDomainAddModal>
                <ModalClientDelete show={this.state.showModalClientDelete} onHide={this.setModalClientDelete} callback={this.getAllClient} data={this.state.dataSelectedClient} getClientDetail={() => this.getClientDetail(this.state.activeClient)} />
                <ModalSiteDelete show={this.state.showModalSiteDelete} onHide={this.setModalSiteDelete} callback={() => this.getAllSites(this.state.activeClient)} data={this.state.isActiveSiteDelete} activeClient={this.state.activeClient} />
            </Content>
        );
    }
}

export default withTranslation()(SuperAdminPage);
