import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";

import Context from "../../pages/Context";
import SuperAdminApi from "../../api/SuperAdminApi";

import "./modalGlobal.css";

export default class SuperAdminUserDetail extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            userDetail: [],
            activeGroups: [],
            groupsNew: [],
            allSites: [],
            is_Admin: false,
            siteSelected: [],
            is_CheckedSite: true,
        };
        this.onShow = this.onShow.bind(this);
        this.onHide = this.onHide.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.handleChangeActiveGroups =
            this.handleChangeActiveGroups.bind(this);
        this.getUserDetail = this.getUserDetail.bind(this);
        this.getAllSites = this.getAllSites.bind(this)
    }

    onShow() {
        this.setState(
            {
                // userDetail: [],
                // activeGroups: [],
                ids: this.props.state,
            },
            () => {
                this.getUserDetail();
                this.getAllSites();
            }
        );
    }

    getUserDetail() {
        const Sa = new SuperAdminApi();
        const contextValue = this.context;
        Sa.getUsersDetail(contextValue.userKey, this.props.activeDetailUser)
            .then((res) => {
                let activeGroups = [];

                let grp = res.data.groups;

                if (this.props.groups && this.props.groups.length > 0) {
                    this.props.groups.forEach((gr) => {
                        if (grp.some((grs) => grs.name === gr.name)) {
                            activeGroups.push({
                                id: gr.id,
                                name: gr.name,
                                active: true,
                            });
                        } else {
                            activeGroups.push({
                                id: gr.id,
                                name: gr.name,
                                active: false,
                            });
                        }
                    });
                }
                const sites = res.data.sites.map((elm) => elm.id);

                this.setState({
                    userDetail: res.data,
                    activeGroups: activeGroups,
                    siteSelected: sites,
                    is_Admin: res.data.is_admin,
                });
            })
            .catch((err) => { });
    }

    getAllSites() {
        const superAdminApi = new SuperAdminApi();
        const { userKey, activeClient } = this.context

        superAdminApi
            .getSitesByAdmin(userKey, activeClient, { is_admin: true })
            .then(res => {
                this.setState({ allSites: res.data.results })
            })
            .catch(err => console.log(err))
    }

    onHide() {
        this.setState(
            {
                userDetail: [],
                activeGroups: [],
            },
            () => {
                this.props.onHide();
            }
        );
    }

    updateUser() {
        const Sa = new SuperAdminApi();
        const contextValue = this.context;
        const { activeGroups, siteSelected } = this.state;

        let groups = [];
        activeGroups.forEach((gr) => {
            if (gr.active) {
                groups.push(gr.id);
            }
        });

        const dataUser = {
            name: document.getElementById("mSa-userD-name").value,
            // username: document.getElementById('mSa-userD-username').value,
            email: document.getElementById("mSa-userD-email").value,
            groups: groups,
            // is_admin: this.state.is_Admin,
            sites: siteSelected,
        };

        if (this.props.activeDetailUser) {
            Sa.updateUser(
                contextValue.userKey,
                this.props.activeDetailUser,
                dataUser
            )
                .then((res) => {
                    if (res.data) {
                        swal("Success", `Update User`, "success");
                        this.props.callback();
                        this.props.onHide();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    handleChangeActiveGroups(value, index) {
        const { activeGroups } = this.state;
        if (activeGroups.length > 0) {
            let activeSubs = [...activeGroups];
            let newactiveSubs = { ...activeSubs[index] };
            newactiveSubs.active = !value;
            activeSubs[index] = newactiveSubs;

            this.setState(
                {
                    activeGroups: activeSubs,
                }
            );
        }
    }

    renderGroupsOption(subs, active, index) {
        let checked;
        if (active === true) {
            checked = (
                <input
                    type="checkbox"
                    onChange={(event) =>
                        this.handleChangeActiveGroups(
                            event.target.checked,
                            index
                        )
                    }
                />
            );
        } else if (active === false) {
            checked = (
                <input
                    type="checkbox"
                    checked
                    onChange={(event) =>
                        this.handleChangeActiveGroups(
                            event.target.checked,
                            index
                        )
                    }
                />
            );
        }
        return (
            <div className="row lg-list" key={`${index}`}>
                <div className="w-50 title-lg">
                    <input
                        type="text"
                        className="form-control input-title-lg"
                        defaultValue={subs.name}
                        disabled
                    />
                </div>
                <div className="w-50 d-flex content-lg">
                    <div className="d-flex m-auto">
                        <span className={active ? "active" : "inactive"}>
                            Active
                        </span>
                        <label className="switchCk">
                            {/* <input type="checkbox" defaultChecked={checked} onChange={(event) => this.handleChangeActiveLang(event.target.checked, index)} /> */}
                            {checked}
                            <div className="sliderCk round"></div>
                        </label>
                        <span className={!active ? "active" : "inactive"}>
                            Inactive
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    handleSelectSite(e, id) {
        const { siteSelected } = this.state;
        let sites = siteSelected;
        if (e && id) {
            if (!siteSelected.includes(id)) {
                sites.push(id);
            }
        } else if (!e && id) {
            if (siteSelected.includes(id)) {
                const index = sites.indexOf(id);
                if (index > -1) {
                    sites.splice(index, 1);
                }
            }
        }

        this.setState(
            {
                siteSelected: sites,
            },
        );
    }

    handleSelectSiteAdmin(e) {
        this.setState({ is_Admin: e });
    }

    render() {
        const { activeGroups } = this.state;

        let groupsActive;

        if (activeGroups.length > 0) {
            groupsActive = activeGroups.map((subs, i) => {
                let active;
                if (subs.active) {
                    active = true;
                } else {
                    active = false;
                }
                return this.renderGroupsOption(subs, active, i);
            });
        } else {
            groupsActive = activeGroups.map((subs, i) => {
                return this.renderGroupsOption(subs, false, i);
            });
        }

        let allSites = this.state.allSites?.map((site) => {
            return (
                <p
                    key={site}
                    className="c-ck-custom mb-2"
                    style={{ color: "#212529" }}
                >
                    <input
                        id={`site-checkbox-${site.id}`}
                        checked={this.state.siteSelected.includes(site.id)}
                        type="checkbox"
                        onClick={(e) => {
                            this.handleSelectSite(e.target.checked, site.id);
                        }}
                    />
                    <span
                        className="checkmark ck-blue"
                        style={{ backgroundColor: "#e2e2e2" }}
                    ></span>
                    <label
                        className="font-weight-normal"
                        htmlFor={`site-checkbox-${site.id}`}
                    >
                        {site.name}
                    </label>
                </p>
            );
        });

        return (
            <Modal
                id="mglbl-modal-SASupdUs"
                size="md"
                className="mglbl-modal"
                show={this.props.show}
                onHide={this.onHide}
                onShow={this.onShow}
            >
                {this.props.loadingModal}
                <Modal.Header>
                    <Modal.Title>Update User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>Name</label>
                        <input
                            id="mSa-userD-name"
                            type="text"
                            name="mSa-userD-name"
                            className="form-control"
                            defaultValue={this.state.userDetail?.name}
                        ></input>
                    </div>
                    {/* <div className="form-group">
                        <label>Username</label>
                        <input
                            id="mSa-userD-username"
                            type="text"
                            name="mSa-userD-username"
                            className="form-control"
                            defaultValue={this.state.userDetail?.username}
                            disabled
                        ></input>
                    </div> */}
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            id="mSa-userD-email"
                            type="email"
                            name="mSa-userD-email"
                            className="form-control"
                            defaultValue={this.state.userDetail?.email}
                        ></input>
                    </div>
                    <div className="form-group d-flex">
                        <label>Active</label>
                        <input
                            id="mSa-userD-active"
                            type="checkbox"
                            name="mSa-userD-active"
                            className="form-control mt-auto mr-auto mb-auto ml-0"
                            checked={this.state.userDetail?.is_active}
                            style={{ height: "1.25rem", width: "4rem" }}
                        ></input>
                    </div>
                    <div className="form-group">
                        <label>Sites</label>
                        {allSites}
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="w-50">
                                <span>Group</span>
                            </div>
                            <div className="w-50">
                                <span>Set status</span>
                            </div>
                        </div>

                        {groupsActive}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex w-100 justify-content-end">
                        <button
                            type="button"
                            className="mglbl-btn-cancel mr-2"
                            onClick={() => this.props.onHide()}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="mglbl-btn-add"
                            onClick={this.updateUser}
                        >
                            Update User
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}
