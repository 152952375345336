import React, { Component } from "react";
// import { Link } from "react-router-dom";
import swal from "sweetalert";

import { withTranslation } from "react-i18next";

import Context from "./Context";
import { Content, ContentHeader, ContentBody } from "./../components/Content";
import TitlePages from "./Elements/TitlePages";

import SuperAdminApi from "../api/SuperAdminApi";

import "./SuperAdminPage.css";

class SuperAdminUserAdd extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            sites: [],
            siteSelected: [],
            activeGroups: [],
            activeSubscription: [],
            isSites: true,
            isGroups: true,
            isPassword: true,
            is_admin: false,

            currentSite: "",
            currentClient: "",

            bzAdmin: [],
        };

        this.getAllGroups = this.getAllGroups.bind(this);
        this.getAllSites = this.getAllSites.bind(this);
        this.addNewUser = this.addNewUser.bind(this);
        this.formValidation = this.formValidation.bind(this);

        this.getSitesByAdmin = this.getSitesByAdmin.bind(this);
        this.getIsAdmin = this.getIsAdmin.bind(this);
    }

    componentDidMount() {
        const contextValue = this.context;
        const activeC = contextValue.activeClient;
        const activeS = contextValue.activeSite;
        this.getAllGroups();
        this.getIsAdmin();

        if (activeC) {
            this.setState(
                { currentClient: activeC, currentSite: activeS },
                () => {
                    // this.getAllSites();
                    this.getSitesByAdmin(activeC);
                    this.getUsersBySite(activeS);
                    this.getClientDetail(activeC);
                }
            );

            // this.getAllSites() enable if you want to keep displaying all sites compare with existing sites on client
        }
    }

    componentDidUpdate() {
        const contextValue = this.context;

        if (this.state.currentClient !== contextValue.activeClient) {
            this.setState({ currentClient: contextValue.activeClient }, () => {
                this.getClientDetail(this.state.currentClient);
                this.getSitesByAdmin(this.state.currentClient);
            });
        }
    }

    getUsersBySite(id) {
        const Sa = new SuperAdminApi();
        const contextValue = this.context;
        // const sites = contextValue.activeSite;

        // const stateLocation = this.props.location.state;

        Sa.getUsersBySite(contextValue.userKey, id)
            .then((res) => {
                this.setState({
                    users: res.data.results,
                    totalCount: res.data.results.length,
                });
            })
            .catch((err) => { });
    }

    getIsAdmin() {
        const Sa = new SuperAdminApi();
        const contextValue = this.context;
        Sa.getIsAdmin(contextValue.userKey)
            .then((res) => {
                this.setState({
                    bzAdmin: res.data.results,
                });
            })
            .catch((err) => { });
    }

    getSitesByAdmin(id) {
        const Sa = new SuperAdminApi();
        const contextValue = this.context;

        Sa.getSitesByAdmin(contextValue.userKey, id, { is_admin: true })
            .then((res) => {
                this.setState({ sites: res.data.results });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getAllSites() {
        const Sa = new SuperAdminApi();
        const contextValue = this.context;

        Sa.getSites(contextValue.userKey)
            .then((res) => {
                this.setState({
                    sites: res.data.results,
                });
            })
            .catch((err) => { });
    }

    getAllGroups() {
        const Sa = new SuperAdminApi();
        const contextValue = this.context;
        const { activeSubscription } = this.state;

        if (activeSubscription) {
            Sa.getGroupsBasedSubscriptions(
                contextValue.userKey,
                activeSubscription
            )
                .then((res) => {
                    let allgroups = res.data.results;
                    let activeGroups = [];

                    if (allgroups && allgroups.length > 0) {
                        allgroups.forEach((gr) => {
                            activeGroups.push({
                                id: gr.id,
                                name: gr.name,
                                active: false,
                            });
                        });
                    }

                    this.setState({
                        groups: res.data.results,
                        activeGroups: activeGroups,
                    });
                })
                .catch((err) => { });
        }
    }

    getClientDetail = (client) => {
        const Sa = new SuperAdminApi();
        const contextValue = this.context;

        Sa.getClientDetail(contextValue.userKey, client)
            .then((res) => {
                let allSubscription = res.data.subscriptions;
                let activeSubscription = [];

                if (allSubscription && allSubscription.length > 0) {
                    allSubscription.forEach((sb, index) => {
                        activeSubscription.push(sb.id);
                    });
                }

                this.setState(
                    {
                        // sites: res.data.sites,
                        activeSubscription: activeSubscription,
                    },
                    () => {
                        this.getAllGroups();
                    }
                );
            })
            .catch((err) => { });
    };

    goBack = () => {
        this.props.history.goBack();
    };

    formValidation(groups) {
        const { siteSelected } = this.state;

        let password = document.getElementById("s-adm-usAdd-pass").value;
        let confirm_password =
            document.getElementById("s-adm-usAdd-cpass").value;
        let isSites, isGroups, isPassword;

        if (confirm_password !== password) {
            isPassword = false;
        } else {
            isPassword = true;
        }

        if (siteSelected.length > 0) {
            isSites = true;
        } else {
            isSites = false;
        }

        if (groups.length > 0) {
            isGroups = true;
        } else {
            isGroups = false;
        }

        this.setState({
            isPassword: isPassword,
            isSites: isSites,
            isGroups: isGroups,
        });

        if (isPassword && isSites && isGroups) {
            return true;
        } else {
            return false;
        }
    }

    addNewUser(e) {
        e.preventDefault();
        const { siteSelected, activeGroups } = this.state;
        const contextValue = this.context;

        // const isAdmin = bzAdmin.map((el) => {
        //     return el.id;
        // });

        let groups = [];
        activeGroups.forEach((gr) => {
            if (gr.active) {
                groups.push(gr.id);
            }
        });

        // const last = siteSelected.concat(isAdmin);

        if (this.formValidation(groups)) {
            const user = {
                sites: siteSelected,
                groups: groups,
                name: document.getElementById("s-adm-usAdd-name").value,
                email: document.getElementById("s-adm-usAdd-email").value,
                password: document.getElementById("s-adm-usAdd-pass").value,
                confirm_password: document.getElementById("s-adm-usAdd-cpass").value,
                // is_admin: this.state.is_admin,
            };

            const Sa = new SuperAdminApi();

            Sa.addNewUser(contextValue.userKey, user)
                .then((res) => {
                    if (res.data) {
                        swal("Success", `Update User success`, "success").then(
                            () => {
                                this.props.history.goBack();
                                this.getUsersBySite();
                            }
                        );
                    }
                })
                .catch((err) => { });
        }
    }

    handleSelectSite(e, id) {
        const { siteSelected } = this.state;

        let sites = siteSelected;
        if (e.target.checked && id) {
            if (!siteSelected.includes(id)) {
                sites.push(id);
            }
        } else if (!e.target.checked && id) {
            if (siteSelected.includes(id)) {
                const index = sites.indexOf(id);
                if (index > -1) {
                    sites.splice(index, 1);
                }
            }
        }

        this.setState(
            {
                siteSelected: sites,
            },
        );
    }

    handleSelectSiteAdmin(e) {
        this.setState({ is_admin: e });
    }

    handleChangeActiveGroups(value, index) {
        const { activeGroups } = this.state;
        if (activeGroups.length > 0) {
            let activeSubs = [...activeGroups];
            let newactiveSubs = { ...activeSubs[index] };
            newactiveSubs.active = !value;
            activeSubs[index] = newactiveSubs;

            this.setState(
                {
                    activeGroups: activeSubs,
                },
            );
        } else {
        }
    }

    renderGroupsOption(subs, active, index) {
        let checked;
        return (
            <div key={subs.name} className="row lg-list">
                <div className="w-50 title-lg pr-0">
                    <input
                        type="text"
                        className="form-control input-title-lg"
                        defaultValue={subs.name}
                        disabled
                    />
                </div>
                <div className="w-50 d-flex content-lg pl-0">
                    <div className="d-flex m-auto">
                        <span className={active ? "active" : "inactive"}>
                            Active
                        </span>
                        <label className="switchCk">
                            <input
                                type="checkbox"
                                defaultChecked={!checked}
                                onChange={(event) =>
                                    this.handleChangeActiveGroups(
                                        event.target.checked,
                                        index
                                    )
                                }
                            />
                            <div className="sliderCk round"></div>
                        </label>
                        <span className={!active ? "active" : "inactive"}>
                            Inactive
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { groups, sites, isSites, isGroups, isPassword } = this.state;

        let allSites;

        if (sites?.length > 0) {
            allSites = sites?.map((site) => {
                return (
                    <p key={site} className="c-ck-custom mb-2">
                        <input
                            id={`site-checkbox-${site.id}`}
                            type="checkbox"
                            onClick={(e) => {
                                this.handleSelectSite(e, site.id);
                            }}
                        />
                        <span
                            className="checkmark ck-blue"
                            style={{ backgroundColor: "#e2e2e2" }}
                        ></span>
                        <label
                            className="font-weight-normal"
                            htmlFor={`site-checkbox-${site.id}`}
                        >
                            {site.name}
                        </label>
                    </p>
                );
            });
        }

        let userGroups;
        if (groups.length > 0) {
            userGroups = groups.map((subs, i) => {
                let active;
                if (subs.active) {
                    active = true;
                } else {
                    active = false;
                }
                return this.renderGroupsOption(subs, active, i);
            });
        }

        return (
            <Content>
                <ContentHeader>
                    <TitlePages title={"Users Add"} />
                </ContentHeader>
                <ContentBody>
                    <div id="s-adm-us-add" className="d-flex flex-column">
                        <form onSubmit={this.addNewUser}>
                            <div className="col-md-12 d-flex">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input
                                            id="s-adm-usAdd-name"
                                            type="text"
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input
                                            id="s-adm-usAdd-email"
                                            type="text"
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className={!isSites ? "sp-attention" : ""}>
                                            Sites
                                        </label>
                                        {allSites}
                                        {!isSites ? (
                                            <span className="sp-attention">
                                                <em>
                                                    Please select at least 1
                                                    site
                                                </em>
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                        {/* <select className='form-control' required
                                        onChange={(event) =>
                                            this.handleChangeSites(event.target.value)
                                        }>
                                            {allSites}
                                        </select> */}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input
                                            id="s-adm-usAdd-pass"
                                            type="password"
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className={!isPassword ? "sp-attention" : ""}>
                                            Confirm Password
                                        </label>
                                        <input
                                            id="s-adm-usAdd-cpass"
                                            type="password"
                                            className="form-control"
                                            required
                                        />
                                        {!isPassword ? (
                                            <span className="sp-attention">
                                                <em>Password doesn't match</em>
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label className={!isGroups ? "sp-attention" : ""}>
                                            Groups
                                        </label>
                                        {userGroups}
                                        {!isGroups ? (
                                            <span className="sp-attention">
                                                <em> Please select at least 1 group</em>
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex pr-2 mt-2 justify-content-end">
                                <button
                                    type="button"
                                    className="form-control w-20 mr-2 "
                                    style={{
                                        backgroundColor: "#f1f1f1",
                                        color: "#00599D",
                                    }}
                                    onClick={this.goBack}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="form-control w-20"
                                    style={{
                                        backgroundColor: "#223771",
                                        color: "#ffffff",
                                    }}
                                >
                                    Add New User
                                </button>
                            </div>
                        </form>
                    </div>
                </ContentBody>
            </Content>
        );
    }
}

export default withTranslation()(SuperAdminUserAdd);
