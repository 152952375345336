import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class JobPosterModalClose extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transPage: "modals.job_poster_close.",
        };

        //this.archiveSelectedApplicant = this.archiveSelectedApplicant.bind(this)
        this.onOpenModal = this.onOpenModal.bind(this);
        this.closeSelectedJob = this.closeSelectedJob.bind(this);
        // this.onHideModal = this.onHideModal.bind(this)
    }

    closeSelectedJob() {
        // dari props method nya
        // this.props.applicantArchive(this.props.applicantId, false)
        this.props.jobPosterUpdateStatus();
        this.props.onHide();
    }

    onOpenModal() {
    }

    onCloseModal() {
        this.props.onHide();
    }

    render() {
        const { t } = this.props;

        return (
            <Modal
                show={this.props.show}
                onHide={() => this.onCloseModal()}
                onShow={() => this.onOpenModal()}
            >
                {this.props.loadingModal}
                <Modal.Header closeButton className="bg-secondary">
                    <Modal.Title>
                        {t(this.state.transPage + "title")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t(this.state.transPage + "confirmation_text")}</p>
                    {/* {
                        this.props.selectedJobs.map(item => {
                            return (<li> {item.article.name}</li>)
                        })
                    } */}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => this.props.onHide()}
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={() => this.closeSelectedJob()}
                    >
                        <i className="fa fa-times-circle">&nbsp;</i>
                        {t(this.state.transPage + "close_job")}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(JobPosterModalClose);
