import React, { Component } from 'react';
import { Badge } from 'react-bootstrap';


const labelConfirmation = [
    {
        id:1,
        name:"Pending",
        label:"secondary"
    },
    {
        id:2,
        name:"In Review",
        label:"primary"
    },
    {
        id:3,
        name:"Waiting for Interview",
        label:"info"
    },
    {
        id:4,
        "name": "Accepted",
        label:"success"
    },{
        id:5,
        "name": "Rejected",
        label:"danger"
    }
]

class index extends Component {
    render() {

        var header = this.props.header ?  <div className="card-header">{this.props.header}</div> : ""

        var myLabel = labelConfirmation.find(item2 => {
            return item2.name === this.props.confirmation_name
        })

        return (
            <a href={this.props.href} className="col-md-3" style={{textDecoration:"none", color:"black"}}>
                <div className="card border-primary mb-3" style={{"maxWidth":"18erm", height:"100%"}}>
                    { header }
                    <div className="card-body" style={{position:"relative"}}>
                        <h5 className="card-title"><b>{ this.props.title }</b></h5>
                        <br></br>
                        <Badge title="confirmation info" variant={myLabel.label} style={{position:"absolute", bottom:"10px", left:"20px"}}>{ this.props?.confirmation_name}</Badge>
                    </div>
                    <div className="card-footer">
                        
                    </div>
                    <div className="card-footer">
                        <p className="card-text">{ this.props.description}</p>
                    </div>
                </div>
            </a>
        );
    }
}

export default index;