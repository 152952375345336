import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
import AlertHelper from "./../../lib/AlertHelper";
import TemplateApi from "./../../api/TemplateApi";
import { withTranslation } from "react-i18next";
import Context from "../../pages/Context";

const initialState = {
  id: 0,
  name: "",
  screenshot: "",
  transPage: "modals.template_add.",
  transAlert: "alert.",
  isActive: false,
  site: "",
};
class TemplateAddModal extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = initialState;

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleActiveTemplate = this.handleActiveTemplate.bind(this);
    this.handleScreenshotFile = this.handleScreenshotFile.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.getTemplateDetail = this.getTemplateDetail.bind(this);
    this.createTemplateNew = this.createTemplateNew.bind(this);
  }

  componentDidMount() {}

  onOpenModal() {
    this.setState(
      {
        id: this.props.id,
      },
      () => {
        if (this.state.id) {
          this.getTemplateDetail();
        }
      }
    );
  }

  handleNameChange(value) {
    this.setState({
      name: value,
    });
  }

  handleActiveTemplate(value) {
    this.setState((prevState) => {
      return {
        isActive: !prevState.isActive,
      };
    });
  }

  handleScreenshotFile(e) {
    this.setState({
      screenshot: e.target.files[0],
    });
  }

  getTemplateDetail() {
    const objTemplate = new TemplateApi();

    objTemplate.getTemplateDetail(this.state.id).then((res) => {
      this.setState({
        name: res.data.name,
      });
    });
  }

  createTemplateNew() {
    const { t } = this.props;
    const { activeSite, getSiteDetail } = this.context.appContext;
    const objTemplate = new TemplateApi();
    const formData = new FormData();
    const ids = parseInt(localStorage.getItem("siteId"));
    formData.append("name", this.state.name);
    formData.append("site", ids);
    formData.append("ga_code", this.state._ga);

    objTemplate
      .createTemplateNew(formData)
      .then((res) => {
        swal(
          "Success !",
          t(this.state.transAlert + "page_add_sucess"),
          "success"
        );
        getSiteDetail(activeSite);
        this.props.onHide();
        this.setState(initialState);
      })
      .catch((err) => {
        const objAlert = new AlertHelper();
        const resErr = err?.response?.data ? err?.response?.data : err.message;
        objAlert.errorHandlerAlert(resErr);
      });
  }

  render() {
    const { t } = this.props;
    var HeaderTitle = t(this.state.transPage + "title_add");
    var BtnSubmit = (
      <Button variant="primary" onClick={() => this.createTemplateNew()}>
        <i className="fa fa-plus">&nbsp;</i>
        {t(this.state.transPage + "add_template")}
      </Button>
    );

    return (
      <Modal
        show={this.props.show}
        onHide={() => this.props.onHide()}
        onShow={() => this.onOpenModal()}
      >
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title> {HeaderTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label> {t("name")} </label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={this.state.name}
              onChange={(event) => this.handleNameChange(event.target.value)}
            ></input>
          </div>
        </Modal.Body>
        <Modal.Footer>{BtnSubmit}</Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(TemplateAddModal);
