import React, { Component } from 'react';
import { Modal } from "react-bootstrap"
import { withTranslation } from 'react-i18next';

class ExperienceListModal extends Component {

    
    constructor(props) {
        super(props)
        this.state = {
            experiences:[],
            transPage: "modals.experience_list."
        }

        this.onOpenModal    = this.onOpenModal.bind(this)
        this.getExperiences = this.getExperiences.bind(this)
    }

    getExperiences() {
        this.setState({
            experiences:this.props.experiences
        })
    }

    onOpenModal() {
        this.getExperiences()
    }

    render() {
        const {t} = this.props;
        const Experiences = this.state.experiences.map(item => {
            return (<tr>
                <td>{item.title}</td>
                <td>{item.company}</td>
                <td>{item.description}</td>
                <td>{ item.date_start } - { item.date_finish}</td>
                
            </tr>)
        })

        return (
            <Modal size="lg" show={this.props.show} onHide={this.props.onHide} onShow={this.onOpenModal}>
                <Modal.Header closeButton className="bg-primary">
                   {t('experience')}
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                       <table className="table table-bordered">
                           <thead>
                               <th> {t('title')} </th>
                               <th> {t('company')} </th>
                               <th> {t('description')} </th>
                               <th> {t('from')} </th>
                           </thead>
                           <tbody>
                               { Experiences }
                           </tbody>
                       </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { this.props.onHide() }}> {t('ok')} </button>
                   
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(ExperienceListModal);