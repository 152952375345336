import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"
import CRMApi from "../../api/CRMApi"
import DataTable from '../DataTable/DataTable'
import css from './HistoryModal.module.css'

class HistoryModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            histories: []
        }

        this.getAllHistories = this.getAllHistories.bind(this)
    }

    onOpenModal = () => {
        this.getAllHistories()
    }

    getAllHistories() {
        const crmApi = new CRMApi();
        const memberId = this.props.memberId

        crmApi
            .getAllHistoriesByMemberId(memberId, { ordering: "-id", max_size: true })
            .then(res => {
                this.setState({ histories: res.data.results })
            })
    }

    render() {
        const bgColor = {
            Create: "#D4FFD4",
            Update: "#FFFBE1",
            Delete: "#FFE1E1"
        }

        const historyColumns = [
            { name: 'Action', selector: row => <span className={`badge badge-pill`} style={{ backgroundColor: bgColor[row.action] }}>{row.action}</span>, sortable: true, width: "120px" },
            { name: 'Description', selector: row => row.description, sortable: true, },
            { name: 'Date', selector: row => (new Date(row.timestamp)).toLocaleString('en-US', { dateStyle: "long", timeStyle: "short", hour12: true}), sortable: true, width: "300px" },
        ]

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                backdrop="static"
                onShow={() => this.onOpenModal()}
                size="xl"
            >
                <Modal.Header closeButton className={['bg-primary', css.bgPrimary].join(' ')}>
                    <Modal.Title>History</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{ backgroundColor: "#F9F9F9" }}
                >
                    <DataTable
                        columns={historyColumns}
                        data={this.state.histories}
                        pagination
                        paginationRowsPerPageOptions={[10, 15, 20, 50, 100]}
                    />
                </Modal.Body>
                <Modal.Footer
                    style={{ backgroundColor: "#F9F9F9" }}
                >
                    <Button className={["btn btn-primary", css.ButtonPrimary].join(' ')} onClick={this.props.onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default HistoryModal;