import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { instanceOf } from "prop-types";
import { Cookies } from "react-cookie";
import { withCookies } from "react-cookie";
import _ from 'lodash';

import { Helmet } from "react-helmet";
import Page404 from "./pages/Page404";
import TestPage from "./pages/TestPage";
import Login from "./pages/Login";
import TestSidebarPage from "./pages/TestSidebarPage";
import Context from "./pages/Context";

import NewSideBar from "./samples/NewSidebar/NewSideBar";
import NewDashboard from "./components/section/DashboardNew";
import PrivateRoute from "./components/Route/PrivateRoute";

import LanguageApi from "./api/LanguageApi";
import WidgetApi from "./api/WidgetApi";
import PageApi from "./api/PageApi";
import ArticleApi from "./api/ArticleApi";
import SectionApi from "./api/SectionApi";
import UserApi from "./api/UserApi";
import AuthApi from "./api/AuthApi";
import SuperAdminApi from "./api/SuperAdminApi";

import Routes from "./lib/Route";

import { setMobileCookie, removeMobileCookie } from "./lib/Common";

class App extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
    };
    constructor(props) {
        super(props);

        const { cookies } = props;
        this.state = {
            changeContextValue: this.changeContextValue,

            lang: "en",
            client: cookies.get("client"),
            userKey: cookies.get("key"),
            user: cookies.get("user"),
            employee_privileges: cookies.get("employee_privileges")
                ? cookies.get("employee_privileges")
                : [],
            removeDataUser: () => {
                this.removeDataUser();
            },

            activeSite: cookies.get("active-site"),
            changeActiveSite: (value) => {
                this.changeActiveSite(value);
            },

            activeTemplate: cookies.get("active-template"),
            changeActiveTemplate: (value) => {
                this.changeActiveTemplate(value);
            },

            clientSites: [],
            activeClient: false,
            changeActiveClient: (value) => {
                this.changeActiveClient(value);
            },

            clientList: [],
            templateList: [],
            getAllClient: () => {
                this.getAllClient();
            },
            getClientDetail: (client) => {
                this.getClientDetail(client);
            },
            clientInformation: [],

            clientSubscriptions: [],

            adminSiteLanguages: [], // Site Admin Backend Language
            activeSiteLang: [], // Site Frontend Language
            activeContentLang: [], // Only use by content -> different per-client

            getSiteDetail: (value) => {
                this.getSiteDetail(value);
            },

            getAllSitesByClient: (client) => {
                this.getAllSitesByClient(client);
            },

            languagesList: [],
            sectionDetail: {
                methods: {
                    getAllWidget: (callback) => {
                        this.getAllWidget(callback);
                    },
                    getPages: (callback) => {
                        this.getPages(callback);
                    },
                    getArticleCategories: (callback) => {
                        this.getArticleCategories(callback);
                    },
                    getAllSections: (callback) => {
                        this.getAllSections(callback);
                    },
                    getAllEmployees: (callback) => {
                        this.getAllEmployees(callback);
                    },
                },
                isLoaded: {},
                widgetList: [],
                pagesList: [],
                categoryList: [],
                employeeList: [],
            },

            stateCustomerListPage: {},
        };

        this.changeLanguage = this.changeLanguage.bind(this);
        this.getDataUser = this.getDataUser.bind(this);
        this.removeDataUser = this.removeDataUser.bind(this);
        this.changeActiveSite = this.changeActiveSite.bind(this);
        this.changeActiveTemplate = this.changeActiveTemplate.bind(this);
        this.getAllWidget = this.getAllWidget.bind(this);
        this.getArticleCategories = this.getArticleCategories.bind(this);
        this.getPages = this.getPages.bind(this);
        this.getAllSections = this.getAllSections.bind(this);

        this.changeActiveClient = this.changeActiveClient.bind(this);
        this.getAllSitesByClient = this.getAllSitesByClient.bind(this);
        this.getSiteDetail = this.getSiteDetail.bind(this);
    }

    componentDidMount() {
        const { cookies } = this.props;
        const languageApi = new LanguageApi();

        if (this.state.user) {
            this.getAllClient();
            let aC = cookies.get("active-client");
            this.setState(
                {
                    activeClient: aC ? aC : this.state.clientList.length ? this.state.clientList[0].id : false,
                },
                () => {
                    if (aC) {
                        this.getAllSitesByClient(aC);
                        this.getClientDetail(aC);
                    }
                    if (this.state.activeSite) {
                        this.getSiteDetail(this.state.activeSite);
                    }
                }
            );

            languageApi.getAllLanguage().then((res) => {
                this.setState({
                    languagesList: res.data.results,
                });
            });
        }
    }

    componentDidUpdate() {
        const { cookies } = this.props;
        if (!this.state.activeClient && this.state.clientList.length) {
            let aC = cookies.get("active-client");
            if (!aC) {
                this.setState(
                    {
                        activeClient: this.state.clientList.length ? this.state.clientList[0].id : false,
                    },
                    () => {
                        this.getAllSitesByClient(this.state.activeClient);
                        this.getClientDetail(this.state.activeClient);
                    }
                );
            }
        }

        if (parseInt(this.state.activeClient) && !this.state.activeTemplate && this.state.templateList.length) {
            const aT = localStorage.getItem("templateId");
            if (parseInt(this.state.activeClient) && this.state.templateList.length && aT !== undefined) {
                let activeTemplate = this.state.templateList[0]?.id;
                this.setState(
                    {
                        activeTemplate: activeTemplate,
                    },
                    () => { }
                );
                cookies.set("active-template", activeTemplate, { path: "*" });
                localStorage.setItem("templateId", activeTemplate);
            }
        }

        if (parseInt(this.state.activeClient) && this.state.clientSites.length && !this.state.activeSite && !this.state.templateList.length) {
            this.changeActiveSite(this.state.clientSites[0]?.id)
        }
    }

    changeContextValue = (value) => {
        this.setState(value)
    }


    changeLanguage(lang) {
        localStorage.setItem("lang", lang.abbreviation.toLowerCase());
        localStorage.setItem("langId", lang.id);
        this.setState(
            {
                lang: lang.abbreviation,
                langId: lang.id,
            },
            () => {
                window.location.reload();
            }
        );
    }

    getDataUser(key, dataUser, client) {
        const { cookies } = this.props;

        localStorage.setItem("token", key);

        if (!dataUser?.is_superuser) {
            cookies.set("active-client", client.id, { path: "*" });
            if (dataUser.sites && dataUser.sites.length > 0) {
                cookies.set("active-site", dataUser.sites[0].id, { path: "*" });
                localStorage.setItem("siteId", dataUser.sites[0].id);
            }
        }

        if (window.screen.width > 1024) {
            cookies.set("key", key, { path: "*" });
            cookies.set("user", dataUser, { path: "*" });
            cookies.set("client", client, { path: "*" });
        } else {
            setMobileCookie(key, dataUser, client);
        }

        if (parseInt(this.state.activeClient) && this.state.clientSites.length && !this.state.activeSite && !this.state.templateList.length) {
            this.changeActiveSite(this.state.clientSites[0]?.id)
        }

        window.location.reload();
    }

    changeActiveSite(site) {
        const { cookies } = this.props;
        const aS = localStorage.getItem("siteId");
        let activeSite;
        if (aS) {
            if (this.state.activeSite !== site) {
                activeSite = site;
                cookies.set("active-site", site, { path: "*" });
                localStorage.setItem("siteId", site);
            } else {
                activeSite = aS;
            }
        } else {
            activeSite = site;
            cookies.set("active-site", site ? site : 5, { path: "*" });
            localStorage.setItem("siteId", site);
        }
        this.setState({
            activeSite: activeSite,
        });
        this.getSiteDetail(activeSite, () => {
            this.changeActiveTemplate(this.state.templateList[0]?.id || null);
        });
    }

    changeActiveClient(client) {
        const { cookies } = this.props;

        cookies.set("active-client", client, { path: "*" });

        this.setState(
            {
                activeClient: client,
            },
            () => {
                this.getAllSitesByClient(client, () => {
                    this.changeActiveSite(this.state.clientSites[0]?.id);
                });
                this.getClientDetail(client);
            }
        );
    }

    getAllSitesByClient(client, callback) {
        const Sa = new SuperAdminApi();

        Sa.getAllSites(this.state.userKey, client)
            .then((res) => {
                let sites = [];

                if (res.data.sites.length > 0) {
                    res.data.sites.forEach((s) => {
                        sites.push(s.id);
                    });

                    Sa.getAllLanguageBySite(sites).then((res) => {
                        this.setState({
                            activeContentLang: res.data.results,
                        });
                    });
                }

                this.setState(
                    {
                        clientSites: res.data.sites,
                    },
                    () => {
                        if (typeof callback === "function") {
                            callback();
                        }
                    }
                );
            })
            .catch((err) => { });
    }

    getClientDetail(clientId) {
        const Sa = new SuperAdminApi();

        Sa.getClientDetail(this.state.userKey, clientId)
            .then((res) => {
                this.setState({
                    clientInformation: res.data,
                    adminSiteLanguages: res.data.languages,
                    clientSubscriptions: res.data.subscriptions,
                });
            })
            .catch((err) => { });
    }

    getSiteDetail(siteId, callback) {
        const groups = (this.state.user.groups || []).map(e => e.id)
        if ((this.state.user.is_superuser && !groups.includes(8)) || (!this.state.user.is_superuser && _.intersection([1, 2, 3, 4, 6], groups).length > 0)) {
            const Sa = new SuperAdminApi();

            Sa.getSiteDetail(this.state.userKey, siteId)
                .then((res) => {
                    this.setState(
                        {
                            activeSiteLang: res.data.site_languages,
                            templateList: res.data.templates,
                        },
                        () => {
                            if (typeof callback === "function") {
                                callback();
                            }
                        }
                    );
                })
                .catch((err) => { });
        }

    }

    changeActiveTemplate(template) {
        const { cookies } = this.props;
        const aT = localStorage.getItem("templateId");

        let activeTemplate;
        if (aT) {
            if (this.state.activeTemplate !== template) {
                activeTemplate = template;
                cookies.set("active-template", template, { path: "*" });
                localStorage.setItem("templateId", template);
            } else {
                activeTemplate = aT;
            }
        } else {
            activeTemplate = template;
            cookies.set("active-template", template ? template : 41, {
                path: "*",
            });
            localStorage.setItem("templateId", template);
        }

        this.setState(
            {
                activeTemplate: activeTemplate,
            },
            () => { }
        );
    }

    getAllClient() {
        const superAdminApi = new SuperAdminApi();

        const param = {
            max_size: true,
        };

        superAdminApi.getAllClient(this.state.userKey, param).then((res) => {
            this.setState({
                clientList: res.data.results,
            });
        });
    }

    removeDataUser() {
        const { cookies } = this.props;
        const objAuth = new AuthApi();

        objAuth
            .logoutBzPublish()
            .then((res) => {
                if (res.status === 200) {
                    this.setState(
                        {
                            userKey: false,
                            user: false,
                            employee_privileges: false,
                        },
                        () => {
                            if (window.screen.width > 1024) {
                                cookies.remove("key", { path: "*" });
                                cookies.remove("user", { path: "*" });
                                cookies.remove("client", { path: "*" });
                                cookies.remove("employee_privileges", {
                                    path: "*",
                                });
                            } else {
                                removeMobileCookie();
                            }
                        }
                    );
                }
            })
            .catch((err) => { });
    }

    // SECTION DETAIL

    getAllWidget(callback) {
        const objWidget = new WidgetApi();

        const params = {
            template: Number(localStorage.getItem("templateId")),
            max_size: true,
            category: 3,
            internal: this.state.is_internal,
        };

        objWidget.getAllWidgets(params).then((res) => {
            // namePage setting
            const widgetList = res.data.results.map((item) => {
                var page = "";
                if (item.page) {
                    page = " - " + item.page;
                }

                return {
                    ...item,
                    namePage: item.name + page,
                };
            });

            let sectionDetail = {
                ...this.state.sectionDetail,
                widgetList: widgetList,
                isLoaded: {
                    ...this.state.sectionDetail.isLoaded,
                    widgetList: true,
                },
            };

            this.setState(
                {
                    sectionDetail: sectionDetail,
                },
                () => {
                    if (typeof callback === "function") {
                        callback();
                    }
                }
            );
        });
    }

    getPages(callback) {
        const objPage = new PageApi();

        const params = {
            language: localStorage.getItem("langId") ? localStorage.getItem("langId") : 1,
            template: localStorage.getItem("templateId"),
            max_size: true,
        };

        objPage.pageLanguageList(params).then((res) => {
            let sectionDetail = {
                ...this.state.sectionDetail,
                pagesList: res.data.results,
                isLoaded: {
                    ...this.state.sectionDetail.isLoaded,
                    pagesList: true,
                },
            };

            this.setState(
                {
                    sectionDetail: sectionDetail,
                },
                () => {
                    if (typeof callback === "function") {
                        callback();
                    }
                }
            );
        });
    }

    getArticleCategories(callback) {
        const objArticle = new ArticleApi();

        objArticle.getArticleCategories().then((res) => {
            let sectionDetail = {
                ...this.state.sectionDetail,
                categoryList: res.data.results ? res.data.results : [],
                isLoaded: {
                    ...this.state.sectionDetail.isLoaded,
                    categoryList: true,
                },
            };

            this.setState(
                {
                    sectionDetail: sectionDetail,
                },
                () => {
                    if (typeof callback === "function") {
                        callback();
                    }
                }
            );
        });
    }

    getAllSections(callback) {
        const obj = new SectionApi();

        obj.getAllSections().then((res) => {
            let sectionDetail = {
                ...this.state.sectionDetail,
                sectionList: res.data.results || [],
                isLoaded: {
                    ...this.state.sectionDetail.isLoaded,
                    sectionList: true,
                },
            };

            this.setState(
                {
                    sectionDetail: sectionDetail,
                },
                () => {
                    if (typeof callback === "function") {
                        callback();
                    }
                }
            );
        });
    }

    getAllEmployees(callback) {
        const userApi = new UserApi();

        userApi
            .getAllEmployees({
                max_size: true,
            })
            .then((res) => {
                const resEmployee = res.data.results.map((item) => {
                    var image = "";
                    if (item.avatar) {
                        image = (
                            <div>
                                <img width="30" height="30" src={item.avatar} alt=""></img>
                                {" " + item.user.first_name + " " + item.user.last_name}
                            </div>
                        );
                    } else {
                        image = item.user.first_name + " " + item.user.last_name;
                    }

                    return {
                        val: item.id,
                        label: image,
                    };
                });

                let sectionDetail = {
                    ...this.state.sectionDetail,
                    employeeList: resEmployee,
                    isLoaded: {
                        ...this.state.sectionDetail.isLoaded,
                        employeeList: true,
                    },
                };

                this.setState(
                    {
                        sectionDetail: sectionDetail,
                    },
                    () => {
                        if (typeof callback === "function") {
                            callback();
                        }
                    }
                );
            });
    }

    render() {
        const { cookies } = this.props;
        const { languagesList, activeSiteLang, adminSiteLanguages, activeContentLang } = this.state;

        let data;

        if (this.state.client) {
            data = this.state.client.name;
        } else {
            data = "BZ Publish";
        }

        if (cookies.get("key") && cookies.get("user")) {
            return (
                <Context.Provider value={this.state}>
                    <Helmet title={data} />
                    <Router>
                        <NewDashboard
                            user={this.state.user}
                            activeClient={this.state.activeClient}
                            activeSite={this.state.activeSite}
                            activeTemplate={this.state.activeTemplate}
                            changeLanguage={this.changeLanguage}
                            sites={this.state.clientSites}
                            languageList={languagesList.length > 0}
                            activeSiteLang={activeSiteLang.length > 0}
                            adminSiteLanguages={adminSiteLanguages.length > 0}
                            activeContentLang={activeContentLang.length > 0}
                            clientInformation={this.state.clientInformation}
                        >
                            <Switch>
                                <Routes user={this.state.user} props={this.props} activeTemplate={this.state.activeTemplate} activeSite={this.state.activeSite} clientInformation={this.state.clientInformation} />
                                <PrivateRoute path="*" component={Page404}></PrivateRoute>
                            </Switch>
                        </NewDashboard>
                    </Router>
                </Context.Provider>
            );
        } else {
            return (
                <Router>
                    <Switch>
                        <Route path="/login" exact={true} render={(props) => <Login {...props} getDataUser={this.getDataUser} />} />
                        <Route path="/test-sidebar" component={TestSidebarPage}></Route>
                        <Route path="/test-route" component={TestPage}></Route>
                        <Route path="/new-sidebar" component={NewSideBar}></Route>

                        {/* <Route path="*" component={Page404}></Route> */}
                        <Route path="*" render={(props) => <Login {...props} getDataUser={this.getDataUser} />} />
                    </Switch>
                </Router>
            );
        }
    }
}

export default withCookies(App);
