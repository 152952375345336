import axios from "axios";
import config from "./../config/config";
export default class GeneralApi {
  // base_api = "http://192.168.13.177:7000/"
  site_api = config.base_api;
  token = config.token;

  getApi(endpoint) {
    return axios.get(this.site_api + `${endpoint}`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getDetailSidebar(siteId, params) {
    return axios.get(this.site_api + `sites/${siteId}/get_sidebar/`, {
      params: params,
      //   params:{
      //       template:templateId,
      //       language:language
      //   },
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getDetailSidebarNew(param) {
    return axios.get(
      this.site_api +
        `pages/?template=${param.template}&ordering=id&language=${param.language}`,
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  getLanguages() {
    return axios.get(this.site_api + `languages/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getUrls() {
    return axios.get(this.site_api + `urls/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getUrlDetail(params) {
    return axios.get(this.site_api + `urls/`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getCountries(params) {
    return axios.get(this.site_api + `countries/`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getCurrencies(params) {
    return axios.get(this.site_api + `currencies/`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }
}
