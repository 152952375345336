import React, { Component } from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import swal from "sweetalert";
// import AlertHelper from "./../lib/AlertHelper"
import Pagination from "react-js-pagination";

import MyCard, { CardHeader } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content";

import AssetDeleteConfirm from "./../components/Modal/AssetDeleteConfirm";
import TitlePages from "./Elements/TitlePages";

import AssetApi from "./../api/AssetApi";
import "./AssetListPage.css";
import { shortSentence } from "../lib/StringHelper";
import docImage from "./Assets/Docx.svg";
import ekselImage from "./Assets/Xlsx.svg";
import pdfImage from "./Assets/Pdf.svg";
import defaultImage from "./Assets/default-image.svg";
import videoImage from "./Assets/Video.svg";
import svgImage from "./Assets/Svg.svg";
import { withTranslation } from "react-i18next";
import config from "../config/config";
import ReactGA from "react-ga";
import Context from "./Context";

class AssetListPage extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            assets: [],
            assetsAfterFilter: [],
            selectedAssets: [],
            deleteDisable: true,
            activeClient: false,

            showModal: false,
            loadingModal: "",
            asset_types: [],
            asset_type: 0,
            successModalshow: false,
            errorModalshow: false,
            errorMessage: "",
            inputType: "",
            search: "",
            activePage: 1,
            limitPageData: 30,
            totalCount: 0,
            totalDataFilter: 0,
            isFilterByType: false,
            isFilterBySearch: false,
            transPage: "pages.asset.",
            transAlert: "alert.",
            co_md: "",
        };

        this.getAllAssets = this.getAllAssets.bind(this);
        this.getAllAssetsAfterFilter = this.getAllAssetsAfterFilter.bind(this);
        this.showConfirmModal = this.showConfirmModal.bind(this);
        this.deleteSelectedAsset = this.deleteSelectedAsset.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.getAssetTypeLanguage = this.getAssetTypeLanguage.bind(this);
        this.handleChangeFileType = this.handleChangeFileType.bind(this);
    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
        const { activeClient } = this.context;

        const w = window.screen.width;

        if (w <= 1440) {
            this.setState(
                {
                    activeClient: activeClient,
                    limitPageData: 20,
                    co_md: "col-md-3",
                },
                () => {
                    this.getAllAssets();
                }
            );
        } else if (w > 1441) {
            this.setState(
                {
                    activeClient: activeClient,
                    limitPageData: 30,
                    co_md: "col-md-2",
                },
                () => {
                    this.getAllAssets();
                }
            );
        }
        this.getAssetTypeLanguage();
        // this.getAllAssets();
    }

    componentDidUpdate(prevProps, prevState) {
        const { activeClient } = this.context;
        if (activeClient) {
            if (this.state.activeClient !== activeClient) {
                this.setState(
                    {
                        activeClient: activeClient,
                    },
                    () => {
                        this.getAllAssets();
                    }
                );
            }
        }
    }

    getAllAssets() {
        const objAsset = new AssetApi();
        let langID = localStorage.getItem("langId");

        return objAsset
            .getAllAssets({
                client: this.state.activeClient,
                language: langID ? langID : 1,
                page_size: this.state.limitPageData,
                page: this.state.activePage,
                search: this.state.search,
            })
            .then((res) => {
                this.setState({
                    assets: res.data.results,
                    totalCount: res.data.count,
                });
            });
    }

    getAllAssetsAfterFilter(input, nextPage) {
        const objAsset = new AssetApi();
        let langID = localStorage.getItem("langId");

        let params = nextPage
            ? {
                  language: langID,
                  page: nextPage,
                  page_size: 30,
                  search: this.state.search,
                  asset_type: input,
                  client: this.state.activeClient,
              }
            : {
                  language: langID,
                  page_size: 30,
                  search: this.state.search,
                  asset_type: input,
                  client: this.state.activeClient,
              };
        return objAsset.getAllAssetsAfterFilter(params).then((res) => {
            this.setState({
                assets: res.data.results,
                isFilterByType: true,
                totalDataFilter: res.data.count,
            });
        });
    }

    handleChangeFileType(value) {
        if (value !== "Check") {

            this.setState(
                {
                    inputType: value,
                    activePage: 1,
                },
                () => {
                    this.getAllAssetsAfterFilter(value);
                }
            );
        } else {
            if (value === "Check") {
                this.setState(
                    {
                        isFilterByType: false,
                    },
                    () => {
                        this.getAllAssets();
                    }
                );
            }
        }
    }

    handleSelectedAsset(asset, value) {
        var lastSelected = [...this.state.selectedAssets];
        var indexDelete = -1;
        // var stateSelected = ""

        if (value === true) {
            lastSelected.push(asset);
        } else {
            this.state.selectedAssets.forEach((item, index) => {
                if (item.id === asset.id) {
                    indexDelete = index;
                }
            });

            lastSelected.splice(indexDelete, 1);
        }

        this.setState(
            {
                selectedAssets: lastSelected,
            },
            () => {
                if (this.state.selectedAssets.length > 0) {
                    this.setState({
                        deleteDisable: false,
                    });
                } else {
                    this.setState({
                        deleteDisable: true,
                    });
                }
            }
        );
    }

    getAssetTypeLanguage() {
        const obj = new AssetApi();
        const lang = localStorage.getItem("langId");

        obj.assetTypeLanguage({
            language: lang ? lang : 1,
        }).then((res) => {
            this.setState({
                asset_types: res.data.results,
            });
        });
    }

    showConfirmModal() {
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    deleteSelectedAsset() {
        const { t } = this.props;
        const objAsset = new AssetApi();
        this.setState({
            loadingModal: (
                <div className="overlay d-flex justify-content-center align-items-center">
                    <i className="fas fa-2x fa-sync fa-spin"></i>
                </div>
            ),
        });

        const selectedAssetId = this.state.selectedAssets.map((item) => {
            return item.id;
        });

        objAsset
            .assetMultiDelete(selectedAssetId)
            .then((res) => {
                this.getAllAssets().then((resB) => {
                    this.setState(
                        {
                            loadingModal: "",
                            showModal: !this.state.showModal,
                        },
                        () => {
                            swal(
                                "Success !",
                                t(
                                    this.state.transAlert +
                                        "asset_multi_delete_success"
                                ),
                                "success"
                            );
                        }
                    );
                });
            })
            .catch((err) => {
                this.setState({
                    errorModalshow: true,
                    message: err.message,
                });
            });
    }

    handlePageChange(pageNumber) {
        this.setState(
            {
                activePage: pageNumber,
            },
            () => {
                if (this.state.inputType || this.state.search) {
                    this.getAllAssetsAfterFilter(
                        this.state.inputType,
                        this.state.activePage
                    );
                } else {
                    this.getAllAssets();
                }
            }
        );
    }

    handleSearchChange(value, input, typefilter) {
        if (input) {
            if (typefilter) {
                this.setState(
                    {
                        search: value,
                        activePage: 1,
                        isFilterBySearch: true,
                        isFilterByType: true,
                    },
                    () => {
                        this.getAllAssetsAfterFilter(input);
                    }
                );
            } else {
                this.setState(
                    {
                        search: value,
                        activePage: 1,
                        isFilterBySearch: true,
                        isFilterByType: false,
                    },
                    () => {
                        this.getAllAssets();
                    }
                );
            }
        } else {
            this.setState(
                {
                    search: value,
                    activePage: 1,
                    isFilterBySearch: true,
                    isFilterByType: false,
                },
                () => {
                    this.getAllAssets();
                }
            );
        }
    }

    render() {
        const { t } = this.props;
        var media, url;

        const AssetList =
            this.state.assets.length > 0 ? (
                this.state.assets.map((item, index) => {
                    if (item.asset_file) {
                        url = item.asset_file;
                    } else {
                        url = "";
                    }

                    //   let url = item.asset_file;
                    let typeDoc = [".docx", ".doc"];
                    let typePdf = ".pdf";
                    let typeXlx = [
                        ".xls",
                        ".xlxs",
                        ".xlxm",
                        ".xlxb",
                        ".xltx",
                        ".xltm",
                    ];
                    //   let typeVideo = [
                    //     ".mp3",
                    //     ".mp4",
                    //     ".webm",
                    //     ".mkv",
                    //     ".m4p",
                    //     ".3gp",
                    //     ".flv",
                    //     ".f4v",
                    //     ".f4p",
                    //     ".f4a",
                    //     ".mpeg",
                    //   ];
                    let typeSvg = ".svg";
                    //   let myArr = url.split();
                    let resultDoc = url
                        .split()
                        .some((i) => typeDoc.includes(i));
                    let resultPdf = url.includes(typePdf);
                    //   let resultVideo = myArr.some((i) => typeVideo.includes(i));
                    let resultEksel = url
                        .split()
                        .some((i) => typeXlx.includes(i));
                    let resultSvg = url.includes(typeSvg);

                    if (item.asset_type.id === 1 || item.asset_type.id === 3) {
                        media = (
                            <Link to={`/asset/detail/${item.id}`}>
                                <Card.Img
                                    title={`asset-media-image-${index}`}
                                    style={{
                                        height: "126px",
                                        width: "100%",
                                        objectFit: "cover",
                                        borderRadius: "6px",
                                    }}
                                    src={
                                        item.file_small
                                            ? item.file_small
                                            : item.asset_file
                                    }
                                ></Card.Img>
                            </Link>
                        );
                    } else if (item.asset_type.id === 5) {
                        media = (
                            <Link to={`/asset/detail/${item.id}`}>
                                {resultPdf ? (
                                    <img
                                        style={{
                                            height: "126px",
                                            width: "100%",
                                            objectFit: "contain",
                                            borderRadius: "6px",
                                        }}
                                        src={
                                            item.thumbnail_file
                                                ? item.thumbnail_file
                                                : pdfImage
                                        }
                                        alt=""
                                    />
                                ) : resultDoc ? (
                                    <img
                                        style={{
                                            height: "126px",
                                            width: "100%",
                                            objectFit: "contain",
                                            borderRadius: "6px",
                                        }}
                                        src={
                                            item.thumbnail_file
                                                ? item.thumbnail_file
                                                : docImage
                                        }
                                        alt=""
                                    />
                                ) : resultEksel ? (
                                    <img
                                        style={{
                                            height: "126px",
                                            width: "100%",
                                            objectFit: "contain",
                                            borderRadius: "6px",
                                        }}
                                        src={
                                            item.thumbnail_file
                                                ? item.thumbnail_file
                                                : ekselImage
                                        }
                                        alt=""
                                    />
                                ) : resultSvg ? (
                                    <img
                                        style={{
                                            height: "126px",
                                            width: "100%",
                                            objectFit: "contain",
                                            borderRadius: "6px",
                                        }}
                                        src={
                                            item.thumbnail_file
                                                ? item.thumbnail_file
                                                : svgImage
                                        }
                                        alt=""
                                    />
                                ) : (
                                    <img
                                        style={{
                                            height: "126px",
                                            width: "100%",
                                            objectFit: "contain",
                                            borderRadius: "6px",
                                        }}
                                        src={
                                            item.thumbnail_file
                                                ? item.thumbnail_file
                                                : defaultImage
                                        }
                                        alt=""
                                    />
                                )}
                            </Link>
                        );
                    } else if (item.asset_type.id === 4) {
                        media = (
                            <Link to={`/asset/detail/${item.id}`}>
                                <img
                                    style={{
                                        height: "126px",
                                        width: "100%",
                                        objectFit: "contain",
                                        borderRadius: "6px",
                                    }}
                                    src={
                                        item.thumbnail_file
                                            ? item.thumbnail_file
                                            : videoImage
                                    }
                                    alt=""
                                />
                            </Link>
                        );
                    } else {
                        if (item.asset_link) {
                            media = (
                                <Link to={`/asset/detail/${item.id}`}>
                                    {item.thumbnail_file ? (
                                        <Card.Img
                                            title={`media-${item.asset_type_name}-${index}`}
                                            style={{
                                                height: "126px",
                                                width: "100%",
                                                objectFit: "cover",
                                                borderRadius: "6px",
                                            }}
                                            src={item.thumbnail_file}
                                        ></Card.Img>
                                    ) : (
                                        <img
                                            style={{
                                                height: "126px",
                                                width: "100%",
                                                objectFit: "contain",
                                                borderRadius: "6px",
                                            }}
                                            src={videoImage}
                                            alt=""
                                        />
                                    )}
                                </Link>
                            );
                        } else if (
                            item.asset_languages[0]?.asset_link ||
                            item.asset_languages[1]?.asset_link
                        ) {
                            media = (
                                <Link to={`/asset/detail/${item.id}`}>
                                    {item.asset_languages[0]?.thumbnail_file ||
                                    item.asset_languages[1]?.thumbnail_file ? (
                                        <Card.Img
                                            title={`media-${item.asset_type_name}-${index}`}
                                            style={{
                                                height: "126px",
                                                width: "100%",
                                                objectFit: "cover",
                                                borderRadius: "6px",
                                            }}
                                            src={
                                                item.asset_languages[0]
                                                    ?.thumbnail_file ||
                                                item.asset_languages[1]
                                                    ?.thumbnail_file
                                            }
                                        ></Card.Img>
                                    ) : (
                                        <img
                                            style={{
                                                height: "126px",
                                                width: "100%",
                                                objectFit: "contain",
                                                borderRadius: "6px",
                                            }}
                                            src={videoImage}
                                            alt=""
                                        />
                                    )}
                                </Link>
                            );
                        } else {
                            media = (
                                <Link to={`/asset/detail/${item.id}`}>
                                    {item.thumbnail_file ? (
                                        <Card.Img
                                            title={`media-${item.asset_type_name}-${index}`}
                                            style={{
                                                height: "126px",
                                                width: "100%",
                                                objectFit: "cover",
                                                borderRadius: "6px",
                                            }}
                                            src={item.thumbnail_file}
                                        ></Card.Img>
                                    ) : (
                                        <img
                                            style={{
                                                height: "126px",
                                                width: "100%",
                                                objectFit: "contain",
                                                borderRadius: "6px",
                                            }}
                                            src={item.file_small}
                                            alt=""
                                        />
                                    )}
                                </Link>
                            );
                        }
                    }

                    return (
                        <div key={item.id} className={this.state.co_md}>
                            <Card>
                                <>
                                    <div
                                    className="card-header  bg-primary"
                                        // style={{
                                        //     padding: "1rem .5rem 0 .5rem",
                                        // }}
                                    >
                                        <div className="d-flex flex-row">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                style={{
                                                    position: "relative",
                                                    margin: "auto 0.5rem auto 0",
                                                }}
                                                id={"label" + item.id}
                                                onClick={(event) =>
                                                    this.handleSelectedAsset(
                                                        item,
                                                        event.target.checked
                                                    )
                                                }
                                            ></input>
                                            <label
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    maxWidth: "100%",
                                                }}
                                                className="form-check-label"
                                                htmlFor={"label" + item.id}
                                            >
                                                {item.asset_type.name}
                                            </label>
                                        </div>
                                    </div>
                                </>

                                <div style={{ padding: "1rem .5rem" }}>
                                    {media}
                                </div>

                                <div
                                    align="left"
                                    style={{
                                        fontSize: 16,
                                        margin: "auto auto 1rem .5rem",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        width: "90%",
                                    }}
                                >
                                    {shortSentence(item.description, 6)} ...
                                </div>
                                {/* </Card.Footer> */}
                            </Card>
                        </div>
                    );
                })
            ) : (
                <div className="col-md-12 center-block">
                    <h4 className="text-center">There is no Assets</h4>
                </div>
            );

        return (
            <Content>
                <ContentHeader>
                    {/* <h1> Asset List </h1> */}
                    <TitlePages title={t(this.state.transPage + "title")} />
                </ContentHeader>
                <ContentBody>
                    <MyCard>
                        <CardHeader>
                            <div className="card-tools">
                                <div className="row">
                                    <div
                                        className=" float-right"
                                        style={{ marginRight: 10 }}
                                    >
                                        <Button
                                            variant="danger"
                                            disabled={this.state.deleteDisable}
                                            onClick={this.showConfirmModal}
                                            className="float-right"
                                        >
                                            {" "}
                                            <i className="fa fa-trash">
                                                &nbsp;
                                            </i>{" "}
                                            {t(
                                                this.state.transPage +
                                                    "delete_selected"
                                            )}{" "}
                                        </Button>
                                    </div>
                                    <Link
                                        to="/asset/add"
                                        className=" float-right"
                                        style={{ marginRight: 10 }}
                                    >
                                        <Button
                                            variant="primary"
                                            className="float-right"
                                        >
                                            <i className="fa fa-plus">&nbsp;</i>
                                            {t(
                                                this.state.transPage +
                                                    "add_asset"
                                            )}
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </CardHeader>
                    </MyCard>

                    <div
                        className="d-flex flex-row"
                        width="100%"
                        style={{ padding: "0 2rem" }}
                    >
                        <div
                            className="form-group"
                            style={{ width: "30%", marginRight: "1rem" }}
                        >
                            <label>{t("file_type")}</label>
                            <select
                                className="form-control"
                                defaultValue={this.state.inputType}
                                onChange={(event) => {
                                    this.handleChangeFileType(
                                        event.target.value
                                    );
                                }}
                            >
                                <option value="Check">
                                    {" "}
                                    --{" "}
                                    {t(
                                        this.state.transPage +
                                            "select_file_type"
                                    )}{" "}
                                    --{" "}
                                </option>
                                {this.state.asset_types.map((item) => {
                                    return (
                                        <option value={item.id}>
                                            {" "}
                                            {item.name}{" "}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="form-group" style={{ width: "30%" }}>
                            <label>{t("search")}</label>
                            <input
                                type="text"
                                placeholder={t(
                                    this.state.transPage + "search_asset"
                                )}
                                className="form-control"
                                name="search"
                                value={this.state.search}
                                onChange={(event) =>
                                    this.handleSearchChange(
                                        event.target.value,
                                        this.state.inputType,
                                        this.state.isFilterByType
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div style={{ padding: "0 2rem" }}>
                        {" "}
                        <hr />
                    </div>

                    <div
                        className="clearfix"
                        style={{ marginBottom: "3rem" }}
                    ></div>
                    <div className="row" style={{ padding: "0 2rem" }}>
                        {AssetList}
                    </div>
                    <div className="d-flex flex-row">
                        <div
                            style={{
                                float: "left",
                                padding: "0 2rem",
                                margin: "2rem 0",
                            }}
                            id="pagination_custom"
                        >
                            <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.limitPageData}
                                totalItemsCount={
                                    this.state.isFilterByType
                                        ? this.state.totalDataFilter
                                        : this.state.totalCount
                                }
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={1}
                                onChange={this.handlePageChange.bind(this)}
                                itemClass="page-item"
                                linkClass="page-link"
                                hideDisabled={true}
                                prevPageText={t("prev")}
                                nextPageText={t("next")}
                            />
                        </div>
                        {/*disabled toal asset for temp */}
                        {/* <div
              style={{
                margin: "auto 0",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <p style={{ marginTop: "unset" }}>
                {t("total")}{" "}
                {this.state.isFilterByType
                  ? this.state.totalDataFilter
                  : this.state.totalCount}
              </p>
            </div> */}
                    </div>

                    <div className="clearfix"></div>

                    <AssetDeleteConfirm
                        show={this.state.showModal}
                        selectedAssets={this.state.selectedAssets}
                        loadingModal={this.state.loadingModal}
                        onHide={this.showConfirmModal}
                        getAllAssets={this.getAllAssets}
                        deleteSelectedAsset={this.deleteSelectedAsset}
                    ></AssetDeleteConfirm>
                </ContentBody>
            </Content>
        );
    }
}

export default withTranslation()(AssetListPage);
